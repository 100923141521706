import React, {ReactElement, useEffect, useState} from "react";
import styles from "./styles.module.sass";
import SpinnerWithText from "../spinner/spinner";
import {url} from "../../config";
import noLogo from "assets/images/static/no-logo.svg";
import {getFullName} from "../../core/utils/helpers";

interface IProps {
    children: ReactElement;
    isCtrlKeyDowned: boolean;
    handleLoadProfile: (userId: number) => void;
    userId: number;
    currentProfile: any;
    isProfileLoading: boolean;
}

const PreviewProfile = ({
    children,
    isCtrlKeyDowned,
    handleLoadProfile,
    userId,
    currentProfile,
    isProfileLoading,
}: IProps) => {
    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });

    const [isProfileVisible, setIsProfileVisible] = useState(false);

    useEffect(() => {
        if (!isCtrlKeyDowned) {
            setIsProfileVisible(false);
        }
    }, [isCtrlKeyDowned]);

    const handleMouseLeaveFromProfile = () => {
        setIsProfileVisible(false);
    };

    const handleMouseLeaveFromText = () => {
        if (isCtrlKeyDowned) {
            return;
        }
        setIsProfileVisible(false);
    };

    const handleMouseMoveText = (e: any) => {
        setIsProfileVisible(true);

        if (isCtrlKeyDowned) {
            return;
        }

        setPosition({x: e.clientX, y: e.clientY});
    };

    return (
        <React.Fragment>
            {isProfileVisible && (
                <div
                    className={styles.wrapper}
                    style={{
                        top: position.y,
                        left: position.x + 25,
                        boxShadow: isCtrlKeyDowned ? "0 0 15px #33b5e5" : "none",
                    }}
                    onMouseLeave={handleMouseLeaveFromProfile}
                >
                    <div className={styles.profileWrapper}>
                        {isProfileLoading ? (
                            <SpinnerWithText text="Загрузка..." className={styles.loader} />
                        ) : (
                            <React.Fragment>
                                {currentProfile ? (
                                    <div className={styles.profile}>
                                        <div className={styles.profileAvatar}>
                                            <img
                                                src={
                                                    currentProfile.userLogo?.fileDownloadUri
                                                        ? url +
                                                          currentProfile.userLogo.fileDownloadUri.slice(
                                                              1,
                                                          )
                                                        : noLogo
                                                }
                                                alt="avatar"
                                            />
                                        </div>
                                        <div className={styles.profileUserInfo}>
                                            <p className={styles.profileUserName}>
                                                {getFullName(
                                                    currentProfile.firstName,
                                                    currentProfile.lastName,
                                                )}
                                            </p>
                                            <p>ID: {currentProfile.id}</p>

                                            {currentProfile.additional && (
                                                <React.Fragment>
                                                    <p>
                                                        Город:{" "}
                                                        {currentProfile.additional.city?.altName}
                                                    </p>
                                                    <p>Пол: {currentProfile.additional.gender}</p>
                                                    <p>
                                                        Школа:{" "}
                                                        {
                                                            currentProfile.additional?.school
                                                                ?.alternateName
                                                        }
                                                    </p>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <p>Нет информации.</p>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    <p className={styles.helperMessage}>
                        Зажмите CTRL, чтобы взаимодействовать с окном
                    </p>
                </div>
            )}

            <span
                className={styles.text}
                onMouseLeave={handleMouseLeaveFromText}
                onMouseMove={handleMouseMoveText}
                onMouseEnter={() => handleLoadProfile(userId)}
            >
                {children}
            </span>
        </React.Fragment>
    );
};

export default PreviewProfile;
