import React from "react";
import PropTypes from "prop-types";
import {TextWithWordSkip} from "./text-with-word-skip";

export const TextWithWordSkipContainer = ({
    text,
    answers,
    skipSymbol,
    selectedAnswers,
    selectWordHandle,
}) => {
    return (
        <TextWithWordSkip
            text={text}
            answers={answers}
            skipSymbol={skipSymbol}
            selectedAnswers={selectedAnswers}
            selectWordHandle={selectWordHandle}
        />
    );
};

TextWithWordSkipContainer.propTypes = {
    answers: PropTypes.array,
    selectedAnswers: PropTypes.array,
    selectWordHandle: PropTypes.func,
    skipSymbol: PropTypes.string,
    text: PropTypes.string,
};
