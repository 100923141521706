import usePlayerV2 from "entries/player-v2/hooks/usePlayerV2";
import PlayerV2 from "entries/player-v2/views/player-v2/player-v2";
import React from "react";
import {useParams} from "react-router-dom";

const PlayerV2Container = ({playerMode, isSingleTask = false, questionsForView = null}) => {
    const {id, asid} = useParams();

    const {
        notificationContent,
        notificationLayerIsActive,
        questions,
        handleSelectStep,
        currentStep,
        content,
        handleCheckAnswer,
        handleToNextQuestion,
        selectedQuestion,
        answer,
        userRights,
        isAdmin,
        isCreator,
        handleExit,
        resultIsLoading,
        withModeration,
        metaTags,
    } = usePlayerV2(id, playerMode, isSingleTask, asid, questionsForView);

    return (
        <PlayerV2
            answer={answer}
            isAdmin={isAdmin}
            isCreator={isCreator}
            content={content}
            questions={questions}
            currentStep={currentStep}
            notificationLayerIsActive={notificationLayerIsActive}
            notificationContent={notificationContent}
            handleSelectStep={handleSelectStep}
            handleCheckAnswer={handleCheckAnswer}
            handleToNextQuestion={handleToNextQuestion}
            selectedQuestion={selectedQuestion}
            userRights={userRights}
            withModeration={withModeration}
            playerMode={playerMode}
            handleExit={handleExit}
            resultIsLoading={resultIsLoading}
            metaTags={metaTags}
        />
    );
};

export default PlayerV2Container;
