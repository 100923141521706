import {Request} from "../../../shared/utils/request";
import {Banner} from "../model";

const URL = "/banner";
export const BannerAPI = {
    getBanners: (page: number, pageSize: number) => {
        return Request.get(`${URL}?page=${page}&pageSize=${pageSize}`);
    },
    createBanner: (banner: Banner) => {
        return Request.post(`${URL}`, banner);
    },
    getBannerById: (id: number) => {
        return Request.get(`${URL}/${id}`);
    },
    updateBanner: (id: number, banner: Banner) => {
        return Request.put(`${URL}/${id}`, banner);
    },
    deleteBanner: (id: number) => {
        return Request.delete(`${URL}/${id}`);
    },
    getBannersByPosition: (position: string) => {
        return Request.get(`${URL}&bannerPosition=${position}`);
    },
};
