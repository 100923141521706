import React from "react";
import styles from "./styles.module.sass";
import GeoSelectRegionsContainer from "../geo-selects/views/geo-select-regions/geo-select-regions-container";
import GeoSelectCityContainer from "../geo-selects/views/geo-select-city/geo-select-city-container";
import {ISchool} from "../../interfaces/ISchool";
import {ISelect} from "../../interfaces/ISelect";

interface IProps {
    school: ISchool | undefined;
    setSchool: (school: ISchool) => void;
    selectedRegion: ISelect | undefined;
    setSelectedRegion: (select: ISelect | undefined) => void;
    selectedCity: ISelect | undefined;
    setSelectedCity: (select: ISelect | undefined) => void;
}

const FormSchool = ({
    school,
    setSchool,
    selectedRegion,
    setSelectedRegion,
    selectedCity,
    setSelectedCity,
}: IProps) => {
    return (
        <div>
            <div className={styles.formRow}>
                <p className="title">Название</p>
                <input
                    type="text"
                    className="form-control"
                    value={school?.alternateName || ""}
                    onChange={(e) => setSchool({...school, alternateName: e.target.value})}
                />
            </div>

            <div className={styles.formRow}>
                <p className="title">Короткое название</p>
                <input
                    type="text"
                    className="form-control"
                    value={school?.shortName || ""}
                    onChange={(e) => setSchool({...school, shortName: e.target.value})}
                />
            </div>

            <div className={styles.formRow}>
                <p className="title">Длинное название</p>
                <input
                    type="text"
                    className="form-control"
                    value={school?.longName || ""}
                    onChange={(e) => setSchool({...school, longName: e.target.value})}
                />
            </div>

            <div className={styles.formRow}>
                <p className="title">Адрес</p>
                <input
                    type="text"
                    className="form-control"
                    value={school?.lawAddress || ""}
                    onChange={(e) => setSchool({...school, lawAddress: e.target.value})}
                />
            </div>

            <div className={styles.formRow}>
                <p className="title">Регион</p>
                <GeoSelectRegionsContainer
                    selectedRegion={selectedRegion}
                    handleSelect={(e) => {
                        setSelectedRegion(e);
                        setSchool({...school, region: {geoNameId: e.value}});
                    }}
                />
            </div>

            <div className={styles.formRow}>
                <p className="title">Город</p>
                <GeoSelectCityContainer
                    regionId={selectedRegion?.value}
                    selectedCity={selectedCity}
                    handleSelect={(e) => {
                        setSelectedCity(e);
                        setSchool({...school, city: {geoNameId: e.value}});
                    }}
                />
            </div>
        </div>
    );
};

export default FormSchool;
