import React from "react";
import PropTypes from "prop-types";

import {Picture} from "views/containers/property-background-select/picture";

import styles from "./picture-list.module.sass";
import cn from "classnames";

export const PictureList = ({
    tags,
    loadHandler,
    editMode,
    saveImage,
    isMiniImages = false,
    selected,
    showCorrect,
}) => {
    return (
        <div
            className={cn({
                [styles.container]: true,
                [styles.isMiniImages]: isMiniImages,
            })}
        >
            {tags.map((pictureData) => (
                <Picture
                    isMiniImages={isMiniImages}
                    key={pictureData.id}
                    pictureData={pictureData}
                    loadHandler={loadHandler}
                    editMode={editMode}
                    saveImage={saveImage}
                    selected={selected}
                    showCorrect={showCorrect}
                />
            ))}
        </div>
    );
};

PictureList.propTypes = {
    editMode: PropTypes.bool,
    isMiniImages: PropTypes.bool,
    loadHandler: PropTypes.func,
    saveImage: PropTypes.func,
    selected: PropTypes.any,
    showCorrect: PropTypes.bool,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            url: PropTypes.string,
        }),
    ),
};
