import {url} from "config";
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.sass";

import cn from "classnames";

const RavenHelper = ({message}) => {
    return (
        <div
            className={cn({
                [styles.helperWrapper]: true,
                "animate__animated animate__fadeIn animate__delay-2s": true,
            })}
        >
            <div className={styles.logoWrapper}>
                <img
                    className="animate__animated animate__slideInDown animate__slow animate__infinite"
                    src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                    alt="raven"
                />
            </div>
            <div className={styles.messageWrapper}>{message}</div>
        </div>
    );
};

RavenHelper.propTypes = {
    message: PropTypes.string,
};

export default RavenHelper;
