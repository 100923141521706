import {SymbolsAPI} from "entries/symbols/core/api/symbols";
import {DICTATION_EMPTY_SKIP} from "entries/constructors/dictation/views/pages/dictation-page";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {SelectSkipBox} from "entries/constructors/dictation/views/containers/select-skip-box/select-skip-box";
import {SpinnerPage} from "views/components/spinner";

export const SelectSkipBoxContainer = ({
    skipVariants,
    selectedSymbolIndex,
    selectedWordIndex,
    word,
    handleSaveVariant,
    skips,
    handleDeleteSkip,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState(null);

    // id символа с севера
    const [symbolId, setSymbolId] = useState(null);
    const [symbolGroups, setSymbolGroups] = useState([]);

    const handleSelectPosition = (index, variant) => {
        setIsLoading(true);
        let tempSymbol;
        let symbolName;
        SymbolsAPI.getSymbols()
            .then((response) => {
                if (variant[index] === DICTATION_EMPTY_SKIP) {
                    symbolName = "";
                } else {
                    symbolName = word[selectedSymbolIndex];
                }

                let result = response.data.filter((symbol) => symbol.name === symbolName);
                if (result.length > 0) {
                    tempSymbol = result[0].id;
                    setSymbolId(tempSymbol);
                }
                SymbolsAPI.getDictationGroupsForSymbol(tempSymbol).then((response) => {
                    setSymbolGroups(response.data);
                });
            })
            .finally(() => setIsLoading(false));

        setSelectedVariant(variant.join(""));
        setSelectedPosition(index);
    };

    useEffect(() => {
        setSymbolId(null);
        setSymbolGroups([]);
        setSelectedPosition(null);
    }, [selectedSymbolIndex]);

    if (isLoading) {
        return <SpinnerPage primary />;
    }

    return (
        <SelectSkipBox
            skipVariants={skipVariants}
            selectedPosition={selectedPosition}
            onSelectPosition={handleSelectPosition}
            symbolGroups={symbolGroups}
            onSaveVariant={handleSaveVariant}
            symbolId={symbolId}
            selectedWordIndex={selectedWordIndex}
            selectedSymbolIndex={selectedSymbolIndex}
            skips={skips}
            selectedVariant={selectedVariant}
            handleDeleteSkip={handleDeleteSkip}
        />
    );
};

SelectSkipBoxContainer.propTypes = {
    handleDeleteSkip: PropTypes.func,
    handleSaveVariant: PropTypes.func,
    selectedSymbolIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    selectedWordIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    skips: PropTypes.array,
    skipVariants: PropTypes.array,
    word: PropTypes.string,
};
