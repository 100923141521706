import cn from "classnames";
import {MDBTooltip} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";
import {SpinnerPage} from "views/components/spinner";

import styles from "./images-library-image.module.sass";

export const Image = (props) => {
    const [loaded, setLoaded] = React.useState(false);

    const handleLoad = React.useCallback(() => {
        setLoaded(true);
        props.onLoad && props.onLoad();
    }, [props.onLoad]);

    const handleError = React.useCallback(
        (error) => {
            process.env.NODE_ENV === "development" && console.log("image loading error", error);
            props.onLoadError && props.onLoadError();
        },
        [props.onLoadError],
    );

    console.log(props.withoutNameTip);

    const imageComponent = () => {
        return (
            <div>
                {props.src && (
                    <img
                        alt={props.alt || props.name}
                        className={cn({
                            [styles.img]: true,
                            [props.imageClassName]: !!props.imageClassName,
                        })}
                        src={props.src}
                        onLoad={handleLoad}
                        onError={handleError}
                    />
                )}
            </div>
        );
    };

    return (
        <div
            className={cn({
                [styles.imageWrapper]: true,
                [props.className]: !!props.className,
                [styles.loading]: !loaded,
            })}
            onClick={props.onClick || void 0}
        >
            {props.withoutNameTip ? (
                imageComponent()
            ) : (
                <MDBTooltip domElement placement="bottom">
                    {imageComponent()}
                    <span>
                        {!props.withoutNameTip && (
                            <span style={{fontSize: "1rem"}}>{props.name && props.name}</span>
                        )}
                    </span>
                </MDBTooltip>
            )}

            {!props.hideLoader && !loaded && (
                <div className={styles.spinner}>
                    <SpinnerPage primary />
                </div>
            )}
        </div>
    );
};

Image.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    hideLoader: PropTypes.bool,
    imageClassName: PropTypes.string,
    name: PropTypes.string,
    src: PropTypes.string,
    onClick: PropTypes.func,
    onLoad: PropTypes.func,
    onLoadError: PropTypes.func,
};
