import PropTypes from "prop-types";
import React from "react";

import cn from "classnames";

import styles from "./narrator.module.sass";

export const Narrator = (props) => {
    const {narrator, isEditMode, narratorSelect} = props;
    return (
        <div className={styles.container}>
            {
                <div
                    className={cn({
                        [styles.narratorFrame]: true,
                        [styles.border]: isEditMode,
                    })}
                    onClick={() => isEditMode && narratorSelect()}
                >
                    {narrator ? (
                        <img src={narrator.url} alt="Narrator" />
                    ) : (
                        isEditMode && <p className="link">Выберите персонажа</p>
                    )}
                </div>
            }
        </div>
    );
};

Narrator.propTypes = {
    isEditMode: PropTypes.bool,
    narrator: PropTypes.shape({
        url: PropTypes.string,
    }),
    narratorSelect: PropTypes.func,
};
