import React from "react";
import PropTypes from "prop-types";

import {TagsAPI} from "core/api/tags";

import {EMPTY_OPTION_VALUE} from "./empty-option";

import {TagSectionSelect} from "./tag-section-select";

const resolveTagsOptions = (responseData) =>
    responseData?.map((item) => ({
        value: item.id,
        label: item.name,
    }));

export const SecondLevelSectionSelect = (props) => {
    const {parentSectionId} = props;
    const controlRef = React.useRef();

    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const loadOptions = React.useCallback((value) => {
        if (value === EMPTY_OPTION_VALUE) {
            return TagsAPI.getSecondLevelTags();
        }
        return TagsAPI.getSecondLevelTagsBySectionThree(value);
    }, []);

    const resetValue = () => {
        controlRef.current.setState({value: null}, () => props.onChange(null));
    };

    React.useEffect(() => {
        setOptions([]);
        resetValue();

        if (parentSectionId) {
            setLoading(true);

            loadOptions(parentSectionId)
                .then((response) => {
                    setOptions(resolveTagsOptions(response.data));
                })
                .catch((error) => console.error("error", error))
                .finally(() => setLoading(false));
        }
    }, [parentSectionId]);

    return (
        <TagSectionSelect
            {...props}
            disabled={!props.parentSectionId}
            options={options}
            loading={loading}
            placeholder={"Выберите раздел"}
            controlRef={controlRef}
        />
    );
};

SecondLevelSectionSelect.propTypes = {
    defaultValue: PropTypes.number,
    parentSectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
};
