import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./col.module.sass";

export const Col = (props) => (
    <div
        className={cn({
            [styles.col]: true,
            [`col-${props.size}`]: true,
            [props.className]: !!props.className,
            [styles[`col${props.size}`]]: !!props.size,
        })}
    >
        {props.children}
    </div>
);

Col.propTypes = {
    size: PropTypes.number.isRequired,
    className: PropTypes.string,
};
