import {TOAST_SUCCESS_CONFIG, url} from "config";
import {PAIRS_TEST} from "config/constructor-names";
import {NARRATORS} from "entries/constructors/classic-test/views/components/properties-buttons";
import {CreateQuestion} from "entries/constructors/classic-test/views/containers/create-question";
import {TestParameters} from "entries/constructors/classic-test/views/containers/test-parameters";
import {useConstructor} from "entries/constructors/hooks/useConstructor";
import {PairsTestAPI} from "entries/constructors/pairs-test/core/api/pairs";
import {
    IMAGE_FROM_BANK_ANSWER_PAIRS,
    IMAGE_FROM_UPLOAD_ANSWER_PAIRS,
    TEXT_ANSWER_PAIRS,
} from "entries/constructors/pairs-test/core/types/pairs-test-types";
import {CreatePairsAnswers} from "entries/constructors/pairs-test/views/containers/create-pairs-answers/creater-pairs-answers";
import {MDBAlert} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {TEXT_TYPE} from "utils/answers-config";
import {ConstructorNavbar} from "views/components/constructor-navbar";
import {Col} from "views/components/layout/col";
import {SaveContent} from "views/components/layout/save-content/save-content";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {TestOptions} from "views/components/layout/test-options";
import {PropertyColumn} from "views/containers/property-column";
import TestSave from "views/containers/test-save/test-save";
import {useWizard} from "views2/conatiners/CreateTestWizard";

export const PairsTest = ({testData, isEditMode}) => {
    const loadAnswers = (answers, properties, correctAnswers) => {
        console.log(correctAnswers);
        let tempStatic = [];
        let tempDynamic = [];
        answers.map((answer) => {
            let answerForSave = {};
            answerForSave.id = answer.id;
            answerForSave.type = answer.objectType;

            if (answer.objectType === TEXT_ANSWER_PAIRS) {
                answerForSave.content = answer.text;
            } else if (answer.objectType === IMAGE_FROM_UPLOAD_ANSWER_PAIRS) {
                answerForSave.content = answer.answerImageQuestion.fileDownloadUri.slice(1);
            } else if (answer.objectType === IMAGE_FROM_BANK_ANSWER_PAIRS) {
                answerForSave.content = answer.answerImageBank.fileDownloadUri.slice(1);
            }

            if (answer.isLeftColumn) {
                answer.type = answer.objectType;
                tempStatic.push(answerForSave);
            } else {
                tempDynamic.push(answerForSave);
            }
        });

        if (correctAnswers) {
            let filteredStaticAnswers = [];
            correctAnswers.staticAnswers.forEach((staticId) => {
                let answer = tempStatic.find((staticAnswer) => staticAnswer.id === staticId);
                if (answer) {
                    filteredStaticAnswers.push(answer);
                }
            });

            let filteredDynamicAnswers = [];
            correctAnswers.dynamicAnswers.forEach((dynamicId) => {
                let answer = tempDynamic.find((dynamicAnswer) => dynamicAnswer.id === dynamicId);
                if (answer) {
                    filteredDynamicAnswers.push(answer);
                }
            });

            setStaticAnswers(filteredStaticAnswers);
            setDynamicAnswers(filteredDynamicAnswers);

            toast.success("Правильные ответы загружены", TOAST_SUCCESS_CONFIG);
        } else {
            setStaticAnswers(tempStatic);
            setDynamicAnswers(tempDynamic);
        }
    };

    const {
        testId,
        questionText,
        setQuestionText,
        questionImage,
        setQuestionImage,
        questionFormula,
        setQuestionFormula,
        background,
        setBackground,
        narrator,
        setNarrator,
        textAnswerSize,
        setTextAnswerSize,
        textTitleSize,
        setTextTitleSize,
        currentPropertyTab,
        setCurrentPropertyTab,
        tabNames,
        questionType,
        elementSize,
        setElementSize,
        questionAudio,
        setQuestionAudio,
    } = useConstructor({
        isEditMode,
        constructorName: PAIRS_TEST,
        testData,
        loadAnswersCallback: loadAnswers,
    });

    const {
        STEP,
        stepsOrder,
        currentStep,
        toNext,
        to,
        enabledSteps,
        enableStep,
        enableSteps,
        disableStep,
    } = useWizard();

    const [staticAnswers, setStaticAnswers] = useState([]);
    const [dynamicAnswers, setDynamicAnswers] = useState([]);

    useEffect(() => {
        if (isEditMode) {
            enableSteps([STEP.ANSWER, STEP.QUESTION, STEP.PARAMS]);
            return;
        }

        if (
            questionText &&
            staticAnswers.length >= 2 &&
            dynamicAnswers.length >= 2 &&
            staticAnswers.length === dynamicAnswers.length &&
            narrator
        ) {
            enableStep(STEP.ANSWER);
        } else {
            disableStep(STEP.ANSWER);
        }
    }, [questionText, staticAnswers, dynamicAnswers, narrator, isEditMode]);

    const onSelectNarrator = (narrator) => {
        setNarrator({
            id: narrator.id,
            url: `${url}${narrator.url}`,
        });
    };

    const updateStaticAnswers = (answers) => {
        setStaticAnswers(answers);
    };

    const updateDynamicAnswers = (answers) => {
        setDynamicAnswers(answers);
    };

    return (
        <div>
            <ConstructorNavbar
                tabs={stepsOrder}
                currentTab={currentStep}
                availableTabs={enabledSteps}
                onSelect={(tab) => to(tab)}
                tabNames={tabNames}
            />

            <Col size={12}>
                {currentStep === STEP.QUESTION || currentStep === STEP.ANSWER ? (
                    <Col size={8} className="d-flex justify-content-start">
                        <TestContent background={background}>
                            <TestConstructor>
                                <CreateQuestion
                                    isEditMode={currentStep === STEP.QUESTION}
                                    testHasNarrator
                                    narrator={narrator}
                                    questionId={testId}
                                    questionTitle={testData.title}
                                    textTitleSize={textTitleSize}
                                    questionImage={questionImage}
                                    questionText={questionText}
                                    questionFormula={questionFormula}
                                    updateQuestionImage={(image) => setQuestionImage(image)}
                                    updateQuestionText={(text) => setQuestionText(text)}
                                    updateQuestionFormula={(formula) => setQuestionFormula(formula)}
                                    selectPropertyNarrator={() => setCurrentPropertyTab(NARRATORS)}
                                    apiInstance={PairsTestAPI}
                                    questionAudio={questionAudio}
                                    updateQuestionAudio={setQuestionAudio}
                                />

                                <CreatePairsAnswers
                                    isEditMode={currentStep === STEP.QUESTION}
                                    textAnswerFontSize={textAnswerSize}
                                    dynamicAnswers={dynamicAnswers}
                                    staticAnswers={staticAnswers}
                                    updateDynamicAnswers={updateDynamicAnswers}
                                    updateStaticAnswers={updateStaticAnswers}
                                    questionId={testId}
                                    elementSize={elementSize}
                                />
                            </TestConstructor>
                        </TestContent>
                    </Col>
                ) : (
                    <TestParameters
                        testId={testId}
                        apiInstance={PairsTestAPI}
                        mode={questionType}
                        isEditMode={isEditMode}
                    />
                )}
                {(currentStep === STEP.QUESTION || currentStep === STEP.ANSWER) && (
                    <Col size={4}>
                        <TestOptions>
                            {currentStep === STEP.QUESTION && (
                                <PropertyColumn
                                    onSelectTab={(tab) => setCurrentPropertyTab(tab)}
                                    currentPropertyTab={currentPropertyTab}
                                    answerType={TEXT_TYPE}
                                    textAnswerSize={textAnswerSize}
                                    setTextAnswerSize={(size) =>
                                        setTextAnswerSize(Number.parseInt(size))
                                    }
                                    selectImage={(background) => setBackground(background)}
                                    selectNarrator={onSelectNarrator}
                                    textTitleSize={textTitleSize}
                                    setTextTitleSize={(size) =>
                                        setTextTitleSize(Number.parseInt(size))
                                    }
                                    isQuestionTextExist={!!questionText}
                                    withResizeElement
                                    currentElementSize={elementSize}
                                    selectElementSize={(size) =>
                                        setElementSize(Number.parseInt(size))
                                    }
                                    background={background}
                                    narrator={narrator}
                                    toolTipText="Создавайте правильные пары. При показе ученику они будут перемешаны случайным образом."
                                />
                            )}
                            {currentStep === STEP.ANSWER && (
                                <SaveContent>
                                    <h5>Проверьте пары</h5>
                                    <p>
                                        Удостовертесь, что пары составлены верно, а затем сохраните
                                        ответ.
                                    </p>
                                    <TestSave
                                        questionId={testId}
                                        apiInstance={PairsTestAPI}
                                        backgroundId={background.id}
                                        isTwoColumnMode={false}
                                        narratorId={narrator.id}
                                        sizeTitle={textTitleSize}
                                        sizeText={textAnswerSize}
                                        answerSize={elementSize}
                                        constructorName={PAIRS_TEST}
                                        pairsAnswers={{
                                            staticAnswers: staticAnswers,
                                            dynamicAnswers: dynamicAnswers,
                                        }}
                                        onSaveProperties={() => toNext()}
                                        mode={questionType}
                                    />

                                    <MDBAlert color="info" className="mt-5">
                                        <p>
                                            <i className="fa fa-info-circle" aria-hidden="true" />
                                            Если пары изначально составлены правильно, просто
                                            нажмите сохранить. Если пары составлены неверно, то
                                            двигайте пары в правой колонке, чтобы составить верный
                                            ответ. А затем нажмите кнопку сохранить.
                                        </p>
                                    </MDBAlert>
                                </SaveContent>
                            )}
                        </TestOptions>
                    </Col>
                )}
            </Col>
        </div>
    );
};

PairsTest.propTypes = {
    isEditMode: PropTypes.bool,
    testData: PropTypes.object,
};
