import {TagUtils} from "core/api/tag-utils";
import SchoolSubjectsSelect from "entries/school-subjects-select/school-subjects-select";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {SpinnerPage} from "views/components/spinner";

const SchoolSubjectsSelectContainer = ({handleSelectSubject, isMulti, selectedSubjects}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [subjects, setSubjects] = useState([]);

    const loadSchoolSubjects = () => {
        setIsLoading(true);
        return TagUtils.getSchoolSubjects()
            .then((response) => {
                let options = response.data.map((subject) => {
                    return {value: subject.id, label: subject.name};
                });
                setSubjects(options);
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        loadSchoolSubjects();
    }, []);

    if (isLoading) return <SpinnerPage primary />;

    return (
        <SchoolSubjectsSelect
            subjects={subjects}
            handleSelectSubject={handleSelectSubject}
            isMulti={isMulti}
            selectedSubjects={selectedSubjects}
        />
    );
};

SchoolSubjectsSelectContainer.propTypes = {
    handleSelectSubject: PropTypes.func.isRequired,
    isMulti: PropTypes.bool,
    selectedSubjects: PropTypes.any,
};

export default SchoolSubjectsSelectContainer;
