export const WORD_SKIP_TEST_ROUTES = {
    ROOT: "/word-skip",
    CREATE_TEST: "/word-skip/create-test",
    CONSTRUCTOR: "/word-skip/constructor",
    EDITING: "/word-skip/editing/:id",
};

export const WORD_SKIP_ADDITIONAL_TEST_ROUTES = {
    ROOT: "/word-skip-additional",
    CREATE_TEST: "/word-skip-additional/create-test",
    CONSTRUCTOR: "/word-skip-additional/constructor",
    EDITING: "/word-skip-additional/editing/:id",
};

export const SENTENCES_SKIP_TEST_ROUTES = {
    ROOT: "/sentences-skip-test",
    CREATE_TEST: "/sentences-skip-test/create-test",
    CONSTRUCTOR: "/sentences-skip-test/constructor",
    EDITING: "/sentences-skip-test/editing/:id",
};
