import {useEffect} from "react";
import {useHistory} from "react-router-dom";

declare global {
    interface Window {
        ym: any;
    }
}

const ServiceMetrics = () => {
    const history = useHistory();

    useEffect(() => {
        history.listen((location: any) => {
            if (window.ym && location) {
                window.ym(86877424, "hit", location.pathname);
            }
        });
    }, [history]);

    return null;
};

export default ServiceMetrics;
