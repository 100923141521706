import React from "react";
import {TOAST_AUTO_CLOSE_TIME} from "../../config";
import {ToastContainer, Zoom} from "react-toastify";

const ServiceToasts = () => {
    return (
        <ToastContainer
            position="bottom-left"
            autoClose={TOAST_AUTO_CLOSE_TIME}
            transition={Zoom}
        />
    );
};

export default ServiceToasts;
