import {makeAutoObservable} from "mobx";
import {ReactElement} from "react";

export class AppStore {
    public tabs: any = null;
    public isAuthorized: boolean = false;
    public isAppInitialized: boolean = false;
    public activeModal: any = null;
    public modalQueue: ReactElement[] = [];
    public websocketMessages: any[] = [];
    public events: any[] = [];
    public isMobile: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    public updateIsMobile = (width: number) => {
        this.isMobile = width <= 750;
    };

    public addModalToQueue = (component: ReactElement): any => {
        this.modalQueue.push(component);

        if (!this.activeModal) {
            this.activeModal = component;
        }
    };

    public closeActiveModal = () => {
        if (!this.activeModal) {
            return;
        }

        this.modalQueue = this.modalQueue.filter(
            (component: ReactElement) => component.key !== this.activeModal.key,
        );

        if (this.modalQueue.length) {
            this.activeModal = this.modalQueue[0];
        } else {
            this.activeModal = null;
        }
    };
}
