import Hints from "entries/hints/hints";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import {WordSkipPlayer} from "entries/player/views/task-players/word-skip-player/word-skip-player";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export const WordSkipPlayerContainer = ({
    taskId,
    isSentences,
    onSelectCorrectAnswer,
    questionApi,
    getInfoAboutSet,
    playerMode,
    answerIsLoading,
    setAnswerIsLoading,
}) => {
    const [question, setQuestion] = useState(null);
    const [answers, setAnswers] = useState(null);
    const [properties, setProperties] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState([]);

    let {id} = useParams();

    useEffect(() => {
        setAnswers(null);
        setQuestion(null);
        setProperties(null);
        setSelectedAnswers([]);
    }, [taskId]);

    useEffect(() => {
        getData();
    }, [taskId]);

    const getData = async () => {
        setAnswerIsLoading(true);
        let data = undefined;
        await questionApi(taskId, id)
            .then((response) => {
                if (playerMode === PlayerV2Modes.TOURNAMENT) {
                    data = response.data[0].question;
                } else {
                    data = response.data;
                    getInfoAboutSet();
                }
            })
            .catch(() => console.log("load data error"))
            .finally(() => setAnswerIsLoading(false));

        if (!data) {
            return;
        }

        let question;
        let answer;
        let properties;
        if (isSentences) {
            question = data.wordSkipClause;
            answer = data.wordSkipClauseAnswerVariants;
            properties = data.questionProperties;
        } else {
            question = data.wordSkip;
            answer = data.wordSkipAnswerVariants;
            properties = data.questionProperties;
        }
        setQuestion(question);
        setAnswers(answer);
        setProperties(properties);
    };

    const deleteSelectedAnswer = (index) => {
        let answerForDelete = selectedAnswers.find((item) => item.position == index);
        let selectedAnswersClone = selectedAnswers;
        selectedAnswersClone = selectedAnswersClone.filter((item) => item.position != index);
        setSelectedAnswers(selectedAnswersClone);
        setAnswers([...answers, answerForDelete]);
    };

    const dragEndHandle = (data) => {
        if (data.destination) {
            let destinationId = data.destination.droppableId;
            let sourceId = data.source.droppableId;
            let draggableId = data.draggableId;

            if (destinationId == sourceId) {
                return;
            }

            let word = answers.find((item) => item.id == draggableId);
            setSelectedAnswers([
                ...selectedAnswers,
                {position: destinationId, words: word.words, id: word.id},
            ]);

            let answersClone = answers;
            answersClone = answersClone.filter((item) => item.id != draggableId);
            setAnswers(answersClone);
        }
    };

    const fillCorrectAnswers = (answersForFill) => {
        setSelectedAnswers(answersForFill);
        let selectedAnswerIds = answersForFill.map((answer) => answer.id);
        let updatedAnswers = answers.filter((answer) => !selectedAnswerIds.includes(answer.id));
        setAnswers(updatedAnswers);
    };

    return (
        <React.Fragment>
            {!answerIsLoading && (
                <React.Fragment>
                    {playerMode === PlayerV2Modes.ACADEMY && (
                        <Hints question={question} onBuyCorrectAnswer={fillCorrectAnswers} />
                    )}

                    {question && answers && properties && (
                        <WordSkipPlayer
                            question={question}
                            answers={answers}
                            properties={properties}
                            selectedAnswers={selectedAnswers}
                            dragEndHandle={dragEndHandle}
                            deleteSelectedAnswer={deleteSelectedAnswer}
                            onSelectCorrectAnswer={onSelectCorrectAnswer}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

WordSkipPlayerContainer.propTypes = {
    getInfoAboutSet: PropTypes.func,
    isSentences: PropTypes.bool,
    playerMode: PropTypes.string,
    questionApi: PropTypes.func,
    taskId: PropTypes.number,
    onSelectCorrectAnswer: PropTypes.func,
    answerIsLoading: PropTypes.bool,
    setAnswerIsLoading: PropTypes.func,
};
