import {Request} from "../../core/utils/request";

export const RatingsAPI = {
    getAcademyRatings: (size: number = 20, page: number = 1) => {
        return Request.get(`/place/academy?size=${size}&page=${page}`);
    },
    getAcademyRatingsByMonth: (size: number = 20, page: number = 1) => {
        return Request.get(`/place/academy/by-month?size=${size}&page=${page}`);
    },
    getAcademyRatingsByWeek: (size: number = 20, page: number = 1) => {
        return Request.get(`/place/academy/by-week?size=${size}&page=${page}`);
    },
    getUserAcademyRating: (userId: number) => {
        return Request.get(`/place/academy/user/${userId}`);
    },
    getUserAcademyRatingByMonth: (userId: number) => {
        return Request.get(`/place/academy/by-month/user/${userId}`);
    },
    getUserAcademyRatingByWeek: (userId: number) => {
        return Request.get(`/place/academy/by-week/user/${userId}`);
    },

    getTournamentRatings: (size: number = 20, page: number = 1) => {
        return Request.get(`/place/tournament?size=${size}&page=${page}`);
    },
    getTournamentRatingsByMonth: (size: number = 20, page: number = 1) => {
        return Request.get(`/place/tournament/by-month?size=${size}&page=${page}`);
    },
    getTournamentRatingsByWeek: (size: number = 20, page: number = 1) => {
        return Request.get(`/place/tournament/by-week?size=${size}&page=${page}`);
    },
    getUserTournamentRating: (userId: number) => {
        return Request.get(`/place/tournament/user/${userId}`);
    },
    getUserTournamentRatingByMonth: (userId: number) => {
        return Request.get(`/place/tournament/by-month/user/${userId}`);
    },
    getUserTournamentRatingByWeek: (userId: number) => {
        return Request.get(`/place/tournament/by-week/user/${userId}`);
    },
    getSchoolAcademy: (size: number = 20, page: number = 1) => {
        return Request.get(`/place/school/academy?size=${size}&page=${page}`);
    },
    getSchoolTournament: (size: number = 20, page: number = 1) => {
        return Request.get(`/place/school/tournament?size=${size}&page=${page}`);
    },
    getUserSchoolAcademyRating: (userId: number) => {
        return Request.get(`/place/user/${userId}/school/academy`);
    },
    getUserSchoolTournamentRating: (userId: number) => {
        return Request.get(`/place/user/${userId}/school/tournament`);
    },
};
