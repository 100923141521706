import cn from "classnames";

import {IMAGE_EXTENSIONS, MAX_FILE_SIZE, TOAST_SUCCESS_CONFIG} from "config";
import {ADMIN_PANEL_ROUTES} from "entries/admin-panel/core/routes";
import {MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import ImagesUploader from "react-images-upload";
import {Link, Prompt, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import styles from "./images-library.module.sass";

// const getItemId = (file) => `${file.name}${file.lastModified}${file.size}`;

export const ImagesUpload = (props) => {
    let history = useHistory();
    const {isForLibrary = true} = props;
    const {t} = useTranslation("images_manager");

    const [files, setFiles] = React.useState(null);
    const [name, setName] = React.useState("");
    const [saving, setSaving] = React.useState(false);

    const handleChange = (items) => {
        items && items.length && setFiles(items);
    };

    // const handleNameChange = (id, fileName) => setName(fileName);

    const resetFile = () => {
        setFiles(null);
        setName("");
    };

    const handleSubmit = (event) => {
        event.preventDefault && event.preventDefault();

        if (!props.tagSelected && isForLibrary) {
            toast.error(t("Тэг не выбран"));

            return void 0;
        }

        setSaving(true);

        return props
            .onSubmit({
                files,
                name,
            })
            .then(() => {
                resetFile();
                toast.success("Картинки загружены", TOAST_SUCCESS_CONFIG);
            })
            .finally(() => {
                setSaving(false);
                history.push(ADMIN_PANEL_ROUTES.IMAGES.ROOT);
            });
    };

    const fileIsSelected = !!files;

    return (
        <div>
            <Prompt
                when={fileIsSelected || !!name}
                message={t("Уверены что хотите перейти в каталог? Все изменения будут потеряны.")}
            />
            <div
                className={cn({
                    [styles.toLibraryLink]: true,
                    row: true,
                })}
            >
                {isForLibrary && (
                    <Link className="col-12" to={ADMIN_PANEL_ROUTES.IMAGES.ROOT}>
                        {t("Перейти в каталог")}
                    </Link>
                )}
            </div>
            <form onSubmit={handleSubmit}>
                <ImagesUploader
                    buttonText={t("Выбрать")}
                    disabled={saving}
                    fileSizeError={t("большой размер файла")}
                    fileTypeError={t("неподдерживаемый формат файла")}
                    imgExtension={IMAGE_EXTENSIONS}
                    maxFileSize={MAX_FILE_SIZE}
                    withIcon={false}
                    withLabel={false}
                    withPreview
                    onChange={handleChange}
                />

                <div className="d-flex justify-content-center">
                    <MDBBtn color="info" type="submit" disabled={saving || !files}>
                        Загрузить
                    </MDBBtn>
                </div>
            </form>
        </div>
    );
};

ImagesUpload.propTypes = {
    isForLibrary: PropTypes.bool,
    tagSelected: PropTypes.bool,
    onSubmit: PropTypes.func,
};
