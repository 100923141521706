import React from "react";
import {Redirect, Route} from "react-router-dom";
import PropTypes from "prop-types";

import {auth} from "core/utils/auth";

export const hasAccess = (keycloak, roles) => {
    if (keycloak && roles) {
        return roles.some((role) => {
            const realm = keycloak.hasRealmRole(role);
            const resource = keycloak.hasResourceRole(role);
            return realm || resource;
        });
    }
    return false;
};

export function PrivateRoute({roles, children, ...rest}) {
    const {keycloak} = auth;

    return (
        <Route
            {...rest}
            render={() =>
                hasAccess(keycloak, roles) ? children : <Redirect to={{pathname: "/"}} />
            }
        />
    );
}

PrivateRoute.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};
