import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./switch.module.sass";

export const Switch = props => {
return (
    <div className={cn({
        "switch": true,
        [styles.switch]: true,
        [props.className]: !!props.className
    })}>
        <label className="text-dark">
            <input type="checkbox" disabled={props.disabled} defaultChecked={props.defaultChecked} checked={props.checked} onChange={props.onChange}/>
            <span className={styles.lever}/>
            {props.children}
        </label>
    </div>
)
};

Switch.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
}
