import {MDBBtn} from "mdbreact";
import React from "react";
import styles from "./styles.module.sass";

const ArticleControlHeader = ({handleCreateClick}) => {
    return (
        <div className={styles.articleHeader}>
            <p className={styles.title}>Статьи</p>
            <MDBBtn color="success" onClick={handleCreateClick}>
                Новая статья
            </MDBBtn>
        </div>
    );
};

export default ArticleControlHeader;
