const RANDOM_MESSAGES = [
    "Интересная тема. Полетели!",
    "М-м-м, какая интересная тема!",
    "Поспеши! Таймер уже запущен.",
    "Новая тема. Время пошло!",
    "Желаю удачи в погоне за сундуком!",
    "Полетели за находками!",
    "Новая тема – новые крутые находки!",
    "Эту тему я очень люблю!",
    "Это моя любимая тема!",
    "Не теряй ни секунды. Полетели!",
    "Решай внимательно, чтобы не потерять баллы! ",
    "Ура! Новые задания – новые сундуки! ",
    "Награда уже ждёт тебя! Полетели!",
    "Посмотрим, как ты знаешь эту тему!",
    "Сейчас узнаем, что ты можешь!",
    "Я в тебя верю! Решай и ничего не бойся!",
    "Правильные решения приносят крутые призы! ",
    "Не забывай про скорость, она приносит баллы!",
    "Эти задания я составляла сама!",
    "Желаю больших успехов!",
    "Попробуй не ошибиться ни разу!",
    "Все задания без ошибки = бонус к рейтингу!",
    "Решай с первой попытки и повышай рейтинг!",
    "На старт! Внимание! Полетели!",
    "Начнём мозговой штурм?",
    "Поскорее начнём! Приз ждёт на финише!",
    "Не забывай о времени! Поторопись!",
    "Эти задания под силу не каждому!",
    "Не каждый осилит эти задания!",
    "Пользуйся подсказками, если не знаешь ответ!",
    "Попробуй победить эти задания!",
    "Победи эти задания! Я в тебя верю!",
    "Верю в твой успех! Не подведи!",
    "Каждая задача важна!",
    "Сможешь решить без единой ошибки?",
    "Разогревай крылья, нас ждут новые задания!",
    "Чем сложней – тем интересней!",
    "Мне очень интересно, как ты знаешь эту тему!",
    "Удачи тебе, большой мозг!",
    "Тут всё не так просто, как кажется!",
    "Не забывай проверять ответы!",
    "Даже не думай звать на помощь!",
    "Посмотрим, сколько ты сможешь набрать!",
    "У тебя всё получится!",
    "Тебя уже ждёт подарок! Скорее начинай!",
    "Посмотрим, что ты умеешь!",
    "В этой теме есть очень сложные задания!",
    "Чем быстрее решаешь – тем выше баллы!",
    "Сложные задания стоят больше рейтинга!",
    "Новый вызов! Попробуем без ошибок?",
    "Попробуем решить быстрее других?",
    "Попробуй решить всё с первой попытки!",
    "Задания уже готовы! А ты?",
    "Ну что же, не подведи!",
    "Это будет весело! Полетели!",
    "Поспеши, пока все сундуки не разобрали!",
    "А за старания дают больше баллов!",
    "Чем сложнее задания, тем выше рейтинг!",
    "Главное – решать задания с первой попытки!",
    "Соберись! Потребуются все знания!",
    "Используй силу своего ума!",
    "Да прибудет с тобой сила!",
    "Проверь силу своих знаний!",
    "Срочно вспоминай эту тему!",
    "Готовность номер один! Полетели!",
    "Рада видеть тебя!",
    "Так классно, что ты учишься со мной!",
    "Очередная порция увлекательных заданий!",
    "О! Эта тема тебе понравится!",
    "Задания загружены! Полетели!",
    "Используй мозг на максимум!",
    "Так-так, какая тема на этот раз?",
    "Ты справишься! Начинай!",
    "Сколько баллов ты сможешь набрать?",
    "Это не будет легко! Но ты справишься!",
    "Сейчас прокачаем твой мозг!",
    "Пора показать всем силу твоего ума!",
    "Привет! Прокачаем рейтинг?",
    "Ну-с, начнём! Подарки уже разложены по сундукам!",
	"А я уже знаю, что выпадет тебе в сундуке!",
	"В моей коллекции больше ста вещей! А в твоей?",
	"Проходи хотя бы две темы в день!",
];

export const getRandomInitializeMessage = () => {
    return RANDOM_MESSAGES[Math.floor(Math.random() * RANDOM_MESSAGES.length)];
};
