import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";
import {SLIDER_IMAGE_URLS} from "views/pages/about/utils/slider-image-urls";

const TournamentFightSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${SLIDER_IMAGE_URLS.TOURNAMENT.FIGHT})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    return <div className={sliders.wrapper} style={backgroundStyles} />;
};

TournamentFightSlide.propTypes = {};

export default TournamentFightSlide;
