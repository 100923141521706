import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

const SpinnerWithText = ({text, className, color}) => {
    return (
        <div
            className={cn({
                "d-flex align-items-center": true,
                [className]: !!className,
            })}
        >
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <span className="title ml-3" style={{color: color ? color : "#33b5e5"}}>
                {text}
            </span>
        </div>
    );
};

SpinnerWithText.propTypes = {
    className: PropTypes.string,
    color: PropTypes.any,
    text: PropTypes.string,
};

export default SpinnerWithText;
