import React from "react";
import PropTypes from "prop-types";

import {TagsAPI} from "core/api/tags";

import {TagSectionSelect} from "./tag-section-select";

const resolveTagsOptions = (responseData) =>
    responseData?.map((item) => ({
        value: item.id,
        label: item.name,
    }));

export const ThirdLevelSectionSelect = (props) => {
    const [loading, setLoading] = React.useState(true);
    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        TagsAPI.getThirdLevelTags()
            .then((response) => {
                const nextOptions = resolveTagsOptions(response.data);

                setOptions(nextOptions);
            })
            .catch((error) => console.error("error", error))
            .finally(() => setLoading(false));
    }, []);

    return (
        <TagSectionSelect
            loading={loading}
            options={options}
            {...props}
            placeholder={"Выберите раздел"}
        />
    );
};

ThirdLevelSectionSelect.propTypes = {
    defaultValue: PropTypes.number,
    emptyOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hideEmptyOption: PropTypes.bool,
    onChange: PropTypes.func,
};
