import React, {useState} from "react";
import {toast} from "react-toastify";
import {AdminPanelSubsAPI} from "../ admin-panel-subs-api";
import AdminPanelFormCreator, {
    EFormCreatorTypes,
} from "../../admin-panel-form-creator/admin-panel-form-creator";
import AdminPanelQueryResultLayout from "../../admin-panel-query-result-layout/admin-panel-query-result-layout";

const AdminPanelSubsByUser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState<any[] | null>(null);

    const handleGetSubs = (formData: any) => {
        let {userId} = formData;
        if (!userId || !userId.trim()) {
            toast.error("Заполните поля");
            return;
        }

        setIsLoading(true);
        setSubscriptions(null);
        AdminPanelSubsAPI.getSubscriptionsByUserId(userId)
            .then((res) => setSubscriptions(res.data))
            .catch((err) => toast.error(err.response.data.message))
            .finally(() => setIsLoading(false));
    };

    return (
        <React.Fragment>
            <AdminPanelFormCreator
                handleClick={handleGetSubs}
                bannerText="Подписки"
                formConstructor={[
                    {
                        name: "userId",
                        labelText: "Введите ID пользователя",
                        type: EFormCreatorTypes.TEXT,
                    },
                    {
                        name: "Получить",
                        type: EFormCreatorTypes.BUTTON,
                        color: "success",
                    },
                ]}
                isLoading={isLoading}
            />

            {subscriptions && (
                <AdminPanelQueryResultLayout>
                    <React.Fragment>
                        <h4 className="mt-3">Подписки пользователя: {subscriptions.length}</h4>
                        <hr />
                        {subscriptions.map((sub, index) => (
                            <div key={index}>
                                <p>
                                    Название: {sub.name} <br /> Класс: {sub.classTag?.name} <br />{" "}
                                    Длительность: {sub.subscriptionType}
                                </p>
                            </div>
                        ))}
                    </React.Fragment>
                </AdminPanelQueryResultLayout>
            )}
        </React.Fragment>
    );
};

export default AdminPanelSubsByUser;
