import React from "react";
import styles from "./styles.module.sass";
import {MDBBtn} from "mdbreact";
import cn from "classnames";
import Events from "../../events/events/events";

interface IProps {
    toggle: () => void;
}

const EventsModal = ({toggle}: IProps) => {
    return (
        <div
            className={cn({
                [styles.containerWrapper]: true,
            })}
        >
            <div className={styles.content}>
                <Events />
            </div>

            <div className={styles.footer}>
                <MDBBtn color="grey" onClick={toggle}>
                    Закрыть
                </MDBBtn>
            </div>
        </div>
    );
};

export default EventsModal;
