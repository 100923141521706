import cn from "classnames";
import {url} from "config";
import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";
import styles from "views/pages/about/sliders/teacher-class-slide/styles.module.sass";

const AcademyForAllSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${url + "data/image-bank/2fa278df-2fd3-4802-9ccd-05d8680aca28.png"})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            <div className={sliders.content}>
                <div
                    className={cn({
                        [sliders.about]: true,
                        "mt-5": true,
                    })}
                >
                    <p
                        className={cn({
                            [sliders.title]: true,
                            [styles.title]: true,
                            "text-center": true,
                        })}
                    >
                        Доступна с любой планеты!
                    </p>
                    <p
                        className={cn({
                            [sliders.description]: true,
                            [styles.description]: true,
                            "text-center": true,
                            "w-100": true,
                        })}
                    >
                        Закрепляйте школьные знания, открывайте новое <br /> и тренируйте смекалку
                        вместе с Умной Вороной!
                    </p>
                </div>
            </div>
        </div>
    );
};

AcademyForAllSlide.propTypes = {};

export default AcademyForAllSlide;
