import {CheckboxIcon} from "components/icons";
import {MDBCol, MDBRow} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";
import noLogo from "assets/images/static/no-logo.svg";
import styles from "./event-item.module.sass";
import cn from "classnames";

const EventItem = ({
    title,
    description,
    isChecked,
    onCheck,
    id,
    rating,
    className,
    withLogo = true,
    imageUrl,
    coin,
}) => {
    return (
        <MDBRow
            className={cn({
                [styles.event]: true,
                "grey lighten-4": isChecked,
                [className]: !!className,
            })}
        >
            {onCheck && (
                <MDBCol className={styles.checkBox} size="1">
                    <CheckboxIcon checked={isChecked} onClick={() => onCheck(id)} />
                </MDBCol>
            )}

            {withLogo && (
                <div className={styles.logo}>
                    <img
                        src={imageUrl ? imageUrl : noLogo}
                        className={styles.logo__image}
                        alt="logo"
                    />
                </div>
            )}

            <MDBCol className={styles.content}>
                <MDBRow>
                    <div className={styles.event__title}>
                        <p>{title}</p>
                        {!!rating && (
                            <div className={styles.event__rating}>
                                <i className="fas fa-star" />
                                <p>{rating}</p>
                            </div>
                        )}
                        {!!coin && (
                            <div className={styles.event__coin}>
                                <img
                                    src={require("../../../assets/images/static/coin.svg")}
                                    alt="coin"
                                />{" "}
                                <p> {coin}</p>
                            </div>
                        )}
                    </div>
                </MDBRow>
                <MDBRow>
                    <p className={styles.event__description}>{description}</p>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    );
};

EventItem.propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    imageUrl: PropTypes.string,
    isChecked: PropTypes.bool,
    rating: PropTypes.any,
    title: PropTypes.string,
    withLogo: PropTypes.bool,
    onCheck: PropTypes.func,
};

export default EventItem;
