import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import Carousel from "../../../entries/carousel/carousel";
import {ABOUT_ENGINE_DATA} from "../about/sliders/about-engines/utils/about-engines-data";
import cn from "classnames";
import {url} from "../../../config";
import {Helmet} from "react-helmet";
import {MDBBtn} from "mdbreact";
import {auth} from "../../../core/utils/auth";
import ClassRequestFormModal from "../../../entries/class-request-form-modal/class-request-form-modal";

const MyClassPage = () => {
    const [tabs] = useState(ABOUT_ENGINE_DATA);
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [requestModalIsOpen, setRequestModalIsOpen] = useState(false);
    const {keycloak} = auth;

    useEffect(() => {
        document.body.style.background = "";
    }, []);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>

            <ClassRequestFormModal
                requestModalIsOpen={requestModalIsOpen}
                setRequestModalIsOpen={setRequestModalIsOpen}
            />

            <div className={styles.container}>
                <div className={styles.twoSideContainer}>
                    <div className={styles.containerLeft}>
                        <h1 className={styles.title}>Занимайтесь бесплатно весь учебный год!</h1>
                        <h2 className={styles.title}>Ученикам и их родителям</h2>
                        <p className="mb-2">
                            Умная Ворона открывает доступ ко всем заданиям совершенно бесплатно на
                            весь учебный год! 20 000 интерактивных заданий по школьной программе, а
                            также еженедельные турниры среди школьников!
                        </p>
                        <a
                            style={{fontSize: "18px"}}
                            className="link"
                            href="https://umnayavorona.ru/main/academy"
                            target="_blank"
                        >
                            Как работает Академия
                        </a>
                        <br />
                        <a
                            style={{fontSize: "18px"}}
                            className="link"
                            href="https://umnayavorona.ru/main/tourney"
                            target="_blank"
                        >
                            Что такое Турниры
                        </a>

                        <p style={{marginTop: 20}}>
                            Просто{" "}
                            <a
                                style={{fontSize: "18px"}}
                                className="link"
                                href="https://umnayavorona.ru"
                                target="_blank"
                            >
                                зарегистрируйтесь
                            </a>{" "}
                            и решайте увлекательные задания Академии Умной Вороны без ограничений.
                            Зарабатывайте рейтинг и участвуйте в еженедельных Турнирах с другими
                            школьниками со всей России.
                        </p>
                    </div>

                    <div className={styles.containerRight}>
                        <img
                            src={require("../../../assets/sliders/teacher-free/teacher-free.svg")}
                        />
                    </div>
                </div>

                <h2 className={styles.title}>Учителям</h2>
                <p className="mb-2">
                    Приглашаем вас подключить свой класс к Академии Умной Вороны! Закрепляйте
                    школьную программу увлекательными домашними заданиями и повышайте интерес детей
                    к учёбе.
                </p>
                <a
                    style={{fontSize: "18px"}}
                    className="link"
                    href="https://umnayavorona.ru/main/teacher"
                    target="_blank"
                >
                    Презентация для учителей
                </a>
                <br />

                <a
                    style={{fontSize: "18px"}}
                    className="link"
                    href="https://umnayavorona.ru/publications/kak-podkluchit-dobavit-uchenikov-k-uchitelu-na-umnoi-vorone"
                    target="_blank"
                >
                    Как зарегистрировать и прикрепить учеников к учителю
                </a>
                <br />

                <a
                    style={{fontSize: "18px"}}
                    className="link"
                    href="https://umnayavorona.ru/publications/kak-otpravlyat-zadaniya-uchenikam"
                    target="_blank"
                >
                    Как отправлять задания ученикам
                </a>

                <h2 className={styles.title}>Как выглядят задания Академии?</h2>
                <div className={styles.carouselWrapper}>
                    <div className={styles.tabs}>
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={cn({
                                    [styles.tab]: true,
                                    [styles.active]: tab.id === activeTab.id,
                                })}
                                onClick={() => setActiveTab(tab)}
                            >
                                <img src={tab.logo} alt="logo" />
                            </div>
                        ))}
                    </div>
                    <Carousel slides={activeTab.slides} />
                </div>
                <h2 className={styles.title}>Как отправлять задания ученикам?</h2>
                <p>
                    1. Выберите в Академии предмет и класс, а затем в нужном тематическом комплекте
                    нажмите на кнопку «Отправить ученикам».
                </p>
                <img
                    src={url + "data/image-bank/578964fc-4371-4d47-b74d-f1db2395ee77.png"}
                    className={styles.image}
                    alt="slide"
                />
                <p>2. Выберите класс или группу учеников.</p>
                <img
                    src={url + "data/image-bank/deb9a43a-f3b0-4578-91fd-c1d94321f901.png"}
                    className={styles.image}
                    alt="slide"
                />
                <p>
                    Нажмите на кнопку нужного класса, и ваши задания мгновенно отправятся в личные
                    кабинеты учеников.
                </p>
                <h2 className={styles.title}>Как ученики увидят ваши задания?</h2>
                <p>В разделе «Задания» ученики увидят своё домашнее задание.</p>
                <img
                    src={url + "data/image-bank/221880e1-9845-47b5-b7e1-d75a684fadae.png"}
                    className={styles.image}
                    alt="slide"
                />
                <p>Нажмут на кнопку «Начать решение» и приступят к решению задач.</p>
                <img
                    src={url + "data/image-bank/be0b54aa-c3d8-4db4-bf45-67d817230a42.png"}
                    className={styles.image}
                    alt="slide"
                />
                <h2 className={styles.title}>Как просматривать результаты?</h2>
                <p>
                    В личном кабинете, в разделе «Ученики», подразделе «Результаты» вы найдете
                    удобную таблицу, в которой будут представлены результаты всех учеников,
                    получивших домашнее задание.
                </p>
                <img
                    src={url + "data/image-bank/96a8bc20-b196-4523-b4b8-a8e2efb9b6e4.png"}
                    className={styles.image}
                    alt="slide"
                />
                <ul>
                    <li>Решено или не решено было задание</li>
                    <li>Сколько попыток потребовалось</li>
                    <li>Сколько времени потратил ученик на выполнение задания</li>
                </ul>
                <p>Просто и удобно!</p>
                <h2 className={styles.title}>Сколько заданий я смогу отправлять ученикам?</h2>
                <p>
                    Сколько захотите. Вам будут доступны все задания и все тематические комплекты
                    Академии. Можете использовать их в любом количестве.
                </p>
                {!keycloak.authenticated && (
                    <MDBBtn
                        className={styles.regButton}
                        color="success"
                        onClick={() => keycloak.login()}
                    >
                        Зарегистрироваться
                    </MDBBtn>
                )}
            </div>
        </>
    );
};

export default MyClassPage;
