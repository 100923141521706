export const APPLICATION_TITLE_SUFFIX = "";

export const TOAST_AUTO_CLOSE_TIME = 4000;
export const TOAST_ERROR_AUTO_CLOSE_TIME = 5000;

export const TOAST_ERROR_CONFIG = {
    position: "bottom-left",
    autoClose: TOAST_ERROR_AUTO_CLOSE_TIME,
    hideProgressBar: true,
    closeOnClick: true,
};

export const TOAST_SUCCESS_CONFIG = {
    position: "bottom-left",
    autoClose: TOAST_AUTO_CLOSE_TIME,
    hideProgressBar: true,
    closeOnClick: true,
};

export const TOAST_INFO_CONFIG = {
    position: "bottom-left",
    autoClose: TOAST_ERROR_AUTO_CLOSE_TIME,
    hideProgressBar: true,
    closeOnClick: true,
};

export const IMAGE_EXTENSIONS = [".jpg", ".jpeg", ".svg", ".png"];
export const MAX_FILE_SIZE = 2 * 5242880;
