import {
    DICTATION_EMPTY_DETECT,
    DICTATION_EMPTY_SKIP,
    DICTATION_SKIP_DETECT,
    DICTATION_SKIP_ID,
} from "entries/constructors/dictation/views/pages/dictation-page";
import {MDBBtn} from "mdbreact";
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "entries/constructors/dictation/views/containers/select-skip-box/select-skip-box.module.sass";

export const SelectSkipBox = ({
    skipVariants,
    onSelectPosition,
    symbolGroups,
    onSaveVariant,
    symbolId,
    selectedPosition,
    selectedSymbolIndex,
    selectedWordIndex,
    skips,
    handleDeleteSkip,
    selectedVariant,
}) => {
    let existSkips = skips.filter(
        (skip) => skip.wordIndex === selectedWordIndex && skip.symbolIndex === selectedSymbolIndex,
    );

    if (existSkips.length > 0) {
        return (
            <div>
                <p
                    className={cn({
                        [styles.title]: true,
                        title: true,
                    })}
                >
                    В этом месте ученику будут предложены
                </p>
                <div className={styles.skipContainer}>
                    {existSkips[0].symbolGroup.symbols.map((symbolName, index) => (
                        <span className={styles.card} key={index}>
                            {symbolName.altName}
                        </span>
                    ))}
                </div>
                <MDBBtn
                    color="info"
                    size="md"
                    block
                    className="mb-2"
                    onClick={() => handleDeleteSkip(existSkips[0])}
                >
                    Удалить пропуск
                </MDBBtn>
            </div>
        );
    }

    return (
        <div>
            {selectedPosition !== null ? (
                <div>
                    <p className="title">Какие варианты будут предложены ученику?</p>
                    {symbolGroups.length > 0 ? (
                        symbolGroups.map((symbolGroup, index) => {
                            let symbolLabel = "";
                            symbolGroup.symbols.map((symbol) => {
                                symbolLabel += `[ ${symbol.altName} ] `;
                            });
                            return (
                                <MDBBtn
                                    key={index}
                                    color="info"
                                    size="md"
                                    block
                                    className="mb-2 mt-4"
                                    onClick={() =>
                                        onSaveVariant(
                                            symbolId,
                                            symbolGroup,
                                            selectedWordIndex,
                                            selectedPosition,
                                            selectedVariant,
                                        )
                                    }
                                >
                                    <p className={styles.buttonText}>{symbolLabel}</p>
                                </MDBBtn>
                            );
                        })
                    ) : (
                        <p className={styles.missText}>Варианты не найдены</p>
                    )}
                </div>
            ) : (
                <div>
                    <p
                        className={cn({
                            title: true,
                            [styles.title]: true,
                        })}
                    >
                        Где будет пропуск?
                    </p>
                    {skipVariants.map((variant, index) => {
                        return (
                            <div key={index}>
                                {variant.map((symbol, index) => {
                                    if (
                                        symbol === DICTATION_SKIP_ID ||
                                        symbol === DICTATION_EMPTY_SKIP
                                    ) {
                                        return (
                                            <span
                                                key={index}
                                                className={styles.skip}
                                                onClick={() => onSelectPosition(index, variant)}
                                            >
                                                {" "}
                                            </span>
                                        );
                                    } else if (
                                        symbol === DICTATION_SKIP_DETECT ||
                                        symbol === DICTATION_EMPTY_DETECT
                                    ) {
                                        return (
                                            <span key={index} className={cn({skipBox: true})}>
                                                {" "}
                                            </span>
                                        );
                                    }

                                    return (
                                        <span key={index} className={styles.text}>
                                            {symbol}
                                        </span>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

SelectSkipBox.propTypes = {
    handleDeleteSkip: PropTypes.func,
    selectedPosition: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    selectedSymbolIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    selectedVariant: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
    selectedWordIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    skips: PropTypes.array,
    skipVariants: PropTypes.array,
    symbolGroups: PropTypes.array,
    symbolId: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    onSaveVariant: PropTypes.func,
    onSelectPosition: PropTypes.func,
};
