import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import Carousel from "../../../entries/carousel/carousel";
import {ABOUT_ENGINE_DATA} from "../about/sliders/about-engines/utils/about-engines-data";
import cn from "classnames";
import {Helmet} from "react-helmet";

const AboutProjectPage = () => {
    const [tabs] = useState(ABOUT_ENGINE_DATA);
    const [activeTab, setActiveTab] = useState(tabs[0]);

    useEffect(() => {
        document.body.style.background = "";
    }, []);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div className={styles.container}>
                <div className={styles.twoSideContainer}>
                    <div className={styles.containerLeft}>
                        <h1 className={styles.title}>Образовательная платформа «Умная Ворона»</h1>
                        <h2 className={styles.title}>Описание проекта</h2>
                        <p className="mb-2">
                            «Умная Ворона» – это образовательная платформа для решения интерактивных
                            заданий, направленных на закрепление школьной учебной программы по
                            общеобразовательным предметам. Задания объединены в тематические
                            комплекты согласно популярным учебно-методическим комплексам для
                            сопровождения школьного учебного процесса.
                        </p>
                    </div>

                    <div className={styles.containerRight}>
                        <img
                            src={require("../../../assets/sliders/teacher-free/teacher-free.svg")}
                        />
                    </div>
                </div>

                <h2 className={styles.title}>Функциональные характеристики</h2>
                <p className="mb-2">
                    Платформа предоставляет специфических набор функций для пользователей с ролями:
                    «Учитель» и «Ученик».
                </p>
                <p className="mb-2">
                    Пользователи с ролью «Ученик» получают доступ к заданиям для самостоятельных
                    занятий, а также занятий со своими родителями. Занятия рассчитаны и распределены
                    на весь учебный год. Процесс обучения выстроен в виде{" "}
                    <a
                        style={{fontSize: "18px"}}
                        className="link"
                        href="https://umnayavorona.ru/main/academy"
                        target="_blank"
                    >
                        «Академии Умной Вороны»
                    </a>
                    , представляющей собой последовательное решение тематических комплектов заданий
                    по различным школьным дисциплинам и получения баллов за достижение результата, и
                    – решения заданий в формате соревнования с другими учениками.
                </p>
                <p className="mb-2">
                    <a
                        style={{fontSize: "18px"}}
                        className="link"
                        href="https://umnayavorona.ru/main/academy"
                        target="_blank"
                    >
                        Как работает «Академия»
                    </a>
                    <br />

                    <a
                        style={{fontSize: "18px"}}
                        className="link"
                        href="https://umnayavorona.ru/main/tourney"
                        target="_blank"
                    >
                        Что такое «Турниры»
                    </a>
                </p>

                <p className="mb-2">
                    Пользователи с ролью «Учитель» получают доступ к базе готовых заданий для
                    организации и проведения проверочных работ для своих учеников, а также
                    функциональную возможность создавать собственные интерактивные задания для их
                    использования в образовательной работе с учениками. «Учитель» получает доступ к
                    статистике решения заданий каждого из своих учеников для контроля знаний.
                </p>

                <p className="mb-2">
                    <a
                        style={{fontSize: "18px"}}
                        className="link"
                        href="https://umnayavorona.ru/main/teacher"
                        target="_blank"
                    >
                        Презентация для учителей
                    </a>
                    <br />
                    <a
                        style={{fontSize: "18px"}}
                        className="link"
                        href="https://umnayavorona.ru/publications/kak-podkluchit-dobavit-uchenikov-k-uchitelu-na-umnoi-vorone"
                        target="_blank"
                    >
                        Как зарегистрировать и прикрепить учеников к учителю
                    </a>
                    <br />

                    <a
                        style={{fontSize: "18px"}}
                        className="link"
                        href="https://umnayavorona.ru/publications/kak-otpravlyat-zadaniya-uchenikam"
                        target="_blank"
                    >
                        Как отправлять задания ученикам
                    </a>
                </p>

                <h2 className={styles.title}>Техническое исполнение</h2>
                <p className="mb-2">
                    Образовательная платформа реализована для пользователей в формате web-сайта с
                    доступом по адресу umnayavorona.ru. Программное обеспечение и базы данных
                    расположены на серверах разработчика.
                </p>

                <h2 className={styles.title}>Технические требования</h2>
                <p className="mb-2">
                    Доступ к платформе реализован через web-сайт и требует наличия браузера (Google
                    Chrome, Mozilla Firefox, Yandex Browser, Safari, Opera, Microsoft Edge – версия
                    которых выпущена не ранее 2021 года) с поддержкой JavaScript и Cookies
                    (фрагментов данных, отправленых веб-сервером и хранимых на компьютере
                    пользователя). На компьютере пользователя должна быть установлена операционная
                    система поддерживающая один из вышеуказанных web-браузеров.
                </p>

                <h2 className={styles.title}>Порядок использования</h2>

                <ul>
                    <li>
                        Для использования платформы необходима регистрация пользователя. Регистрация
                        проводится пользователем самостоятельно на web-сайте umnayavorona.ru
                    </li>
                    <li>
                        Регистрация пользователя включает в себя согласие на обработку персональных
                        данных, безоговорочное принятие пользовательского соглашения и политики
                        конфиденциальности и настроек хранения Cookie-файлов на компьютере
                        пользователя.
                    </li>
                    <li>
                        После успешной регистрации пользователь должен пройти процедуру
                        аутентификации на сайте (авторизоваться с помощью логина и пароля)
                    </li>
                    <li>
                        После прохождения авторизации пользователь выбирает одну из ролей (учитель
                        или ученик), которая закрепляется за ним без возможности дальнейшего
                        изменения.
                    </li>
                    <li>
                        Все функциональные возможности сайта отражены в интерфейсах личного кабинета
                        и меню сайта.
                    </li>
                    <li>
                        Помощь при работе с платформой осуществляется через функцию «Техподдержка»
                        или посредством связи через электронную почту support@umnayavorona.ru
                    </li>
                </ul>

                <h2 className={styles.title}>Стоимость использования</h2>
                <p className="mb-2">
                    Доступ к платформе и заданиям по общеобразовательным предметам (математика,
                    русский язык, литературное чтение, окружающий мир, английский язык)
                    предоставляется ученикам и учителям на безвозмездной основе (бесплатно).
                </p>

                <p className="mb-2">
                    Доступ к заданиям по дополнительным дисциплинам (логика, этикет) предоставляется
                    по платной подписке на период от месяца (169 рублей) до года (799 рублей) – по
                    состоянию на 01.10.2022. Актуальные тарифы на дополнительные услуги опубликованы
                    в разделе «Подписка» личного кабинета ученика/учителя.
                </p>

                <h2 className={styles.title}>Как выглядят задания Академии?</h2>
                <div className={styles.carouselWrapper}>
                    <div className={styles.tabs}>
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={cn({
                                    [styles.tab]: true,
                                    [styles.active]: tab.id === activeTab.id,
                                })}
                                onClick={() => setActiveTab(tab)}
                            >
                                <img src={tab.logo} alt="logo" />
                            </div>
                        ))}
                    </div>
                    <Carousel slides={activeTab.slides} />
                </div>

                <h2 className={styles.title}>Информация для пользователей</h2>
                <p className="mb-2">
                    <a
                        style={{fontSize: "18px"}}
                        className="link"
                        href="https://umnayavorona.ru/terms"
                        target="_blank"
                    >
                        Пользовательское соглашение
                    </a>
                    <br />
                    <a
                        style={{fontSize: "18px"}}
                        className="link"
                        href="https://umnayavorona.ru/offer"
                        target="_blank"
                    >
                        Публичная оферта
                    </a>
                    <br />

                    <a
                        style={{fontSize: "18px"}}
                        className="link"
                        href="https://umnayavorona.ru/files/private_policy.pdf"
                        target="_blank"
                    >
                        Политика обработки персональных данных
                    </a>
                    <br />
                    <a
                        style={{fontSize: "18px"}}
                        className="link"
                        href="https://umnayavorona.ru/cookies_policy"
                        target="_blank"
                    >
                        Настройки файлов Cookies
                    </a>
                </p>
            </div>
        </>
    );
};

export default AboutProjectPage;
