import {UserAPI} from "core/api/user";
import SelectRole from "entries/select-role/select-role";
import React from "react";
import {useHistory} from "react-router-dom";

export const SELECT_ROLE_USER = "STUDENT";
export const SELECT_ROLE_TEACHER = "TEACHER";

const SelectRoleContainer = () => {
    const history = useHistory();

    const handleSelectRole = (role) => {
        UserAPI.selectRole(role).then(() => history.push("/"));
    };

    return <SelectRole handleSelectRole={handleSelectRole} />;
};

SelectRoleContainer.propTypes = {};

export default SelectRoleContainer;
