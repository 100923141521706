import {url} from "config";
import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";

const TeacherShopSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${url + "data/image-bank/888b921f-8811-4a47-a8ad-2cad9febbd0d.png"})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            <div className={sliders.content}>
                <div className={sliders.about}>
                    <p className={sliders.title}>Лавка Умной Вороны</p>
                    <p className={sliders.description}>
                        Накопленный рейтинг можно выгодно поменять на реальные и, главное, полезные
                        подарки. Например, купон на интересный вебинар или заказ качественной
                        рецензии. А если помощь при аттестации пока не нужна, то просто купить
                        несколько подписок на «Академию Умной Вороны» для своих учеников!
                    </p>
                </div>
            </div>
        </div>
    );
};

TeacherShopSlide.propTypes = {};

export default TeacherShopSlide;
