import {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";

const useNotificationQueue = () => {
    const [queue, setQueue] = useState([]);
    const [activeNotification, setActiveNotification] = useState(null);

    useEffect(() => {
        if (!queue.length) {
            setActiveNotification(null);
            return;
        }

        let notificationWithHighestPriority = queue.reduce((max, notification) =>
            max.priority ? max : notification,
        );

        setActiveNotification(notificationWithHighestPriority.component);
    }, [queue]);

    const addNotificationInQueue = (priority, component) => {
        let id = uuidv4();
        setQueue((prev) => [...prev, {id, component, priority}]);
        return id;
    };

    const removeNotificationFromQueue = (notificationId, componentKey) => {
        let updatedQueue = queue;
        if (notificationId) {
            updatedQueue = updatedQueue.filter((notification) => notification.id != notificationId);
        } else if (componentKey) {
            updatedQueue = updatedQueue.filter(
                (notification) => notification.component.key != componentKey,
            );
        }

        setQueue(updatedQueue);
    };

    return {
        addNotificationInQueue,
        removeNotificationFromQueue,
        activeNotification,
    };
};

export default useNotificationQueue;
