import {url} from "config";
import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";

const TeacherFabricSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${url + "data/image-bank/694cdfc0-6d14-447f-a7a0-28b40b7d1a41.png"})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            <div className={sliders.content}>
                <div className={sliders.about}>
                    <p className={sliders.title}>Фабрика заданий</p>
                    <p className={sliders.description}>
                        Создавайте интересные задания с помощью удобного конструктора. Пользуйтесь
                        базой готовых заданий и изображений, чтобы экономить на времени, а не на
                        качестве. Делитесь и используйте готовые наработки коллег для создания
                        уникальных задач для своих учеников!
                    </p>
                </div>
            </div>
        </div>
    );
};

TeacherFabricSlide.propTypes = {};

export default TeacherFabricSlide;
