import raven from "assets/sliders/teacher-free/teacher-free.svg";
import {auth} from "core/utils/auth";
import {MDBBtn} from "mdbreact";
import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";
import styles from "./styles.module.sass";
import cn from "classnames";

const TeacherFreeSlide = () => {
    const {keycloak} = auth;

    return (
        <div className={sliders.wrapper}>
            <div className={sliders.content}>
                <div className={sliders.about}>
                    <div className={styles.descriptionWrapper}>
                        <p
                            className={cn({
                                [sliders.title]: true,
                                [styles.title]: true,
                            })}
                        >
                            Умная Ворона работает для педагогов бесплатно.
                        </p>
                        <img className={styles.raven} src={raven} alt="logo" />
                        <p
                            className={cn({
                                [sliders.description]: true,
                                [styles.description]: true,
                            })}
                        >
                            И так будет всегда.
                        </p>

                        <MDBBtn
                            color="success"
                            className={styles.button}
                            onClick={() => keycloak.login()}
                        >
                            Зарегистрироваться
                        </MDBBtn>
                    </div>
                </div>
            </div>
        </div>
    );
};

TeacherFreeSlide.propTypes = {};

export default TeacherFreeSlide;
