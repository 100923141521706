import cn from "classnames";
import {convertTextWithSymbols} from "core/utils/converter";
import {AddQuestionButtons} from "entries/constructors/classic-test/views/components/add-question-buttons";
import DndDroppableBox from "feauters/clipping-pictures-engine/dnd-droppable-box/dnd-droppable-box";
import DraggableBox from "feauters/clipping-pictures-engine/draggable-box/draggable-box";
import {ItemTypes} from "feauters/clipping-pictures-engine/types";
import PropTypes from "prop-types";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDrop} from "react-dnd";
import {IMAGE_TYPE, SIZE_L, SIZE_M, SIZE_S, SIZE_XL, TEXT_TYPE} from "utils/answers-config";
import {EditButtons} from "views/components/edit-buttons";
import {ImageUploadModal} from "views/components/image-upload-modal/image-upload-modal";
import {
    PROPORTION_1TO1,
    PROPORTION_3TO2,
    PROPORTION_4TO3,
} from "views/components/proportion-buttons";
import {TextUploadModal} from "views/components/text-upload-modal/text-upload-modal";
import styles from "./styles.module.sass";
import {v4 as uuidv4} from "uuid";

const ContainerForClippingMulti = ({
    boxes,
    setBoxes,
    background,
    imageUploadModalIsOpen,
    setImageUploadModalIsOpen,
    handleBackgroundSave,
    handleBackgroundDelete,
    handleAddPictureAnswer,
    handleAddTextAnswer,
    uploadImageAnswerModalIsOpen,
    setUploadImageAnswerModalIsOpen,
    imageSize,
    handleAnswerDelete,
    isEditMode,
    isRoundedAnswer,
    answers,
    setAnswers,
    uploadTextAnswerModalIsOpen,
    setUploadTextAnswerModalIsOpen,
    answerType,
    textAnswerSize,
    handleSelectAnswer,
    selectedAnswerId,
}) => {
    const [addAnswersAvailable, setAddAnswersAvailable] = useState(false);
    const dropBox = useRef(null);

    useEffect(() => {
        if (answerType === TEXT_TYPE) {
            if (answers.length < 8) {
                setAddAnswersAvailable(true);
            } else {
                setAddAnswersAvailable(false);
            }
            return;
        }

        if (imageSize === SIZE_XL && answers.length < 3) {
            setAddAnswersAvailable(true);
        } else if (imageSize === SIZE_L && answers.length < 4) {
            setAddAnswersAvailable(true);
        } else if (imageSize === SIZE_M && answers.length < 5) {
            setAddAnswersAvailable(true);
        } else if (imageSize === SIZE_S && answers.length < 6) {
            setAddAnswersAvailable(true);
        } else {
            setAddAnswersAvailable(false);
        }
    }, [answers, imageSize, answerType]);

    const moveBox = useCallback(
        (tempId, left, top, center) => {
            let tempBoxes = boxes.slice();
            let itemIdx = boxes.findIndex((box) => box.tempId === tempId);

            if (itemIdx !== -1) {
                tempBoxes[itemIdx] = {...tempBoxes[itemIdx], left, top, center};
                setBoxes(tempBoxes);
            }
        },
        [boxes, setBoxes],
    );

    const [{isOver}, drop] = useDrop(
        () => ({
            accept: ItemTypes.CLIP,
            drop(item, monitor) {
                let selectedAnswer = answers.find((answer) => answer.id === item.id);
                let clientRect = dropBox?.current.getBoundingClientRect();
                let clientOffset = monitor.getClientOffset();
                let dropTarget = {
                    x: Math.floor(clientOffset.x - clientRect.x),
                    y: Math.floor(clientOffset.y - clientRect.y),
                };

                if (item.isFromAnswerBox) {
                    if (selectedAnswer) {
                        let updatedAnswers = answers.map((answer) =>
                            answer.tempId === selectedAnswer.tempId
                                ? {...answer, isAvailable: true, width: item.width}
                                : answer,
                        );
                        setAnswers(updatedAnswers);
                    }
                }

                const delta = monitor.getDifferenceFromInitialOffset();
                const left = Math.round(item.left + delta.x);
                const top = Math.round(item.top + delta.y);

                let boxIdx = boxes.findIndex((box) => box.tempId === item.tempId);
                let boxId;

                let center = item.width / 2 + left;
                center = left + item.width / 2;

                if (boxIdx === -1) {
                    let tempBoxId = uuidv4();
                    boxId = tempBoxId;
                    setBoxes([
                        ...boxes,
                        {
                            ...selectedAnswer,
                            tempId: tempBoxId,
                            top: dropTarget.y,
                            left: dropTarget.x,
                            center,
                        },
                    ]);
                } else {
                    boxId = boxes[boxIdx].tempId;
                }

                moveBox(boxId, left, top, center);
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            }),
        }),
        [moveBox, answers],
    );

    const handleDropAnswerToAnswerBox = (item) => {
        let newBoxes = boxes.filter((box) => box.tempId !== item.tempId);
        setBoxes(newBoxes);
    };

    const backgroundStyle = {
        backgroundImage:
            background && `url(${background.objectUrl ? background.objectUrl : background})`,
        backgroundRepeat: "no-repeat",
    };

    return (
        <React.Fragment>
            {imageUploadModalIsOpen && (
                <ImageUploadModal
                    visible={imageUploadModalIsOpen}
                    toggle={() => setImageUploadModalIsOpen(!imageUploadModalIsOpen)}
                    withCrop
                    uploadIsAvailable
                    onSaveImage={handleBackgroundSave}
                    availableProportions={[PROPORTION_3TO2, PROPORTION_4TO3]}
                    selectFromLibraryAvailable={false}
                />
            )}

            {uploadImageAnswerModalIsOpen && (
                <ImageUploadModal
                    visible={uploadImageAnswerModalIsOpen}
                    toggle={() => setUploadImageAnswerModalIsOpen(!uploadImageAnswerModalIsOpen)}
                    withCrop
                    uploadIsAvailable
                    selectFromLibraryAvailable
                    onSaveImage={handleAddPictureAnswer}
                    availableProportions={[PROPORTION_1TO1]}
                />
            )}

            {uploadTextAnswerModalIsOpen && (
                <TextUploadModal
                    saveText={handleAddTextAnswer}
                    visible={uploadTextAnswerModalIsOpen}
                    toggle={() => setUploadTextAnswerModalIsOpen(!uploadTextAnswerModalIsOpen)}
                    maxSymbols={100}
                />
            )}

            <div
                ref={(el) => {
                    drop(el);
                    dropBox.current = el;
                }}
                className={cn({
                    [styles.container]: true,
                    [styles.container_border]: isEditMode,
                })}
                style={{...backgroundStyle, backgroundColor: isOver ? "lightblue" : "white"}}
            >
                {!background && isEditMode ? (
                    <p
                        className={cn({
                            link: true,
                            [styles.container__description]: true,
                        })}
                        onClick={() => setImageUploadModalIsOpen(true)}
                    >
                        Добавить фоновое изображение
                    </p>
                ) : (
                    <React.Fragment>
                        {isEditMode && (
                            <EditButtons
                                editAction={() => setImageUploadModalIsOpen(true)}
                                deleteAction={handleBackgroundDelete}
                                withoutRightBorder={true}
                                withoutTopBorder={true}
                                withoutDeleteConfirmation
                            />
                        )}
                    </React.Fragment>
                )}

                {boxes.map((box) => {
                    return (
                        <DraggableBox
                            key={box.tempId}
                            id={box.id}
                            tempId={box.tempId}
                            left={box.left}
                            top={box.top}
                            className={cn({
                                [styles.rounded]: isRoundedAnswer,
                                [styles.isSelected]: selectedAnswerId === box.id,
                            })}
                            handleClick={handleSelectAnswer}
                        >
                            {box.type === IMAGE_TYPE ? (
                                <img
                                    src={box.objectUrl}
                                    alt="answer"
                                    className={cn({
                                        [imageSize]: true,
                                        [styles.rounded]: isRoundedAnswer,
                                        [styles.imageAnswer]: true,
                                    })}
                                />
                            ) : box.type === TEXT_TYPE ? (
                                <p className={styles.textAnswer} style={{fontSize: textAnswerSize}}>
                                    {convertTextWithSymbols(box.text)}
                                </p>
                            ) : null}
                        </DraggableBox>
                    );
                })}
            </div>

            {!answers.length ? (
                <div className={styles.chooseAnswer}>
                    <p>Добавить объект для перетаскивания</p>

                    <AddQuestionButtons
                        textVisible={true}
                        imageVisible={true}
                        textAction={() => setUploadTextAnswerModalIsOpen(true)}
                        imageAction={() => setUploadImageAnswerModalIsOpen(true)}
                        isSingle={true}
                    />
                </div>
            ) : (
                <DndDroppableBox
                    acceptType={ItemTypes.CLIP}
                    handleDrop={(item) => handleDropAnswerToAnswerBox(item)}
                    className={cn({
                        [styles.droppableBox]: true,
                        [styles.droppableBox_text]: answerType === TEXT_TYPE,
                    })}
                >
                    {answers.map((answer, index) => {
                        if (!answer.isAvailable) {
                            return;
                        }

                        return (
                            <DraggableBox
                                key={answer.id}
                                className={cn({
                                    [styles.rounded]: isRoundedAnswer,
                                    [styles.draggableBox]: true,
                                })}
                                id={answer.id}
                                isFromAnswerBox={true}
                            >
                                {isEditMode && (
                                    <EditButtons
                                        // editAction={() => console.log("edit")}
                                        deleteAction={() => handleAnswerDelete(answer.id, index)}
                                        withoutRightBorder
                                        withoutTopBorder
                                    />
                                )}

                                {answer.type === IMAGE_TYPE && answer.objectUrl ? (
                                    <img
                                        src={answer.objectUrl}
                                        alt="answer"
                                        className={cn({
                                            [styles.imageAnswer]: true,
                                            [imageSize]: true,
                                            [styles.rounded]: isRoundedAnswer,
                                        })}
                                    />
                                ) : answer.type === TEXT_TYPE ? (
                                    <p
                                        className={cn({
                                            [styles.rounded]: isRoundedAnswer,
                                            [styles.textAnswer]: true,
                                        })}
                                        style={{fontSize: textAnswerSize}}
                                    >
                                        {convertTextWithSymbols(answer.text)}
                                    </p>
                                ) : null}
                            </DraggableBox>
                        );
                    })}

                    {addAnswersAvailable && isEditMode && (
                        <div
                            className={cn({
                                [styles.addAnswer]: true,
                                [styles.rounded]: isRoundedAnswer,
                                [imageSize]: answerType === IMAGE_TYPE,
                                [styles.addAnswerText]: answerType === TEXT_TYPE,
                            })}
                            onClick={
                                answerType === TEXT_TYPE
                                    ? () => setUploadTextAnswerModalIsOpen(true)
                                    : () => setUploadImageAnswerModalIsOpen(true)
                            }
                            style={{fontSize: textAnswerSize}}
                        >
                            Добавить объект
                        </div>
                    )}
                </DndDroppableBox>
            )}
        </React.Fragment>
    );
};

ContainerForClippingMulti.propTypes = {
    answers: PropTypes.array,
    answerType: PropTypes.string,
    background: PropTypes.object,
    boxes: PropTypes.array,
    handleAddPictureAnswer: PropTypes.func,
    handleAddTextAnswer: PropTypes.func,
    handleAnswerDelete: PropTypes.func,
    handleBackgroundDelete: PropTypes.func,
    handleBackgroundSave: PropTypes.func,
    handleSelectAnswer: PropTypes.func,
    imageSize: PropTypes.string,
    imageUploadModalIsOpen: PropTypes.bool,
    isEditMode: PropTypes.bool,
    isRoundedAnswer: PropTypes.bool,
    multiple: PropTypes.bool,
    selectedAnswerId: PropTypes.number,
    setAnswers: PropTypes.func,
    setBoxes: PropTypes.func,
    setImageUploadModalIsOpen: PropTypes.func,
    setUploadImageAnswerModalIsOpen: PropTypes.func,
    setUploadTextAnswerModalIsOpen: PropTypes.func,
    textAnswerSize: PropTypes.number,
    uploadImageAnswerModalIsOpen: PropTypes.bool,
    uploadTextAnswerModalIsOpen: PropTypes.bool,
};

export default ContainerForClippingMulti;
