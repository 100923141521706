import { makeAutoObservable } from "mobx";
import { ITaskEngine } from "../interfaces/ITaskEngine";

export class ConstructorV2Store {
    public constructorType: ITaskEngine | undefined = undefined
    public payload: any
    public step: number = 0

    constructor() {
        makeAutoObservable(this);
    }
}
