import React from "react";
import PropTypes from "prop-types";
import {Draggable} from "react-beautiful-dnd";
import cn from "classnames";
import {EditButtons} from "views/components/edit-buttons";

import styles from "entries/constructors/open-answer/views/box-with-symbols/box-with-symbols.module.sass";

export const BoxWithSymbols = ({
    symbols,
    isShortContainer,
    placeholder,
    isDraggable = true,
    disableSymbolHandle,
    isForAnswer,
    editAction,
}) => {
    return (
        <div
            className={cn({
                [styles.boxWithSymbols]: !isDraggable,
                [styles.center]: !isDraggable,
                [styles.droppableBox]: true,
                [styles.droppableBoxShort]: isShortContainer,
            })}
        >
            {symbols.length > 0 ? (
                <>
                    {isDraggable ? (
                        <>
                            {symbols.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={`${item.id}`}
                                    index={index}
                                    isDragDisabled={item.isDisabled}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div
                                                className={cn({
                                                    [styles.card]: true,
                                                    [styles.cardDragging]: snapshot.isDragging,
                                                    [styles.disabledBox]: item.isDisabled,
                                                })}
                                                onClick={
                                                    !isForAnswer
                                                        ? () => disableSymbolHandle(item)
                                                        : null
                                                }
                                            >
                                                <span
                                                    className={cn({
                                                        [item.symbol.svgClass]: true,
                                                    })}
                                                >
                                                    {item.isDisabled && (
                                                        <i
                                                            className={cn({
                                                                "fa fa-times": true,
                                                                [styles.times]: item.isDisabled,
                                                            })}
                                                        />
                                                    )}

                                                    {item.symbol.svgClass === "string" &&
                                                        item.symbol.altName}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </>
                    ) : (
                        <div
                            className={cn({
                                [styles.cardContainer]: true,
                            })}
                        >
                            {symbols.map((item, index) => (
                                <div
                                    key={index}
                                    className={cn({
                                        [styles.card]: true,
                                    })}
                                >
                                    <span className={item.symbol.svgClass}>
                                        {item.symbol.svgClass === "string" && item.symbol.altName}
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            ) : (
                <p className={styles.placeholder}>{placeholder}</p>
            )}
            <EditButtons
                editAction={editAction}
                withoutTopBorder={true}
                withoutRightBorder={true}
            />
        </div>
    );
};

BoxWithSymbols.propTypes = {
    disableSymbolHandle: PropTypes.func,
    editAction: PropTypes.any,
    isDraggable: PropTypes.bool,
    isForAnswer: PropTypes.bool,
    isShortContainer: PropTypes.bool,
    placeholder: PropTypes.any,
    symbols: PropTypes.array,
};
