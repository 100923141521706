import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../hooks/use-stores";
import {url} from "../../../config/index";
import {Roles} from "../../../enums/roles";
import {PromoSets} from "./promo-sets";
import {ACADEMY_ROUTES} from "../../../routes/main";
import {useHistory} from "react-router-dom";
import cn from "classnames";

interface IProps {
    isOpen: boolean;
    updateFirstEnter: () => void;
}

const WelcomeModal = observer((props: IProps) => {
    const [classId, setClassId] = useState<number | undefined>(0);
    const {userStore} = useStores();
    const history = useHistory();

    useEffect(() => {
        setClassId(userStore.user?.classTag?.id);
    }, [userStore.user]);

    return (
        <div
            className={cn({
                [styles.containerWrapper]: true,
            })}
        >
            <img
                className={styles.logo}
                src={url + "data/narrator/c25d495c-d45a-4c26-87ac-48d37a0d35b5.svg"}
                alt="raven-logo"
            />
            <h3 className={styles.title}>Добро пожаловать!</h3>
            <div className={styles.content}>
                <div className={styles.description}>
                    {userStore.role === Roles.STUDENT && (
                        <React.Fragment>
                            <p>Я покажу тебе, какие задания тебя ждут в академии и турнирах.</p>
                            <p className="mb-2">Выбирай любимый предмет и полетели решать!</p>
                        </React.Fragment>
                    )}

                    {userStore.role === Roles.TEACHER && (
                        <React.Fragment>
                            <p>
                                Умная Ворона – приглашает учителей начальной школы подключить свой
                                класс! <br /> Вы сможете отправлять своим ученикам готовые комплекты
                                домашних заданий, разбитых по школьным предметам и темам – это более
                                20000 уникальных заданий, созданных нашими методистами и
                                иллюстраторами. <br /> 1. Находите в «Академии» нужную тему <br />{" "}
                                2. Отправляйте своему классу или группе учеников <br /> 3.
                                Проверяйте результаты. Всё очень просто и удобно!
                            </p>

                            <p>Знакомьтесь с нашей Академией!</p>
                        </React.Fragment>
                    )}
                </div>
            </div>

            <div className={styles.footer}>
                {PromoSets.filter((set) =>
                    classId ? set.classId === classId : set.classId === 3,
                ).map((set) => (
                    <div
                        key={set.id}
                        className={styles.subjects}
                        onClick={() => {
                            props.updateFirstEnter();
                            history.push(ACADEMY_ROUTES.SETS.PLAY.replace(":id", `${set.setId}`));
                        }}
                    >
                        <img src={set.logoSrc} alt="subject-logo" />
                        <p>{set.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default WelcomeModal;
