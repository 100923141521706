import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

import {EMPTY_OPTION_DEFAULT_LABEL, EMPTY_OPTION_VALUE} from "./empty-option";

export const TagSectionSelect = (props) => {
    const {
        disabled,
        onChange,
        options,
        hideEmptyOption,
        emptyOptionLabel = EMPTY_OPTION_DEFAULT_LABEL,
        controlRef,
        ...componentProps
    } = props;

    const handleChange = React.useCallback(
        (option) => {
            return onChange?.(option.value);
        },
        [onChange],
    );

    const placeholder = props.placeholder || "Выберите тему";

    return (
        <>
            <Select
                {...componentProps}
                isDisabled={disabled}
                onChange={handleChange}
                ref={controlRef}
                options={
                    hideEmptyOption
                        ? options
                        : [
                              {
                                  value: EMPTY_OPTION_VALUE,
                                  label: emptyOptionLabel,
                              },
                              ...(options || {}),
                          ]
                }
                placeholder={placeholder}
            />
        </>
    );
};

TagSectionSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        }),
    ).isRequired,
    controlRef: PropTypes.any,
    defaultValue: PropTypes.bool,
    disabled: PropTypes.bool,
    emptyOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hideEmptyOption: PropTypes.bool,
    menuIsOpen: PropTypes.bool,
    parentSectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.any,
    value: PropTypes.number,
    onChange: PropTypes.func,
};
