import {CLIPPING_TEST} from "config/constructor-names";
import CreateTest from "entries/constructors/classic-test/views/containers/create-test/create-test";
import {ClippingAPI} from "entries/constructors/clipping/core/ClippingAPI";
import ClippingEngine from "entries/constructors/clipping/views/clipping-engine";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";

export const ClippingManager = ({routerInstance}) => {
    let history = useHistory();
    const [testData, setTestData] = useState({});
    const [mode, setMode] = useState(null);

    useEffect(() => {
        if (
            !history.location.state &&
            !history.location.pathname.includes(
                routerInstance.CLIPPING_TEST.EDITING.replace(":id", ""),
            )
        ) {
            history.push(routerInstance.ROOT);
        }

        setMode(history.location.state);
    }, []);

    const onCreateTest = (testData) => {
        setTestData(testData);
        history.push(routerInstance.CLIPPING_TEST.CONSTRUCTOR);
    };

    return (
        <div>
            <Switch>
                <Route path={routerInstance.CLIPPING_TEST.CREATE_TEST}>
                    <CreateTest
                        onCreateTest={onCreateTest}
                        apiInstance={ClippingAPI}
                        mode={mode}
                        constructorType={CLIPPING_TEST}
                    />
                </Route>

                <Route path={routerInstance.CLIPPING_TEST.EDITING}>
                    <ClippingEngine testData={testData} isEditMode={true} />
                </Route>

                <Route path={routerInstance.CLIPPING_TEST.CONSTRUCTOR}>
                    <ClippingEngine testData={testData} mode={mode} />
                </Route>
            </Switch>
        </div>
    );
};

ClippingManager.propTypes = {
    routerInstance: PropTypes.any,
};
