import {Request} from "../../../core/utils/request";

export const AdminPanelUserAPI = {
    getUserByEmail: (email: string) => {
        return Request.get(`admin/user-by-email?email=${email}`)
    },
    getUserByName: (firstName?: string, lastName?: string) => {
        let query
        if (firstName && lastName) {
            query = `?firstName=${firstName}&lastName=${lastName}`
        } else if (firstName) {
            query = `?firstName=${firstName}`
        } else if (lastName) {
            query = `?lastName=${lastName}`
        }
        return Request.get(`admin/user${query}&size=10000`)
    },
    createStudent: (payload: any) => {
        return Request.post(`super-admin/create-student?firstName=${payload.firstName}&lastName=${payload.lastName}&teacherId=${payload.teacherId}`)
    },
    getAcceptedStudents: (id: number, page: number = 1, size: number = 50) => {
        return Request.get(`super-admin/teacher/${id}/student-accepted?page=${page}&size=${size}`)
    },
    getStudentsInClass: (id: string) => {
        return Request.get(`super-admin/teacher/${id}/students-in-class`)
    }
}
