import {TOAST_ERROR_CONFIG} from "config";
import {OpenAnswerAPI} from "entries/constructors/open-answer/core/api/open-answer";
import {ModalOpenAnswer} from "entries/constructors/open-answer/views/modal-open-answer/modal-open-answer";
import {SymbolsAPI} from "entries/symbols/core/api/symbols";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {copy, move, reorder} from "utils/dnd-helpers";
import {v4 as uuidv4} from "uuid";

export const ANSWERS = "droppableAnswerBox";
export const VARIANTS = "droppableBoxWithSymbols";

export const ModalOpenAnswerContainer = ({
    modalOpenAnswerIsOpen,
    toggleModal,
    setAnswers,
    questionId,
    selectedGroup,
    setSelectedGroup,
    modalAnswers,
    setModalAnswers,
    variants,
    setVariants,
    setSaveAnswerIsLoading,
}) => {
    const [isSymbolsLoading, setIsSymbolsLoading] = useState(false);

    const handleSaveAnswers = async () => {
        let availableAnswers = variants
            .filter((variant) => !variant.isDisabled)
            .map((answer) => answer.symbol.id);

        setSaveAnswerIsLoading(true);
        OpenAnswerAPI.clearAnswers(questionId)
            .then(() => OpenAnswerAPI.addAnswers(questionId, availableAnswers))
            .finally(() => setSaveAnswerIsLoading(false));

        toggleModal();
        setAnswers(modalAnswers);
    };

    const handleReset = () => {
        setModalAnswers([]);
    };

    const onSelectGroup = async (groups, triggeredAction) => {
        // if (triggeredAction.action === "remove-value") {
        //     let newVariants = variants.filter(
        //         (variant) => variant.groupId !== triggeredAction.removedValue.value,
        //     );
        //     setVariants(newVariants);
        // }

        if (!groups) {
            setVariants([]);
            setSelectedGroup([]);
            setModalAnswers([]);
            return;
        }

        if (
            (modalAnswers.length > 0 && triggeredAction.action === "remove-value") ||
            triggeredAction.action === "clear"
        ) {
            let availableGroupIds = groups.map((group) => group.value);

            let variantsClone = variants.filter((variant) =>
                availableGroupIds.includes(variant.groupId),
            );

            let answersClone = modalAnswers.filter(
                (answer) => !availableGroupIds.includes(answer.groupId),
            );
            if (answersClone.length > 0) {
                toast.error("Символ удалить нельзя, т.к он выбран", TOAST_ERROR_CONFIG);
                return;
            }

            setSelectedGroup(groups);
            setVariants(variantsClone);
            return;
        }

        if (groups && groups.length > 0) {
            setSelectedGroup(groups);
            for (const group of groups) {
                await loadVariants(group.value);
            }
        }
    };

    const loadVariants = (groupId) => {
        setIsSymbolsLoading(true);
        return SymbolsAPI.getSymbolsBySymbolGroup(groupId)
            .then((response) => {
                let result = response.data.map((item) => ({
                    ...item,
                    groupId: groupId,
                    isDisabled: false,
                    id: uuidv4(),
                }));
                let cloneVariants = variants;
                cloneVariants = [...cloneVariants, ...result];
                setVariants(cloneVariants);
            })
            .finally(() => setIsSymbolsLoading(false));
    };

    const disableSymbolHandle = (symbolData) => {
        let answer = modalAnswers.find(
            (answer) =>
                answer.groupId === symbolData.groupId && symbolData.position === answer.position,
        );

        if (answer) {
            toast.error("Нельзя удалить символ, который используется в ответе", TOAST_ERROR_CONFIG);
            return;
        }
        let itemForToggle = variants.find((variant) => variant === symbolData);
        if (itemForToggle) {
            let cloneVariants = variants.map((variant) => {
                if (variant === itemForToggle) {
                    itemForToggle.isDisabled = !variant.isDisabled;
                    return itemForToggle;
                }
                return variant;
            });
            setVariants(cloneVariants);
        }
    };

    const onDragEnd = (result) => {
        const {source, destination} = result;
        if (!destination) {
            return;
        }

        const list = {
            [VARIANTS]: variants,
            [ANSWERS]: modalAnswers,
        };

        const getList = (id) => list[id];

        if (source.droppableId === ANSWERS && destination.droppableId === VARIANTS) {
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination,
                true,
            );

            setModalAnswers(result.droppableAnswerBox);
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(getList(source.droppableId), source.index, destination.index);

            if (source.droppableId === VARIANTS) {
                setVariants(items);
            } else {
                setModalAnswers(items);
            }
        } else {
            if (modalAnswers.length > 9 && destination.droppableId === ANSWERS) {
                toast.error("Максимальное число символов - 10", TOAST_ERROR_CONFIG);
                return;
            }

            const result = copy(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination,
            );

            setModalAnswers(result);
        }
    };

    return (
        <ModalOpenAnswer
            modalOpenAnswerIsOpen={modalOpenAnswerIsOpen}
            toggleModal={toggleModal}
            onDragEnd={onDragEnd}
            answers={modalAnswers}
            variants={variants}
            selectedGroup={selectedGroup}
            onSelectGroup={onSelectGroup}
            handleSaveAnswers={handleSaveAnswers}
            handleReset={handleReset}
            isSymbolsLoading={isSymbolsLoading}
            disableSymbolHandle={disableSymbolHandle}
        />
    );
};

ModalOpenAnswerContainer.propTypes = {
    modalAnswers: PropTypes.any,
    modalOpenAnswerIsOpen: PropTypes.bool,
    questionId: PropTypes.number,
    selectedGroup: PropTypes.any,
    setAnswers: PropTypes.func,
    setModalAnswers: PropTypes.any,
    setSaveAnswerIsLoading: PropTypes.func,
    setSelectedGroup: PropTypes.any,
    setVariants: PropTypes.any,
    toggleModal: PropTypes.func,
    variants: PropTypes.any,
};
