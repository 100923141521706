import {TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG, url} from "config";
import {IMAGE_FROM_BANK} from "core/types/content-types";
import {ClassicTestAPI} from "entries/constructors/classic-test/core/api/classic-test";
import {
    AUDIO_ANSWER_CLASSIC,
    FORMULA_ANSWER_CLASSIC,
    IMAGE_FROM_BANK_ANSWER_CLASSIC,
    IMAGE_FROM_UPLOAD_ANSWER_CLASSIC,
    TEXT_ANSWER_CLASSIC,
} from "entries/constructors/classic-test/utils/answer-types";

import {
    convertResponseToFormulaAnswer,
    convertResponseToTextAnswer,
    deleteAnswerFromArray,
} from "entries/constructors/classic-test/utils/utils";
import {AddQuestionButtons} from "entries/constructors/classic-test/views/components/add-question-buttons";
import {FormulaAnswer} from "entries/constructors/classic-test/views/components/formula-answer";
import {Image} from "entries/constructors/classic-test/views/components/image-answer";

import {ImageAnswer} from "entries/constructors/classic-test/views/components/image-answer/image-answer";
import {Text, TextAnswer} from "entries/constructors/classic-test/views/components/text-answer";
import styles from "entries/constructors/classic-test/views/containers/create-answer/create-answer.module.sass";
import ListOfAudioAnswers from "entries/list-of-audio-answers/list-of-audio-answers";
import UploadAudioModalContainer from "entries/modals/upload-audio-modal/upload-audio-modal-container";

import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {AUDIO_TYPE, FORMULA_TYPE, IMAGE_TYPE, TEXT_TYPE} from "utils/answers-config";
import {ConvertUploadImageFromResponse} from "utils/convert-response-data";
import {formulaToBlob} from "utils/save-utils";
import {FormulaUploadModal} from "views/components/formula-upload-modal";
import {ImageUploadModal} from "views/components/image-upload-modal/image-upload-modal";
import {PROPORTIONS} from "views/components/proportion-buttons";
import {SpinnerPage} from "views/components/spinner";
import {TextUploadModal} from "views/components/text-upload-modal/text-upload-modal";

export const CreateAnswer = (props) => {
    const {
        answers,
        updateAnswers,
        isEditMode,
        isSelectCorrectAnswerMode,
        imageSize,
        answerType,
        updateAnswerType,
        textAnswerSize,
        questionId,
        isTwoColumnMode,
        isRoundedAnswer,
        addAnswerRef,
    } = props;

    const [answerIdForEdit, setAnswerIdForEdit] = useState(null);
    const [textModalVisible, setTextModalVisible] = useState(false);
    const [imageModalVisible, setImageModalVisible] = useState(false);
    const [formulaModalVisible, setFormulaModalVisible] = useState(false);
    const [audioModalVisible, setAudioModalVisible] = useState(false);
    const [availableProportions, setAvailableProportions] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (answerIdForEdit) {
            if (!textModalVisible && !imageModalVisible && !formulaModalVisible) {
                setAnswerIdForEdit(null);
            }
        }
    }, [textModalVisible, imageModalVisible, formulaModalVisible]);

    const saveText = async (answerData) => {
        if (answerIdForEdit) {
            let answerForEdit = getAnswerForEdit(answerIdForEdit);
            let cloneAnswers = answers;
            setIsLoading(true);
            await ClassicTestAPI.editTextAnswer(questionId, answerForEdit.id, answerData.text)
                .then(() => {
                    cloneAnswers = cloneAnswers.map((answer) => {
                        if (answer === answerForEdit) {
                            return {
                                id: answerIdForEdit,
                                component: <Text text={answerData.text} />,
                                text: answerData.text,
                                type: answerData.type,
                            };
                        } else {
                            return answer;
                        }
                    });
                    setAnswerIdForEdit(null);
                })
                .catch(() => toast.error("Ошибка изменения ответа", TOAST_ERROR_CONFIG))
                .finally(() => setIsLoading(false));
            updateAnswers(cloneAnswers);
        } else {
            setIsLoading(true);
            ClassicTestAPI.createTextAnswer(answerData, questionId)
                .then((response) => {
                    updateAnswers([
                        ...answers,
                        convertResponseToTextAnswer(response, answerData, isEditMode),
                    ]);
                })
                .catch(() => toast.error("Ошибка добавления ответа", TOAST_ERROR_CONFIG))
                .finally(() => setIsLoading(false));
        }
        setTextModalVisible(false);
    };

    const deleteAnswer = (answerId) => {
        let answer = getAnswerForEdit(answerId);
        switch (answer.objectType || answer.type) {
            case TEXT_ANSWER_CLASSIC:
                deleteAnswerRequest(answerId);
                break;
            case IMAGE_FROM_UPLOAD_ANSWER_CLASSIC:
            case IMAGE_FROM_BANK_ANSWER_CLASSIC:
            case AUDIO_ANSWER_CLASSIC:
                return deleteAnswerRequest(answerId, true).then(() => {
                    setAvailableProportions([]);
                    updateAnswers(deleteAnswerFromArray(answers, answerId));
                });
            case FORMULA_ANSWER_CLASSIC:
                deleteAnswerRequest(answerId);
                break;
            default:
                break;
        }

        updateAnswers(deleteAnswerFromArray(answers, answerId));
    };

    const deleteAnswerRequest = (answerId, withReturnPromise) => {
        setIsLoading(true);
        let request = ClassicTestAPI.deleteAnswer(questionId, answerId)
            .catch(() => toast.error("Ошибка удаления ответа", TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));

        if (withReturnPromise) {
            return request;
        }
    };

    const saveFormula = (formula) => {
        setIsLoading(true);
        ClassicTestAPI.createFormulaAnswer(questionId, formulaToBlob(formula))
            .then((response) => {
                updateAnswers([...answers, convertResponseToFormulaAnswer(response)]);
                setFormulaModalVisible(false);
                toast.success("Формула загружена", TOAST_SUCCESS_CONFIG);
            })
            .catch(() => toast.error("Ошибка загрузки формулы", TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));
    };

    const handleSaveAudio = (audio) => {
        ClassicTestAPI.createAudioAnswer(questionId, audio).then((response) => {
            updateAnswers([
                ...answers,
                {
                    id: response.data.id,
                    audioUrl: url + response.data.audio.fileDownloadUri.slice(1),
                    image: null,
                    type: AUDIO_ANSWER_CLASSIC,
                },
            ]);
        });

        setAudioModalVisible(false);
    };

    const uploadImage = (image) => {
        let componentImage;
        let cropArea = image.areaForCrop;
        const proportion = PROPORTIONS[image.proportion];
        setIsLoading(true);
        ClassicTestAPI.addImageAnswer(
            questionId,
            image.image,
            cropArea.height,
            cropArea.width,
            cropArea.x,
            cropArea.y,
        )
            .then((response) => {
                setAvailableProportions([proportion]);
                let image = ConvertUploadImageFromResponse(
                    response.data.answerImageQuestion,
                    response.data.id,
                );
                componentImage = {
                    id: response.data.id,
                    component: <Image image={{id: image.id, url: image.url}} size={imageSize} />,
                    type: image.type,
                };

                if (answerIdForEdit) {
                    let cloneAnswers = answers;
                    cloneAnswers = cloneAnswers.map((answer) =>
                        answer.id === answerIdForEdit ? componentImage : answer,
                    );
                    setAnswerIdForEdit(null);
                    updateAnswers(cloneAnswers);
                } else {
                    updateAnswers([...answers, componentImage]);
                }
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
    };

    const uploadImageFromBank = (image) => {
        let componentImage;
        setIsLoading(true);
        ClassicTestAPI.addImageAnswerFromBank(questionId, image.id)
            .then((response) => {
                componentImage = {
                    id: response.data.id,
                    component: (
                        <Image
                            image={{id: image.id, url: image.fileDownloadUri.slice(1)}}
                            size={imageSize}
                        />
                    ),
                    type: response.data.objectType,
                };

                if (answerIdForEdit) {
                    let cloneAnswers = answers;
                    cloneAnswers = cloneAnswers.map((answer) =>
                        answer.id === answerIdForEdit ? componentImage : answer,
                    );
                    setAnswerIdForEdit(null);
                    updateAnswers(cloneAnswers);
                } else {
                    updateAnswers([...answers, componentImage]);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const saveImage = (image) => {
        if (!image) {
            return;
        }

        if (answerIdForEdit) {
            let answer = getAnswerForEdit(answerIdForEdit);

            if (answer && answer.type === AUDIO_ANSWER_CLASSIC) {
                ClassicTestAPI.addImageToAudioAnswer(questionId, answer.id, image.id)
                    .then((response) => {
                        let updatedAnswers = answers.map((answer) => {
                            if (answer.id === response.data.id) {
                                return {...answer, image: response.data.answerImageBank};
                            }
                            return answer;
                        });
                        updateAnswers(updatedAnswers);
                    })
                    .catch(() => toast.error("Ошибка добавления изображения", TOAST_ERROR_CONFIG))
                    .finally(() => {
                        setAnswerIdForEdit(null);
                        setImageModalVisible(false);
                    });
                return;
            }

            setIsLoading(true);
            ClassicTestAPI.deleteAnswer(questionId, answerIdForEdit)
                .catch(() => {
                    toast.error("Ошибка изменения изображения", TOAST_ERROR_CONFIG);
                })
                .finally(() => setIsLoading(false));
        }

        if (image.type === IMAGE_FROM_UPLOAD_ANSWER_CLASSIC) {
            uploadImage(image);
        } else if (image.type === IMAGE_FROM_BANK) {
            uploadImageFromBank(image);
        }

        setImageModalVisible(false);
    };

    const getAnswerForEdit = (answerIdForEdit) => {
        return answers.find((item) => item.id === answerIdForEdit);
    };

    const correctAnswer = (answerId) => {
        let arrayWithCorrectAnswer = answers.map((answer) => {
            if (answer.id === answerId) {
                if (answer.isCorrect) {
                    return {...answer, isCorrect: false};
                } else {
                    return {...answer, isCorrect: true};
                }
            }
            return answer;
        });
        updateAnswers(arrayWithCorrectAnswer);
    };

    if (isLoading) {
        return <SpinnerPage primary />;
    }

    const handleDeleteImageFromAudioAnswer = (id) => {
        ClassicTestAPI.deleteImageFromAudioAnswer(questionId, id)
            .then((response) => {
                let updatedAnswers = answers.map((answer) => {
                    if (answer.id === response.data.id) {
                        return {...answer, image: null};
                    }
                    return answer;
                });
                updateAnswers(updatedAnswers);
            })
            .catch(() => toast.error("Ошибка удаления изображения", TOAST_ERROR_CONFIG));
    };

    return (
        <div className={styles.container} ref={addAnswerRef}>
            {textModalVisible && (
                <TextUploadModal
                    visible={textModalVisible}
                    toggle={() => setTextModalVisible(!textModalVisible)}
                    saveText={saveText}
                    itemForEdit={getAnswerForEdit(answerIdForEdit)}
                    maxSymbols={100}
                />
            )}

            {imageModalVisible && (
                <ImageUploadModal
                    visible={imageModalVisible}
                    availableProportions={availableProportions}
                    toggle={() => setImageModalVisible(!imageModalVisible)}
                    onSaveImage={saveImage}
                    withCrop
                    testId={questionId}
                    svgDisabled
                    uploadIsAvailable={answerType !== AUDIO_TYPE}
                />
            )}

            {formulaModalVisible && (
                <FormulaUploadModal
                    visible={formulaModalVisible}
                    toggle={() => setFormulaModalVisible(!formulaModalVisible)}
                    saveFormula={saveFormula}
                />
            )}

            {audioModalVisible && (
                <UploadAudioModalContainer
                    toggle={() => setAudioModalVisible(!audioModalVisible)}
                    isOpen={audioModalVisible}
                    saveAudio={handleSaveAudio}
                />
            )}

            {answerType === IMAGE_TYPE && (
                <ImageAnswer
                    answers={answers}
                    correctAnswer={correctAnswer}
                    deleteAnswer={deleteAnswer}
                    editImage={(imageId) => {
                        setImageModalVisible(true);
                        setAnswerIdForEdit(imageId);
                    }}
                    imageSize={imageSize}
                    isEditMode={isEditMode}
                    isSelectCorrectAnswerMode={isSelectCorrectAnswerMode}
                    setImageModalVisible={setImageModalVisible}
                    updateAnswerType={updateAnswerType}
                    isRoundedAnswer={isRoundedAnswer}
                />
            )}

            {answerType === TEXT_TYPE && (
                <TextAnswer
                    answers={answers}
                    correctAnswer={correctAnswer}
                    deleteAnswer={deleteAnswer}
                    editText={(textId) => {
                        setTextModalVisible(true);
                        setAnswerIdForEdit(textId);
                    }}
                    updateAnswerType={updateAnswerType}
                    isEditMode={isEditMode}
                    setTextModalVisible={setTextModalVisible}
                    isSelectCorrectAnswerMode={isSelectCorrectAnswerMode}
                    textAnswerSize={textAnswerSize}
                    isTwoColumnMode={isTwoColumnMode}
                />
            )}

            {answerType === FORMULA_TYPE && (
                <FormulaAnswer
                    answers={answers}
                    correctAnswer={correctAnswer}
                    deleteAnswer={deleteAnswer}
                    setFormulaModalVisible={setFormulaModalVisible}
                    isEditMode={isEditMode}
                    isSelectCorrectAnswerMode={isSelectCorrectAnswerMode}
                    updateAnswerType={updateAnswerType}
                />
            )}

            {(answerType === AUDIO_TYPE || answerType === AUDIO_ANSWER_CLASSIC) && (
                <ListOfAudioAnswers
                    answers={answers}
                    handleSelectAnswer={correctAnswer}
                    handleDeleteAnswer={deleteAnswer}
                    handleAddAnswer={() => setAudioModalVisible(true)}
                    handleDeleteType={() => updateAnswerType("")}
                    selectIsAvailable={isSelectCorrectAnswerMode}
                    withAddAudioButton={true}
                    maxAnswers={6}
                    handleClick={correctAnswer}
                    isEditMode={isEditMode}
                    handleAddImage={(id) => {
                        setImageModalVisible(true);
                        setAnswerIdForEdit(id);
                    }}
                    handleDeleteImageAnswer={handleDeleteImageFromAudioAnswer}
                />
            )}

            {!answerType && isEditMode && (
                <div className={styles.selectType}>
                    <p className={styles.selectText}>Добавить вариант ответа</p>
                    <AddQuestionButtons
                        formulaVisible={false}
                        imageVisible
                        textVisible
                        audioVisible
                        imageAction={() => updateAnswerType(IMAGE_TYPE)}
                        textAction={() => updateAnswerType(TEXT_TYPE)}
                        formulaAction={() => updateAnswerType(FORMULA_TYPE)}
                        audioAction={() => updateAnswerType(AUDIO_TYPE)}
                    />
                </div>
            )}
        </div>
    );
};

CreateAnswer.propTypes = {
    answers: PropTypes.any,
    answersFromServer: PropTypes.array,
    answerType: PropTypes.string,
    imageSize: PropTypes.string,
    isEditMode: PropTypes.bool,
    isRoundedAnswer: PropTypes.bool,
    isSelectCorrectAnswerMode: PropTypes.bool,
    isTwoColumnMode: PropTypes.bool,
    questionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    textAnswerSize: PropTypes.number,
    updateAnswers: PropTypes.func,
    updateAnswerType: PropTypes.func,
};
