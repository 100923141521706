import {ADMIN_ROLE, STUDENT_ROLE, TEACHER_ROLE, USER_ROLE} from "config/roles";
import {Roles} from "enums/roles";
import {hasAccess} from "utils/private-route";

export const getFullName = (firstName, lastName) => {
    return `${firstName} ${lastName}`;
};

//arr-массив для фильтра, items-массив с выбранными элементами
export const getUniqueItemsByField = (arr, items, uniqueField) => {
    const existedItems = [];
    let uniqueItems = items.filter((item) => {
        if (arr.find((v) => v[uniqueField] === item)) {
            existedItems.push(item);
            return null;
        } else return item;
    });

    return [uniqueItems, existedItems];
};

export const getRole = (keycloak, withEnum) => {
    if (keycloak) {
        if (hasAccess(keycloak, [ADMIN_ROLE])) {
            return withEnum ? Roles.ADMIN : ADMIN_ROLE;
        } else if (hasAccess(keycloak, [TEACHER_ROLE])) {
            return withEnum ? Roles.TEACHER : TEACHER_ROLE;
        } else if (hasAccess(keycloak, [STUDENT_ROLE])) {
            return withEnum ? Roles.STUDENT : STUDENT_ROLE;
        } else if (hasAccess(keycloak, [USER_ROLE])) {
            return withEnum ? Roles.USER : USER_ROLE;
        }
    }

    return null;
};

export const getDifferenceBetweenDates = (startDate, endDate) => {
    return Math.floor(Math.abs(new Date(startDate) - new Date(endDate)) / 1000);
};

export function msToTime(duration) {
    function addZ(n) {
        return (n < 10 ? "0" : "") + n;
    }

    let time = new Date(duration);
    let hours = time.getUTCHours();
    let minutes = time.getUTCMinutes();
    let seconds = time.getUTCSeconds();

    if (Number(hours) === 0) {
        return addZ(minutes) + ":" + addZ(seconds);
    }

    return addZ(hours) + ":" + addZ(minutes) + ":" + addZ(seconds);
}

export const declOfNum = (number, titles) => {
    let cases = [2, 0, 1, 1, 1, 2];
    return titles[
        number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
    ];
};

export const searchQueryParams = (page, searchParams) => {
    if (!page) {
        return `?size=10000`;
    }

    let params = `?page=${page}`;

    if (!searchParams) {
        return params;
    }

    let tags = "";

    //subjects
    if (searchParams.selectedSubjects?.length) {
        let subjects = searchParams.selectedSubjects.join(",");
        tags += subjects;
    }

    if (searchParams.selectedClasses?.length) {
        if (tags.trim()) {
            tags += ",";
        }
        let classes = searchParams.selectedClasses.join(",");
        tags += classes;
    }

    if (tags.trim()) {
        params += `&tags_id=${tags}`;
    }

    if (searchParams.selectedTheme?.length) {
        let themes = searchParams.selectedTheme.join(",");
        params += `&themes_id=${themes}`;
    }

    if (searchParams.selectedDifficult) {
        params += `&questionDifficult=${searchParams.selectedDifficult}`;
    }

    if (searchParams.selectedOrder) {
        params += `&sort=${searchParams.selectedOrder.value}`;
    }

    return params;
};

const timeOptions = {
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    year: "numeric",
};

export const convertDateTime = (date, suffix) => {
    let newDate = new Date(date).toLocaleString("ru-RU", timeOptions);

    if (suffix) {
        newDate = newDate.replace("в", suffix);
    }

    return newDate;
};

export const translate = (str) => {
    const ru_en = {
        а: "a",
        б: "b",
        в: "v",
        г: "g",
        д: "d",
        е: "e",
        ё: "e",
        ж: "j",
        з: "z",
        и: "i",
        к: "k",
        л: "l",
        м: "m",
        н: "n",
        о: "o",
        п: "p",
        р: "r",
        с: "s",
        т: "t",
        у: "u",
        ф: "f",
        х: "h",
        ц: "c",
        ч: "ch",
        ш: "sh",
        щ: "shch",
        ы: "y",
        э: "e",
        ю: "u",
        я: "ya",
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
        f: "f",
        g: "g",
        h: "h",
        i: "i",
        j: "j",
        k: "k",
        l: "l",
        m: "m",
        n: "n",
        o: "o",
        p: "p",
        q: "q",
        r: "r",
        s: "s",
        t: "t",
        u: "u",
        v: "v",
        w: "w",
        x: "x",
        y: "y",
        z: "z",
        " ": "-",
        ":": "",
        "«": "",
        "»": "",
    };

    let n_str = [];

    str = str.replace(/[ъь]+/g, "").replace(/й/g, "i");

    for (let i = 0; i < str.length; ++i) {
        let sym = ru_en[str[i]];

        if (sym) {
            n_str.push(sym);
        } else {
            let symLower = str[i].toLowerCase();
            if (ru_en[symLower]) {
                n_str.push(ru_en[symLower]);
            }
        }
    }

    return n_str.join("");
};

export const getFileTypeByFileName = (filename) => {
    if (!filename) {
        return null;
    }

    let splittedFilename = filename.split(".");
    return splittedFilename[splittedFilename.length - 1];
};
