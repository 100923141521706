import {
    IMAGE_FROM_BANK_ANSWER_PAIRS,
    IMAGE_FROM_UPLOAD_ANSWER_PAIRS,
    TEXT_ANSWER_PAIRS,
} from "entries/constructors/pairs-test/core/types/pairs-test-types";
import Hints from "entries/hints/hints";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import {PairsPlayer} from "entries/player/views/task-players/pairs-player/pairs-player";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export const PairsPlayerContainer = ({
    taskId,
    onSelectCorrectAnswer,
    questionApi,
    getInfoAboutSet,
    playerMode,
    answerIsLoading,
    setAnswerIsLoading,
}) => {
    const [question, setQuestion] = useState(null);
    const [properties, setProperties] = useState(null);

    const [staticAnswers, setStaticAnswers] = useState([]);
    const [dynamicAnswers, setDynamicAnswers] = useState([]);

    let {id} = useParams();

    useEffect(() => {
        getData();
    }, [taskId]);

    const getData = async () => {
        setAnswerIsLoading(true);
        let data = undefined;
        await questionApi(taskId, id)
            .then((response) => {
                if (playerMode === PlayerV2Modes.TOURNAMENT) {
                    data = response.data[0].question;
                } else {
                    data = response.data;
                    getInfoAboutSet();
                }
            })
            .catch(() => console.log("load data error"))
            .finally(() => setAnswerIsLoading(false));

        if (!data) {
            return;
        }

        setQuestion(data.pair);

        let tempStatic = [];
        let tempDynamic = [];
        data.pairAnswerVariants.map((answer) => {
            let answerForSave = {};
            answerForSave.id = answer.id;
            answerForSave.type = answer.objectType;

            if (answer.objectType === TEXT_ANSWER_PAIRS) {
                answerForSave.content = answer.text;
            } else if (answer.objectType === IMAGE_FROM_UPLOAD_ANSWER_PAIRS) {
                answerForSave.content = answer.answerImageQuestion.fileDownloadUri.slice(1);
            } else if (answer.objectType === IMAGE_FROM_BANK_ANSWER_PAIRS) {
                answerForSave.content = answer.answerImageBank.fileDownloadUri.slice(1);
            }

            if (answer.isLeftColumn) {
                answer.type = answer.objectType;
                tempStatic.push(answerForSave);
            } else {
                tempDynamic.push(answerForSave);
            }
        });

        setStaticAnswers(tempStatic);
        setDynamicAnswers(tempDynamic);
        setProperties(data.questionProperties);
    };

    const fillCorrectAnswers = (answers) => {
        let staticAnswers = [];
        let dynamicAnswers = [];
        answers.forEach((answer) => {
            staticAnswers.push({
                ...answer.oneCorrectAnswer,
                content:
                    answer.oneCorrectAnswer?.text ||
                    answer.oneCorrectAnswer?.answerImageBank?.fileDownloadUri?.slice(1),
                type: answer.oneCorrectAnswer.objectType,
            });
            dynamicAnswers.push({
                ...answer.twoCorrectAnswer,
                content:
                    answer.twoCorrectAnswer?.text ||
                    answer.twoCorrectAnswer?.answerImageBank?.fileDownloadUri?.slice(1),
                type: answer.twoCorrectAnswer.objectType,
            });
        });

        setDynamicAnswers(dynamicAnswers);
        setStaticAnswers(staticAnswers);
    };

    return (
        <React.Fragment>
            {!answerIsLoading && (
                <React.Fragment>
                    {playerMode === PlayerV2Modes.ACADEMY && (
                        <Hints question={question} onBuyCorrectAnswer={fillCorrectAnswers} />
                    )}

                    {question && properties && (
                        <PairsPlayer
                            question={question}
                            staticAnswers={staticAnswers}
                            dynamicAnswers={dynamicAnswers}
                            setDynamicAnswers={setDynamicAnswers}
                            properties={properties}
                            onSelectCorrectAnswer={onSelectCorrectAnswer}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

PairsPlayerContainer.propTypes = {
    getInfoAboutSet: PropTypes.func,
    playerMode: PropTypes.string,
    questionApi: PropTypes.func,
    taskId: PropTypes.number,
    onSelectCorrectAnswer: PropTypes.func,
    answerIsLoading: PropTypes.bool,
    setAnswerIsLoading: PropTypes.func,
};
