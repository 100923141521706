import {
    BASKETS_TEST,
    CLASSIC_TEST,
    CLIPPING_SECONDARY_TEST,
    CLIPPING_TEST,
    DICTATION_TEST,
    OPEN_ANSWER_TEST,
    PAIRS_TEST,
    SEQUENCES,
    SEQUENCES_IMAGE_TEST,
    SEQUENCES_TEXT_TEST,
    WORD_MANY_SKIP_TEST,
    WORD_SEQUENCE_SKIP_TEST,
    WORD_SKIP_TEST,
} from "config/constructor-names";
import {BasketsAPI} from "entries/constructors/baskets-game/core/BasketsAPI";
import {ClassicTestAPI} from "entries/constructors/classic-test/core/api/classic-test";
import {ClippingAPI} from "entries/constructors/clipping/core/ClippingAPI";
import {ClippingSecondaryAPI} from "entries/constructors/clipping/core/ClippingSecondaryAPI";
import {DictationAPI} from "entries/constructors/dictation/core/api/dictation";
import {OpenAnswerAPI} from "entries/constructors/open-answer/core/api/open-answer";
import {PairsTestAPI} from "entries/constructors/pairs-test/core/api/pairs";
import {SequencesTestAPI} from "entries/constructors/sequences/core/api/sequences";
import {SentenceSkipAPI} from "entries/constructors/word-skip/core/api/sentence-skip";
import {WordSkipAPI} from "entries/constructors/word-skip/core/api/word-skip";
import {WordSkipAdditionalAPI} from "entries/constructors/word-skip/core/api/word-skip-additional";

export const getConstructorData = (constructorName, id, instance, isTextAnswer) => {
    if (constructorName === SEQUENCES) {
        if (isTextAnswer) {
            constructorName = SEQUENCES_TEXT_TEST;
        } else {
            constructorName = SEQUENCES_IMAGE_TEST;
        }
    }

    let taskId = parseInt(id);
    switch (constructorName) {
        case CLASSIC_TEST:
            return {
                id: taskId,
                name: "Классический",
                api: ClassicTestAPI,
                editUrl: instance.CLASSIC_TEST.EDITING.replace(":id", taskId),
            };
        case DICTATION_TEST:
            return {
                id: taskId,
                name: "Диктант",
                api: DictationAPI,
                editUrl: instance.DICTATION.EDITING.replace(":id", taskId),
            };

        case SEQUENCES_TEXT_TEST:
            return {
                id: taskId,
                name: "Последовательность (текст)",
                api: SequencesTestAPI,
                editUrl: instance.TEXT_SEQUENCE_TEST.EDITING.replace(":id", taskId),
            };

        case SEQUENCES_IMAGE_TEST:
            return {
                id: taskId,
                name: "Последовательность (картинки)",
                api: SequencesTestAPI,
                editUrl: instance.NUMERIC_SEQUENCE_TEST.EDITING.replace(":id", taskId),
            };

        case PAIRS_TEST:
            return {
                id: taskId,
                name: "Пары",
                api: PairsTestAPI,
                editUrl: instance.PAIRS_TEST.EDITING.replace(":id", taskId),
            };

        case WORD_SKIP_TEST:
            return {
                id: taskId,
                name: "Пропуск слов",
                api: WordSkipAPI,
                editUrl: instance.WORD_SKIP_TEST.EDITING.replace(":id", taskId),
            };

        case WORD_SEQUENCE_SKIP_TEST:
            return {
                id: taskId,
                name: "Составление предложения",
                api: SentenceSkipAPI,
                editUrl: instance.SENTENCE_TEST.EDITING.replace(":id", taskId),
            };
        case WORD_MANY_SKIP_TEST:
            return {
                id: taskId,
                name: "Множ. подстановка слов",
                api: WordSkipAdditionalAPI,
                editUrl: instance.WORD_SKIP_ADDITIONAL_TEST.EDITING.replace(":id", taskId),
            };
        case OPEN_ANSWER_TEST:
            return {
                id: taskId,
                name: "Открытый ответ",
                api: OpenAnswerAPI,
                editUrl: instance.OPEN_ANSWER_TEST.EDITING.replace(":id", taskId),
            };
        case BASKETS_TEST:
            return {
                id: taskId,
                name: "Корзины",
                api: BasketsAPI,
                editUrl: instance.BASKETS_TEST.EDITING.replace(":id", taskId),
            };
        case CLIPPING_TEST:
            return {
                id: taskId,
                name: "1 к 1",
                api: ClippingAPI,
                editUrl: instance.CLIPPING_TEST.EDITING.replace(":id", taskId),
            };
        case CLIPPING_SECONDARY_TEST:
            return {
                id: taskId,
                name: "1 ко мн.",
                api: ClippingSecondaryAPI,
                editUrl: instance.CLIPPING_SECONDARY_TEST.EDITING.replace(":id", taskId),
            };
    }
};
