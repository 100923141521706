import React, {useEffect, useState} from "react";
import {ITaskEngine} from "../../../interfaces/ITaskEngine";
import styles from "./styles.module.sass";
import {Alert, Button, Skeleton} from "antd";
import {useHistory} from "react-router-dom";

interface IProps {
    engine: ITaskEngine;
}

const EnginePreview = ({engine}: IProps) => {
    const [isVideoLoading, setIsVideoLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (engine?.videoURL) {
            setIsVideoLoading(true);
        }
    }, [engine]);

    return (
        <div className={styles.previewEngine}>
            <p className={styles.title}>Пример задания</p>
            {engine.videoURL ? (
                <React.Fragment>
                    {isVideoLoading && (
                        <Skeleton.Node
                            className={styles.skeletonHeader}
                            style={{width: 588, height: 788}}
                            active
                        >
                            {" "}
                        </Skeleton.Node>
                    )}

                    <video
                        id="video"
                        autoPlay
                        width={588}
                        height={788}
                        loop
                        onCanPlay={() => setIsVideoLoading(false)}
                    >
                        <source src={engine.videoURL} type="video/mp4" />
                    </video>
                </React.Fragment>
            ) : (
                <img src={engine.image} alt="preview-engine" />
            )}
            <Alert
                message="Награда"
                description={`Если ваше задание пройдет модерацию вы получите ${engine.rewardForCreate} ед. рейтинга.
                     За каждое решение пользователей вы дополнительно получите ${engine.rewardForResolve} ед. рейтинга.`}
                type="info"
                showIcon
                className={styles.alert}
            />
            <Button
                type="primary"
                size={"large"}
                onClick={() => history.push(engine.constructorURL)}
            >
                Выбрать режим "{engine.name}"
            </Button>
        </div>
    );
};

export default EnginePreview;
