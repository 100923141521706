import {CLIPPING_TEST} from "config/constructor-names";
import CreateTest from "entries/constructors/classic-test/views/containers/create-test/create-test";
import {ClippingAPI} from "entries/constructors/clipping/core/ClippingAPI";
import ClippingEngine from "entries/constructors/clipping/views/clipping-engine";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";

export const ManyToManyDndTestManager = ({routerInstance}) => {
    let history = useHistory();
    const [testData, setTestData] = useState({});
    const [mode, setMode] = useState(null);

    useEffect(() => {
        if (
            !history.location.state &&
            !history.location.pathname.includes(
                routerInstance.MANY_TO_MANY_DND_TEST.EDITING.replace(":id", ""),
            )
        ) {
            history.push(routerInstance.ROOT);
        }

        setMode(history.location.state);
    }, []);

    const onCreateTest = (testData) => {
        setTestData(testData);
        history.push(routerInstance.MANY_TO_MANY_DND_TEST.CONSTRUCTOR);
    };

    return (
        <div>
            <Switch>
                <Route path={routerInstance.MANY_TO_MANY_DND_TEST.CREATE_TEST}>
                    <CreateTest
                        onCreateTest={onCreateTest}
                        apiInstance={ClippingAPI}
                        mode={mode}
                        constructorType={CLIPPING_TEST}
                        settings={{isManyToMany: true}}
                    />
                </Route>

                <Route path={routerInstance.MANY_TO_MANY_DND_TEST.EDITING}>
                    <ClippingEngine
                        testData={testData}
                        isEditMode={true}
                        isSecondary={false}
                        isManyToMany={true}
                    />
                </Route>

                <Route path={routerInstance.MANY_TO_MANY_DND_TEST.CONSTRUCTOR}>
                    <ClippingEngine
                        testData={testData}
                        mode={mode}
                        isSecondary={false}
                        isManyToMany={true}
                    />
                </Route>
            </Switch>
        </div>
    );
};

ManyToManyDndTestManager.propTypes = {
    routerInstance: PropTypes.any,
};
