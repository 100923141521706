import cn from "classnames";
import {convertTextWithSymbols} from "core/utils/converter";
import {MDBBtn, MDBPopover, MDBPopoverBody} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";

import styles from "./text-with-word-skip.module.sass";

export const TextWithWordSkip = ({
    text,
    answers,
    skipSymbol,
    selectedAnswers,
    selectWordHandle,
}) => {
    return (
        <div
            className={cn({
                [styles.container]: true,
            })}
        >
            {text.split(" ").map((word, index) => {
                let wordForView;
                let existedWord = selectedAnswers.find((item) => item.position === index);
                if (word === skipSymbol) {
                    wordForView = (
                        <MDBPopover
                            placement="bottom"
                            popover
                            clickable
                            id={`popper${index}`}
                            key={index}
                            className={styles.popupContainer}
                        >
                            <MDBBtn
                                color="info"
                                className={styles.skipButton}
                                size="sm"
                                style={{height: 25}}
                            >
                                {selectedAnswers && selectedAnswers.length > 0 && existedWord
                                    ? existedWord.text
                                    : " "}
                            </MDBBtn>
                            <div>
                                <MDBPopoverBody>
                                    <div className={styles.variantsWrapper}>
                                        {answers.map((answer, answerIndex) => {
                                            if (answer.position === index) {
                                                return (
                                                    <span
                                                        key={answerIndex}
                                                        className={styles.variant}
                                                        onClick={() => selectWordHandle(answer)}
                                                    >
                                                        {answer.text}
                                                    </span>
                                                );
                                            }
                                        })}
                                    </div>
                                </MDBPopoverBody>
                            </div>
                        </MDBPopover>
                    );
                } else {
                    return convertTextWithSymbols(word) + " ";
                }

                return wordForView;
            })}
        </div>
    );
};

TextWithWordSkip.propTypes = {
    answers: PropTypes.array,
    selectedAnswers: PropTypes.array,
    selectWordHandle: PropTypes.func,
    skipSymbol: PropTypes.string,
    text: PropTypes.string,
};
