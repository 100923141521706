import {TOAST_ERROR_CONFIG, url} from "config";
import {BASKETS_TEST} from "config/constructor-names";
import {BasketsAPI} from "entries/constructors/baskets-game/core/BasketsAPI";
import BasketsConstructor from "entries/constructors/baskets-game/views/baskets-constructor/baskets-constructor";
import {NARRATORS} from "entries/constructors/classic-test/views/components/properties-buttons";
import {CreateQuestion} from "entries/constructors/classic-test/views/containers/create-question";
import {TestParameters} from "entries/constructors/classic-test/views/containers/test-parameters";
import {useConstructor} from "entries/constructors/hooks/useConstructor";
import PlayerV2NotificationsLayer from "entries/player-v2/views/player-v2-notifications-layer/player-v2-notifications-layer";
import PlayerV2NotificationLoading from "entries/player-v2/views/player-v2-notifications/player-v2-notification-loading/player-v2-notification-loading";
import Konva from "konva";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import useImage from "use-image";
import {TEXT_TYPE} from "utils/answers-config";
import {ConstructorNavbar} from "views/components/constructor-navbar";
import {Col} from "views/components/layout/col";
import {SaveContent} from "views/components/layout/save-content/save-content";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {TestOptions} from "views/components/layout/test-options";
import {PropertyColumn} from "views/containers/property-column";
import TestSave from "views/containers/test-save/test-save";
import {useWizard} from "views2/conatiners/CreateTestWizard";

const BasketsGame = ({testData, isEditMode}) => {
    const loadAnswers = (data) => {
        if (data.backgroundGame.fileDownloadUri) {
            setImageUrl(data.backgroundGame);
        }
    };

    const {
        testId,
        questionText,
        setQuestionText,
        narrator,
        textTitleSize,
        setCurrentPropertyTab,
        tabNames,
        background,
        setBackground,
        setNarrator,
        currentPropertyTab,
        setTextTitleSize,
        questionType,
        textAnswerSize,
        setTextAnswerSize,
    } = useConstructor({
        isEditMode,
        constructorName: BASKETS_TEST,
        testData,
        loadAnswersCallback: loadAnswers,
    });
    const {STEP, stepsOrder, currentStep, to, enabledSteps, enableStep, toNext} = useWizard();
    const [baskets, setBaskets] = useState([]);
    const [gameBackground] = useImage(null);
    // drop
    const [basketIdForDrop, setBasketIdForDrop] = useState(null);
    const [selectedBasketId, setSelectedBasketId] = useState(null);
    // drag
    const [draggableId, setDraggableId] = useState(null);

    // фон холста
    const [imageUrl, setImageUrl] = useState("");
    const [image] = useImage(imageUrl && url + imageUrl.fileDownloadUri.slice(1));

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        enableStep(STEP.ANSWER);
    }, [questionText, narrator]);

    // answers
    const [answers, setAnswers] = useState([]);

    const onMoveAnswer = (e) => {
        let answerX = e.evt.layerX;
        let answerY = e.evt.layerY;

        baskets.forEach((basket) => {
            if (
                answerX >= basket.x &&
                answerX <= basket.width + basket.x &&
                answerY >= basket.y &&
                answerY <= basket.y + basket.height
            ) {
                if (basket.id !== basketIdForDrop) {
                    setBasketIdForDrop(basket.id);
                }
            } else {
                if (basket.id === basketIdForDrop) {
                    setBasketIdForDrop(null);
                }
            }
        });
    };

    const deleteAnswerFromBasket = (answer) => {
        let tempBaskets = baskets.map((basket) => {
            let updatedBasket = basket.answers.filter((ba) => ba.id !== answer.id);
            return {...basket, answers: updatedBasket};
        });
        setBaskets(tempBaskets);
    };

    const releaseDrop = () => {
        let answer = answers.find((answer) => answer.id === draggableId);

        // если перетаскиваем в пустую область и элемент уже привязан к корзине, то удаляем его
        if (!basketIdForDrop) {
            deleteAnswerFromBasket(answer);
        } else {
            // если ответ выбран
            if (answer) {
                let tempBaskets = baskets.map((basket) => {
                    //находим существует ли уже этот элемент в корзине
                    let existedIndex = basket.answers.findIndex((ba) => ba.id === draggableId);
                    if (basket.id === basketIdForDrop) {
                        // если его нет, то добавляем
                        if (existedIndex === -1) {
                            // если этот ответ привязян уже к другой корзине, то удаляем его из старой
                            if (answer.chainedBasketId && answer?.chainedBasketId !== basket.id) {
                                let chainedBaskedIndex = baskets.findIndex(
                                    (b) => b.id === answer.chainedBasketId,
                                );
                                let tempBaskets = baskets.slice();
                                tempBaskets[chainedBaskedIndex].answers = tempBaskets[
                                    chainedBaskedIndex
                                ].answers.filter((a) => a.id !== answer.id);
                                setBaskets(tempBaskets);
                            }

                            // привязываем новую корзину
                            answer.chainedBasketId = basketIdForDrop;

                            let tempAnswers = answers.map((ta) =>
                                ta.id === answer.id ? answer : ta,
                            );

                            setAnswers(tempAnswers);
                            return {...basket, answers: [...basket.answers, answer]};
                        } else {
                            // пропускаем добавление, т.к элемент уже в корзине
                            return basket;
                        }
                    } else {
                        // пропускаем, т.к элемент не для этой корзины
                        return basket;
                    }
                });
                setBaskets(tempBaskets);
            }
        }

        setBasketIdForDrop(null);
        setDraggableId(null);
    };

    const deleteAnswer = (answerId) => {
        setIsLoading(true);
        BasketsAPI.deleteAnswer(testId, answerId)
            .then(() => {
                let answer = answers.find((answer) => answer.id === answerId);
                let tempAnswers = answers.filter((answer) => answer.id !== answerId);

                if (answer.chainedBasketId) {
                    let tempBaskets = baskets.slice();
                    let basketIndex = baskets.findIndex(
                        (basket) => basket.id === answer.chainedBasketId,
                    );
                    let basket = tempBaskets[basketIndex];
                    let updatedAnswers = basket.answers.filter((answer) => answer.id !== answerId);
                    tempBaskets[basketIndex] = {...basket, answers: updatedAnswers};
                    setBaskets(tempBaskets);
                }
                setAnswers(tempAnswers);
            })
            .catch(() => toast.error("Ошибка удаления ответа", TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));
    };

    const deleteBasket = (basketId) => {
        let confirmed = window.confirm(
            "Внимание! В случае удаления ответы отвяжутся от этой корзины",
        );
        if (confirmed) {
            setIsLoading(true);

            BasketsAPI.deleteBasket(testId, basketId)
                .then(() => {
                    let tempAnswers = answers;
                    tempAnswers.forEach(
                        (answer) =>
                            answer.chainedBasketId === basketId && delete answer["chainedBasketId"],
                    );

                    let tempBaskets = baskets.filter((basket) => basket.id !== basketId);
                    setBaskets(tempBaskets);
                    setAnswers(tempAnswers);
                })
                .catch(() => toast.error("Ошибка удаления корзины", TOAST_ERROR_CONFIG))
                .finally(() => setIsLoading(false));
        }
    };

    const createBasketCopy = (basket) => {
        const shapeProps = {
            x: 10,
            y: 10,
            width: basket.width,
            height: basket.height,
            fill: "rgba(0, 0, 0, 0.2)",
            color: Konva.Util.getRandomColor(),
            answers: [],
        };

        setIsLoading(true);
        BasketsAPI.createBasket(testId, {
            x: shapeProps.x,
            y: shapeProps.y,
            width: basket.width,
            height: basket.height,
        })
            .then((response) => {
                setBaskets((prev) => [...prev, {...shapeProps, id: response.data.id}]);
            })
            .catch(() => toast.error("Ошибка создания корзины", TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));
    };

    return (
        <React.Fragment>
            <ConstructorNavbar
                tabs={stepsOrder}
                currentTab={currentStep}
                availableTabs={enabledSteps}
                onSelect={(tab) => to(tab)}
                tabNames={tabNames}
            />

            <Col size={12} className="d-flex justify-content-between">
                {currentStep === STEP.QUESTION || currentStep === STEP.ANSWER ? (
                    <React.Fragment>
                        <TestContent
                            className="d-flex justify-content-start position-relative"
                            isFullWidth
                            background={background}
                        >
                            {isLoading ? (
                                <PlayerV2NotificationsLayer isActive={true}>
                                    <PlayerV2NotificationLoading />
                                </PlayerV2NotificationsLayer>
                            ) : (
                                <TestConstructor size="full">
                                    <CreateQuestion
                                        apiInstance={BasketsAPI}
                                        questionId={testId}
                                        questionTitle={testData.title}
                                        questionText={questionText}
                                        updateQuestionText={(text) => setQuestionText(text)}
                                        selectPropertyNarrator={() =>
                                            setCurrentPropertyTab(NARRATORS)
                                        }
                                        narrator={narrator}
                                        isEditMode={currentStep === STEP.QUESTION}
                                        testHasNarrator
                                        textTitleSize={textTitleSize}
                                        withEditButtons={false}
                                    />

                                    <BasketsConstructor
                                        answers={answers}
                                        draggableId={draggableId}
                                        background={gameBackground}
                                        setAnswers={setAnswers}
                                        basketIdForDrop={basketIdForDrop}
                                        onMoveAnswer={onMoveAnswer}
                                        releaseDrop={releaseDrop}
                                        baskets={baskets}
                                        setBaskets={setBaskets}
                                        setDraggableId={setDraggableId}
                                        selectedBasketId={selectedBasketId}
                                        setSelectedBasketId={setSelectedBasketId}
                                        testId={testId}
                                        image={image}
                                        setImageUrl={setImageUrl}
                                        isEditMode={currentStep === STEP.QUESTION}
                                        textAnswerSize={textAnswerSize}
                                    />
                                </TestConstructor>
                            )}
                        </TestContent>
                    </React.Fragment>
                ) : (
                    <TestParameters
                        apiInstance={BasketsAPI}
                        testId={testId}
                        mode={questionType}
                        isEditMode={isEditMode}
                    />
                )}

                {(currentStep === STEP.QUESTION || currentStep === STEP.ANSWER) && (
                    <Col size={4}>
                        <TestOptions>
                            {currentStep === STEP.QUESTION && (
                                <PropertyColumn
                                    selectImage={(background) => setBackground(background)}
                                    selectNarrator={(narrator) =>
                                        setNarrator({
                                            id: narrator.id,
                                            url: `${url}${narrator.url}`,
                                        })
                                    }
                                    background={background}
                                    textTitleSize={textTitleSize}
                                    setTextTitleSize={(size) =>
                                        setTextTitleSize(Number.parseInt(size))
                                    }
                                    currentPropertyTab={currentPropertyTab}
                                    onSelectTab={(tab) => setCurrentPropertyTab(tab)}
                                    narrator={narrator}
                                    constructorType={BASKETS_TEST}
                                    basketsToolsData={{answers, baskets, gameBackground}}
                                    setBaskets={setBaskets}
                                    deleteBasket={deleteBasket}
                                    deleteAnswer={deleteAnswer}
                                    selectedBasketId={selectedBasketId}
                                    isQuestionTextExist={!!questionText}
                                    setTextAnswerSize={(size) => setTextAnswerSize(parseInt(size))}
                                    textAnswerSize={textAnswerSize}
                                    answerType={TEXT_TYPE}
                                    createBasketCopy={createBasketCopy}
                                />
                            )}
                            {currentStep === STEP.ANSWER && (
                                <SaveContent>
                                    <h5>Проверьте задачу</h5>
                                    <TestSave
                                        questionId={testId}
                                        backgroundId={background?.id}
                                        apiInstance={BasketsAPI}
                                        answersForSave={baskets}
                                        constructorName={BASKETS_TEST}
                                        narratorId={narrator?.id}
                                        mode={questionType}
                                        onSaveProperties={() => {
                                            toNext();
                                        }}
                                        sizeTitle={textTitleSize}
                                        sizeText={textAnswerSize}
                                    />
                                </SaveContent>
                            )}
                        </TestOptions>
                    </Col>
                )}
            </Col>
        </React.Fragment>
    );
};

BasketsGame.propTypes = {
    isEditMode: PropTypes.bool,
    testData: PropTypes.object,
};

export default BasketsGame;
