import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import {SelectSymbolBox} from "entries/constructors/dictation/views/containers/select-symbol-box/select-symbol-box";

export const SelectSymbolBoxContainer = ({
    word,
    selectedSymbolIndex,
    setSelectedSymbolIndex,
    selectedWordIndex,
    skips,
}) => {
    const [separatedWord, setSeparatedWord] = useState([]);
    useEffect(() => {
        if (word) {
            setSelectedSymbolIndex(null);
            let tempSeparatedWord = word.split("");
            setSeparatedWord(tempSeparatedWord);
        }
    }, [word]);

    const handleSelectSymbol = (index) => {
        if (selectedSymbolIndex === index) {
            setSelectedSymbolIndex(null);
            return;
        }
        setSelectedSymbolIndex(index);
    };

    return (
        <SelectSymbolBox
            separatedWord={separatedWord}
            onSelectSymbolIndex={handleSelectSymbol}
            selectedSymbolIndex={selectedSymbolIndex}
            selectedWordIndex={selectedWordIndex}
            skips={skips}
        />
    );
};

SelectSymbolBoxContainer.propTypes = {
    selectedSymbolIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    selectedWordIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    setSelectedSymbolIndex: PropTypes.func,
    skips: PropTypes.array,
    word: PropTypes.string,
};
