import {IMAGE_FROM_BANK} from "core/types/content-types";
import {IMAGE_FROM_UPLOAD_ANSWER_CLASSIC} from "entries/constructors/classic-test/utils/answer-types";

import {Image} from "entries/constructors/classic-test/views/components/image-answer";
import {SequencesTestAPI} from "entries/constructors/sequences/core/api/sequences";
import styles from "entries/constructors/sequences/numeric-sequence-test/views/containers/create-image-sequence-answers/create-image-sequence-answers.module.sass";

import PropTypes from "prop-types";
import React, {useState} from "react";
import {ConvertUploadImageFromResponse} from "utils/convert-response-data";
import {ImageUploadModal} from "views/components/image-upload-modal/image-upload-modal";
import {NumericSequenceContainer, NumericSequenceItem} from "views/components/NumericSequence";
import {SpinnerPage} from "views/components/spinner";

export const CreateImageSequenceAnswers = (props) => {
    const {answers, isEditMode, questionId, updateAnswers, updateCorrectAnswers, elementSize} =
        props;
    const [imageModalVisible, setImageModalVisible] = useState(false);
    const [isImageEditMode, setIsImageEditMode] = useState(false);
    const [answerForEdit, setAnswerForEdit] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const uploadImage = (image) => {
        let componentImage;
        let cropArea = image.areaForCrop;

        SequencesTestAPI.addImageAnswer(
            questionId,
            image.image,
            cropArea.height,
            cropArea.width,
            cropArea.x,
            cropArea.y,
        )
            .then((response) => {
                let image = ConvertUploadImageFromResponse(
                    response.data.answerImageQuestion,
                    response.data.id,
                );
                componentImage = {
                    id: response.data.id,
                    component: <Image image={{id: image.id, url: image.url}} />,
                    type: image.type,
                };
                updateAnswers([...answers, componentImage]);
            })
            .catch((error) => console.log(error));
    };

    const uploadImageFromBank = (image) => {
        let componentImage;

        SequencesTestAPI.addImageAnswerFromBank(questionId, image.id).then((response) => {
            componentImage = {
                id: response.data.id,
                component: <Image image={{id: image.id, url: image.fileDownloadUri.slice(1)}} />,
                type: IMAGE_FROM_BANK,
            };
            updateAnswers([...answers, componentImage]);
        });
    };

    const handleSaveImage = (image) => {
        if (image.type === IMAGE_FROM_UPLOAD_ANSWER_CLASSIC) {
            uploadImage(image);
        } else if (image.type === IMAGE_FROM_BANK) {
            uploadImageFromBank(image);
        }

        setImageModalVisible(false);
    };

    const handleDelete = (id) => {
        SequencesTestAPI.deleteAnswer(questionId, id).catch((err) => console.log(err));
        let cloneAnswers = answers;
        cloneAnswers = cloneAnswers.filter((answer) => answer.id !== id);
        updateAnswers(cloneAnswers);
    };

    const handleEdit = (id) => {
        let answerForEdit = answers.find((answer) => answer.id === id);
        console.log(answerForEdit, id);

        if (answerForEdit) {
            setIsImageEditMode(true);
            setImageModalVisible(true);
            setAnswerForEdit(answerForEdit);
        }
    };

    const handleEditImage = async (image) => {
        if (!(image || image.type || answerForEdit)) return;

        let newImage = null;

        // if (image.type.include !== answerForEdit.type) {
        //     toast.info(
        //         "Изменить изображение из разных типов загрузки нельзя. Используйте удаление",
        //         TOAST_INFO_CONFIG,
        //     );
        //     return;
        // }

        if (image.type === IMAGE_FROM_UPLOAD_ANSWER_CLASSIC) {
            setIsLoading(true);
            let crop = image.areaForCrop;
            await SequencesTestAPI.editImageAnswerFromUpload(
                questionId,
                answerForEdit.id,
                image.image,
                crop.height,
                crop.width,
                crop.x,
                crop.y,
            )
                .then((response) => {
                    let image = ConvertUploadImageFromResponse(
                        response.data.answerImageQuestion,
                        response.data.id,
                    );
                    newImage = {
                        id: response.data.id,
                        component: <Image image={{id: image.id, url: image.url}} />,
                        type: IMAGE_FROM_UPLOAD_ANSWER_CLASSIC,
                    };
                })
                .finally(() => setIsLoading(false));
        } else if (image.type === IMAGE_FROM_BANK) {
            setIsLoading(true);
            await SequencesTestAPI.editImageAnswerFromBank(questionId, answerForEdit.id, image.id)
                .then(() => {
                    newImage = {
                        id: answerForEdit.id,
                        component: (
                            <Image
                                image={{
                                    id: image.id,
                                    url: image.fileDownloadUri.slice(1),
                                }}
                            />
                        ),
                        type: IMAGE_FROM_BANK,
                    };
                })
                .catch((err) => console.log(err))
                .finally(() => setIsLoading(false));
        }

        let cloneAnswers = answers;
        cloneAnswers = cloneAnswers.map((answer) =>
            answer.id === answerForEdit.id ? newImage : answer,
        );
        updateAnswers(cloneAnswers);

        setIsImageEditMode(false);
        setAnswerForEdit(null);
        setImageModalVisible(false);
    };

    return (
        <>
            <ImageUploadModal
                visible={imageModalVisible}
                toggle={() => {
                    setImageModalVisible(!imageModalVisible);
                    setIsImageEditMode(false);
                }}
                onSaveImage={isImageEditMode ? handleEditImage : handleSaveImage}
                testId={questionId}
                svgDisabled
                withCrop
            />

            {isLoading ? (
                <SpinnerPage primary />
            ) : (
                <React.Fragment>
                    <NumericSequenceContainer
                        isEditMode={isEditMode}
                        onChange={(answers) => updateCorrectAnswers(answers)}
                    >
                        {answers.map((item, index) => (
                            <NumericSequenceItem
                                key={index}
                                id={item.id}
                                isEditMode={isEditMode}
                                elementSize={elementSize}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                            >
                                {item.component}
                            </NumericSequenceItem>
                        ))}
                    </NumericSequenceContainer>

                    {answers.length < 6 && isEditMode && (
                        <div className={styles.addImage} onClick={() => setImageModalVisible(true)}>
                            <p className="link">
                                <i className="fas fa-plus mr-1" /> Добавить изображение
                            </p>
                        </div>
                    )}
                </React.Fragment>
            )}
        </>
    );
};

CreateImageSequenceAnswers.propTypes = {
    answers: PropTypes.array,
    elementSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isEditMode: PropTypes.bool,
    questionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    updateAnswers: PropTypes.func,
    updateCorrectAnswers: PropTypes.func,
};
