import {url} from "config";

export const SLIDER_IMAGE_URLS = {
    TOURNAMENT: {
        INVITE: `${url + "data/image-bank/4a043d8d-e134-4eab-a05d-843ea1835f01.png"}`,
        SUBJECT: `${url + "data/image-bank/2fb72283-069e-4c5f-8e51-1ac71cd20139.png"}`,
        FIGHT: `${url + "data/image-bank/818d1852-f483-4b0a-9351-87baf12761d6.png"}`,
        REGISTER: `${url + "data/image-bank/94a2ea5d-bb3f-4e16-b5d0-69b754fadfea.png"}`,
    },
};

export const SLIDER_IMAGE_URLS_OTHER = {
    REGISTER_BUTTON: `${url + "data/image-bank/4852d786-11d4-45ac-a7e4-e15bdac3d112.png"}`,
};
