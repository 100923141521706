import { useState } from "react";

export const useSelectCheckBox = () => {
    const [selectedItems, setSelectedItems] = useState([]);

    const handleSelect = (id, items = null) => {
        if (items) {
            setSelectedItems(items)
            return
        }

        let isExist = selectedItems.find(item => item === id)
        if (isExist) {
            setSelectedItems(selectedItems.filter(item => item !== id))
        } else {
            setSelectedItems([...selectedItems, id])
        }
    };

    const reset = () => {
        setSelectedItems([])
    }

    return [selectedItems, handleSelect, reset];
};
