import React, {useEffect, useState} from "react";
import {AsyncPaginate} from "react-select-async-paginate";
import PropTypes from "prop-types";

const GeoSelectSchool = ({handleSelect, loadOptions, cityId, regionId, selectedSchool}) => {
    const [key, setKey] = useState(0);

    useEffect(() => {
        setKey((prev) => prev + 1);
    }, [cityId]);

    return (
        <AsyncPaginate
            key={key}
            styles={{menu: (provided) => ({...provided, zIndex: 9999})}}
            onChange={handleSelect}
            closeMenuOnSelect
            loadOptions={loadOptions}
            additional={{
                page: 1,
            }}
            isDisabled={!cityId || !regionId}
            placeholder="Выберите школу"
            value={selectedSchool}
        />
    );
};

GeoSelectSchool.propTypes = {
    cityId: PropTypes.number,
    handleSelect: PropTypes.func,
    loadOptions: PropTypes.func,
    regionId: PropTypes.number,
    selectedSchool: PropTypes.object,
};

export default GeoSelectSchool;
