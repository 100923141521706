import {TOAST_ERROR_CONFIG} from "config";
import {ErrorStatus} from "core/api/error-status";
import {SubscriptionsAPI} from "core/api/subcriptions";
import {UserAPI} from "core/api/user";
import SubscriptionPromocodeSuccessful from "entries/notifications/subscription-promocode-successful/subscription-promocode-successful";
import {useStores} from "hooks/use-stores";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {ACADEMY_ROUTES} from "routes/main";
import BuySubscription from "views/pages/about/sliders/buy-subscription-slide/buy-subscription";
import PropTypes from "prop-types";
import {PaymentAPI} from "views/pages/about/sliders/buy-subscription-slide/core/PaymentAPI";

const SUBSCRIBE_CLASS_TABS = {
    FIRST: 1,
    SECOND: 2,
    THIRD: 3,
    FOUR: 4,
};

const AVAILABLE_TABS = [
    {
        value: SUBSCRIBE_CLASS_TABS.FIRST,
        subjects: ["Логика", "Этикет"],
    },
    {
        value: SUBSCRIBE_CLASS_TABS.SECOND,
        subjects: ["Логика", "Этикет"],
    },
    {
        value: SUBSCRIBE_CLASS_TABS.THIRD,
        subjects: ["Логика", "Этикет"],
    },
    {
        value: SUBSCRIBE_CLASS_TABS.FOUR,
        subjects: ["Логика", "Этикет"],
    },
];

const BuySubscriptionContainer = ({isPaymentDone, withPaymentResult}) => {
    const [selectedTab, setSelectedTab] = useState(AVAILABLE_TABS[0]);
    const [currentSlide, setCurrentSlide] = useState(1);
    const [isPaymentParametersLoading, setIsPaymentParametersLoading] = useState(false);
    const [mySubscriptions, setMySubscriptions] = useState([]);
    const [subscription, setSubscription] = useState(null);
    const [subscriptionsIsLoading, setSubscriptionsIsLoading] = useState(false);
    const [allSubscriptions, setAllSubscriptions] = useState([]);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const {userStore} = useStores();

    const buttonWrapperRef = useRef(null);
    const promoRef = useRef(null);

    const history = useHistory();

    useEffect(() => {
        if (userStore.user) {
            getUserSubscriptions();
            let classTag = userStore.user.classTag?.id;
            if (classTag && classTag <= 4) {
                let newTab = AVAILABLE_TABS.find((tab) => tab.value === classTag);
                if (newTab) {
                    setSelectedTab(newTab);
                }
            }
        }
    }, [userStore.user]);

    const getUserSubscriptions = () => {
        setSubscriptionsIsLoading(true);
        UserAPI.getMySubscriptions()
            .then((response) => {
                setMySubscriptions(response.data);
            })
            .finally(() => setSubscriptionsIsLoading(false));
    };

    const handleSelect = () => {
        setCurrentSlide(2);
    };

    useEffect(() => {
        getAllSubscriptions();
    }, []);

    const getAllSubscriptions = () => {
        setSubscriptionsIsLoading(true);
        SubscriptionsAPI.getSubscriptions()
            .then((res) => {
                setAllSubscriptions(res.data.content);
            })
            .catch(() => {
                toast.error(
                    "Для покупки подписки необходимо пройти авторизацию",
                    TOAST_ERROR_CONFIG,
                );
            })
            .finally(() => {
                setSubscriptionsIsLoading(false);
            });
    };

    useEffect(() => {
        if (mySubscriptions.length > 0) {
            let sub = mySubscriptions.find(
                (sub) => sub.subscription?.classTag?.id === selectedTab.value,
            );

            if (sub) {
                setSubscription({
                    classTag: selectedTab.value,
                    isExpired: new Date(sub.activeUntil) < new Date(),
                    ...sub,
                });
            } else {
                setSubscription(null);
            }
        }
    }, [selectedTab, currentSlide, mySubscriptions]);

    const handlePayment = (id, promoValue) => {
        if (promoValue) {
            promoValue = promoValue.replaceAll("−", "");
        }
        setIsPaymentParametersLoading(true);
        PaymentAPI.getPaymentParameters(id, promoValue)
            .then((response) => {
                if (response.data) {
                    window.open(
                        `https://auth.robokassa.ru/Merchant/Index.aspx?${response.data}`,
                        "_self",
                    );
                }
            })
            .catch((e) => {
                if (e.response.data.message == 682) {
                    setModalContent(
                        <SubscriptionPromocodeSuccessful
                            onClick={() => {
                                setModalIsVisible(false);
                                setModalContent(null);
                                history.push(ACADEMY_ROUTES.ROOT);
                            }}
                        />,
                    );
                    setModalIsVisible(true);
                } else {
                    toast.error(ErrorStatus[e.response.data.message]);
                }
            })
            .finally(() => setIsPaymentParametersLoading(false));
    };

    return (
        <BuySubscription
            tabs={AVAILABLE_TABS}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
            isPaymentDone={isPaymentDone}
            withPaymentResult={withPaymentResult}
            handleSelect={handleSelect}
            buttonWrapperRef={buttonWrapperRef}
            isPaymentParametersLoading={isPaymentParametersLoading}
            subscription={subscription}
            subscriptionsIsLoading={subscriptionsIsLoading}
            handlePayment={handlePayment}
            allSubscriptions={allSubscriptions}
            promoRef={promoRef}
            modalIsVisible={modalIsVisible}
            setModalIsVisible={setModalIsVisible}
            modalContent={modalContent}
        />
    );
};

BuySubscriptionContainer.propTypes = {
    isPaymentDone: PropTypes.bool,
    withPaymentResult: PropTypes.bool,
};

export default BuySubscriptionContainer;
