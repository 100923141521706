import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {EditButtons} from "views/components/edit-buttons";
import {MDBPopover, MDBPopoverBody, MDBBtn} from "mdbreact";

import styles from "entries/constructors/dictation/views/containers/text-box-with-symbol-skips/text-box-with-symbol-skips.module.sass";

export const TextBoxWithSymbolSkips = ({
    splittedText,
    skipSymbols,
    editAction,
    deleteAction,
    withBorder = true,
    isPlayMode = false,
    onSelectVariant,
    selectedPositions,
    answers,
    isEditMode = true,
}) => {
    return (
        <div
            className={cn({
                [styles.container]: true,
                [styles.border]: withBorder && isEditMode,
            })}
        >
            {isPlayMode ? (
                <>
                    {splittedText
                        .join(" ")
                        .split("")
                        .map((symbol, index) => {
                            let symbolForView;
                            let symbolExist = selectedPositions.filter(
                                (symbol) => symbol.index === index,
                            );
                            let symbolGroup = answers?.filter(
                                (answer) => answer.position === index,
                            );

                            if (skipSymbols.includes(symbol)) {
                                symbolForView = (
                                    <MDBPopover
                                        placement="bottom"
                                        popover
                                        clickable
                                        id={`popper${index}`}
                                        key={index}
                                        className={styles.popupContainer}
                                    >
                                        <MDBBtn
                                            className={cn({
                                                [styles.skipButton]: true,
                                                [styles.activeButton]: symbolExist.length > 0,
                                                [styles.unactiveButton]: !(symbolExist.length > 0),
                                            })}
                                            size="sm"
                                            style={{
                                                height: 30,
                                            }}
                                        >
                                            <p
                                                style={{
                                                    marginTop: "-2px",
                                                }}
                                            >
                                                {symbolExist.length > 0
                                                    ? symbolExist[0].symbol.name
                                                    : " "}
                                            </p>
                                        </MDBBtn>
                                        <div>
                                            <MDBPopoverBody>
                                                <div className={styles.variantsWrapper}>
                                                    {symbolGroup.length > 0 &&
                                                        symbolGroup[0].symbolGroupDictation.symbols.map(
                                                            (symbol, idx) => {
                                                                return (
                                                                    <div
                                                                        key={idx}
                                                                        onClick={() =>
                                                                            onSelectVariant(
                                                                                index,
                                                                                symbol,
                                                                            )
                                                                        }
                                                                        className={styles.variant}
                                                                    >
                                                                        {symbol.altName}
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                </div>
                                            </MDBPopoverBody>
                                        </div>
                                    </MDBPopover>
                                );
                            } else {
                                symbolForView = symbol;
                            }
                            return symbolForView;
                        })}
                </>
            ) : (
                <>
                    {splittedText.map((word, idx) => {
                        return (
                            <span key={idx}>
                                {word.split("").map((symbol, index) => {
                                    let symbolForView;
                                    if (skipSymbols.includes(symbol)) {
                                        symbolForView = (
                                            <span key={index} className="skipBox">
                                                {" "}
                                            </span>
                                        );
                                    } else {
                                        symbolForView = symbol;
                                    }
                                    return symbolForView;
                                })}{" "}
                            </span>
                        );
                    })}
                    {isEditMode && (
                        <EditButtons
                            deleteAction={deleteAction}
                            editAction={editAction}
                            withoutRightBorder
                            withoutTopBorder
                        />
                    )}
                </>
            )}
        </div>
    );
};

TextBoxWithSymbolSkips.propTypes = {
    skipSymbols: PropTypes.array.isRequired,
    splittedText: PropTypes.array.isRequired,
    answers: PropTypes.any,
    deleteAction: PropTypes.func,
    editAction: PropTypes.func,
    isEditMode: PropTypes.bool,
    isPlayMode: PropTypes.bool,
    selectedPositions: PropTypes.array,
    withBorder: PropTypes.bool,
    onSelectVariant: PropTypes.func,
};
