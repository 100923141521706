import {IMAGE_FROM_BANK} from "core/types/content-types";
import {AddQuestionButtons} from "entries/constructors/classic-test/views/components/add-question-buttons";
import {Formula} from "entries/constructors/classic-test/views/components/formula";
import {Image} from "entries/constructors/classic-test/views/components/image-answer";
import {PairsTestAPI} from "entries/constructors/pairs-test/core/api/pairs";
import {
    FORMULA_ANSWER_PAIRS,
    IMAGE_FROM_BANK_ANSWER_PAIRS,
    IMAGE_FROM_UPLOAD_ANSWER_PAIRS,
    TEXT_ANSWER_PAIRS,
} from "entries/constructors/pairs-test/core/types/pairs-test-types";
import React, {useState} from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "entries/constructors/pairs-test/views/containers/pairs-test/pairs-test.module.sass";

import {Text} from "entries/constructors/classic-test/views/components/text-answer";
import {DraggableItem, DraggableList} from "views/components/DraggableList";
import {EditButtons} from "views/components/edit-buttons";
import {ImageUploadModal} from "views/components/image-upload-modal/image-upload-modal";
import {TwoColumnAnswers} from "views/components/layout/two-column-answers/two-column-answers";
import {SpinnerPage} from "views/components/spinner";
import {TextUploadModal} from "views/components/text-upload-modal/text-upload-modal";

export const CreatePairsAnswers = (props) => {
    const STATIC_COLUMN = "STATIC_COLUMN";
    const DYNAMIC_COLUMN = "DYNAMIC_COLUMN";

    const [staticTextModal, setStaticTextModal] = useState(false);
    const [dynamicTextModal, setDynamicTextModal] = useState(false);

    const [staticImageModal, setStaticImageModal] = useState(false);
    const [dynamicImageModal, setDynamicImageModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const {
        staticAnswers,
        dynamicAnswers,
        isEditMode,
        textAnswerFontSize,
        updateDynamicAnswers,
        updateStaticAnswers,
        questionId,
        elementSize,
    } = props;

    const saveTextAnswer = (answer, columnType) => {
        setIsLoading(true);
        PairsTestAPI.createTextAnswer(answer, questionId, columnType === STATIC_COLUMN && true)
            .then((response) => {
                let createdAnswer = {
                    id: response.data.id,
                    type: response.data.objectType,
                    content: response.data.text,
                };
                if (columnType === STATIC_COLUMN) {
                    updateStaticAnswers([...staticAnswers, createdAnswer]);
                    setStaticTextModal(false);
                } else {
                    updateDynamicAnswers([...dynamicAnswers, createdAnswer]);
                    setDynamicTextModal(false);
                }
            })
            .catch((error) => {
                process.env.NODE_ENV === "development" &&
                    console.log(error, `Create error answer:${answer}`);
            })
            .finally(() => setIsLoading(false));
    };

    const saveImageAnswer = (answer, columnType) => {
        if (answer.type === IMAGE_FROM_BANK) {
            setIsLoading(true);
            PairsTestAPI.addImageAnswerFromBank(
                questionId,
                answer.id,
                columnType === STATIC_COLUMN && true,
            )
                .then((response) => {
                    let createdAnswer = {
                        id: response.data.id,
                        type: response.data.objectType,
                        content: response.data.answerImageBank.fileDownloadUri.slice(1),
                    };
                    updateImageAnswersAfterResponse(createdAnswer, columnType);
                })
                .catch((error) => {
                    process.env.NODE_ENV === "development" &&
                        console.log(error, `Error answer: ${answer}`);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else if (answer.type === IMAGE_FROM_UPLOAD_ANSWER_PAIRS) {
            let areaForCrop = answer.areaForCrop;
            setIsLoading(true);
            PairsTestAPI.addImageAnswer(
                questionId,
                answer.image,
                areaForCrop.height,
                areaForCrop.width,
                areaForCrop.x,
                areaForCrop.y,
                columnType === STATIC_COLUMN && true,
            )
                .then((response) => {
                    let createdAnswer = {
                        id: response.data.id,
                        type: response.data.objectType,
                        content: response.data.answerImageQuestion.fileDownloadUri.slice(1),
                    };
                    updateImageAnswersAfterResponse(createdAnswer, columnType);
                })
                .catch(
                    (error) =>
                        process.env.NODE_ENV === "development" &&
                        console.log(error, `Error answer: ${answer}`),
                )
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const updateImageAnswersAfterResponse = (createdAnswer, columnType) => {
        if (columnType === STATIC_COLUMN) {
            updateStaticAnswers([...staticAnswers, createdAnswer]);
            setStaticImageModal(false);
        } else {
            updateDynamicAnswers([...dynamicAnswers, createdAnswer]);
            setDynamicImageModal(false);
        }
    };

    const deleteAnswer = (id, columnType) => {
        setIsLoading(true);
        PairsTestAPI.deleteAnswer(questionId, id)
            .then(() => {
                if (columnType === STATIC_COLUMN) {
                    let newAnswers = staticAnswers.filter((answer) => answer.id !== id);
                    updateStaticAnswers(newAnswers);
                } else {
                    let newAnswers = dynamicAnswers.filter((answer) => answer.id !== id);
                    updateDynamicAnswers(newAnswers);
                }
            })
            .catch((error) => {
                process.env.NODE_ENV === "development" &&
                    console.log(error, `Delete error ID:${id}`);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <TextUploadModal
                visible={staticTextModal}
                toggle={() => setStaticTextModal(!staticTextModal)}
                maxSymbols={100}
                saveText={(answer) => saveTextAnswer(answer, STATIC_COLUMN)}
            />

            <TextUploadModal
                visible={dynamicTextModal}
                toggle={() => setDynamicTextModal(!dynamicTextModal)}
                maxSymbols={100}
                saveText={(answer) => saveTextAnswer(answer, DYNAMIC_COLUMN)}
            />

            <ImageUploadModal
                visible={staticImageModal}
                isLoading={isLoading}
                toggle={() => setStaticImageModal(!staticImageModal)}
                onSaveImage={(answer) => saveImageAnswer(answer, STATIC_COLUMN)}
                withCrop
                svgDisabled
                testType={"Pairs"}
            />

            <ImageUploadModal
                visible={dynamicImageModal}
                isLoading={isLoading}
                toggle={() => setDynamicImageModal(!dynamicImageModal)}
                onSaveImage={(answer) => saveImageAnswer(answer, DYNAMIC_COLUMN)}
                withCrop
                svgDisabled
                testType={"Pairs"}
            />

            <TwoColumnAnswers>
                <div className={styles.staticAnswers}>
                    {staticAnswers.map((answer, index) => (
                        <div
                            key={index}
                            className={cn({
                                [styles.answerContainer]: true,
                                [styles.border]: isEditMode,
                            })}
                            style={{height: `${elementSize}px`}}
                        >
                            {isEditMode && (
                                <EditButtons
                                    itemId={answer.id}
                                    deleteAction={() => deleteAnswer(answer.id, STATIC_COLUMN)}
                                    withoutRightBorder
                                    withoutTopBorder
                                />
                            )}
                            {answer.type === TEXT_ANSWER_PAIRS && (
                                <Text
                                    style={{fontSize: textAnswerFontSize}}
                                    text={answer.content}
                                    withBorder={false}
                                />
                            )}
                            {answer.type === IMAGE_FROM_BANK_ANSWER_PAIRS && (
                                <Image imageUrl={answer.content} className="h-100" />
                            )}
                            {answer.type === IMAGE_FROM_UPLOAD_ANSWER_PAIRS && (
                                <Image imageUrl={answer.content} className="h-100" />
                            )}
                            {answer.type === FORMULA_ANSWER_PAIRS && <Formula formulaURI="" />}
                        </div>
                    ))}
                    {isEditMode && staticAnswers.length < 5 && (
                        <div className={styles.createContainer}>
                            <p>Составить пару</p>
                            {isLoading ? (
                                <SpinnerPage primary />
                            ) : (
                                <AddQuestionButtons
                                    formulaAction={() => console.log("formula")}
                                    formulaVisible={false}
                                    imageAction={() => setStaticImageModal(true)}
                                    imageVisible={true}
                                    textAction={() => setStaticTextModal(true)}
                                    textVisible={true}
                                    withLabels={false}
                                />
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.dynamicAnswers}>
                    <DraggableList
                        onSort={updateDynamicAnswers}
                        items={dynamicAnswers}
                        pressDelay={0}
                    >
                        {dynamicAnswers.map((answer, index) => (
                            <DraggableItem key={`item-${index}`} id={`item-${index}`} index={index}>
                                <div
                                    className={cn({
                                        [styles.answerContainer]: true,
                                        [styles.border]: isEditMode,
                                        noselect: true,
                                    })}
                                    style={{height: `${elementSize}px`}}
                                >
                                    {isEditMode && (
                                        <EditButtons
                                            itemId={answer}
                                            deleteAction={() =>
                                                deleteAnswer(answer.id, DYNAMIC_COLUMN)
                                            }
                                            withoutRightBorder
                                            withoutTopBorder
                                        />
                                    )}
                                    {answer.type === TEXT_ANSWER_PAIRS && (
                                        <Text
                                            style={{fontSize: textAnswerFontSize}}
                                            text={answer.content}
                                            withBorder={false}
                                        />
                                    )}
                                    {answer.type === IMAGE_FROM_BANK_ANSWER_PAIRS && (
                                        <Image
                                            imageUrl={answer.content}
                                            className="h-100 noselect"
                                        />
                                    )}
                                    {answer.type === IMAGE_FROM_UPLOAD_ANSWER_PAIRS && (
                                        <Image
                                            imageUrl={answer.content}
                                            className="h-100 noselect"
                                        />
                                    )}
                                    {answer.type === FORMULA_ANSWER_PAIRS && (
                                        <Formula formulaURI="" />
                                    )}
                                </div>
                            </DraggableItem>
                        ))}
                    </DraggableList>
                    {isEditMode && dynamicAnswers.length < 5 && (
                        <div className={styles.createContainer}>
                            <p>Составить пару</p>
                            {isLoading ? (
                                <SpinnerPage primary />
                            ) : (
                                <AddQuestionButtons
                                    formulaAction={() => console.log("formula")}
                                    formulaVisible={false}
                                    imageAction={() => setDynamicImageModal(true)}
                                    imageVisible={true}
                                    textAction={() => setDynamicTextModal(true)}
                                    textVisible={true}
                                    withLabels={false}
                                />
                            )}
                        </div>
                    )}
                </div>
            </TwoColumnAnswers>
        </>
    );
};

CreatePairsAnswers.propTypes = {
    dynamicAnswers: PropTypes.array,
    elementSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isEditMode: PropTypes.bool,
    questionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    staticAnswers: PropTypes.array,
    textAnswerFontSize: PropTypes.number,
    updateDynamicAnswers: PropTypes.func,
    updateStaticAnswers: PropTypes.func,
};
