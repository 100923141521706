import React from "react";
import {url} from "config";

export const MOBILE_PRESENTATION_TOURNAMENT = [
    {
        title: (
            <>
                Учишься в начальной школе? <br /> Вступай в образовательную игру вместе с Умной
                Вороной! <br /> Каждую неделю новые турниры!
            </>
        ),
        image: `${url}data/image-bank/7c0eac5d-b825-42de-84a2-7e5075f0b7aa.png`,
    },
    {
        title: <>Выбери любимый предмет и покажи, на что способен</>,
        image: `${url}data/image-bank/6416efc3-ca95-4970-8a29-a22db7f3e6ce.png`,
    },
    {
        title: <>Решай интересные задачи и соревнуйся с другими учениками в знаниях и скорости!</>,
        image: `${url}data/image-bank/98b9c204-8134-4370-bb68-1875406eac97.png`,
    },
    {
        title: (
            <>
                Ставь рекорды и выигрывай уникальные награды! <br />
                Вперёд за наградами! <br /> Вперёд за заниями!
            </>
        ),
        image: `${url}data/image-bank/bc14904e-7000-46b0-a64d-cf950101c2a9.png`,
    },
];

export const MOBILE_PRESENTATION_ACADEMY = [
    {
        title: (
            <>
                Тысячи интерактивных заданий для 1-4 классов по школьной программе. <br />
                Учитесь играя вместе с Умной Вороной!
            </>
        ),
        image: `${url}data/image-bank/c2ceaee3-b303-4fe0-a712-ca7dfc80ea34.png`,
    },
    {
        title: (
            <>
                Доступна с любой планеты! Закрепляйте школьные знания, открывайте новое и тренируйте
                смекалку вместе с Умной Вороной!
            </>
        ),
        image: `${url}data/image-bank/eacfbe8e-e81d-40ef-9bc3-7203f8c37aa5.png`,
    },
    {
        title: (
            <>
                В составе Академии всё <br /> самое интересное и полезное
            </>
        ),
        image: `${url}data/image-bank/a86facdb-0922-483e-8457-f36ef6a10dfe.png`,
    },
    {
        title: (
            <>
                Соревнуйтесь с другими учениками со всей России в знаниях и скорости. <br /> Не
                бойтесь стать лучшим!
            </>
        ),
        image: `${url}data/image-bank/e54b4d8d-9c54-468c-91b4-fb8b704709f2.png`,
    },
    {
        title: (
            <>
                Комфортная среда! <br /> Здесь нет комментариев и лайков, нет чатов и личных
                сообщений. <br /> Только дружелюбная атмосфера и полезные знания!
            </>
        ),
        image: `${url}data/image-bank/e1138a40-b9c3-4675-90dd-da097b1ddb65.png`,
    },
    {
        title: (
            <>
                Что главное, участие или результат? <br /> Дипломы точно станут приятным дополнением
                к школьному портфолио.
            </>
        ),
        image: `${url}data/image-bank/e8312006-e073-4aef-846b-c46d02e775d9.png`,
    },
];

export const MOBILE_PRESENTATION_TEACHER = [
    {
        title: (
            <>
                Постройте свой онлайн-класс! <br />
                Создавайте интерактивные задания легко и быстро, а затем отправляйте их своим
                ученикам!
            </>
        ),
        image: `${url}data/image-bank/69fc7e43-e158-4588-b3f7-db942d0c0173.png`,
    },
    {
        title: (
            <>
                Целая фабрика заданий! <br />
                Создавайте задания с помощью удобного конструктора, базы готовых заданий и
                изображений.
            </>
        ),
        image: `${url}data/image-bank/c2a483e7-c10d-4a21-8db9-7fbee5a17a68.png`,
    },
    {
        title: (
            <>
                Пополняя общий банк заданий, мы обмениваемся идеями и опытом, чтобы сделать любимую
                работу легче и интересней!
            </>
        ),
        image: `${url}data/image-bank/00157044-01a7-4ca3-90e7-446e43c5a8f3.png`,
    },
    {
        title: (
            <>
                Накопленный рейтинг можно выгодно поменять на реальные и, главное, полезные подарки
                – вебинары, рецензии и аттестационный пакет.
            </>
        ),
        image: `${url}data/image-bank/be356919-03af-4f49-bc0f-fff7b62c630e.png`,
    },
    {
        title: (
            <>
                Умная Ворона работает для педагогов бесплатно. И так будет всегда!
            </>
        ),
        image: `${url}data/image-bank/e892939c-6f76-442d-aa37-939645ac53f9.png`,
    },
];
