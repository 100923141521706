import {TOAST_SUCCESS_CONFIG} from "config";
import {auth} from "core/utils/auth";
import {ConversationsAPI} from "entries/conversations/api/conversations-api";
import {MDBBtn, MDBModal, MDBModalBody} from "mdbreact";
import React, {useState} from "react";
import {toast} from "react-toastify";
import styles from "./styles.module.sass";

const ClassRequestFormModal = ({setRequestModalIsOpen, requestModalIsOpen}) => {
    const [data, setData] = useState({
        name: "",
        telephone: "",
        time: "",
        school: "",
        city: "",
        class: "",
    });

    const {keycloak} = auth;

    const handleSendMessage = () => {
        let message = `Заявка на подключение. \n ФИО: ${data.name} \n Телефон: ${data.telephone} \n Удобное время: ${data.time} \n Город: ${data.city} \n Школа: ${data.school} \n Класс: ${data.class}`;
        ConversationsAPI.sendMessage(5893, {reply: message}).then(() => {
            toast.success("Заявка отправлена", TOAST_SUCCESS_CONFIG);
            setRequestModalIsOpen(false);
        });
    };

    return (
        <MDBModal
            isOpen={requestModalIsOpen}
            toggle={() => setRequestModalIsOpen(!requestModalIsOpen)}
            centered
            overflowScroll={false}
        >
            <MDBModalBody>
                <p className={styles.title}>Заявка на бесплатное подключение</p>

                {keycloak.authenticated ? (
                    <React.Fragment>
                        <p className={styles.description}>ФИО</p>
                        <input
                            type="text"
                            id="symbolName"
                            className="form-control"
                            value={data.name}
                            onChange={(e) => setData({...data, name: e.target.value})}
                        />

                        <div className={styles.inputGroup}>
                            <div>
                                <p className={styles.description}>Телефон для связи с вами</p>
                                <input
                                    type="text"
                                    id="symbolName"
                                    className="form-control"
                                    value={data.telephone}
                                    onChange={(e) => setData({...data, telephone: e.target.value})}
                                />
                            </div>

                            <div>
                                <p className={styles.description}>Удобное время (по МСК)</p>
                                <input
                                    type="text"
                                    id="symbolName"
                                    className="form-control"
                                    value={data.time}
                                    onChange={(e) => setData({...data, time: e.target.value})}
                                />
                            </div>
                        </div>

                        <p className={styles.description}>Ваш город</p>
                        <input
                            type="text"
                            id="symbolName"
                            className="form-control"
                            value={data.city}
                            onChange={(e) => setData({...data, city: e.target.value})}
                        />

                        <div className={styles.inputGroup}>
                            <div>
                                <p className={styles.description}>Название вашей школы</p>
                                <input
                                    type="text"
                                    id="symbolName"
                                    className="form-control"
                                    value={data.school}
                                    onChange={(e) => setData({...data, school: e.target.value})}
                                />
                            </div>

                            <div>
                                <p className={styles.description}>Класс</p>
                                <input
                                    type="text"
                                    id="symbolName"
                                    className="form-control"
                                    value={data.class}
                                    onChange={(e) => setData({...data, class: e.target.value})}
                                />
                            </div>
                        </div>

                        <p className={styles.description}>
                            Мы с удовольствием перезвоним вам и всё объясним. <br /> Или вы сами
                            сможете позвонить нам +7 (8362) 41-32-95
                        </p>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <p className={styles.description} style={{margin: "20px 0"}}>
                            Подать заявку на бесплатное подключение могут только зарегистрированные
                            пользователи.
                        </p>

                        <p className={styles.description} style={{margin: "20px 0"}}>
                            Пожалуйста, сначала пройдите процедуру регистрации, это также бесплатно.
                        </p>
                    </React.Fragment>
                )}

                <div className={styles.controls}>
                    {keycloak.authenticated ? (
                        <MDBBtn color="success" onClick={handleSendMessage}>
                            Отправить
                        </MDBBtn>
                    ) : (
                        <MDBBtn color="success" onClick={() => keycloak.login()}>
                            Зарегистрироваться
                        </MDBBtn>
                    )}

                    <MDBBtn color="blue-grey" onClick={() => setRequestModalIsOpen(false)}>
                        Закрыть
                    </MDBBtn>
                </div>
            </MDBModalBody>
        </MDBModal>
    );
};

export default ClassRequestFormModal;
