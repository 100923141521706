import React from "react";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import cn from "classnames";
import {useTranslation} from "react-i18next";

import {TOAST_ERROR_CONFIG} from "config";

import {Button} from "views2/components/Button";
import {STEP} from "./constants";

import styles from "./CreateTestWizard.module.sass";

export const CreateTestName = (props) => {
    const {onSubmit, onSubmitSuccess, defaultName = "", className} = props;
    const [testName, setTestName] = React.useState(defaultName);
    const [loading, setLoading] = React.useState(false);

    const {t} = useTranslation("create_test_wizard");

    const handleSubmit = React.useCallback(
        (event) => {
            event.preventDefault();

            setLoading(true);

            const trimmedTestName = testName ? testName.trim() : "";

            if (trimmedTestName.length > 0) {
                const result = onSubmit({name: trimmedTestName});

                if (typeof result?.finally === "function") {
                    return result.finally(() => setLoading(false)).then(() => onSubmitSuccess());
                } else {
                    setLoading(false);
                    return onSubmitSuccess();
                }
            } else {
                toast.error(t("emptyNameNotification"), TOAST_ERROR_CONFIG);
            }
        },
        [testName, onSubmit],
    );

    const handleChange = React.useCallback((event) => {
        setTestName(event.target.value);
    }, []);

    return (
        <form
            className={cn({
                "form-group": true,
                "shadow-3": true,
                [styles.container]: true,
                [className]: !!className,
            })}
            onSubmit={handleSubmit}
        >
            <div className={styles.createContainer}>
                <div className="md-form">
                    <input
                        autoComplete="false"
                        className="form-control"
                        id="test-name"
                        onChange={handleChange}
                        type="text"
                        value={testName}
                    />
                    <label htmlFor="test-name" className="text-dark">
                        {t("enterTestName")}
                    </label>
                    <p className={styles.prompt}>({t("testNameDescription")})</p>
                </div>
            </div>

            <Button type="submit" disabled={!testName || loading} color="success" size="md">
                {loading && (
                    <span
                        className="spinner-border spinner-border-sm mr-3"
                        role="status"
                        aria-hidden="true"
                    />
                )}
                {t("action")}
            </Button>
        </form>
    );
};

CreateTestName.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onSubmitSuccess: PropTypes.func.isRequired,
    className: PropTypes.string,
    defaultName: PropTypes.string,
    loading: PropTypes.bool,
};

CreateTestName.step = STEP.NAME;
