import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {toast} from "react-toastify";

import {TOAST_SUCCESS_CONFIG} from "config/ui";

import {TagsAPI} from "core/api/tags";

import {FirstLevelTagsContainer} from "views/containers/tag-manager/first-level-section-tags";

import {TagManagerList, TagManagerSection} from "views/components/tag-manager";

const SecondLevelSectionTagsTitle = () => {
    const {t} = useTranslation("tag_manager");

    return <h5 className="font-weight-bold">{t("secondLevelSectionTitle")}</h5>;
};

export const SectionLevelTagsContainer = (props) => {
    const {id, withoutThirdLevel} = props;
    const {t} = useTranslation("tag_manager");

    const [loaded, setLoaded] = React.useState(false);
    const [tags, setTags] = React.useState([]);

    const loadTags = () => {
        if (withoutThirdLevel) {
            TagsAPI.getSecondLevelTags()
                .then((response) => {
                    setTags(response.data);
                })
                .finally(() => {
                    setLoaded(true);
                });
        } else {
            TagsAPI.getSecondLevelTagsBySectionThree(props.id)
                .then((response) => {
                    setTags(response.data);
                })
                .finally(() => {
                    setLoaded(true);
                });
        }
    };

    React.useEffect(() => {
        loadTags();
    }, [withoutThirdLevel]);

    const handleAddClick = React.useCallback(() => {
        const name = prompt(t("newTagName"));

        if (name) {
            TagsAPI.addSecondLevelTag(name)
                .then((response) => {
                    if (withoutThirdLevel) {
                        loadTags();
                    } else {
                        TagsAPI.addTwoSectionToThreeSection(response.data.id, id).then(() => {
                            loadTags();
                            toast.success(t("tagCreated"), TOAST_SUCCESS_CONFIG);
                        });
                    }
                })
                .catch((error) => {
                    console.error("Adding tag error", error);
                });
        }
    }, [t, id]);

    const handleRemoveClick = React.useCallback(
        (tagId) => {
            TagsAPI.deleteSecondLevelTag(tagId)
                .then(() => {
                    loadTags();
                    toast.success(t("tagRemoved"), TOAST_SUCCESS_CONFIG);
                })
                .catch((error) => {
                    console.error("Removing tag error", error);
                });
        },
        [t, id],
    );

    const handleRenameClick = React.useCallback(
        (tagId, tagName) => {
            const name = prompt(t("newTagName"), tagName);

            if (name && name !== tagName) {
                TagsAPI.renameSecondLevelTag(tagId, name)
                    .then(() => {
                        loadTags();
                        toast.success(t("tagRenamed"), TOAST_SUCCESS_CONFIG);
                    })
                    .catch((error) => {
                        console.error("Renaming tag error", error);
                    });
            }
        },
        [t, id],
    );

    return (
        <TagManagerSection loaded={loaded} title={<SecondLevelSectionTagsTitle />}>
            <div>
                <button className="btn btn-success btn-sm ml-2" onClick={handleAddClick}>
                    Добавить секцию 2 уровня
                </button>
            </div>
            {tags.map((tag) => (
                <TagManagerList
                    key={tag.id}
                    id={tag.id}
                    name={tag.name}
                    onAddClick={handleAddClick}
                    onRemoveClick={handleRemoveClick}
                    onRenameClick={handleRenameClick}
                >
                    <FirstLevelTagsContainer {...tag} />
                </TagManagerList>
            ))}
        </TagManagerSection>
    );
};

SectionLevelTagsContainer.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    withoutThirdLevel: PropTypes.bool,
};
