import {TOAST_SUCCESS_CONFIG, url} from "config";
import {SEQUENCES_TEXT_TEST} from "config/constructor-names";
import {NARRATORS} from "entries/constructors/classic-test/views/components/properties-buttons";
import {CreateQuestion} from "entries/constructors/classic-test/views/containers/create-question";
import {TestParameters} from "entries/constructors/classic-test/views/containers/test-parameters";
import {useConstructor} from "entries/constructors/hooks/useConstructor";
import {SequencesTestAPI} from "entries/constructors/sequences/core/api/sequences";

import {CreateTextSequencesAnswers} from "entries/constructors/sequences/sequence-test/views/containers/create-text-sequence-answers";

import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {TEXT_TYPE} from "utils/answers-config";
import {ConstructorNavbar} from "views/components/constructor-navbar";
import {Col} from "views/components/layout/col";
import {SaveContent} from "views/components/layout/save-content/save-content";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {TestOptions} from "views/components/layout/test-options";
import {PropertyColumn} from "views/containers/property-column";
import TestSave from "views/containers/test-save/test-save";
import {useWizard} from "views2/conatiners/CreateTestWizard";
import {toast} from "react-toastify";

export const SequenceTest = ({testData, isEditMode, maxAnswersCount}) => {
    const loadAnswers = (answers, questionId, correctAnswers) => {
        if (correctAnswers) {
            let filteredAnswers = correctAnswers.map((correctAnswerId) => {
                let answerById = answers.find((answer) => answer.id === correctAnswerId);
                if (answerById) {
                    return answerById;
                }
            });

            if (filteredAnswers) {
                toast.success("Правильные ответы загружены", TOAST_SUCCESS_CONFIG);
                setAnswers(filteredAnswers);
            } else {
                setAnswers(filteredAnswers);
            }
        } else {
            setAnswers(answers);
        }
    };

    const {
        testId,
        questionText,
        setQuestionText,
        questionImage,
        setQuestionImage,
        questionFormula,
        setQuestionFormula,
        background,
        setBackground,
        narrator,
        setNarrator,
        textTitleSize,
        setTextTitleSize,
        textAnswerSize,
        setTextAnswerSize,
        currentPropertyTab,
        setCurrentPropertyTab,
        tabNames,
        questionType,
        questionAudio,
        setQuestionAudio,
    } = useConstructor({
        isEditMode,
        constructorName: SEQUENCES_TEXT_TEST,
        testData,
        loadAnswersCallback: loadAnswers,
    });

    const {
        STEP,
        stepsOrder,
        currentStep,
        toNext,
        to,
        enabledSteps,
        enableStep,
        disableStep,
        enableSteps,
    } = useWizard();

    const [textModalVisible, setTextModalVisible] = useState(false);
    const [answers, setAnswers] = useState([]);

    const onSelectNarrator = (narrator) => {
        setNarrator({
            id: narrator.id,
            url: `${url}${narrator.url}`,
        });
    };

    const updateAnswers = (answer) => {
        setAnswers([...answers, answer]);
        setTextModalVisible(false);
    };

    const deleteAnswer = (id) => {
        const nextAnswers = answers.filter((answer) => answer.id !== id);
        setAnswers(nextAnswers);
    };

    useEffect(() => {
        if (isEditMode) {
            enableSteps([STEP.ANSWER, STEP.QUESTION, STEP.PARAMS]);
            return;
        }

        if (questionText && answers.length >= 2 && narrator) {
            enableStep(STEP.ANSWER);
        } else {
            disableStep(STEP.ANSWER);
        }
    }, [questionText, answers, narrator, isEditMode]);

    return (
        <div>
            <ConstructorNavbar
                tabs={stepsOrder}
                currentTab={currentStep}
                availableTabs={enabledSteps}
                onSelect={(tab) => to(tab)}
                tabNames={tabNames}
            />
            <Col size={12}>
                {currentStep === STEP.QUESTION || currentStep === STEP.ANSWER ? (
                    <Col size={8} className="d-flex justify-content-start">
                        <TestContent background={background}>
                            <TestConstructor>
                                <CreateQuestion
                                    narrator={narrator}
                                    testHasNarrator
                                    questionId={testId}
                                    questionText={questionText}
                                    questionTitle={testData.title}
                                    textTitleSize={textTitleSize}
                                    questionImage={questionImage}
                                    questionFormula={questionFormula}
                                    isEditMode={currentStep === STEP.QUESTION}
                                    updateQuestionImage={(image) => setQuestionImage(image)}
                                    updateQuestionText={(text) => setQuestionText(text)}
                                    updateQuestionFormula={(formula) => setQuestionFormula(formula)}
                                    selectPropertyNarrator={() => setCurrentPropertyTab(NARRATORS)}
                                    apiInstance={SequencesTestAPI}
                                    questionAudio={questionAudio}
                                    updateQuestionAudio={setQuestionAudio}
                                />

                                <CreateTextSequencesAnswers
                                    answers={answers}
                                    setAnswers={setAnswers}
                                    textModalVisible={textModalVisible}
                                    setTextModalVisible={() =>
                                        setTextModalVisible(!textModalVisible)
                                    }
                                    questionId={testId}
                                    isEditMode={currentStep === STEP.QUESTION}
                                    maxAnswersCount={maxAnswersCount}
                                    textAnswerFontSize={textAnswerSize}
                                    updateAnswers={updateAnswers}
                                    onDeleteAnswer={deleteAnswer}
                                />
                            </TestConstructor>
                        </TestContent>
                    </Col>
                ) : (
                    <TestParameters
                        testId={testId}
                        apiInstance={SequencesTestAPI}
                        mode={questionType}
                        isEditMode={isEditMode}
                    />
                )}
                {(currentStep === STEP.QUESTION || currentStep === STEP.ANSWER) && (
                    <Col size={4}>
                        <TestOptions>
                            {currentStep === STEP.QUESTION && (
                                <PropertyColumn
                                    onSelectTab={(tab) => setCurrentPropertyTab(tab)}
                                    currentPropertyTab={currentPropertyTab}
                                    answerType={TEXT_TYPE}
                                    textAnswerSize={textAnswerSize}
                                    setTextAnswerSize={(size) =>
                                        setTextAnswerSize(Number.parseInt(size))
                                    }
                                    selectImage={(background) => setBackground(background)}
                                    selectNarrator={onSelectNarrator}
                                    textTitleSize={textTitleSize}
                                    setTextTitleSize={(size) =>
                                        setTextTitleSize(Number.parseInt(size))
                                    }
                                    isQuestionTextExist={!!questionText}
                                    toolTipText={
                                        "Передвигайте мышкой варианты ответов, чтобы изменить их последовательность."
                                    }
                                    background={background}
                                    narrator={narrator}
                                />
                            )}
                            {currentStep === STEP.ANSWER && (
                                <SaveContent>
                                    <h5>Проверьте ответ</h5>
                                    <p>
                                        Удостовертесь, что ответы находятся в правильной
                                        последовательности, а затем сохраните.
                                    </p>

                                    <TestSave
                                        apiInstance={SequencesTestAPI}
                                        questionId={testId}
                                        answersIdsForSave={answers.map((answer) => answer.id)}
                                        backgroundId={background.id}
                                        isTwoColumnMode={false}
                                        narratorId={narrator.id}
                                        sizeTitle={textTitleSize}
                                        sizeText={textAnswerSize}
                                        constructorName={SEQUENCES_TEXT_TEST}
                                        onSaveProperties={() => toNext()}
                                        mode={questionType}
                                    />
                                </SaveContent>
                            )}
                        </TestOptions>
                    </Col>
                )}
            </Col>
        </div>
    );
};

SequenceTest.propTypes = {
    isEditMode: PropTypes.bool,
    maxAnswersCount: PropTypes.number.isRequired,
    mode: PropTypes.string,
    testData: PropTypes.object,
};

SequenceTest.defaultProps = {
    maxAnswersCount: 6,
};
