import {TextWithSkipSymbol} from "entries/constructors/word-skip/utils/skips-to-symbols";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {TextWithSkips} from "entries/constructors/word-skip/views/text-with-skips/text-with-skips";
import {SpinnerPage} from "views/components/spinner";

export const SKIP_ID = "#";

export const TextWithSkipsContainer = ({
    text,
    skips,
    isEditMode,
    deleteAction,
    editAction,
    textIsLoading,
}) => {
    const [textWithSkipSymbols, setTextWithSkipSymbols] = useState([]);
    useEffect(() => {
        // [1, 2, 3, [4,5], 6, [7]] => [1, 2, 3, '!$!$', 6 '!$!$!']
        if (skips.length > 0) {
            let textWithSymbols = TextWithSkipSymbol(skips, text);
            setTextWithSkipSymbols(textWithSymbols);
        }
    }, [skips]);

    if (textIsLoading) {
        return <SpinnerPage primary />;
    }

    return (
        <TextWithSkips
            text={textWithSkipSymbols}
            isEditMode={isEditMode}
            deleteAction={deleteAction}
            editAction={editAction}
        />
    );
};

TextWithSkipsContainer.propTypes = {
    deleteAction: PropTypes.func,
    editAction: PropTypes.func,
    isEditMode: PropTypes.bool,
    skips: PropTypes.array,
    text: PropTypes.string,
    textIsLoading: PropTypes.bool,
};
