import {MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

const SaveButton = (props) => {
    const {
        handleSaveClick,
        isDisabled,
        isDisabledWithLoad,
        isBlockStyle,
        buttonText,
        className,
    } = props;
    return (
        <MDBBtn
            className={cn({
                btn: true,
                "blue-gradient": false,
                "btn-block": isBlockStyle,
                [className]: !!className,
            })}
            color="success"
            size="md"
            onClick={handleSaveClick}
            disabled={isDisabled}
        >
            {isDisabledWithLoad && (
                <span
                    className="spinner-border spinner-border-sm mr-3"
                    role="status"
                    aria-hidden="true"
                />
            )}
            {buttonText ? buttonText : "Сохранить и продолжить"}
        </MDBBtn>
    );
};

export default SaveButton;

SaveButton.propTypes = {
    buttonText: PropTypes.string,
    className: PropTypes.string,
    handleSaveClick: PropTypes.func,
    isBlockStyle: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isDisabledWithLoad: PropTypes.bool,
};
