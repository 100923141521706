import CreateTest from "entries/constructors/classic-test/views/containers/create-test/create-test";
import {PairsTestAPI} from "entries/constructors/pairs-test/core/api/pairs";
import {PairsTest} from "entries/constructors/pairs-test/views/containers/pairs-test";
import React, {useEffect, useState} from "react";

import {Route, Switch, useHistory} from "react-router-dom";
import PropTypes from "prop-types";

export const PairsManager = ({routerInstance}) => {
    let history = useHistory();
    const [testData, setTestData] = useState({});
    const [mode, setMode] = useState(undefined);

    useEffect(() => {
        if (
            !history.location.state &&
            !history.location.pathname.includes(
                routerInstance.PAIRS_TEST.EDITING.replace(":id", ""),
            )
        ) {
            history.push(routerInstance.ROOT);
        }

        setMode(history.location.state);
    }, []);

    const onCreateTest = (testData) => {
        setTestData(testData);
        history.push(routerInstance.PAIRS_TEST.CONSTRUCTOR);
    };

    return (
        <div>
            <Switch>
                <Route path={routerInstance.PAIRS_TEST.CREATE_TEST}>
                    <CreateTest
                        onCreateTest={onCreateTest}
                        apiInstance={PairsTestAPI}
                        mode={mode}
                    />
                </Route>

                <Route path={routerInstance.PAIRS_TEST.EDITING}>
                    <PairsTest testData={testData} isEditMode />
                </Route>

                <Route path={routerInstance.PAIRS_TEST.CONSTRUCTOR}>
                    <PairsTest testData={testData} />
                </Route>
            </Switch>
        </div>
    );
};

PairsManager.propTypes = {
    routerInstance: PropTypes.any,
};
