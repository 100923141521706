import React from "react";
import AdminPanelSideMenu from "../admin-panel-side-menu/admin-panel-side-menu";
import styles from "./styles.module.sass";

interface IProps {
    children: React.ReactElement;
}

const AdminPanelLayout = ({children}: IProps) => {
    return (
        <div className={styles.layout}>
            <AdminPanelSideMenu />
            <div className={styles.content}>{children}</div>
        </div>
    );
};

export default AdminPanelLayout;
