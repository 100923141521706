export const PREMIUM_BONUSES = [
    {name: "Математика", icon: 'https://umnayavorona.ru:8443/data/image-bank/c66b5b91-634a-4e1a-b643-c4dcefb02e0e.svg', free: {isAvailable: true}, premium: {isAvailable: true}},
    {name: "Русский язык", icon: 'https://umnayavorona.ru:8443/data/image-bank/500794cd-c4e6-48ed-9b98-46228899eb54.svg', free: {isAvailable: true}, premium: {isAvailable: true}},
    {name: "Чтение", icon: 'https://umnayavorona.ru:8443/data/image-bank/f907f570-091a-40f3-83f5-e6a3b59d8afc.svg', free: {isAvailable: true}, premium: {isAvailable: true}},
    {name: "Окружающий мир", icon: 'https://umnayavorona.ru:8443/data/image-bank/7a267407-adba-4074-b21d-13e2d2ed7660.svg', free: {isAvailable: true}, premium: {isAvailable: true}},
    {name: "Английский язык", icon: 'https://umnayavorona.ru:8443/data/image-bank/36ae4010-8700-4a0f-9d95-e6e0c5bb9095.svg', free: {isAvailable: true}, premium: {isAvailable: true}},
    {name: "Логика", icon: 'https://umnayavorona.ru:8443/data/image-bank/5fcc7b57-8a29-4039-8c06-bd89b8dab22f.svg', free: {isAvailable: false}, premium: {isAvailable: true}},
    {name: "Этикет", icon: 'https://umnayavorona.ru:8443/data/image-bank/d123ffb5-8a7b-40b7-b268-841bbad433ab.svg', free: {isAvailable: false}, premium: {isAvailable: true}},
]

