import {MDBContainer, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";

const ModalLayout = ({children, isOpen, toggle, title, size = "md"}) => {
    return (
        <MDBContainer>
            <MDBModal isOpen={isOpen} toggle={toggle} centered overflowScroll={false} size={size}>
                {title && (
                    <MDBModalHeader toggle={toggle}>
                        <p>{title}</p>
                    </MDBModalHeader>
                )}

                {(children[0] || children) && (
                    <MDBModalBody>{children[0] || children}</MDBModalBody>
                )}
                {children[1] && (
                    <MDBModalFooter className="modal-footer">{children[1]}</MDBModalFooter>
                )}
            </MDBModal>
        </MDBContainer>
    );
};

ModalLayout.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    toggle: PropTypes.func,
};

export default ModalLayout;
