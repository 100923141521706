import React from "react";
import PropTypes from "prop-types";

import {STEP} from "./constants";

export const CreateTestQuestion = (props) => {
    return (
        <div>
            <div>{props.children}</div>
            <div>Params</div>
        </div>
    );
};

CreateTestQuestion.propTypes = {
    toNext: PropTypes.func.isRequired,
};

CreateTestQuestion.step = STEP.QUESTION;
