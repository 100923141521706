import React from "react";
import PropTypes from "prop-types";

export const TagManagerTag = props => {
    const {name, id, onEditClick, onRemoveClick} = props;

    const handleEditClick = React.useCallback(() => {
        onEditClick(id, name);
    }, [id, name, onEditClick]);

    const handleRemoveClick = React.useCallback(() => {
        onRemoveClick(id);
    }, [id, onRemoveClick]);

    return (
        <li className="list-group-item mt-1">
            <div className="d-flex justify-content-between align-items-center">
                <div>{name}</div>
                <div className="btn-group" role="group">
                    <button className="btn btn-warning btn-sm" onClick={handleEditClick}>
                        <i className="fas fa-pen" />
                    </button>
                    <button className="btn btn-danger btn-sm" onClick={handleRemoveClick}>
                        <i className="fas fa-trash-alt" />
                    </button>
                </div>
            </div>
        </li>
    );
};

TagManagerTag.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onRemoveClick: PropTypes.func.isRequired,
};
