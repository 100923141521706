import React, {useEffect, useState} from "react";
import {MDBBtn} from "mdbreact";
import styles from "./styles.module.sass";
import cn from "classnames";
import SpinnerWithText from "../../spinner/spinner";

// @ts-ignore
import Select from "react-select";

interface IProps {
    handleClick: (data: IFormCreator) => void;
    bannerText: string;
    formConstructor: IFormCreator[];
    isLoading: boolean;
}

export enum EFormCreatorTypes {
    TEXT = "TEXT",
    BUTTON = "BUTTON",
    SELECT = "SELECT",
}

export interface IFormCreator {
    type: EFormCreatorTypes;
    labelText?: string;
    name: string;
    color?: any;
    selectValues?: any[];
}

const AdminPanelFormCreator = ({handleClick, bannerText, formConstructor, isLoading}: IProps) => {
    const [formData, setFormData] = useState<IFormCreator[]>([]);
    const [payload, setPayload] = useState<any>({});

    useEffect(() => {
        setFormData(formConstructor);
    }, []);

    const handleChangeValue = (name: string, value: string) => {
        setPayload({...payload, [name]: value});
    };

    return (
        <div
            className={cn({
                ["animate__animated animate__fadeIn"]: true,
                [styles.formWrapper]: true,
            })}
        >
            <div className={styles.form}>
                <div className={styles.banner}>{bannerText}</div>
                {isLoading && (
                    <div
                        className={cn({
                            [styles.loading]: true,
                            "animate__animated animate__fadeIn": true,
                        })}
                    >
                        <SpinnerWithText text="Выполнение..." />
                    </div>
                )}

                {formData?.map((item) => {
                    switch (item.type) {
                        case EFormCreatorTypes.TEXT:
                            return (
                                <div key={item.name}>
                                    <label htmlFor={item.name}>{item.labelText}</label>
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            handleChangeValue(item.name, e.target.value)
                                        }
                                        autoComplete="false"
                                        className="form-control"
                                        id={item.name}
                                    />
                                </div>
                            );
                        case EFormCreatorTypes.BUTTON:
                            return (
                                <div style={{paddingTop: 10}} key={item.name}>
                                    <MDBBtn color={item.color} onClick={() => handleClick(payload)}>
                                        {item.name}
                                    </MDBBtn>
                                </div>
                            );
                        case EFormCreatorTypes.SELECT:
                            return (
                                <div className={styles.select} key={item.name}>
                                    <Select
                                        options={item.selectValues}
                                        onChange={(e: any) => handleChangeValue(item.name, e.value)}
                                        placeholder="Выбор..."
                                    />
                                </div>
                            );
                    }
                })}
            </div>
        </div>
    );
};

export default AdminPanelFormCreator;
