import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./styles.module.sass";

const PlayerV2NotificationsLayer = ({isActive, children}) => {
    return (
        <div
            className={cn({
                [styles.notifications]: true,
                animate__animated: true,
                animate__fadeIn: isActive,
                // animate__fadeOut: !isActive,
            })}
        >
            {children}
        </div>
    );
};

PlayerV2NotificationsLayer.propTypes = {
    children: PropTypes.object,
    isActive: PropTypes.bool,
};

export default PlayerV2NotificationsLayer;
