import {url} from "config";
import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";

const TeacherTasksSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${url + "data/image-bank/b46226b2-a195-4d2c-8c5d-49f978012329.png"})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            <div className={sliders.content}>
                <div className={sliders.about}>
                    <p className={sliders.title}>Тысячи готовых заданий</p>
                    <p className={sliders.description}>
                        Целая Академия готовых, уникальных проиллюстрированных заданий для ваших
                        домашних и контрольных работ. Выбирайте тематические комплекты и отправляйте
                        ученикам в любом объеме. Сделайте ваши школьные занятия ещё увлекательней!
                    </p>
                </div>
            </div>
        </div>
    );
};

TeacherTasksSlide.propTypes = {};

export default TeacherTasksSlide;
