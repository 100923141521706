import React from "react";
import PropTypes from "prop-types";
import {SymbolsAPI} from "entries/symbols/core/api/symbols";
import {AsyncPaginate} from "react-select-async-paginate";

export const SelectWithSymbolGroups = ({selectedGroup, onSelectGroup, isMulti = false}) => {
    async function loadGroups(_, loadedOptions, {page}) {
        const response = await SymbolsAPI.getGroups(page);
        return {
            options: response.data.content.map((item) => {
                return {
                    value: item.id,
                    label: item.name,
                };
            }),
            hasMore: !response.data.last,
            additional: {
                page: page + 1,
            },
        };
    }

    return (
        <AsyncPaginate
            loadOptions={loadGroups}
            loadOptionsOnMenuOpen
            value={selectedGroup}
            onChange={(selectedOption, triggeredAction) => {
                onSelectGroup(selectedOption, triggeredAction);
            }}
            closeMenuOnSelect
            isClearable={false}
            additional={{
                page: 1,
            }}
            isMulti={isMulti}
            clearValue={(value) => console.log(value)}
            id="group"
            placeholder="Выберите шаблон символов"
        />
    );
};

SelectWithSymbolGroups.propTypes = {
    selectedGroup: PropTypes.any,
    withMulti: PropTypes.bool,
    onSelectGroup: PropTypes.func,
};
