import { makeAutoObservable, runInAction } from "mobx";
import { ConversationsAPI } from "../entries/conversations/api/conversations-api";

export class NotificationStore {
    public unreadMessageCount: number = 0
    public unreadEventsCount: number = 0
    public unreadNewsCount: number = 0

    constructor() {
        makeAutoObservable(this);
    }

    public updateMessageCount = async (userId: number | undefined) => {
        if (!userId) {
            return Promise.reject()
        }

        const result = await ConversationsAPI.getUnreadMessageCount(userId)

        runInAction(() => {
            this.unreadMessageCount = result.data
        })
    }
}
