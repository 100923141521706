import React, {useState} from "react";
import {toast} from "react-toastify";
import AdminPanelFormCreator, {
    EFormCreatorTypes,
} from "../../admin-panel-form-creator/admin-panel-form-creator";
import AdminPanelQueryResultLayout from "../../admin-panel-query-result-layout/admin-panel-query-result-layout";
import {IUserInfo} from "../../../../interfaces/IUserInfo";
import {UserAPI} from "../../../../core/api/user";
import styles from "../../../form-school/styles.module.sass";
import GeoSelectRegionsContainer from "../../../geo-selects/views/geo-select-regions/geo-select-regions-container";
import GeoSelectCityContainer from "../../../geo-selects/views/geo-select-city/geo-select-city-container";
import SchoolClassesSelectContainer from "../../../school-classes-select/school-classes-select-container";
import GeoSelectSchoolContainer from "../../../geo-selects/views/geo-select-school/geo-select-school-container";
import {MDBAlert, MDBBtn} from "mdbreact";
// @ts-ignore
import Select from "react-select";
import {url} from "../../../../config";
import {AdminAPI} from "../../../../core/api/admin-api";
import NotificationModalLayer from "../../../notifications-modal/notification-modal-layer";
import NotificationModalContent from "../../../notifications-modal/notification-modal-content/notification-modal-content";

const AdminPanelUserUpdate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [foundedUser, setFoundedUser] = useState<IUserInfo | null>(null);
    const [resetFoundedUser, setResetFoundedUser] = useState<IUserInfo | null>(null);

    // modal
    const [modalContent, setModalContent] = useState<any>(null);
    const [modalIsVisible, setModalIsVisible] = useState(false);

    const [isCardReloaded, setIsCardReloaded] = useState(false);

    const handleGetUserByEmail = (formData: any) => {
        let {id} = formData;

        if (!id) {
            toast.error("Заполните поля");
            return;
        }

        setIsLoading(true);
        setFoundedUser(null);

        getUserProfile(id);
    };

    const getUserProfile = (id: any, isReload: boolean = false) => {
        UserAPI.getUserProfileById(id)
            .then((response: any) => {
                if (isReload) {
                    setIsCardReloaded(true);
                    setTimeout(() => {
                        setIsCardReloaded(false);
                    }, 5000);
                }
                setFoundedUser(response.data);
                setResetFoundedUser(response.data);
            })
            .catch((err: any) => {
                toast.error(err.response.data.message);
            })
            .finally(() => setIsLoading(false));
    };

    const handleUpdate = () => {
        setModalIsVisible(false);
        setModalContent(null);
        setIsLoading(true);
        let userId = foundedUser?.userDto?.id;

        if (!userId) {
            toast.error("Ошибка сохранения. Не найден ID");
            return;
        }

        if (!foundedUser) {
            toast.error("Ошибка сохранения. Не найдены данные");
            return;
        }

        AdminAPI.updateUserInfo(userId, foundedUser)
            .then(async (response) => {
                console.log(response);
                toast.success("Данные успешно обновлены");
                await getUserProfile(userId, true);
            })
            .catch(() => toast.error("Ошибка обновления"))
            .finally(() => setIsLoading(false));
    };

    const getAcceptUpdateModal = () => {
        return (
            <NotificationModalContent>
                <div className="animate__animated animate__fadeIn">
                    <p className="title mt-4">Новые данные</p>
                    <MDBAlert color="success">
                        <div className="mb-2">
                            <p className="title">Регион</p>
                            <p>
                                ID: {foundedUser?.region?.geoNameId}, {foundedUser?.region?.altName}
                            </p>
                        </div>
                        <div className="mb-2">
                            <p className="title">Город</p>
                            <p>
                                ID: {foundedUser?.city?.geoNameId}, {foundedUser?.city?.altName}
                            </p>
                        </div>
                        <div className="mb-2">
                            <p className="title">Школа</p>
                            <p>
                                ID: {foundedUser?.school?.id}, {foundedUser?.school?.alternateName}
                            </p>
                        </div>

                        <div className="mb-2">
                            <p className="title">Класс</p>
                            <p>{foundedUser?.classTag?.id}</p>
                        </div>

                        <div className="mb-2">
                            <p className="title">Пол</p>
                            <p>{foundedUser?.gender}</p>
                        </div>
                    </MDBAlert>

                    <p className="title">Старые данные</p>
                    <MDBAlert color="info">
                        <div className="mb-2">
                            <p className="title">Регион</p>
                            <p>
                                ID: {resetFoundedUser?.region?.geoNameId},{" "}
                                {resetFoundedUser?.region?.altName}
                            </p>
                        </div>
                        <div className="mb-2">
                            <p className="title">Город</p>
                            <p>
                                ID: {resetFoundedUser?.city?.geoNameId},{" "}
                                {resetFoundedUser?.city?.altName}
                            </p>
                        </div>
                        <div className="mb-2">
                            <p className="title">Школа</p>
                            <p>
                                ID: {resetFoundedUser?.school?.id},{" "}
                                {resetFoundedUser?.school?.alternateName}
                            </p>
                        </div>

                        <div className="mb-2">
                            <p className="title">Класс</p>
                            <p>{resetFoundedUser?.classTag?.id}</p>
                        </div>

                        <div className="mb-2">
                            <p className="title">Пол</p>
                            <p>{resetFoundedUser?.gender}</p>
                        </div>
                    </MDBAlert>

                    <MDBBtn className="mt-4" color="success" onClick={handleUpdate}>
                        Сохранить
                    </MDBBtn>
                </div>
            </NotificationModalContent>
        );
    };

    return (
        <React.Fragment>
            <NotificationModalLayer
                isActive={modalIsVisible}
                toggle={() => setModalIsVisible(!modalContent)}
                withTimes
            >
                {modalContent}
            </NotificationModalLayer>

            <AdminPanelFormCreator
                handleClick={handleGetUserByEmail}
                bannerText="Редактор пользователя"
                formConstructor={[
                    {
                        name: "id",
                        labelText: "Введите ID",
                        type: EFormCreatorTypes.TEXT,
                    },
                    {
                        name: "Редактировать",
                        type: EFormCreatorTypes.BUTTON,
                        color: "success",
                    },
                ]}
                isLoading={isLoading}
            />

            {foundedUser && (
                <AdminPanelQueryResultLayout>
                    <div className="mt-2 ">
                        <h4>Пользователь найден</h4>

                        {isCardReloaded && (
                            <MDBAlert className="animate__animated animate__fadeIn" color="success">
                                Карточка пользователя перезагружена
                            </MDBAlert>
                        )}

                        <MDBAlert color="warning">
                            Внимание! Можно изменить только ДОПОЛНИТЕЛЬНЫЕ данные о пользователе.
                        </MDBAlert>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center animate__animated animate__fadeIn">
                            <MDBAlert color="info">
                                <p>
                                    ID: {foundedUser.userDto?.id} Имя:{" "}
                                    {foundedUser.userDto?.firstName} Фамилия:{" "}
                                    {foundedUser.userDto?.lastName}
                                </p>
                            </MDBAlert>

                            <p className="link" onClick={() => setFoundedUser(resetFoundedUser)}>
                                Вернуть данные к начальным
                            </p>
                        </div>

                        <div className={styles.formRow}>
                            <p className="title">Регион</p>
                            <GeoSelectRegionsContainer
                                selectedRegion={{
                                    value: foundedUser.region?.geoNameId,
                                    label: foundedUser.region?.altName,
                                }}
                                handleSelect={(e) => {
                                    setFoundedUser({
                                        ...foundedUser,
                                        region: {geoNameId: e.value, altName: e.label},
                                    });
                                }}
                            />
                        </div>

                        <div className={styles.formRow}>
                            <p className="title">Город</p>
                            <GeoSelectCityContainer
                                regionId={foundedUser.region?.geoNameId}
                                selectedCity={{
                                    value: foundedUser.city?.geoNameId,
                                    label: foundedUser.city?.altName,
                                }}
                                handleSelect={(e) => {
                                    setFoundedUser({
                                        ...foundedUser,
                                        city: {geoNameId: e.value, altName: e.label},
                                    });
                                }}
                            />
                        </div>

                        <div className={styles.formRow}>
                            <p className="title">Школа</p>
                            <GeoSelectSchoolContainer
                                cityId={foundedUser.city?.geoNameId}
                                regionId={foundedUser.region?.geoNameId}
                                selectedSchool={{
                                    value: foundedUser.school?.id,
                                    label: foundedUser.school?.alternateName,
                                }}
                                handleSelect={(e) => {
                                    setFoundedUser({
                                        ...foundedUser,
                                        school: {id: e.value, alternateName: e.label},
                                    });
                                }}
                            />
                        </div>

                        <div className={styles.formRow}>
                            <p className="title">Класс</p>
                            <SchoolClassesSelectContainer
                                handleSelect={(e) => {
                                    setFoundedUser({
                                        ...foundedUser,
                                        classTag: {id: e, name: e.toString()},
                                    });
                                }}
                                selectValue={foundedUser.classTag?.id}
                            />
                        </div>

                        <div className={styles.formRow}>
                            <p className="title">Пол</p>
                            <Select
                                styles={{
                                    menu: (provided: any) => ({
                                        ...provided,
                                        zIndex: 9999,
                                    }),
                                }}
                                options={[
                                    {value: "WOMAN", label: "Женщина"},
                                    {value: "MAN", label: "Мужчина"},
                                ]}
                                placeholder="Выберите класс"
                                maxMenuHeight={200}
                                value={{
                                    value: foundedUser.gender,
                                    label: foundedUser.gender === "MAN" ? "Мужчина" : "Женщина",
                                }}
                                onChange={(e: any) =>
                                    setFoundedUser({...foundedUser, gender: e.value})
                                }
                            />
                        </div>

                        <div className={styles.formRow}>
                            <p className="title">Настройки</p>
                            <input
                                type="text"
                                disabled={true}
                                autoComplete="false"
                                className="form-control"
                                value={
                                    foundedUser.settings
                                        ? atob(foundedUser.settings)
                                        : "Нет настроек"
                                }
                            />
                        </div>

                        <div className={styles.formRow}>
                            <p className="title">Аватар</p>

                            {foundedUser.avatarImageBank ? (
                                <img
                                    style={{maxWidth: 100, maxHeight: "auto"}}
                                    src={
                                        url + foundedUser.avatarImageBank?.fileDownloadUri.slice(1)
                                    }
                                    alt="avatar"
                                />
                            ) : (
                                <p>Нет изображения</p>
                            )}
                        </div>

                        <div className={styles.formRow}>
                            <p className="title">Шапка профиля</p>
                            {foundedUser.headerImageBank ? (
                                <img
                                    style={{maxWidth: "100%", maxHeight: "auto"}}
                                    src={
                                        url + foundedUser.headerImageBank?.fileDownloadUri.slice(1)
                                    }
                                    alt="avatar"
                                />
                            ) : (
                                <p>Нет изображения</p>
                            )}
                        </div>

                        <MDBBtn
                            color="success"
                            disabled={foundedUser === resetFoundedUser}
                            onClick={() => {
                                setModalContent(getAcceptUpdateModal);
                                setModalIsVisible(true);
                            }}
                        >
                            Сохранить
                        </MDBBtn>
                    </div>
                </AdminPanelQueryResultLayout>
            )}
        </React.Fragment>
    );
};

export default AdminPanelUserUpdate;
