import {CLASSIC_TEST_ROUTES} from "entries/constructors/classic-test/views/routes";
import {DICTATION_TEST_ROUTES} from "entries/constructors/dictation/views/routes";
import {OPEN_ANSWER_TEST_ROUTES} from "entries/constructors/open-answer/routes";
import {PAIRS_TEST_ROUTES} from "entries/constructors/pairs-test";
import {NUMERIC_SEQUENCE_TEST_ROUTES} from "entries/constructors/sequences/numeric-sequence-test";
import {TEXT_SEQUENCE_TEST_ROUTES} from "entries/constructors/sequences/sequence-test/routes";
import {
    SENTENCES_SKIP_TEST_ROUTES,
    WORD_SKIP_ADDITIONAL_TEST_ROUTES,
    WORD_SKIP_TEST_ROUTES,
} from "entries/constructors/word-skip";

export const ACADEMY_ROUTES = {
    ROOT: "/academy",
    RESOLVE: "/academy/:id",
    SETS: {
        UPLOAD: "/academy/upload/:id",
        CREATE: "/academy/create/:id",
        EDIT: "/academy/edit/:id",
        PLAY: "/academy/play/:id",
    },
    BLOCK: {
        ROOT: "/academy/block",
        CREATE: "/academy/block/create",
        EDIT: "/academy/block/edit/:id",
    },
};

export const STUDENT_ROUTES = {
    ROOT: "/student",
    HOMEWORK: {
        ROOT: "/student/homework",
        PLAY: "/student/homework/:id",
    },
    HOMEWORK_ACADEMY: {
        PLAY: "/student/academy-homework/:id/academy-set/:asid",
    },
    ACCOUNT: {
        ROOT: "/student/account",
        PAGE: "/student/account/page",
        DOCUMENTS: "/student/account/documents",
        MY_TEACHER: "/student/account/my-teacher",
        SETTINGS: {
            ROOT: "/student/account/settings",
            ABOUT: "/student/account/settings/about",
            SECURITY: "/student/account/settings/security",
            DIPLOMA: "/student/account/settings/diploma",
        },
        CONVERSATION: {
            ROOT: "/student/account/conversations",
        },
    },
    MY_CLASS: {
        ROOT: "/student/my-class",
    },
};

export const TOURNAMENT_ROUTES = {
    ROOT: "/tournament",
    TOURNAMENTS: "/tournament/tournaments",
    VIEW: "/tournament/view/:id",
    PLAY: "/tournament/play/:id",
};

export const RATING_ROUTES = {
    ROOT: "/ratings",
    ACADEMY: "/ratings/academy",
    TOURNAMENTS: "/ratings/tournaments",
    SCHOOLS: "/ratings/schools",
};

export const PUBLIC_ROUTES = {
    ROOT: "/public",
    PAGES: {
        TEACHER: "/public/pages/teacher/:id",
        USER: "/public/pages/user/:id",
        TASKS: "/public/tasks",
    },
};

export const USER_ROUTES = {
    ROOT: "/user",
    SELECT_ROLE: "/user/select-role",
    REGISTER: "/user/register",
    RECEIVED_ACHIEVEMENTS: "/achievements",
};

export const SERVICE_ROUTES = {
    SINGLE_TASK_PREVIEW: "/service/single-task-preview/:id",
};

export const TEACHER_ROUTES = {
    ROOT: "/teacher",
    ACCOUNT: {
        ROOT: "/teacher/account",
        PAGE: "/teacher/account/page",
        DOCUMENTS: "/teacher/account/documents",
        SETTINGS: {
            ROOT: "/teacher/account/settings",
            ABOUT: "/teacher/account/settings/about",
            SECURITY: "/teacher/account/settings/security",
            DIPLOMA: "/teacher/account/settings/diploma",
        },
    },
    SHOP: {
        ROOT: "/teacher/shop",
    },
    TRAINING: {
        ROOT: "/teacher/training",
        TASKS: {
            ROOT: "/teacher/tasks",
            CREATE: "/teacher/tasks/create-task",
            MY: "/teacher/tasks/my",
            FAVORITE: "/teacher/tasks/favorite",
            BASE: "/teacher/tasks/base",
            PLAY: "/teacher/tasks/play/:id",
            MY_TOURNAMENT: "/teacher/tasks/my-tournament",
        },
        SETS: {
            ROOT: "/teacher/sets",
            MY: "/teacher/sets/my",
            FAVORITE: "/teacher/sets/favorite",
            BASE: "/teacher/sets/base",
            EDIT: "/teacher/sets/edit/:id",
            VIEW: "/teacher/sets/view/:id",
            PLAY: "/teacher/sets/play/:id",
        },
        STUDENTS: {
            ROOT: "/teacher/students",
            STUDENTS: "/teacher/students/my-students",
            HOMEWORK: {
                ROOT: "/teacher/students/homework",
                MY: "/teacher/students/homework/my-homework",
                RESULTS: "/teacher/students/homework/result/:id",
            },
            MY_CLASS: {
                ROOT: "/teacher/students/classes",
                VIEW: "/teacher/students/classes/view/:id",
            },
        },
    },
    TUTORIALS: {
        ROOT: "/teacher/account/tutorials",
    },
    CONVERSATION: {
        ROOT: "/teacher/account/conversations",
    },
    STUDENTS: {
        ROOT: "/teacher/students",
        STUDENTS: "/teacher/students/my-students",
        HOMEWORK: {
            ROOT: "/teacher/students/homework",
            MY: "/teacher/students/homework/my-homework",
            RESULTS: "/teacher/students/homework/result/:id",
        },
        MY_CLASS: {
            ROOT: "/teacher/students/classes",
            VIEW: "/teacher/students/classes/view/:id",
        },
    },
    CONSTRUCTORS: {
        ROOT: "/teacher/constructors",
        CLASSIC_TEST: {
            ROOT: `/teacher/constructors${CLASSIC_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/teacher/constructors${CLASSIC_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/teacher/constructors${CLASSIC_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/teacher/constructors${CLASSIC_TEST_ROUTES.EDITING}`,
        },
        DICTATION: {
            ROOT: `/teacher/constructors${DICTATION_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/teacher/constructors${DICTATION_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/teacher/constructors${DICTATION_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/teacher/constructors${DICTATION_TEST_ROUTES.EDITING}`,
        },
        TEXT_SEQUENCE_TEST: {
            ROOT: `/teacher/constructors${TEXT_SEQUENCE_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/teacher/constructors${TEXT_SEQUENCE_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/teacher/constructors${TEXT_SEQUENCE_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/teacher/constructors${TEXT_SEQUENCE_TEST_ROUTES.EDITING}`,
        },
        NUMERIC_SEQUENCE_TEST: {
            ROOT: `/teacher/constructors${NUMERIC_SEQUENCE_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/teacher/constructors${NUMERIC_SEQUENCE_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/teacher/constructors${NUMERIC_SEQUENCE_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/teacher/constructors${NUMERIC_SEQUENCE_TEST_ROUTES.EDITING}`,
        },
        PAIRS_TEST: {
            ROOT: `/teacher/constructors${PAIRS_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/teacher/constructors${PAIRS_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/teacher/constructors${PAIRS_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/teacher/constructors${PAIRS_TEST_ROUTES.EDITING}`,
        },
        WORD_SKIP_TEST: {
            ROOT: `/teacher/constructors${WORD_SKIP_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/teacher/constructors${WORD_SKIP_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/teacher/constructors${WORD_SKIP_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/teacher/constructors${WORD_SKIP_TEST_ROUTES.EDITING}`,
        },
        WORD_SKIP_ADDITIONAL_TEST: {
            ROOT: `/teacher/constructors${WORD_SKIP_ADDITIONAL_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/teacher/constructors${WORD_SKIP_ADDITIONAL_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/teacher/constructors${WORD_SKIP_ADDITIONAL_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/teacher/constructors${WORD_SKIP_ADDITIONAL_TEST_ROUTES.EDITING}`,
        },
        OPEN_ANSWER_TEST: {
            ROOT: `/teacher/constructors${OPEN_ANSWER_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/teacher/constructors${OPEN_ANSWER_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/teacher/constructors${OPEN_ANSWER_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/teacher/constructors${OPEN_ANSWER_TEST_ROUTES.EDITING}`,
        },
        SENTENCE_TEST: {
            ROOT: `/teacher/constructors${SENTENCES_SKIP_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/teacher/constructors${SENTENCES_SKIP_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/teacher/constructors${SENTENCES_SKIP_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/teacher/constructors${SENTENCES_SKIP_TEST_ROUTES.EDITING}`,
        },
        CLIPPING_TEST: {
            ROOT: `/teacher/constructors/clipping-game`,
            CREATE_TEST: `/teacher/constructors/clipping-game/create`,
            CONSTRUCTOR: `/teacher/constructors/clipping-game/constructor`,
            EDITING: `/teacher/constructors/clipping-game/editing/:id`,
        },
        CLIPPING_SECONDARY_TEST: {
            ROOT: `/teacher/constructors/clipping-secondary-game`,
            CREATE_TEST: `/teacher/constructors/clipping-secondary-game/create`,
            CONSTRUCTOR: `/teacher/constructors/clipping-secondary-game/constructor`,
            EDITING: `/teacher/constructors/clipping-secondary-game/editing/:id`,
        },
        MANY_TO_MANY_DND_TEST: {
            ROOT: `/teacher/constructors/many-to-many-dnd`,
            CREATE_TEST: `/teacher/constructors/many-to-many-dnd/create`,
            CONSTRUCTOR: `/teacher/constructors/many-to-many-dnd/constructor`,
            EDITING: `/teacher/constructors/many-to-many-dnd/editing/:id`,
        },
    },
};
