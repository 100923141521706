import {ADMIN_ROLE, TEACHER_ROLE} from "config/roles";
import {getMultipartFormDataContentTypeHeader} from "core/config/headers";
import {Request} from "core/utils/request";
import {
    ACADEMY_MODE,
    HOMEWORK_MODE,
    TOURNAMENT_MODE,
} from "entries/player/views/player/utils/player-config";
import {SIZE_S} from "utils/answers-config";

export const CLASSIC_TEST_LIMIT_SECONDS = 1000;
const INTERNAL_ERROR = "error.internal";

export const ClassicTestAPI = {
    addCorrectAnswer: (questionId, answerId) => {
        if (questionId && answerId) {
            return Request.post(`classic-test/${questionId}/correct-answer/${answerId}`);
        }

        return Request.reject(INTERNAL_ERROR);
    },
    addFormulaForQuestion: (questionId, formulaImage) => {
        if (questionId && formulaImage) {
            const fileData = new FormData();
            fileData.set("file", formulaImage);

            return Request.post(
                `classic-test/${questionId}/question-image-formula`,
                fileData,
                getMultipartFormDataContentTypeHeader(),
            );
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    addImageAnswer: (questionId, file, height, width, x, y) => {
        if (questionId && file) {
            const fileData = new FormData();
            fileData.set("file", file);

            return Request.post(
                `classic-test/${questionId}/answer/answer-image-question?height=${height}&width=${width}&x=${x}&y=${y}`,
                fileData,
                getMultipartFormDataContentTypeHeader(),
            );
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    addImageToAudioAnswer: (questionId, answerId, imageId) => {
        return Request.put(
            `classic-test/${questionId}/answer/${answerId}/answer-audio?imageBankId=${imageId}`,
        );
    },
    addImageAnswerFromBank: (questionId, imageBankId) => {
        if (questionId && imageBankId) {
            return Request.post(
                `classic-test/${questionId}/answer/answer-image-bank?imageBankId=${imageBankId}`,
            );
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    addImageForQuestion: (questionId, file, height, width, x, y) => {
        if (questionId && file) {
            const fileData = new FormData();
            fileData.set("file", file);

            return Request.post(
                `classic-test/${questionId}/question-image?height=${height}&width=${width}&x=${x}&y=${y}`,
                fileData,
                getMultipartFormDataContentTypeHeader(),
            );
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    createFormulaAnswer: (questionId, file) => {
        if (questionId && file) {
            const data = new FormData();
            data.set("file", file);

            return Request.post(
                `classic-test/${questionId}/answer/answer-image-question-formula`,
                data,
                getMultipartFormDataContentTypeHeader(),
            );
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    createAudioAnswer: (questionId, file) => {
        let data = new FormData();
        data.append("file", file);
        return Request.post(
            `classic-test/${questionId}/answer/answer-audio`,
            data,
            getMultipartFormDataContentTypeHeader(),
        );
    },
    createProperties: (questionId) => {
        if (questionId) {
            return Request.post(`classic-test/${questionId}/properties`, {
                sizeText: 16,
                sizeTitle: 18,
                sizeImage: SIZE_S,
            });
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    createQuestion: (questionDto) => {
        return Request.post(`classic-test`, questionDto);
    },
    createQuestionAdmin: (questionDto) => {
        return Request.post(`admin/classic-test`, questionDto);
    },
    createTextAnswer: (text, questionId) => {
        if (text && questionId) {
            return Request.post(`classic-test/${questionId}/answer`, {
                text,
            });
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    deleteCorrectAnswers: (questionId) => {
        if (questionId) {
            return Request.delete(`classic-test/${questionId}/correct-answer`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    deleteQuestion: (questionId) => {
        if (questionId) {
            return Request.delete(`classic-test/${questionId}`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    deleteQuestionImage: (questionId, imageId) => {
        if (questionId && imageId) {
            return Request.delete(`classic-test/${questionId}/question-image`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    deleteImageFromAudioAnswer: (questionId, answerId) => {
        return Request.delete(
            `classic-test/${questionId}/answer/${answerId}/answer-audio/delete-image`,
        );
    },
    deleteQuestionFormula: (questionId, imageId) => {
        if (questionId && imageId) {
            return Request.delete(`classic-test/${questionId}/question-image-formula`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    deleteAnswer: (questionId, answerId) => {
        if (questionId && answerId) {
            return Request.delete(`classic-test/${questionId}/answer/${answerId}`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    editQuestion: (questionId, questionEditDto) => {
        return Request.put(`classic-test/${questionId}`, questionEditDto);
    },
    editTextAnswer: (questionId, answerId, answer) => {
        if (questionId && answerId) {
            return Request.put(`classic-test/${questionId}/answer/${answerId}`, {text: answer});
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    setProperties: (questionId, propertiesDto) => {
        if (questionId) {
            return Request.put(`classic-test/${questionId}/properties`, propertiesDto);
        }
        return Promise.reject(INTERNAL_ERROR);
    },
    getClassicTests: (page = 1, size = 10) => {
        return Request.get(`classic-test?page=${page}&size=${size}`);
    },
    deleteClassicTest: (testId) => {
        if (testId) {
            return Request.delete(`classic-test/${testId}`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    allDataForClassicTest: (testId) => {
        if (testId) {
            return Request.get(`classic-test/${testId}/all`);
        }

        return Promise.reject(testId ? "" : "");
    },
    getCorrectAnswer: (testId) => {
        return Request.get(`classic-test/${testId}/correct-answer`);
    },
    checkAnswer: (questionId, answers, playerMode, role, id) => {
        if (questionId && answers) {
            if (role === ADMIN_ROLE || role === TEACHER_ROLE) {
                return Request.post(`classic-test/${questionId}/submit-answers-admin`, answers);
            }

            if (playerMode) {
                if (playerMode === ACADEMY_MODE) {
                    return Request.post(
                        `classic-test/${questionId}/submit-answers-academy`,
                        answers,
                    );
                } else if (playerMode === HOMEWORK_MODE) {
                    return Request.post(
                        `classic-test/${questionId}/submit-answers-home-work?teacherHomeWorkId=${id}`,
                        answers,
                    );
                } else if (playerMode === TOURNAMENT_MODE) {
                    return Request.post(
                        `classic-test/${questionId}/submit-answers-tournament?tournamentId=${id}`,
                        answers,
                    );
                }
            }
        }
    },
};
