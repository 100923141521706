export const TEACHER_WORKSHOP_ROUTES = {
    ROOT: "/teacher-workshop",
    TASKS: {
        MY_TASKS: "/teacher-workshop/my-tasks",
        CREATE_TASK: "/teacher-workshop/create-task"
    },
    SETS: {
        MY_SETS: "/teacher-workshop/my-sets",
        OTHER_SETS: "/teacher-workshop/other-sets"
    },
    CONSTRUCTOR: {
        CLASSIC_TEST: "/teacher-workshop/engine/classic",
        DICTATION: "/teacher-workshop/engine/dictation",
    }
}
