import {MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React, {useCallback, useEffect, useState} from "react";
import Cropper from "react-easy-crop";
import styles from "./crop-image.module.sass";

export const CropImage = ({image, proportion, onSelectAreaForCrop}) => {
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);

    const [imageInBlob, setImageInBlob] = useState("");
    const onCropComplete = useCallback(
        (croppedArea, croppedAreaPixels) => {
            onSelectAreaForCrop(croppedAreaPixels);
        },
        [crop],
    );

    useEffect(() => {
        setImageInBlob(window.URL.createObjectURL(image));
    }, [image]);

    return (
        <div>
            <div className={styles.cropperContainer}>
                <Cropper
                    image={imageInBlob}
                    crop={crop}
                    zoom={zoom}
                    aspect={proportion}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    showGrid={false}
                    zoomSpeed={0.2}
                />
            </div>
            <div className={styles.buttons}>
                <MDBBtn color="info" onClick={() => setZoom(zoom - 0.2)} size="sm">
                    <p>-</p>
                </MDBBtn>
                <MDBBtn color="info" onClick={() => setZoom(zoom + 0.2)} size="sm">
                    <p>+</p>
                </MDBBtn>
            </div>
        </div>
    );
};

CropImage.propTypes = {
    image: PropTypes.any,
    proportion: PropTypes.number,
    onSelectAreaForCrop: PropTypes.func,
};
