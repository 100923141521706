import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import adminNavbarRu from "dictionary/admin/navbar/ru.json";
import adminNavbarEn from "dictionary/admin/navbar/en.json";
import tagsGroupRu from "dictionary/admin/tags-group/ru.json";
import tagsGroupEn from "dictionary/admin/tags-group/ru.json";
import tagManagerRu from "dictionary/admin/tag-manager/ru.json";
import tagManagerEn from "dictionary/admin/tag-manager/en.json";
import imagesManagerRu from "dictionary/admin/images-manager/ru.json";
import imagesManagerEn from "dictionary/admin/images-manager/en.json";
import codesEn from "dictionary/codes/en.json";
import codesRu from "dictionary/codes/ru.json";

import createTestWizardRu from "views2/conatiners/CreateTestWizard/locale/ru.json";
import createTestWizardEn from "views2/conatiners/CreateTestWizard/locale/en.json";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: "ru",
        supportedLngs: ["ru", "en"],
        fallbackLng: "ru",
        // debug: true,
        resources: {
            ru: {
                translation: {
                    mainPage: {
                        title: "Название на русском языке",
                    },
                },
                admin_navbar: adminNavbarRu,
                images_manager: imagesManagerRu,
                tags_group: tagsGroupRu,
                tag_manager: tagManagerRu,
                create_test_wizard: createTestWizardRu,
                codes: codesRu,
            },
            en: {
                translation: {
                    mainPage: {
                        title: "MainPage page title",
                    },
                },
                admin_navbar: adminNavbarEn,
                images_manager: imagesManagerEn,
                tags_group: tagsGroupEn,
                tag_manager: tagManagerEn,
                create_test_wizard: createTestWizardEn,
                codes: codesEn,
            },
        },
    });

window.i18n = i18n;
