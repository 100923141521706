const RANDOM_PREMIUM_MESSAGES = [
    "Подписка открывает задания по логике и этикету!",
    "Подписка даёт золотой сундук за решение темы без ошибок!",
    "Подписка даёт серебряный сундук за решение темы с ошибками!",
    "Подписчики получают в Академии серебряные и золотые сундуки!",
    "Оформи подписку, чтобы получать крутые сундуки!",
    "Подписка поможет найти самые крутые вещи в игре!",
    "Годовая подписка даёт 600 монет!",
    "С подпиской ты получишь до 600 монет!",
    "Хочешь получать серебряные и золотые сундуки – оформи подписку!",
    "Всё самое интересное спрятано в золотых сундуках!",
];

export const getRandomPremiumMessage = () => {
    return RANDOM_PREMIUM_MESSAGES[Math.floor(Math.random() * RANDOM_PREMIUM_MESSAGES.length)];
};
