import React from "react";
import {Route, Switch} from "react-router-dom";
import NewsList from "./news-list/news-list";
import {ADMIN_PANEL_ROUTES} from "../admin-panel/core/routes";

const NewsPage = () => {
    return (
        <Switch>
            <Route path={ADMIN_PANEL_ROUTES.NEWS.ROOT}>
                <NewsList isView={false} />
            </Route>
        </Switch>
    );
};

export default NewsPage;
