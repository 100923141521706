import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import {Button, Form, Input, Space, Table} from "antd";
import {Request} from "../../../../core/utils/request";
import {ColumnsType} from "antd/es/table";
import ModalWrapper from "../../../modals/modal-wrapper/modal-wrapper";
import {useStores} from "../../../../hooks/use-stores";
import {toast} from "react-toastify";
import {IGameMagic} from "../../../../interfaces/IGameMagic";

interface IItemModal {
    handleClose: () => void;
    item?: any;
    handleRefresh: () => void;
}

const AdminPanelItemGroupsModal = ({handleClose, item, handleRefresh}: IItemModal) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [loadedGroups, setLoadedGroups] = useState<any>(undefined);

    useEffect(() => {
        if (!item) {
            return;
        }

        setLoadedGroups(item);
        form.setFieldsValue(item);
    }, [item]);

    useEffect(() => {
        handleRefresh();
    }, [loadedGroups]);

    const handleSaveGroup = (form: any) => {
        setIsLoading(true);

        if (item) {
            Request.put(`/game-item-group/${item.id}`, form)
                .then(() => {
                    handleRefresh();
                    handleClose();
                })
                .finally(() => setIsLoading(false));
        } else {
            Request.post(`/game-item-group`, form)
                .then((response) => {
                    setLoadedGroups(response.data);
                    handleRefresh();
                    handleClose();
                })
                .finally(() => setIsLoading(false));
        }
    };

    return (
        <ModalWrapper withHeader={true} headerTitle={"Магия"} onClose={handleClose}>
            <Form
                layout="horizontal"
                style={{padding: "20px"}}
                form={form}
                onFinish={handleSaveGroup}
            >
                <Form.Item name="name" label="Название">
                    <Input />
                </Form.Item>

                <Button htmlType="submit" type="primary" disabled={isLoading}>
                    Сохранить
                </Button>
            </Form>
        </ModalWrapper>
    );
};

const AdminPanelItemGroups = () => {
    const {appStore} = useStores();
    const [groups, setGroups] = useState<any>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const [page, setPage] = useState(1);

    useEffect(() => {
        getGroups();
    }, [page]);

    const getGroups = () => {
        setIsLoading(true);
        Request.get(`/game-item-group?page=${page}`)
            .then((response) => {
                setGroups(response.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDeleteGroup = (id: number) => {
        setIsLoading(true);
        Request.delete(`/game-item-group/${id}`)
            .then(() => {
                toast.success("Группа удалена");
                getGroups();
            })
            .catch(() => {
                toast.error("Ошибка удаления группы");
            })
            .finally(() => setIsLoading(false));
    };

    const columns: ColumnsType<IGameMagic> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: "Название",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Действия",
            key: "action",
            render: (_: any, record: any) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            appStore.addModalToQueue(
                                <AdminPanelItemGroupsModal
                                    handleClose={() => appStore.closeActiveModal()}
                                    key={"game-magic-modal"}
                                    item={record}
                                    handleRefresh={() => getGroups()}
                                />,
                            );
                        }}
                    >
                        <i className="fas fa-pen" style={{color: "orange"}} />
                    </a>
                    <a onClick={() => handleDeleteGroup(record.id)}>
                        <i className="fas fa-trash" style={{color: "red"}} />
                    </a>
                </Space>
            ),
        },
    ];

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.header}>
                    <Button
                        type="primary"
                        onClick={() => {
                            appStore.addModalToQueue(
                                <AdminPanelItemGroupsModal
                                    handleClose={() => appStore.closeActiveModal()}
                                    key={"game-item-group-modal"}
                                    handleRefresh={() => getGroups()}
                                />,
                            );
                        }}
                    >
                        Создать группу
                    </Button>
                </div>

                <Table
                    columns={columns}
                    dataSource={groups?.content}
                    loading={isLoading}
                    rowKey="id"
                    pagination={{
                        current: groups?.page,
                        total: groups?.totalElements,
                        onChange: (page) => setPage(page),
                        pageSize: groups?.size,
                        hideOnSinglePage: true,
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default AdminPanelItemGroups;
