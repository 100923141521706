import {TOAST_ERROR_CONFIG, url} from "config";
import {
    WORD_MANY_SKIP_TEST,
    WORD_SEQUENCE_SKIP_TEST,
    WORD_SKIP_TEST,
} from "config/constructor-names";
import {NARRATORS} from "entries/constructors/classic-test/views/components/properties-buttons";
import {CreateQuestion} from "entries/constructors/classic-test/views/containers/create-question";
import {TestParameters} from "entries/constructors/classic-test/views/containers/test-parameters";
import {useConstructor} from "entries/constructors/hooks/useConstructor";
import {TextWithSkipSymbol} from "entries/constructors/word-skip/utils/skips-to-symbols";
import {ModalCreateWordSkipContainer} from "entries/constructors/word-skip/views/modal-create-word-skip/modal-create-word-skip-container";
import {TextWithSkipsContainer} from "entries/constructors/word-skip/views/text-with-skips/text-with-skips-container";

import styles from "entries/constructors/word-skip/views/word-skip-constructor/word-skip-constructor.module.sass";
import {MDBAlert, MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {ConstructorNavbar} from "views/components/constructor-navbar";
import {EditButtons} from "views/components/edit-buttons";
import {Col} from "views/components/layout/col";
import {SaveContent} from "views/components/layout/save-content/save-content";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {TestOptions} from "views/components/layout/test-options";
import {SpinnerPage} from "views/components/spinner";
import {TextUploadModal} from "views/components/text-upload-modal/text-upload-modal";
import {PropertyColumn} from "views/containers/property-column";
import TestSave from "views/containers/test-save/test-save";
import {useWizard} from "views2/conatiners/CreateTestWizard";

// skipWithAdditionalAnswers - true = 19 ТЗ
// sentenceSkip - true = 18 ТЗ
export const WordSkipConstructor = ({
    testData,
    skipWithAdditionalAnswers,
    sentenceSkip,
    apiInstance,
    isEditMode = false,
}) => {
    const loadAnswers = (answers) => {
        console.log(answers);
    };

    const {
        testId,
        questionText,
        questionAdditionalText,
        setQuestionAdditionalText,
        setQuestionText,
        questionFormula,
        background,
        setBackground,
        narrator,
        setNarrator,
        textTitleSize,
        setTextTitleSize,
        textAnswerSize,
        setTextAnswerSize,
        currentPropertyTab,
        setCurrentPropertyTab,
        tabNames,
        questionType,
    } = useConstructor({
        isEditMode,
        constructorName: sentenceSkip
            ? WORD_SEQUENCE_SKIP_TEST
            : skipWithAdditionalAnswers
            ? WORD_MANY_SKIP_TEST
            : WORD_SKIP_TEST,
        testData,
        loadAnswersCallback: loadAnswers,
    });

    const {
        STEP,
        stepsOrder,
        currentStep,
        toNext,
        to,
        enabledSteps,
        enableStep,
        enableSteps,
        disableStep,
    } = useWizard();

    const [skipsModalIsOpen, setSkipsModalIsOpen] = useState(false);
    const [textUploadModalIsOpen, setTextUploadModalIsOpen] = useState(false);
    const [isCreateNew, setIsCreateNew] = useState(false);

    // Данные из модалки выбора пропусков
    const [skipsIndexes, setSkipIndexes] = useState([]);
    const [additionalAnswers, setAdditionalAnswer] = useState([]);
    const [textIsLoading, setTextIsLoading] = useState(false);

    // Данные для теста
    const [answersForSave, setAnswersForSave] = useState(null);
    // const [text, setText] = useState("");

    useEffect(() => {
        if (isEditMode) {
            enableSteps([STEP.ANSWER, STEP.QUESTION, STEP.PARAMS]);
            return;
        }

        if ((questionText && skipsIndexes.length > 0 && narrator) || isEditMode) {
            enableStep(STEP.ANSWER);
        } else {
            disableStep(STEP.ANSWER);
        }
    }, [questionText, skipsIndexes, narrator, isEditMode]);

    const uploadAdditionalAnswers = async (indexes) => {
        setAdditionalAnswer(indexes.additionalAnswers);
        let wordsForSkips = [];

        indexes.skips.forEach((wordIndexes, index) => {
            // находим пропуски
            if (Array.isArray(wordIndexes)) {
                // находим доп. слова для этого пропуска
                let wordsForSkip = indexes.additionalAnswers.filter(
                    (skip) => skip.skipIndexes === wordIndexes,
                );

                if (wordsForSkip.length > 0) {
                    wordsForSkip[0].answers.forEach((wordSkip, wordIndex) => {
                        let correctWord = wordSkip.word;
                        wordsForSkips.push({
                            position: index,
                            text: correctWord,
                            isCorrect: wordIndex === 0,
                        });
                    });
                }
            }
        });

        // сохраняем правильные ответы, чтобы получить ID с сервера
        let correctAnswersForSave = wordsForSkips.filter((word) => word.isCorrect);
        if (correctAnswersForSave.length > 0) {
            await apiInstance
                .addAnswers(testId, correctAnswersForSave)
                .then((response) => {
                    let correctAnswersIds = response.data.map((answer) => answer.id);
                    if (correctAnswersIds.length > 0) {
                        // очищаем старые значения правильных ответов
                        apiInstance.deleteCorrectAnswers(testId).then(() => {
                            apiInstance.addCorrectAnswers(testId, correctAnswersIds).catch(() => {
                                toast.error("Ошибка загрузки ответов", TOAST_ERROR_CONFIG);
                            });
                        });
                    } else {
                        return Promise.reject("Ошибка сохранения");
                    }
                })
                .catch(() => {
                    toast.error("Ошибка добавления пропусков", TOAST_ERROR_CONFIG);
                })
                .finally(() => setTextIsLoading(false));
        }
        let additionalAnswers = wordsForSkips.filter((word) => !word.isCorrect);

        // сохраняем доп. слова
        if (additionalAnswers.length > 0) {
            await apiInstance
                .addAnswers(testId, additionalAnswers)
                .catch(() => {
                    toast.error("Ошибка добавления пропусков", TOAST_ERROR_CONFIG);
                })
                .finally(() => setTextIsLoading(false));
        }
    };

    const onSaveSkips = async (indexes) => {
        setTextIsLoading(true);

        if (skipWithAdditionalAnswers) {
            await apiInstance.deleteAllAnswers(testId).catch(() => {
                toast.error("Ошибка чистки старых ответов");
            });
            await uploadAdditionalAnswers(indexes);
        }

        // сохраняем текст с пропусками
        let textWithSymbols = TextWithSkipSymbol(indexes.skips, questionAdditionalText);
        let textForSave = textWithSymbols.join(" ");
        setTextIsLoading(true);
        apiInstance
            .editQuestion(testId, {title: testData.title, questionText, text: textForSave})
            .finally(() => setTextIsLoading(false));

        let skipIndexAndWord = {};
        let splittedText = questionAdditionalText.split(" ");
        indexes.skips.forEach((wordIndexes, index) => {
            if (Array.isArray(wordIndexes)) {
                let word = "";
                wordIndexes.forEach((wordIndex) => {
                    word += `${splittedText[wordIndex]} `;
                });

                skipIndexAndWord[index] = word;
            }
        });

        setAnswersForSave(skipIndexAndWord);

        setSkipIndexes(indexes.skips);
        setSkipsModalIsOpen(false);
    };

    const onDeleteTextWithSkips = () => {
        setTextIsLoading(true);
        apiInstance
            .editQuestion(testId, {title: testData.title, questionText, text: ""})
            .finally(() => setTextIsLoading(false));
        setQuestionAdditionalText("");
        setSkipIndexes([]);
    };

    const onSelectNarrator = (narrator) => {
        setNarrator({
            id: narrator.id,
            url: `${url}${narrator.url}`,
        });
    };

    const onSaveText = (text) => {
        setQuestionAdditionalText(text.replace(/ +/g, " "));
        setTextUploadModalIsOpen(false);
    };

    return (
        <div>
            <ConstructorNavbar
                tabs={stepsOrder}
                currentTab={currentStep}
                availableTabs={enabledSteps}
                onSelect={(tab) => to(tab)}
                tabNames={tabNames}
            />

            <Col size={12}>
                {currentStep === STEP.QUESTION || currentStep === STEP.ANSWER ? (
                    <Col size={8} className="d-flex justify-content-start">
                        <TestContent background={background}>
                            <TestConstructor size="lg">
                                <ModalCreateWordSkipContainer
                                    onSaveSkips={onSaveSkips}
                                    skipsIndexes={skipsIndexes}
                                    skipWithAdditionalAnswers={skipWithAdditionalAnswers}
                                    additionalAnswers={additionalAnswers}
                                    isOpen={skipsModalIsOpen}
                                    setIsOpen={setSkipsModalIsOpen}
                                    text={questionAdditionalText}
                                    sentenceSkip={sentenceSkip}
                                />

                                <TextUploadModal
                                    saveText={onSaveText}
                                    visible={textUploadModalIsOpen}
                                    toggle={() => setTextUploadModalIsOpen(!textUploadModalIsOpen)}
                                    maxSymbols={500}
                                    questionText={questionAdditionalText}
                                    isLoading={textIsLoading}
                                />

                                <CreateQuestion
                                    isEditMode={currentStep === STEP.QUESTION}
                                    testHasNarrator
                                    narrator={narrator}
                                    questionId={testId}
                                    questionTitle={testData.title}
                                    textTitleSize={textTitleSize}
                                    questionImage={{}}
                                    questionText={questionText}
                                    questionFormula={questionFormula}
                                    updateQuestionImage={() => void 0}
                                    updateQuestionText={(text) => setQuestionText(text)}
                                    updateQuestionFormula={() => void 0}
                                    selectPropertyNarrator={() => setCurrentPropertyTab(NARRATORS)}
                                    apiInstance={apiInstance}
                                    withEditButtons={false}
                                />

                                {textIsLoading ? (
                                    <SpinnerPage primary />
                                ) : (
                                    <div>
                                        {!isEditMode &&
                                            questionAdditionalText.length > 0 &&
                                            skipsIndexes.length === 0 && (
                                                <div className={styles.textPreview}>
                                                    {questionAdditionalText}
                                                    {currentStep === STEP.QUESTION && (
                                                        <EditButtons
                                                            deleteAction={() =>
                                                                setQuestionAdditionalText("")
                                                            }
                                                            editAction={() =>
                                                                setTextUploadModalIsOpen(true)
                                                            }
                                                            withoutRightBorder
                                                            withoutTopBorder
                                                        />
                                                    )}
                                                </div>
                                            )}

                                        {questionAdditionalText.length > 0 &&
                                            sentenceSkip &&
                                            skipsIndexes.length > 0 && (
                                                <div className={styles.textPreview}>
                                                    {questionAdditionalText}
                                                    {currentStep === STEP.QUESTION && (
                                                        <EditButtons
                                                            deleteAction={onDeleteTextWithSkips}
                                                            editAction={() =>
                                                                setSkipsModalIsOpen(true)
                                                            }
                                                            withoutRightBorder
                                                            withoutTopBorder
                                                        />
                                                    )}
                                                </div>
                                            )}

                                        {questionAdditionalText.length > 0 &&
                                            skipsIndexes.length > 0 &&
                                            !sentenceSkip && (
                                                <TextWithSkipsContainer
                                                    text={questionAdditionalText}
                                                    skips={skipsIndexes}
                                                    editAction={() => setSkipsModalIsOpen(true)}
                                                    deleteAction={onDeleteTextWithSkips}
                                                    isEditMode={currentStep === STEP.QUESTION}
                                                    textIsLoading={textIsLoading}
                                                />
                                            )}

                                        {!questionAdditionalText.length > 0 && (
                                            <div
                                                className={styles.addContent}
                                                onClick={() => setTextUploadModalIsOpen(true)}
                                            >
                                                <p className="link">Добавить текст задания</p>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {currentStep === STEP.QUESTION &&
                                    questionAdditionalText.length > 0 &&
                                    skipsIndexes.length === 0 &&
                                    (!isEditMode || isCreateNew) && (
                                        <div
                                            className={styles.addContent}
                                            onClick={() => setSkipsModalIsOpen(true)}
                                        >
                                            <p className="link">Расставить пропуски в тексте</p>
                                        </div>
                                    )}

                                {isEditMode && !isCreateNew && (
                                    <React.Fragment>
                                        {currentStep === STEP.QUESTION && (
                                            <MDBAlert color="warning" className="mt-3">
                                                <p>
                                                    Редактирование ответа для этого задания
                                                    невозможно. Если вы хотите внести изменения, то
                                                    необходимо заново внести текст и расставить
                                                    пропуски.
                                                </p>
                                                <MDBBtn
                                                    color="info"
                                                    block
                                                    className="mt-2"
                                                    onClick={() => {
                                                        setQuestionAdditionalText("");
                                                        setIsCreateNew(true);
                                                    }}
                                                >
                                                    Создать заново
                                                </MDBBtn>
                                            </MDBAlert>
                                        )}
                                    </React.Fragment>
                                )}
                            </TestConstructor>
                        </TestContent>
                    </Col>
                ) : (
                    <TestParameters
                        testId={testId}
                        apiInstance={apiInstance}
                        mode={questionType}
                        isEditMode={isEditMode}
                        withEditMessage
                    />
                )}

                {(currentStep === STEP.QUESTION || currentStep === STEP.ANSWER) && (
                    <Col size={4}>
                        <TestOptions>
                            {currentStep === STEP.QUESTION && (
                                <PropertyColumn
                                    onSelectTab={(tab) => setCurrentPropertyTab(tab)}
                                    currentPropertyTab={currentPropertyTab}
                                    textAnswerSize={textAnswerSize}
                                    setTextAnswerSize={(size) =>
                                        setTextAnswerSize(Number.parseInt(size))
                                    }
                                    selectImage={(background) => setBackground(background)}
                                    selectNarrator={onSelectNarrator}
                                    textTitleSize={textTitleSize}
                                    setTextTitleSize={(size) =>
                                        setTextTitleSize(Number.parseInt(size))
                                    }
                                    isQuestionTextExist={!!questionText}
                                    background={background}
                                    narrator={narrator}
                                    toolTipText="Сначала полностью отредактируйте текст задания,
                                        лишь затем приступайте к расстановке пропусков. Исправить текст с пропусками будет невозможно."
                                />
                            )}
                            {currentStep === STEP.ANSWER && (
                                <SaveContent>
                                    <h5>Проверьте задание</h5>
                                    {sentenceSkip ? (
                                        <p>
                                            Убедитесь, что предложение составлено верно, а затем
                                            нажмите сохранить.
                                        </p>
                                    ) : (
                                        <p>
                                            Убедитесь, что в тексте нет ошибок и все пропуски
                                            расставлены верно. А затем нажмите сохранить.
                                        </p>
                                    )}

                                    <TestSave
                                        apiInstance={apiInstance}
                                        questionId={testId}
                                        answersIdsForSave={[]}
                                        answersForSave={answersForSave}
                                        backgroundId={background.id}
                                        isTwoColumnMode={false}
                                        narratorId={narrator.id}
                                        sizeTitle={textTitleSize}
                                        sizeText={textAnswerSize}
                                        constructorName={
                                            skipWithAdditionalAnswers
                                                ? WORD_MANY_SKIP_TEST
                                                : WORD_SKIP_TEST
                                        }
                                        onSaveProperties={() => toNext()}
                                        mode={questionType}
                                    />
                                </SaveContent>
                            )}
                        </TestOptions>
                    </Col>
                )}
            </Col>
        </div>
    );
};

WordSkipConstructor.propTypes = {
    apiInstance: PropTypes.object.isRequired,
    isEditMode: PropTypes.bool,
    sentenceSkip: PropTypes.bool,
    skipWithAdditionalAnswers: PropTypes.bool,
    testData: PropTypes.object,
};
