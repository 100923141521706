import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./test-contructor.module.sass";

export const TestConstructor = (props) => {
    return (
        <div
            className={cn({
                [styles.container]: true,
                [props.className]: !!props.className,
                [styles.lg]: props.size === "lg",
                [styles.full]: props.size === "full",
            })}
        >
            {props.children}
        </div>
    );
};

TestConstructor.propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
};
