import {Request} from "core/utils/request";

export const PaymentAPI = {
    getPaymentParameters: (subscriptionId, promoValue) => {
        let url = `robokassa/url?subscriptionId=${subscriptionId}`;
        if (promoValue) {
            url += `&code=${promoValue}`;
        }
        return Request.get(url);
    },
};
