import {Request} from "core/utils/request";

export const EventsAPI = {
    getEvents: () => {
        return Request.get(`event?size=1000`);
    },
    getEvent: (eventId) => {
        return Request.get(`event/${eventId}`);
    },
    createEvent: (eventDto) => {
        return Request.post(`event`, eventDto);
    },
    addPictogram: (eventId, imageId) => {
        return Request.put(`event/${eventId}/pictogram?imageBankId=${imageId}`);
    },
    addReward: (eventId, rewardId) => {
        return Request.put(`event/${eventId}/reward?rewardId=${rewardId}`);
    },
    addDocument: (eventId, documentId) => {
        return Request.put(`event/${eventId}/reward-document?rewardDocumentId=${documentId}`);
    },
    createEventSet: (eventName) => {
        return Request.post(`event-set`, {name: eventName});
    },
    getEventSets: () => {
        return Request.get(`event-set?size=100000`);
    },
    getEventSetsStudent: () => {
        return Request.get(`student/event-set?size=100000`);
    },
    getEventSetsTeacher: () => {
        return Request.get(`teacher/event-set?size=100000`);
    },
    addEventsToSet: (eventSet, eventIds) => {
        return Request.put(`event-set/${eventSet}/event`, eventIds);
    },
    deleteGroup: (eventId) => {
        return Request.delete(`event-set/${eventId}`);
    },
    editGroup: (groupId, groupDto) => {
        return Request.put(`event-set/${groupId}`, groupDto);
    },
    deleteEvent: (eventId) => {
        return Request.delete(`event/${eventId}`);
    },
    editEvent: (eventId, eventDto) => {
        return Request.put(`event/${eventId}`, eventDto);
    },
    editDeleteIdEvent: (eventId, idForDelete) => {
        return Request.put(`event/${eventId}/event-delete?eventDeleteId=${idForDelete}`);
    },
    deleteReward: (eventId) => {
        return Request.delete(`event/${eventId}/reward`);
    },
    deleteDocument: (eventId) => {
        return Request.delete(`event/${eventId}/reward-document`);
    },
    getGameItems: () => {
        return Request.get(`game-item?size=1000000`);
    },
    addGameItem: (gameItemId, eventId, isUserSubscription = false) => {
        return Request.post(
            `game-item-for-event?gameItemId=${gameItemId}&eventId=${eventId}&isUserSubscription=${isUserSubscription}`,
        );
    },
    deleteGameItem: (id) => {
        return Request.delete(`game-item-for-event/${id}`);
    },
    getGameItemByEventId: (eventId) => {
        return Request.get(`game-item-for-event/by-event/${eventId}`);
    },
};
