import {TOAST_ERROR_CONFIG} from "config";
import {EventsAPI} from "entries/events/core/EventsAPI";
import Thumbler from "entries/thumbler/thumbler";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import {EditButtons} from "views/components/edit-buttons";
import styles from "./styles.module.sass";

const EventsGroup = ({events, handleDeleteGroup, handleEditGroup}) => {
    const [eventsLocal, setEventsLocal] = useState([]);

    useEffect(() => {
        setEventsLocal(events.content);
    }, [events]);

    const updateGroupStatus = (value, setId) => {
        EventsAPI.editGroup(setId, {isOn: value})
            .then((res) => {
                let updatedItem = res.data;
                let updatedEvents = eventsLocal.map((e) =>
                    e.id === updatedItem.id ? updatedItem : e,
                );

                setEventsLocal(updatedEvents);
            })
            .catch((err) => toast.error(`Ошибка редактирования, ${err}`, TOAST_ERROR_CONFIG));
    };

    return (
        <div>
            {eventsLocal.map((eventSet, index) => (
                <div key={index} className={styles.event}>
                    <Thumbler
                        options={[
                            {value: true, name: "Вкл"},
                            {value: false, name: "Выкл"},
                        ]}
                        activeState={eventSet.isOn}
                        setActiveState={(e) => {
                            updateGroupStatus(e, eventSet.id);
                        }}
                    />
                    <EditButtons
                        editAction={() => handleEditGroup(eventSet.id)}
                        deleteAction={() => handleDeleteGroup(eventSet.id)}
                    />
                    <p className={styles.event__title}>
                        <span className={styles.event__id}>ID: {eventSet.id}</span> Название группы:{" "}
                        {eventSet.name}
                    </p>

                    <div className={styles.event__item}>
                        {eventSet.events.length > 0 ? (
                            <div>
                                {eventSet.events.map((event) => (
                                    <div key={event.id}>
                                        <p>
                                            ID: {event.id} Событие: {event.title}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div>У этой группы нет событий</div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

EventsGroup.propTypes = {
    events: PropTypes.object,
    handleDeleteGroup: PropTypes.func,
    handleEditGroup: PropTypes.func,
};

export default EventsGroup;
