import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./styles.module.sass";
import {EditButtons} from "../../../../views/components/edit-buttons/edit-buttons";
import {IPublicationImagePayload} from "../../article-modals/new-article-image-modal/new-article-image-modal";
import ArticleTitle from "../../article-title/article-title";
import {EPublicationCustomActionTypes} from "../../../../enums/EPublicationCustomActionTypes";
import {MDBBtn} from "mdbreact";

interface IProps {
    block: any;
    isViewMode: boolean;
    handleDelete: (id: number) => void;
    handleCustomAction: (type: EPublicationCustomActionTypes, payload: any) => void;
}

const ArticleVideoBlock = ({block, handleDelete, isViewMode, handleCustomAction}: IProps) => {
    const [, setSettings] = useState<IPublicationImagePayload | null>(null);
    const [videoURLValue, setVideoURLValue] = useState("");

    useEffect(() => {
        if (block?.blockSettings) {
            let tempSettings = JSON.parse(atob(block.blockSettings));
            setSettings(tempSettings);
        }
    }, [block]);

    useEffect(() => {
        if (!block?.urlVideo) {
            return;
        }

        setVideoURLValue(block?.urlVideo);
    }, [block?.urlVideo]);

    return (
        <div
            className={cn({
                [styles.videoBlock]: true,
                [styles.editMode]: !isViewMode,
            })}
        >
            <ArticleTitle
                isViewMode={isViewMode}
                handleCustomAction={handleCustomAction}
                block={block}
                title={block.description}
            />

            {/*{textModalIsOpen && (*/}
            {/*    <TextUploadModal*/}
            {/*        saveText={(t) => {*/}
            {/*            handleCustomAction(EPublicationCustomActionTypes.UPDATE_VIDEO_DESCRIPTION, {*/}
            {/*                description: t,*/}
            {/*                blockId: block.id,*/}
            {/*            });*/}
            {/*        }}*/}
            {/*        visible={textModalIsOpen}*/}
            {/*        toggle={() => setTextModalIsOpen(!textModalIsOpen)}*/}
            {/*        text={block?.description}*/}
            {/*    />*/}
            {/*)}*/}

            <React.Fragment>
                <div
                    className={cn({
                        [styles.description]: true,
                    })}
                >
                    {!isViewMode && (
                        <div className={styles.videoURL}>
                            <input
                                type="text"
                                className="form-control"
                                value={videoURLValue}
                                onChange={(e) => setVideoURLValue(e.target.value)}
                                placeholder="URL"
                            />
                            <MDBBtn
                                color="success"
                                onClick={() => {
                                    handleCustomAction(
                                        EPublicationCustomActionTypes.ADD_VIDEO_URL,
                                        {
                                            urlVideo: videoURLValue,
                                            blockId: block.id,
                                        },
                                    );
                                }}
                            >
                                Сохранить
                            </MDBBtn>
                        </div>
                    )}

                    {block?.urlVideo && (
                        <div className={styles.video}>
                            <iframe
                                src={block.urlVideo}
                                title="Video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    )}

                    {/*{!isViewMode && !block?.description && (*/}
                    {/*    <div className={styles.description}>*/}
                    {/*        <p>*/}
                    {/*            {block?.description ? block.description : "Подпись"}{" "}*/}
                    {/*            {!isViewMode && (*/}
                    {/*                <i*/}
                    {/*                    className="fas fa-pen"*/}
                    {/*                    onClick={() => setTextModalIsOpen(true)}*/}
                    {/*                />*/}
                    {/*            )}*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            </React.Fragment>

            {!isViewMode && (
                <EditButtons
                    deleteAction={() => handleDelete(block.id)}
                    isSolidBorder
                    itemId={block.id}
                />
            )}
        </div>
    );
};

export default ArticleVideoBlock;
