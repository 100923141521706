import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import {ImageUpload} from "views/containers/image-upload";

export const UploadImageForm = (props) => {
    const {onSaveImage, withCrop, testType} = props;
    const [picture, setPicture] = useState(null);
    const [cropEnable, setCropEnable] = useState(withCrop);

    useEffect(() => {
        if (picture && picture[0].type.includes("svg")) {
            setCropEnable(false);
        }
    }, [picture]);

    return (
        <ImageUpload
            uploadHandler={onSaveImage}
            image={picture}
            setPicture={(picture) => setPicture(picture)}
            withCrop={cropEnable}
            testType={testType}
            svgDisabled={props.svgDisabled}
            availableProportions={props.availableProportions}
        />
    );
};

UploadImageForm.propTypes = {
    availableProportions: PropTypes.array,
    isForAnswer: PropTypes.bool,
    isForQuestion: PropTypes.bool,
    isModalSave: PropTypes.bool,
    isNonSaveTag: PropTypes.bool,
    svgDisabled: PropTypes.bool,
    testId: PropTypes.number,
    testType: PropTypes.string,
    withCrop: PropTypes.bool,
    onSaveImage: PropTypes.func,
};
