import {BoxWithSymbols} from "entries/constructors/open-answer/views/box-with-symbols/box-with-symbols";
import {SelectWithSymbolGroups} from "entries/symbols/views/select-with-symbol-groups/select-with-symbol-groups";
import {MDBAlert, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import React from "react";
import PropTypes from "prop-types";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import cn from "classnames";
import {SpinnerPage} from "views/components/spinner";

import styles from "entries/constructors/open-answer/views/modal-open-answer/modal-open-answer.module.sass";

export const ModalOpenAnswer = ({
    modalOpenAnswerIsOpen,
    toggleModal,
    onDragEnd,
    variants,
    answers,
    selectedGroup,
    onSelectGroup,
    handleSaveAnswers,
    handleReset,
    isSymbolsLoading,
    disableSymbolHandle,
}) => {
    return (
        <MDBModal isOpen={modalOpenAnswerIsOpen} toggle={toggleModal} className={styles.modalLg}>
            <MDBModalHeader className="modal-header" toggle={toggleModal}>
                <p>Составление ответа</p>
            </MDBModalHeader>
            <MDBModalBody>
                <div className={styles.header}>
                    <p className={styles.headerDescription}>
                        В задачах этого типа очень важно, чтобы ученик, зная правильный ответ, не
                        ошибся при его вводе. Поэтому ввод с клавиатуры возможен не во всех случаях.
                        Для этого мы создали шаблоны.
                    </p>
                </div>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <p className={styles.title}>Выберите набор символов</p>
                        <div>
                            <SelectWithSymbolGroups
                                onSelectGroup={onSelectGroup}
                                selectedGroup={selectedGroup}
                                isMulti
                            />
                            <MDBAlert color="info" className={styles.helpText}>
                                <p>
                                    <i className="fa fa-info-circle" aria-hidden="true" /> Выберите
                                    один или несколько наборов символов.
                                </p>
                            </MDBAlert>
                            <MDBAlert color="info" className={styles.helpText}>
                                <p>
                                    <i className="fa fa-info-circle" aria-hidden="true" /> Если в
                                    наборе слишком много символов, вы можете убрать ненужные, кликая
                                    на них. Так ученику будет проще составить ответ.
                                </p>
                            </MDBAlert>
                        </div>
                    </div>

                    <div className={styles.right}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <div className="noselect">
                                <div className={styles.answerHeader}>
                                    <div>
                                        <p className={styles.title}>
                                            Переноси символы сюда и составляй ответ
                                        </p>
                                    </div>
                                    <div>
                                        <p className={styles.link} onClick={handleReset}>
                                            Сбросить
                                        </p>
                                    </div>
                                </div>
                                <Droppable droppableId="droppableAnswerBox" direction="horizontal">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className={cn({
                                                [styles.dragging]: snapshot.isDraggingOver,
                                                [styles.boxWithSymbols]: true,
                                                [styles.disabledBox]: answers.length === 0,
                                            })}
                                        >
                                            <BoxWithSymbols
                                                symbols={answers}
                                                placeholder={"Перетаскивайте сюда символы"}
                                                isShortContainer
                                                isForAnswer
                                            />
                                        </div>
                                    )}
                                </Droppable>

                                <p className={styles.title}>Доступные символы:</p>
                                <Droppable
                                    droppableId="droppableBoxWithSymbols"
                                    direction="horizontal"
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className={cn({
                                                [styles.boxWithSymbols]: true,
                                                [styles.disabledBox]:
                                                    variants.length === 0 && answers.length === 0,
                                                [styles.dragging]: snapshot.isDraggingOver,
                                                [styles.border]: true,
                                            })}
                                        >
                                            {isSymbolsLoading ? (
                                                <SpinnerPage info />
                                            ) : (
                                                <React.Fragment>
                                                    <BoxWithSymbols
                                                        symbols={variants}
                                                        disableSymbolHandle={disableSymbolHandle}
                                                        placeholder={
                                                            variants.length === 0 &&
                                                            answers.length === 0 &&
                                                            "Выберите предмет и набор символов"
                                                        }
                                                        isShortContainer={false}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </DragDropContext>
                    </div>
                </div>
                <div className={styles.footer}>
                    <MDBBtn
                        color="success"
                        disabled={answers.length === 0}
                        size="md"
                        onClick={handleSaveAnswers}
                    >
                        Сохранить
                    </MDBBtn>
                </div>
            </MDBModalBody>
        </MDBModal>
    );
};

ModalOpenAnswer.propTypes = {
    answers: PropTypes.array,
    disableSymbolHandle: PropTypes.func,
    handleReset: PropTypes.func,
    handleSaveAnswers: PropTypes.func,
    isSymbolsLoading: PropTypes.bool,
    modalOpenAnswerIsOpen: PropTypes.bool,
    selectedGroup: PropTypes.array,
    toggleModal: PropTypes.func,
    variants: PropTypes.array,
    onDragEnd: PropTypes.func,
    onSelectGroup: PropTypes.func,
};
