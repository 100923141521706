export const GAME_TUTORIAL_SCRIPT = [
    {
        title: "ПРИВЕТ! ПОЛЕТЕЛИ УЧИТЬСЯ!",
        image: require('../../../assets/game/modals/tutorial/1.svg'),
        description: "Умная Ворона – это не только образовательная программа, но еще и увлекательная игра! Чем лучше ты будешь заниматься, тем круче прокачаешь своего Героя. Сейчас я тебе всë расскажу…"
    },
    {
        title: "ДОБРО ПОЖАЛОВАТЬ В ИГРУ!",
        image: require('../../../assets/game/modals/tutorial/5.svg'),
        female: require('../../../assets/game/modals/tutorial/female/5.svg'),
        male: require('../../../assets/game/modals/tutorial/male/5.svg'),
        description: "Решай задания вместе с Умной Вороной и собирай коллекцию уникальных предметов для своего Героя и питомца. Создай уникальный образ и найди все самые крутые предметы!"
    },
    {
        title: "НАХОДИ ИНТЕРЕСНЫЕ ПРЕДМЕТЫ!",
        image: require('../../../assets/game/modals/tutorial/2.svg'),
        description: "Все предметы спрятаны в волшебных сундуках! В каждом сундуке можно найти случайный предмет или монеты. Чтобы получить простой сундук, нужно пройти одну тему по любому из предметов Академии."
    },
    {
        title: "НАХОДИ РЕДКИЕ ПРЕДМЕТЫ!",
        image: require('../../../assets/game/modals/tutorial/3.svg'),
        description: "В серебряном сундуке попадаются более редкие предметы и больше монет. Чтобы получить серебряный сундук, нужно пройти тему в Академии с первого раза и без ошибок или успешно поучаствовать в турнире."
    },
    {
        title: "НАХОДИ САМЫЕ КРУТЫЕ ПРЕДМЕТЫ!",
        image: require('../../../assets/game/modals/tutorial/4.svg'),
        description: "В золотом сундуке можно найти самые крутые предметы и очень много монет. Такой сундук можно получить за высокие результаты в Академии и Турнирах. Для этого нужно регулярно заниматься и проходить много заданий."
    },
    {
        title: "ПОЛУЧАЙ МАКСИМУМ СУНДУКОВ!",
        image: require('../../../assets/game/modals/tutorial/5.svg'),
        description: "Оформи подписку и получай только серебряные и золотые сундуки в Академии и Турнирах и много-много монет! Собери самую удивительную коллекцию предметов и лучшего Героя."
    },
]
