import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {TextBoxWithSelect} from "entries/constructors/word-skip/views/text-box-with-select/text-box-with-select";

export const TextBoxWithSelectContainer = ({
    onSaveSkips,
    skipsIndexes,
    text,
    skipWithAdditionalAnswers,
    additionalAnswersFromSave,
    sentenceSkip,
}) => {
    const [splittedText, setSplittedText] = useState([]);
    const [selectedWords, setSelectedWords] = useState([]);
    const [indexesForText, setIndexesForText] = useState([]);
    // indexesForText - индексы всех символов, пропуски оформлены как массивы - [1, 2, 3, 4, [5,6,7], 8, [9]]
    // Доп ответы.
    const [selectedSkip, setSelectedSkip] = useState(null);
    const [additionalAnswers, setAdditionalAnswers] = useState([]);
    const [newAnswerValue, setNewAnswerValue] = useState("");

    useEffect(() => {
        let splitted = text.split(" ");
        setSplittedText(splitted);

        let indexes = [];
        splitted.forEach((_, index) => indexes.push(index));
        setIndexesForText(indexes);
    }, []);

    useEffect(() => {
        // подгружаем индексы если нажимаем повторно на кнопку добавления пропусков
        if (skipsIndexes.length > 0) {
            setIndexesForText(skipsIndexes);
        }

        if (additionalAnswersFromSave.length > 0) {
            setAdditionalAnswers(additionalAnswersFromSave);
        }
    }, [skipsIndexes, additionalAnswersFromSave]);

    function compareNumbers(a, b) {
        return a - b;
    }

    const selectWordHandler = (idx) => {
        if (skipWithAdditionalAnswers) {
            setSelectedSkip(null);
        }

        if (selectedWords.length > 0) {
            let sortedWords = selectedWords.sort(compareNumbers);
            let last = sortedWords[sortedWords.length - 1] - idx;
            let first = sortedWords[0] - idx;
            if (last === -1 || last === 1 || first === 1 || first === -1) {
                //
            } else {
                setSelectedWords([]);
                return;
            }
        }

        if (selectedWords.length > 2) {
            setSelectedWords([]);
            return;
        }

        let isWordAlreadySelected = selectedWords.find((wordIndex) => wordIndex === idx);

        if (isWordAlreadySelected) {
            let newStateWithoutSelectedWord = selectedWords.filter(
                (wordIndex) => wordIndex !== idx,
            );
            setSelectedWords(newStateWithoutSelectedWord);
            return;
        }

        setSelectedWords([...selectedWords, idx]);
    };

    const addSkipHandler = () => {
        let sortedSelectedWord = selectedWords.sort(compareNumbers);
        let minIndex = Math.min(...sortedSelectedWord);
        const indexesWithSelectedWords = [];

        for (let i = 0; i <= indexesForText.length - 1; i++) {
            if (!Array.isArray(indexesForText[i])) {
                if (sortedSelectedWord.includes(indexesForText[i])) {
                    if (indexesForText[i] === minIndex) {
                        indexesWithSelectedWords.push(sortedSelectedWord);
                    }
                } else {
                    indexesWithSelectedWords.push(indexesForText[i]);
                }
            } else {
                indexesWithSelectedWords.push(indexesForText[i]);
            }
        }

        setIndexesForText(indexesWithSelectedWords);

        //Режим с доп. ответами
        if (skipWithAdditionalAnswers) {
            let words = selectedWords.map((wordIndex) => `${splittedText[wordIndex]} `).join("");
            let newAnswers = {
                skipIndexes: selectedWords,
                answers: [{word: words, isEditable: false}],
            };

            let idx = indexesWithSelectedWords.findIndex((idx) => idx === selectedWords);
            let skip = {skipIndexes: selectedWords, indexInArray: idx};
            setSelectedSkip(skip);
            // let skip = {skipIndexes: selectedWords};
            // setSelectedSkip(skip);
            // console.log(newAnswers);
            setAdditionalAnswers([...additionalAnswers, newAnswers]);
        }
        setSelectedWords([]);
    };

    // wordIndex - индексы пропущенных слов [12,13,14]
    // index - индекс слова в тексте
    const deleteSkipHandler = (wordIndex, index) => {
        if (skipWithAdditionalAnswers) {
            const newState = additionalAnswers.filter((answer) => answer.skipIndexes !== wordIndex);
            setAdditionalAnswers(newState);
            setSelectedSkip(null);
        }

        // получаем значение индекса
        let stateWithoutSkip = [...indexesForText];
        if (wordIndex.length === 1) {
            stateWithoutSkip[index] = parseInt(indexesForText[index]);
        } else {
            let arrayForSave = [];

            for (let i = 0; i <= indexesForText.length - 1; i++) {
                if (Array.isArray(indexesForText[i])) {
                    if (i === index) {
                        for (let j = 0; j <= wordIndex.length - 1; j++) {
                            arrayForSave.push(wordIndex[j]);
                        }
                    } else {
                        arrayForSave.push(indexesForText[i]);
                    }
                } else {
                    arrayForSave.push(indexesForText[i]);
                }
            }

            stateWithoutSkip = arrayForSave;
        }

        setIndexesForText(stateWithoutSkip);
    };

    const saveSkipsHandler = () => {
        if (skipWithAdditionalAnswers) {
            onSaveSkips({skips: indexesForText, additionalAnswers});
        } else {
            onSaveSkips({skips: indexesForText});
        }
    };

    // Доп. ответы

    const selectSkipHandler = (wordIndex, indexInArray) => {
        let skip = {skipIndexes: wordIndex, indexInArray: indexInArray};

        setNewAnswerValue("");
        if (!selectedSkip) {
            setSelectedSkip(skip);
            return;
        }

        if (selectedSkip.indexInArray && selectedSkip.skipIndexes.length > 0) {
            if (selectedSkip.indexInArray === indexInArray) {
                setSelectedSkip(null);
            } else {
                setSelectedSkip(skip);
            }
        } else {
            setSelectedSkip(skip);
        }
    };

    // Добавление нового ответа
    const addNewAnswerHandler = (skip) => {
        const cloneAnswer = additionalAnswers.find(
            (answer) => answer.skipIndexes === skip.skipIndexes,
        );
        if (cloneAnswer) {
            cloneAnswer.answers.push({word: newAnswerValue, isEditable: true});

            let stateWithoutOldAnswer = additionalAnswers.filter(
                (answer) => answer.skipIndexes !== skip.skipIndexes,
            );

            setAdditionalAnswers([...stateWithoutOldAnswer, cloneAnswer]);
        }
        setNewAnswerValue("");
    };

    const deleteAdditionalAnswerHandler = (answerForDelete) => {
        let cloneAnswer = additionalAnswers.find(
            (answer) => answer.skipIndexes === selectedSkip.skipIndexes,
        );
        if (cloneAnswer) {
            cloneAnswer.answers = cloneAnswer.answers.filter(
                (answer) => answer !== answerForDelete,
            );

            let stateWithoutOldAnswer = additionalAnswers.filter(
                (answer) => answer.skipIndexes !== selectedSkip.skipIndexes,
            );
            setAdditionalAnswers([...stateWithoutOldAnswer, cloneAnswer]);
        }
    };

    return (
        <TextBoxWithSelect
            splittedText={splittedText}
            onSelectWord={selectWordHandler}
            onAddSkip={addSkipHandler}
            onDeleteSkip={deleteSkipHandler}
            onSaveSkips={saveSkipsHandler}
            selectedWords={selectedWords}
            indexesForText={indexesForText}
            selectSkipHandler={selectSkipHandler}
            selectedSkip={selectedSkip}
            skipWithAdditionalAnswers={skipWithAdditionalAnswers}
            additionalAnswers={additionalAnswers}
            newAnswerValue={newAnswerValue}
            setNewAnswerValue={setNewAnswerValue}
            addNewAnswer={addNewAnswerHandler}
            deleteAdditionalAnswer={deleteAdditionalAnswerHandler}
            sentenceSkip={sentenceSkip}
        />
    );
};

TextBoxWithSelectContainer.propTypes = {
    additionalAnswersFromSave: PropTypes.array,
    sentenceSkip: PropTypes.bool,
    skipsIndexes: PropTypes.array,
    skipWithAdditionalAnswers: PropTypes.bool,
    text: PropTypes.string,
    onSaveSkips: PropTypes.func,
};
