import React from "react";
import PolicyHeader from "views/pages/policy/policy-header";
import styles from "views/pages/policy/styles.module.sass";

const OfferPolicy = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <PolicyHeader />

                <p align="right">Приложение №1 к Пользовательскому Соглашению</p>

                <h1 align="center">Оферта</h1>

                <p>
                    Настоящий документ (далее &ndash; Договор), постоянно размещенный в сети
                    Интернет по сетевому адресу{" "}
                    <a href="https://umnayavorona.ru/offer">https://umnayavorona.ru/offer</a>,
                    является предложением Общества с ограниченной ответственностью
                    &laquo;Урок&raquo; (далее и по тексту Договора &ndash; Администрация) заключить
                    Договор возмездного оказания Услуг (далее по тексту &ndash; Договор) с
                    заинтересованным физическим лицом (далее по тексту Договора &ndash;
                    Пользователь). По тексту Договора Администрация и Пользователь совместно
                    именуются &laquo;Стороны&raquo;, а раздельно &ndash; &laquo;Сторона&raquo;.
                </p>
                <p>
                    Настоящий Договор является офертой в соответствии с пунктом 1 статьи 435
                    Гражданского Кодекса Российской Федерации.
                </p>

                <p>
                    Настоящий Договор является неотъемлемой частью Пользовательского Соглашения. Во
                    всем, что не урегулировано настоящим Договором, Стороны будут руководствоваться
                    Соглашением.
                </p>

                <p>
                    Надлежащим акцептом оферты в соответствии со статьей 438 Гражданского кодекса
                    Российской Федерации считается осуществление Пользователем всех
                    нижеперечисленных действий:
                </p>

                <p>&ndash; ознакомление с условиями настоящего Договора;</p>

                <p>
                    &ndash; ознакомление с Пользовательским соглашением, выражение согласия на
                    обработку персональных данных в соответствии с Политикой обработки персональных
                    данных;
                </p>

                <p>
                    &ndash; получение Услуг в соответствии с Договором путём нажатия на кнопку
                    &laquo;Оплатить&raquo; на странице &laquo;Оформление подписки&raquo;,
                    расположенной на сайте{" "}
                    <a href="https://umnayavorona.ru/">https://umnayavorona.ru</a>.
                </p>

                <h2 align="center">Договор возмездного оказания</h2>

                <h2>1. Термины и определения</h2>

                <p>
                    В настоящем Договоре, если из текста прямо не вытекает иное, следующие термины
                    будут иметь указанные ниже значения:
                </p>

                <p>
                    <strong>Личный кабинет </strong>&ndash; информационное пространство,
                    представляющее собой web-сервис для обеспечения доступа к услугам Сайта
                    (например, но не ограничиваясь, образовательным тренажерам, результатам
                    использования Сайта, внесению информации о Пользователе и другим функциям) и
                    имеющее набор прав доступа к Сайту. Доступ к Личному кабинету осуществляется с
                    использованием Логина и Пароля и возможен только после Регистрации на Сайте с
                    указанием адреса электронной почты Пользователя.
                </p>

                <p>
                    <strong>Сайт</strong> &ndash; совокупность информации: текстов, графических
                    элементов, дизайна, изображений, фото и видеоматериалов и иных результатов
                    интеллектуальной деятельности, а также программ для ЭВМ, содержащихся в
                    информационной системе, обеспечивающей доступность такой информации в сети
                    Интернет по сетевому адресу <a href="https://урок.рф">umnayavorona.ru</a>,
                    включая все его поддомены.
                    <br />
                    <br />
                    <strong>Пользователь</strong> &ndash; дееспособное физическое лицо,
                    присоединившееся к настоящему Договору в собственном интересе, либо выступающее
                    от имени и в интересах представляемого им физического лица, в пользу которого
                    осуществляется оказание услуг по Договору; добровольно прошедшее процедуру
                    Регистрации и создавшее Личный кабинет пользователя.
                </p>

                <p>
                    <strong>Стандартный функционал</strong> &ndash; функции и Материалы Сайта,
                    предоставляемые Пользователю на безвозмездной основе, в рамках простой
                    (неисключительной) лицензии на основании Пользовательского соглашения.
                </p>

                <p>
                    <strong>Расширенный функционал</strong> &ndash; Дополнительные функции и
                    Материалы Сайта (такие как доступ к заданиям, конкурсам, соревнованиям,
                    тренажерам и другим функциям и Материалам Сайта), не входящие в Стандартный
                    функционал и предоставляемые Пользователю на возмездной основе в рамках простой
                    (неисключительной) лицензии на основании настоящего Договора.
                </p>

                <p>
                    <strong>Код доступа</strong> &ndash; уникальная последовательность символов,
                    позволяющая получить доступ к Расширенному функционалу в течение определенного
                    срока действия, указанного в параметрах этого Кода доступа. Код доступа не может
                    быть куплен (получен на возмездной основе) Пользователем на Сайте. Код доступа
                    может быть получен Пользователем только на безвозмездной основе в рамках
                    рекламной акций Сайта, направленных на ознакомление с Расширенным функционалом.
                </p>

                <p>
                    <strong>Материалы</strong> &ndash; объекты интеллектуальной собственности,
                    размещенные на Сайте Администрацией или Пользователями.
                </p>

                <h2>2. Предмет договора</h2>

                <p>
                    2.1. Администрация обязуется предоставить Пользователю доступ к Расширенному
                    функционалу &nbsp;Сайта, а Пользователь обязуется оплатить эти услуги. По тексту
                    Договора Администрация и Пользователь совместно именуются &laquo;Стороны&raquo;,
                    а раздельно &ndash; &laquo;Сторона&raquo;.
                </p>

                <p>
                    2.2. Доступ к Расширенному функционалу предоставляется удаленно через сеть
                    Интернет. 2.3. Оказание услуги по предоставлению доступа к Расширенному
                    функционалу осуществляется путем снятия ограничения на функции Сайта на просмотр
                    страниц Сайта, входящих в Расширенный функционал. 2.4. Администрация вправе
                    оказывать услуги с привлечением третьих лиц.
                </p>

                <h2>3. Права и обязанности Сторон</h2>

                <p>
                    3.1. Администрация обязуется предоставить доступ к Расширенному Функционалу в
                    соответствии с выбранными Пользователем параметрами (объем предоставляемых
                    Услуг, срок действия Услуг) Расширенного функционала при условии совершения
                    Пользователем следующих действий:
                </p>

                <p>
                    3.1.1. Пользователь определил параметры Расширенного функционала (объем
                    предоставляемых Услуг, срок действия Услуг) на странице оформления Подписки,
                    размещенной на Сайте.
                </p>

                <p>
                    3.1.2. Пользователь полностью и достоверно заполнил в формах оформления заказа
                    информацию о себе.
                </p>

                <p>3.1.3. Пользователь оплатил доступ к Расширенному функционалу.</p>

                <p>
                    3.2. Доступ к Расширенному функционалу предоставляется Пользователю на основании
                    простой (неисключительной) лицензии, только для некоммерческого использования,
                    на отзывной основе, без сублицензирования.&nbsp;
                </p>

                <p>
                    3.3. Администрация обязуется размещать на Сайте информацию об условиях
                    предоставления доступа к Расширенному функционалу, сроках предоставления
                    доступа, его стоимости, а также информацию об изменении стоимости Товаров и
                    Услуг.
                </p>

                <p>
                    3.4. Доступ к Расширенному функционалу может быть активирован Пользователем
                    путем применения Кода доступа на странице &laquo;Оформление подписки&raquo;.
                    Применение Кода доступа не приравнивается к оплате Услуг и в дальнейшем не
                    позволяет применить возврат денежных средств эквивалентных стоимости
                    предоставляемых Услуг по доступу к Расширенному функционалу. Применяя Код
                    доступа Пользователь присоединяется к рекламной акции Сайта по ознакомлению с
                    Расширенным функционалом на безвозмездной основе. Срок доступа к Расширенному
                    функционалу определяется параметрами рекламной акции, в рамках которой был выдан
                    этот Код Доступа.
                </p>

                <p>
                    3.5. Денежные средства, внесенные в качестве оплаты за получение доступа к
                    Расширенному функционалу Сайта, могут быть возвращены Пользователю (или третьему
                    лицу, оплатившему доступ к Расширенному функционалу) при условии, что
                    Пользователь направил заявление на возврат денежных средств с приложенным чеком
                    об оплате не позднее 7 (семи) календарных дней с момента оплаты доступа к
                    Расширенному функционалу, а также немедленно прекратил использование
                    Расширенного функционала Сайта.
                </p>

                <p>
                    3.5. Администрация вправе информировать Пользователя с помощью сообщений
                    (текстовых и голосовых) на адрес электронной почты и/или номер телефона,
                    указанных Пользователем при регистрации.
                </p>

                <p>
                    3.6. Текст настоящего Договора может быть в любое время изменен Администрацией в
                    одностороннем порядке, при этом актуальной считается версия, размещенная на
                    Сайте по адресу{" "}
                    <a href="https://umnayavorona.ru/offer">https://umnayavorona.ru/offer</a>.
                    Условия Договора остаются неизменными для лица, акцептовавшего условия, до
                    окончания срока его действия.
                </p>

                <p>
                    3.7. За допущенные при осуществлении оплаты или заполнении анкет (форм сайта)
                    ошибки отвечает Пользователь.
                </p>

                <p>
                    3.8. Пользователь обязан соблюдать все условия{" "}
                    <a href="https://урок.рф/terms.html">Пользовательского соглашения</a> Сайта.
                </p>

                <h2>4. Стоимость Услуг</h2>

                <p>
                    4.1. Стоимость предоставляемых Услуг зависит от выбранных Пользователем
                    параметров Расширенного доступа, и рассчитывается по тарифам, опубликованным на
                    странице &laquo;Оформление подписки&raquo; на Сайте. Стоимость услуг НДС не
                    облагается в связи с применением упрощенной системой налогообложения (УСН).
                </p>

                <p>
                    4.2. Администрация вправе в одностороннем порядке пересматривать и устанавливать
                    новую стоимость на предоставляемые Услуги в любое время без предварительного
                    уведомления Пользователей. Информация об изменениях размещается на Сайте. Датой
                    вступления в силу новой стоимости Услуг считается дата опубликования измененной
                    стоимости на Сайте. Стоимость уже приобретенных Услуг доступа к Расширенному
                    функционалу не пересматривается.
                </p>

                <p>
                    4.3. Пользователь обязан произвести оплату Товаров и/или Услуг в полном объеме.
                    Пользователь оплачивает Товары и/или Услуги с помощью любого из электронных
                    платежных сервисов, доступных после перехода по кнопке &laquo;Оплатить&raquo; с
                    соответствующей страницы Сайта (п. 4.1. Договора) на сайт электронного
                    платежного сервиса Робокасса (сайт сервиса &ndash;{" "}
                    <a href="https://robokassa.ru">https://robokassa.ru</a>).
                </p>

                <p>
                    4.4. Датой начала предоставления доступа к Расширенному функционалу считается
                    дата зачисления оплаты на расчетный счет ООО &laquo;Урок&raquo;.
                </p>

                <h2>5. Ответственность сторон и особые условия</h2>

                <p>
                    5.1. Пользователь несет полную ответственность за правильность и своевременность
                    производимых им платежей, заполнение регистрационных форм, форм Личного
                    Кабинета.
                </p>

                <p>
                    5.2. Администрация не несет ответственность за действия Пользователя, в
                    результате которых он не смог получить доступ к Расширенному функционалу и не
                    осуществляет возврат денежных средств Пользователя в следующих случаях:
                </p>

                <p>
                    5.2.1. Если действия Пользователя привели к прекращению или невозможности
                    получения доступа к Расширенному функционалу;
                </p>

                <p>
                    5.2.2. Если невозможность получения доступа к Расширенному функционалы вызвана
                    проблемами с каналами связи общего пользования или службами, предоставляющими
                    доступ Пользователю к Сайту и выбранным Пользователем Услугам, неисправностью
                    оборудования Пользователя;
                </p>

                <p>
                    5.2.3. Если невозможность получения доступа к Расширенному функционалу вызвана
                    независящими от Администрации причинами;
                </p>

                <p>
                    5.3. Администрация снимает с себя ответственность в случае возникновения спорных
                    ситуаций, потерь и убытков, не компенсирует уплаченные Пользователем денежные
                    средства и не оказывает услуги по организации доступа к Расширенному
                    функционалу, если Пользователь произвел оплату доступа к Расширенному
                    функционалу не на сайте umnayavorona.ru.
                </p>

                <p>
                    5.4. Максимальная совокупная ответственность Администрации в любом случае
                    ограничивается суммой денежных средств (размером оплаты), полученной от
                    Пользователя в рамках данного Договора.
                </p>

                <p>
                    5.5. Пользователь несет ответственность за выполнение инструкций и рекомендаций,
                    высылаемых Пользователю Администрацией и регламентирующих предоставляемые
                    Услуги.
                </p>

                <p>
                    5.6. Пользователь подтверждает свои намерения и в полной мере осознает, что
                    приобретение доступа к Расширенному функционалу не является обязательным
                    условием использования Стандартного функционала Сайта, и является исключительно
                    и полностью его собственным намерением.
                </p>

                <p>
                    5.7. Расширенный функционал предоставляется &laquo;как есть&raquo;. Пользователь
                    использует Расширенный функционал Сайта на свой собственный риск. Администрация
                    не принимает на себя никакой ответственности, в том числе за соответствие
                    Расширенного функционала целям или ожиданиям Пользователя.
                </p>

                <h2>6. Срок действия договора</h2>

                <p>
                    6.1. Договор действует с даты его заключения, которой считается дата оплаты
                    Услуг доступа к Расширенному функционалу Пользователем в соответствии с
                    условиями Договора, и до даты истечения срока действия Услуг доступа к
                    Расширенному функционалу, определенной Пользователем в параметрах заказа Услуг
                    доступа к Расширенному функционалу.
                </p>

                <p>
                    6.2. По истечении срока действия доступа к Расширенному функционалу договор
                    считается исполненным и Администрация вправе прекратить доступ Пользователя к
                    Расширенному функционалу, и это не дает Пользователю основания требовать
                    возврата денежных средств, оплаченных за Услуги доступа к Расширенному
                    функционалу.
                </p>

                <p>
                    6.3. Пользователь вправе в любое время в одностороннем порядке отказаться от
                    Услуг Администрации. В случае одностороннего отказа Пользователь имеет право на
                    возврат денежных средств, уплаченных по Договору, за вычетом стоимости
                    фактически оказанных Услуг. Возврат денежных средств осуществляется по
                    письменному требованию Пользователя по реквизитам, с которых была осуществлена
                    оплата.
                </p>

                <h2>7. Дополнительные условия</h2>

                <p>
                    7.1. Администрация не несет ответственности за любые виды убытков, произошедшие
                    вследствие использования Сайта, или невозможности использования Сайта
                    Пользователем или отдельных частей/функций Сервиса и не обязана возмещать
                    Пользователю понесенные в связи с этим убытки, в том числе вызванные
                    непредвиденными чрезвычайными ситуациями (катастрофы, пожары, природные
                    катаклизмы и пр.), плановыми или внеплановыми техническими и профилактическими
                    работами, техническими сбоями интернет-провайдеров, компьютерных сетей, серверов
                    и средств, а также противоправными действиями третьих лиц, неосторожными
                    действиями самого Пользователя, а также вследствие вмешательства третьих лиц, в
                    том числе государственных служб безопасности и правоохранительных органов, и
                    иных чрезвычайных и непредотвратимых при данных условиях обстоятельств, при
                    которых осуществление надлежащей работы Сайта оказалось невозможным.
                </p>

                <p>
                    7.2. Стороны договорились, что если иное прямо не предусмотрено
                    законодательством или настоящим Договором, любые сообщения, уведомления и
                    документы в виде хорошо читаемых скан- или фотокопий, направленные Сторонами
                    исключительно с электронных адресов Администрации с доменными именами
                    @umnayavorona.ru, Пользователя &ndash; с адреса электронной почты, указанного им
                    при регистрации на Сайте, обладают юридической силой соответствующих документов
                    на бумажном носителе и создают для Сторон все права и обязанности,
                    предусмотренные настоящим Договором.
                </p>

                <p>
                    7.3. В случае возникновения любых разногласий между Пользователем и
                    Администрацией относительно исполнения каждой из сторон условий Договора, а
                    также любых иных разногласий, такие разногласия должны быть урегулированы с
                    применением обязательного досудебного претензионного порядка. Администрация
                    направляет Пользователю претензию в электронном виде на адрес электронной почты,
                    указанный Пользователем при регистрации на Сайте. Пользователь направляет
                    Администрации претензию в электронном виде на адрес электронной почты
                    support@umnayavorona.ru, а также дублирует претензию в письменном виде на адрес
                    Администрации, указанный в разделе 8 настоящего Договора. Срок ответа на
                    претензию &ndash; 10 (десять) рабочих дней со дня ее получения. При несоблюдении
                    любой из Сторон всех перечисленных выше условий обязательный претензионный
                    порядок не считается соблюденным. В случае невозможности разрешения спора в
                    досудебном порядке, спор между Пользователем и Администрацией разрешается в суде
                    в соответствии с законодательством Российской Федерации исключительно по месту
                    нахождения ООО &laquo;Урок&raquo;.
                </p>

                <p>
                    7.4. Соглашаясь с условиями настоящего Договора, Пользователь дает свое согласие
                    на обработку предоставленных им в Личном кабинете и при Регистрации на Сайте
                    данных в соответствии с условиями Политики обработки персональных данных:{" "}
                    <a href="https://umnayavorona.ru/private_policy.pdf">
                        https://umnayavorona.ru/private_policy.pdf
                    </a>
                    .
                </p>

                <p>
                    7.5. Соглашаясь с настоящим Договором, Пользователь выражает согласие на
                    получение информационных рассылок и рекламных материалов от Администрации, либо
                    от иных лиц по поручению Администрации, на адрес электронной почты, указанный
                    при регистрации на Сайте.
                </p>

                <h2>8. Реквизиты</h2>

                <p>
                    Полное наименование: Общество с ограниченной ответственностью &laquo;Урок&raquo;
                    <br />
                    Сокращенное наименование: ООО &laquo;Урок&raquo;
                    <br />
                    Юридический и почтовый адрес: 424003, Республика Марий Эл, г. Йошкар-Ола, ул.
                    Зарубина, д. 44, помещение I, офис 4<br />
                    ИНН 1215216925 ОГРН 1171215001980
                    <br />
                    Email: support@umnayavorona.ru
                    <br />
                    Телефон: +7 (8362) 72-02-62
                    <br />
                    Директор: Васенев Александр Владимирович
                </p>
            </div>
        </div>
    );
};

OfferPolicy.propTypes = {};

export default OfferPolicy;
