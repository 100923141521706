import React, {useEffect} from "react";
import {NewsAPI} from "../news-api";
import {toast} from "react-toastify";
import NewsHeader from "../news-header/news-header";
import NewsItem from "../news-item/news-item";
import {useStores} from "../../../hooks/use-stores";
import {observer} from "mobx-react-lite";

interface IProps {
    isView: boolean;
}

const NewsList = observer(({isView}: IProps) => {
    const {newsStore, notificationStore} = useStores();

    useEffect(() => {
        if (newsStore.news?.content) {
            let lastViewedNewsId = newsStore.news?.content[0]?.id;
            if (!lastViewedNewsId) return;
            localStorage.setItem("myLastViewedNewsId", lastViewedNewsId.toString());
            notificationStore.unreadNewsCount = 0;
        }
    }, [newsStore.news]);

    const deleteNews = (newsId: number) => {
        NewsAPI.deleteNews(newsId)
            .then(() => {
                toast.success("Успешно удалено");
                newsStore.getNews();
            })
            .catch(() => toast.error("Ошибка получения новостей"));
    };

    return (
        <div>
            {!isView && <NewsHeader getNews={newsStore.getNews} />}

            {newsStore.news?.content?.map((news) => {
                return (
                    <NewsItem
                        key={news.id}
                        news={news}
                        deleteNews={deleteNews}
                        getNews={newsStore.getNews}
                        isView={isView}
                    />
                );
            })}
        </div>
    );
});

export default NewsList;
