import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

import {TOAST_SUCCESS_CONFIG} from "config/ui";

import {TagsAPI} from "core/api/tags";

import {SectionLevelTagsContainer} from "views/containers/tag-manager/second-level-section-tags";

import {TagManagerList, TagManagerSection} from "views/components/tag-manager";

const ThirdLevelSectionTagsTitle = () => {
    const {t} = useTranslation("tag_manager");

    return <h4 className="font-weight-bold">{t("thirdLevelSectionTitle")}</h4>;
};

export const ThirdLevelSectionTagsContainer = () => {
    const {t} = useTranslation("tag_manager");

    const [loaded, setLoaded] = React.useState(false);
    const [tags, setTags] = React.useState([]);
    const [level, setLevel] = useState(0);

    const loadTags = () =>
        TagsAPI.getThirdLevelTags()
            .then((response) => {
                setTags(response.data);
            })
            .finally(() => {
                setLoaded(true);
            });

    React.useEffect(() => {
        loadTags();
    }, []);

    const handleAddClick = React.useCallback(() => {
        const name = prompt(t("newTagName"));

        if (name) {
            TagsAPI.addThirdLevelTag(name)
                .then(() => {
                    loadTags();
                    toast.success(t("tagCreated"), TOAST_SUCCESS_CONFIG);
                })
                .catch((error) => {
                    console.error("Adding tag error", error);
                });
        }
    }, [t]);

    const handleRemoveClick = React.useCallback(
        (tagId) => {
            TagsAPI.deleteThirdLevelTag(tagId)
                .then(() => {
                    loadTags();
                    toast.success(t("tagRemoved"), TOAST_SUCCESS_CONFIG);
                })
                .catch((error) => {
                    console.error("Removing tag error", error);
                });
        },
        [t],
    );

    const handleRenameClick = React.useCallback(
        (tagId, tagName) => {
            const name = prompt(t("newTagName"), tagName);

            if (name && name !== tagName) {
                TagsAPI.renameThirdLevelTag(tagId, name)
                    .then(() => {
                        loadTags();
                        toast.success(t("tagRenamed"), TOAST_SUCCESS_CONFIG);
                    })
                    .catch((error) => {
                        console.error("Renaming tag error", error);
                    });
            }
        },
        [t],
    );

    return (
        <TagManagerSection loaded={loaded} title={<ThirdLevelSectionTagsTitle />}>
            <div className="mb-5">
                <button className="btn btn-success btn-sm ml-0" onClick={() => setLevel(3)}>
                    Загрузить секции 3 уровня
                </button>
                <button className="btn btn-success btn-sm ml-2" onClick={() => setLevel(2)}>
                    Загрузить секции 2 уровня
                </button>
            </div>
            {level === 3 ? (
                <React.Fragment>
                    <div>
                        <h3>Секции 3 уровня</h3>
                        <button className="btn btn-success btn-sm ml-0" onClick={handleAddClick}>
                            Добавить секцию 3 уровня
                        </button>
                    </div>
                    {tags.map((tag) => (
                        <TagManagerList
                            key={tag.id}
                            {...tag}
                            // onAddClick={handleAddClick}
                            onRemoveClick={handleRemoveClick}
                            onRenameClick={handleRenameClick}
                        >
                            <SectionLevelTagsContainer {...tag} />
                        </TagManagerList>
                    ))}
                </React.Fragment>
            ) : level === 2 ? (
                <React.Fragment>
                    <h3>Секции 2 уровня</h3>
                    <SectionLevelTagsContainer withoutThirdLevel={true} />
                </React.Fragment>
            ) : (
                <h2>Выберите секцию</h2>
            )}
        </TagManagerSection>
    );
};
