import {url} from "config";
import {DEFAULT_FONT_SIZE} from "config/project";
import {AUDIO_ANSWER_CLASSIC} from "entries/constructors/classic-test/utils/answer-types";
import {getClassicAnswers} from "entries/constructors/classic-test/utils/utils";
import {ImageAnswer} from "entries/constructors/classic-test/views/components/image-answer/image-answer";
import {TextAnswer} from "entries/constructors/classic-test/views/components/text-answer";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {IMAGE_TYPE, SIZE_S, TEXT_TYPE} from "utils/answers-config";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {Question} from "views/components/question";
import ListOfAudioAnswers from "../../../../list-of-audio-answers/list-of-audio-answers";

export const ClassicTestPlayer = ({question, answers, properties, onSelectCorrectAnswer}) => {
    // данные для вопроса
    const [questionImage, setQuestionImage] = useState({});
    const [questionText, setQuestionText] = useState("");
    const [questionNarrator, setQuestionNarrator] = useState({});
    const [questionAudio, setQuestionAudio] = useState(null);

    // параметры
    const [questionSizeText, setQuestionSizeText] = useState(DEFAULT_FONT_SIZE);
    const [answerSizeText, setAnswerSizeText] = useState(DEFAULT_FONT_SIZE);
    const [answerImageSize, setAnswerImageSize] = useState(SIZE_S);
    const [isTwoColumnMode, setIsTwoColumnMode] = useState(false);
    const [background, setBackground] = useState("");
    const [isRoundedAnswer, setIsRoundedAnswer] = useState(false);

    // ответы
    const [answerType, setAnswerType] = useState(null);
    const [convertedAnswers, setConvertedAnswers] = useState(null);

    useEffect(() => {
        // заполянем вопрос
        if (question) {
            // текст вопроса
            if (question.questionText) {
                setQuestionText(question.questionText);
            }

            // картинка к вопросу
            if (question.imageQuestion) {
                setQuestionImage({
                    id: question.imageQuestion.id,
                    url: question.imageQuestion.fileDownloadUri.slice(1),
                });
            }

            if (question.audio) {
                setQuestionAudio(question.audio);
            }
        }
    }, [question, questionSizeText]);

    useEffect(() => {
        // применяем параметры
        if (properties) {
            // персонаж вопроса
            if (properties.narrator && properties.narrator.image) {
                let narratorUrl = `${url}${properties.narrator.image.fileDownloadUri.slice(1)}`;
                setQuestionNarrator({url: narratorUrl});
            }

            // размер шрифта заголовка
            if (properties.sizeTitle) {
                setQuestionSizeText(properties.sizeTitle);
            }

            // размер шрифта ответа
            if (properties.sizeText) {
                setAnswerSizeText(properties.sizeText);
            }

            // отображение в 2 колонки
            if (properties.isShortContainer) {
                setIsTwoColumnMode(properties.isShortContainer);
            }

            // размер картинок
            if (properties.sizeImage) {
                setAnswerImageSize(properties.sizeImage);
            }

            // отображение фона
            if (properties.backgroundImage) {
                setBackground(properties.backgroundImage.fileDownloadUri.slice(1));
            } else {
                setBackground("data/image-bank/ae86cc0f-5bc8-457f-98bb-454a0ea993a6.svg");
            }

            if (properties.isRoundedAnswer) {
                setIsRoundedAnswer(properties.isRoundedAnswer);
            }
        }
    }, [properties]);

    const getAnswers = async () => {
        let result = await getClassicAnswers(answers, answerSizeText, answerImageSize);
        setAnswerType(result?.type);
        setConvertedAnswers(result?.answers);
    };

    useEffect(() => {
        getAnswers();
    }, [answers, answerSizeText, isTwoColumnMode, answerImageSize]);

    return (
        <React.Fragment>
            {question && answers && properties && (
                <TestContent background={background} withEditBorder={false}>
                    <TestConstructor>
                        <div
                            className="animate__animated animate__fadeIn"
                            style={{marginBottom: 15}}
                        >
                            <Question
                                testHasNarrator
                                isEditMode={false}
                                text={questionText}
                                image={questionImage}
                                narrator={questionNarrator}
                                textTitleSize={questionSizeText}
                                audio={questionAudio}
                            />
                        </div>

                        <div>
                            {answerType ? (
                                <div>
                                    {answerType === TEXT_TYPE && (
                                        <TextAnswer
                                            answers={convertedAnswers}
                                            isTwoColumnMode={isTwoColumnMode}
                                            isSelectCorrectAnswerMode
                                            correctAnswer={onSelectCorrectAnswer}
                                            isEditMode={false}
                                            isPlayerMode={true}
                                            textAnswerSize={answerSizeText}
                                        />
                                    )}

                                    {answerType === IMAGE_TYPE && (
                                        <ImageAnswer
                                            answers={convertedAnswers}
                                            isEditMode={false}
                                            imageSize={answerImageSize}
                                            isSelectCorrectAnswerMode
                                            correctAnswer={onSelectCorrectAnswer}
                                            isRoundedAnswer={isRoundedAnswer}
                                            isPlayerMode={true}
                                        />
                                    )}

                                    {answerType === AUDIO_ANSWER_CLASSIC && (
                                        <ListOfAudioAnswers
                                            answers={convertedAnswers}
                                            handleClick={onSelectCorrectAnswer}
                                        />
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <p>Тип задания не определен..</p>
                                </div>
                            )}
                        </div>
                    </TestConstructor>
                </TestContent>
            )}
        </React.Fragment>
    );
};

ClassicTestPlayer.propTypes = {
    answers: PropTypes.array,
    properties: PropTypes.object,
    question: PropTypes.object,
    onSelectCorrectAnswer: PropTypes.func,
};
