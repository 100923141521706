import {searchQueryParams} from "core/utils/helpers";
import {Request} from "core/utils/request";

export const TeacherAPI = {
    getTeacherSets: (page) => {
        return Request.get(`teacher/set?page=${page}&size=10`);
    },
    getTeacherSetMy: (page) => {
        return Request.get(`teacher/set-my?page=${page}&size=10`);
    },
    getTeacherSetQuestions: (id) => {
        return Request.get(`teacher/set/${id}/teacher-question`);
    },
    createTeacherSet: (teacherSetDto) => {
        return Request.post(`teacher/set`, teacherSetDto);
    },
    getTeacherSet: (id) => {
        return Request.get(`teacher/set/${id}`);
    },
    getTeacherQuestionsMy: (page, searchParams) => {
        let query = `teacher/my-questions${searchQueryParams(page, searchParams)}`;
        return Request.get(query);
    },
    addTeacherQuestions: (setId, questionsData) => {
        if (setId) {
            return Request.post(`teacher/set/${setId}/teacher-question`, questionsData);
        }
    },
    deleteQuestionFromSet: (setId, questionId) => {
        if (setId && questionId) {
            return Request.delete(`teacher/set/${setId}/teacher-question/${questionId}`);
        }
    },
    deleteTeacherSet: (setId) => {
        if (setId) {
            return Request.delete(`teacher/set/${setId}`);
        }
    },
    //homework
    createHomework: (homeworkDto, setId) => {
        if (homeworkDto && setId) {
            return Request.post(`teacher/home-work?teacher_set_id=${setId}`, homeworkDto);
        }
    },
    createHomeworkAcademy: (homeworkDto, setId) => {
        if (homeworkDto && setId) {
            return Request.post(`teacher/home-work?academy_set_id=${setId}`, homeworkDto);
        }
    },
    addStudentForHomework: (homeworkId, users) => {
        if (homeworkId) {
            return Request.post(`teacher/home-work/${homeworkId}/add-student`, users);
        }
    },
    getMyHomeworks: (page = 1, filters) => {
        if (filters) {
            return Request.get(`teacher/home-work?page=${page}&size=10&sort=id,desc&${filters}`);
        }

        return Request.get(`teacher/home-work?page=${page}&size=10&sort=id,desc`);
    },
    getMyHomework: (id) => {
        return Request.get(`teacher/home-work/${id}`);
    },
    getHomeworkLog: (homeworkId) => {
        return Request.get(`teacher/home-work/${homeworkId}/log?size=1000`);
    },
    getQuestionAllForTeacher: (id) => {
        return Request.get(`teacher/question/${id}/all`);
    },
    getHomeworkQuestions: (id) => {
        return Request.get(`teacher/home-work/${id}/questions`);
    },
    //invite
    getInviteCode: () => {
        return Request.get(`teacher/invite-code`);
    },
    getPendingUsers: () => {
        return Request.get(`teacher/my-student-pending?size=1000`);
    },
    getAcceptedUsers: () => {
        return Request.get(`teacher/my-student-accepted?size=1000`);
    },
    getDeletedUsers: () => {
        return Request.get(`teacher/my-student-deleted?size=1000`);
    },
    getRejectedUsers: () => {
        return Request.get(`teacher/my-student-rejected?size=1000`);
    },
    acceptUsers: (usersIds) => {
        return Request.post(`teacher/my-student/accept`, usersIds);
    },
    deleteUsers: (usersIds) => {
        return Request.post(`teacher/my-student/delete`, usersIds);
    },
    rejectUsers: (usersIds) => {
        return Request.post(`teacher/my-student/reject`, usersIds);
    },
    getTeacherSetsById: (teacherId, page) => {
        return Request.get(`teacher/${teacherId}/teacher-sets?page=${page}&size=10`);
    },
    addSetToFavorite: (setId) => {
        return Request.post(`teacher/set-saved/${setId}/add`);
    },
    deleteFromFavorite: (setId) => {
        return Request.delete(`teacher/set-saved/${setId}/delete`);
    },
    getFavoriteSets: (page) => {
        return Request.get(`teacher/set-saved?page=${page}&size=10`);
    },
    createStudent: (userDto) => {
        return Request.post(
            `teacher/create-student?firstName=${userDto.firstName}&lastName=${userDto.lastName}`,
        );
    },
    updateTeacherSet: (setId, teacherSetDto, tags) => {
        return Request.put(`teacher/set/${setId}?tags_id=${tags}`, teacherSetDto);
    },
    updateHomework: (id, homework) => {
        return Request.put(`teacher/home-work/${id}`, homework);
    },
};
