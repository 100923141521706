import {TOAST_ERROR_CONFIG, url} from "config";
import {
    BASKETS_TEST,
    CLASSIC_TEST,
    CLIPPING_SECONDARY_TEST,
    CLIPPING_TEST,
    DICTATION_TEST,
    OPEN_ANSWER_TEST,
    PAIRS_TEST,
    SEQUENCES_IMAGE_TEST,
    SEQUENCES_TEXT_TEST,
    WORD_MANY_SKIP_TEST,
    WORD_SEQUENCE_SKIP_TEST,
    WORD_SKIP_TEST,
} from "config/constructor-names";
import {DEFAULT_FONT_SIZE} from "config/project";
import {QuestionAPI} from "core/api/question";
import {ClassicTestAPI} from "entries/constructors/classic-test/core/api/classic-test";
import {PARAMETERS} from "entries/constructors/classic-test/views/components/properties-buttons";
import {ClippingAPI} from "entries/constructors/clipping/core/ClippingAPI";
import {DictationAPI} from "entries/constructors/dictation/core/api/dictation";
import {OpenAnswerAPI} from "entries/constructors/open-answer/core/api/open-answer";
import {PairsTestAPI} from "entries/constructors/pairs-test/core/api/pairs";
import {SequencesTestAPI} from "entries/constructors/sequences/core/api/sequences";
import {SentenceSkipAPI} from "entries/constructors/word-skip/core/api/sentence-skip";
import {WordSkipAPI} from "entries/constructors/word-skip/core/api/word-skip";
import {WordSkipAdditionalAPI} from "entries/constructors/word-skip/core/api/word-skip-additional";
import {FOR_ACADEMY, FOR_LESSON} from "entries/teacher/utils/teacher-config";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {ADMIN_ROUTES} from "routes/admin";
import {SIZE_S} from "utils/answers-config";
import {ClippingSecondaryAPI} from "../clipping/core/ClippingSecondaryAPI";

export const useConstructor = ({isEditMode, constructorName, testData, loadAnswersCallback}) => {
    const {id} = useParams();
    const history = useHistory();

    const tabNames = {
        question: "Задача",
        answer: "Ответ",
        params: "Параметры",
    };

    const [testId, setTestId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    //данные для вопроса
    const [questionText, setQuestionText] = useState("");
    const [questionImage, setQuestionImage] = useState({});
    const [questionFormula, setQuestionFormula] = useState("");
    const [questionAudio, setQuestionAudio] = useState({});
    const [questionAdditionalText, setQuestionAdditionalText] = useState("");

    //параметры
    const [background, setBackground] = useState({});
    const [narrator, setNarrator] = useState(null);
    const [imageSize, setImageSize] = useState(SIZE_S);
    const [textTitleSize, setTextTitleSize] = useState(21);
    const [textAnswerSize, setTextAnswerSize] = useState(21);
    const [isRoundedAnswer, setIsRoundedAnswer] = useState(false);
    const [currentPropertyTab, setCurrentPropertyTab] = useState(PARAMETERS);
    const [isTwoColumnMode, setIsTwoColumnMode] = useState(false);
    const [elementSize, setElementSize] = useState(100);
    const [answerType, setAnswerType] = useState(undefined);
    const [multiple, setMultiple] = useState(false);

    // режим
    const [questionType, setQuestionType] = useState(null);

    useEffect(() => {
        if (id || testData?.id) {
            process.env.NODE_ENV === "development" && console.log(`TEST ID: ${testData.id || id}`);
            if (isEditMode) {
                setTestId(id);
                getConstructorData(id);
            } else {
                setTestId(testData.id);
            }
        } else {
            toast.error("Ошибка запуска конструктора", TOAST_ERROR_CONFIG);
            history.push(ADMIN_ROUTES.CONSTRUCTORS.ROOT);
        }
    }, []);

    useEffect(() => {
        if (history.location.pathname.includes("admin")) {
            setQuestionType(FOR_ACADEMY);
        } else if (history.location.pathname.includes("teacher")) {
            setQuestionType(FOR_LESSON);
        }
    }, []);

    const getConstructorData = (id) => {
        if (!constructorName) {
            toast.error("Название конструктора не задано", TOAST_ERROR_CONFIG);
            return;
        }

        switch (constructorName) {
            case CLASSIC_TEST:
                getClassicTestData(id);
                break;
            case DICTATION_TEST:
                getDictationData(id);
                break;
            case SEQUENCES_IMAGE_TEST:
            case SEQUENCES_TEXT_TEST:
                getSequenceData(id);
                break;
            case PAIRS_TEST:
                getPairsData(id);
                break;
            case WORD_SKIP_TEST:
                getWordSkipData(id);
                break;
            case WORD_MANY_SKIP_TEST:
                getManyWordSkipData(id);
                break;
            case WORD_SEQUENCE_SKIP_TEST:
                getSentenceWordSkipData(id);
                break;
            case OPEN_ANSWER_TEST:
                getOpenAnswerData(id);
                break;
            case CLIPPING_TEST:
                getClippingTestData(id);
                break;
            case CLIPPING_SECONDARY_TEST:
                getClippingSecondaryTestData(id);
                break;
            case BASKETS_TEST:
                getBasketsTestData(id);
                break;
        }
    };

    const getClassicTestData = (id) => {
        setIsLoading(true);
        ClassicTestAPI.allDataForClassicTest(id)
            .then((response) => {
                initializeEditMode(
                    response.data.classicTest,
                    response.data.classicTestAnswerVariants,
                    response.data.questionProperties,
                );
            })
            .catch(() => toast.error("Ошибка загрузки классического теста", TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));
    };

    const getPairsData = (id) => {
        setIsLoading(true);
        PairsTestAPI.getAllData(id)
            .then(async (response) => {
                let correctAnswers = {
                    staticAnswers: [],
                    dynamicAnswers: [],
                };
                if (isEditMode) {
                    await PairsTestAPI.getCorrectAnswers(id).then((response) => {
                        response.data.forEach((answer) => {
                            correctAnswers.staticAnswers.push(answer.oneCorrectAnswer?.id);
                            correctAnswers.dynamicAnswers.push(answer.twoCorrectAnswer?.id);
                        });
                    });
                }

                initializeEditMode(
                    response.data.pair,
                    response.data.pairAnswerVariants,
                    response.data.questionProperties,
                    correctAnswers,
                );
            })
            .catch((e) => console.log(`Ошибка загрузки конструктора, ${e}`))
            .finally(() => setIsLoading(false));
    };

    const getDictationData = (id) => {
        setIsLoading(true);
        DictationAPI.getAllData(id)
            .then((response) => {
                initializeEditMode(
                    response.data.dictation,
                    response.data.dictationAnswerVariants,
                    response.data.questionProperties,
                );
            })
            .catch(() => toast.error("Ошибка загрузки диктанта", TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));
    };

    const getSequenceData = (id) => {
        setIsLoading(true);
        SequencesTestAPI.getAllData(id)
            .then(async (response) => {
                let correctAnswers;
                if (isEditMode) {
                    await SequencesTestAPI.getCorrectAnswers(id).then((response) => {
                        correctAnswers = response.data.map((answer) => answer.correctAnswer.id);
                    });
                }

                initializeEditMode(
                    response.data.sequenceTest,
                    response.data.sequenceTestAnswerVariants,
                    response.data.questionProperties,
                    correctAnswers,
                );
            })
            .catch(() =>
                toast.error("Ошибка загрузки текстовой последовательности", TOAST_ERROR_CONFIG),
            )
            .finally(() => setIsLoading(false));
    };

    const getWordSkipData = (id) => {
        setIsLoading(true);
        WordSkipAPI.getAllData(id)
            .then((response) => {
                initializeEditMode(
                    response.data.wordSkip,
                    response.data.wordSkipAnswerVariants,
                    response.data.questionProperties,
                );
            })
            .catch(() => toast.error("Ошибка загрузки пропуска слов", TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));
    };

    const getManyWordSkipData = (id) => {
        setIsLoading(true);
        WordSkipAdditionalAPI.getAllData(id)
            .then((response) => {
                initializeEditMode(
                    response.data.manyWordSkip,
                    response.data.manyWordSkipAnswerVariants,
                    response.data.questionProperties,
                );
            })
            .catch(() => toast.error("Ошибка загрузки конструктора", TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));
    };

    const getSentenceWordSkipData = (id) => {
        setIsLoading(true);
        SentenceSkipAPI.getAllData(id)
            .then((response) => {
                initializeEditMode(
                    response.data.wordSkipClause,
                    response.data.wordSkipClauseAnswerVariants,
                    response.data.questionProperties,
                );
            })
            .catch(() => toast.error("Ошибка загрузки конструктора", TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));
    };

    const getOpenAnswerData = (id) => {
        setIsLoading(true);
        OpenAnswerAPI.getAllData(id)
            .then((response) => {
                initializeEditMode(
                    response.data.openAnswer,
                    response.data.openAnswerAnswerVariants,
                    response.data.questionProperties,
                );
            })
            .catch(() => toast.error("Ошибка загрузки конструктора", TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));
    };

    const getClippingTestData = (id) => {
        setIsLoading(true);
        ClippingAPI.getAllData(id)
            .then((response) => {
                initializeEditMode(
                    response.data.clippingEngine,
                    {
                        answers: response.data.clippingEngineAnswerVariants,
                        boxes: response.data.clippingEngineAnswerPositionDtos,
                        backgroundGame: response.data.clippingEngine?.imageQuestion,
                        id,
                    },
                    response.data.questionProperties,
                );
            })
            .finally(() => setIsLoading(false));
    };

    const getClippingSecondaryTestData = (id) => {
        setIsLoading(true);
        ClippingSecondaryAPI.getAllData(id)
            .then((response) => {
                initializeEditMode(
                    response.data.clippingSecondaryEngine,
                    {
                        answers: response.data.clippingSecondaryEngineAnswerVariants,
                        boxes: response.data.clippingSecondaryEngineAnswerPositionDtos,
                        backgroundGame: response.data.clippingSecondaryEngine?.imageQuestion,
                        id,
                    },
                    response.data.questionProperties,
                );
            })
            .finally(() => setIsLoading(false));
    };

    const getBasketsTestData = (id) => {
        setIsLoading(true);
        QuestionAPI.getAllForAdmin(id)
            .then((response) => {
                initializeEditMode(
                    response.data.basketEngine,
                    {
                        answers: response.data.basketEngineAnswerVariants,
                        boxes: response.data.basketEngineAnswerPositionDtos,
                        backgroundGame: response.data.basketEngine?.imageQuestion,
                        id,
                    },
                    response.data.questionProperties,
                );
            })
            .finally(() => setIsLoading(false));
    };

    const initializeEditMode = (question, answers, properties, correctAnswers) => {
        if (properties) {
            // персонаж вопроса
            if (properties.narrator?.image) {
                let narratorUrl = `${url}${properties.narrator.image.fileDownloadUri.slice(1)}`;
                setNarrator({id: properties.narrator.id, url: narratorUrl});
            }

            // размер шрифта заголовка
            if (properties.sizeTitle) {
                setTextTitleSize(properties.sizeTitle);
            }

            // размер шрифта ответа
            if (properties.sizeText) {
                setTextAnswerSize(properties.sizeText);
            }

            // отображение в 2 колонки
            if (properties.isShortContainer) {
                setIsTwoColumnMode(properties.isShortContainer);
            }

            // размер картинок
            if (properties.sizeImage) {
                if (constructorName === SEQUENCES_IMAGE_TEST || constructorName === PAIRS_TEST) {
                    setElementSize(properties.sizeImage);
                }

                setImageSize(properties.sizeImage);
            }

            // отображение фона
            if (properties.backgroundImage) {
                setBackground({
                    id: properties.backgroundImage.id,
                    name: "",
                    url: properties.backgroundImage.fileDownloadUri.slice(1),
                });
            }

            if (properties.isRoundedAnswer) {
                setIsRoundedAnswer(properties.isRoundedAnswer);
            }
        }

        // вопрос
        if (question) {
            if (question.questionText) {
                setQuestionText(question.questionText);
            }

            if (question.text) {
                setQuestionAdditionalText(question.text);
            }

            if (question.imageQuestion) {
                setQuestionImage({
                    id: question.imageQuestion.id,
                    url: question.imageQuestion.fileDownloadUri.slice(1),
                });
            }

            if (question.audio) {
                setQuestionAudio(question.audio);
            }
        }

        if (loadAnswersCallback) {
            loadAnswersCallback(answers, question.id, correctAnswers);
        }
    };

    return {
        testId,
        isLoading,
        setIsLoading,
        questionText,
        setQuestionText,
        questionAdditionalText,
        setQuestionAdditionalText,
        questionImage,
        setQuestionImage,
        questionFormula,
        setQuestionFormula,
        questionAudio,
        setQuestionAudio,
        background,
        setBackground,
        narrator,
        setNarrator,
        imageSize,
        setImageSize,
        textTitleSize,
        setTextTitleSize,
        textAnswerSize,
        setTextAnswerSize,
        isRoundedAnswer,
        setIsRoundedAnswer,
        currentPropertyTab,
        setCurrentPropertyTab,
        isTwoColumnMode,
        setIsTwoColumnMode,
        tabNames,
        questionType,
        elementSize,
        setElementSize,
        answerType,
        setAnswerType,
        multiple,
        setMultiple,
    };
};
