import React from "react";
import PropTypes from "prop-types";

import {setTitle} from "utils/dom-utils";

export class HeadTitle extends React.PureComponent {
    componentDidMount() {
        setTitle(this.props.children);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.children !== this.props.children) {
            setTitle(this.props.children);
        }
    }

    render() {
        return null;
    }
}

HeadTitle.propTypes = {
    children: PropTypes.string,
};
