import React, {useEffect, useState} from "react";
import {Roles} from "../../../enums/roles";
import {useStores} from "../../../hooks/use-stores";
import {STUDENT_ROUTES, TEACHER_ROUTES} from "../../../routes/main";
import {Header} from "./header";
import {observer} from "mobx-react-lite";

interface IProps {
    tabs: any;
    withEvents: boolean;
    toggleEvents?: any;
    withShop?: boolean;
    withAuth?: boolean;
    withLogoText?: boolean;
}

export const HeaderContainer = observer(({tabs, withLogoText}: IProps) => {
    const [userPath, setUserPath] = useState("");
    const {userStore, appStore} = useStores();
    const [extendedTabs, setExtendedTabs] = useState([]);

    useEffect(() => {
        if (userStore.role === Roles.USER) {
            setUserPath("");
            return;
        }

        if (userStore.role === Roles.TEACHER) {
            setUserPath(TEACHER_ROUTES.ACCOUNT.ROOT);
        } else {
            setUserPath(STUDENT_ROUTES.ACCOUNT.ROOT);
        }
    }, [userStore.role]);

    useEffect(() => {
        let updatedTabs = tabs;

        if (!appStore.isMobile) {
            setExtendedTabs(updatedTabs);
            return;
        }

        if (userStore.role === Roles.STUDENT) {
            updatedTabs = [
                ...tabs,
                {name: "Профиль", path: STUDENT_ROUTES.ACCOUNT.PAGE},
                {name: "Документы", path: STUDENT_ROUTES.ACCOUNT.DOCUMENTS},
                {name: "Мой учитель", path: STUDENT_ROUTES.ACCOUNT.MY_TEACHER},
                {name: "Настройки", path: STUDENT_ROUTES.ACCOUNT.SETTINGS.ROOT},
                {name: "Техподдержка", path: STUDENT_ROUTES.ACCOUNT.CONVERSATION.ROOT},
            ];
        } else if (userStore.role === Roles.TEACHER) {
            updatedTabs = [
                ...tabs,
                {name: "Персональная страница", path: TEACHER_ROUTES.ACCOUNT.PAGE},
                {name: "Документы", path: TEACHER_ROUTES.ACCOUNT.DOCUMENTS},
                {name: "Настройки", path: TEACHER_ROUTES.ACCOUNT.SETTINGS.ABOUT},
                {name: "Техподдержка", path: TEACHER_ROUTES.CONVERSATION.ROOT},
                {name: "Результаты", path: TEACHER_ROUTES.TRAINING.STUDENTS.HOMEWORK.ROOT},
                {name: "Ученики", path: TEACHER_ROUTES.TRAINING.STUDENTS.STUDENTS},
                {name: "Классы", path: TEACHER_ROUTES.TRAINING.STUDENTS.MY_CLASS.ROOT},
                {name: "Задания", path: TEACHER_ROUTES.TRAINING.TASKS.ROOT},
                {name: "Комплекты", path: TEACHER_ROUTES.TRAINING.SETS.ROOT},
            ];
        }
        setExtendedTabs(updatedTabs);
    }, [tabs, appStore.isMobile]);

    return <Header tabs={extendedTabs} userAccountPath={userPath} withLogoText={withLogoText} />;
});
