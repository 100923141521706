import {TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG} from "config";

import {difficulties, timeData} from "config/tasks-params";
import {QuestionAPI} from "core/api/question";

import styles from "entries/constructors/classic-test/views/containers/test-parameters/test-parameters.module.sass";
import SchoolClassesSelectButtonsContainer from "entries/school-classes-select-buttons/school-classes-select-buttons-container";
import SchoolSubjectsSelectContainer from "entries/school-subjects-select/school-subjects-select-container";
import SchoolThemesSelectContainer from "entries/school-themes-select/school-themes-select-container";
import {FOR_ACADEMY, FOR_LESSON} from "entries/teacher/utils/teacher-config";
import {MDBAlert, MDBBtn, MDBBtnGroup, MDBCol, MDBContainer, MDBRow} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {ADMIN_ROUTES} from "routes/admin";
import {TEACHER_ROUTES} from "routes/main";
import {SpinnerPage} from "views/components/spinner";
import {TagUtils} from "../../../../../../core/api/tag-utils";

export const TestParameters = ({
    apiInstance,
    mode,
    isEditMode,
    withEditMessage,
    ...props
} = props) => {
    const {testId} = props;
    const [selectedLevel, setSelectedLevel] = useState("EASY");
    const [selectedTime, setSelectedTime] = useState(10000);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedClasses, setSelectedClasses] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState("");

    let history = useHistory();

    const handleSelectTheme = (theme) => {
        setSelectedTheme(theme);
    };

    const handleSelectSubject = (subject) => {
        setSelectedSubjects(subject);
    };

    const getProperties = async () => {
        setIsLoading(true);
        let question = await QuestionAPI.getQuestion(testId)
            .then(async (response) => {
                setSelectedTime(response.data.limitMilliSec);
                return response.data;
            })
            .catch(() => {
                toast.error("Ошибка получения вопроса", TOAST_ERROR_CONFIG);
            });

        let classes = await TagUtils.getClasses()
            .then(async (response) => {
                return response.data;
            })
            .catch(() => {
                toast.error("Ошибка получения классов", TOAST_ERROR_CONFIG);
            });

        let subjects = await TagUtils.getSchoolSubjects()
            .then(async (response) => {
                return response.data;
            })
            .catch(() => {
                toast.error("Ошибка получения предметов", TOAST_ERROR_CONFIG);
            });

        await QuestionAPI.getQuestionProperties(testId)
            .then((response) => {
                setSelectedLevel(response.data.questionDifficult);
                return response.data;
            })
            .catch(() => {
                toast.error("Ошибка получения параметров", TOAST_ERROR_CONFIG);
            });

        await QuestionAPI.getQuestionThemes(testId)
            .then((response) => {
                let themes = response?.data?.content?.map((theme) => ({
                    value: theme.id,
                    label: theme.name,
                }));

                setSelectedTheme(themes);
                return response.data;
            })
            .catch(() => {
                toast.error("Ошибка получения тем", TOAST_ERROR_CONFIG);
            });

        if (question.tags) {
            let classesInQuestionTags = question.tags.filter((tag) =>
                classes.some((cl) => cl.id === tag.id),
            );
            let subjectsInQuestionTags = question.tags.filter((tag) =>
                subjects.some((cl) => cl.id === tag.id),
            );

            setSelectedClasses(classesInQuestionTags.map((cl) => cl.id));
            setSelectedSubjects(
                subjectsInQuestionTags.map((sub) => ({value: sub.id, label: sub.name})),
            );
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (isEditMode) {
            getProperties();
        }
    }, [isEditMode]);

    const saveProperties = () => {
        let subjectIds = [];
        let similarThemesIds = [];

        if (subjectIds) {
            subjectIds = selectedSubjects?.map((item) => item.value);
        }

        if (selectedTheme) {
            similarThemesIds = selectedTheme?.map((item) => item.value);
        }

        let tagIds = subjectIds.concat(selectedClasses);
        let requests = [];

        requests.push(apiInstance.editQuestion(testId, {limitMilliSec: selectedTime}));
        requests.push(QuestionAPI.updateProperties(testId, {questionDifficult: selectedLevel}));
        requests.push(QuestionAPI.deleteTags(testId));
        requests.push(QuestionAPI.deleteThemes(testId));

        setIsLoading(true);

        Promise.all(requests)
            .then(() => {
                QuestionAPI.addTags(testId, tagIds)
                    .then(() => {
                        return QuestionAPI.addThemes(testId, similarThemesIds);
                    })
                    .then(async () => {
                        setIsLoading(false);
                        {
                            !isEditMode && (await QuestionAPI.validate(testId));
                        }

                        toast.success("Задание сохранено", TOAST_SUCCESS_CONFIG);
                        history.push(
                            mode === FOR_ACADEMY
                                ? ADMIN_ROUTES.CONSTRUCTORS.ROOT
                                : mode === FOR_LESSON
                                ? TEACHER_ROUTES.TRAINING.TASKS.MY
                                : null,
                        );
                    });
            })
            .catch(() => {
                toast.error("Ошибка сохранения", TOAST_SUCCESS_CONFIG);
                setIsLoading(false);
            });
    };

    if (isLoading) return <SpinnerPage info />;

    return (
        <React.Fragment>
            <MDBContainer>
                <MDBRow className="mb-3">
                    <MDBCol size="3" className="d-flex align-items-center">
                        <p className={styles.propertyText}>Сложность задания</p>
                    </MDBCol>
                    <MDBCol size="9">
                        <MDBBtnGroup className="w-100">
                            {difficulties.map((difficult) => (
                                <MDBBtn
                                    color="info"
                                    key={difficult.id}
                                    active={selectedLevel === difficult.level}
                                    onClick={() => setSelectedLevel(difficult.level)}
                                >
                                    {difficult.name}
                                </MDBBtn>
                            ))}
                        </MDBBtnGroup>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="mb-3">
                    <MDBCol size="3" className="d-flex align-items-center">
                        <p className={styles.propertyText}>Время выполнения</p>
                    </MDBCol>
                    <MDBCol size="9">
                        <MDBBtnGroup className="w-100">
                            {timeData.map((time) => (
                                <MDBBtn
                                    color="info"
                                    key={time.id}
                                    active={selectedTime === time.time}
                                    onClick={() => setSelectedTime(time.time)}
                                >
                                    {time.name}
                                </MDBBtn>
                            ))}
                        </MDBBtnGroup>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="mb-3">
                    <MDBCol size="3" className="d-flex align-items-center">
                        <p className={styles.propertyText}>Предмет</p>
                    </MDBCol>
                    <MDBCol size="9">
                        <SchoolSubjectsSelectContainer
                            handleSelectSubject={handleSelectSubject}
                            selectedSubjects={selectedSubjects}
                            isMulti={true}
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow className="mb-3">
                    <MDBCol size="3" className="d-flex align-items-center">
                        <p className={styles.propertyText}>Класс</p>
                    </MDBCol>
                    <MDBCol size="9">
                        <SchoolClassesSelectButtonsContainer
                            selectedClasses={selectedClasses}
                            setSelectedClasses={setSelectedClasses}
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow className="mb-3">
                    <MDBCol size="3" className="d-flex align-items-center">
                        <p className={styles.propertyText}>Темы задания</p>
                    </MDBCol>
                    <MDBCol size="9">
                        <SchoolThemesSelectContainer
                            classes={selectedClasses}
                            subjects={selectedSubjects}
                            handleSelectTheme={handleSelectTheme}
                            themes={selectedTheme}
                        />
                    </MDBCol>
                </MDBRow>

                {!isEditMode && withEditMessage && (
                    <MDBAlert color="warning" className="mt-5">
                        <p>
                            <i className="fa fa-info-circle" aria-hidden="true" />
                            Внимание! Убедитесь, что задание сделано правильно, перед тем, как
                            нажать на кнопку «Завершить задание». Если вы создатите задание этого
                            типа, то в дальнейшем не сможете его изменить. В ближайшее время мы
                            добавим возможность редактировать задания этого типа.
                        </p>
                    </MDBAlert>
                )}

                <MDBRow>
                    <MDBCol size="3" />
                    <MDBCol size="9" className="d-flex justify-content-start mt-5">
                        <MDBBtn
                            color="success"
                            disabled={
                                !selectedSubjects ||
                                !selectedTheme ||
                                selectedSubjects?.length === 0 ||
                                selectedTheme?.length === 0
                            }
                            onClick={saveProperties}
                        >
                            Завершить задание
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </React.Fragment>
    );
};

TestParameters.propTypes = {
    apiInstance: PropTypes.object,
    className: PropTypes.string,
    isEditMode: PropTypes.bool,
    mode: PropTypes.string,
    testId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    withEditMessage: PropTypes.bool,
};
