import React, {useState} from "react";
import cn from "classnames";
import styles from "./styles.module.sass";
import {EditButtons} from "../../../../views/components/edit-buttons/edit-buttons";
import ArticleTitle from "../../article-title/article-title";
import {EPublicationCustomActionTypes} from "../../../../enums/EPublicationCustomActionTypes";
import {AsyncPaginate} from "react-select-async-paginate";
import {ISelect} from "../../../../interfaces/ISelect";
import {ArticleAPI} from "../../core/article-api";
import {url} from "../../../../config";
import {Link, useHistory} from "react-router-dom";
import {PAGES_ROUTES} from "../../../../views/routes/routes";
import {TagColors} from "../../core/tag-colors";
import TagList from "../../../tags/views/tag-list/tag-list";

interface IProps {
    block: any;
    isViewMode: boolean;
    handleDelete: (id: number) => void;
    handleCustomAction: (type: EPublicationCustomActionTypes, payload: any) => void;
}

const ArticleSimilarBlock = ({block, handleDelete, isViewMode, handleCustomAction}: IProps) => {
    const [articles] = useState<ISelect[] | null>(null);
    let history = useHistory();

    async function loadOptions(search: string, options: any, {page}: any) {
        const response = await ArticleAPI.getPublications(page);

        return {
            options: response.data.content.map((item: any) => {
                return {
                    value: item.id,
                    label: item.title,
                };
            }),
            hasMore: !response.data.last,
            additional: {
                page: page + 1,
            },
        };
    }

    return (
        <div
            className={cn({
                [styles.similarBlock]: true,
                [styles.editMode]: !isViewMode,
            })}
        >
            <ArticleTitle
                isViewMode={isViewMode}
                handleCustomAction={handleCustomAction}
                block={block}
                title={block.description}
            />

            {!isViewMode && (
                <div className={styles.selector}>
                    <AsyncPaginate
                        value={articles}
                        loadOptions={loadOptions}
                        onChange={(e: ISelect) =>
                            handleCustomAction(EPublicationCustomActionTypes.SIMILAR_ADD_ARTICLE, {
                                articleId: e.value,
                                blockId: block.id,
                            })
                        }
                        additional={{
                            page: 1,
                        }}
                        placeholder="Выберите статью"
                        styles={{menu: (provided: any) => ({...provided, zIndex: 9999})}}
                    />
                </div>
            )}

            <div className={styles.cards}>
                {block.similarPubs?.map((b: any) => (
                    <div
                        className={styles.card}
                        key={b.id}
                        onClick={(e: any) => {
                            e.preventDefault();
                            e.target.id !== "delete-action" &&
                                history.push(
                                    PAGES_ROUTES.PUBLICATIONS.VIEW.replace(
                                        ":url",
                                        b.publication.publicationUrl,
                                    ),
                                );
                        }}
                    >
                        <div className={styles.image}>
                            {b.publication.imageOne && (
                                <img
                                    src={url + b.publication.imageOne.fileDownloadUri.slice(1)}
                                    alt="preview"
                                />
                            )}
                        </div>

                        <div className={styles.linkWrapper}>
                            <Link
                                to={PAGES_ROUTES.PUBLICATIONS.VIEW.replace(
                                    ":url",
                                    b.publication.publicationUrl,
                                )}
                                className={styles.title}
                            >
                                {b.publication.title}
                            </Link>
                        </div>

                        <TagList
                            tags={b.publication.tags.filter((t: any) => t.sectionTag?.id === 72)}
                            cardClassName={styles.tag}
                            isCards
                            className={styles.tagWrapper}
                            withShowAllTags
                            customTagColors={TagColors}
                            hideDelete={!isViewMode}
                        />

                        {!isViewMode && (
                            <EditButtons
                                deleteAction={() =>
                                    handleCustomAction(
                                        EPublicationCustomActionTypes.SIMILAR_DELETE_ARTICLE,
                                        {blockId: block.id, articleId: b.id},
                                    )
                                }
                                withoutDeleteConfirmation
                            />
                        )}
                    </div>
                ))}
            </div>

            {!isViewMode && (
                <EditButtons
                    deleteAction={() => handleDelete(block.id)}
                    isSolidBorder
                    itemId={block.id}
                />
            )}
        </div>
    );
};

export default ArticleSimilarBlock;
