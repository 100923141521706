import crown from "assets/images/static/crown.png";
import bottomConfetti from "assets/sliders/buy-subscription/bottom-confetti.svg";
import bottomEffect from "assets/sliders/buy-subscription/bottom-effect.svg";
import raven from "assets/sliders/buy-subscription/raven.svg";
import topConfetti from "assets/sliders/buy-subscription/top-confetti.svg";
import cn from "classnames";
import {url} from "config";
import {convertDateTime} from "core/utils/helpers";
import EnterButton from "entries/enter-button/enter-button";
import InputWithMask from "entries/input-with-mask/input-with-mask";
import NotificationModalLayer from "entries/notifications-modal/notification-modal-layer";
import PremiumCard from "entries/premium-card/premium-card";
import SpinnerWithText from "entries/spinner/spinner";
import {MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useHistory} from "react-router-dom";
import {PAGES_ROUTES} from "views/routes/routes";
import styles from "./styles.module.sass";

export const SubTypes = {
    ONE_YEAR: "1 год",
    SIX_MONTH: "6 месяцев",
    THREE_MONTH: "3 месяца",
    ONE_MONTH: "1 месяц",
};

const BuySubscription = ({
    tabs,
    selectedTab,
    setSelectedTab,
    currentSlide,
    setCurrentSlide,
    isPaymentDone,
    withPaymentResult,
    buttonWrapperRef,
    isPaymentParametersLoading,
    subscriptionsIsLoading,
    subscription,
    handlePayment,
    allSubscriptions,
    modalIsVisible,
    setModalIsVisible,
    modalContent,
}) => {
    const history = useHistory();
    const [checkValue, setCheckValue] = useState(false);
    const [selectedSub, setSelectedSub] = useState(null);
    const [promoIsOpen, setPromoIsOpen] = useState(false);
    const [promoValue, setPromoValue] = useState("");

    useEffect(() => {
        setCheckValue(false);
        setPromoValue("");
    }, [currentSlide, selectedTab]);

    return (
        <React.Fragment>
            <NotificationModalLayer
                isActive={modalIsVisible}
                toggle={() => setModalIsVisible(!modalIsVisible)}
            >
                {modalContent}
            </NotificationModalLayer>

            <div className={styles.container}>
                <Helmet>
                    <title>Подписка на Умную Ворону</title>
                    <meta
                        name="description"
                        content={`Годовая подписка на Академию и Турниры Умной Вороны. Откройте тысячи интерактивных по шести школьным предметам для начальных классов!`}
                    />
                    <meta
                        name="keywords"
                        content={`математика английский язык русский логика литературное чтение окружающий мир дистанционный программа курс начальная школа 1 2 3 4 класс`}
                    />
                </Helmet>
                <img className={styles.raven} src={raven} alt="ravenLogo" />
                <div className={styles.topConfettiWrapper}>
                    <div className={styles.topConfetti}>
                        <img src={topConfetti} alt="topConfetti" />
                    </div>
                </div>
                <div className={styles.bottomConfettiWrapper}>
                    <div className={styles.bottomConfetti}>
                        <img src={bottomConfetti} alt="bottomConfetti" />
                        <img className={styles.fadeEffect} src={bottomEffect} alt="bottomEffect" />
                    </div>
                </div>

                {withPaymentResult ? (
                    <div className={styles.paymentContent}>
                        {isPaymentDone ? (
                            <img src={crown} alt="crown" />
                        ) : (
                            <img
                                src={
                                    url + "data/image-bank/5fbdf0af-b8c4-4dbd-bf07-0674c1fb1c0d.png"
                                }
                                alt="crown"
                            />
                        )}
                        <p
                            className={styles.paymentTitle}
                            style={{color: isPaymentDone ? "green" : "white"}}
                        >
                            {isPaymentDone ? "Оплата прошла успешно" : " Что-то пошло не так ;("}
                        </p>
                        {!isPaymentDone ? (
                            <p className={styles.paymentDescription}>
                                Оплата не прошла. Если вы столкнулись с какими-то трудностями при
                                оплате, пожалуйста, сообщите нам <br /> на support@umnayavorona.ru
                                или по телефону <br /> 8 (8362) 72-02-62 мы с радостью вам поможем!
                            </p>
                        ) : (
                            <p className={styles.paymentDescription}>
                                Поздравляем! Вы успешно оформили подписку на Умную Ворону и можете
                                начать пользоваться ей прямо сейчас.
                            </p>
                        )}
                        {!isPaymentDone ? (
                            <MDBBtn
                                onClick={() => history.push(PAGES_ROUTES.PREMIUM.ROOT)}
                                color="info"
                            >
                                Вернуться к тарифам
                            </MDBBtn>
                        ) : (
                            <EnterButton />
                        )}
                    </div>
                ) : (
                    <React.Fragment>
                        {currentSlide === 1 ? (
                            <React.Fragment>
                                <div className={styles.content}>
                                    <div className={styles.contentCenter}>
                                        <div className={styles.tabs}>
                                            {tabs.map((tab, index) => (
                                                <div
                                                    key={index}
                                                    className={cn({
                                                        [styles.tab]: true,
                                                        [styles.tabIsActive]:
                                                            selectedTab.value === tab.value,
                                                    })}
                                                    onClick={() => setSelectedTab(tab)}
                                                >
                                                    <p className={styles.tabClass}>{tab.value}</p>
                                                    <p>класс</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div className={styles.cards}>
                                            <PremiumCard
                                                backgroundHeaderURL="data/image-bank/8e4a3505-d58c-434c-8078-0a8c9b54fbda.svg"
                                                logoHeaderURL="data/image-bank/d123ffb5-8a7b-40b7-b268-841bbad433ab.svg"
                                                title="Этика"
                                                description="доступ ко всем заданиям"
                                            />
                                            <PremiumCard
                                                backgroundHeaderURL="data/image-bank/d9618de9-73bf-4afd-bd3d-15ebf7c2391a.svg"
                                                logoHeaderURL="data/image-bank/5fcc7b57-8a29-4039-8c06-bd89b8dab22f.svg"
                                                title="Логика"
                                                description="доступ ко всем заданиям"
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.buttonsWrapper}>
                                        {subscriptionsIsLoading ? (
                                            <SpinnerWithText
                                                text="Загружаем подписки"
                                                color="white"
                                                className="mt-5"
                                            />
                                        ) : (
                                            <React.Fragment>
                                                {allSubscriptions.length ? (
                                                    <React.Fragment>
                                                        {allSubscriptions
                                                            .filter(
                                                                (sub) =>
                                                                    sub.classTag?.id ===
                                                                    selectedTab.value,
                                                            )
                                                            .map((sub, idx) => (
                                                                <div
                                                                    className={cn({
                                                                        [styles.button]: true,
                                                                        [styles.buttonBuy]: true,
                                                                    })}
                                                                    key={idx}
                                                                    onClick={() => {
                                                                        setSelectedSub(sub);
                                                                        setCurrentSlide(2);
                                                                    }}
                                                                >
                                                                    <p>
                                                                        <i className="fas fa-check" />
                                                                        {
                                                                            SubTypes[
                                                                                sub.subscriptionType
                                                                            ]
                                                                        }
                                                                    </p>

                                                                    <p>
                                                                        <i className="fas fa-check" />
                                                                        {sub.subscriptionType ===
                                                                        "ONE_MONTH"
                                                                            ? "50"
                                                                            : sub.subscriptionType ===
                                                                              "THREE_MONTH"
                                                                            ? "150"
                                                                            : sub.subscriptionType ===
                                                                              "SIX_MONTH"
                                                                            ? "300"
                                                                            : sub.subscriptionType ===
                                                                              "ONE_YEAR"
                                                                            ? "600"
                                                                            : 0}{" "}
                                                                        монет
                                                                    </p>

                                                                    <b className={styles.price}>
                                                                        {sub.price} рублей
                                                                    </b>
                                                                </div>
                                                            ))}
                                                    </React.Fragment>
                                                ) : (
                                                    <div className={styles.button}>
                                                        <s className={styles.discount}>
                                                            Подписок для этого класса пока нет
                                                        </s>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>

                                    {subscription && (
                                        <div
                                            className={cn({
                                                [styles.button]: true,
                                                [styles.info]: true,
                                                "w-100": true,
                                            })}
                                            style={{cursor: "auto"}}
                                        >
                                            <p className="mb-0">
                                                Подписка активна до{" "}
                                                {convertDateTime(subscription?.activeUntil, " ")}{" "}
                                                для {subscription?.classTag} класса
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div
                                    className={cn({
                                        "animate__animated animate__fadeIn": true,
                                        [styles.payContent]: true,
                                    })}
                                >
                                    <p className="link" onClick={() => setCurrentSlide(1)}>
                                        Вернуться к выбору класса
                                    </p>
                                    <div className={styles.payWrapper}>
                                        <div className={styles.payInfo}>
                                            <div className={styles.payDate}>
                                                <h3>{selectedSub.name}</h3>
                                            </div>

                                            <div className={styles.payDescription}>
                                                <p style={{marginBottom: "5px"}}>
                                                    <i className="fas fa-check" /> Доступ к
                                                    «Академии Умной Вороны»
                                                </p>
                                                <div className={styles.payAccess}>
                                                    <p style={{marginBottom: "5px"}}>
                                                        <b>{selectedTab.value} класс</b>
                                                    </p>
                                                    <p>
                                                        Открываются задания:{" "}
                                                        {selectedTab?.subjects?.map(
                                                            (subject, index) => {
                                                                let sub = subject.toLowerCase();
                                                                if (
                                                                    index !==
                                                                    selectedTab?.subjects.length - 1
                                                                ) {
                                                                    sub += ", ";
                                                                }
                                                                return sub;
                                                            },
                                                        )}
                                                    </p>
                                                </div>

                                                <p>
                                                    <i className="fas fa-check" /> Доступ к
                                                    «Турнирам Умной Вороны»
                                                </p>
                                            </div>

                                            <p className={styles.payPrice}>
                                                {selectedSub.price} рублей
                                            </p>

                                            <div className={styles.offer}>
                                                <input
                                                    type="checkbox"
                                                    value={checkValue}
                                                    onChange={() => setCheckValue(!checkValue)}
                                                />
                                                <p>
                                                    Я принимаю условия{" "}
                                                    <span
                                                        className="link"
                                                        onClick={() =>
                                                            window.open(
                                                                "https://umnayavorona.ru/offer",
                                                                "_blank",
                                                            )
                                                        }
                                                    >
                                                        оферты
                                                    </span>
                                                </p>
                                            </div>

                                            <div
                                                ref={buttonWrapperRef}
                                                className={styles.footerWrapper}
                                            >
                                                <MDBBtn
                                                    color="success"
                                                    disabled={
                                                        isPaymentParametersLoading ||
                                                        !checkValue ||
                                                        !selectedSub.id
                                                    }
                                                    onClick={() =>
                                                        handlePayment(selectedSub.id, promoValue)
                                                    }
                                                >
                                                    Оплатить
                                                </MDBBtn>

                                                <div className={styles.promoWrapper}>
                                                    {promoIsOpen ? (
                                                        <InputWithMask
                                                            value={promoValue}
                                                            setValue={setPromoValue}
                                                        />
                                                    ) : (
                                                        <p
                                                            onClick={() => setPromoIsOpen(true)}
                                                            className={styles.promoText}
                                                        >
                                                            Ввести промокод
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.payLogo}>
                                            <div className={styles.payLogoImage}>
                                                <img src={crown} alt="crown" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

BuySubscription.propTypes = {
    buttonWrapperRef: PropTypes.any,
    isPaymentDone: PropTypes.bool,
    isPaymentParametersLoading: PropTypes.bool,
    selectedTab: PropTypes.object,
    setSelectedTab: PropTypes.func,
    subscription: PropTypes.any,
    subscriptionsIsLoading: PropTypes.bool,
    tabs: PropTypes.array,
    allSubscriptions: PropTypes.array,
};

export default BuySubscription;
