const RANDOM_GAME_MESSAGES = [
    "В сундуке можно найти предметы, монеты или другой сундук.",
    "В деревянном сундуке чаще выпадают простые предметы.",
    "В серебряном сундуке можно найти вещи покруче.",
    "В золотом сундуке можно найти самые крутые вещи!",
    "В золотом сундуке можно найти любую вещь из игры!",
    "Не забывай продавать ненужные вещи и покупать новые сундуки.",
    "Старайся учиться и сможешь отыскать волшебные предметы!",
    "Даже в деревянном сундуке можно найти золотой. Но очень редко…",
    "Участвуй в турнирах и получай сундуки!",
    "Решай задания, копи монеты и покупай сундуки!",
    "Собери самого крутого героя во всём классе!",
    "Приглашай одноклассников и играй вместе с ними!",
    "Купи перо переименования, чтобы выбрать имя героя!",
    "Купи медальон питомца и выбери ему имя!",
    "Ты можешь собрать любое количество вещей, а ненужные продать!",
    "В сундуках лежат сотни разных вещей!",
    "Решишь без ошибок – получишь крутой сундук!",
    "Реши все задания по теме, чтобы получить сундук!",
    "Реши все задания по теме, чтобы получить сундук!",
    "Реши все задания по теме, чтобы получить сундук!",
    "В игре есть не только причёски, но и уникальные выражения лица!",
    "Собери самую крутую коллекцию вещей!",
    "Ты даже не представляешь, какие у меня есть питомцы!",
    "После прохождения темы не забудь заглянуть в сундук!",
    "О, сколько нам открытий чудных готовят сундуки!",
];

export const getRandomGameMessage = () => {
    return RANDOM_GAME_MESSAGES[Math.floor(Math.random() * RANDOM_GAME_MESSAGES.length)];
};
