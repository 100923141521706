import cn from "classnames";
import {PLAYER_RIGHTS} from "entries/player/views/player/utils/player-config";
import {MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";
import styles from "./player-v2-controls.module.sass";

const PlayerV2Controls = ({handleCheckAnswer, answer, userRights}) => {
    return (
        <div
            className={cn({
                [styles.controls]: true,
                [styles.isVisible]: !userRights.includes(PLAYER_RIGHTS.HAVE_INFO_BLOCK),
            })}
        >
            <MDBBtn
                color={!answer ? "blue-grey" : "success"}
                className={styles.controls__check}
                onClick={handleCheckAnswer}
                disabled={!answer}
            >
                Ответить
            </MDBBtn>
        </div>
    );
};

PlayerV2Controls.propTypes = {
    answer: PropTypes.any,
    handleCheckAnswer: PropTypes.func,
    handleToNextQuestion: PropTypes.func,
};

export default PlayerV2Controls;
