import React from "react";
import {SortableElement} from "react-sortable-hoc";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./DraggableItem.module.sass";

const DraggableElement = SortableElement((props) => {
    const {className, ...controlProps} = props;

    return (
        <div
            className={cn({
                [styles.ListItem]: true,
                [className]: !!className,
            })}
            {...controlProps}
        />
    );
});

DraggableElement.propTypes = {
    index: PropTypes.number.isRequired,
    className: PropTypes.string,
};

export const DraggableItem = (props) => {
    return <DraggableElement {...props} />;
};

DraggableItem.propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    className: PropTypes.string,
};
