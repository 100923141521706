export const OUTLINE_TYPE = {
    CIRCLE: "CIRCLE",
    SQUARE: "SQUARE",
};

export const ITEMS_TYPE = {
    IMAGE: "image",
    TEXT: "text",
};

export const STAGE_PADDING = 5;

export const SIZE = {
    S: {
        fontSize: 12,
        label: "S",
        maxImageHeight: 100,
        maxImageWidth: 100,
        maxPreviewImagesCount: 5,
        maxStageImagesCount: 5,
        maxPreviewTextCount: 5,
        maxStageTextCount: 5,
        minImageHeight: 30,
        minImageWidth: 30,
        value: "S",
        zoomLevel: 0.5,
    },
    M: {
        fontSize: 14,
        label: "M",
        maxImageHeight: 200,
        maxImageWidth: 200,
        maxPreviewImagesCount: 5,
        maxStageImagesCount: 5,
        maxPreviewTextCount: 5,
        maxStageTextCount: 5,
        minImageHeight: 80,
        minImageWidth: 80,
        value: "M",
        zoomLevel: 1,
    },
    L: {
        fontSize: 16,
        label: "L",
        maxImageHeight: 300,
        maxImageWidth: 300,
        maxPreviewImagesCount: 5,
        maxStageImagesCount: 4,
        maxPreviewTextCount: 5,
        maxStageTextCount: 4,
        minImageHeight: 130,
        minImageWidth: 130,
        value: "L",
        zoomLevel: 1.5,
    },
    XL: {
        fontSize: 18,
        label: "XL",
        maxImageHeight: 400,
        maxImageWidth: 400,
        maxPreviewImagesCount: 5,
        maxStageImagesCount: 3,
        maxPreviewTextCount: 5,
        maxStageTextCount: 3,
        minImageHeight: 230,
        minImageWidth: 230,
        value: "XL",
        zoomLevel: 2,
    },
};
