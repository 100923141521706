import React, {useEffect, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import {Button} from "antd";
import {useStores} from "../../../../../hooks/use-stores";
import {observer} from "mobx-react-lite";
import {runInAction} from "mobx";

const DictationText = observer(() => {
    const [value, setValue] = useState("");
    const [status, setStatus] = useState<"error" | "warning" | undefined>(undefined);

    const {constructorV2Store} = useStores();

    useEffect(() => {
        if (constructorV2Store.payload?.text) {
            setValue(constructorV2Store.payload.text);
        }
    }, []);

    const handleSave = () => {
        if (!value.trim()) {
            setStatus("error");
            return;
        } else {
            setStatus(undefined);
        }

        constructorV2Store.payload = {
            ...constructorV2Store.payload,
            text: value,
        };

        runInAction(() => {
            constructorV2Store.step = constructorV2Store.step + 1;
        });
    };

    return (
        <div>
            <TextArea
                placeholder="Введите текст диктанта..."
                maxLength={500}
                showCount
                className="mb-2"
                rows={10}
                value={value}
                status={status}
                onChange={(e) => {
                    setValue(e.target.value);
                    if (status === "error") {
                        setStatus(undefined);
                    }
                }}
            />
            <Button size={"large"} type="primary" onClick={handleSave}>
                Сохранить текст и продолжить
            </Button>
        </div>
    );
});

export default DictationText;
