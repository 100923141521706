import {
    DICTATION_EMPTY_DETECT,
    DICTATION_SKIP_DETECT,
} from "entries/constructors/dictation/views/pages/dictation-page";
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "entries/constructors/dictation/views/containers/select-symbol-box/select-symbol-box.module.sass";

export const SelectSymbolBox = ({separatedWord, onSelectSymbolIndex, selectedSymbolIndex}) => {
    return (
        <div>
            <p
                className={cn({
                    [styles.title]: true,
                    title: true,
                })}
            >
                Выберите символ:
            </p>
            {
                <p className={styles.text}>
                    {separatedWord.map((symbol, index) => (
                        <span
                            key={index}
                            onClick={() => onSelectSymbolIndex(index)}
                            className={cn({
                                [styles.symbol]: true,
                                [styles.selectedSymbol]: selectedSymbolIndex === index,
                                skipBox:
                                    symbol === DICTATION_SKIP_DETECT ||
                                    symbol === DICTATION_EMPTY_DETECT,
                            })}
                        >
                            {symbol === DICTATION_SKIP_DETECT || symbol === DICTATION_EMPTY_DETECT
                                ? ""
                                : symbol}
                        </span>
                    ))}
                </p>
            }
        </div>
    );
};

SelectSymbolBox.propTypes = {
    selectedSymbolIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    selectedWordIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    separatedWord: PropTypes.array,
    onSelectSymbolIndex: PropTypes.func,
};
