export const PLAYER_RIGHTS = {
    CAN_NAVIGATE: "can-navigate",
    CAN_RETRY: "can-retry",
    CAN_NAVIGATE_TO_PREVIOUSLY: "can-navigate-to-previously",
    CAN_NAVIGATE_TO_NEXT: "can-navigate-to-next",
    HAVE_THEME: "have-theme",
    HAVE_SUBJECT: "have-subject",
    HAVE_INFO_BLOCK: "have-info-block",
    HAVE_ACHIEVEMENT: "have-achievement",
    HAVE_TASK_RATING: "have-task-rating",
    HAVE_TO_NEXT_BUTTON: "have-to-next-button",
    HAVE_TIMER: "have-timer",
    HAVE_TASK_INFO: "task-info",
    HAVE_NAVIGATE: "have-navigate",
    HAVE_ROCKET_STEPPER: "have-rocket-stepper",
    CAN_SWIPE_LEFT: "CAN_SWIPE_LEFT",
    CAN_SWIPE_RIGHT: "CAN_SWIPE_RIGHT",
    RATING_EFFECT: "RATING_EFFECT",
};

export const academyModeRights = [
    PLAYER_RIGHTS.CAN_NAVIGATE,
    PLAYER_RIGHTS.CAN_NAVIGATE_TO_PREVIOUSLY,
    PLAYER_RIGHTS.HAVE_NAVIGATE,
    PLAYER_RIGHTS.HAVE_THEME,
    PLAYER_RIGHTS.HAVE_SUBJECT,
    PLAYER_RIGHTS.HAVE_ACHIEVEMENT,
    PLAYER_RIGHTS.HAVE_TIMER,
    PLAYER_RIGHTS.HAVE_TASK_INFO,
    PLAYER_RIGHTS.HAVE_TO_NEXT_BUTTON,
    PLAYER_RIGHTS.CAN_NAVIGATE_TO_NEXT,
    PLAYER_RIGHTS.CAN_RETRY,
    PLAYER_RIGHTS.CAN_SWIPE_LEFT,
    PLAYER_RIGHTS.CAN_SWIPE_RIGHT,
    PLAYER_RIGHTS.RATING_EFFECT,
    PLAYER_RIGHTS.HAVE_INFO_BLOCK,
];

export const articleModeRights = [
    PLAYER_RIGHTS.CAN_NAVIGATE,
    PLAYER_RIGHTS.CAN_NAVIGATE_TO_PREVIOUSLY,
    PLAYER_RIGHTS.HAVE_NAVIGATE,
    PLAYER_RIGHTS.HAVE_THEME,
    PLAYER_RIGHTS.HAVE_SUBJECT,
    PLAYER_RIGHTS.HAVE_ACHIEVEMENT,
    PLAYER_RIGHTS.HAVE_TIMER,
    PLAYER_RIGHTS.HAVE_TASK_INFO,
    PLAYER_RIGHTS.HAVE_TO_NEXT_BUTTON,
    PLAYER_RIGHTS.CAN_NAVIGATE_TO_NEXT,
    PLAYER_RIGHTS.CAN_RETRY,
    PLAYER_RIGHTS.CAN_SWIPE_LEFT,
    PLAYER_RIGHTS.CAN_SWIPE_RIGHT,
    PLAYER_RIGHTS.RATING_EFFECT,
];

export const tournamentModeRights = [
    PLAYER_RIGHTS.HAVE_ACHIEVEMENT,
    PLAYER_RIGHTS.HAVE_TIMER,
    PLAYER_RIGHTS.HAVE_TASK_INFO,
    PLAYER_RIGHTS.CAN_SWIPE_RIGHT,
    PLAYER_RIGHTS.HAVE_INFO_BLOCK,
];

export const previewModeRights = [
    PLAYER_RIGHTS.CAN_NAVIGATE,
    PLAYER_RIGHTS.CAN_NAVIGATE_TO_PREVIOUSLY,
    PLAYER_RIGHTS.HAVE_NAVIGATE,
    PLAYER_RIGHTS.HAVE_THEME,
    PLAYER_RIGHTS.HAVE_SUBJECT,
    PLAYER_RIGHTS.HAVE_ACHIEVEMENT,
    PLAYER_RIGHTS.HAVE_TIMER,
    PLAYER_RIGHTS.HAVE_TASK_INFO,
    PLAYER_RIGHTS.HAVE_TO_NEXT_BUTTON,
    PLAYER_RIGHTS.CAN_NAVIGATE_TO_NEXT,
    PLAYER_RIGHTS.CAN_RETRY,
    PLAYER_RIGHTS.CAN_SWIPE_LEFT,
    PLAYER_RIGHTS.CAN_SWIPE_RIGHT,
    PLAYER_RIGHTS.RATING_EFFECT,
];

export const oldPreviewModeRights = [
    PLAYER_RIGHTS.HAVE_THEME,
    PLAYER_RIGHTS.HAVE_SUBJECT,
    PLAYER_RIGHTS.HAVE_NAVIGATE,
    PLAYER_RIGHTS.CAN_SWIPE_RIGHT,
    PLAYER_RIGHTS.CAN_SWIPE_LEFT,
    PLAYER_RIGHTS.HAVE_INFO_BLOCK,
];

export const teacherModeRights = [
    PLAYER_RIGHTS.CAN_NAVIGATE,
    PLAYER_RIGHTS.CAN_NAVIGATE_TO_PREVIOUSLY,
    PLAYER_RIGHTS.CAN_NAVIGATE_TO_NEXT,
    PLAYER_RIGHTS.CAN_RETRY,
    PLAYER_RIGHTS.HAVE_NAVIGATE,
    PLAYER_RIGHTS.HAVE_THEME,
    PLAYER_RIGHTS.HAVE_SUBJECT,
    PLAYER_RIGHTS.HAVE_TASK_RATING,
    PLAYER_RIGHTS.HAVE_TO_NEXT_BUTTON,
    PLAYER_RIGHTS.HAVE_TASK_INFO,
    PLAYER_RIGHTS.CAN_SWIPE_LEFT,
    PLAYER_RIGHTS.CAN_SWIPE_RIGHT,
    PLAYER_RIGHTS.HAVE_INFO_BLOCK,
    // HAVE_ROCKET_STEPPER,
];

export const ACADEMY_MODE = "academy-mode";
export const TEACHER_MODE = "teacher-mode";
export const PREVIEW_MODE = "preview-mode";

export const TOURNAMENT_MODE = "tournament-mode";
export const HOMEWORK_MODE = "homework-mode";
