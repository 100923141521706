import Hints from "entries/hints/hints";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {DictationPlayer} from "entries/player/views/task-players/dictation-player/dictation-player";
import {useParams} from "react-router-dom";

export const DictationPlayerContainer = ({
    taskId,
    onSelectCorrectAnswer,
    questionApi,
    getInfoAboutSet,
    playerMode,
    answerIsLoading,
    setAnswerIsLoading,
}) => {
    const [question, setQuestion] = useState(null);
    const [answers, setAnswers] = useState(null);
    const [properties, setProperties] = useState(null);
    const [selectedPositions, setSelectedPosition] = useState([]);

    let {id} = useParams();

    useEffect(() => {
        getData();
    }, [taskId]);

    const getData = async () => {
        setAnswerIsLoading(true);
        let data = undefined;
        await questionApi(taskId, id)
            .then((response) => {
                if (playerMode === PlayerV2Modes.TOURNAMENT) {
                    data = response.data[0].question;
                } else {
                    data = response.data;
                    getInfoAboutSet();
                }
            })
            .catch(() => console.log("load data error"))
            .finally(() => setAnswerIsLoading(false));

        if (!data) {
            return;
        }

        setQuestion(data.dictation);
        setAnswers(data.dictationAnswerVariants);
        setProperties(data.questionProperties);
    };

    const fillCorrectAnswers = (answers) => {
        let correctAnswers = answers.map((answer) => ({...answer, index: answer.position}));
        setSelectedPosition(correctAnswers);
    };

    return (
        <React.Fragment>
            {!answerIsLoading && (
                <React.Fragment>
                    {playerMode === PlayerV2Modes.ACADEMY && (
                        <Hints question={question} onBuyCorrectAnswer={fillCorrectAnswers} />
                    )}

                    {question && answers && properties && (
                        <DictationPlayer
                            question={question}
                            answers={answers}
                            properties={properties}
                            onSelectCorrectAnswer={onSelectCorrectAnswer}
                            selectedPositions={selectedPositions}
                            setSelectedPosition={setSelectedPosition}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

DictationPlayerContainer.propTypes = {
    getInfoAboutSet: PropTypes.func,
    playerMode: PropTypes.string,
    questionApi: PropTypes.func,
    reset: PropTypes.any,
    taskId: PropTypes.number,
    onSelectCorrectAnswer: PropTypes.func,
    answerIsLoading: PropTypes.bool,
    setAnswerIsLoading: PropTypes.func,
};
