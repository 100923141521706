import raven from "assets/characters/raven/notebook.svg";
import {url} from "config";
import {getRandomInitializeMessage} from "entries/academy/core/utils/player-v2-random-messages";
import {AchievementsListContainer} from "entries/achievements/achievements-list/achievements-list-container";
import HintsAdd from "entries/hints/hints-add/hints-add";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import TaskFeedbackModal from "entries/task-feedback-modal/task-feedback-modal";
import {EWebsocketTypes} from "enums/EWebsocketTypes";
import {Roles} from "enums/roles";
import {useStores} from "hooks/use-stores";
import {WS_TYPES} from "hooks/useWebsocket";
import {MDBBtn} from "mdbreact";
import {observer} from "mobx-react-lite";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {getRandomGameMessage} from "entries/academy/core/utils/game-messages";
import {getRandomPremiumMessage} from "entries/academy/core/utils/game-premium-message";
import UpdateImageQuestion from "../../../update-image-question/update-image-question";
import PlayerV2Timer from "../player-v2-timer/player-v2-timer";
import mobileStyles from "./mobile-styles.module.sass";
import browserStyles from "./styles.module.sass";

const PlayerV2InfoBlock = observer(
    ({
        selectedQuestion,
        taskId,
        isAdmin,
        handleCheckAnswer,
        answer,
        playerMode,
        resultIsLoading,
        withAnswerButton = true,
        isCreator,
    }) => {
        const [styles, setStyles] = useState(browserStyles);
        const {playerStore, appStore, userStore} = useStores();

        useEffect(() => {
            if (playerStore.isMobileMode) {
                setStyles(mobileStyles);
            } else {
                setStyles(browserStyles);
            }
        }, [playerStore.isMobileMode]);

        useEffect(() => {
            let message = {
                description:
                    playerMode === PlayerV2Modes.TOURNAMENT
                        ? "Я не буду говорить тебе ответы после решения. Ты узнаешь свой результат после завершения турнира!"
                        : getRandomInitializeMessage(),
                objectType: WS_TYPES.OTHER,
                imageUri: "/data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg",
            };
            appStore.websocketMessages.push(message);

            if (playerMode === PlayerV2Modes.ACADEMY) {
                let messageAboutGame = {
                    description: getRandomGameMessage(),
                    objectType: WS_TYPES.OTHER,
                    imageUri: "/data/image-bank/e47f627d-29a6-4960-a3e2-487c123ce59f.svg",
                };

                appStore.websocketMessages.push(messageAboutGame);

                let messageAboutPremium = {
                    description: getRandomPremiumMessage(),
                    objectType: WS_TYPES.OTHER,
                    imageUri: "/data/image-bank/c2460cd7-2e5b-4c2d-b875-43905f7a6e11.svg",
                };

                appStore.websocketMessages.push(messageAboutPremium);
            }

            return () => {
                appStore.websocketMessages = appStore.websocketMessages.filter(
                    (m) => m.objectType !== WS_TYPES.OTHER,
                );
            };
        }, []);

        return (
            <div className={styles.info}>
                <div className={styles.header}>
                    {isAdmin && <p>ID - {taskId}</p>}
                    {selectedQuestion?.academyBlockAvatar && (
                        <img
                            src={url + selectedQuestion.academyBlockAvatar.fileDownloadUri.slice(1)}
                            alt="logo"
                        />
                    )}

                    {playerMode === PlayerV2Modes.HOMEWORK && (
                        <div>
                            <img src={raven} alt="logo" />
                            <p className={styles.header__subject}>Задание от учителя</p>
                        </div>
                    )}

                    <p className={styles.header__subject}>{selectedQuestion?.academyBlockName}</p>
                    <p className={styles.header__theme}>{selectedQuestion?.academySetName}</p>
                </div>

                {(isAdmin || playerMode === PlayerV2Modes.OLD_PREVIEW) && (
                    <div>
                        <UpdateImageQuestion questionId={taskId} />
                        <HintsAdd questionId={taskId} />
                    </div>
                )}

                {playerMode !== PlayerV2Modes.OLD_PREVIEW && !isAdmin && (
                    <div className={styles.achievements}>
                        <AchievementsListContainer
                            achievements={appStore.websocketMessages.filter(
                                (m) =>
                                    m.objectType === EWebsocketTypes.OTHER ||
                                    m.objectType === EWebsocketTypes.ACHIEVEMENT,
                            )}
                        />
                    </div>
                )}

                {playerMode !== PlayerV2Modes.OLD_PREVIEW && (
                    <PlayerV2Timer selectedQuestion={selectedQuestion} />
                )}

                {userStore.role === Roles.TEACHER && !isCreator && (
                    <p
                        className="link text-center mb-3"
                        style={{fontSize: "1.1rem"}}
                        onClick={() =>
                            appStore.addModalToQueue(
                                <TaskFeedbackModal
                                    toggle={appStore.closeActiveModal}
                                    key={"task-feedback-modal toggle-disabled"}
                                    question={selectedQuestion}
                                />,
                            )
                        }
                    >
                        Сообщить об ошибке
                    </p>
                )}

                {withAnswerButton && (
                    <MDBBtn
                        color={!answer ? "blue-grey" : "success"}
                        className={styles.buttonAnswer}
                        onClick={handleCheckAnswer}
                        disabled={!answer || resultIsLoading}
                    >
                        {resultIsLoading ? "Проверяю..." : "Ответить"}
                    </MDBBtn>
                )}
            </div>
        );
    },
);

PlayerV2InfoBlock.propTypes = {
    answer: PropTypes.any,
    handleCheckAnswer: PropTypes.func,
    isAdmin: PropTypes.bool,
    playerMode: PropTypes.any,
    resultIsLoading: PropTypes.bool,
    selectedQuestion: PropTypes.object,
    taskId: PropTypes.number,
    withAnswerButton: PropTypes.bool,
};

export default PlayerV2InfoBlock;
