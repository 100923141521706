import SchoolThemesSelect from "entries/school-themes-select/school-themes-select";
import React from "react";
import PropTypes from "prop-types";

const SchoolThemesSelectContainer = ({subjects, classes, handleSelectTheme, themes}) => {
    return (
        <SchoolThemesSelect
            classes={classes}
            subjects={subjects}
            handleSelectTheme={handleSelectTheme}
            theme={themes}
        />
    );
};

SchoolThemesSelectContainer.propTypes = {
    classes: PropTypes.array,
    handleSelectTheme: PropTypes.func,
    subjects: PropTypes.any,
    themes: PropTypes.any,
};

export default SchoolThemesSelectContainer;
