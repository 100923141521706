import confetti from "assets/animations/confetti2.json";
import cn from "classnames";
import {getRandomMessageByResult} from "entries/player-v2/config/player-messages";
import {
    getRandomRavenWithEmotion,
    RAVEN_EMOTIONS_TYPES,
} from "entries/player-v2/config/raven-emotions";
import {PLAYER_RIGHTS} from "entries/player/views/player/utils/player-config";
import {useStores} from "hooks/use-stores";
import Lottie from "lottie-react";
import {MDBBtn} from "mdbreact";
import {toJS} from "mobx";
import PropTypes from "prop-types";
import React, {useEffect, useRef, useState} from "react";
import CountUp from "react-countup";
import styles from "./styles.module.sass";

const PlayerV2SolvingResult = ({result, handleRetry, handleContinue, userRights, questionId}) => {
    const [emotionImage, setEmotionImage] = useState(null);
    const [message, setMessage] = useState(null);
    const [receivedRating, setReceivedRating] = useState(null);
    let ref = useRef();
    const {appStore} = useStores();

    const getRatingFromSocketMessages = () => {
        let messages = toJS(appStore.websocketMessages);

        if (!messages || !messages.length) return;

        let rating = messages.find((msg) => msg.questionId === questionId)?.rating;

        if (rating) {
            setReceivedRating(rating);
        }
    };

    useEffect(() => {
        if (!result) return;
        getRatingFromSocketMessages();
    }, [appStore.websocketMessages, result]);

    useEffect(() => {
        if (result) {
            setEmotionImage(getRandomRavenWithEmotion(RAVEN_EMOTIONS_TYPES.FUNNY));
        } else {
            setEmotionImage(getRandomRavenWithEmotion(RAVEN_EMOTIONS_TYPES.SAD));
        }

        setMessage(getRandomMessageByResult(result, receivedRating));
    }, [result, questionId, receivedRating]);

    useEffect(() => {
        return () => ref?.current?.destroy();
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.result}>
                <div
                    className={cn({
                        [styles.result__title]: true,
                        animate__animated: true,
                        animate__shakeX: !result,
                        animate__shakeY: result,
                    })}
                >
                    <p className={styles.message}>{message}</p>
                    {result ? (
                        <p
                            className={cn({
                                [styles.result__title_green]: true,
                            })}
                        >
                            {result && <span>Правильный ответ</span>}
                        </p>
                    ) : (
                        <p
                            className={cn({
                                [styles.result__title_red]: true,
                            })}
                        >
                            {!result && <span>Неправильный ответ</span>}
                        </p>
                    )}
                </div>
                <div className={styles.result__narrator}>
                    <img src={emotionImage} alt="narrator" />
                </div>

                {result && (
                    <Lottie
                        animationData={result && confetti}
                        loop={!result}
                        className={styles.result__animation}
                        lottieRef={ref}
                    />
                )}

                <div className={styles.result__buttons}>
                    {!result && userRights.includes(PLAYER_RIGHTS.CAN_RETRY) && (
                        <MDBBtn
                            className={styles.result__buttons_retry}
                            color="warning"
                            onClick={handleRetry}
                        >
                            Попробовать еще
                        </MDBBtn>
                    )}

                    {result && receivedRating && (
                        <p className={styles.rating}>
                            <i className="fa fa-star" aria-hidden="true" />{" "}
                            <CountUp end={receivedRating} duration={1} />
                        </p>
                    )}

                    <MDBBtn
                        className={styles.result__buttons_next}
                        color="success"
                        onClick={handleContinue}
                    >
                        Продолжить
                    </MDBBtn>
                </div>
            </div>
        </div>
    );
};

PlayerV2SolvingResult.propTypes = {
    handleContinue: PropTypes.func,
    handleRetry: PropTypes.func,
    questionId: PropTypes.number,
    result: PropTypes.any,
    selectedQuestion: PropTypes.object,
};

export default PlayerV2SolvingResult;
