import UploadAudioModal from "entries/modals/upload-audio-modal/upload-audio-modal";
import PropTypes from "prop-types";
import React, {useState} from "react";

const UploadAudioModalContainer = ({isOpen, toggle, saveAudio}) => {
    const [audio, setAudio] = useState("");
    return (
        <UploadAudioModal
            isOpen={isOpen}
            toggle={toggle}
            audio={audio}
            setAudio={setAudio}
            saveAudio={saveAudio}
        />
    );
};

UploadAudioModalContainer.propTypes = {
    isOpen: PropTypes.bool,
    saveAudio: PropTypes.func,
    toggle: PropTypes.func,
};

export default UploadAudioModalContainer;
