import {Header} from "actual/shared/header";
import {EventTabs} from "entries/events/core/event-tabs";
import EventsList from "entries/events/events-list/events-list";
import NewsList from "entries/news/news-list/news-list";
import {useStores} from "hooks/use-stores";
import {toJS} from "mobx";
import {observer} from "mobx-react-lite";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import styles from "./events.module.sass";

const Events = observer(() => {
    const {appStore, notificationStore} = useStores();
    const [events, setEvents] = useState([]);

    useEffect(() => {
        if (!appStore.events.length) {
            return;
        }

        setEvents(toJS(appStore.events));
    }, [appStore.events?.length]);

    useEffect(() => {
        notificationStore.unreadEventsCount = 0;
        localStorage.setItem("myLogLastViewedDate", new Date().toString());
    }, []);

    const [activeHeaderItem, setActiveHeaderItem] = useState(EventTabs.ALL);

    return (
        <div className={styles.events}>
            <Header
                items={[
                    {label: "Все", value: EventTabs.ALL},
                    {label: "Достижения", value: EventTabs.ACHIEVEMENT},
                    {label: "Уведомления", value: EventTabs.NOTIFICATION},
                    {label: "Новости", value: EventTabs.NEWS},
                    {label: "Успеваемость", value: EventTabs.PERFORMANCE},
                    {label: "Итоги турниров", value: EventTabs.TOURNAMENT},
                ]}
                activeItem={activeHeaderItem}
                onSetActiveItem={setActiveHeaderItem}
            />

            <div className={styles.events__content}>
                {activeHeaderItem === EventTabs.ALL && <EventsList events={events} />}

                {activeHeaderItem === EventTabs.ACHIEVEMENT && (
                    <EventsList
                        events={events.filter((event) => {
                            if (event.event.eventSubSection === EventTabs.ACHIEVEMENT) {
                                return event;
                            }
                        })}
                    />
                )}

                {activeHeaderItem === EventTabs.NOTIFICATION && (
                    <EventsList
                        events={events.filter(
                            (events) => events.event.eventSubSection === EventTabs.NOTIFICATION,
                        )}
                    />
                )}

                {activeHeaderItem === EventTabs.NEWS && <NewsList isView={true} />}

                {activeHeaderItem === EventTabs.PERFORMANCE && (
                    <EventsList
                        events={events.filter(
                            (event) => event.event.eventSubSection === EventTabs.PERFORMANCE,
                        )}
                    />
                )}

                {activeHeaderItem === EventTabs.TOURNAMENT && (
                    <EventsList
                        events={events.filter(
                            (event) => event.event.eventSubSection === EventTabs.TOURNAMENT,
                        )}
                    />
                )}
            </div>
        </div>
    );
});

Events.propTypes = {
    currentTab: PropTypes.string,
    receivedEvents: PropTypes.array,
    onSelect: PropTypes.func,
};

export default Events;
