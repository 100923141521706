//stepper
//rem
// rem

export const DEFAULT_FONT_SIZE = 15; //px
export const MAX_FONT_SIZE = 21; //px
export const FONT_SIZE_STEP = 3; //px

export const DEFAULT_ANSWER_FONT_SIZE = 15; //px
export const MAX_ANSWER_FONT_SIZE = 27; //px
export const FONT_ANSWER_SIZE_STEP = 3; //px
