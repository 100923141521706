import {url} from "config";
import {CLIPPING_TEST} from "config/constructor-names";
import {ClippingAPI} from "entries/constructors/clipping/core/ClippingAPI";
import {ClippingSecondaryAPI} from "entries/constructors/clipping/core/ClippingSecondaryAPI";
import {
    IMAGE_TYPE_CLIPPING_BANK,
    IMAGE_TYPE_CLIPPING_UPLOAD,
    IMAGE_TYPE_SECONDARY_CLIPPING_BANK,
    IMAGE_TYPE_SECONDARY_CLIPPING_UPLOAD,
} from "entries/constructors/clipping/core/types";
import {IMAGE_TYPE, TEXT_TYPE} from "utils/answers-config";

export const ClippingEngineConverter = async (answers, constructorType, testId) => {
    if (!answers || !answers.length) {
        console.log("Answers or boxes not init");
        return;
    }

    let type = getAnswerType(answers[0]);
    let boxes = await getBoxes(constructorType, testId);

    let tempAnswers = answers.map((answer) => {
        let imageUrl = answer.answerImageQuestion
            ? answer.answerImageQuestion.fileDownloadUri.slice(1)
            : answer.answerImageBank?.fileDownloadUri.slice(1);
        return {
            id: answer.id,
            type,
            text: answer.text,
            objectUrl: url + imageUrl,
            isAvailable: true,
        };
    });

    boxes = boxes?.map((box) => {
        let answerIndex = tempAnswers.findIndex((a) => a.id === box.id);
        tempAnswers[answerIndex].isAvailable = false;
        let answer = tempAnswers[answerIndex];
        return {...box, type: answer.type, text: answer.text, objectUrl: answer.objectUrl};
    });

    return {answers: tempAnswers, boxes, type};
};

const getBoxes = async (constructorType, testId) => {
    let requestResult;
    let api = constructorType === CLIPPING_TEST ? ClippingAPI : ClippingSecondaryAPI;

    await api
        .getCorrectAnswers(testId)
        .then((response) => {
            requestResult = response.data;
        })
        .catch(() => console.log("correct answers error"));

    if (!requestResult) {
        return null;
    }

    let tempResult = requestResult?.map((box) => {
        let suffix =
            constructorType === CLIPPING_TEST
                ? "clippingEngineAnswerVariant"
                : "clippingSecondaryEngineAnswerVariant";
        let boxType = box[suffix];
        return {
            ...box,
            left: box.x,
            top: box.y,
            id: boxType.id,
            center: box.center ? box.center : box.x,
            isAvailable: true,
        };
    });

    return tempResult;
};

const getAnswerType = (answer) => {
    if (answer) {
        if (
            answer.objectType === IMAGE_TYPE_CLIPPING_BANK ||
            answer.objectType === IMAGE_TYPE_CLIPPING_UPLOAD ||
            answer.objectType === IMAGE_TYPE_SECONDARY_CLIPPING_BANK ||
            answer.objectType === IMAGE_TYPE_SECONDARY_CLIPPING_UPLOAD
        ) {
            return IMAGE_TYPE;
        } else {
            return TEXT_TYPE;
        }
    }
};
