import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";

const SchoolClassesSelect = ({classes, handleSelect, selectedValue}) => {
    return (
        <Select
            styles={{
                menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                }),
            }}
            options={classes}
            placeholder="Выберите класс"
            maxMenuHeight={200}
            value={selectedValue}
            onChange={(event) => handleSelect(event.value)}
        />
    );
};

SchoolClassesSelect.propTypes = {
    classes: PropTypes.any,
    handleSelect: PropTypes.func,
    selectedValue: PropTypes.object,
};

export default SchoolClassesSelect;
