import {url} from "config";
import {DEFAULT_FONT_SIZE} from "config/project";
import {Text} from "entries/constructors/classic-test/views/components/text-answer";
import styles from "entries/constructors/sequences/sequence-test/views/containers/sequence-test/sequence-test.module.sass";
import {MDBAlert} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {DraggableItem, DraggableList} from "views/components/DraggableList";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {Question} from "views/components/question";

export const TextSequencePlayer = ({question, answers, properties, setAnswers}) => {
    // данные для вопроса
    const [questionText, setQuestionText] = useState("");
    const [questionNarrator, setQuestionNarrator] = useState({});
    const [questionAudio, setQuestionAudio] = useState(null);

    // параметры
    const [questionSizeText, setQuestionSizeText] = useState(DEFAULT_FONT_SIZE);
    const [questionImage, setQuestionImage] = useState({});
    const [answerSizeText, setAnswerSizeText] = useState(DEFAULT_FONT_SIZE);
    const [background, setBackground] = useState("");

    useEffect(() => {
        if (question) {
            // вопрос
            if (question.questionText) {
                setQuestionText(question.questionText);
            }

            // картинка к вопросу
            if (question.imageQuestion) {
                setQuestionImage({
                    id: question.imageQuestion.id,
                    url: question.imageQuestion.fileDownloadUri.slice(1),
                });
            }

            if (question.audio) {
                setQuestionAudio(question.audio);
            }
        }
    }, [question, questionSizeText]);

    useEffect(() => {
        if (properties) {
            // фон
            if (properties.backgroundImage) {
                setBackground(properties.backgroundImage.fileDownloadUri.slice(1));
            } else {
                setBackground("data/image-bank/ae86cc0f-5bc8-457f-98bb-454a0ea993a6.svg");
            }

            // персонаж
            if (properties.narrator) {
                let narratorUrl = `${url}${properties.narrator.image.fileDownloadUri.slice(1)}`;
                setQuestionNarrator({url: narratorUrl});
            }

            // размер шрифта вопроса
            if (properties.sizeTitle) {
                setQuestionSizeText(properties.sizeTitle);
            }

            // размер шрифта ответов
            if (properties.sizeText) {
                setAnswerSizeText(properties.sizeText);
            }
        }
    }, [properties]);

    return (
        <div className="h-100">
            {question && answers && properties && (
                <TestContent background={background} withEditBorder={false}>
                    <TestConstructor>
                        <div className="animate__animated animate__fadeIn">
                            <Question
                                testHasNarrator
                                isEditMode={false}
                                text={questionText}
                                narrator={questionNarrator}
                                textTitleSize={questionSizeText}
                                image={questionImage}
                                audio={questionAudio}
                            />
                        </div>
                        <div className="mt-2 animate__animated animate__fadeIn">
                            <MDBAlert color="info" className="mt-3 text-center">
                                Перетаскивай варианты ответов
                            </MDBAlert>

                            {answers && answers.length > 0 && (
                                <DraggableList
                                    onSort={setAnswers}
                                    items={answers}
                                    className={styles.answerContainer}
                                    pressDelay={0}
                                >
                                    {answers.map((answer, index) => (
                                        <DraggableItem
                                            key={`item-${answer.id}`}
                                            id={`item-${answer.id}`}
                                            index={index}
                                            style={{zIndex: 999}}
                                        >
                                            <div className={styles.textAnswerContainer}>
                                                <Text
                                                    style={{fontSize: answerSizeText}}
                                                    text={answer.text}
                                                    withBorder={false}
                                                />
                                            </div>
                                        </DraggableItem>
                                    ))}
                                </DraggableList>
                            )}
                        </div>
                    </TestConstructor>
                </TestContent>
            )}
        </div>
    );
};

TextSequencePlayer.propTypes = {
    answers: PropTypes.array,
    properties: PropTypes.object,
    question: PropTypes.object,
    setAnswers: PropTypes.func,
    onSelectCorrectAnswer: PropTypes.func,
};
