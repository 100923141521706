import React, {useEffect, useState} from "react";
import {MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBModalHeader, MDBRow} from "mdbreact";
import styles from "./styles.module.sass";
// @ts-ignore
import Select from "react-select";
import {
    DESCRIPTION_STYLE_OPTIONS,
    EStylesDescription,
    IPublicationImagePayload,
} from "../new-article-image-modal/new-article-image-modal";
import {IPublicationBlockDto} from "../../../../interfaces/IPublicationBlockDto";
import {ISelect} from "../../../../interfaces/ISelect";

interface IProps {
    isOpen: boolean;
    toggle: () => void;
    handleSave: (content: IPublicationGalleryPayload) => void;
    blockForEdit: IPublicationBlockDto | null;
}

export interface IPublicationGalleryPayload {
    blockStyle: number;
    descriptionStyle: number;
}

export enum EGalleryStyles {
    SLIDER = 1,
    TEST = 2,
}

export const GALLERY_STYLE_OPTIONS = [
    {value: EGalleryStyles.SLIDER, label: "Слайдер"},
    {value: EGalleryStyles.TEST, label: "ТЕСТ"},
];

const NewArticleGalleryModal = ({isOpen, toggle, handleSave, blockForEdit}: IProps) => {
    const [payload, setPayload] = useState<IPublicationGalleryPayload>({
        blockStyle: GALLERY_STYLE_OPTIONS[1].value,
        descriptionStyle: EStylesDescription.CENTER,
    });

    useEffect(() => {
        if (blockForEdit) {
            if (typeof blockForEdit.blockSettings === "string") {
                let settings: IPublicationImagePayload = JSON.parse(
                    atob(blockForEdit.blockSettings),
                );

                setPayload({...settings});
            }
        }
    }, [blockForEdit]);

    return (
        <MDBModal
            isOpen={isOpen}
            toggle={toggle}
            size="lg"
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll
        >
            <MDBModalHeader toggle={toggle} className="modal-header">
                <p>Нстройки блока Фото-галерея</p>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow className={styles.row}>
                    <MDBCol md="3">
                        <p>Стиль блока</p>
                    </MDBCol>
                    <MDBCol md="9">
                        <Select
                            options={GALLERY_STYLE_OPTIONS}
                            value={GALLERY_STYLE_OPTIONS[payload.blockStyle - 1]}
                            onChange={(v: ISelect) => setPayload({...payload, blockStyle: v.value})}
                            placeholder="Выберите стиль"
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow className={styles.row}>
                    <MDBCol md="3">
                        <p>Стиль подписи</p>
                    </MDBCol>
                    <MDBCol md="9">
                        <Select
                            options={DESCRIPTION_STYLE_OPTIONS}
                            value={DESCRIPTION_STYLE_OPTIONS[payload.descriptionStyle - 1]}
                            onChange={(v: ISelect) =>
                                setPayload({...payload, descriptionStyle: v.value})
                            }
                            placeholder="Выберите стиль"
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow className={styles.row}>
                    <MDBCol md="3" className="mt-3">
                        <MDBBtn color="success" onClick={() => handleSave(payload)}>
                            Сохранить
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>
        </MDBModal>
    );
};

export default NewArticleGalleryModal;
