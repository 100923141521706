import {
    BASKETS_TEST,
    CLASSIC_TEST,
    CLIPPING_SECONDARY_TEST,
    CLIPPING_TEST,
    DICTATION_TEST,
    OPEN_ANSWER_TEST,
    PAIRS_TEST,
    SEQUENCES_IMAGE_TEST,
    SEQUENCES_TEXT_TEST,
    WORD_MANY_SKIP_TEST,
    WORD_SEQUENCE_SKIP_TEST,
    WORD_SKIP_TEST,
} from "config/constructor-names";
import {ADMIN_ROLE} from "config/roles";
import {QuestionAPI} from "core/api/question";
import {auth} from "core/utils/auth";
import BasketsPlayerContainer from "entries/constructors/baskets-game/views/baskets-player/baskets-player-container";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import {ClassicTestPlayerContainer} from "entries/player/views/task-players/classic-test-player/classic-test-player-container";
import {DictationPlayerContainer} from "entries/player/views/task-players/dictation-player/dictation-player-container";
import {ImageSequencePlayerContainer} from "entries/player/views/task-players/image-sequence-player/image-sequence-player-container";
import {OpenAnswerPlayerContainer} from "entries/player/views/task-players/open-answer-player/open-answer-player-container";
import {PairsPlayerContainer} from "entries/player/views/task-players/pairs-player/pairs-player-container";
import {TextSequencePlayerContainer} from "entries/player/views/task-players/text-sequence-player/text-sequence-player-container";
import {WordSkipAdditionalPlayerContainer} from "entries/player/views/task-players/word-skip-additional-player/word-skip-additional-player.container";
import {WordSkipPlayerContainer} from "entries/player/views/task-players/word-skip-player/word-skip-container-player";
import {TeacherAPI} from "entries/teacher/core/api/TeacherApi";
import ClippingPlayerContainer from "feauters/clipping-pictures-engine/clipping-player/clipping-player-container";
import React from "react";
import {hasAccess} from "utils/private-route";

export const getPlayer = (
    constructorName,
    handleSelectAnswer,
    id,
    reset,
    playerMode,
    getInfoAboutSet,
    answerIsLoading,
    setAnswerIsLoading,
) => {
    const {keycloak} = auth;
    let QUESTION_API = QuestionAPI.getAllForAcademy;
    if (playerMode !== PlayerV2Modes.TOURNAMENT && hasAccess(keycloak, [ADMIN_ROLE])) {
        QUESTION_API = QuestionAPI.getAllForAdmin;
        getInfoAboutSet = () => {
            return void 0;
        };
    } else {
        switch (playerMode) {
            case PlayerV2Modes.TEACHER:
                QUESTION_API = TeacherAPI.getQuestionAllForTeacher;
                break;
            case PlayerV2Modes.ACADEMY:
                QUESTION_API = QuestionAPI.getAllForAcademy;
                break;
            case PlayerV2Modes.TOURNAMENT:
                QUESTION_API = QuestionAPI.getAllForTournament;
                break;
            case PlayerV2Modes.PREVIEW:
                QUESTION_API = QuestionAPI.getAllForAcademy;
                break;
            case PlayerV2Modes.HOMEWORK:
            case PlayerV2Modes.ACADEMY_HOMEWORK:
                QUESTION_API = QuestionAPI.getAllForHomeWork;
                break;
            case PlayerV2Modes.ARTICLE:
                QUESTION_API = QuestionAPI.getAllForArticle;
                break;
        }
    }

    let taskId = parseInt(id);

    switch (constructorName) {
        case CLASSIC_TEST:
            return (
                <ClassicTestPlayerContainer
                    taskId={taskId}
                    onSelectCorrectAnswer={handleSelectAnswer}
                    questionApi={QUESTION_API}
                    reset={reset}
                    getInfoAboutSet={getInfoAboutSet}
                    playerMode={playerMode}
                    answerIsLoading={answerIsLoading}
                    setAnswerIsLoading={setAnswerIsLoading}
                />
            );
        case DICTATION_TEST:
            return (
                <DictationPlayerContainer
                    taskId={taskId}
                    onSelectCorrectAnswer={handleSelectAnswer}
                    questionApi={QUESTION_API}
                    reset={reset}
                    getInfoAboutSet={getInfoAboutSet}
                    playerMode={playerMode}
                    answerIsLoading={answerIsLoading}
                    setAnswerIsLoading={setAnswerIsLoading}
                />
            );
        case SEQUENCES_TEXT_TEST:
            return (
                <TextSequencePlayerContainer
                    taskId={taskId}
                    onSelectCorrectAnswer={handleSelectAnswer}
                    questionApi={QUESTION_API}
                    getInfoAboutSet={getInfoAboutSet}
                    playerMode={playerMode}
                    answerIsLoading={answerIsLoading}
                    setAnswerIsLoading={setAnswerIsLoading}
                />
            );
        case SEQUENCES_IMAGE_TEST:
            return (
                <ImageSequencePlayerContainer
                    taskId={taskId}
                    onSelectCorrectAnswer={handleSelectAnswer}
                    questionApi={QUESTION_API}
                    getInfoAboutSet={getInfoAboutSet}
                    playerMode={playerMode}
                    answerIsLoading={answerIsLoading}
                    setAnswerIsLoading={setAnswerIsLoading}
                />
            );
        case PAIRS_TEST:
            return (
                <PairsPlayerContainer
                    taskId={taskId}
                    onSelectCorrectAnswer={handleSelectAnswer}
                    questionApi={QUESTION_API}
                    getInfoAboutSet={getInfoAboutSet}
                    playerMode={playerMode}
                    answerIsLoading={answerIsLoading}
                    setAnswerIsLoading={setAnswerIsLoading}
                />
            );
        case WORD_SKIP_TEST:
            return (
                <WordSkipPlayerContainer
                    taskId={taskId}
                    onSelectCorrectAnswer={handleSelectAnswer}
                    questionApi={QUESTION_API}
                    getInfoAboutSet={getInfoAboutSet}
                    playerMode={playerMode}
                    answerIsLoading={answerIsLoading}
                    setAnswerIsLoading={setAnswerIsLoading}
                />
            );
        case WORD_SEQUENCE_SKIP_TEST:
            return (
                <WordSkipPlayerContainer
                    taskId={taskId}
                    isSentences
                    onSelectCorrectAnswer={handleSelectAnswer}
                    questionApi={QUESTION_API}
                    getInfoAboutSet={getInfoAboutSet}
                    playerMode={playerMode}
                    answerIsLoading={answerIsLoading}
                    setAnswerIsLoading={setAnswerIsLoading}
                />
            );
        case WORD_MANY_SKIP_TEST:
            return (
                <WordSkipAdditionalPlayerContainer
                    taskId={taskId}
                    onSelectCorrectAnswer={handleSelectAnswer}
                    questionApi={QUESTION_API}
                    getInfoAboutSet={getInfoAboutSet}
                    playerMode={playerMode}
                    answerIsLoading={answerIsLoading}
                    setAnswerIsLoading={setAnswerIsLoading}
                />
            );
        case OPEN_ANSWER_TEST:
            return (
                <OpenAnswerPlayerContainer
                    taskId={taskId}
                    onSelectCorrectAnswer={handleSelectAnswer}
                    questionApi={QUESTION_API}
                    getInfoAboutSet={getInfoAboutSet}
                    playerMode={playerMode}
                    answerIsLoading={answerIsLoading}
                    setAnswerIsLoading={setAnswerIsLoading}
                />
            );
        case BASKETS_TEST:
            return (
                <BasketsPlayerContainer
                    taskId={taskId}
                    onSelectCorrectAnswer={handleSelectAnswer}
                    questionApi={QUESTION_API}
                    getInfoAboutSet={getInfoAboutSet}
                    playerMode={playerMode}
                    answerIsLoading={answerIsLoading}
                    setAnswerIsLoading={setAnswerIsLoading}
                />
            );
        case CLIPPING_TEST:
            return (
                <ClippingPlayerContainer
                    taskId={taskId}
                    onSelectCorrectAnswer={handleSelectAnswer}
                    questionApi={QUESTION_API}
                    getInfoAboutSet={getInfoAboutSet}
                    playerMode={playerMode}
                    answerIsLoading={answerIsLoading}
                    setAnswerIsLoading={setAnswerIsLoading}
                />
            );
        case CLIPPING_SECONDARY_TEST:
            return (
                <ClippingPlayerContainer
                    taskId={taskId}
                    onSelectCorrectAnswer={handleSelectAnswer}
                    questionApi={QUESTION_API}
                    getInfoAboutSet={getInfoAboutSet}
                    playerMode={playerMode}
                    isSecondary={true}
                    answerIsLoading={answerIsLoading}
                    setAnswerIsLoading={setAnswerIsLoading}
                />
            );
    }
};
