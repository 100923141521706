import {url} from "config";
import {convertTextWithSymbols} from "core/utils/converter";
import {
    IMAGE_TYPE_CLIPPING_BANK,
    IMAGE_TYPE_CLIPPING_UPLOAD,
    IMAGE_TYPE_SECONDARY_CLIPPING_BANK,
    IMAGE_TYPE_SECONDARY_CLIPPING_UPLOAD,
} from "entries/constructors/clipping/core/types";
import Hints from "entries/hints/hints";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import ClippingPlayer from "feauters/clipping-pictures-engine/clipping-player/clipping-player";
import DraggableBox from "feauters/clipping-pictures-engine/draggable-box/draggable-box";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {DndProvider} from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {IMAGE_TYPE, TEXT_TYPE} from "utils/answers-config";
import {v4 as uuidv4} from "uuid";

const ClippingPlayerContainer = ({
    taskId,
    onSelectCorrectAnswer,
    questionApi,
    getInfoAboutSet,
    playerMode,
    isSecondary,
    answerIsLoading,
    setAnswerIsLoading,
}) => {
    let {id} = useParams();
    const [properties, setProperties] = useState(null);
    const [question, setQuestion] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [clips, setClips] = useState([]);
    const [isManyToMany, setIsManyToMany] = useState(false);

    useEffect(() => {
        setAnswers([]);
        setQuestion({});
        setProperties({});
        setIsManyToMany(false);
        getData();
    }, [taskId]);

    useEffect(() => {
        if (!answers) {
            return;
        }

        let updatedAnswers;

        if (isManyToMany) {
            updatedAnswers = {};
            clips.forEach((clip, index) => {
                updatedAnswers[index] = clip.content?.props?.id;
            });
            onSelectCorrectAnswer(updatedAnswers);
            return;
        }

        if (isSecondary) {
            updatedAnswers = clips.filter((fc) => fc.answerId).map((clip) => ({id: clip.answerId}));
        } else {
            updatedAnswers = {};
            answers.forEach((answer) => {
                if (answer.chainedClip || answer.chainedClip === 0) {
                    updatedAnswers[answer.chainedClip] = answer.id;
                }
            });
        }

        onSelectCorrectAnswer(updatedAnswers);
    }, [answers, clips]);

    const getData = async () => {
        setAnswerIsLoading(true);
        let data = undefined;
        await questionApi(taskId, id)
            .then((response) => {
                if (playerMode === PlayerV2Modes.TOURNAMENT) {
                    data = response.data[0].question;
                } else {
                    data = response.data;
                    getInfoAboutSet();
                }
            })
            .catch((err) => console.log("load data error", err))
            .finally(() => setAnswerIsLoading(false));

        if (!data) {
            return;
        }

        let prefix = isSecondary ? "clippingSecondaryEngine" : "clippingEngine";

        let tempAnswers = data[`${prefix}AnswerVariants`].map((ta) => {
            let answer = {id: ta.id};
            if (
                ta.objectType === IMAGE_TYPE_CLIPPING_BANK ||
                ta.objectType === IMAGE_TYPE_CLIPPING_UPLOAD ||
                ta.objectType === IMAGE_TYPE_SECONDARY_CLIPPING_BANK ||
                ta.objectType === IMAGE_TYPE_SECONDARY_CLIPPING_UPLOAD
            ) {
                answer.type = IMAGE_TYPE;
            } else {
                answer.type = TEXT_TYPE;
            }

            if (
                ta.objectType === IMAGE_TYPE_CLIPPING_BANK ||
                ta.objectType === IMAGE_TYPE_SECONDARY_CLIPPING_BANK
            ) {
                answer.url = url + ta.answerImageBank?.fileDownloadUri?.slice(1);
            } else if (
                ta.objectType === IMAGE_TYPE_CLIPPING_UPLOAD ||
                ta.objectType === IMAGE_TYPE_SECONDARY_CLIPPING_UPLOAD
            ) {
                answer.url = url + ta.answerImageQuestion?.fileDownloadUri?.slice(1);
            } else {
                answer.text = convertTextWithSymbols(ta.text);
            }

            answer.isAvailable = true;

            return answer;
        });

        let tempClips = data[`${prefix}AnswerPositionDtos`].map((tc) => {
            return {
                id: tc.position,
                top: tc.y,
                left: tempAnswers[0]?.type === TEXT_TYPE ? (tc.center ? tc.center : tc.x) : tc.x,
                originalLeft:
                    tempAnswers[0]?.type === TEXT_TYPE ? (tc.center ? tc.center : tc.x) : tc.x,
                content: null,
            };
        });

        setQuestion(isSecondary ? data.clippingSecondaryEngine : data.clippingEngine);
        setClips(tempClips);
        setAnswers(tempAnswers);

        let settings = data.questionProperties?.settings;
        if (settings) {
            try {
                let decodedSettings = JSON.parse(atob(settings));
                if (decodedSettings.isManyToMany) {
                    setIsManyToMany(true);
                }
            } catch (e) {
                console.log(e);
            }
        }

        setProperties(data.questionProperties);
    };

    const handleDropToAnswers = (drop) => {
        if (drop.isFromAnswerBox) {
            return;
        }

        let tmpClips = clips.slice();

        if (isManyToMany) {
            let clipForDeleteIndex = tmpClips.findIndex(
                (tc) => tc.content?.props?.tempId === drop.tempId,
            );
            tmpClips[clipForDeleteIndex].content = null;

            setClips(tmpClips);
            return;
        }

        let answerIndex = answers.findIndex((answer) => answer.id === drop.id);
        let answer = answers[answerIndex];

        if (answerIndex !== -1) {
            // убираем контент из привязанного выреза

            let clipIndex = clips.findIndex((clip) => clip.id === answer.chainedClip);
            tmpClips[clipIndex] = {
                ...tmpClips[clipIndex],
                content: null,
                answerId: null,
                left: tmpClips[clipIndex].originalLeft,
            };

            // убираем привязку из ответа + делаем его доступным
            let tmpAnswers = answers.slice();
            tmpAnswers[answerIndex] = {
                ...tmpAnswers[answerIndex],
                chainedClip: null,
                isAvailable: true,
            };

            setAnswers(tmpAnswers);
            setClips(tmpClips);
        }
    };

    const handleDropToClip = (drop, _, targetId) => {
        // проверяем на наличие ответа
        let answerIndex = answers.findIndex((answer) => answer.id === drop.id);
        if (answerIndex === -1) {
            return;
        }

        // получаем вырез
        let targetIndex;
        targetIndex = clips.findIndex((clip) => clip.id === targetId);

        // если есть цель для перетаскивания
        if (targetIndex !== -1) {
            // если перетаскиваем из ответов
            let tmpClips = clips.slice();
            let tmpAnswers = answers.slice();

            let clip = tmpClips[targetIndex];
            let answer = answers[answerIndex];

            let isTextAnswer = answer.type === TEXT_TYPE;

            if (!answer.originalWidth) {
                answer.originalWidth = drop.width;
            }

            if (answer.originalWidth) {
                tmpClips[targetIndex].left = isTextAnswer
                    ? tmpClips[targetIndex].originalLeft - (answer.originalWidth - 6) / 2 + 25
                    : tmpClips[targetIndex].left;
            }

            // проверяем, есть ли в вырезе ответ

            if (!isManyToMany && clip.content) {
                console.log("container is not empty, swap");
                let chainedAnswerIndex = answers.findIndex(
                    (answer) => answer.chainedClip === targetId,
                );

                tmpClips[targetIndex].left = isTextAnswer
                    ? tmpClips[targetIndex].originalLeft - (answer.originalWidth - 6) / 2 + 25
                    : tmpClips[targetIndex].left;

                if (chainedAnswerIndex !== -1) {
                    tmpAnswers[chainedAnswerIndex] = {
                        ...tmpAnswers[chainedAnswerIndex],
                        chainedClip: null,
                        isAvailable: true,
                    };
                } else {
                    console.log("answer w/o chain, initial error");
                    return;
                }
            }

            // проверяем, перетаскиваем ли мы из выреза в другой вырез
            if (!drop.isFromAnswerBox) {
                if (isManyToMany) {
                    let oldClipIndex = tmpClips.findIndex(
                        (tc) => tc.content?.props?.tempId === drop.tempId,
                    );
                    if (tmpClips[oldClipIndex].content) {
                        tmpClips[oldClipIndex].content = null;
                    }
                } else {
                    let chainedClipIndex = tmpClips.findIndex((tc) => tc.id === answer.chainedClip);
                    if (chainedClipIndex !== -1) {
                        tmpClips[chainedClipIndex] = {
                            ...tmpClips[chainedClipIndex],
                            left: tmpClips[chainedClipIndex].originalLeft,
                            content: null,
                            answerId: null,
                        };
                    } else {
                        console.log("clip not chained, initial error");
                    }
                }
            }

            // привязываем ответ к вырезу
            tmpAnswers[answerIndex] = {
                ...tmpAnswers[answerIndex],
                isAvailable: isManyToMany ? true : false,
                chainedClip: targetId,
            };
            setAnswers(tmpAnswers);

            tmpClips[targetIndex] = {
                ...clip,
                answerId: answers[answerIndex].id,
                content: (
                    <DraggableBox id={answers[answerIndex].id} tempId={uuidv4()}>
                        {answer?.type === IMAGE_TYPE ? (
                            <img src={answer.url} className={properties.sizeImage} alt="answer" />
                        ) : (
                            <p>{answer.text}</p>
                        )}
                    </DraggableBox>
                ),
            };

            setClips(tmpClips);
        }
    };

    const fillCorrectAnswers = (answersForFill) => {
        let updatedClips = [];
        let updatedAnswers = answers.map((answer) => ({...answer, isAvailable: true}));

        answersForFill.forEach((answer) => {
            let answerTemp = answers.find((a) => {
                if (isSecondary) {
                    return a.id === answer.clippingSecondaryEngineAnswerVariant.id;
                } else {
                    return a.id === answer.clippingEngineAnswerVariant.id;
                }
            });

            let clip = clips.find(
                (c) =>
                    (c.originalLeft === answer.center && c.top === answer.y) ||
                    (c.left === answer.x && c.top === answer.y),
            );

            if (!clip || !answer) {
                toast.error("Ошибка загрузки подсказки");
                return;
            }

            let answerIdx = answers.findIndex((a) => a === answerTemp);

            if (answerIdx !== -1) {
                updatedAnswers[answerIdx] = {
                    ...updatedAnswers[answerIdx],
                    isAvailable: false,
                    chainedClip: clip.id,
                };
            }

            updatedClips.push({
                ...clip,
                answerId: answerTemp.id,
                content: (
                    <DraggableBox id={answerTemp.id} tempId={uuidv4()}>
                        {answerTemp?.type === IMAGE_TYPE ? (
                            <img
                                src={answerTemp.url}
                                className={properties.sizeImage}
                                alt="answer"
                            />
                        ) : (
                            <p>{answerTemp.text}</p>
                        )}
                    </DraggableBox>
                ),
            });
        });

        setClips(updatedClips);
        setAnswers(updatedAnswers);
    };

    return (
        <React.Fragment>
            {!answerIsLoading && (
                <React.Fragment>
                    {playerMode === PlayerV2Modes.ACADEMY && (
                        <Hints question={question} onBuyCorrectAnswer={fillCorrectAnswers} />
                    )}

                    {question && answers.length > 0 && (
                        <DndProvider options={HTML5toTouch}>
                            <ClippingPlayer
                                answers={answers}
                                clips={clips}
                                handleDropToAnswers={handleDropToAnswers}
                                handleDropToClip={handleDropToClip}
                                question={question}
                                properties={properties}
                            />
                        </DndProvider>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

ClippingPlayerContainer.propTypes = {
    answerIsLoading: PropTypes.bool,
    getInfoAboutSet: PropTypes.func,
    isSecondary: PropTypes.bool,
    isSentences: PropTypes.bool,
    playerMode: PropTypes.string,
    questionApi: PropTypes.func,
    setAnswerIsLoading: PropTypes.func,
    taskId: PropTypes.number,
    onSelectCorrectAnswer: PropTypes.func,
};

export default ClippingPlayerContainer;
