import React from "react";
import styles from "./styles.module.sass";
import {MDBBtn} from "mdbreact";
// @ts-ignore
import cn from "classnames";
import {useHistory} from "react-router-dom";
import {TOURNAMENT_ROUTES} from "../../../routes/main";
import {toast} from "react-toastify";
import {url} from "../../../config";

interface IProps {
    name: string;
    tournamentId: number;
    toggle: () => void;
}

const TournamentStartModal = ({name, tournamentId, toggle}: IProps) => {
    let history = useHistory();

    let clickTournamentStart = () => {
        if (!tournamentId) {
            toast.error("Ошибка запуска турнира");
            toggle();
            return;
        }

        history.push(TOURNAMENT_ROUTES.PLAY.replace(":id", tournamentId.toString()));
        toggle();
    };

    return (
        <div
            className={cn({
                [styles.containerWrapper]: true,
            })}
        >
            <div className={styles.content}>
                <p className={styles.title}>{`Начинается турнир ${name ? `«${name}»` : ""}`}</p>
                <img
                    src={url + "data/image-bank/e510e302-4ff5-4fb2-8728-3724909d2b6f.svg"}
                    alt="raven-start"
                />
            </div>

            <div className={styles.footer}>
                <MDBBtn color="success" onClick={clickTournamentStart}>
                    Перейти к турниру
                </MDBBtn>
            </div>
        </div>
    );
};

export default TournamentStartModal;
