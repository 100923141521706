import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import cn from "classnames";

import {InboxOutlined} from "@ant-design/icons";
import {Button, Form, Image, Input, InputNumber, Select, Upload} from "antd";
import {IHint} from "../../../interfaces/IHint";
import {HintsAPI} from "../../hints/hints-api";
import {toast} from "react-toastify";
import {useStores} from "../../../hooks/use-stores";
import {url} from "../../../config";

interface IProps {
    toggle: () => void;
    questionId: number;
    hintId?: number;
    onUpdate: () => void;
}

const {Option} = Select;

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 14},
};

const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};

const HintsAddModal = ({toggle, questionId, onUpdate, hintId}: IProps) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState("");
    const {appStore} = useStores();

    const getHintById = () => {
        if (!hintId) {
            toast.error("Произошла ошибка. Не найден ID подсказки");
            return;
        }

        setIsLoading(true);
        HintsAPI.getHintAdmin(hintId)
            .then((response) => {
                form.setFieldsValue(response.data);
                if (response.data.imageHint?.fileDownloadUri) {
                    setImage(url + response.data.imageHint.fileDownloadUri.slice(1));
                }
            })
            .catch(() => toast.error("Ошибка получения подсказки"))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (hintId) {
            getHintById();
        }
    }, [hintId]);

    const onFinish = (hint: IHint) => {
        setIsLoading(true);
        if (hintId) {
            HintsAPI.updateHintAdmin(hintId, hint)
                .then(() => {
                    toast.success("Обновлено");
                    onUpdate();
                    setIsLoading(false);
                    appStore.closeActiveModal();
                })
                .catch(() => {
                    setIsLoading(false);
                    toast.error("Ошибка обновления подсказки");
                });
        } else {
            HintsAPI.createHintAdmin(questionId, hint)
                .then(() => {
                    toast.success("Успешно");
                    onUpdate();
                    setIsLoading(false);
                    appStore.closeActiveModal();
                })
                .catch(() => {
                    setIsLoading(false);
                    toast.error("Ошибка создания подсказки");
                });
        }
    };

    const handleUploadImage = async (options: any) => {
        if (!hintId) {
            toast.info("Для добавления изображения необходимо сначало создать подсказку");
            return;
        }
        const {file} = options;
        setIsLoading(true);
        HintsAPI.addImageHintAdmin(hintId, file)
            .then((response) => {
                setImage(url + response.data.imageHint.fileDownloadUri.slice(1));
            })
            .catch(() => toast.error("Ошибка добавления изображения"))
            .finally(() => setIsLoading(false));
    };

    return (
        <div
            className={cn({
                [styles.containerWrapper]: true,
            })}
        >
            <div className={styles.content}>
                <Form
                    className="w-100"
                    form={form}
                    name="validate_other"
                    {...formItemLayout}
                    onFinish={onFinish}
                    disabled={isLoading}
                >
                    <Form.Item
                        name="hintType"
                        label="Тип"
                        hasFeedback
                        rules={[{required: true, message: "Заполните тип!"}]}
                    >
                        <Select placeholder="Выберите тип">
                            <Option value="HINT">Подсказка</Option>
                            <Option value="ANSWER">Правильный ответ</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Цена">
                        <Form.Item name="price" noStyle>
                            <InputNumber min={1} max={9999} />
                        </Form.Item>
                    </Form.Item>

                    <Form.Item label="Текст" hasFeedback help="Текст подсказки" name="hintText">
                        <Input.TextArea showCount />
                    </Form.Item>

                    {image && (
                        <Form.Item label="Изображение">
                            {image && <Image width={200} src={image} />}
                        </Form.Item>
                    )}

                    {hintId && (
                        <Form.Item label="Загрузка">
                            <Form.Item
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                                noStyle
                            >
                                <Upload.Dragger
                                    name="files"
                                    customRequest={handleUploadImage}
                                    accept="image/*"
                                    multiple={false}
                                    showUploadList={false}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        Нажмите или перетащите для загрузки
                                    </p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Form.Item>
                    )}

                    <Form.Item wrapperCol={{span: 12, offset: 6}}>
                        <Button type="primary" htmlType="submit" disabled={isLoading}>
                            Сохранить
                        </Button>

                        <Button
                            type="dashed"
                            onClick={toggle}
                            disabled={isLoading}
                            className="ml-2"
                        >
                            Закрыть
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default HintsAddModal;
