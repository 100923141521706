import {url} from "config";
import AudioPlayer from "entries/audio-player/audio-player";
import {FormulaQuestion} from "entries/constructors/classic-test/views/components/formula-question";
import {ImageForQuestion} from "entries/constructors/classic-test/views/components/image-question";
import PropTypes from "prop-types";
import React from "react";
import {SpinnerPage} from "views/components/spinner";
import {TextUpload} from "views/components/text-upload";
import styles from "./question.module.sass";

export const Question = (props) => {
    const {
        text,
        image,
        formula,
        audio,
        narrator,
        isEditMode,
        deleteText,
        deleteImage,
        deleteFormula,
        deleteAudio,
        testHasNarrator,
        isTextLoading,
        isImageLoading,
        isFormulaLoading,
        isAudioLoading,
        textTitleSize,
        editImageHandler,
        editTextHandler,
        narratorSelect,
        toggleTextModal,
    } = props;

    return (
        <div className={styles.wrapper}>
            {isTextLoading ? (
                <SpinnerPage primary />
            ) : (
                <TextUpload
                    text={text}
                    editText={editTextHandler}
                    deleteText={deleteText}
                    narrator={narrator}
                    isEditMode={isEditMode}
                    testHasNarrator={testHasNarrator}
                    textTitleSize={textTitleSize}
                    narratorSelect={narratorSelect}
                    toggleTextModal={toggleTextModal}
                />
            )}

            {isImageLoading ? (
                <SpinnerPage primary />
            ) : (
                <ImageForQuestion
                    image={image}
                    editImage={editImageHandler}
                    deleteImage={deleteImage}
                    isEditMode={isEditMode}
                />
            )}

            {isFormulaLoading ? (
                <SpinnerPage primary />
            ) : (
                <FormulaQuestion
                    formula={formula}
                    deleteFormula={deleteFormula}
                    isEditMode={isEditMode}
                />
            )}

            {isAudioLoading ? (
                <SpinnerPage primary />
            ) : (
                <React.Fragment>
                    {audio && Object.keys(audio).length > 0 && (
                        <AudioPlayer
                            src={
                                audio.fileDownloadUri && `${url}${audio.fileDownloadUri?.slice(1)}`
                            }
                            deleteAction={deleteAudio}
                            withEdit={isEditMode}
                            id={audio.id}
                            isQuestion
                            isConstructor={isEditMode}
                        />
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

Question.propTypes = {
    audio: PropTypes.object,
    deleteAudio: PropTypes.func,
    deleteFormula: PropTypes.func,
    deleteImage: PropTypes.func,
    deleteText: PropTypes.func,
    editImageHandler: PropTypes.func,
    editTextHandler: PropTypes.func,
    formula: PropTypes.any,
    image: PropTypes.any,
    isAudioLoading: PropTypes.bool,
    isEditMode: PropTypes.bool,
    isFormulaLoading: PropTypes.bool,
    isImageLoading: PropTypes.bool,
    isTextLoading: PropTypes.bool,
    narrator: PropTypes.any,
    narratorSelect: PropTypes.func,
    testHasNarrator: PropTypes.bool,
    text: PropTypes.string,
    textTitleSize: PropTypes.number,
    toggleTextModal: PropTypes.func,
};
