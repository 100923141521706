import React, {useMemo, useState} from "react";
import styles from "./styles.module.sass";
import {GAME_TUTORIAL_SCRIPT} from "./constant";
import {MDBBtn} from "mdbreact";
import {useStores} from "../../../hooks/use-stores";
import {useHistory} from "react-router-dom";
import {PAGES_ROUTES} from "../../../views/routes/routes";
import {STUDENT_ROUTES} from "../../../routes/main";

interface IProps {
    updateGameTutorialSetting: () => void;
}

const GameTutorialModal = ({updateGameTutorialSetting}: IProps) => {
    const [step, setStep] = useState(0);
    const {userStore} = useStores();
    const history = useHistory();

    const slide = useMemo(() => {
        return GAME_TUTORIAL_SCRIPT[step];
    }, [step]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.titleWrapper}>
                    <p>{slide.title}</p>
                </div>
                <div className={styles.slide}>
                    <div className={styles.slideImage}>
                        <img
                            src={
                                step === 1
                                    ? userStore.user?.gender === "MAN"
                                        ? slide.male
                                        : slide.female
                                    : slide.image
                            }
                            alt="game-tutorial-image"
                        />
                    </div>

                    <div className={styles.slideDescription}>
                        <p className={styles.description}>{slide.description}</p>
                    </div>
                </div>

                <div className={styles.buttons}>
                    {step === 5 ? (
                        <React.Fragment>
                            <MDBBtn
                                color="success"
                                onClick={() => {
                                    history.push(STUDENT_ROUTES.ROOT);
                                    updateGameTutorialSetting();
                                }}
                            >
                                Скорее в игру!
                            </MDBBtn>

                            <MDBBtn
                                color="warning"
                                onClick={() => {
                                    history.push(PAGES_ROUTES.PREMIUM.ROOT);
                                    updateGameTutorialSetting();
                                }}
                            >
                                Подписка
                            </MDBBtn>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {step < GAME_TUTORIAL_SCRIPT.length - 1 && (
                                <MDBBtn color="success" onClick={() => setStep(step + 1)}>
                                    Дальше
                                </MDBBtn>
                            )}

                            <MDBBtn color="grey" onClick={updateGameTutorialSetting}>
                                Закрыть
                            </MDBBtn>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GameTutorialModal;
