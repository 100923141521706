import Hints from "entries/hints/hints";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import {WordSkipAdditionalPlayer} from "entries/player/views/task-players/word-skip-additional-player/word-skip-additional-player";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export const WordSkipAdditionalPlayerContainer = ({
    taskId,
    onSelectCorrectAnswer,
    questionApi,
    getInfoAboutSet,
    playerMode,
    answerIsLoading,
    setAnswerIsLoading,
}) => {
    const [question, setQuestion] = useState(null);
    const [answers, setAnswers] = useState(null);
    const [properties, setProperties] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState([]);

    let {id} = useParams();

    useEffect(() => {
        getData();
    }, [taskId]);

    const getData = async () => {
        setAnswerIsLoading(true);
        let data = undefined;
        await questionApi(taskId, id)
            .then((response) => {
                if (playerMode === PlayerV2Modes.TOURNAMENT) {
                    data = response.data[0].question;
                } else {
                    data = response.data;
                    getInfoAboutSet();
                }
            })
            .catch(() => console.log("load data error"))
            .finally(() => setAnswerIsLoading(false));

        if (!data) {
            return;
        }

        setQuestion(data.manyWordSkip);
        setAnswers(data.manyWordSkipAnswerVariants);
        setProperties(data.questionProperties);
    };

    const selectWordHandle = (selectedAnswer) => {
        let selectedAnswersClone = selectedAnswers;
        let answer = selectedAnswersClone.find(
            (answer) => answer.position === selectedAnswer.position,
        );
        if (answer) {
            selectedAnswersClone = selectedAnswersClone.map((item) =>
                item.position === selectedAnswer.position && item.text !== selectedAnswers.text
                    ? selectedAnswer
                    : item,
            );
            setSelectedAnswers(selectedAnswersClone);
        } else {
            setSelectedAnswers([...selectedAnswers, selectedAnswer]);
        }
    };

    useEffect(() => {
        onSelectCorrectAnswer(selectedAnswers);
    }, [selectedAnswers]);

    useEffect(() => {
        setAnswers(null);
        setQuestion(null);
        setProperties(null);
        setSelectedAnswers([]);
    }, [taskId]);

    const fillCorrectAnswers = (answersForFill) => {
        setSelectedAnswers(answersForFill.map((answer) => answer.correctAnswer));
    };

    return (
        <React.Fragment>
            {!answerIsLoading && (
                <React.Fragment>
                    {playerMode === PlayerV2Modes.ACADEMY && (
                        <Hints question={question} onBuyCorrectAnswer={fillCorrectAnswers} />
                    )}

                    {question && answers && properties && (
                        <WordSkipAdditionalPlayer
                            question={question}
                            answers={answers}
                            properties={properties}
                            selectWordHandle={selectWordHandle}
                            selectedAnswers={selectedAnswers}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

WordSkipAdditionalPlayerContainer.propTypes = {
    getInfoAboutSet: PropTypes.func,
    playerMode: PropTypes.string,
    questionApi: PropTypes.func,
    taskId: PropTypes.number,
    onSelectCorrectAnswer: PropTypes.func,
    answerIsLoading: PropTypes.bool,
    setAnswerIsLoading: PropTypes.func,
};
