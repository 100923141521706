import {ITEMS_TYPE, SIZE} from "./config";

export function getStageItemsCount(itemsType, stageImagesIds = [], stageTextIds = []) {
    return (itemsType === ITEMS_TYPE.IMAGE ? stageImagesIds : stageTextIds).length;
}

export function getMaxStageItemsCount(itemsType, stateSize) {
    const config = SIZE[stateSize];

    if (itemsType === ITEMS_TYPE.IMAGE) {
        return config.maxStageImagesCount;
    }

    return config.maxStageTextCount;
}

export function removeStateItem(stateItemsById, stateItemsIds, id) {
    delete stateItemsById[id];
    stateItemsIds.splice(stateItemsIds.indexOf(id), 1);

    return {
        ids: stateItemsIds,
        byId: stateItemsById,
    };
}

export function getStageImageSizes(propsWidth, propsHeight, config) {
    const zoomLevel = config.zoomLevel;
    const imageWidth = propsWidth || config.minImageWidth;
    const width = Math.floor((imageWidth > 100 ? 100 : imageWidth) * zoomLevel);
    const imageHeight = propsHeight || config.minImageHeight;
    const scale = imageWidth / width;
    const height = Math.floor(imageHeight / scale);

    return {width, height};
}

export function getId() {
    return Math.random().toString(36).substring(2);
}
