import {url} from "config";
import {OPEN_ANSWER_TEST} from "config/constructor-names";
import {NARRATORS} from "entries/constructors/classic-test/views/components/properties-buttons";
import {CreateQuestion} from "entries/constructors/classic-test/views/containers/create-question";
import {TestParameters} from "entries/constructors/classic-test/views/containers/test-parameters";
import {useConstructor} from "entries/constructors/hooks/useConstructor";
import {OpenAnswerAPI} from "entries/constructors/open-answer/core/api/open-answer";
import {BoxWithSymbols} from "entries/constructors/open-answer/views/box-with-symbols/box-with-symbols";
import {ModalOpenAnswerContainer} from "entries/constructors/open-answer/views/modal-open-answer/modal-open-answer-container";
import {MDBAlert, MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {AddContentBox} from "views/components/add-content-box/add-content-box";
import {ConstructorNavbar} from "views/components/constructor-navbar";
import {Col} from "views/components/layout/col";
import {SaveContent} from "views/components/layout/save-content/save-content";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {TestOptions} from "views/components/layout/test-options";
import {SpinnerPage} from "views/components/spinner";
import {PropertyColumn} from "views/containers/property-column";
import TestSave from "views/containers/test-save/test-save";
import {useWizard} from "views2/conatiners/CreateTestWizard";

export const OpenAnswerConstructor = ({testData, isEditMode = false}) => {
    const loadAnswers = (answers) => {
        console.log(answers);
    };

    const {
        STEP,
        stepsOrder,
        currentStep,
        toNext,
        to,
        enabledSteps,
        enableStep,
        disableStep,
        enableSteps,
    } = useWizard();

    const {
        testId,
        questionText,
        setQuestionText,
        questionImage,
        setQuestionImage,
        background,
        setBackground,
        narrator,
        setNarrator,
        textTitleSize,
        setTextTitleSize,
        textAnswerSize,
        currentPropertyTab,
        setCurrentPropertyTab,
        tabNames,
        questionType,
        questionAudio,
        setQuestionAudio,
    } = useConstructor({
        isEditMode,
        constructorName: OPEN_ANSWER_TEST,
        testData,
        loadAnswersCallback: loadAnswers,
    });

    const [modalOpenAnswerIsOpen, setModalOpenAnswerIsOpen] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [isCreateNew, setIsCreateNew] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [modalAnswers, setModalAnswers] = useState([]);
    const [variants, setVariants] = useState([]);
    const [saveAnswerIsLoading, setSaveAnswerIsLoading] = useState(false);

    useEffect(() => {
        if (isEditMode) {
            enableSteps([STEP.ANSWER, STEP.QUESTION, STEP.PARAMS]);
            return;
        }

        if (questionText && narrator) {
            enableStep(STEP.ANSWER);
        } else {
            disableStep(STEP.ANSWER);
        }
    }, [questionText, narrator, isEditMode]);

    const onSelectNarrator = (narrator) => {
        setNarrator({
            id: narrator.id,
            url: `${url}${narrator.url}`,
        });
    };

    return (
        <div>
            <ConstructorNavbar
                tabs={stepsOrder}
                currentTab={currentStep}
                availableTabs={enabledSteps}
                onSelect={(tab) => to(tab)}
                tabNames={tabNames}
            />

            <Col size={12}>
                {currentStep === STEP.QUESTION || currentStep === STEP.ANSWER ? (
                    <Col size={8}>
                        <TestContent background={background}>
                            <TestConstructor>
                                {testId && modalOpenAnswerIsOpen && (
                                    <ModalOpenAnswerContainer
                                        modalOpenAnswerIsOpen={modalOpenAnswerIsOpen}
                                        toggleModal={() =>
                                            setModalOpenAnswerIsOpen(!modalOpenAnswerIsOpen)
                                        }
                                        answers={answers}
                                        setAnswers={setAnswers}
                                        questionId={testId}
                                        selectedGroup={selectedGroup}
                                        setSelectedGroup={setSelectedGroup}
                                        modalAnswers={modalAnswers}
                                        setModalAnswers={setModalAnswers}
                                        variants={variants}
                                        setVariants={setVariants}
                                        setSaveAnswerIsLoading={setSaveAnswerIsLoading}
                                    />
                                )}

                                <CreateQuestion
                                    apiInstance={OpenAnswerAPI}
                                    questionId={testId}
                                    questionTitle={testData.title}
                                    questionImage={questionImage}
                                    questionText={questionText}
                                    updateQuestionImage={(image) => setQuestionImage(image)}
                                    updateQuestionText={(text) => setQuestionText(text)}
                                    selectPropertyNarrator={() => setCurrentPropertyTab(NARRATORS)}
                                    narrator={narrator}
                                    isEditMode={currentStep === STEP.QUESTION}
                                    testHasNarrator
                                    textTitleSize={textTitleSize}
                                    questionAudio={questionAudio}
                                    updateQuestionAudio={setQuestionAudio}
                                />

                                {saveAnswerIsLoading ? (
                                    <SpinnerPage info />
                                ) : (
                                    <React.Fragment>
                                        {answers.length > 0 && (
                                            <BoxWithSymbols
                                                symbols={answers}
                                                isDraggable={false}
                                                isShortContainer
                                                editAction={
                                                    currentStep === STEP.QUESTION
                                                        ? () => setModalOpenAnswerIsOpen(true)
                                                        : null
                                                }
                                            />
                                        )}

                                        {currentStep === STEP.QUESTION &&
                                            answers.length === 0 &&
                                            (!isEditMode || isCreateNew) && (
                                                <AddContentBox
                                                    onClick={() => setModalOpenAnswerIsOpen(true)}
                                                    text={"Добавить правильный ответ"}
                                                />
                                            )}

                                        {isEditMode && !isCreateNew && (
                                            <React.Fragment>
                                                {currentStep === STEP.QUESTION && (
                                                    <MDBAlert color="warning" className="mt-3">
                                                        <p>
                                                            Редактирование ответа для этого задания
                                                            невозможно. Если вы хотите внести
                                                            изменения, то необходимо заново выбрать
                                                            символы и указать правильный ответ.
                                                        </p>
                                                        <MDBBtn
                                                            color="info"
                                                            block
                                                            className="mt-2"
                                                            onClick={() => {
                                                                setAnswers([]);
                                                                setIsCreateNew(true);
                                                            }}
                                                        >
                                                            Создать заново
                                                        </MDBBtn>
                                                    </MDBAlert>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </TestConstructor>
                        </TestContent>
                    </Col>
                ) : (
                    <TestParameters
                        testId={testId}
                        apiInstance={OpenAnswerAPI}
                        mode={questionType}
                        isEditMode={isEditMode}
                        withEditMessage
                    />
                )}

                {(currentStep === STEP.QUESTION || currentStep === STEP.ANSWER) && (
                    <Col size={4}>
                        <TestOptions>
                            {currentStep === STEP.QUESTION && (
                                <PropertyColumn
                                    onSelectTab={(tab) => setCurrentPropertyTab(tab)}
                                    currentPropertyTab={currentPropertyTab}
                                    selectImage={(background) => setBackground(background)}
                                    selectNarrator={onSelectNarrator}
                                    textTitleSize={textTitleSize}
                                    setTextTitleSize={(size) =>
                                        setTextTitleSize(Number.parseInt(size))
                                    }
                                    isQuestionTextExist={!!questionText}
                                />
                            )}
                            {currentStep === STEP.ANSWER && (
                                <SaveContent>
                                    <h5>Проверьте правильный ответ</h5>
                                    <p>
                                        Убедитесь, что ответ набран верно, а затем нажмите
                                        сохранить.
                                    </p>

                                    <TestSave
                                        apiInstance={OpenAnswerAPI}
                                        questionId={testId}
                                        answersIdsForSave={answers.map(
                                            (answer) => answer.symbol.id,
                                        )}
                                        backgroundId={background.id}
                                        isTwoColumnMode={false}
                                        narratorId={narrator.id}
                                        sizeTitle={textTitleSize}
                                        sizeText={textAnswerSize}
                                        constructorName={OPEN_ANSWER_TEST}
                                        onSaveProperties={toNext}
                                        mode={questionType}
                                    />
                                </SaveContent>
                            )}
                        </TestOptions>
                    </Col>
                )}
            </Col>
        </div>
    );
};

OpenAnswerConstructor.propTypes = {
    isEditMode: PropTypes.bool,
    testData: PropTypes.object,
};
