import cn from "classnames";
import {url} from "config";
import {DEFAULT_FONT_SIZE} from "config/project";
import {convertTextWithSymbols} from "core/utils/converter";
import {SKIP_ID} from "entries/constructors/word-skip/views/text-with-skips/text-with-skips-container";
import {BoxWithAnswers} from "entries/player/views/task-players/word-skip-player/box-with-answers/box-with-answers";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {Question} from "views/components/question";

import styles from "./word-skip-player.module.sass";

export const WordSkipPlayer = ({
    question,
    answers,
    properties,
    dragEndHandle,
    deleteSelectedAnswer,
    selectedAnswers,
    onSelectCorrectAnswer,
}) => {
    const [text, setText] = useState("");
    // данные для вопроса
    const [questionText, setQuestionText] = useState("");
    const [questionNarrator, setQuestionNarrator] = useState({});

    // параметры
    const [questionSizeText, setQuestionSizeText] = useState(DEFAULT_FONT_SIZE);
    const [, setAnswerSizeText] = useState(DEFAULT_FONT_SIZE);
    const [background, setBackground] = useState("");

    useEffect(() => {}, [background]);

    useEffect(() => {
        if (properties) {
            // фон
            if (properties.backgroundImage) {
                setBackground(properties.backgroundImage.fileDownloadUri.slice(1));
            } else {
                setBackground("data/image-bank/ae86cc0f-5bc8-457f-98bb-454a0ea993a6.svg");
            }

            // персонаж
            if (properties.narrator) {
                let narratorUrl = `${url}${properties.narrator.image.fileDownloadUri.slice(1)}`;
                setQuestionNarrator({url: narratorUrl});
            }

            // размер шрифта вопроса
            if (properties.sizeTitle) {
                setQuestionSizeText(properties.sizeTitle);
            }

            // размер шрифта ответов
            if (properties.sizeText) {
                setAnswerSizeText(properties.sizeText);
            }
        }
    }, [properties]);

    useEffect(() => {
        if (question) {
            // вопрос
            if (question.questionText) {
                setQuestionText(question.questionText);
            }

            if (question.text) {
                setText(question.text);
            }
        }
    }, [question, questionSizeText]);

    useEffect(() => {
        let answersForSave = {};
        selectedAnswers.forEach((answer) => {
            answersForSave[answer.position] = answer.id;
        });
        onSelectCorrectAnswer(answersForSave);
    }, [selectedAnswers]);

    return (
        <div className="h-100">
            {question || answers || properties ? (
                <TestContent background={background} withEditBorder={false}>
                    <TestConstructor>
                        <div className="animate__animated animate__fadeIn">
                            <Question
                                testHasNarrator
                                isEditMode={false}
                                text={questionText}
                                narrator={questionNarrator}
                                textTitleSize={questionSizeText}
                                image={{}}
                            />
                        </div>
                        <div className="noselect animate__animated animate__fadeIn">
                            <DragDropContext onDragEnd={dragEndHandle}>
                                <div className={styles.skipsContainer}>
                                    <p className="text-muted" style={{fontSize: "1rem"}}>
                                        Перетаскивай сюда
                                    </p>
                                    <div className={styles.skips}>
                                        {text.split(" ").map((word, index) => {
                                            let answer = selectedAnswers.find(
                                                (item) => item.position == index,
                                            );
                                            if (answer) {
                                                return (
                                                    <div
                                                        className={cn({[styles.box]: true})}
                                                        key={index}
                                                    >
                                                        {convertTextWithSymbols(answer.words)}
                                                        <i
                                                            className={cn({
                                                                fas: true,
                                                                "fa-times": true,
                                                                [styles.times]: true,
                                                            })}
                                                            onClick={() =>
                                                                deleteSelectedAnswer(index)
                                                            }
                                                        />
                                                    </div>
                                                );
                                            } else {
                                                if (word === SKIP_ID) {
                                                    return (
                                                        <Droppable
                                                            droppableId={`${index}`}
                                                            key={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.droppableProps}
                                                                    className={cn({
                                                                        [styles.box]: true,
                                                                        [styles.dragging]:
                                                                            snapshot.isDraggingOver,
                                                                    })}
                                                                >
                                                                    <div
                                                                        className={styles.emptyBox}
                                                                    >
                                                                        {" "}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    );
                                                } else {
                                                    return <p key={index}>{word + " "}</p>;
                                                }
                                            }
                                        })}
                                    </div>
                                </div>

                                <Droppable droppableId="answers" direction="horizontal">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className={cn({
                                                [styles.boxWithWords]: true,
                                                [styles.dragging]: snapshot.isDraggingOver,
                                            })}
                                        >
                                            <p className="text-muted" style={{fontSize: "1rem"}}>
                                                Перетаскивай отсюда
                                            </p>
                                            <BoxWithAnswers answers={answers} />
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </TestConstructor>
                </TestContent>
            ) : (
                <p>Данных нет</p>
            )}
        </div>
    );
};

WordSkipPlayer.propTypes = {
    answers: PropTypes.array,
    deleteSelectedAnswer: PropTypes.func,
    dragEndHandle: PropTypes.func,
    properties: PropTypes.object,
    question: PropTypes.object,
    selectedAnswers: PropTypes.array,
    onSelectCorrectAnswer: PropTypes.func,
};
