import React from "react";
import {Circle, Group, Text, Transformer} from "react-konva";
import PropTypes from "prop-types";

import {STAGE_PADDING} from "./config";

export const StageText = (props) => {
    const [position, setPosition] = React.useState({
        x: props.x,
        y: props.y,
    });
    React.useEffect(() => props.onMove(position), [position]);

    const [stateRotation, setRotation] = React.useState(props.rotation || 0);

    const {stageWidth, stageHeight} = props;

    const shapeRef = React.useRef();
    const trRef = React.useRef();
    const textRef = React.useRef();

    const [size, setSize] = React.useState({
        width: undefined,
        height: undefined,
    });

    React.useEffect(() => {
        if (props.isSelected && props.enableResize) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [props.isSelected]);

    React.useEffect(() => {
        if (textRef.current) {
            const oneLineHeight = textRef.current.getTextHeight();
            const linesCount = textRef.current.textArr.length || 1;

            setSize({
                width: parseFloat(textRef.current.getTextWidth()),
                height: parseFloat(oneLineHeight * linesCount),
            });
        }
    }, [props.fontSize]);

    React.useEffect(() => {
        const oneLineHeight = textRef.current.getTextHeight();
        const linesCount = textRef.current.textArr.length || 1;

        setSize((state) => ({
            width: state.width,
            height: parseFloat(oneLineHeight * linesCount),
        }));
    }, [size.width]);

    React.useEffect(() => {
        props.onTransform({
            width: size.width,
            height: size.height,
            rotation: stateRotation,
        });
    }, [size.width, size.height, stateRotation]);

    return (
        <>
            <Group
                x={position.x}
                y={position.y}
                rotation={stateRotation}
                ref={shapeRef}
                width={size.width}
                draggable
                onTransformEnd={() => {
                    const node = shapeRef.current;
                    const rotation = node.rotation();

                    if (rotation !== stateRotation) {
                        setRotation(Math.floor(rotation));
                    } else {
                        const newWidth = Math.floor(size.width * node.scaleX());

                        setSize((state) => ({
                            width: newWidth,
                            height: state.height,
                        }));

                        node.scaleX(1);
                    }
                }}
                dragBoundFunc={function (pos) {
                    let newX = pos.x;
                    let newY = pos.y;

                    if (pos.x < STAGE_PADDING) {
                        newX = STAGE_PADDING;
                    } else if (pos.x > stageWidth - size.width - STAGE_PADDING) {
                        newX = stageWidth - size.width - STAGE_PADDING;
                    }
                    if (pos.y < STAGE_PADDING) {
                        newY = STAGE_PADDING;
                    } else if (pos.y > stageHeight - size.height - STAGE_PADDING) {
                        newY = stageHeight - size.height - STAGE_PADDING;
                    }

                    return {
                        x: newX,
                        y: newY,
                    };
                }}
                onDragEnd={(e) => {
                    setPosition({
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onContextMenu={(e) => {
                    e.evt.preventDefault(true);

                    const mousePosition = e.target.getStage().getPointerPosition();
                    props.onContextMenu(mousePosition);
                }}
            >
                {size.width && size.height && (
                    <Circle
                        radius={props.holderSize}
                        x={size.width / 2}
                        y={size.height / 2}
                        fill={props.holderColor}
                        opacity={props.holderOpacity}
                    />
                )}
                <Text
                    text={props.text}
                    ref={textRef}
                    fill={props.textColor}
                    wrap="word"
                    width={size.width}
                    fontSize={props.fontSize}
                    align="center"
                />
            </Group>
            {props.enableResize && props.isSelected && (
                <Transformer
                    ref={trRef}
                    rotationSnapTolerance={10}
                    rotationSnaps={[0, 90, 180, 270]}
                    enabledAnchors={["middle-left", "middle-right"]}
                />
            )}
        </>
    );
};

StageText.propTypes = {
    draggable: PropTypes.bool,
    enableResize: PropTypes.bool,
    fontSize: PropTypes.number,
    holderColor: PropTypes.string,
    holderOpacity: PropTypes.number,
    holderSize: PropTypes.number,
    isSelected: PropTypes.bool,
    rotation: PropTypes.number,
    stageHeight: PropTypes.number.isRequired,
    stageWidth: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    onContextMenu: PropTypes.func.isRequired,
    onMove: PropTypes.func.isRequired,
    onTransform: PropTypes.func.isRequired,
};

StageText.defaultProps = {
    holderSize: 15,
    textColor: "#121212",
    holderColor: "#CECECE",
    holderOpacity: 0.5,
};
