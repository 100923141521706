import {TagUtils} from "core/api/tag-utils";
import SchoolClassesSelectButtons from "entries/school-classes-select-buttons/school-classes-select-buttons";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {SpinnerPage} from "views/components/spinner";

const SchoolClassesSelectButtonsContainer = ({selectedClasses, setSelectedClasses}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [classes, setClasses] = useState([]);

    const loadClasses = () => {
        setIsLoading(true);
        TagUtils.getClasses()
            .then((response) => setClasses(response.data))
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        loadClasses();
    }, []);

    const handleSelectClass = (classData) => {
        if (selectedClasses.includes(classData.id)) {
            let clone = selectedClasses;
            clone = clone.filter((grade) => grade !== classData.id);
            setSelectedClasses(clone);
        } else {
            setSelectedClasses([...selectedClasses, classData.id]);
        }
    };

    if (isLoading) return <SpinnerPage primary />;

    return (
        <SchoolClassesSelectButtons
            classes={classes}
            selectedClasses={selectedClasses}
            handleSelectClass={handleSelectClass}
        />
    );
};

SchoolClassesSelectButtonsContainer.propTypes = {
    selectedClasses: PropTypes.array,
    setSelectedClasses: PropTypes.func,
};

export default SchoolClassesSelectButtonsContainer;
