import React, {useState} from "react";
import {AdminPanelUserAPI} from "../admin-panel-users-api";
import {toast} from "react-toastify";
import {IUser} from "../../../../interfaces/IUser";
import AdminPanelFormCreator, {
    EFormCreatorTypes,
} from "../../admin-panel-form-creator/admin-panel-form-creator";
import AdminPanelQueryResultLayout from "../../admin-panel-query-result-layout/admin-panel-query-result-layout";

const AdminPanelUserByEmail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState<IUser | null>(null);

    const handleGetUserByEmail = (formData: any) => {
        let {emailValue} = formData;

        if (!emailValue || !emailValue.trim()) {
            toast.error("Заполните поля");
            return;
        }

        setIsLoading(true);
        setResult(null);
        AdminPanelUserAPI.getUserByEmail(emailValue)
            .then((response) => {
                setResult(response.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <React.Fragment>
            <AdminPanelFormCreator
                handleClick={handleGetUserByEmail}
                bannerText="Поиск пользователя"
                formConstructor={[
                    {
                        name: "emailValue",
                        labelText: "Введите Email",
                        type: EFormCreatorTypes.TEXT,
                    },
                    {
                        name: "Найти",
                        type: EFormCreatorTypes.BUTTON,
                        color: "success",
                    },
                ]}
                isLoading={isLoading}
            />

            {result && (
                <AdminPanelQueryResultLayout>
                    <div className="mt-2">
                        <h4>Пользователь найден</h4>
                        <hr />
                        <p>
                            ID: {result.id} Имя: {result.firstName} Фамилия: {result.lastName}
                        </p>
                    </div>
                </AdminPanelQueryResultLayout>
            )}
        </React.Fragment>
    );
};

export default AdminPanelUserByEmail;
