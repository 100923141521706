import {MDBBtn} from "mdbreact";
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.sass";

const PlayerV2SetResult = ({handleStayClick, handleExitClick, result}) => {
    return (
        <div className={styles.result}>
            <p className={styles.title}>Тема пройдена</p>

            <img
                src={require("../../../../../assets/characters/emotions/raven_funny_01.svg")}
                alt="raven-funny"
            />

            <div className={styles.stat}>
                <p className={styles.correctStat}>Правильных ответов: {result.correct}</p>
                <p className={styles.wrongStat}>Неправильных ответов: {result.wrong}</p>
            </div>
            <div className={styles.controls}>
                <MDBBtn onClick={handleStayClick} color="info">
                    Остаться
                </MDBBtn>

                <MDBBtn onClick={handleExitClick} color="warning">
                    Вернуться к темам
                </MDBBtn>
            </div>
        </div>
    );
};

PlayerV2SetResult.propTypes = {
    handleExitClick: PropTypes.func,
};

export default PlayerV2SetResult;
