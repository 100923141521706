import React, {useEffect, useState} from "react";
import {EngineTypes} from "./config/engine-types";
import {ENGINE_SCRIPTS} from "./config/engine-scripts";
import {Steps} from "antd";
import styles from "./styles.module.sass";
import {observer} from "mobx-react-lite";
import {useStores} from "../../hooks/use-stores";
import {runInAction} from "mobx";

interface IProps {
    engineType: EngineTypes;
}

const ConstructorV2TaskWrapper = observer(({engineType}: IProps) => {
    const [engineScript, setEngineScript] = useState<any>(null);
    const {constructorV2Store} = useStores();

    useEffect(() => {
        let script = ENGINE_SCRIPTS[engineType];
        setEngineScript(script);

        if (!script) return;
        runInAction(() => {
            constructorV2Store.step = 0;
        });
    }, [engineType]);
    if (!engineScript) {
        return <p>No script</p>;
    }

    return (
        <div className={styles.wrapper}>
            <Steps
                className={styles.stepper}
                direction="vertical"
                current={constructorV2Store.step}
                onChange={(step) => runInAction(() => (constructorV2Store.step = step))}
                items={engineScript}
            />
            <div className={styles.content}>
                {engineScript[constructorV2Store.step].helpers &&
                    engineScript[constructorV2Store.step].helpers.map(
                        (help: any, index: number) => (
                            <div className={styles.helperWrapper} key={index}>
                                {help.component}
                            </div>
                        ),
                    )}
                {engineScript[constructorV2Store.step].component}
            </div>
        </div>
    );
});

export default ConstructorV2TaskWrapper;
