import background from "assets/sliders/available_bg.jpg";
import React from "react";
import sliders from "../sliders.module.sass";
import styles from "./styles.module.sass";

const AvailableSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            <div className={styles.header}>
                <p className={styles.title}>Возможности сайта</p>
            </div>
        </div>
    );
};

AvailableSlide.propTypes = {};

export default AvailableSlide;
