import {MDBBtn, MDBBtnGroup} from "mdbreact";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

export const PROPORTION_1TO1 = 1;
export const PROPORTION_2TO1 = 2;
export const PROPORTION_3TO2 = 1.5;
// export const PROPORTION_3TO4 = 0.75;
export const PROPORTION_4TO3 = 1.333;

export const PROPORTIONS = {
    1: PROPORTION_1TO1,
    2: PROPORTION_2TO1,
    1.5: PROPORTION_3TO2,
    // 0.75: PROPORTION_3TO4,
    1.333: PROPORTION_4TO3,
};

export const ProportionButtons = ({handleSelect, selected, availableProportions}) => {
    const [proportions, setProportions] = useState([]);

    useEffect(() => {
        if (!availableProportions || availableProportions.length === 0) {
            setProportions([PROPORTION_1TO1, PROPORTION_2TO1, PROPORTION_3TO2, PROPORTION_4TO3]);
        } else {
            if (availableProportions.length === 1) {
                handleSelect(availableProportions[0]);
            }
            setProportions(availableProportions);
        }
    }, [availableProportions]);

    return (
        <MDBBtnGroup className="w-100">
            <MDBBtn
                color="info"
                onClick={() => handleSelect(PROPORTION_1TO1)}
                active={selected === PROPORTION_1TO1}
                disabled={!proportions?.includes(PROPORTION_1TO1)}
            >
                1:1
            </MDBBtn>
            <MDBBtn
                color="info"
                onClick={() => handleSelect(PROPORTION_2TO1)}
                active={selected === PROPORTION_2TO1}
                disabled={!proportions?.includes(PROPORTION_2TO1)}
            >
                2:1
            </MDBBtn>
            <MDBBtn
                color="info"
                onClick={() => handleSelect(PROPORTION_3TO2)}
                active={selected === PROPORTION_3TO2}
                disabled={!proportions?.includes(PROPORTION_3TO2)}
            >
                3:2
            </MDBBtn>
            <MDBBtn
                color="info"
                onClick={() => handleSelect(PROPORTION_4TO3)}
                active={selected === PROPORTION_4TO3}
                disabled={!proportions?.includes(PROPORTION_4TO3)}
            >
                4:3
            </MDBBtn>
        </MDBBtnGroup>
    );
};

ProportionButtons.propTypes = {
    availableProportions: PropTypes.array,
    handleSelect: PropTypes.func.isRequired,
    selected: PropTypes.number,
};
