import React from "react";
import CreateNewsModal from "../../modals/create-news-modal/create-news-modal";
import {INews} from "../../../interfaces/INews";
import {EditButtons} from "../../../views/components/edit-buttons";
import {useStores} from "../../../hooks/use-stores";
import styles from "./styles.module.sass";
import noLogo from "assets/images/static/no-logo.svg";
import {url} from "../../../config";

interface IProps {
    news: INews;
    getNews: () => void;
    isView: boolean;
    deleteNews: (id: number) => void;
}

const images = [
    {
        tagId: 215,
        imageURL: "/data/image-bank/76c05a9d-21f1-4fe1-baa7-2ecf2e6210b2.svg",
        importance: 1,
    },
    {
        tagId: 215,
        imageURL: "/data/image-bank/f04584d5-3a5e-4772-91d1-da5e362652c0.svg",
        importance: 2,
    },
    {
        tagId: 215,
        imageURL: "/data/image-bank/af3e0c14-4b40-4c19-880d-fc39cb1269bf.svg",
        importance: 3,
    },
    {
        tagId: 216,
        imageURL: "/data/image-bank/638ad110-640d-4443-99ab-7022599bb86b.svg",
        importance: 1,
    },
    {
        tagId: 216,
        imageURL: "/data/image-bank/7b2292a5-2055-4c7e-b703-3abe456db34d.svg",
        importance: 2,
    },
    {
        tagId: 216,
        imageURL: "/data/image-bank/85f7ffe9-3139-4953-8072-00a98a41e005.svg",
        importance: 3,
    },
    {
        tagId: 217,
        imageURL: "/data/image-bank/c0728c02-63bc-4e8b-b004-fd65547e3c1e.svg",
        importance: 1,
    },
    {
        tagId: 217,
        imageURL: "/data/image-bank/60404711-d803-4bc5-a429-bdd6cae51835.svg",
        importance: 2,
    },
    {
        tagId: 217,
        imageURL: "/data/image-bank/2267749d-64d3-45dd-bea3-274eaa8e7831.svg",
        importance: 3,
    },
];

const NewsItem = ({news, getNews, deleteNews, isView}: IProps) => {
    const {appStore} = useStores();

    let date = new Date(news.updatedOn);

    const getImageURL = () => {
        if (!news) return noLogo;

        let image = images.find(
            (img) => img.tagId === news.newsTag?.id && img.importance === news.importance,
        );

        if (image) {
            return url + image.imageURL.slice(1);
        }

        return noLogo;
    };

    return (
        <div className={styles.newsItem}>
            <div className={styles.newsItemLeft}>
                <img className={styles.newsItemLogo} src={getImageURL()} alt="no-logo" />
            </div>

            <div className={styles.newsItemRight}>
                <div className={styles.newsItemRightHeader}>
                    <p className={styles.title}>{news.title}</p>
                    <span className={styles.date}>
                        —{" "}
                        {date.toLocaleDateString("ru-RU", {
                            month: "long",
                            day: "numeric",
                        })}
                    </span>
                </div>

                <div
                    className={styles.newsItemText}
                    dangerouslySetInnerHTML={{__html: news.newsText}}
                ></div>
            </div>

            {!isView && (
                <EditButtons
                    editAction={() =>
                        appStore.addModalToQueue(
                            <CreateNewsModal
                                key={"cnm-modal toggle-disabled"}
                                toggle={appStore.closeActiveModal}
                                news={news}
                                getNews={getNews}
                            />,
                        )
                    }
                    deleteAction={() => deleteNews(news.id)}
                />
            )}
        </div>
    );
};

export default NewsItem;
