export const META_TAGS = {
    MAIN: {
        TITLE: "Умная Ворона",
        DESCRIPTION: "Онлайн тренажёры и познавательные турниры для школьников начальных классов (1-4). 20000 увлекательных заданий в формате Академии и Турниров!",
        KEYWORDS: "школа 1 2 3 4 начальный класс обучение школьная программа тренажёр турнир олимпиада школьник ученик бесплатно задача задание тест игра тема"
    },
    ACADEMY: {
        TITLE: "Академия Умной Вороны!",
        DESCRIPTION: "Тысячи интерактивных заданий для школьников начальных классов. Задачи по школьным предметам и темам.",
        KEYWORDS: "русский язык английский логика окружающий мир литературное чтение математика задача задание обучение курсы школа начальный класс 1 2 3 4 вопрос решать бесплатно"
    },
    ACADEMY_RESOLVE: {
        TITLE: "Академия Умной Вороны:",
        DESCRIPTION: "Тысячи интерактивных, познавательных заданий для начальных классов. Школьная программа в интересных и полезных задачках!",
        KEYWORDS: "учеба онлайн решать задача загадка задачка урок задание школьный класс 1 2 3 4 начальный развивающий тренажёр онлайн бесплатный"
    },
    TOURNAMENT: {
        TITLE: "Турниры Умной Вороны",
        DESCRIPTION: "Бесплатные онлайн соревнования в знаниях и скорости для 1-4 классов начальной школы. Учитесь играя! Каждый день новые задания для учеников.",
        KEYWORDS: "математика русский язык окружающий мир чтение английский этикет логика задача задание образование обучение учеба школа начальный класс 1 2 3 4 вопрос"
    },
    STUDENT_HOMEWORK: {
        TITLE: "Задания от учителя",
        DESCRIPTION: "Бесплатные интерактивные задания, созданные педагогом. Онлайн обучение для школьников в формате интерактивных заданий.",
        KEYWORDS: "домашняя контрольная работа школа школьный 1 2 3 4 класс учеба онлайн учитель педагог тренажёр создать решать дистанционный курс"
    },
}
