import React from "react";
import {MDBNavItem, MDBNavLink} from "mdbreact";
import cn from "classnames";
import styles from "./header.module.sass";
import {useStores} from "../../../hooks/use-stores";
import {observer} from "mobx-react-lite";
import {useLocation} from "react-router-dom";
import {auth} from "../../../core/utils/auth";

interface IProps {
    tabs: any[];
    navbarIsCollapsed: boolean;
}

const HeaderTabs = observer(({tabs, navbarIsCollapsed}: IProps) => {
    const {appStore} = useStores();
    let location = useLocation();
    const {keycloak} = auth;

    return (
        <React.Fragment>
            {!!tabs?.length &&
                tabs.map((tab: any, index: number) => (
                    <MDBNavItem
                        key={index}
                        className={cn({
                            [styles.navItemMobile]: appStore.isMobile,
                        })}
                    >
                        <MDBNavLink
                            to={tab.path}
                            className={cn({
                                [styles.navLink]: true,
                                [styles.isActive]:
                                    location?.pathname !== "/" &&
                                    tab.path?.includes(location?.pathname),
                            })}
                        >
                            {tab.name}
                        </MDBNavLink>
                    </MDBNavItem>
                ))}

            {keycloak.authenticated && navbarIsCollapsed && (
                <MDBNavItem className={styles.exitButton}>
                    <MDBNavLink key="exit" to="#">
                        <div onClick={() => keycloak.logout()}>
                            <p style={{color: "#434242"}}>Выход</p>
                        </div>
                    </MDBNavLink>
                </MDBNavItem>
            )}
        </React.Fragment>
    );
});

export default HeaderTabs;
