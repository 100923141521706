import React from "react";
import PropTypes from "prop-types";
import {Stage, Layer, Image} from "react-konva";
import {STEP} from "views2/conatiners/CreateTestWizard";

import {SIZE, ITEMS_TYPE, OUTLINE_TYPE} from "./config";
import {getId, getMaxStageItemsCount, getStageItemsCount, removeStateItem} from "./utils";

import {ContextMenu} from "./ContextMenu";
import {OutlineType} from "./OutlineType";
import {Portal} from "./Portal";
import {Size} from "./Size";
import {StageText} from "./StageText";
import {URLImage} from "./URLImage";

import styles from "./styles.module.scss";

export class DragArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isTextEdit: false,
            itemsType: undefined,
            draggingId: undefined,
            imageById: {},
            imagesIds: [],
            background: {
                src: undefined,
                height: undefined,
                width: undefined,
                image: null,
            },
            size: SIZE.M.value,
            stageImages: {},
            stageImagesIds: [],
            textById: {},
            textsIds: [],
            stageTexts: {},
            stageTextIds: [],
            multipleUsages: false,
            outlineType: OUTLINE_TYPE.SQUARE,
        };

        this.dragUrl = React.createRef();
        this.stageRef = React.createRef();
        this.contextMenuRef = React.createRef();
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.itemsType !== this.state.itemsType) {
            console.log(true);
            this.setItemsType(nextProps.itemsType);
        }

        return true;
    }

    loadImage = (src) => {
        const image = new window.Image();

        image.onload = () => {
            const width = image.width > 588 ? 588 : image.width;
            const ratio = image.width / width;
            const height = image.height / ratio;

            this.setState((state) => ({
                ...state,
                background: {
                    ...state.background,
                    image,
                    width,
                    height,
                },
            }));
        };

        image.src = src;
    };

    handleLoadBackground = (event) => {
        if (event.target?.files?.[0]) {
            const image = URL.createObjectURL(event.target.files[0]);
            this.loadImage(image);
        }
    };

    setItemsType = (type) => {
        this.setState({
            itemsType: type,
        });
    };

    handleSubmitText = (event) => {
        event.preventDefault();

        const input = event.target.querySelector("textarea");
        const submittedText = input.value;

        if (submittedText) {
            const id = getId();

            this.setState(
                (state) => ({
                    textById: {
                        ...state.textById,
                        [id]: {
                            id,
                            text: submittedText.trim(),
                            isEditable: false,
                            shown: true,
                        },
                    },
                    textsIds: [...state.textsIds, id],
                }),
                () => {
                    input.value = "";
                },
            );
        }
    };

    handleAddImage = (event) => {
        event.preventDefault();
        const form = event.target;
        const input = form.querySelector("input[type='file']");

        if (input.files?.length) {
            const file = input.files?.[0];

            const imageSrc = URL.createObjectURL(file);
            const image = new window.Image();

            image.onload = () => {
                const id = getId();

                this.setState((state) => ({
                    imageById: {
                        ...state.imageById,
                        [id]: {
                            file,
                            src: imageSrc,
                            image: image,
                            shown: true,
                            width: image.width,
                            height: image.height,
                        },
                    },
                    imagesIds: [...state.imagesIds, id],
                }));
            };

            image.src = imageSrc;
        }

        input.value = "";
    };

    addStageImage = (id, image) => {
        this.setState((state) => ({
            ...state,
            stageImages: {...state.stageImages, [id]: image},
            stageImagesIds: [...state.stageImagesIds, id],
        }));
    };

    addStageText = (id, item) => {
        this.setState((state) => ({
            ...state,
            stageTexts: {
                ...state.stageTexts,
                [id]: item,
            },
            stageTextIds: [...state.stageTextIds, id],
        }));
    };

    toggleMultipleUsages = () => {
        this.setState({
            multipleUsages: !this.state.multipleUsages,
        });
    };

    handleDrop = (e) => {
        e.preventDefault();

        const itemsType = this.state.itemsType;
        // TODO: replace to items
        const stageItemsCount = getStageItemsCount(
            itemsType,
            this.state.stageImagesIds,
            this.state.stageTextIds,
        );
        const maxItemsCount = getMaxStageItemsCount(itemsType, this.state.size);

        if (stageItemsCount >= maxItemsCount) {
            alert("Невозможно добавить элемент! Измените размер или удалите существующий элемент!");
            return false;
        }

        this.stageRef.current.setPointersPositions(e);

        const pointerPosition = this.stageRef.current.getPointerPosition();
        const y = Math.floor(pointerPosition.y);
        const x = Math.floor(pointerPosition.x);
        const currentId = this.dragUrl.current;
        const id = getId();

        if (this.state.itemsType === ITEMS_TYPE.IMAGE) {
            const stateImage = this.state.imageById[currentId];

            if (stateImage) {
                this.addStageImage(id, {
                    ...this.stageRef.current.getPointerPosition(),
                    src: stateImage.src,
                    image: stateImage.image,
                    y,
                    x,
                    width: stateImage.width,
                    height: stateImage.height,
                    id,
                    parentId: currentId,
                });

                if (!this.state.multipleUsages) {
                    this.setState((state) => {
                        const nextState = {...state};
                        nextState.imageById[currentId].shown = false;

                        return nextState;
                    });
                }

                this.dragUrl.current = null;
            }
        } else {
            const stateText = this.state.textById[currentId];

            if (stateText) {
                this.addStageText(id, {
                    id,
                    x,
                    y,
                    text: stateText.text,
                    parentId: currentId,
                });
            }

            this.setState((state) => {
                const nextState = {...state};
                nextState.textById[currentId].shown = false;

                return nextState;
            });
        }
    };

    handleRemoveImage = (id) => {
        if (this.state.imageById[id] != null) {
            this.setState((state) => {
                const {ids, byId} = removeStateItem(state.imageById, state.imagesIds, id);

                return {
                    ...state,
                    imageById: byId,
                    imagesIds: ids,
                };
            });
        }
    };

    handleRemoveText = (id) => {
        if (this.state.textById[id] != null) {
            this.setState((state) => {
                const {ids, byId} = removeStateItem(state.textById, state.textsIds, id);

                return {
                    ...state,
                    textById: byId,
                    textsIds: ids,
                };
            });
        }
    };

    handleChangeSize = (event) => {
        const size = event.target.dataset?.size;
        const itemsType = this.state.itemsType;

        let overItemsCount = 0;

        if (itemsType === ITEMS_TYPE.IMAGE) {
            const imagesCount = this.state.stageImagesIds.length;
            const maxImagesCount = SIZE[size].maxStageImagesCount;

            overItemsCount = imagesCount - maxImagesCount;
        }

        let newState = {};
        let shouldChangeState = false;

        if (overItemsCount > 0) {
            const canChangeSize = window.confirm(
                `Изменение размера приведет к удалению ${Math.abs(overItemsCount)} элемента(ов)`,
            );

            if (canChangeSize) {
                let newStageImages = this.state.stageImages;
                let newStageImagesIds = this.state.stageImagesIds;
                let newStateImages = this.state.imageById;

                const removedStageImageIds = newStageImagesIds.splice(overItemsCount * -1);
                removedStageImageIds.forEach((imageId) => {
                    const parentId = newStageImages[imageId].parentId;
                    newStateImages[parentId] && (newStateImages[parentId].shown = true);

                    delete newStageImages[imageId];
                });

                shouldChangeState = true;
                newState = {
                    size,
                    stageImages: newStageImages,
                    stageImagesIds: newStageImagesIds,
                    imageById: newStateImages,
                };
            }
        } else {
            shouldChangeState = true;
            newState = {
                size,
            };
        }

        if (shouldChangeState) {
            this.setState(newState);
        }
    };

    handleChangeColor = (e) => {
        const contextFor = this.state.contextFor;
        const newColor = e.target.value;

        if (contextFor && newColor) {
            this.setState((state) => ({
                currentChangedColor: newColor,
                ...state,
                stageTexts: {
                    ...state.stageTexts,
                    [contextFor]: {
                        ...state.stageTexts[contextFor],
                        color: newColor,
                    },
                },
            }));
        }
    };

    handleOutlineTypeChange = (outlineType) => {
        if (outlineType && outlineType !== this.state.outlineType) {
            this.setState({
                outlineType,
            });
        }
    };

    checkDeselect = (e) => {
        if (this.state.contextShown) {
            this.setState({
                contextShown: false,
                contextPosition: {
                    x: undefined,
                    y: undefined,
                },
                contextFor: null,
                selected: null,
            });
        }

        if (this.state.selected) {
            const clickedOnEmpty =
                e.target === e.target.getStage() || e.target === this.backgroundNode;

            if (clickedOnEmpty) {
                this.setState({selected: null});
            }
        }
    };

    showContext = (position, imageId) => {
        this.setState({
            contextShown: true,
            contextPosition: position,
            contextFor: imageId,
            selected: null,
        });
    };

    onRemoveFromStage = (itemId) => {
        if (this.state.itemsType === ITEMS_TYPE.IMAGE) {
            this.setState((state) => {
                const newStageImagesIds = state.stageImagesIds;
                const newStageImages = state.stageImages;
                const newStateImages = state.imageById;

                const removedIds = newStageImagesIds.splice(
                    state.stageImagesIds.indexOf(itemId),
                    1,
                );
                removedIds.forEach((removedId) => {
                    const parentId = newStageImages[removedId].parentId;
                    newStateImages[parentId] && (newStateImages[parentId].shown = true);
                });

                return {
                    ...state,
                    stageImages: newStageImages,
                    stageImagesIds: newStageImagesIds,
                    imageById: newStateImages,
                };
            });
        } else {
            this.setState((state) => {
                const newStageTextIds = state.stageTextIds;
                const newStageTexts = state.stageTexts;
                const newStateTexts = state.textById;

                const removedIds = newStageTextIds.splice(state.stageTextIds.indexOf(itemId), 1);
                removedIds.forEach((removedId) => {
                    const parentId = newStageTexts[removedId].parentId;
                    newStateTexts[parentId] && (newStateTexts[parentId].shown = true);
                });

                return {
                    ...state,
                    stageTexts: newStageTexts,
                    stageTextIds: newStageTextIds,
                    textById: newStateTexts,
                };
            });
        }
    };

    setTextEditable = (id) => {
        this.setState((state) => ({
            isTextEdit: true,
            textById: {
                ...state.textById,
                [id]: {
                    ...state.textById[id],
                    isEditable: true,
                },
            },
            textsIds: [...state.textsIds, id],
        }));
    };

    handleEditText = (id, event) => {
        event.preventDefault();

        const input = event.target.querySelector("textarea");
        const submittedText = input.value;

        if (submittedText) {
            this.setState((state) => ({
                isTextEdit: false,
                textById: {
                    ...state.textById,
                    [id]: {
                        ...state.textById[id],
                        text: submittedText.trim(),
                        isEditable: false,
                    },
                },
                textsIds: [...state.textsIds, id],
            }));
        }
    };

    handleTransformImage = (id, transformation) => {
        this.setState((state) => ({
            ...state,
            stageImages: {
                ...state.stageImages,
                [id]: {
                    ...state.stageImages[id],
                    rotation: transformation.rotation || 0,
                    width: transformation.width,
                    height: transformation.height,
                },
            },
        }));
    };

    handleMoveImage = (id, movement) => {
        this.setState((state) => ({
            ...state,
            stageImages: {
                ...state.stageImages,
                [id]: {
                    ...state.stageImages[id],
                    x: movement.x,
                    y: movement.y,
                },
            },
        }));
    };

    handleTransformText = (id, transformation) => {
        this.setState((state) => ({
            ...state,
            stageTexts: {
                ...state.stageTexts,
                [id]: {
                    ...state.stageTexts[id],
                    rotation: transformation.rotation || 0,
                    width: transformation.width,
                },
            },
        }));
    };

    handleMoveText = (id, movement) => {
        this.setState((state) => ({
            ...state,
            stageTexts: {
                ...state.stageTexts,
                [id]: {
                    ...state.stageTexts[id],
                    x: movement.x,
                    y: movement.y,
                },
            },
        }));
    };

    handleSave = () => {
        return new Promise((resolve) => {
            const {background, size, itemsType} = this.state;

            const values = {
                background: {
                    height: background.height,
                    width: background.width,
                    src: background.src || background.image.currentSrc,
                },
                config: {},
                itemsType: itemsType,
            };

            if (itemsType === ITEMS_TYPE.IMAGE) {
                const {stageImages, stageImagesIds, outlineType, multipleUsages} = this.state;

                values.items = stageImagesIds.map((id) => {
                    const item = stageImages[id];

                    return {
                        height: item.height,
                        id,
                        rotation: item.rotation,
                        src: item.src,
                        type: ITEMS_TYPE.IMAGE,
                        width: item.width,
                        x: item.x,
                        y: item.y,
                    };
                });
                values.config = {
                    size,
                    outlineType,
                    multipleUsages,
                };
            } else {
                const {stageTexts, stageTextIds} = this.state;

                values.items = stageTextIds.map((id) => {
                    const item = stageTexts[id];

                    return {
                        id,
                        color: item.color,
                        holderColor: item.holderColor,
                        holderOpacity: item.holderOpacity,
                        rotation: item.rotation,
                        text: item.text,
                        type: ITEMS_TYPE.TEXT,
                        width: item.width,
                        x: item.x,
                        y: item.y,
                    };
                });
                values.config = {
                    size,
                    holderSize: this.state.holder,
                };
            }

            this.props.enableNextStep(STEP.ANSWER);
            this.props.handleSave(values);
            return resolve(values);
        });
    };

    render() {
        const {
            imagesIds,
            itemsType,
            textsIds,
            size: stateSize,
            background: stateBackground,
            contextFor: stateContextFor,
        } = this.state;
        const backgroundChosen = stateBackground.image;
        const backgroundWidth = backgroundChosen ? stateBackground.width : undefined;
        const backgroundHeight = backgroundChosen ? stateBackground.height : undefined;
        const itemsTypeChosen = itemsType !== undefined;
        const isImageItemsType = itemsType === ITEMS_TYPE.IMAGE;
        const itemsCount = (isImageItemsType ? imagesIds : textsIds).length;
        const hasItems =
            (isImageItemsType ? this.state.stageImagesIds : this.state.stageTextIds).length > 0;
        const sizeConfig = SIZE[stateSize];
        const maxItemsCount =
            sizeConfig[isImageItemsType ? "maxPreviewImagesCount" : "maxPreviewTextCount"];

        return (
            <div className={styles.DragArea}>
                <div>
                    <div
                        onDrop={this.handleDrop}
                        onDragOver={(e) => e.preventDefault()}
                        style={{position: "relative"}}
                    >
                        {backgroundChosen ? (
                            <Stage
                                width={backgroundWidth}
                                height={backgroundHeight}
                                ref={this.stageRef}
                                onMouseDown={this.checkDeselect}
                                onTouchStart={this.checkDeselect}
                            >
                                <Layer>
                                    <Image
                                        x={0}
                                        y={0}
                                        width={backgroundWidth}
                                        height={backgroundHeight}
                                        image={stateBackground.image}
                                        ref={(node) => (this.backgroundNode = node)}
                                    />
                                </Layer>
                                {this.state.itemsType === ITEMS_TYPE.IMAGE && (
                                    <Layer>
                                        {this.state.stageImagesIds.map((imageId) => {
                                            const image = this.state.stageImages[imageId];

                                            return (
                                                <URLImage
                                                    enableResize={this.props.enableResize}
                                                    isSelected={this.state.selected === imageId}
                                                    outlineType={this.state.outlineType}
                                                    showOutline
                                                    defaultX={image.x}
                                                    defaultY={image.y}
                                                    width={image.width}
                                                    height={image.height}
                                                    image={image.image}
                                                    key={imageId}
                                                    size={this.state.size}
                                                    stageHeight={backgroundHeight}
                                                    stageWidth={backgroundWidth}
                                                    onContextMenu={(position) => {
                                                        this.showContext(position, imageId);
                                                    }}
                                                    onTransform={this.handleTransformImage.bind(
                                                        this,
                                                        imageId,
                                                    )}
                                                    onMove={this.handleMoveImage.bind(
                                                        this,
                                                        imageId,
                                                    )}
                                                />
                                            );
                                        })}
                                    </Layer>
                                )}
                                {this.state.itemsType === ITEMS_TYPE.TEXT && (
                                    <Layer>
                                        {this.state.stageTextIds.map((itemId) => {
                                            const item = this.state.stageTexts[itemId];

                                            return (
                                                <StageText
                                                    draggable
                                                    enableResize={this.props.enableResize}
                                                    isSelected={this.state.selected === itemId}
                                                    outlineType={this.state.outlineType}
                                                    showOutline
                                                    x={item.x}
                                                    y={item.y}
                                                    text={item.text}
                                                    key={itemId}
                                                    fontSize={sizeConfig.fontSize}
                                                    textColor={item.color}
                                                    stageHeight={backgroundHeight}
                                                    stageWidth={backgroundWidth}
                                                    onContextMenu={(position) =>
                                                        this.showContext(position, itemId)
                                                    }
                                                    onTransform={this.handleTransformText.bind(
                                                        this,
                                                        itemId,
                                                    )}
                                                    onMove={this.handleMoveText.bind(this, itemId)}
                                                />
                                            );
                                        })}
                                    </Layer>
                                )}
                                {this.state.contextShown && (
                                    <Portal node={this.contextMenuRef.current}>
                                        <ContextMenu position={this.state.contextPosition}>
                                            <ul
                                                className={styles.ContextActions}
                                                onClick={() => {
                                                    this.setState({
                                                        contextShown: false,
                                                    });
                                                }}
                                            >
                                                <li
                                                    onClick={() =>
                                                        this.setState({
                                                            selected: stateContextFor,
                                                        })
                                                    }
                                                >
                                                    Изменить
                                                </li>
                                                <li
                                                    onClick={() =>
                                                        this.setState({
                                                            colorSelect: true,
                                                        })
                                                    }
                                                >
                                                    Изменить цвет
                                                </li>
                                                <li
                                                    onClick={() =>
                                                        this.onRemoveFromStage(stateContextFor)
                                                    }
                                                >
                                                    Удалить
                                                </li>
                                            </ul>
                                        </ContextMenu>
                                    </Portal>
                                )}
                            </Stage>
                        ) : (
                            <label>
                                Добавить фоновое изображение
                                <input type="file" onChange={this.handleLoadBackground} />
                            </label>
                        )}
                        <div className="context_menu" ref={this.contextMenuRef} />
                    </div>
                    {backgroundChosen && (
                        <div>
                            <div>
                                {/*{this.state.itemsType === undefined && (*/}
                                {/*    <div>*/}
                                {/*        <div>Тип объекта для перетаскивания</div>*/}
                                {/*        <div*/}
                                {/*            onClick={this.setItemsType.bind(this, ITEMS_TYPE.TEXT)}*/}
                                {/*        >*/}
                                {/*            Текст*/}
                                {/*        </div>*/}
                                {/*        <div*/}
                                {/*            onClick={this.setItemsType.bind(this, ITEMS_TYPE.IMAGE)}*/}
                                {/*        >*/}
                                {/*            Изображение*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                {this.state.itemsType === ITEMS_TYPE.TEXT && (
                                    <div>
                                        <div>Добавить текст</div>
                                        {this.state.colorSelect && (
                                            <input
                                                type="color"
                                                onChange={this.handleChangeColor}
                                                value={this.state.currentChangedColor || "#000"}
                                                onBlur={() => {
                                                    this.setState({
                                                        colorSelect: false,
                                                        contextFor: null,
                                                    });
                                                }}
                                            />
                                        )}
                                        <div>
                                            {Object.entries(this.state.textById)
                                                .filter(([, item]) => item.shown)
                                                .map(([id, item]) => (
                                                    <div key={id}>
                                                        <div
                                                            draggable="true"
                                                            onDragStart={() => {
                                                                this.dragUrl.current = id;
                                                            }}
                                                            onDoubleClick={this.setTextEditable.bind(
                                                                this,
                                                                id,
                                                            )}
                                                        >
                                                            {item.isEditable ? (
                                                                <form
                                                                    onSubmit={this.handleEditText.bind(
                                                                        this,
                                                                        id,
                                                                    )}
                                                                >
                                                                    <textarea
                                                                        placeholder="Текст"
                                                                        maxLength={200}
                                                                        defaultValue={item.text}
                                                                    />
                                                                    <button>Сохранить</button>
                                                                </form>
                                                            ) : (
                                                                item.text
                                                            )}
                                                        </div>
                                                        <div
                                                            onClick={this.handleRemoveText.bind(
                                                                this,
                                                                id,
                                                            )}
                                                        >
                                                            ✖
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                        {!this.state.isTextEdit && itemsCount < maxItemsCount && (
                                            <div>
                                                <form onSubmit={this.handleSubmitText}>
                                                    <textarea placeholder="Текст" maxLength={200} />
                                                    <button>Добавить</button>
                                                </form>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {this.state.itemsType === ITEMS_TYPE.IMAGE && (
                                    <div>
                                        <div>Добавить изображение</div>
                                        <div>
                                            {Object.entries(this.state.imageById)
                                                .filter(
                                                    ([, image]) =>
                                                        this.state.multipleUsages || image.shown,
                                                )
                                                .map(([id, image]) => (
                                                    <div key={id}>
                                                        <img
                                                            alt=""
                                                            src={image.src}
                                                            draggable="true"
                                                            width={100}
                                                            onDragStart={() => {
                                                                this.dragUrl.current = id;
                                                            }}
                                                        />
                                                        <div
                                                            onClick={this.handleRemoveImage.bind(
                                                                this,
                                                                id,
                                                            )}
                                                        >
                                                            ✖
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                        {itemsCount < maxItemsCount && (
                                            <div>
                                                <form onSubmit={this.handleAddImage}>
                                                    <input type="file" placeholder="Изображение" />
                                                    <button>Добавить</button>
                                                </form>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {backgroundChosen && (
                    <div>
                        {itemsTypeChosen && (
                            <div>
                                <div>Размер объектов</div>
                                <div onClick={this.handleChangeSize}>
                                    {Object.entries(SIZE).map(([key, size]) => (
                                        <Size
                                            key={key}
                                            size={size.value}
                                            selected={size.value === this.state.size}
                                        >
                                            {size.label}
                                        </Size>
                                    ))}
                                </div>
                            </div>
                        )}
                        {this.state.itemsType === ITEMS_TYPE.IMAGE && (
                            <>
                                <OutlineType
                                    onChange={this.handleOutlineTypeChange}
                                    defaultOutlineType={this.state.outlineType}
                                />
                                <div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            defaultChecked={this.state.multipleUsages}
                                            onChange={this.toggleMultipleUsages}
                                        />
                                        <span>Можно использовать изображение несколько раз</span>
                                    </label>
                                </div>
                            </>
                        )}
                        {hasItems && (
                            <button type="button" onClick={this.handleSave}>
                                Продолжить
                            </button>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

DragArea.propTypes = {
    enableNextStep: PropTypes.func,
    enableResize: PropTypes.bool,
    handleSave: PropTypes.func,
    itemsType: PropTypes.string,
};

DragArea.defaultProps = {
    enableResize: true,
};
