export const EventTabs = {
    ALL: "ALL",
    ACHIEVEMENT: "ACHIEVEMENT",
    STUDENT: "STUDENT",
    NOTIFICATION: "NOTIFICATION",
    NEWS: "NEWS",
    PERFORMANCE: "PERFORMANCE",
    TOURNAMENT: "TOURNAMENT",
};

export const EventSubSection = [
    {value: "PERFORMANCE", label: "Успеваемость"},
    {value: "TOURNAMENT", label: "Итоги турниров"},
    {value: "ACHIEVEMENT", label: "Достижения"},
    {value: "NEWS", label: "Новости"},
    {value: "NOTIFICATION", label: "Уведомления"},
];
