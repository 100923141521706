import {url} from "config";
import EventItem from "entries/events/event-item/event-item";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.sass";

const EventsList = ({events}) => {
    return (
        <div className={styles.eventList}>
            {!!events?.length ? (
                <React.Fragment>
                    {events.map((event, index) => {
                        return (
                            <EventItem
                                key={index}
                                title={event?.event?.title}
                                description={event?.event?.description}
                                rating={event?.event?.rating}
                                className="mt-3"
                                imageUrl={
                                    event?.event?.pictogram &&
                                    url + event.event.pictogram.fileDownloadUri.slice(1)
                                }
                                coin={event?.event?.coin}
                            />
                        );
                    })}
                </React.Fragment>
            ) : (
                <p className="title mt-4">Событий пока нет</p>
            )}
        </div>
    );
};

EventsList.propTypes = {
    events: PropTypes.array,
};

export default EventsList;
