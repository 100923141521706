import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import {Button, Form, Input, InputNumber, Select, Space, Table} from "antd";
import {Request} from "../../../../core/utils/request";
import {ColumnsType} from "antd/es/table";
import {IPageable} from "../../../../interfaces/IPageable";
import ModalWrapper from "../../../modals/modal-wrapper/modal-wrapper";
import {useStores} from "../../../../hooks/use-stores";
import {toast} from "react-toastify";
import {IGameMagic} from "../../../../interfaces/IGameMagic";

interface IItemModal {
    handleClose: () => void;
    magic?: IGameMagic;
    handleRefresh: () => void;
}

const MagicModal = ({handleClose, magic, handleRefresh}: IItemModal) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [loadedMagic, setLoadedMagic] = useState<any>(undefined);

    useEffect(() => {
        if (!magic) {
            return;
        }

        setLoadedMagic(magic);
        form.setFieldsValue(magic);
    }, [magic]);

    useEffect(() => {
        handleRefresh();
    }, [loadedMagic]);

    const handleSaveMagic = (form: IGameMagic) => {
        setIsLoading(true);

        if (magic) {
            Request.put(`/game-magic/${magic.id}`, form)
                .then(() => {
                    handleRefresh();
                    handleClose();
                })
                .finally(() => setIsLoading(false));
        } else {
            Request.post(`/game-magic`, form)
                .then((response) => {
                    setLoadedMagic(response.data);
                    handleRefresh();
                    handleClose();
                })
                .finally(() => setIsLoading(false));
        }
    };

    return (
        <ModalWrapper withHeader={true} headerTitle={"Магия"} onClose={handleClose}>
            <Form
                layout="horizontal"
                style={{padding: "20px"}}
                form={form}
                onFinish={handleSaveMagic}
            >
                <Form.Item name="bonus" label="Бонус">
                    <InputNumber />
                </Form.Item>

                <Form.Item name="description" label="Описание">
                    <Input />
                </Form.Item>

                <Form.Item name="setMin" label="Мин. значение">
                    <InputNumber />
                </Form.Item>

                <Form.Item name="gameMagicType" label="Тип">
                    <Select>
                        <Select.Option value="RATING_ALL_UP">RATING_ALL_UP</Select.Option>
                        <Select.Option value="MONEY_ALL_UP">MONEY_ALL_UP</Select.Option>
                        <Select.Option value="SELL_BONUS">SELL_BONUS</Select.Option>
                        <Select.Option value="SHOP_DISCOUNT">SHOP_DISCOUNT</Select.Option>
                        <Select.Option value="BOX_DISCOUNT">BOX_DISCOUNT</Select.Option>
                    </Select>
                </Form.Item>

                <Button htmlType="submit" type="primary" disabled={isLoading}>
                    Сохранить
                </Button>
            </Form>
        </ModalWrapper>
    );
};

const AdminPanelGameMagic = () => {
    const {appStore} = useStores();
    const [magics, setMagics] = useState<IPageable<IGameMagic> | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const [page, setPage] = useState(1);

    useEffect(() => {
        getMagics();
    }, [page]);

    const getMagics = () => {
        setIsLoading(true);
        Request.get(`/game-magic?page=${page}`)
            .then((response) => {
                setMagics(response.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDeleteMagic = (id: number) => {
        setIsLoading(true);
        Request.delete(`/game-magic/${id}`)
            .then(() => {
                toast.success("Магия удалена");
                getMagics();
            })
            .catch(() => {
                toast.error("Ошибка удаления магии");
            })
            .finally(() => setIsLoading(false));
    };

    const columns: ColumnsType<IGameMagic> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: "Тип",
            dataIndex: "gameMagicType",
            key: "gameMagicType",
        },
        {
            title: "Бонус",
            dataIndex: "bonus",
            key: "bonus",
        },
        {
            title: "Описание",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Мин. значение",
            dataIndex: "setMin",
            key: "setMin",
        },
        {
            title: "Действия",
            key: "action",
            render: (_: any, record: any) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            appStore.addModalToQueue(
                                <MagicModal
                                    handleClose={() => appStore.closeActiveModal()}
                                    key={"game-magic-modal"}
                                    magic={record}
                                    handleRefresh={() => getMagics()}
                                />,
                            );
                        }}
                    >
                        <i className="fas fa-pen" style={{color: "orange"}} />
                    </a>
                    <a onClick={() => handleDeleteMagic(record.id)}>
                        <i className="fas fa-trash" style={{color: "red"}} />
                    </a>
                </Space>
            ),
        },
    ];

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.header}>
                    <Button
                        type="primary"
                        onClick={() => {
                            appStore.addModalToQueue(
                                <MagicModal
                                    handleClose={() => appStore.closeActiveModal()}
                                    key={"game-magic-modal"}
                                    handleRefresh={() => getMagics()}
                                />,
                            );
                        }}
                    >
                        Создать магию
                    </Button>
                </div>

                <Table
                    columns={columns}
                    dataSource={magics?.content}
                    loading={isLoading}
                    rowKey="id"
                    pagination={{
                        current: magics?.page,
                        total: magics?.totalElements,
                        onChange: (page) => setPage(page),
                        pageSize: magics?.size,
                        hideOnSinglePage: true,
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default AdminPanelGameMagic;
