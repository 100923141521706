import {Image} from "entries/constructors/classic-test/views/components/image-answer";
import Hints from "entries/hints/hints";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import {ImageSequencePlayer} from "entries/player/views/task-players/image-sequence-player/image-sequence-player";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export const ImageSequencePlayerContainer = ({
    taskId,
    onSelectCorrectAnswer,
    questionApi,
    getInfoAboutSet,
    playerMode,
    answerIsLoading,
    setAnswerIsLoading,
}) => {
    const [question, setQuestion] = useState(null);
    const [answers, setAnswers] = useState(null);
    const [properties, setProperties] = useState(null);
    const [correctAnswers, setCorrectAnswers] = useState(null);
    const [indexes, setIndexes] = useState([]);

    let {id} = useParams();

    useEffect(() => {
        setAnswers(null);
        setCorrectAnswers(null);
        setQuestion(null);
        setProperties(null);
        getData();
    }, [taskId]);

    const getData = async () => {
        setAnswerIsLoading(true);
        let data = undefined;
        await questionApi(taskId, id)
            .then((response) => {
                if (playerMode === PlayerV2Modes.TOURNAMENT) {
                    data = response.data[0].question;
                } else {
                    data = response.data;
                    getInfoAboutSet();
                }
            })
            .catch(() => console.log("load data error"))
            .finally(() => setAnswerIsLoading(false));

        if (!data) {
            return;
        }

        let answersTemp = data.sequenceTestAnswerVariants.map((item) => {
            let itemImage = item.answerImageBank
                ? item.answerImageBank.fileDownloadUri.slice(1)
                : item.answerImageQuestion.fileDownloadUri.slice(1);
            return {id: item.id, component: <Image imageUrl={itemImage} />};
        });

        setQuestion(data.sequenceTest);
        setAnswers(answersTemp);
        setProperties(data.questionProperties);
    };

    const selectCorrectAnswerHandle = (answers) => {
        setCorrectAnswers(answers);
        onSelectCorrectAnswer(answers);
    };

    const fillCorrectAnswers = (answersForFill) => {
        let correctAnswers = answersForFill.map((aff) => {
            return aff.correctAnswer.id;
        });
        setIndexes(correctAnswers);

        selectCorrectAnswerHandle(correctAnswers);
    };

    return (
        <React.Fragment>
            {!answerIsLoading && (
                <React.Fragment>
                    {playerMode === PlayerV2Modes.ACADEMY && (
                        <Hints question={question} onBuyCorrectAnswer={fillCorrectAnswers} />
                    )}

                    {question && answers && properties && (
                        <ImageSequencePlayer
                            question={question}
                            answers={answers}
                            properties={properties}
                            setAnswers={setAnswers}
                            correctAnswers={correctAnswers}
                            onSelectCorrectAnswer={selectCorrectAnswerHandle}
                            indexes={indexes}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

ImageSequencePlayerContainer.propTypes = {
    getInfoAboutSet: PropTypes.func,
    playerMode: PropTypes.string,
    questionApi: PropTypes.func,
    taskId: PropTypes.number,
    onSelectCorrectAnswer: PropTypes.func,
    answerIsLoading: PropTypes.bool,
    setAnswerIsLoading: PropTypes.func,
};
