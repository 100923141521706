import { Request } from "../../../core/utils/request";
import { IPublication } from "../../../interfaces/IPublication";
import { IPublicationBlockText } from "../../../interfaces/IPublicationBlockText";
import { IPublicationBlockImage } from "../../../interfaces/IPublicationBlockImage";
import { IPublicationAddImage } from "../../../interfaces/IPublicationAddImage";
import { IPublicationBlockGallery } from "../../../interfaces/IPublicationBlockGallery";
import { IPublicationAddGalleryImage } from "../../../interfaces/IPublicationAddGalleryImage";
import { IPublicationBlockPlayer } from "../../../interfaces/IPublicationBlockPlayer";
import { IPublicationBlockFiles } from "../../../interfaces/IPublicationBlockFiles";
import { IPublicationBlockDto } from "../../../interfaces/IPublicationBlockDto";

export const ArticleAPI = {
    getPublicationByUrl: (url: string) => {
        return Request.get(`publication/${url}`)
    },
    createPublication: (publication: IPublication) => {
        return Request.post(`publication`, publication)
    },
    updatePublication: (publication: IPublication) => {
        return Request.put(`publication/${publication.id}`, publication)
    },
    updatePublicationImageOne: (publicationId: number, file: any) => {
        const fileData = new FormData();
        fileData.set("file", file);

        return Request.put(`publication/${publicationId}/image-one?name=imageOne`, fileData)
    },
    updatePublicationImageTwo: (publicationId: number, file: any) => {
        const fileData = new FormData();
        fileData.set("file", file);

        return Request.put(`publication/${publicationId}/image-two?name=imageTwo`, fileData)
    },

    updatePublicationImageThree: (publicationId: number, file: any) => {
        const fileData = new FormData();
        fileData.set("file", file);

        return Request.put(`publication/${publicationId}/image-three?name=imageThree`, fileData)
    },
    deletePublication: (publicationId: number) => {
        return Request.delete(`publication/${publicationId}`)
    },
    getPublications: (page: number) => {
        return Request.get(`publication?page=${page}&size=10&sort=createdOn,desc`)
    },
    deletePublicationBlock: (blockId: number) => {
        return Request.delete(`publication/block/${blockId}`)
    },
    createBlockText: (block: IPublicationBlockText, publicationId: number) => {
        return Request.post(`publication/${publicationId}/block-text`, block)
    },
    updateBlockText: (block: IPublicationBlockText | IPublicationBlockDto) => {
        return Request.put(`publication/block-text/${block.id}`, block)
    },
    updateBlockDocument: (block: IPublicationBlockDto | IPublicationBlockDto) => {
        return Request.put(`publication/block-document/${block.id}`, block)
    },
    createBlockImage: (block: IPublicationBlockImage, publicationId: number) => {
        return Request.post(`publication/${publicationId}/block-image`, block)
    },
    updateBlockImage: (block: IPublicationBlockImage | IPublicationBlockDto, blockId: number) => {
        return Request.put(`publication/block-image/${blockId}`, block)
    },
    updateBlockPlayer: (block: any, blockId: number) => {
        return Request.put(`publication/block-player/${blockId}`, block)
    },
    updateBlockSimilar: (block: any, blockId: number) => {
        return Request.put(`publication/block-similar/${blockId}`, block)
    },
    updateBlockVideoSharing: (block: any, blockId: number) => {
        return Request.put(`publication/block-video-sharing/${blockId}`, block)
    },
    addBlockImage: (block: IPublicationAddImage, blockId: number) => {
        const fileData = new FormData();
        fileData.set("file", block.file);

        return Request.put(`publication/block-image/${blockId}/add-image?name=${block.name}`, fileData)
    },
    createBlockGallery: (block: IPublicationBlockGallery, publicationId: number) => {
        return Request.post(`publication/${publicationId}/block-gallery`, block)
    },
    addImageToGallery: (image: IPublicationAddGalleryImage, blockId: number) => {
        const fileData = new FormData();
        fileData.set("file", image.file);
        return Request.put(`publication/block-gallery/${blockId}/add-image?name=${image.name}&positionGallery=${image.positionGallery}`, fileData)
    },
    updateBlockGallery: (block: IPublicationBlockGallery | IPublicationBlockDto, blockId: number) => {
        return Request.put(`publication/block-gallery/${blockId}`, block)
    },
    updateImageName: (blockId: number, imageId: number, name: string) => {
        return Request.put(`publication/block/${blockId}/image/${imageId}/name?name=${name}`)
    },
    deleteImageFromGallery: (imageId: number) => {
        return Request.delete(`publication/block-gallery/gallery-image/${imageId}`)
    },
    createBlockPlayer: (block: IPublicationBlockPlayer, publicationId: number) => {
        return Request.post(`publication/${publicationId}/block-player`, block)
    },
    addQuestionBlockPlayer: (questionId: number, blockId: number, position: number) => {
        return Request.put(`publication/block-player/${blockId}/add-question?positionQuestion=${position}&questionId=${questionId}`)
    },
    deleteQuestionFromBlockPlayer: (questionId: number) => {
        return Request.delete(`publication/block-player/player-question/${questionId}`)
    },
    createDocumentBlock: (block: IPublicationBlockFiles, blockId: number) => {
        return Request.post(`publication/${blockId}/block-document`, block)
    },
    addFileToFileBlock: (blockId: number, file: File, position: number) => {
        let formData = new FormData();
        formData.append('file', file);
        return Request.put(`publication/block-document/${blockId}/add-file?name=File&positionDocument=${position}`, formData)
    },
    updateFileName: (blockId: number, fileId: number, name: string) => {
        return Request.put(`publication/block/${blockId}/document-file/${fileId}/name?name=${name}`)
    },
    changeBlockPosition: (blockId: number, position: number) => {
        return Request.put(`publication/block/${blockId}/position?positionBlock=${position}`)
    },
    addTags: (publicationId: number, tags: number[]) => {
        return Request.post(`publication/${publicationId}/tags`, tags)
    },
    deleteTag: (publicationId: number, tagId: number) => {
        return Request.delete(`publication/${publicationId}/tags/${tagId}`)
    },
    createBlockSimilar: (block: IPublicationBlockDto, publicationId: number) => {
        return Request.post(`publication/${publicationId}/block-similar`, block)
    },
    createBlockMonogram: (block: IPublicationBlockDto, publicationId: number) => {
        return Request.post(`publication/${publicationId}/block-line`, block)
    },
    createBlockVideoSharing: (block: IPublicationBlockDto, publicationId: number) => {
        return Request.post(`publication/${publicationId}/block-video-sharing`, block)
    },
    addArticleToBlockSimilar: (blockId: number, publications: object) => {
        return Request.put(`publication/block-similar/${blockId}/add-publication`, publications)
    },
    deleteSimilarArticle: (articleId: number) => {
        return Request.delete(`publication/block-similar/similar-pub/${articleId}`)
    },
    deleteFile: (fileId: number) => {
        return Request.delete(`publication/block-document/document-file/${fileId}`)
    },
    searchPublication: (text: string, page: number) => {
        return Request.get(`es/publication/search?page=${page}&size=10&slop=5${text && `&search=${text}`}`)
    },
    getSimilar: (publicationId: number) => {
        return Request.get(`es/publication/${publicationId}/similar`)
    },
    getPopularTags: () => {
        return Request.get(`es/publication/tag-group`)
    },
    getPublicationsByTagId: (tagId: number, page: number) => {
        return Request.get(`publication/by-tags?tags_id=${tagId}&page=${page}&size=10&sort=createdOn,desc`)
    }
}
