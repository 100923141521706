import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import {Button, Form, Input, Space, Table} from "antd";
import {Request} from "../../../../core/utils/request";
import {ColumnsType} from "antd/es/table";
import {IPageable} from "../../../../interfaces/IPageable";
import ModalWrapper from "../../../modals/modal-wrapper/modal-wrapper";
import {useStores} from "../../../../hooks/use-stores";
import {toast} from "react-toastify";
import {IGameItemType} from "../../../../interfaces/IGameItemType";

interface IItemModal {
    handleClose: () => void;
    itemType?: IGameItemType;
    handleRefresh: () => void;
}

const ItemTypeModal = ({handleClose, itemType, handleRefresh}: IItemModal) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [loadedItemType, setLoadedItemType] = useState<any>(undefined);

    useEffect(() => {
        if (!itemType) {
            return;
        }

        setLoadedItemType(itemType);
        form.setFieldsValue(itemType);
    }, [itemType]);

    useEffect(() => {
        handleRefresh();
    }, [loadedItemType]);

    const handleSaveItem = (form: IGameItemType) => {
        setIsLoading(true);

        if (itemType) {
            Request.put(`/game-item-type/${itemType.id}`, form)
                .then(() => {
                    handleRefresh();
                    handleClose();
                })
                .finally(() => setIsLoading(false));
        } else {
            Request.post(`/game-item-type`, form)
                .then((response) => {
                    setLoadedItemType(response.data);
                    handleRefresh();
                    handleClose();
                })
                .finally(() => setIsLoading(false));
        }
    };

    return (
        <ModalWrapper withHeader={true} headerTitle={"Тип предмета"} onClose={handleClose}>
            <Form
                layout="horizontal"
                style={{padding: "20px"}}
                form={form}
                onFinish={handleSaveItem}
            >
                <Form.Item name="name" label="Название">
                    <Input />
                </Form.Item>

                <Button htmlType="submit" type="primary" disabled={isLoading}>
                    Сохранить
                </Button>
            </Form>
        </ModalWrapper>
    );
};

const AdminPanelGameItemType = () => {
    const {appStore} = useStores();
    const [types, setTypes] = useState<IPageable<IGameItemType> | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const [page, setPage] = useState(1);

    useEffect(() => {
        getItemTypes();
    }, [page]);

    const getItemTypes = () => {
        setIsLoading(true);
        Request.get(`/game-item-type?page=${page}`)
            .then((response) => {
                setTypes(response.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDeleteItemType = (id: number) => {
        setIsLoading(true);
        Request.delete(`/game-item-type/${id}`)
            .then(() => {
                toast.success("Тип удален");
                getItemTypes();
            })
            .catch(() => {
                toast.error("Ошибка удаления типа");
            })
            .finally(() => setIsLoading(false));
    };

    const columns: ColumnsType<IGameItemType> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: "Название",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Действия",
            key: "action",
            render: (_: any, record: any) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            appStore.addModalToQueue(
                                <ItemTypeModal
                                    handleClose={() => appStore.closeActiveModal()}
                                    key={"game-item-type-modal"}
                                    itemType={record}
                                    handleRefresh={() => getItemTypes()}
                                />,
                            );
                        }}
                    >
                        <i className="fas fa-pen" style={{color: "orange"}} />
                    </a>
                    <a onClick={() => handleDeleteItemType(record.id)}>
                        <i className="fas fa-trash" style={{color: "red"}} />
                    </a>
                </Space>
            ),
        },
    ];

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.header}>
                    <Button
                        type="primary"
                        onClick={() => {
                            appStore.addModalToQueue(
                                <ItemTypeModal
                                    handleClose={() => appStore.closeActiveModal()}
                                    key={"game-item-type-modal"}
                                    handleRefresh={() => getItemTypes()}
                                />,
                            );
                        }}
                    >
                        Создать тип
                    </Button>
                </div>

                <Table
                    columns={columns}
                    dataSource={types?.content}
                    loading={isLoading}
                    rowKey="id"
                    pagination={{
                        current: types?.page,
                        total: types?.totalElements,
                        onChange: (page) => setPage(page),
                        pageSize: types?.size,
                        hideOnSinglePage: true,
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default AdminPanelGameItemType;
