import React from "react";
import styles from "./styles.module.sass";
import {ENGINES} from "../config/engines";
import cn from "classnames";

const EngineList = ({onClick}: {onClick: (item: any) => void}) => {
    return (
        <div className={styles.engineList}>
            <p className={styles.title}>Выбор режима</p>
            {ENGINES.map((engine, index: number) => (
                <div
                    className={cn({
                        [styles.engineItem]: true,
                        [styles.isDisabled]: engine.isDisabled,
                    })}
                    key={index}
                    onClick={engine.isDisabled ? () => null : () => onClick(engine)}
                >
                    {engine.name}
                </div>
            ))}
        </div>
    );
};

export default EngineList;
