import React, {useState} from "react";
import {SequencesTestAPI} from "entries/constructors/sequences/core/api/sequences";

import styles from "entries/constructors/sequences/sequence-test/views/containers/sequence-test/sequence-test.module.sass";
import PropTypes from "prop-types";

import {Text} from "entries/constructors/classic-test/views/components/text-answer";
import {SpinnerPage} from "views/components/spinner";
import {EditButtons} from "views/components/edit-buttons";
import {TextUploadModal} from "views/components/text-upload-modal/text-upload-modal";
import {AddTextAnswerButton} from "views/components/add-text-answer-button";
import {DraggableItem, DraggableList} from "views/components/DraggableList";
import cn from "classnames";

export const CreateTextSequencesAnswers = ({
    updateAnswers,
    textModalVisible,
    setTextModalVisible,
    questionId,
    answers,
    isEditMode,
    setAnswers,
    textAnswerFontSize,
    maxAnswersCount,
    onDeleteAnswer,
}) => {
    const [isAnswerLoading, setIsAnswerLoading] = useState(false);

    const handleSave = (answer) => {
        const trimmedAnswer = answer.trim();
        if (trimmedAnswer) {
            setIsAnswerLoading(true);
            SequencesTestAPI.createTextAnswer(trimmedAnswer, questionId)
                .then((response) => {
                    updateAnswers({id: response.data.id, text: trimmedAnswer});
                })
                .catch((error) => console.log(error))
                .finally(() => setIsAnswerLoading(false));
        }
    };

    const handleDelete = (answerId) => {
        setIsAnswerLoading(true);
        SequencesTestAPI.deleteAnswer(questionId, answerId)
            .then(() => onDeleteAnswer(answerId))
            .catch((error) => console.log(error))
            .finally(() => setIsAnswerLoading(false));
    };

    return (
        <>
            <TextUploadModal
                visible={textModalVisible}
                toggle={setTextModalVisible}
                maxSymbols={100}
                saveText={handleSave}
            />

            {isAnswerLoading ? (
                <SpinnerPage primary />
            ) : (
                <>
                    <DraggableList
                        onSort={setAnswers}
                        items={answers}
                        className={cn({
                            [styles.answerContainer]: true,
                            noselect: true,
                        })}
                        pressDelay={0}
                    >
                        {answers.map((answer, index) => (
                            <DraggableItem
                                key={`item-${answer.id}`}
                                id={`item-${answer.id}`}
                                index={index}
                            >
                                <div className={styles.textAnswerContainer}>
                                    {isEditMode && (
                                        <EditButtons
                                            itemId={answer.id}
                                            deleteAction={handleDelete}
                                        />
                                    )}

                                    <Text
                                        text={answer.text}
                                        withBorder={isEditMode}
                                        style={{fontSize: textAnswerFontSize}}
                                    />
                                </div>
                            </DraggableItem>
                        ))}
                    </DraggableList>

                    {isEditMode && answers.length < maxAnswersCount && (
                        <AddTextAnswerButton handleClick={setTextModalVisible} />
                    )}
                </>
            )}
        </>
    );
};

CreateTextSequencesAnswers.propTypes = {
    answers: PropTypes.array,
    apiInstance: PropTypes.object,
    isEditMode: PropTypes.bool,
    maxAnswersCount: PropTypes.number,
    questionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    setAnswers: PropTypes.func,
    setTextModalVisible: PropTypes.func,
    textAnswerFontSize: PropTypes.number,
    textModalVisible: PropTypes.bool,
    updateAnswers: PropTypes.func,
    onDeleteAnswer: PropTypes.func,
};
