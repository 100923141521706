import React from "react";
import styles from "./styles.module.sass";
import InfiniteScroll from "react-infinite-scroll-component";
import TournamentParticipantItem from "../../tournament/views/tournament-participants/tournament-participant-item";
import {url} from "../../../config";
import noLogo from "assets/images/static/no-logo.svg";
import {getFullName} from "../../../core/utils/helpers";

interface IProps {
    ratings: any;
    handleLoadMore: () => void;
    filter: string;
}

const RatingsList = ({ratings, handleLoadMore, filter}: IProps) => {
    return (
        <React.Fragment>
            <InfiniteScroll
                next={handleLoadMore}
                hasMore={!ratings?.last}
                loader={
                    <div className="text-info mb-5 text-center" style={{fontSize: "1.5rem"}}>
                        Загрузка рейтингов...
                        <div className="spinner-border text-info ml-2" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                dataLength={ratings?.content?.length || 0}
            >
                <div className={styles.cards}>
                    {filter === "TOURNAMENT" || filter === "ACADEMY" ? (
                        <React.Fragment>
                            {ratings?.content?.map((rating: any) => {
                                return (
                                    <div key={rating.school.id} className={styles.schoolItem}>
                                        <p className={styles.schoolItemPlace}>{rating.place}</p>
                                        <p className={styles.schoolItemRating}>
                                            <i className="fa fa-star" aria-hidden="true" />
                                            {rating.rating}
                                        </p>

                                        <div>
                                            <p>
                                                {rating.school?.alternateName === "TEMP"
                                                    ? "Школа не указана"
                                                    : rating.school?.alternateName || ""}
                                            </p>
                                            <p>
                                                {rating.school.city?.altName ===
                                                rating.school.region?.altName ? (
                                                    rating.school?.city?.altName === "TEMP" ? (
                                                        "Город не указан"
                                                    ) : (
                                                        rating.school?.city?.altName || ""
                                                    )
                                                ) : (
                                                    <React.Fragment>
                                                        {rating.school?.region?.altName
                                                            ? rating.school?.region?.altName
                                                            : "Регион не указан"}
                                                        ,{" "}
                                                        {rating.school?.city?.altName === "TEMP"
                                                            ? "Город не указан"
                                                            : rating.school?.city?.altName || ""}
                                                    </React.Fragment>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {ratings?.content?.map((rating: any) => {
                                return (
                                    <TournamentParticipantItem
                                        rating={
                                            rating.rating ||
                                            rating.userPlace.rating ||
                                            rating.userPlaceAcademy.rating ||
                                            0
                                        }
                                        place={
                                            rating.place ||
                                            rating.userPlace.place ||
                                            rating.userPlaceAcademy.place ||
                                            0
                                        }
                                        key={rating.user.id}
                                        id={rating.user.id}
                                        avatar={
                                            rating.avatarImageBank
                                                ? url +
                                                  rating.avatarImageBank.fileDownloadUri.slice(1)
                                                : noLogo
                                        }
                                        city={rating.city?.altName}
                                        school={
                                            rating.school?.alternateName === "TEMP"
                                                ? "Школа не указана"
                                                : rating.school?.alternateName || ""
                                        }
                                        userName={getFullName(
                                            rating.user.firstName,
                                            rating.user.lastName,
                                        )}
                                    />
                                );
                            })}
                        </React.Fragment>
                    )}
                </div>
            </InfiniteScroll>
        </React.Fragment>
    );
};

export default RatingsList;
