import PropTypes from "prop-types";
import React from "react";
import {EditButtons} from "views/components/edit-buttons";

import styles from "entries/constructors/classic-test/views/components/formula-answer/formula-answer.module.sass";

export const FormulaAnswer = (props) => {
    const {
        answers,
        setFormulaModalVisible,
        isEditMode,
        deleteAnswer,
        updateAnswerType,
        isSelectCorrectAnswerMode,
        correctAnswer,
    } = props;

    return (
        <div className={styles.container}>
            {answers.map((answer) => (
                <div
                    key={answer.id}
                    className={styles.editWrapper}
                    onClick={() => isSelectCorrectAnswerMode && correctAnswer(answer.id)}
                >
                    {isEditMode && <EditButtons deleteAction={() => deleteAnswer(answer.id)} />}
                    <div
                        className={
                            answer.isCorrect ? styles.correctAnswerImage : styles.answerBackground
                        }
                    >
                        {answer.component}
                    </div>
                </div>
            ))}

            {answers.length < 6 && isEditMode && (
                <div className={styles.editWrapper}>
                    {answers.length === 0 && isEditMode && (
                        <EditButtons deleteAction={() => updateAnswerType("")} />
                    )}
                    <div
                        className={styles.formulaButton}
                        onClick={() => setFormulaModalVisible(true)}
                    >
                        <p>Добавить формулу</p>
                    </div>
                </div>
            )}
        </div>
    );
};

FormulaAnswer.propTypes = {
    answers: PropTypes.arrayOf(PropTypes.any),
    correctAnswer: PropTypes.func,
    deleteAnswer: PropTypes.func,
    isEditMode: PropTypes.bool,
    isSelectCorrectAnswerMode: PropTypes.bool,
    setFormulaModalVisible: PropTypes.func,
    updateAnswerType: PropTypes.func,
};
