import {TOAST_ERROR_CONFIG, url} from "config";
import {DICTATION_TEST} from "config/constructor-names";
import {NARRATORS} from "entries/constructors/classic-test/views/components/properties-buttons";
import {CreateQuestion} from "entries/constructors/classic-test/views/containers/create-question";
import {TestParameters} from "entries/constructors/classic-test/views/containers/test-parameters";
import {DictationAPI} from "entries/constructors/dictation/core/api/dictation";
import {insert} from "entries/constructors/dictation/utils/helpers";
import {ModalDictationSkipsContainer} from "entries/constructors/dictation/views/containers/modal-dictation-skips/modal-dictation-skips-container";
import {TextBoxWithSymbolSkipsContainer} from "entries/constructors/dictation/views/containers/text-box-with-symbol-skips/text-box-with-symbol-skips-container";
import {useConstructor} from "entries/constructors/hooks/useConstructor";
import {MDBAlert, MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {AddContentBox} from "views/components/add-content-box/add-content-box";
import {ConstructorNavbar} from "views/components/constructor-navbar";

import {Col} from "views/components/layout/col";
import {SaveContent} from "views/components/layout/save-content/save-content";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {TestOptions} from "views/components/layout/test-options";
import {SpinnerPage} from "views/components/spinner";
import {TextUploadModal} from "views/components/text-upload-modal/text-upload-modal";
import {PropertyColumn} from "views/containers/property-column";
import TestSave from "views/containers/test-save/test-save";
import {useWizard} from "views2/conatiners/CreateTestWizard";

export let DICTATION_SKIP_ID = "_";
export let DICTATION_EMPTY_SKIP = "~";

export let DICTATION_SKIP_DETECT = "#";
export let DICTATION_EMPTY_DETECT = "|";

export const DictationPage = ({testData, isEditMode = false}) => {
    const {
        testId,
        questionText,
        setQuestionText,
        questionAdditionalText,
        setQuestionAdditionalText,
        background,
        setBackground,
        narrator,
        setNarrator,
        textTitleSize,
        setTextTitleSize,
        currentPropertyTab,
        setCurrentPropertyTab,
        tabNames,
        questionType,
    } = useConstructor({
        isEditMode,
        constructorName: DICTATION_TEST,
        testData,
    });

    const [questionTextModalIsOpen, setQuestionTextModalIsOpen] = useState(false);
    const [, setQuestionIsLoading] = useState(false);
    const [originalText, setOriginalText] = useState("");
    const [textUploadModalIsOpen, setTextUploadModalIsOpen] = useState(false);
    const [textIsLoading, setTextIsLoading] = useState(false);
    const [skipsModalIsOpen, setSkipsModalIsOpen] = useState(false);
    const [answersForSave, setAnswersForSave] = useState(null);
    const [, setTextForSave] = useState(null);
    const [isCreateNew, setIsCreateNew] = useState(false);

    const {
        STEP,
        stepsOrder,
        currentStep,
        to,
        toNext,
        enableStep,
        disableStep,
        enabledSteps,
        enableSteps,
    } = useWizard();

    // Пропуски
    const [skips, setSkips] = useState([]);
    const isDevelopment = process.env.NODE_ENV === "development";

    useEffect(() => {
        if (isEditMode && !isCreateNew) {
            enableSteps([STEP.ANSWER, STEP.QUESTION, STEP.PARAMS]);
        } else {
            if (questionText && skips.length > 0 && narrator) {
                enableStep(STEP.ANSWER);
            } else {
                disableStep(STEP.ANSWER);
            }
        }
    }, [questionText, skips, narrator, isCreateNew, isEditMode]);

    const onSelectNarrator = (narrator) => {
        setNarrator({
            id: narrator.id,
            url: `${url}${narrator.url}`,
        });
    };

    const onSaveQuestion = (text) => {
        if (text.trim()) {
            setQuestionIsLoading(true);
            DictationAPI.editQuestion(testId, {title: testData.title, text})
                .catch((error) => {
                    toast.error("Произошла ошибка", TOAST_ERROR_CONFIG);
                    console.log(error);
                })
                .finally(() => setQuestionIsLoading(false));
        }
        setQuestionText(text);
        setQuestionTextModalIsOpen(false);
    };

    const onSaveText = (text) => {
        setQuestionAdditionalText(text);
        setOriginalText(text);
        setSkips([]);
        setTextUploadModalIsOpen(false);
    };

    const handleDeleteText = () => {
        setQuestionAdditionalText("");
        setOriginalText("");
    };

    const saveAnswersOnServer = (positionAndSymbolGroup) => {
        setTextIsLoading(true);
        DictationAPI.deleteAnswers(testId).then(() => {
            DictationAPI.addAnswer(testId, positionAndSymbolGroup)
                .catch(() => isDevelopment && console.log())
                .finally(() => setTextIsLoading(false));
        });
    };

    const handleSaveSkip = () => {
        setSkipsModalIsOpen(false);

        let editedText = questionAdditionalText
            .split(" ")
            .map((word) => word.replace(DICTATION_EMPTY_DETECT, DICTATION_SKIP_DETECT));
        let globalPosition = 0;
        let correctAnswers = [];
        let positionAndSymbolGroup = {};
        for (let i = 0; i <= editedText.length - 1; i++) {
            for (let j = 0; j <= editedText[i].length - 1; j++) {
                let word = editedText[i];
                let symbol = word[j];
                if (symbol === DICTATION_SKIP_DETECT) {
                    let res = skips.filter(
                        (skip) => skip.wordIndex === i && skip.symbolIndex === j,
                    );
                    positionAndSymbolGroup[globalPosition] = res[0].symbolGroup.id;
                    // TODO: Добавить правила для пропуска
                    correctAnswers.push({
                        languageRuleId: 1,
                        position: globalPosition,
                        symbolId: res[0].symbolId,
                    });
                }
                globalPosition++;
            }
            globalPosition++;
        }
        setTextForSave(editedText.join(" "));

        setTextIsLoading(true);
        DictationAPI.editQuestion(testId, {
            title: testData.title,
            questionText,
            text: editedText.join(" "),
        })
            .catch(() => console.log("error save edited text"))
            .finally(() => setTextIsLoading(false));

        setAnswersForSave(correctAnswers);
        saveAnswersOnServer(positionAndSymbolGroup);
    };

    const handleSaveVariant = (symbolId, symbolGroup, wordIndex, symbolIndex, selectedVariant) => {
        let skipForSave = {symbolId, symbolGroup, wordIndex, symbolIndex};

        let existSkips = skips.filter(
            (skip) => skip.wordIndex === wordIndex && skip.symbolIndex === symbolIndex,
        );

        if (existSkips.length > 0) {
            return;
        }

        let cloneText = questionAdditionalText;
        let splittedText = cloneText.split(" ");
        let word = splittedText[wordIndex].split("");
        if (selectedVariant[symbolIndex] === DICTATION_EMPTY_SKIP) {
            word = insert(word, symbolIndex, DICTATION_EMPTY_DETECT);
        } else {
            word[symbolIndex] = DICTATION_SKIP_DETECT;
        }

        splittedText[wordIndex] = word.join("");
        cloneText = splittedText.join(" ");
        setQuestionAdditionalText(cloneText);

        setSkips([...skips, skipForSave]);
    };

    const handleDeleteSkip = (skipForDelete) => {
        let cloneTextSplitted = questionAdditionalText.split(" ");
        let word = cloneTextSplitted[skipForDelete.wordIndex].split("");
        let symbol = word[skipForDelete.symbolIndex];
        if (symbol === DICTATION_EMPTY_DETECT) {
            delete word[skipForDelete.symbolIndex];
        } else {
            // получаем текст без изменений(оригинальный)
            let originalTextCloneSplitted = originalText.split(" ");
            let originalWordSplitted = originalTextCloneSplitted[skipForDelete.wordIndex].split("");
            let originalSymbol = originalWordSplitted[skipForDelete.symbolIndex];
            word[skipForDelete.symbolIndex] = originalSymbol;
        }
        word = word.join("");
        cloneTextSplitted[skipForDelete.wordIndex] = word;
        cloneTextSplitted = cloneTextSplitted.join(" ");
        setQuestionAdditionalText(cloneTextSplitted);

        let cloneSkipsWithoutSkipForDelete = skips.filter((skip) => skip !== skipForDelete);
        setSkips(cloneSkipsWithoutSkipForDelete);
    };

    return (
        <div>
            <ConstructorNavbar
                tabs={stepsOrder}
                currentTab={currentStep}
                availableTabs={enabledSteps}
                onSelect={(tab) => to(tab)}
                tabNames={tabNames}
            />

            <Col size={12}>
                {currentStep === STEP.QUESTION || currentStep === STEP.ANSWER ? (
                    <Col size={8} className="d-flex justify-content-start">
                        <TestContent background={background}>
                            <TestConstructor>
                                <TextUploadModal
                                    saveText={onSaveQuestion}
                                    visible={questionTextModalIsOpen}
                                    toggle={() =>
                                        setQuestionTextModalIsOpen(!questionTextModalIsOpen)
                                    }
                                    maxSymbols={100}
                                    questionText={questionText}
                                />

                                <TextUploadModal
                                    saveText={onSaveText}
                                    visible={textUploadModalIsOpen}
                                    toggle={() => setTextUploadModalIsOpen(!textUploadModalIsOpen)}
                                    maxSymbols={1000}
                                    questionText={questionAdditionalText}
                                    isLoading={textIsLoading}
                                />

                                <ModalDictationSkipsContainer
                                    text={questionAdditionalText}
                                    skips={skips}
                                    isOpen={skipsModalIsOpen}
                                    setIsOpen={setSkipsModalIsOpen}
                                    handleSaveVariant={handleSaveVariant}
                                    handleSaveSkip={handleSaveSkip}
                                    handleDeleteSkip={handleDeleteSkip}
                                />

                                <CreateQuestion
                                    isEditMode={currentStep === STEP.QUESTION}
                                    testHasNarrator
                                    narrator={narrator}
                                    questionId={testId}
                                    questionTitle={testData.title}
                                    textTitleSize={textTitleSize}
                                    questionImage={{}}
                                    questionText={questionText}
                                    updateQuestionImage={() => void 0}
                                    updateQuestionText={(text) => setQuestionText(text)}
                                    updateQuestionFormula={() => void 0}
                                    selectPropertyNarrator={() => setCurrentPropertyTab(NARRATORS)}
                                    apiInstance={DictationAPI}
                                    withEditButtons={false}
                                />

                                {isEditMode && !isCreateNew && (
                                    <React.Fragment>
                                        <TextBoxWithSymbolSkipsContainer
                                            skipSymbols={[
                                                DICTATION_EMPTY_DETECT,
                                                DICTATION_SKIP_DETECT,
                                            ]}
                                            text={questionAdditionalText}
                                            isEditMode={false}
                                        />

                                        {currentStep === STEP.QUESTION && (
                                            <MDBAlert color="warning" className="mt-3">
                                                <p>
                                                    Редактирование ответа для этого задания
                                                    невозможно. Если вы хотите внести изменения, то
                                                    необходимо заново внести текст и расставить
                                                    пропуски.
                                                </p>
                                                <MDBBtn
                                                    color="info"
                                                    block
                                                    className="mt-2"
                                                    onClick={() => {
                                                        setQuestionAdditionalText("");
                                                        setIsCreateNew(true);
                                                    }}
                                                >
                                                    Создать заново
                                                </MDBBtn>
                                            </MDBAlert>
                                        )}
                                    </React.Fragment>
                                )}

                                {textIsLoading ? (
                                    <SpinnerPage primary />
                                ) : (
                                    <React.Fragment>
                                        {(isCreateNew || !isEditMode) && (
                                            <React.Fragment>
                                                {questionAdditionalText.trim() ? (
                                                    <TextBoxWithSymbolSkipsContainer
                                                        skipSymbols={[
                                                            DICTATION_EMPTY_DETECT,
                                                            DICTATION_SKIP_DETECT,
                                                        ]}
                                                        editAction={
                                                            skips.length
                                                                ? () => setSkipsModalIsOpen(true)
                                                                : () =>
                                                                      setTextUploadModalIsOpen(true)
                                                        }
                                                        isEditMode={currentStep === STEP.QUESTION}
                                                        deleteAction={handleDeleteText}
                                                        text={questionAdditionalText}
                                                    />
                                                ) : (
                                                    <AddContentBox
                                                        onClick={() =>
                                                            setTextUploadModalIsOpen(true)
                                                        }
                                                        text={"Добавьте текст диктанта"}
                                                    />
                                                )}

                                                {questionAdditionalText &&
                                                    skips &&
                                                    skips.length === 0 && (
                                                        <AddContentBox
                                                            onClick={() =>
                                                                setSkipsModalIsOpen(true)
                                                            }
                                                            text={"Добавьте пропуски в текст"}
                                                        />
                                                    )}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </TestConstructor>
                        </TestContent>
                    </Col>
                ) : (
                    <TestParameters
                        testId={testId}
                        apiInstance={DictationAPI}
                        mode={questionType}
                        isEditMode={isEditMode}
                        withEditMessage
                    />
                )}
                {(currentStep === STEP.QUESTION || currentStep === STEP.ANSWER) && (
                    <Col size={4}>
                        <TestOptions>
                            {currentStep === STEP.QUESTION && (
                                <PropertyColumn
                                    currentPropertyTab={currentPropertyTab}
                                    onSelectTab={(tab) => setCurrentPropertyTab(tab)}
                                    selectImage={(background) => setBackground(background)}
                                    selectNarrator={onSelectNarrator}
                                    textTitleSize={textTitleSize}
                                    setTextTitleSize={(size) =>
                                        setTextTitleSize(Number.parseInt(size))
                                    }
                                    isQuestionTextExist={!!questionText}
                                />
                            )}

                            {currentStep === STEP.ANSWER && (
                                <SaveContent>
                                    <h5>Проверьте диктант</h5>
                                    <p>Если диктант составлен верно нажмите на кнопку сохранить</p>
                                    <TestSave
                                        apiInstance={DictationAPI}
                                        questionId={testId}
                                        answersForSave={answersForSave}
                                        backgroundId={background.id}
                                        isTwoColumnMode={false}
                                        narratorId={narrator.id}
                                        sizeTitle={textTitleSize}
                                        constructorName={DICTATION_TEST}
                                        onSaveProperties={() => toNext()}
                                        mode={questionType}
                                    />
                                </SaveContent>
                            )}
                        </TestOptions>
                    </Col>
                )}
            </Col>
        </div>
    );
};

DictationPage.propTypes = {
    isEditMode: PropTypes.bool,
    testData: PropTypes.object,
};
