import React, {useEffect} from "react";
import styles from "./styles.module.sass";

const BlackoutLayer = ({children}) => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll";
        };
    }, []);

    return <div className={styles.blackout}>{children}</div>;
};

BlackoutLayer.propTypes = {};

export default BlackoutLayer;
