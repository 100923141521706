import {ADMIN_PANEL_ROUTES} from "./routes";
import {EColors} from "./EColors";

export const ADMIN_PANEL_MENU_ITEMS = [
    {type: "DIVIDER", name: "Общее"},
    {
        path: ADMIN_PANEL_ROUTES.DASHBOARD.ROOT,
        name: "Панель",
        color: EColors.PURPLE,
        icon: "fa-solid fa-chart-line",
    },

    {type: "DIVIDER", name: "Контент сайта"},
    {
        path: ADMIN_PANEL_ROUTES.ARTICLES.ROOT,
        name: "Статьи",
        color: EColors.ORANGE,
        icon: "fa-solid fa-newspaper",
    },
    {
        path: ADMIN_PANEL_ROUTES.NEWS.ROOT,
        name: "Новости",
        color: EColors.SKY_BLUE,
        icon: "fa-solid fa-newspaper",
    },
    {
        path: ADMIN_PANEL_ROUTES.BANNERS.ROOT,
        name: "Баннеры",
        color: EColors.GREEN,
        icon: "fa-solid fa-ad",
    },
    {
        path: ADMIN_PANEL_ROUTES.TASKS.LIST,
        name: "Задания",
        color: EColors.PURPLE,
        icon: "fa-solid fa-question",
    },

    {type: "DIVIDER", name: "Контроллеры"},
    {
        path: ADMIN_PANEL_ROUTES.USERS.ROOT,
        name: "Пользователи",
        color: EColors.BLUE,
        icon: "fa-solid fa-users",
    },
    {
        path: ADMIN_PANEL_ROUTES.SUBSCRIBES.ROOT,
        name: "Подписки",
        color: EColors.SKY_BLUE,
        icon: "fa-solid fa-credit-card",
    },
    {
        path: ADMIN_PANEL_ROUTES.SCHOOLS.ROOT,
        name: "Школы",
        color: EColors.PURPLE,
        icon: "fa-solid fa-school",
    },
    {
        path: ADMIN_PANEL_ROUTES.TAGS.ROOT,
        name: "Теги",
        color: EColors.BLUE,
        icon: "fa-solid fa-tags",
    },
    {
        path: ADMIN_PANEL_ROUTES.IMAGES.ROOT,
        name: "Изображения",
        color: EColors.GREEN,
        icon: "fa-solid fa-image",
    },
    {
        path: ADMIN_PANEL_ROUTES.CONSTRUCTORS.ROOT,
        name: "Конструкторы",
        color: EColors.PURPLE,
        icon: "fa-solid fa-question",
    },
    {
        path: ADMIN_PANEL_ROUTES.SYSTEM.ROOT,
        name: "Система",
        color: EColors.SKY_BLUE,
        icon: "fa-solid fa-question",
    },
    {
        path: ADMIN_PANEL_ROUTES.GAME.ROOT,
        name: "Игра",
        color: EColors.SKY_BLUE,
        icon: "fa-solid fa-gamepad",
    },
];
