import Keycloak from "keycloak-js";
import {AUTH_CONFIG} from "../config/auth";

export const auth = (() => {
    const kc = new Keycloak({
        url: AUTH_CONFIG.URL,
        sslRequired: true,
        realm: "tournament",
        clientId: AUTH_CONFIG.CLIENT_ID,
    });

    function init() {
        return kc.init({onLoad: "check-sso"})
    }

    kc.onTokenExpired = () => {
        console.log("[AUTH] Token expired");
        // kc.logout();
    };

    return {
        init,
        keycloak: kc,
    };
})();

window.auth = auth;
