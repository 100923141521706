export const difficulties = [
    {id: 1, level: "EASY", name: "Простое"},
    {id: 2, level: "MEDIUM", name: "Средней сложности"},
    {id: 3, level: "HARD", name: "Сложное"},
    {id: 4, level: "OLYMPIAD", name: "Олимпиадное"},
];

export const difficultiesOptions = [
    {value: null, name: "Любое"},
    {value: "EASY", name: "Простое"},
    {value: "MEDIUM", name: "Средней сложности"},
    {value: "HARD", name: "Сложное"},
    {value: "OLYMPIAD", name: "Олимпиадное"},
];

export const timeData = [
    {id: 1, time: 1e4, name: "10 сек"},
    {id: 2, time: 3e4, name: "30 сек"},
    {id: 3, time: 6e4, name: "1 мин"},
    {id: 4, time: 12e4, name: "2 мин"},
    {id: 5, time: 3e5, name: "5 мин"},
    {id: 6, time: 6e5, name: "10 мин"},
];

export const timeDataOptions = [
    {value: null, name: "Любое"},
    {value: 1e4, name: "10 сек"},
    {value: 3e4, name: "30 сек"},
    {value: 6e4, name: "1 мин"},
    {value: 12e4, name: "2 мин"},
    {value: 3e5, name: "5 мин"},
    {value: 6e5, name: "10 мин"},
];

export const timeDataSelectOptions = [
    {value: 1e4, label: "10 сек"},
    {value: 3e4, label: "30 сек"},
    {value: 6e4, label: "1 мин"},
    {value: 12e4, label: "2 мин"},
    {value: 3e5, label: "5 мин"},
    {value: 6e5, label: "10 мин"},
];
