import React from "react";
import {Button, Checkbox, Form, FormInstance, Input, Modal, Select} from "antd";
import {Banner} from "../../entities/banner";

export type Props = {
    isOpen: boolean;
    handleSave: (banner: Banner) => void;
    handleClose: () => void;
    bannerForUpdate: Banner | undefined;
    form: FormInstance<Banner>;
};

const {TextArea} = Input;

export const BannerModal = ({isOpen, handleSave, handleClose, bannerForUpdate, form}: Props) => {
    return (
        <Modal
            title="Баннер"
            open={isOpen}
            cancelButtonProps={{
                className: "mr-2",
            }}
            footer={null}
            onCancel={handleClose}
        >
            <Form
                name="banner-form"
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 600}}
                initialValues={bannerForUpdate}
                onFinish={handleSave}
                autoComplete="off"
                form={form}
            >
                <Form.Item<Banner> label="Контент" name="content">
                    <TextArea rows={6} />
                </Form.Item>

                <Form.Item<Banner> label="Позиция" name="position">
                    <Select
                        options={[
                            {value: "BANNER_HEADER", label: "BANNER_HEADER"},
                            {value: "BANNER_FOOTER", label: "BANNER_FOOTER"},
                            {value: "BANNER_ASIDE_STICKER", label: "BANNER_ASIDE_STICKER"},
                            {value: "BANNER_ASIDE_TOP", label: "BANNER_ASIDE_TOP"},
                            {value: "BANNER_ASIDE_MIDDLE", label: "BANNER_ASIDE_MIDDLE"},
                            {value: "BANNER_CONTENT_BOTTOM", label: "BANNER_CONTENT_BOTTOM"},
                            {value: "BANNER_MOBILE_FULLSCREEN", label: "BANNER_MOBILE_FULLSCREEN"},
                            {value: "BANNER_MOBILE_BOTTOM", label: "BANNER_MOBILE_BOTTOM"},
                            {value: "OUR_ASIDE_DIGEST", label: "OUR_ASIDE_DIGEST"},
                            {value: "OUR_BRANDING", label: "OUR_BRANDING"},
                            {value: "OUR_FULLSCREEN", label: "OUR_FULLSCREEN"},
                            {value: "BANNER_FEED", label: "BANNER_FEED"},
                        ]}
                    />
                </Form.Item>

                <Form.Item<Banner>
                    name="isActive"
                    valuePropName="checked"
                    wrapperCol={{offset: 8, span: 16}}
                >
                    <Checkbox>Активен</Checkbox>
                </Form.Item>

                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit" className="mr-2">
                        Сохранить
                    </Button>

                    <Button type="default" onClick={handleClose}>
                        Отмена
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
