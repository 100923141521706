import {Button, Input} from "antd";
import React, {useState} from "react";
import styles from "./styles.module.sass";

const EngineCreate = () => {
    const [value, setValue] = useState("");
    const [status, setStatus] = useState<"error" | "warning" | undefined>(undefined);

    const handleClick = () => {
        if (!value.trim()) {
            setStatus("error");
            return;
        } else {
            setStatus(undefined);
        }
    };

    return (
        <div className={styles.content}>
            <Input
                placeholder="Задание про птиц №2"
                size={"large"}
                value={value}
                status={status}
                onChange={(e) => setValue(e.target.value)}
                onPressEnter={handleClick}
                maxLength={30}
                showCount
                className={styles.textInput}
            />
            <Button size={"large"} type="primary" onClick={handleClick}>
                Далее
            </Button>
        </div>
    );
};

export default EngineCreate;
