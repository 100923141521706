import cn from "classnames";
import {TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG} from "config";

import {AddQuestionButtons} from "entries/constructors/classic-test/views/components/add-question-buttons";

import styles from "entries/constructors/classic-test/views/containers/create-question/create-question.module.sass";
import UploadAudioModalContainer from "entries/modals/upload-audio-modal/upload-audio-modal-container";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {ConvertBankImageFromResponse} from "utils/convert-response-data";
import {formulaToBlob} from "utils/save-utils";
import {FormulaUploadModal} from "views/components/formula-upload-modal";
import {ImageUploadModal} from "views/components/image-upload-modal/image-upload-modal";
import {Question} from "views/components/question";
import {TextUploadModal} from "views/components/text-upload-modal/text-upload-modal";
import {QuestionAPI} from "core/api/question";

export const CreateQuestion = (props) => {
    const {
        apiInstance,
        questionId,
        questionTitle,
        questionText,
        questionImage,
        questionFormula,
        questionAudio,
        updateQuestionFormula,
        updateQuestionImage,
        updateQuestionText,
        updateQuestionAudio,
        narrator,
        testHasNarrator,
        selectPropertyNarrator,
        isEditMode,
        withEditButtons = true,
        textTitleSize,
        withoutBottomMargin,
        withoutBorder,
        questionRef,
    } = props;

    // Видимость кнопок для ввода данных в конструкторе вопроса
    const [textVisible, setTextVisible] = useState(true);
    const [imageVisible, setImageVisible] = useState(true);
    const [formulaVisible, setFormulaVisible] = useState(false);
    const [audioVisible, setAudioVisible] = useState(true);

    const [textModalVisible, setTextModalVisible] = useState(false);
    const [imageModalVisible, setImageModalVisible] = useState(false);
    const [formulaModalVisible, setFormulaModalVisible] = useState(false);
    const [audioModalVisible, setAudioModalVisible] = useState(false);

    const [isTextLoading, setIsTextLoading] = useState(false);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [isFormulaLoading, setIsFormulaLoading] = useState(false);
    const [isAudioLoading, setIsAudioLoading] = useState(false);

    const [formulaId, setFormulaId] = useState(null);

    useEffect(() => {
        questionText && setTextVisible(false);
        questionImage && Object.keys(questionImage).length > 0 && setImageVisible(false);
        questionFormula && Object.keys(questionFormula).length > 0 && setFormulaVisible(false);
        questionAudio && Object.keys(questionAudio).length > 0 && setAudioVisible(false);
    }, [questionText, questionFormula, questionImage, questionAudio]);

    const saveText = (text) => {
        setIsTextLoading(true);
        apiInstance
            .editQuestion(questionId, {title: questionTitle, questionText: `${text}`})
            .then(() => {
                updateQuestionText(text);
                setTextVisible(false);
                setTextModalVisible(false);
            })
            .catch(() => {
                toast.error("Ошибка сохранения текста", TOAST_ERROR_CONFIG);
                setTextVisible(true);
            })
            .finally(() => setIsTextLoading(false));
    };

    const deleteText = () => {
        setIsTextLoading(true);
        apiInstance
            .editQuestion(questionId, {title: questionTitle, text: ""})
            .then(() => {
                updateQuestionText("");
                setTextVisible(true);
                setTextModalVisible(false);
            })
            .catch(() => {
                toast.error("Ошибка удаления текста", TOAST_ERROR_CONFIG);
                setTextVisible(true);
            })
            .finally(() => setIsTextLoading(false));
    };

    const saveImage = (imageData) => {
        let cropArea = imageData.areaForCrop;
        setIsImageLoading(true);
        apiInstance
            .addImageForQuestion(
                questionId,
                imageData.image,
                cropArea.height,
                cropArea.width,
                cropArea.x,
                cropArea.y,
            )
            .then((response) => {
                let convertedImage = ConvertBankImageFromResponse(response.data, response.data.id);
                updateQuestionImage({id: convertedImage.id, url: convertedImage.url});
                setImageVisible(false);
                setImageModalVisible(false);
                toast.success("Картинка загружена", TOAST_SUCCESS_CONFIG);
            })
            .finally(() => setIsImageLoading(false));
    };

    const deleteImage = () => {
        setIsImageLoading(true);
        apiInstance
            .deleteQuestionImage(questionId, questionImage.id)
            .then(() => {
                updateQuestionImage({});
                setImageVisible(true);
            })
            .catch((error) => {
                toast.error("Ошибка удаления изображения", TOAST_ERROR_CONFIG);
                console.log(error);
            })
            .finally(() => setIsImageLoading(false));
    };

    const deleteFormula = () => {
        setIsFormulaLoading(true);
        if (formulaId) {
            apiInstance
                .deleteQuestionFormula(questionId, formulaId)
                .then(() => {
                    setFormulaId(null);
                    updateQuestionFormula("");
                    setFormulaVisible(true);
                })
                .catch((error) => {
                    toast.error("Ошибка удаления формулы", TOAST_ERROR_CONFIG);
                    console.log(error);
                })
                .finally(() => setIsFormulaLoading(false));
        }
    };

    const saveFormula = (formula) => {
        setIsFormulaLoading(true);
        apiInstance
            .addFormulaForQuestion(questionId, formulaToBlob(formula))
            .then((response) => {
                setFormulaId(response.data.id);
                setFormulaVisible(false);
                setFormulaModalVisible(false);
                updateQuestionFormula(formula);
            })
            .catch((error) => {
                toast.error("Ошибка сохранения формулы", TOAST_ERROR_CONFIG);
                console.log(error);
                setFormulaVisible(true);
            })
            .finally(() => setIsFormulaLoading(false));
    };

    const saveAudio = (audioFile) => {
        setAudioModalVisible(false);
        setIsAudioLoading(true);
        QuestionAPI.addAudioForQuestion(questionId, audioFile)
            .then((response) => updateQuestionAudio(response.data))
            .finally(() => setIsAudioLoading(false));
    };

    const deleteAudio = () => {
        setIsAudioLoading(true);
        QuestionAPI.deleteAudioForQuestion(questionId)
            .then(() => {
                updateQuestionAudio({});
                setAudioVisible(true);
            })
            .catch(() => toast.error("Ошибка удаления аудио", TOAST_ERROR_CONFIG))
            .finally(() => setIsAudioLoading(false));
    };

    return (
        <>
            {textModalVisible && (
                <TextUploadModal
                    toggle={() => setTextModalVisible(!textModalVisible)}
                    visible={textModalVisible}
                    questionText={questionText}
                    saveText={(text) => saveText(text)}
                    isLoading={isTextLoading}
                    maxSymbols={500}
                />
            )}

            {imageModalVisible && (
                <ImageUploadModal
                    toggle={() => setImageModalVisible(!imageModalVisible)}
                    visible={imageModalVisible}
                    onSaveImage={saveImage}
                    testId={questionId}
                    withCrop
                    uploadIsAvailable
                    selectFromLibraryAvailable={false}
                    isLoading={isImageLoading}
                    svgDisabled
                />
            )}

            {formulaModalVisible && (
                <FormulaUploadModal
                    visible={formulaModalVisible}
                    toggle={() => setFormulaModalVisible(false)}
                    saveFormula={(formula) => saveFormula(formula)}
                />
            )}

            {audioModalVisible && (
                <UploadAudioModalContainer
                    isOpen={audioModalVisible}
                    toggle={() => setAudioModalVisible(!audioModalVisible)}
                    saveAudio={saveAudio}
                />
            )}

            <div
                className={cn({
                    [styles.container]: true,
                    [styles.border]: isEditMode && !withoutBorder,
                    [styles.withoutBottomMargin]: withoutBottomMargin,
                })}
                ref={questionRef}
            >
                <Question
                    text={questionText}
                    image={questionImage}
                    formula={questionFormula}
                    audio={questionAudio}
                    saveFormula={saveFormula}
                    deleteText={deleteText}
                    deleteImage={deleteImage}
                    deleteFormula={deleteFormula}
                    deleteAudio={deleteAudio}
                    isEditMode={isEditMode}
                    narrator={narrator}
                    testHasNarrator={testHasNarrator}
                    textTitleSize={textTitleSize}
                    isTextLoading={isTextLoading}
                    isImageLoading={isImageLoading}
                    isAudioLoading={isAudioLoading}
                    isFormulaLoading={isFormulaLoading}
                    toggleTextModal={() => setTextModalVisible(!textModalVisible)}
                    narratorSelect={selectPropertyNarrator}
                    questionId={questionId}
                    editImageHandler={() => setImageModalVisible(true)}
                    editTextHandler={() => setTextModalVisible(true)}
                />
                {isEditMode && withEditButtons && (
                    <AddQuestionButtons
                        textVisible={textVisible}
                        imageVisible={imageVisible}
                        formulaVisible={formulaVisible}
                        audioVisible={audioVisible}
                        textAction={() => setTextModalVisible(true)}
                        imageAction={() => setImageModalVisible(true)}
                        formulaAction={() => setFormulaModalVisible(true)}
                        audioAction={() => setAudioModalVisible(true)}
                    />
                )}
            </div>
        </>
    );
};

CreateQuestion.propTypes = {
    apiInstance: PropTypes.object,
    isEditMode: PropTypes.bool,
    narrator: PropTypes.any,
    questionAudio: PropTypes.object,
    questionFormula: PropTypes.string,
    questionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    questionImage: PropTypes.object,
    questionText: PropTypes.string,
    questionTitle: PropTypes.string,
    selectPropertyNarrator: PropTypes.func,
    testHasNarrator: PropTypes.bool,
    textTitleSize: PropTypes.number,
    updateQuestionAudio: PropTypes.func,
    updateQuestionFormula: PropTypes.func,
    updateQuestionImage: PropTypes.func,
    updateQuestionText: PropTypes.func,
    withEditButtons: PropTypes.bool,
    withoutBorder: PropTypes.bool,
    withoutBottomMargin: PropTypes.bool,
};
