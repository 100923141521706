import {TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG} from "config";
import {EventsAPI} from "entries/events/core/EventsAPI";
import EventsAdmin from "entries/events/events-admin/events-admin";
import {useSelectCheckBox} from "hooks/useSelectCheckBox";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";

const EventsAdminContainer = () => {
    const [events, setEvents] = useState(null);
    const [eventSets, setEventSets] = useState(null);
    const [selectedEvents, handleSelect, reset] = useSelectCheckBox();
    const [createNewSetModalIsOpen, setCreateNewSetModalIsOpen] = useState(false);
    const [addEventsToSetModalIsOpen, setAddEventsToSetModalIsOpen] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [groupForEdit, setGroupForEdit] = useState(null);

    const loadEvents = () => {
        EventsAPI.getEvents().then((response) => {
            setEvents(response.data);
        });
    };

    const loadEventSets = () => {
        EventsAPI.getEventSets().then((response) => {
            setEventSets(response.data);
        });
    };

    useEffect(() => {
        loadEvents();
        loadEventSets();
    }, []);

    const handleSaveNewSet = () => {
        if (!groupName.trim()) {
            toast.error("Заполните название", TOAST_ERROR_CONFIG);
            return;
        }

        EventsAPI.createEventSet(groupName)
            .then(() => {
                toast.success("Успешно", TOAST_SUCCESS_CONFIG);
                setCreateNewSetModalIsOpen(false);
                setGroupName("");
                loadEventSets();
            })
            .catch(() => toast.error("Ошибка создания", TOAST_ERROR_CONFIG));
    };

    const handleSelectSet = (setId) => {
        EventsAPI.addEventsToSet(setId, selectedEvents)
            .then(() => {
                toast.success("Успешно", TOAST_SUCCESS_CONFIG);
                loadEventSets();
                reset();
            })
            .catch(() => toast.error("Ошибка добавления", TOAST_ERROR_CONFIG));
        setAddEventsToSetModalIsOpen(false);
    };

    const editGroupClick = (id) => {
        let group = eventSets.content.find((event) => event.id === id);

        if (!group) {
            return;
        }

        setGroupName(group.name);
        setGroupForEdit(group);
        setCreateNewSetModalIsOpen(true);
    };

    const handleEditGroup = () => {
        if (!groupForEdit) {
            return;
        }

        if (!groupName.trim()) {
            toast.error("Введите название группы", TOAST_ERROR_CONFIG);
            return;
        }

        EventsAPI.editGroup(groupForEdit.id, {name: groupName})
            .then(() => {
                toast.success("Успешно", TOAST_SUCCESS_CONFIG);
                loadEventSets();
                setGroupName("");
                setGroupForEdit(null);
                setCreateNewSetModalIsOpen(false);
                reset();
            })
            .catch(() => toast.error("Ошибка редактирования", TOAST_ERROR_CONFIG));
    };

    const handleDeleteGroup = (id) => {
        EventsAPI.deleteGroup(id)
            .then(() => {
                toast.success("Успешно", TOAST_SUCCESS_CONFIG);
                loadEventSets();
                reset();
            })
            .catch(() => toast.error("Ошибка удаления", TOAST_ERROR_CONFIG));
    };

    const handleDeleteEvent = (id) => {
        EventsAPI.deleteEvent(id)
            .then(() => {
                toast.success("Успешно", TOAST_SUCCESS_CONFIG);
                loadEvents();
                reset();
            })
            .catch(() => toast.error("Ошибка удаления", TOAST_ERROR_CONFIG));
    };

    return (
        <EventsAdmin
            events={events}
            eventSets={eventSets}
            selectedEvents={selectedEvents}
            handleSelect={handleSelect}
            createNewSetModalIsOpen={createNewSetModalIsOpen}
            setCreateNewSetModalIsOpen={setCreateNewSetModalIsOpen}
            addEventsToSetModalIsOpen={addEventsToSetModalIsOpen}
            setAddEventsToSetModalIsOpen={setAddEventsToSetModalIsOpen}
            handleSaveNewSet={handleSaveNewSet}
            handleSelectSet={handleSelectSet}
            handleEditGroup={handleEditGroup}
            handleDeleteGroup={handleDeleteGroup}
            groupName={groupName}
            setGroupName={setGroupName}
            editGroupClick={editGroupClick}
            handleDeleteEvent={handleDeleteEvent}
            groupForEdit={groupForEdit}
        />
    );
};

EventsAdminContainer.propTypes = {};

export default EventsAdminContainer;
