import {Request} from "../utils/request";

export const TagsAPI = {
    addTag: (sectionId, tagName) => {
        if (sectionId && tagName) {
            return Request.post(`section-tags/${sectionId}/tags`, {name: tagName});
        }

        return Promise.reject();
    },

    addFirstLevelTag: (name) => {
        if (name) {
            return Request.post(`section-tags`, {name});
        }

        return Promise.reject();
    },

    addSecondLevelTag: (name) => {
        if (name) {
            return Request.post(`two-section-tags`, {name});
        }

        return Promise.reject();
    },

    addThirdLevelTag: (name) => {
        if (name) {
            return Request.post(`three-section-tags`, {name});
        }

        return Promise.reject();
    },

    addTwoSectionToThreeSection: (twoSectionId, threeSectionId) => {
        return Request.put(`two-section-tags/${twoSectionId}/add-to-three/${threeSectionId}`);
    },

    addSectionToTwoSection: (sectionId, twoSectionId) => {
        return Request.put(`section-tags/${sectionId}/add-to-two/${twoSectionId}`);
    },

    deleteTag: (sectionId, tagId) => {
        if (sectionId && tagId) {
            return Request.delete(`section-tags/${sectionId}/tags/${tagId}`);
        }

        return Promise.reject(sectionId ? "" : "");
    },

    deleteFirstLevelTag: (tagId) => {
        if (tagId) {
            return Request.delete(`section-tags/${tagId}`);
        }

        return Promise.reject();
    },

    deleteSecondLevelTag: (tagId) => {
        if (tagId) {
            return Request.delete(`two-section-tags/${tagId}`);
        }

        return Promise.reject();
    },

    deleteThirdLevelTag: (tagId) => {
        if (tagId) {
            return Request.delete(`three-section-tags/${tagId}`);
        }

        return Promise.reject();
    },

    getTags: (sectionId) => {
        if (sectionId) {
            return Request.get(`section-tags/${sectionId}/tags`);
        }

        return Promise.reject();
    },

    getFirstLevelTags: () => {
        return Request.get(`/section-tags`);
    },

    getFirstLevelTagsBySectionTwo: (sectionId) => {
        if (sectionId) {
            return Request.get(`section-tags/by-section-two/${sectionId}`);
        }

        return Promise.reject();
    },

    getSecondLevelTags: () => {
        return Request.get(`two-section-tags`);
    },

    getSecondLevelTagsBySectionThree: (sectionId) => {
        if (sectionId) {
            return Request.get(`two-section-tags/by-section-three/${sectionId}`);
        }

        return Promise.reject();
    },

    getThirdLevelTags: () => {
        return Request.get(`three-section-tags`);
    },

    renameTag: (sectionId, tagId, tagName) => {
        if (sectionId && tagId && tagName) {
            return Request.put(`section-tags/${sectionId}/tags/${tagId}`, {name: tagName});
        }

        return Promise.reject("");
    },

    renameFirstLevelTag: (tagId, name) => {
        if (tagId && name) {
            return Request.put(`section-tags/${tagId}`, {name});
        }
        return Promise.reject();
    },

    renameSecondLevelTag: (tagId, name) => {
        if (tagId && name) {
            return Request.put(`two-section-tags/${tagId}`, {name});
        }

        return Promise.reject();
    },

    renameThirdLevelTag: (tagId, name) => {
        if (tagId && name) {
            return Request.put(`three-section-tags/${tagId}`, {name});
        }

        return Promise.reject();
    },
};
