import React from "react";
import PropTypes from "prop-types";
import {EditButtons} from "views/components/edit-buttons";
import styles from "./item-test.module.sass";

export const ItemTest = ({test, handleTestDelete, handleTestEdit}) => {
    return (
        <div className={styles.container}>
            <div>
                <p>Вопрос: {test.questionText}</p>
            </div>
            <div>
                <p>Название: {test.title}</p>
            </div>
            <EditButtons
                itemId={test.id}
                editAction={() => handleTestEdit(test.id)}
                deleteAction={() => handleTestDelete(test.id)}
            />
        </div>
    );
};

ItemTest.propTypes = {
    handleTestDelete: PropTypes.func,
    handleTestEdit: PropTypes.func,
    test: PropTypes.object,
};
