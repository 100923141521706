import React from "react";
import styles from "./styles.module.sass";
import {PREMIUM_BONUSES} from "./constant";
import cn from "classnames";
import raven from "assets/sliders/buy-subscription/raven.svg";
import topConfetti from "assets/sliders/buy-subscription/top-confetti.svg";
import bottomConfetti from "assets/sliders/buy-subscription/bottom-confetti.svg";

const Premium = ({handlePayment, selectedPremiumTime, setSelectedPremiumTime}: any) => {
    return (
        <div className={styles.wrapper}>
            <img className={styles.raven} src={raven} alt="ravenLogo" />
            <div className={styles.topConfettiWrapper}>
                <div className={styles.topConfetti}>
                    <img src={topConfetti} alt="topConfetti" />
                </div>
            </div>
            <div className={styles.bottomConfettiWrapper}>
                <img src={bottomConfetti} alt="bottomConfetti" />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    <div className={styles.contentHeader}>
                        <div className={styles.column1}></div>
                        <div className={styles.column2}></div>
                        <div
                            className={cn({
                                [styles.column3]: true,
                                [styles.relative]: true,
                                [styles.crowWrapper]: true,
                            })}
                            style={{color: "#E3BE00"}}
                        >
                            <img
                                className={styles.crown}
                                alt="crown"
                                src={require("../../assets/premium/crown.svg")}
                            />
                        </div>
                    </div>

                    <div className={styles.contentHeader}>
                        <div className={styles.column1} style={{justifyContent: "flex-start"}}>
                            <p>Доступ к Умной Вороне</p>{" "}
                        </div>
                        <div
                            className={cn({
                                [styles.column2]: true,
                                [styles.headerTitle]: true,
                            })}
                        >
                            <p>Обычный</p>{" "}
                        </div>
                        <div
                            className={cn({
                                [styles.column3]: true,
                                [styles.relative]: true,
                                [styles.headerTitle]: true,
                            })}
                            style={{color: "#E3BE00"}}
                        >
                            <p>Подписка</p>
                        </div>
                    </div>

                    <div className={styles.secondHeader}>
                        <div className={styles.column1} style={{paddingTop: 15}}>
                            <p style={{color: "#E3BE00", paddingBottom: 15}}>АКАДЕМИЯ</p>
                        </div>
                        <div className={styles.column2}></div>
                        <div
                            className={cn({
                                [styles.column3]: true,
                            })}
                            style={{borderColor: "#E3BE00"}}
                        >
                            <div className={styles.buttons}>
                                <div
                                    className={cn({
                                        [styles.button]: true,
                                        [styles.buttonPremiumTime]: true,
                                        [styles.selectedButton]: selectedPremiumTime === "MONTH",
                                    })}
                                    onClick={() => setSelectedPremiumTime("MONTH")}
                                >
                                    <p>1 месяц</p>
                                </div>
                                <div
                                    className={cn({
                                        [styles.button]: true,
                                        [styles.buttonPremiumTime]: true,
                                        [styles.selectedButton]: selectedPremiumTime === "YEAR",
                                    })}
                                    onClick={() => setSelectedPremiumTime("YEAR")}
                                >
                                    <p>1 год</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {PREMIUM_BONUSES.map((bonus) => (
                        <div className={styles.bonus} key={bonus.name}>
                            <div
                                className={cn({
                                    [styles.column1]: true,
                                    [styles.borderRight]: true,
                                    [styles.bonusImage]: true,
                                })}
                            >
                                <img src={bonus.icon} alt="icon" />
                                <p>{bonus.name}</p>
                            </div>
                            <div className={styles.column2}>
                                <p>
                                    {bonus.free.isAvailable ? (
                                        <img
                                            className={styles.icon}
                                            alt="golden-check"
                                            src={require("../../assets/premium/golden-check.svg")}
                                        />
                                    ) : (
                                        <img
                                            className={styles.icon}
                                            alt="xmark"
                                            src={require("../../assets/premium/xmark.svg")}
                                        />
                                    )}
                                </p>
                            </div>
                            <div
                                className={cn({
                                    [styles.column3]: true,
                                    [styles.borderLeft]: true,
                                    [styles.borderRight]: true,
                                })}
                                style={{borderColor: "#E3BE00"}}
                            >
                                <p>
                                    {bonus.premium.isAvailable ? (
                                        <img
                                            className={styles.icon}
                                            alt="golden-check"
                                            src={require("../../assets/premium/golden-check.svg")}
                                        />
                                    ) : (
                                        <img
                                            className={styles.icon}
                                            alt="xmark"
                                            src={require("../../assets/premium/xmark.svg")}
                                        />
                                    )}
                                </p>
                            </div>
                        </div>
                    ))}

                    <div className={styles.prizesHeader}>
                        <div
                            className={cn({
                                [styles.borderRight]: true,
                                [styles.column1]: true,
                            })}
                        >
                            <p style={{color: "#E3BE00", paddingBottom: 15, paddingTop: 15}}>
                                ПРИЗЫ В ИГРЕ
                            </p>
                        </div>
                        <div
                            className={cn({
                                [styles.column2]: true,
                            })}
                            style={{borderColor: "#E3BE00"}}
                        ></div>
                        <div
                            className={cn({
                                [styles.column3]: true,
                                [styles.borderLeft]: true,
                                [styles.borderRight]: true,
                            })}
                            style={{borderColor: "#E3BE00"}}
                        ></div>
                    </div>

                    <div className={styles.prizes}>
                        <div
                            className={cn({
                                [styles.borderRight]: true,
                                [styles.column1]: true,
                            })}
                        >
                            <div className={styles.row}>
                                <img
                                    className={cn({
                                        [styles.icon]: true,
                                        [styles.dot]: true,
                                    })}
                                    alt="icon"
                                    src={require("../../assets/premium/silver-ring.svg")}
                                />
                                <p>Тема пройдена без ошибок</p>
                            </div>

                            <div className={styles.row}>
                                <img
                                    className={cn({
                                        [styles.icon]: true,
                                        [styles.dot]: true,
                                    })}
                                    alt="icon"
                                    src={require("../../assets/premium/silver-ring.svg")}
                                />
                                <p>Участие в турнире</p>
                            </div>
                        </div>
                        <div className={styles.column2}>
                            <img
                                className={styles.iconBig}
                                alt="icon"
                                src={require("../../assets/premium/wooden-chest.svg")}
                            />
                        </div>
                        <div
                            className={cn({
                                [styles.column3]: true,
                                [styles.borderLeft]: true,
                                [styles.borderRight]: true,
                            })}
                            style={{borderColor: "#E3BE00"}}
                        >
                            <img
                                className={styles.iconBig}
                                alt="icon"
                                src={require("../../assets/premium/silver-chest.svg")}
                            />
                        </div>
                    </div>

                    <div className={styles.prizes}>
                        <div
                            className={cn({
                                [styles.borderRight]: true,
                                [styles.column1]: true,
                            })}
                        >
                            <div className={styles.row}>
                                <img
                                    className={cn({
                                        [styles.icon]: true,
                                        [styles.dot]: true,
                                    })}
                                    alt="icon"
                                    src={require("../../assets/premium/gold-ring.svg")}
                                />
                                <p>Тема пройдена без ошибок</p>
                            </div>
                            <div className={styles.row}>
                                <img
                                    className={cn({
                                        [styles.icon]: true,
                                        [styles.dot]: true,
                                    })}
                                    alt="icon"
                                    src={require("../../assets/premium/gold-ring.svg")}
                                />
                                <p>Высокий результат в турнире</p>
                            </div>
                        </div>
                        <div className={styles.column2}>
                            <img
                                className={styles.iconBig}
                                alt="icon"
                                src={require("../../assets/premium/silver-chest.svg")}
                            />
                        </div>
                        <div
                            className={cn({
                                [styles.column3]: true,
                                [styles.borderLeft]: true,
                                [styles.borderRight]: true,
                            })}
                            style={{borderColor: "#E3BE00"}}
                        >
                            <img
                                className={styles.iconBig}
                                alt="icon"
                                src={require("../../assets/premium/golden-chest.svg")}
                            />
                        </div>
                    </div>

                    <div className={styles.prizes}>
                        <div
                            className={cn({
                                [styles.borderRight]: true,
                                [styles.column1]: true,
                            })}
                        >
                            <div className={styles.row}>
                                <img
                                    className={cn({
                                        [styles.icon]: true,
                                        [styles.dot]: true,
                                    })}
                                    alt="icon"
                                    src={require("../../assets/premium/gold-ring.svg")}
                                />
                                <p>Игровой бонус</p>
                            </div>
                        </div>
                        <div className={styles.column2}>
                            <img
                                className={styles.icon}
                                alt="xmark"
                                src={require("../../assets/premium/xmark.svg")}
                            />
                        </div>
                        <div
                            className={cn({
                                [styles.column3]: true,
                                [styles.borderLeft]: true,
                                [styles.borderRight]: true,
                            })}
                            style={{borderColor: "#E3BE00"}}
                        >
                            <div className={styles.row}>
                                <p style={{color: "#E3BE00"}}>
                                    {selectedPremiumTime === "MONTH" ? 50 : 600}
                                </p>
                                <img
                                    className={styles.coin}
                                    alt="icon"
                                    src={require("../../assets/images/static/coin.svg")}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className={cn({
                            [styles.prizes]: true,
                            [styles.prizesAction]: true,
                        })}
                        style={{marginTop: 25}}
                    >
                        <div
                            className={cn({
                                [styles.column1]: true,
                            })}
                        ></div>
                        <div className={styles.column2} style={{alignItems: "center"}}>
                            <p>Бесплатно</p>
                        </div>
                        <div
                            className={cn({
                                [styles.column3]: true,
                            })}
                            style={{borderColor: "#E3BE00"}}
                        >
                            {selectedPremiumTime === "MONTH" ? (
                                <div className={styles.button} onClick={() => handlePayment(20)}>
                                    <p>169 руб.</p>
                                </div>
                            ) : (
                                <div className={styles.button} onClick={() => handlePayment(5)}>
                                    <p>799 руб.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Premium;
