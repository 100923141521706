import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./image-answer.module.sass";

import {url} from "config";

export const Image = ({image, imageUrl, className}) => {
    return (
        <img
            className={cn({
                [className]: !!className,
                [styles.disableDrag]: true,
                noselect: true,
            })}
            src={(image || imageUrl) && `${url}${image ? image.url : imageUrl}`}
            alt="answer"
        />
    );
};

Image.propTypes = {
    className: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
    }),
    imageUrl: PropTypes.string,
};
