import React from "react";
import PropTypes from "prop-types";
import {SelectWordBox} from "entries/constructors/dictation/views/containers/select-word-box/select-word-box";

export const SelectWordBoxContainer = ({
    separatedText,
    selectedWordIndex,
    setSelectedWordIndex,
    skips,
}) => {
    const handleSelect = (index) => {
        if (selectedWordIndex === index) {
            setSelectedWordIndex(null);
            return;
        }
        setSelectedWordIndex(index);
    };

    return (
        <SelectWordBox
            separatedText={separatedText}
            onSelectWord={handleSelect}
            selectedWordIndex={selectedWordIndex}
            skips={skips}
        />
    );
};

SelectWordBoxContainer.propTypes = {
    selectedWordIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    separatedText: PropTypes.array,
    setSelectedWordIndex: PropTypes.func,
    skips: PropTypes.array,
};
