import AboutEngines from "views/pages/about/sliders/about-engines/about-engines";
import AcademyComfortSlide from "views/pages/about/sliders/academy-comfort-slide/academy-comfort-slide";
import AcademyForAllSlide from "views/pages/about/sliders/academy-forall-slide/academy-forall-slide";
import AcademyHallOfFameSlide from "views/pages/about/sliders/academy-hall-of-fame-slide/academy-hall-of-fame-slide";
import AcademyRatingSlide from "views/pages/about/sliders/academy-rating-slide/academy-rating-slide";
import AcademyStartSlide from "views/pages/about/sliders/academy-start-slide/academy-start-slide";
import AcademyStructureSlide from "views/pages/about/sliders/academy-structure-slide/academy-structure-slide";
import AvailableSlide from "views/pages/about/sliders/available-slide/available-slide";
import BuySubscriptionContainer from "views/pages/about/sliders/buy-subscription-slide/buy-subscription-container";
import RatingSystemSlide from "views/pages/about/sliders/rating-system-slide/rating-system-slide";
import TeacherCareerSlide from "views/pages/about/sliders/teacher-career-slide/teacher-career-slide";
import TeacherClassSlide from "views/pages/about/sliders/teacher-class-slide/teacher-class-slide";
import TeacherFabricSlide from "views/pages/about/sliders/teacher-fabric-slide/teacher-fabric-slide";
import TeacherFreeSlide from "views/pages/about/sliders/teacher-free-slide/teacher-free-slide";
import TeacherShopSlide from "views/pages/about/sliders/teacher-shop-slide/teacher-shop-slide";
import TeacherTasksSlide from "views/pages/about/sliders/teacher-tasks-slide/teacher-tasks-slide";
import TournamentFightSlide from "views/pages/about/sliders/tournament-fight-slide/tournament-fight-slide";
import TournamentInviteSlide from "views/pages/about/sliders/tournament-invite-slide/tournament-invite-slide";
import TournamentRegisterSlide from "views/pages/about/sliders/tournament-register-slide/tournament-register-slide";
import TournamentSubjectSlide from "views/pages/about/sliders/tournament-subject-slide/tournament-subject-slide";

export const SLIDERS_ABOUT = [
    {name: "SUBSCRIPTION", component: BuySubscriptionContainer},
    {name: "RATING_SYSTEM", component: RatingSystemSlide},
    {name: "AVAILABLE", component: AvailableSlide},
];

export const SLIDERS_TEACHER_ABOUT = [
    {name: "TeacherClass", component: TeacherClassSlide},
    {name: "TeacherTasks", component: TeacherTasksSlide},
    {name: "AcademyEngines", component: AboutEngines},
    {name: "TeacherFabric", component: TeacherFabricSlide},
    {name: "TeacherCareer", component: TeacherCareerSlide},
    {name: "TeacherShop", component: TeacherShopSlide},
    {name: "TeacherFree", component: TeacherFreeSlide},
];

export const SLIDERS_TOURNAMENT_ABOUT = [
    {name: "Tournament", component: TournamentInviteSlide},
    {name: "Tournament", component: TournamentSubjectSlide},
    {name: "Tournament", component: TournamentFightSlide},
    {name: "Tournament", component: TournamentRegisterSlide},
];

export const SLIDERS_ACADEMY_ABOUT = [
    {name: "AcademyStart", component: AcademyStartSlide},
    {name: "AcademyForAll", component: AcademyForAllSlide},
    {name: "AcademyStructure", component: AcademyStructureSlide},
    {name: "AcademyEngines", component: AboutEngines},
    {name: "AcademyRating", component: AcademyRatingSlide},
    {name: "AcademyComfort", component: AcademyComfortSlide},
    {name: "AcademyHallOfFame", component: AcademyHallOfFameSlide},
];
