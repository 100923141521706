import {auth} from "core/utils/auth";
import ShineEffect from "entries/shine-effect/shine-effect";
import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";
import {
    SLIDER_IMAGE_URLS,
    SLIDER_IMAGE_URLS_OTHER,
} from "views/pages/about/utils/slider-image-urls";
import styles from "./styles.module.sass";
import cn from "classnames";
import {useHistory} from "react-router-dom";

const TournamentRegisterSlide = () => {
    const {keycloak} = auth;
    const history = useHistory();
    const backgroundStyles = {
        backgroundImage: `url(${SLIDER_IMAGE_URLS.TOURNAMENT.REGISTER})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    const backgroundStylesButton = {
        backgroundImage: `url(${SLIDER_IMAGE_URLS_OTHER.REGISTER_BUTTON})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    const handleClick = () => {
        if (keycloak?.authenticated) {
            history.push("/");
            return;
        }

        keycloak.login();
    };

    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            <div className={styles.contentWrapper}>
                <div
                    style={backgroundStylesButton}
                    className={cn({
                        "animate__animated animate__pulse animate__infinite animate__slower": true,
                        [styles.registerButton]: true,
                    })}
                    onClick={handleClick}
                >
                    <ShineEffect />
                </div>
            </div>
        </div>
    );
};

TournamentRegisterSlide.propTypes = {};

export default TournamentRegisterSlide;
