import {TOAST_ERROR_CONFIG} from "config";
import {USER_ROLE} from "config/roles";
import {UserAPI} from "core/api/user";
import {auth} from "core/utils/auth";
import {getRole} from "core/utils/helpers";
import {useStores} from "hooks/use-stores";
import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {SELECT_ROLE_TEACHER, SELECT_ROLE_USER} from "../select-role/select-role-container";
import RegisterForm from "./register-form";

const RegisterFormContainer = observer(() => {
    const [region, setRegion] = useState(null);
    const [city, setCity] = useState(null);
    const [grade, setGrade] = useState(null);
    const [school, setSchool] = useState(null);
    const [gender, setGender] = useState("MAN");
    const [role, setRole] = useState(SELECT_ROLE_USER);
    const [isLoading, setIsLoading] = useState(false);
    const [teacherPosition, setTeacherPosition] = useState("");
    const [selectedSubjects, setSelectedSubjects] = useState([]);

    const [schoolInputValue, setSchoolInputValue] = useState("");
    const [isSchoolInputOpen, setIsSchoolInputOpen] = useState(false);
    const history = useHistory();

    const [userRole, setUserRole] = useState(null);
    const {keycloak} = auth;

    const {userStore} = useStores();

    useEffect(() => {
        if (userStore.user) {
            history.push("/");
        }
    }, [userStore.user]);

    useEffect(() => {
        let role = getRole(keycloak);
        setUserRole(role);
    }, []);

    const handleRegister = () => {
        if (!(region && city)) {
            if (role === SELECT_ROLE_USER) {
                if (!grade) {
                    toast.error("Заполните класс", TOAST_ERROR_CONFIG);
                    return;
                }
            }

            toast.error("Заполните все поля", TOAST_ERROR_CONFIG);
            return;
        }

        let profileDto = {
            city: {geoNameId: city.value},
            gender,
            region: {geoNameId: region.value},
            school: isSchoolInputOpen ? null : {id: school.value},
        };

        if (role === SELECT_ROLE_TEACHER) {
            profileDto.teacherPosition = teacherPosition;
            profileDto.tags = selectedSubjects.map((subject) => ({id: subject.value}));
        }

        if (grade) {
            profileDto.classTag = {id: grade};
        }

        profileDto.updatedClassTag = new Date();

        let API = UserAPI.updateProfile;

        if (!userStore.user) {
            API = UserAPI.createProfile;
        }

        setIsLoading(true);
        API(profileDto)
            .then(async () => {
                if (userRole === USER_ROLE) {
                    await UserAPI.selectRole(role);
                }

                if (isSchoolInputOpen && schoolInputValue.trim()) {
                    await UserAPI.createTempSchool(schoolInputValue.trim());
                }
            })
            .then(async () => {
                await userStore.getUser();
                window.location.reload();
            })
            .catch(() => toast.error("Ошибка регистрации", TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));
    };

    return (
        <RegisterForm
            role={role}
            city={city}
            grade={grade}
            region={region}
            gender={gender}
            handleSelectRole={setRole}
            handleSelectRegion={setRegion}
            handleSelectCity={setCity}
            handleSelectGrade={setGrade}
            handleSelectGender={setGender}
            handleSelectSchool={setSchool}
            handleRegister={handleRegister}
            isLoading={isLoading}
            teacherPosition={teacherPosition}
            setTeacherPosition={setTeacherPosition}
            handleSelectSubject={setSelectedSubjects}
            selectedSubjects={selectedSubjects}
            userRole={userRole}
            setIsSchoolInputOpen={setIsSchoolInputOpen}
            isSchoolInputOpen={isSchoolInputOpen}
            schoolInputValue={schoolInputValue}
            setSchoolInputValue={setSchoolInputValue}
        />
    );
});

RegisterFormContainer.propTypes = {};

export default RegisterFormContainer;
