import ClassicTestIcon from "assets/images/icon-test/иконки-14.svg";
import {url} from "config";
import {ADMIN_ROLE} from "config/roles";
import {auth} from "core/utils/auth";
import VideoModal from "entries/modals/video-modal/video-modal";
import NotificationModalLayer from "entries/notifications-modal/notification-modal-layer";
import {useStores} from "hooks/use-stores";
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {hasAccess} from "utils/private-route";

import styles from "./create-task.module.sass";

export const CreateTask = ({routerInstance, mode}) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [roleIsLoaded, setRoleIsLoaded] = useState(false);
    const {keycloak} = auth;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedMode, setSelectedMode] = useState(null);

    useEffect(() => {
        setIsAdmin(hasAccess(keycloak, [ADMIN_ROLE]));
        setRoleIsLoaded(true);
    }, []);

    let history = useHistory();
    const {appStore} = useStores();

    const modes = [
        {
            id: 1,
            path: {
                pathname: routerInstance.CLASSIC_TEST.CREATE_TEST,
                state: mode,
            },
            name: "Классический тест",
            alt: "classic-test",
            isAvailable: true,
            description: (
                <>
                    <div className={styles.logoWrapper}>
                        <img
                            className="animate__animated animate__fadeIn animate__slow"
                            src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                            alt="raven"
                        />
                    </div>
                    <p className="title mb-4 text-center">Классический тест</p>
                    <p className="mb-2 text-center">
                        Это задание с вариантами ответов. В ответах можно использовать текст,
                        изображение или аудио-файл.
                    </p>
                    <p className="mb-2 text-center">
                        Правильным может быть не один вариант ответа, а два, или даже все. Чтобы
                        решить верно, ученик должен выбрать все правильные варианты ответов.
                    </p>
                    <p className="mb-2 text-center">
                        Например. Если в задании установлено, что ответы №1 и №4 являются верными,
                        то ученик должен обязательно отметить их оба. Если он выберет только вариант
                        №1 или только вариант №4, его ответ будет неверным.
                    </p>
                </>
            ),
            tutorial: "https://www.youtube.com/embed/biMbFoozRKw",
        },

        {
            id: 2,
            path: {
                pathname: routerInstance.DICTATION.CREATE_TEST,
                state: mode,
            },
            name: "Диктант",
            alt: "dictation",
            isAvailable: true,
            description: (
                <>
                    <div className={styles.logoWrapper}>
                        <img
                            className="animate__animated animate__fadeIn animate__slow"
                            src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                            alt="raven"
                        />
                    </div>
                    <p className="title mb-4 text-center">Диктант</p>
                    <p className="mb-2 text-center">
                        Это полноценный диктант. В тексте пропущены буквы, которые ученик должен
                        выбрать из предложенных вариантов.
                    </p>
                    <p className="mb-2 text-center">Текст может содержать не более 1000 знаков.</p>
                    <p className="mb-2 text-center">
                        В отличие от других типов заданий, где ответ может быть только «верным» или
                        «неверным», в диктанте считается процент правильно выбранных букв.
                    </p>
                </>
            ),
        },

        {
            id: 3,
            path: {
                pathname: routerInstance.TEXT_SEQUENCE_TEST.CREATE_TEST,
                state: mode,
            },
            name: "Последовательность текстов",
            alt: "text-sequence",
            isAvailable: true,
            description: (
                <>
                    <div className={styles.logoWrapper}>
                        <img
                            className="animate__animated animate__fadeIn animate__slow"
                            src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                            alt="raven"
                        />
                    </div>
                    <p className="title mb-4 text-center">Последовательность текстов</p>
                    <p className="mb-2 text-center">
                        Задание на восстановление последовательности. Ответами могут быть только
                        тексты.
                    </p>
                    <p className="mb-2 text-center">
                        Ученик должен расставить предложенные варианты ответов в правильную
                        последовательность. Например, восстановить предложение или расставить
                        события в хронологическом порядке.
                    </p>
                </>
            ),
        },

        {
            id: 4,
            path: {
                pathname: routerInstance.NUMERIC_SEQUENCE_TEST.CREATE_TEST,
                state: mode,
            },
            name: "Последовательность изображений",
            alt: "numeric-sequence",
            isAvailable: true,
            description: (
                <>
                    <div className={styles.logoWrapper}>
                        <img
                            className="animate__animated animate__fadeIn animate__slow"
                            src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                            alt="raven"
                        />
                    </div>
                    <p className="title mb-4 text-center">Последовательность изображений</p>
                    <p className="mb-2 text-center">
                        Задание на восстановление последовательности. Ответами могут быть только
                        изображения.
                    </p>
                    <p className="mb-2 text-center">
                        Ученик должен расставить предложенные варианты ответов в правильную
                        последовательность. Например, определить поочередность времен года,
                        изображенных на картинках.
                    </p>
                </>
            ),
        },

        {
            id: 5,
            path: {
                pathname: routerInstance.PAIRS_TEST.CREATE_TEST,
                state: mode,
            },
            name: "Пары",
            alt: "pairs",
            isAvailable: true,
            description: (
                <>
                    <div className={styles.logoWrapper}>
                        <img
                            className="animate__animated animate__fadeIn animate__slow"
                            src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                            alt="raven"
                        />
                    </div>
                    <p className="title mb-4 text-center">Пары</p>
                    <p className="mb-2 text-center">
                        Задание на составление пар. Ответами могут быть тексты или изображения.
                    </p>
                    <p className="mb-2 text-center">
                        Из предложенных вариантов ученик должен составить пары. Например, найти
                        подходящее описание к картинке, или составить фразу из двух половинок.
                    </p>
                </>
            ),
            tutorial: "https://www.youtube.com/embed/APObq2XUEgo",
        },

        {
            id: 6,
            path: {
                pathname: routerInstance.WORD_SKIP_TEST.CREATE_TEST,
                state: mode,
            },
            name: "Пропуск слов",
            alt: "word-skip",
            isAvailable: true,
            description: (
                <>
                    <div className={styles.logoWrapper}>
                        <img
                            className="animate__animated animate__fadeIn animate__slow"
                            src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                            alt="raven"
                        />
                    </div>
                    <p className="title mb-4 text-center">Пропуск слов</p>
                    <p className="mb-2 text-center">
                        Задание на восстановление текста (Вариация №1).
                    </p>
                    <p className="mb-2 text-center">
                        Ученику дается текст, в котором пропущены некоторые слова или фразы. То, что
                        пропущено, становится вариантами ответов. Ученик должен вернуть слова на
                        свои места.
                    </p>
                </>
            ),
        },

        {
            id: 7,
            path: {
                pathname: routerInstance.WORD_SKIP_ADDITIONAL_TEST.CREATE_TEST,
                state: mode,
            },
            name: "Пропуск слов с вариантами ответов",
            alt: "word-skip-add",
            isAvailable: true,
            description: (
                <>
                    <div className={styles.logoWrapper}>
                        <img
                            className="animate__animated animate__fadeIn animate__slow"
                            src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                            alt="raven"
                        />
                    </div>
                    <p className="title mb-4 text-center">Пропуск слов с вариантами ответов</p>
                    <p className="mb-2 text-center">
                        Задание на восстановление текста (Вариация №2).
                    </p>
                    <p className="mb-2 text-center">
                        Ученик должен восстановить текст, вернув пропущенные слова или фразы. Каждый
                        пропуск в тексте может иметь несколько вариантов ответа, лишь один из
                        которых верный.
                    </p>
                </>
            ),
        },

        {
            id: 8,
            path: {
                pathname: routerInstance.OPEN_ANSWER_TEST.CREATE_TEST,
                state: mode,
            },
            name: "Открытый ответ",
            alt: "open-answer",
            isAvailable: true,
            description: (
                <>
                    <div className={styles.logoWrapper}>
                        <img
                            className="animate__animated animate__fadeIn animate__slow"
                            src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                            alt="raven"
                        />
                    </div>
                    <p className="title mb-4 text-center">Открытый ответ</p>
                    <p className="mb-2 text-center">
                        Ответом для задания может быть одно слово или число. Ученик должен составить
                        ответ из предложенных букв или цифр, перетаскивая их в поле ответа.
                    </p>
                </>
            ),
        },

        {
            id: 9,
            path: {
                pathname: routerInstance.SENTENCE_TEST.CREATE_TEST,
                state: mode,
            },
            name: "Восстановление предложения",
            alt: "sequence",
            isAvailable: true,
            description: (
                <>
                    <div className={styles.logoWrapper}>
                        <img
                            className="animate__animated animate__fadeIn animate__slow"
                            src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                            alt="raven"
                        />
                    </div>
                    <p className="title mb-4 text-center">Восстановление предложения</p>
                    <p className="mb-2 text-center">
                        Задание на восстановление текста (Вариация №3).
                    </p>
                    <p className="mb-2 text-center">
                        Текст разбивается на фрагменты по словам или фразам. Ученик должен
                        восстановить текст, использовав все фрагменты.
                    </p>
                </>
            ),
        },

        {
            id: 10,
            path: {
                pathname: routerInstance.BASKETS_TEST?.CREATE_TEST,
                state: mode,
            },
            name: "Перетаскивание",
            alt: "baskets",
            isAvailable: false,
            description: <></>,
        },

        {
            id: 11,
            path: {
                pathname: routerInstance.CLIPPING_TEST.CREATE_TEST,
                state: mode,
            },
            name: `Перетаскивание «Один к одному»`,
            alt: "clipping",
            isAvailable: true,
            description: (
                <>
                    <div className={styles.logoWrapper}>
                        <img
                            className="animate__animated animate__fadeIn animate__slow"
                            src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                            alt="raven"
                        />
                    </div>
                    <p className="title mb-4 text-center">Перетаскивание «Один к одному»</p>
                    <p className="mb-2 text-center">
                        Задание, в котором ученик должен перетащить варианты ответы на свои места. В
                        задании обязательно должно быть изображение, на котором делаются «вырезы» –
                        это области, куда ученик будет переносить варианты ответов.
                    </p>
                    <p className="mb-2 text-center">
                        В качестве ответов могут быть тексты или изображения.
                    </p>
                </>
            ),
        },

        {
            id: 12,
            path: {
                pathname: routerInstance.CLIPPING_SECONDARY_TEST.CREATE_TEST,
                state: mode,
            },
            name: "Перетаскивание «Один ко многим»",
            alt: "clipping",
            isAvailable: true,
            description: (
                <>
                    <div className={styles.logoWrapper}>
                        <img
                            className="animate__animated animate__fadeIn animate__slow"
                            src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                            alt="raven"
                        />
                    </div>

                    <p className="title mb-4 text-center">Перетаскивание «Один ко многим»</p>
                    <p className="mb-2 text-center">
                        Задание, в котором ученик должен выбрать правильные варианты ответов и
                        перетащить их на картинку в «вырезы». В отличие от вариации «Один к одному»,
                        ответы не должны занимать строго установленные «вырезы». Достаточно, чтобы
                        они были выбраны – то есть, перенесены в любой «вырез».
                    </p>
                    <p className="mb-2 text-center">
                        Важно: количество вариантов ответов, из которых выбирает ученик, должно
                        превышать количество «вырезов». Иначе задача потеряет свой смысл.
                    </p>
                </>
            ),
        },
        {
            id: 13,
            path: {
                pathname: routerInstance.MANY_TO_MANY_DND_TEST.CREATE_TEST,
                state: mode,
            },
            name: "Перетаскивание «Многие к одному»",
            alt: "clipping-many",
            isAvailable: false,
            description: (
                <>
                    <div className={styles.logoWrapper}>
                        <img
                            className="animate__animated animate__fadeIn animate__slow"
                            src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                            alt="raven"
                        />
                    </div>

                    <p className="title mb-4 text-center">Перетаскивание «Многие ко многим»</p>
                    <p className="mb-2 text-center">....</p>
                    <p className="mb-2 text-center">....</p>
                </>
            ),
        },
    ];

    return (
        <MDBContainer className={styles.container}>
            <MDBRow>
                <MDBCol md="8">
                    <p className={styles.title}>Выберите тип задания</p>
                </MDBCol>
            </MDBRow>

            {modalIsOpen && (
                <NotificationModalLayer
                    isActive={modalIsOpen}
                    withLightBackground
                    toggle={() => setModalIsOpen(!modalIsOpen)}
                    withTimes
                >
                    <div className={styles.modalContent}>
                        <div>{selectedMode?.description}</div>
                    </div>
                </NotificationModalLayer>
            )}

            <MDBRow>
                {
                    <React.Fragment>
                        {roleIsLoaded && (
                            <React.Fragment>
                                {modes.map((mode) => {
                                    if (!mode.isAvailable && !isAdmin) {
                                        return;
                                    }

                                    return (
                                        <MDBCol key={mode.id} md="4">
                                            <div
                                                className={styles.cardContainer}
                                                id="card"
                                                onClick={(e) => {
                                                    if (e.target.id === "card") {
                                                        history.push(mode.path);
                                                    }
                                                }}
                                            >
                                                <img src={ClassicTestIcon} alt={mode.alt} />
                                                {mode.name}
                                                {mode.tutorial && (
                                                    <p
                                                        className="link mt-2"
                                                        onClick={() =>
                                                            appStore.addModalToQueue(
                                                                <VideoModal
                                                                    toggle={
                                                                        appStore.closeActiveModal
                                                                    }
                                                                    url={mode.tutorial}
                                                                    key={"video-modal"}
                                                                />,
                                                            )
                                                        }
                                                    >
                                                        Видеоинструкция
                                                    </p>
                                                )}

                                                <div
                                                    className={styles.info}
                                                    onClick={() => {
                                                        setSelectedMode(mode);
                                                        setModalIsOpen(true);
                                                    }}
                                                >
                                                    <i className="fas fa-info-circle" />
                                                </div>
                                            </div>
                                        </MDBCol>
                                    );
                                })}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                }
            </MDBRow>
        </MDBContainer>
    );
};

CreateTask.propTypes = {
    mode: PropTypes.any,
    routerInstance: PropTypes.any,
};
