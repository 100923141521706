import {ADMIN_ROLE, TEACHER_ROLE} from "config/roles";
import {Request} from "core/utils/request";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";

export const PlayerAPI = {
    checkAnswer: (prefix, questionId, answers, playerMode, role, entityId) => {
        if (questionId && answers) {
            if (role === ADMIN_ROLE || role === TEACHER_ROLE) {
                return Request.post(`${prefix}/${questionId}/submit-answers-admin`, answers);
            }

            if (playerMode === PlayerV2Modes.ARTICLE) {
                return Request.post(`${prefix}/${questionId}/publication/submit-answers`, answers);
            }

            if (playerMode === PlayerV2Modes.ACADEMY) {
                return Request.post(`${prefix}/${questionId}/submit-answers-academy`, answers);
            } else if (
                playerMode === PlayerV2Modes.HOMEWORK ||
                playerMode === PlayerV2Modes.ACADEMY_HOMEWORK
            ) {
                return Request.post(
                    `${prefix}/${questionId}/submit-answers-home-work?teacherHomeWorkId=${entityId}`,
                    answers,
                );
            } else if (playerMode === PlayerV2Modes.TOURNAMENT) {
                return Request.post(
                    `${prefix}/${questionId}/submit-answers-tournament?tournamentId=${entityId}`,
                    answers,
                );
            }
        }
    },
};
