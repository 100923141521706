import titleLogoAcademy from "assets/logo/academy/academy_logo.svg";
import titleLogoTeacher from "assets/logo/teacher/teacher_logo.svg";
import titleLogoTournament from "assets/logo/tournament/tournament_logo.svg";
import {
    MOBILE_PRESENTATION_ACADEMY,
    MOBILE_PRESENTATION_TEACHER,
    MOBILE_PRESENTATION_TOURNAMENT,
} from "entries/mobile-presentation/data";
import MobilePresentation from "entries/mobile-presentation/mobile-presentation";
import SwiperLayout from "entries/swiper-layout/swiper-layout";
import React from "react";
import PremiumContainer from "entries/premium/premium-container";
import {
    SLIDERS_ABOUT,
    SLIDERS_ACADEMY_ABOUT,
    SLIDERS_TEACHER_ABOUT,
    SLIDERS_TOURNAMENT_ABOUT,
} from "views/pages/about";
import AboutProjectPage from "views/pages/about-project/about-project";
import We from "views/pages/about-project/we";
import BuySubscriptionContainer from "views/pages/about/sliders/buy-subscription-slide/buy-subscription-container";
import ArticlePublicPage from "views/pages/articles/article-public-page";
import MyClassPage from "views/pages/my-class/my-class";
import CookiesPolicy from "views/pages/policy/cookies-policy";
import OfferPolicy from "views/pages/policy/offer-policy";
import PrivatePolicy from "views/pages/policy/private-policy";
import TermsPolicy from "views/pages/policy/terms-policy";

export const PAGES_ROUTES = {
    ROOT: "/main",
    ACADEMY_ABOUT: "/main/academy",
    TEACHER_ABOUT: "/main/teacher",
    TOURNAMENT_ABOUT: "/main/tourney",
    POLICY: {
        PRIVATE_POLICY: "/private_policy",
        TERMS: "/terms",
        OFFER: "/offer",
        COOKIES_POLICY: "/cookies_policy",
    },
    MY_CLASS: {
        ROOT: "/class",
    },
    ABOUT_PROJECT: {
        ROOT: "/about",
    },
    WE: {
        ROOT: "/we",
    },
    PREMIUM: {
        ROOT: "/premium",
        SUCCESS: "/premium/success",
        FAIL: "/premium/fail",
    },
    MOBILE: {
        ACADEMY: "/main/m/academy",
        TEACHER: "/main/m/teacher",
        TOURNAMENT: "/main/m/tournament",
    },
    PUBLICATIONS: {
        ROOT: "/publications",
        VIEW: "/publications/:url",
        VIEW_BY_TAG_ID: "/publications/tag/:tagId",
    },
};

export const PageRoutes = [
    {path: PAGES_ROUTES.ROOT, component: <SwiperLayout sliders={SLIDERS_ABOUT} />, exact: true},
    {
        path: PAGES_ROUTES.TEACHER_ABOUT,
        component: <SwiperLayout sliders={SLIDERS_TEACHER_ABOUT} />,
        exact: false,
        withHelper: true,
    },
    {
        path: PAGES_ROUTES.TOURNAMENT_ABOUT,
        component: <SwiperLayout sliders={SLIDERS_TOURNAMENT_ABOUT} />,
        exact: false,
        withHelper: true,
    },
    {
        path: PAGES_ROUTES.ACADEMY_ABOUT,
        component: <SwiperLayout sliders={SLIDERS_ACADEMY_ABOUT} />,
        exact: false,
        withHelper: true,
    },
    {
        path: PAGES_ROUTES.POLICY.PRIVATE_POLICY,
        component: <PrivatePolicy />,
        exact: false,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.POLICY.COOKIES_POLICY,
        component: <CookiesPolicy />,
        exact: false,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.POLICY.OFFER,
        component: <OfferPolicy />,
        exact: false,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.POLICY.TERMS,
        component: <TermsPolicy />,
        exact: false,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.WE.ROOT,
        component: <We />,
        exact: false,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.PREMIUM.ROOT,
        component: <PremiumContainer />,
        exact: true,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.PREMIUM.SUCCESS,
        component: <BuySubscriptionContainer isPaymentDone={true} withPaymentResult={true} />,
        exact: false,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.PREMIUM.FAIL,
        component: <BuySubscriptionContainer isPaymentDone={false} withPaymentResult={true} />,
        exact: false,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.MOBILE.TOURNAMENT,
        component: (
            <MobilePresentation
                slides={MOBILE_PRESENTATION_TOURNAMENT}
                titleLogo={titleLogoTournament}
            />
        ),
        exact: false,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.MOBILE.ACADEMY,
        component: (
            <MobilePresentation slides={MOBILE_PRESENTATION_ACADEMY} titleLogo={titleLogoAcademy} />
        ),
        exact: false,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.MOBILE.TEACHER,
        component: (
            <MobilePresentation slides={MOBILE_PRESENTATION_TEACHER} titleLogo={titleLogoTeacher} />
        ),
        exact: false,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.MY_CLASS.ROOT,
        component: <MyClassPage />,
        exact: false,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.ABOUT_PROJECT.ROOT,
        component: <AboutProjectPage />,
        exact: false,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.PUBLICATIONS.ROOT,
        component: <ArticlePublicPage />,
        exact: true,
        withHelper: false,
    },
    {
        path: PAGES_ROUTES.PUBLICATIONS.VIEW_BY_TAG_ID,
        component: <ArticlePublicPage />,
        exact: true,
        withHelper: false,
    },
];
