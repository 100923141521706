import React from "react";
import PolicyHeader from "views/pages/policy/policy-header";
import styles from "./styles.module.sass";

const PrivatePolicy = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <PolicyHeader />
            </div>
        </div>
    );
};

PrivatePolicy.propTypes = {};

export default PrivatePolicy;
