import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.sass";

const NotificationModalLayer = ({
    children,
    withLightBackground,
    toggle,
    withTimes = false,
    isActive = true,
}) => {
    return (
        <>
            {isActive && (
                <div
                    className={cn({
                        [styles.notifications]: true,
                        [styles.lightBackground]: withLightBackground,
                    })}
                    id="background"
                    onClick={(e) =>
                        toggle ? (e?.target?.id === "background" ? toggle() : null) : null
                    }
                >
                    <div className={styles.content}>
                        {withTimes && (
                            <p className={styles.times} onClick={toggle}>
                                <i className="fas fa-times" />
                            </p>
                        )}

                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

NotificationModalLayer.propTypes = {
    children: PropTypes.any,
    isActive: PropTypes.bool,
    toggle: PropTypes.func,
    withLightBackground: PropTypes.bool,
    withTimes: PropTypes.bool,
};

export default NotificationModalLayer;
