import CreateTest from "entries/constructors/classic-test/views/containers/create-test/create-test";
import {WordSkipAPI} from "entries/constructors/word-skip/core/api/word-skip";
import {WordSkipConstructor} from "entries/constructors/word-skip/views/word-skip-constructor/word-skip-constructor";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";

export const WordSkipManager = ({routerInstance}) => {
    const [testData, setTestData] = useState({});
    const [mode, setMode] = useState(undefined);
    const history = useHistory();

    useEffect(() => {
        if (
            !history.location.state &&
            !history.location.pathname.includes(
                routerInstance.WORD_SKIP_TEST.EDITING.replace(":id", ""),
            )
        ) {
            history.push(routerInstance.ROOT);
        }

        setMode(history.location.state);
    }, []);

    const onCreateTest = (testData) => {
        setTestData(testData);
        history.push(routerInstance.WORD_SKIP_TEST.CONSTRUCTOR);
    };

    return (
        <div>
            <Switch>
                <Route path={routerInstance.WORD_SKIP_TEST.CREATE_TEST}>
                    <CreateTest onCreateTest={onCreateTest} apiInstance={WordSkipAPI} mode={mode} />
                </Route>

                <Route path={routerInstance.WORD_SKIP_TEST.EDITING}>
                    <WordSkipConstructor testData={testData} apiInstance={WordSkipAPI} isEditMode />
                </Route>

                <Route path={routerInstance.WORD_SKIP_TEST.CONSTRUCTOR}>
                    <WordSkipConstructor testData={testData} apiInstance={WordSkipAPI} />
                </Route>
            </Switch>
        </div>
    );
};

WordSkipManager.propTypes = {
    routerInstance: PropTypes.any,
};
