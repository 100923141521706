import React from "react";
import styles from "../two-column-template-styles.module.sass";
import NotificationModalContent from "../../notifications-modal/notification-modal-content/notification-modal-content";
import ravenFunny from "../../../assets/characters/emotions/raven_funny_04.svg";
import {MDBBtn} from "mdbreact";

interface IProps {
    onClick: () => void;
}

const SubscriptionPromocodeSuccessful = ({onClick}: IProps) => {
    return (
        <NotificationModalContent className={styles.contentWrapper}>
            <div className={styles.content}>
                <img src={ravenFunny} className={styles.image} alt="raven-with-books" />
                <div className={styles.descriptionBlock}>
                    <p className={styles.title}>Успех!</p>
                    <p className={styles.description}>Промокод на 100% скидку применён.</p>
                </div>
            </div>
            <MDBBtn color="info" onClick={onClick}>
                Супер!
            </MDBBtn>
        </NotificationModalContent>
    );
};

export default SubscriptionPromocodeSuccessful;
