import {Select} from "antd";
import React, {useEffect, useState} from "react";
import {ITag} from "../../interfaces/ITag";
import {TagsAPI} from "../../core/api/tags-api";
import {AxiosResponse} from "axios";
import {ISelect} from "../../interfaces/ISelect";

interface IProps {
    threeSectionTagId?: number;
    twoSectionTagId?: number;
    sectionTagId?: number;
    handleSelect: (id: any) => void;
    selectedTag: ISelect;
}

const TagSelect = ({sectionTagId, handleSelect, selectedTag}: IProps) => {
    const [tags, setTags] = useState<ISelect[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const convertTagsToOptions = (arr: ITag[]) => {
        if (!arr?.length) return;

        return arr.map((tag) => ({value: tag.id, label: tag.name}));
    };

    const getTagsBySectionTag = () => {
        if (!sectionTagId) return;
        setIsLoading(true);
        TagsAPI.getTagsBySectionTagId(sectionTagId)
            .then((response: AxiosResponse<ITag[]>) => setTags(convertTagsToOptions(response.data)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (sectionTagId) {
            getTagsBySectionTag();
        }
    }, [sectionTagId]);

    return (
        <React.Fragment>
            {tags && (
                <Select
                    defaultValue={selectedTag}
                    onChange={handleSelect}
                    options={tags}
                    loading={isLoading}
                />
            )}
        </React.Fragment>
    );
};

export default TagSelect;
