import React, {useEffect, useState} from "react";
import {QuestionAPI} from "../../core/api/question";
import TaskList from "./task-list";
import {IPageable} from "../../interfaces/IPageable";
import {ITask} from "../../interfaces/ITask";
import styles from "../../views/components/list-with-pagination/list-with-pagination.module.sass";
import {MDBBtn} from "mdbreact";
import {toast} from "react-toastify";

const TaskListContainer = () => {
    const [tasks, setTasks] = useState<IPageable<ITask> | undefined | any>(undefined);
    const [pageSize, setPageSize] = useState(20);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        getAllTasks();
    }, []);

    useEffect(() => {
        if (isSearching) {
            handleSearch();
            return;
        }

        getAllTasks();
    }, [page, pageSize]);

    const getAllTasks = () => {
        setIsLoading(true);
        QuestionAPI.getQuestions(page, pageSize)
            .then((response) => setTasks(response.data))
            .finally(() => setIsLoading(false));
    };

    const handleSearch = () => {
        if (!searchParam.trim()) {
            toast.error("Заполните поле поиска");
            return;
        }

        setTasks(undefined);

        if (Number.isInteger(parseInt(searchParam))) {
            setIsLoading(true);
            QuestionAPI.getQuestion(searchParam)
                .then((response) => {
                    setTasks({content: [response.data]});
                })
                .catch((err) => console.log(err))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(true);
            QuestionAPI.getQuestionByName(searchParam, page, pageSize)
                .then((response) => {
                    setTasks(response.data);
                    setIsSearching(true);
                })
                .catch((err) => console.log(err))
                .finally(() => setIsLoading(false));
        }
    };

    const handleReset = () => {
        setIsSearching(false);
        getAllTasks();
        setPage(1);
        setSearchParam("");
    };

    const handlePaginationChange = (page: number, size: number) => {
        setPage(page);
        setPageSize(size);
    };

    return (
        <div>
            <div className={styles.search}>
                <input
                    type="text"
                    value={searchParam}
                    onChange={(event) => setSearchParam(event.target.value)}
                    autoComplete="false"
                    className="form-control"
                    id="input"
                    name="input"
                    placeholder="ID или название задания"
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            handleSearch();
                        }
                    }}
                />
                <MDBBtn color="indigo" size="sm" onClick={handleSearch}>
                    Найти
                </MDBBtn>
                <MDBBtn color="info" size="sm" className="ml-1" onClick={handleReset}>
                    Сбросить
                </MDBBtn>
            </div>

            <TaskList
                tasks={tasks}
                handlePaginationChange={handlePaginationChange}
                isLoading={isLoading}
            />
        </div>
    );
};

export default TaskListContainer;
