import PropTypes from "prop-types";
import React, {useEffect, useRef} from "react";
import {Rect, Transformer} from "react-konva";

export const BasketRectangle = ({shapeProps, isSelected, onSelect, onChange, basketIdForDrop}) => {
    const shapeRef = useRef();
    const trRef = useRef();

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Rect
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: Math.floor(e.target.x()),
                        y: Math.floor(e.target.y()),
                    });
                }}
                fill={shapeProps.id === basketIdForDrop ? "rgba(0, 0, 0, 0.4)" : shapeProps.fill}
                strokeWidth={2}
                stroke={shapeProps.color}
                onTransformEnd={() => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: Math.floor(node.x()),
                        y: Math.floor(node.y()),
                        // set minimal value
                        width: Math.floor(Math.max(5, node.width() * scaleX)),
                        height: Math.floor(Math.max(node.height() * scaleY)),
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

BasketRectangle.propTypes = {
    basketIdForDrop: PropTypes.any,
    deleteBasket: PropTypes.func,
    isSelected: PropTypes.bool,
    shapeProps: PropTypes.object,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
};
