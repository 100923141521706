import React, {useEffect, useState} from "react";
import AdminPanelFormCreator, {
    EFormCreatorTypes,
} from "../../admin-panel-form-creator/admin-panel-form-creator";
import {AdminPanelUserAPI} from "../admin-panel-users-api";
import {toast} from "react-toastify";
import GenericTable from "../../../generic-table/generic-table";

const AdminPanelUsersAcceptedByTeacher = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [students, setStudents] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [teacherId, setTeacherId] = useState<number>(0);
    const [totalElements, setTotalElements] = useState(0);

    const handleGetStudentsClick = (formData: any) => {
        let {id} = formData;

        if (!id) {
            toast.error("Введите ID");
            return;
        }

        setTeacherId(id);
    };

    const getStudents = (id: number) => {
        setStudents(null);
        setIsLoading(true);
        AdminPanelUserAPI.getAcceptedStudents(id, page)
            .then((response) => {
                setStudents(response.data.content);
                setTotalElements(response.data.totalElements);
            })
            .catch((err) => toast.error(err.response.data.message))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (!teacherId) {
            return;
        }

        getStudents(teacherId);
    }, [page, teacherId]);

    return (
        <React.Fragment>
            <AdminPanelFormCreator
                handleClick={handleGetStudentsClick}
                bannerText="Получить учеников"
                formConstructor={[
                    {
                        name: "id",
                        labelText: "ID Учителя",
                        type: EFormCreatorTypes.TEXT,
                    },
                    {
                        name: "Получить",
                        type: EFormCreatorTypes.BUTTON,
                        color: "success",
                    },
                ]}
                isLoading={isLoading}
            />

            <GenericTable
                headers={["ID", "Username", "Имя", "Фамилия", "Дата создания", "Учитель"]}
                outputKeys={[
                    "student.id",
                    "student.userName",
                    "student.firstName",
                    "student.lastName",
                    "createdOn",
                    "teacherRequestJoinStatus",
                ]}
                data={students}
                page={page}
                setPage={setPage}
                totalElements={totalElements}
            />
        </React.Fragment>
    );
};

export default AdminPanelUsersAcceptedByTeacher;
