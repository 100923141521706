import {Request} from "core/utils/request";

export const TagUtils = {
    getBackgrounds: () => {
        return Request.get(`tag-utils/background`);
    },

    getClasses: () => {
        return Request.get(`tag-utils/class`);
    },

    getSchoolSubjects: () => {
        return Request.get(`tag-utils/school-subject`);
    },
};
