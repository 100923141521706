import {url} from "config";
import {DEFAULT_FONT_SIZE} from "config/project";
import {TextWithWordSkipContainer} from "entries/player/views/task-players/word-skip-additional-player/text-with-word-skip/text-with-word-skip-container";
import {SKIP_ID} from "entries/constructors/word-skip/views/text-with-skips/text-with-skips-container";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {Question} from "views/components/question";

export const WordSkipAdditionalPlayer = ({
    question,
    answers,
    properties,
    selectWordHandle,
    selectedAnswers,
}) => {
    // данные для вопроса
    const [questionText, setQuestionText] = useState("");
    const [questionNarrator, setQuestionNarrator] = useState({});

    const [text, setText] = useState("");

    // параметры
    const [questionSizeText, setQuestionSizeText] = useState(DEFAULT_FONT_SIZE);

    const [background, setBackground] = useState("");

    useEffect(() => {}, [background]);

    useEffect(() => {
        if (properties) {
            // фон
            if (properties.backgroundImage) {
                setBackground(properties.backgroundImage.fileDownloadUri.slice(1));
            } else {
                setBackground("data/image-bank/ae86cc0f-5bc8-457f-98bb-454a0ea993a6.svg");
            }

            // персонаж
            if (properties.narrator) {
                let narratorUrl = `${url}${properties.narrator.image.fileDownloadUri.slice(1)}`;
                setQuestionNarrator({url: narratorUrl});
            }

            // размер шрифта вопроса
            if (properties.sizeTitle) {
                setQuestionSizeText(properties.sizeTitle);
            }
        }
    }, [properties]);

    useEffect(() => {
        if (question) {
            // вопрос
            if (question.questionText) {
                setQuestionText(question.questionText);
            }

            // текст диктанта
            if (question.text) {
                setText(question.text);
            }
        }
    }, [question, questionSizeText]);

    useEffect(() => {
        // console.log(question, answers, properties);
    }, []);

    return (
        <div className="h-100">
            {question || answers || properties ? (
                <TestContent background={background} withEditBorder={false}>
                    <TestConstructor>
                        <div className="animate__animated animate__fadeIn">
                            <Question
                                testHasNarrator
                                isEditMode={false}
                                text={questionText}
                                narrator={questionNarrator}
                                textTitleSize={questionSizeText}
                                image={{}}
                            />
                        </div>

                        <div className="mt-2 animate__animated animate__fadeIn">
                            {text.trim() ? (
                                <TextWithWordSkipContainer
                                    text={text}
                                    answers={answers}
                                    skipSymbol={SKIP_ID}
                                    selectedAnswers={selectedAnswers}
                                    selectWordHandle={selectWordHandle}
                                />
                            ) : (
                                <p>Текста нет...</p>
                            )}
                        </div>
                    </TestConstructor>
                </TestContent>
            ) : (
                <p>Данных нет</p>
            )}
        </div>
    );
};

WordSkipAdditionalPlayer.propTypes = {
    answers: PropTypes.array,
    properties: PropTypes.object,
    question: PropTypes.object,
    selectedAnswers: PropTypes.array,
    selectWordHandle: PropTypes.func,
};
