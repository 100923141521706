import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";

export default function FixedBottomBar(props) {
	return (
		<div className="fixed-bottom">
			<MDBContainer className="site-width">
				<MDBRow>
					<MDBCol className="grey lighten-3 py-3">
						{props.children}
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</div>
	)
}
