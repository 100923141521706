import React from "react";
import PropTypes from "prop-types";

import {STEP} from "./constants";

export const SetTestAnswer = (props) => {
    return (
        <div>
            <div>{props.children}</div>
            <div>Params</div>
        </div>
    );
};

SetTestAnswer.propTypes = {
    toNext: PropTypes.func.isRequired,
    toPrev: PropTypes.func.isRequired,
};

SetTestAnswer.step = STEP.ANSWER;
