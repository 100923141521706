import React, {useEffect, useState} from "react";
import CKEditorTextModal from "../../ckeditor-text-modal/ckeditor-text-modal";
import styles from "./styles.module.sass";
import ArticleCreateNewBlock from "../article-create-new-block/article-create-new-block";
import ArticleBlocks from "../article-blocks/article-blocks";
import {MDBBtn} from "mdbreact";
import {ArticleAPI} from "../core/article-api";
import {Link, useHistory, useParams} from "react-router-dom";
import {IPublicationBlockText} from "../../../interfaces/IPublicationBlockText";
import {IPublicationDto} from "../../../interfaces/IPublicationDto";
import {toast} from "react-toastify";
import {IPublicationBlockDto} from "../../../interfaces/IPublicationBlockDto";
import {EPublicationBlockTypes} from "../../../enums/EPublicationBlockTypes";
import NewArticleImageModal, {
    IPublicationImagePayload,
} from "../article-modals/new-article-image-modal/new-article-image-modal";
import {IPublicationBlockImage} from "../../../interfaces/IPublicationBlockImage";
import {ImageUploadModal} from "../../../views/components/image-upload-modal/image-upload-modal";
import {IPublicationAddImage} from "../../../interfaces/IPublicationAddImage";
import NewArticleGalleryModal, {
    IPublicationGalleryPayload,
} from "../article-modals/new-article-gallery-modal/new-article-gallery-modal";
import {IPublicationBlockGallery} from "../../../interfaces/IPublicationBlockGallery";
import {EPublicationCustomActionTypes} from "../../../enums/EPublicationCustomActionTypes";
import {IPublicationAddGalleryImage} from "../../../interfaces/IPublicationAddGalleryImage";
import {IPublicationBlockPlayer} from "../../../interfaces/IPublicationBlockPlayer";
import {Helmet} from "react-helmet";
import {IPublicationBlockFiles} from "../../../interfaces/IPublicationBlockFiles";
import {url as baseUrl} from "../../../config";
import TagSelector from "../article-modals/tag-selector/tag-selector";
import {ISelect} from "../../../interfaces/ISelect";
import TagList from "../../tags/views/tag-list/tag-list";
import {TagColors} from "../core/tag-colors";
import {Switch} from "../../../views/components/switch";
import {IPublication} from "../../../interfaces/IPublication";
import {url as hostURL} from "../../../config/url";
import {PAGES_ROUTES} from "../../../views/routes/routes";
import {ADMIN_PANEL_ROUTES} from "../../admin-panel/core/routes";
import PlayerV2NotificationLoading from "../../player-v2/views/player-v2-notifications/player-v2-notification-loading/player-v2-notification-loading";
import NotificationModalLayer from "../../notifications-modal/notification-modal-layer";
import YandexAd from "../../yandex-ad/yandex-ad";
import ArticleFooterBanner from "../article-footer-banner/article-footer-banner";
import cn from "classnames";
import SpinnerWithText from "../../spinner/spinner";

interface IProps {
    isPublic?: boolean;
}

const Article = ({isPublic}: IProps) => {
    let {url} = useParams<any>();
    const [article, setArticle] = useState<IPublicationDto | null>(null);
    const [isViewMode, setIsViewMode] = useState(false);
    const [isDragOn, setIsDragOn] = useState(false);

    const [blockForEdit, setBlockForEdit] = useState<IPublicationBlockDto | null>(null);
    const [id, setId] = useState(0);

    const [textModalIsOpen, setTextModalIsOpen] = useState(false);
    const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
    const [galleryModalIsOpen, setGalleryModalIsOpen] = useState(false);

    const [uploadImageModalIsOpen, setUploadImageModalIsOpen] = useState(false);
    const [imageBlockIdForAddImage, setImageBlockIdForAddImage] = useState<null | number>(null);

    const [selectTagsModalIsOpen, setSelectTagsModalIsOpen] = useState(false);
    const [isSimilarLoading, setIsSimilarLoading] = useState(false);
    const [similarPublications, setSimilarPublications] = useState<IPublication[] | undefined>(
        undefined,
    );
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setIsViewMode(!!isPublic);
    }, [isPublic]);

    useEffect(() => {
        handleGetPublicationData();
    }, [url]);

    useEffect(() => {
        if (!id) return;

        getSimilarArticles();
    }, [id]);

    const handleGetPublicationData = () => {
        setIsLoading(true);
        ArticleAPI.getPublicationByUrl(url)
            .then((response) => {
                setArticle(response.data);
                setId(response.data.publication.id);
            })
            .finally(() => setIsLoading(false));
    };

    const getSimilarArticles = () => {
        setIsSimilarLoading(true);
        ArticleAPI.getSimilar(id)
            .then((response) => setSimilarPublications(response.data))
            .catch(() => toast.error("Ошибка получения похожих публикаций"))
            .finally(() => setIsSimilarLoading(false));
    };

    const sortItemsByPosition = (items: any[]) => {
        return items.sort((a: any, b: any) => {
            if (!a.positionBlock || !b.positionBlock) {
                return 1;
            }

            return a.positionBlock - b.positionBlock;
        });
    };

    const reorderPositions = (fromItemIndex: number, toItemIndex: number) => {
        let sortedBlocksByPosition = sortItemsByPosition(article?.blocks);

        let fromPosition = sortedBlocksByPosition[fromItemIndex].positionBlock;
        let toPosition = sortedBlocksByPosition[toItemIndex].positionBlock;

        sortedBlocksByPosition[fromItemIndex] = {
            ...sortedBlocksByPosition[fromItemIndex],
            positionBlock: toPosition,
        };
        sortedBlocksByPosition[toItemIndex] = {
            ...sortedBlocksByPosition[toItemIndex],
            positionBlock: fromPosition,
        };

        return sortedBlocksByPosition;
    };

    const savePosition = () => {
        let blocksForUpdate: any = [];
        article?.blocks.forEach((item: any) => {
            blocksForUpdate.push(ArticleAPI.changeBlockPosition(item.id, item.positionBlock));
        });

        Promise.all(blocksForUpdate)
            .then(() => toast.success("Позиции обновлены"))
            .catch(() => toast.error("Ошибка обновления позиций"));
    };

    const handleDragEnd = (action: any) => {
        if (!action.destination) {
            return;
        }

        let sourceIndex = action.source.index;
        let destinationIndex = action.destination.index;

        if (sourceIndex === destinationIndex) {
            return;
        }

        let items = reorderPositions(sourceIndex, destinationIndex);
        setArticle({publication: article?.publication, blocks: items});
    };

    let handleCustomAction = (type: EPublicationCustomActionTypes, payload: any) => {
        switch (type) {
            case EPublicationCustomActionTypes.GALLERY_SAVE_IMAGES:
                handleSaveGalleryImages(payload);
                break;
            case EPublicationCustomActionTypes.GALLERY_DELETE_IMAGE:
                handleDeleteImageFromGallery(payload);
                break;
            case EPublicationCustomActionTypes.PLAYER_ADD_TASK:
                handleAddQuestionToPlayerBlock(payload);
                break;
            case EPublicationCustomActionTypes.PLAYER_DELETE_TASK:
                handleDeleteQuestionFromPlayerBlock(payload);
                break;
            case EPublicationCustomActionTypes.GALLERY_CHANGE_NAME:
                handleChangeImageName(payload);
                break;
            case EPublicationCustomActionTypes.FILES_UPDATE_NAME:
                handleChangeFileName(payload);
                break;
            case EPublicationCustomActionTypes.FILES_UPLOAD_FILE:
                handleUploadFile(payload);
                break;
            case EPublicationCustomActionTypes.TITLE_SAVE:
                handleSaveTitle(payload);
                break;
            case EPublicationCustomActionTypes.IMAGE_CHANGE_NAME:
                handleChangeImageName(payload);
                break;
            case EPublicationCustomActionTypes.SIMILAR_ADD_ARTICLE:
                handleAddArticleToSimilarBlock(payload);
                break;
            case EPublicationCustomActionTypes.SIMILAR_DELETE_ARTICLE:
                handleDeleteSimilarArticle(payload);
                break;
            case EPublicationCustomActionTypes.FILES_DELETE_FILE:
                handleDeleteFile(payload);
                break;
            case EPublicationCustomActionTypes.ADD_VIDEO_URL:
                handleUpdateVideoURL(payload);
                break;
        }
    };

    let handleUpdateVideoURL = (payload: any) => {
        let {blockId} = payload;
        ArticleAPI.updateBlockVideoSharing(payload, blockId)
            .then((response) => {
                let newBlock: IPublicationBlockImage = response.data;
                let updatedBlock = article?.blocks.map((block: IPublicationBlockDto) =>
                    block.id === blockId ? newBlock : block,
                );

                setArticle({
                    publication: article?.publication,
                    blocks: updatedBlock,
                });

                setBlockForEdit(null);
            })
            .finally(() => {
                console.log("Block image promise finally");
            });

        console.log(payload);
    };

    let handleDeleteBlock = (id: number) => {
        ArticleAPI.deletePublicationBlock(id)
            .then(() => {
                let updatedBlocks = article?.blocks.filter((a: any) => a.id !== id);
                setArticle({publication: article?.publication, blocks: updatedBlocks});
            })
            .catch((e) => {
                console.log(e);
                toast.error("Ошибка удаления блока");
            });
    };

    let getBlockById = (id: number) => {
        let block: IPublicationBlockDto = article?.blocks.find((block: any) => block.id === id);
        return block;
    };

    let getLastPosition = (block?: any) => {
        if (!article || !article.blocks.length) {
            return 1;
        }

        if (block) {
            if (!block.length) {
                return 1;
            }

            return Math.max(...block.map((b: any) => b.positionQuestion)) + 1;
        }

        return Math.max(...article.blocks.map((b: IPublicationBlockDto) => b.positionBlock)) + 1;
    };

    let handleAddImage = (blockId: number | null, image?: any) => {
        setUploadImageModalIsOpen(false);

        let block;
        if (blockId) {
            block = getBlockById(blockId);
            if (block) {
                setBlockForEdit(block);
            }
        }

        if (!imageBlockIdForAddImage) {
            setImageBlockIdForAddImage(blockId);
            setUploadImageModalIsOpen(true);
            return;
        } else {
            if (!blockId) {
                return;
            }
            if (image) {
                let blockImageDto: IPublicationAddImage = {
                    name: image.image.name,
                    file: image.image,
                    id: 0,
                };
                ArticleAPI.addBlockImage(blockImageDto, blockId)
                    .then((response) => {
                        let newBlock: IPublicationBlockImage = response.data;
                        let updatedBlock = article?.blocks.map((block: IPublicationBlockDto) =>
                            block.id === blockId ? newBlock : block,
                        );
                        setArticle({
                            publication: article?.publication,
                            blocks: updatedBlock,
                        });
                    })
                    .finally(() => console.log("image promise finally"));
                setBlockForEdit(null);
                setImageBlockIdForAddImage(null);
            }
        }
    };

    let handleEditBlock = (id: number) => {
        let block = getBlockById(id);
        if (block) {
            setBlockForEdit(block);
            switch (block.objectType) {
                case EPublicationBlockTypes.PublicationBlockTextDto:
                    setTextModalIsOpen(true);
                    break;
                case EPublicationBlockTypes.PublicationBlockImageDto:
                    setImageModalIsOpen(true);
                    break;
                case EPublicationBlockTypes.PublicationBlockGalleryDto:
                    setGalleryModalIsOpen(true);
                    break;
            }
        }
    };

    let handleSaveText = (text: string) => {
        setTextModalIsOpen(false);
        if (blockForEdit) {
            let textBlockDto: IPublicationBlockText = {
                ...blockForEdit,
                blockText: text,
                id: blockForEdit.id,
            };

            handleUpdateTextBlock(textBlockDto);
            return;
        }

        let textBlockDto: IPublicationBlockText = {
            id: 0,
            blockText: text,
            positionBlock: getLastPosition(),
        };

        ArticleAPI.createBlockText(textBlockDto, id).then((response) => {
            let newBlock: IPublicationBlockText = response.data;
            setArticle({
                publication: article?.publication,
                blocks: article?.blocks ? [...article.blocks, newBlock] : [newBlock],
            });
        });
    };

    let handleUpdateTextBlock = (textBlockDto: IPublicationBlockText) => {
        ArticleAPI.updateBlockText(textBlockDto)
            .then((response) => {
                let updatedBlocks = article?.blocks.map((block: IPublicationBlockDto) =>
                    block.id === response.data.id ? response.data : block,
                );
                setArticle({publication: article?.publication, blocks: updatedBlocks});
            })
            .catch(() => {
                toast.error("Ошибка обновления блока");
            })
            .finally(() => {
                setBlockForEdit(null);
            });
    };

    let handleSaveBlockImage = (payload: IPublicationImagePayload) => {
        setImageModalIsOpen(false);

        let settings = {
            descriptionStyle: payload.descriptionStyle,
            blockStyle: payload.blockStyle,
        };

        let imageBlockDto: IPublicationBlockImage = {
            blockSettings: btoa(JSON.stringify(settings)),
            id: 0,
            positionBlock: getLastPosition(),
        };

        if (blockForEdit) {
            imageBlockDto.positionBlock = blockForEdit.positionBlock || 0;
            handleEditBlockImage(imageBlockDto);
            return;
        }

        ArticleAPI.createBlockImage(imageBlockDto, id)
            .then((response) => {
                let newBlock: IPublicationBlockImage = response.data;
                setArticle({
                    publication: article?.publication,
                    blocks: article?.blocks ? [...article.blocks, newBlock] : [newBlock],
                });
            })
            .catch(() => {
                toast.error("Ошибка добавления блока изображений");
            })
            .finally(() => {
                console.log("image block promise finally");
            });
    };

    let handleEditBlockImage = (payload: IPublicationBlockImage) => {
        if (!blockForEdit?.id) {
            console.log("no block for edit");
            return;
        }

        ArticleAPI.updateBlockImage(payload, blockForEdit.id)
            .then((response) => {
                let newBlock: IPublicationBlockImage = response.data;
                let updatedBlock = article?.blocks.map((block: IPublicationBlockDto) =>
                    block.id === blockForEdit.id ? newBlock : block,
                );
                setArticle({
                    publication: article?.publication,
                    blocks: updatedBlock,
                });

                setBlockForEdit(null);
            })
            .finally(() => {
                console.log("Block image promise finally");
            });
    };

    let handleSaveGalleryBlock = (payload: IPublicationGalleryPayload) => {
        setGalleryModalIsOpen(false);

        if (!payload) {
            return;
        }

        let settings: IPublicationGalleryPayload = {
            descriptionStyle: payload.descriptionStyle,
            blockStyle: payload.blockStyle,
        };

        let galleryBlock: IPublicationBlockGallery = {
            id: 0,
            blockSettings: btoa(JSON.stringify(settings)),
            positionBlock: getLastPosition(),
        };

        if (blockForEdit) {
            galleryBlock.positionBlock = blockForEdit.positionBlock || 0;
            handleEditBlockGallery(galleryBlock);
            return;
        }

        ArticleAPI.createBlockGallery(galleryBlock, id)
            .then((response) => {
                let newBlock: IPublicationBlockGallery = response.data;
                setArticle({
                    publication: article?.publication,
                    blocks: article?.blocks ? [...article.blocks, newBlock] : [newBlock],
                });
            })
            .finally(() => {
                console.log("gallery block promise finally");
            });
    };

    let handleSaveGalleryImages = (payload: any) => {
        let {images, blockId} = payload;

        if (!images.length) {
            toast.error("Нет изображений");
            return;
        }

        let promises: any = [];
        images.forEach((file: Blob, index: number) => {
            let updatedFile: IPublicationAddGalleryImage = {
                positionGallery: index,
                name: "",
                file,
                id: 0,
            };

            promises.push(ArticleAPI.addImageToGallery(updatedFile, blockId));
        });

        Promise.all(promises).then((response) => {
            if (!response[response.length - 1].data) {
                toast.error("Ошибка обновления изображений");
                return;
            }
            let lastUpdate: IPublicationBlockGallery = response[response.length - 1].data;
            let updatedBlocks = article?.blocks.map((block: IPublicationBlockDto) =>
                block.id === lastUpdate.id ? lastUpdate : block,
            );
            setArticle({publication: article?.publication, blocks: updatedBlocks});
        });
    };

    let handleChangeImageName = (payload: any) => {
        let {blockId, imageId, name} = payload;

        if (!(blockId || imageId || name)) {
            toast.error("Ошибка сохранения, нехватает данных");
            return;
        }

        ArticleAPI.updateImageName(blockId, imageId, name)
            .then((response) => {
                if (response.data) {
                    let updatedBlock: IPublicationBlockDto = response.data;
                    let updatedArticleBlocks = article?.blocks.map(
                        (block: IPublicationBlockDto) => {
                            if (block.id === blockId) {
                                return updatedBlock;
                            } else {
                                return block;
                            }
                        },
                    );

                    setArticle({
                        publication: article?.publication,
                        blocks: updatedArticleBlocks,
                    });
                }
            })
            .finally(() => {
                console.log("Block gallery image name change promise finally");
            });
    };

    let handleChangeFileName = (payload: any) => {
        let {blockId, fileId, name} = payload;

        if (!(blockId || fileId || name)) {
            toast.error("Ошибка сохранения, нехватает данных");
            return;
        }

        ArticleAPI.updateFileName(blockId, fileId, name)
            .then((response) => {
                if (response.data) {
                    let updatedBlock: IPublicationBlockDto = response.data;
                    let updatedArticleBlocks = article?.blocks.map(
                        (block: IPublicationBlockDto) => {
                            if (block.id === blockId) {
                                return updatedBlock;
                            } else {
                                return block;
                            }
                        },
                    );

                    setArticle({
                        publication: article?.publication,
                        blocks: updatedArticleBlocks,
                    });
                }
            })
            .finally(() => {
                console.log("Block files image name change promise finally");
            });
    };

    let handleEditBlockGallery = (payload: IPublicationBlockGallery) => {
        if (!blockForEdit?.id) {
            console.log("no block for edit");
            return;
        }

        ArticleAPI.updateBlockGallery(payload, blockForEdit.id)
            .then((response) => {
                let newBlock: IPublicationBlockGallery = response.data;
                let updatedBlock = article?.blocks.map((block: IPublicationBlockDto) =>
                    block.id === blockForEdit.id ? newBlock : block,
                );
                setArticle({
                    publication: article?.publication,
                    blocks: updatedBlock,
                });

                setBlockForEdit(null);
            })
            .finally(() => {
                console.log("Block gallery promise finally");
            });
    };

    let handleDeleteImageFromGallery = (payload: any) => {
        let {blockId, imageId} = payload;

        ArticleAPI.deleteImageFromGallery(imageId)
            .then(() => {
                let blocks = article?.blocks;
                blocks = blocks.map((block: any) => {
                    if (block.id !== blockId) {
                        return block;
                    }

                    if (block.galleryImages && block.galleryImages.length) {
                        block.galleryImages = block.galleryImages.filter(
                            (image: any) => image.id !== imageId,
                        );
                    }

                    return block;
                });

                setArticle({
                    publication: article?.publication,
                    blocks,
                });
            })
            .finally(() => {
                console.log("Block gallery promise finally [delete]");
            });
    };

    let handleSavePlayerBlock = () => {
        let payload: IPublicationBlockPlayer = {
            positionBlock: getLastPosition(),
            id: 0,
            playerQuestions: undefined,
        };

        ArticleAPI.createBlockPlayer(payload, id)
            .then((response) => {
                let newBlock: IPublicationBlockPlayer = response.data;
                setArticle({
                    publication: article?.publication,
                    blocks: article?.blocks ? [...article.blocks, newBlock] : [newBlock],
                });
            })
            .finally(() => console.log("Promise finally player block"));
    };

    let handleAddQuestionToPlayerBlock = (payload: any) => {
        let {questionId, blockId} = payload;

        if (!questionId) {
            return toast.error("Неверный ID");
        }

        let playerBlock = article?.blocks.find(
            (block: IPublicationBlockDto) => block.id === blockId,
        );

        if (!playerBlock) {
            return toast.error("Блок не найден. Внутренняя ошибка");
        }

        ArticleAPI.addQuestionBlockPlayer(
            questionId,
            blockId,
            getLastPosition(playerBlock.playerQuestions),
        )
            .then((response) => {
                let newBlock: IPublicationBlockPlayer = response.data;
                let updatedBlock = article?.blocks.map((block: IPublicationBlockDto) =>
                    block.id === blockId ? newBlock : block,
                );

                setArticle({
                    publication: article?.publication,
                    blocks: updatedBlock,
                });
            })
            .finally(() => console.log("Finally promise Question Add"));
    };

    let handleDeleteQuestionFromPlayerBlock = (payload: any) => {
        let {blockId, questionId} = payload;

        ArticleAPI.deleteQuestionFromBlockPlayer(questionId)
            .then(() => {
                let blocks = article?.blocks;
                blocks = blocks.map((block: any) => {
                    if (block.id !== blockId) {
                        return block;
                    }

                    if (block.playerQuestions && block.playerQuestions.length) {
                        block.playerQuestions = block.playerQuestions.filter(
                            (question: any) => question.id !== questionId,
                        );
                    }

                    return block;
                });

                setArticle({
                    publication: article?.publication,
                    blocks,
                });
            })
            .finally(() => {
                console.log("Block gallery promise finally [delete]");
            });
    };

    let handleClickFileItem = () => {
        let payload: IPublicationBlockFiles = {
            positionBlock: getLastPosition(),
            id: 0,
            documentFiles: undefined,
        };

        ArticleAPI.createDocumentBlock(payload, id)
            .then((response) => {
                let newBlock: IPublicationBlockFiles = response.data;
                setArticle({
                    publication: article?.publication,
                    blocks: article?.blocks ? [...article.blocks, newBlock] : [newBlock],
                });
            })
            .finally(() => console.log("Promise finally document file"));
    };

    let handleUploadFile = (payload: any) => {
        let {blockId, file} = payload;

        if (!file) {
            toast.error("Ошибка выбора файла");
            return;
        }

        let fileBlock = article?.blocks.find((block: IPublicationBlockDto) => block.id === blockId);
        let position = getLastPosition(fileBlock);

        ArticleAPI.addFileToFileBlock(blockId, file, position)
            .then((response) => {
                let updatedBlock = article?.blocks.map((block: IPublicationBlockDto) =>
                    block.id === blockId ? response.data : block,
                );

                setArticle({
                    publication: article?.publication,
                    blocks: updatedBlock,
                });
            })
            .catch(() => toast.error("Ошибка загрузки файла"))
            .finally(() => console.log("Promise finally document file upload"));
    };

    let handleSaveTitle = (payload: any) => {
        let block: IPublicationBlockDto = payload.block;
        let {title} = payload;

        let API;
        switch (block.objectType) {
            case EPublicationBlockTypes.PublicationBlockTextDto:
                API = ArticleAPI.updateBlockText;
                break;
            case EPublicationBlockTypes.PublicationBlockDocumentDto:
                API = ArticleAPI.updateBlockDocument;
                break;
            case EPublicationBlockTypes.PublicationBlockGalleryDto:
                API = ArticleAPI.updateBlockGallery;
                break;
            case EPublicationBlockTypes.PublicationBlockImageDto:
                API = ArticleAPI.updateBlockImage;
                break;
            case EPublicationBlockTypes.PublicationBlockPlayerDto:
                API = ArticleAPI.updateBlockPlayer;
                break;
            case EPublicationBlockTypes.PublicationBlockSimilarDto:
                API = ArticleAPI.updateBlockSimilar;
                break;
            case EPublicationBlockTypes.PublicationBlockVideoSharingDto:
                API = ArticleAPI.updateBlockVideoSharing;
                break;
        }

        let newBlock: IPublicationBlockDto = {
            ...block,
            description: title,
        };

        if (!API) {
            toast.error("Внутренняя ошибка. Не найден API для блока.");
            return;
        }

        if (block.id) {
            API(newBlock, block.id).then((response) => {
                let updatedArticleBlocks = article?.blocks.map((tb: IPublicationBlockDto) =>
                    tb.id === block.id ? response.data : tb,
                );
                setArticle({
                    publication: article?.publication,
                    blocks: updatedArticleBlocks,
                });
            });
        }
    };

    const handleSaveTags = (tags: ISelect[]) => {
        setSelectTagsModalIsOpen(false);
        let convertedTags = tags.map((tag) => tag.value);
        if (!article?.publication?.id) {
            toast.error("Внутренняя ошибка");
            return;
        }

        ArticleAPI.addTags(article?.publication?.id, convertedTags)
            .then((response) => {
                let updatedTags = response.data;
                let updatedPublication: any = {
                    ...article.publication,
                    tags: updatedTags,
                };
                setArticle({
                    blocks: article.blocks,
                    publication: updatedPublication,
                });
            })
            .catch(() => toast.error("Ошибка сохранения тегов"));
    };

    const handleDeleteTag = (tagId: number) => {
        if (!article?.publication?.id) {
            toast.error("Внутренняя ошибка");
            return;
        }

        ArticleAPI.deleteTag(article?.publication?.id, tagId)
            .then((response) => {
                if (response.status === 200) {
                    let updatedTags = article.publication?.tags?.filter((t) => t.id !== tagId);

                    let updatedPublication: any = {
                        ...article.publication,
                        tags: updatedTags,
                    };

                    setArticle({
                        blocks: article.blocks,
                        publication: updatedPublication,
                    });
                }
            })
            .catch(() => toast.error("Ошибка удаления тега"));
    };

    const handleClickSimilarItem = () => {
        let payload: IPublicationBlockDto = {
            positionBlock: getLastPosition(),
            id: 0,
        };

        ArticleAPI.createBlockSimilar(payload, id)
            .then((response) => {
                let newBlock: IPublicationBlockDto = response.data;
                setArticle({
                    publication: article?.publication,
                    blocks: article?.blocks ? [...article.blocks, newBlock] : [newBlock],
                });
            })
            .finally(() => console.log("Promise finally similiar create"));
    };

    const handleClickMonogramItem = () => {
        let payload: IPublicationBlockDto = {
            positionBlock: getLastPosition(),
            id: 0,
        };

        ArticleAPI.createBlockMonogram(payload, id)
            .then((response) => {
                let newBlock: IPublicationBlockDto = response.data;
                setArticle({
                    publication: article?.publication,
                    blocks: article?.blocks ? [...article.blocks, newBlock] : [newBlock],
                });
            })
            .finally(() => console.log("Promise finally monogram create"));
    };

    const handleClickVideoItem = () => {
        let payload: IPublicationBlockDto = {
            positionBlock: getLastPosition(),
            id: 0,
        };

        ArticleAPI.createBlockVideoSharing(payload, id)
            .then((response) => {
                let newBlock: IPublicationBlockDto = response.data;
                setArticle({
                    publication: article?.publication,
                    blocks: article?.blocks ? [...article.blocks, newBlock] : [newBlock],
                });
            })
            .finally(() => console.log("Promise finally video create"));
    };

    const handleAddArticleToSimilarBlock = (payload: any) => {
        let {articleId, blockId} = payload;

        let similarBlock = article?.blocks.find(
            (block: IPublicationBlockDto) => block.id === blockId,
        );

        if (!similarBlock || !blockId) {
            toast.error("Ошибка. Не найден блок для редактирования");
            return;
        }

        let articleForSave = {
            [0]: articleId,
        };

        ArticleAPI.addArticleToBlockSimilar(blockId, articleForSave).then((response) => {
            let updatedArticleBlocks = article?.blocks.map((tb: IPublicationBlockDto) =>
                tb.id === blockId ? response.data : tb,
            );
            setArticle({
                publication: article?.publication,
                blocks: updatedArticleBlocks,
            });
        });
    };

    const handleDeleteSimilarArticle = (payload: any) => {
        let {articleId, blockId} = payload;

        if (!articleId || !blockId) {
            toast.error("Ошибка. Не найден блок");
            return;
        }

        ArticleAPI.deleteSimilarArticle(articleId).then((response) => {
            let updatedArticleBlocks = article?.blocks.map((tb: IPublicationBlockDto) =>
                tb.id === blockId ? response.data : tb,
            );
            setArticle({
                publication: article?.publication,
                blocks: updatedArticleBlocks,
            });
        });
    };

    const handleDeleteFile = (payload: any) => {
        let {fileId} = payload;
        ArticleAPI.deleteFile(fileId).then((res) => {
            let updatedArticleBlocks = article?.blocks.map((tb: IPublicationBlockDto) =>
                tb.id === res.data.id ? {...tb, documentFiles: res.data.documentFiles} : tb,
            );

            setArticle({
                publication: article?.publication,
                blocks: updatedArticleBlocks,
            });
        });
    };

    if (isLoading) {
        return (
            <NotificationModalLayer isActive={true}>
                <PlayerV2NotificationLoading />
            </NotificationModalLayer>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <script type="text/javascript">
                    {` VK.Widgets.Group("vk_groups2", {mode: 4, wide: 1, height: 400, color1: "FFFFFF", color2: "000000", color3: "68C8F2"}, 208897875);`}
                </script>
            </Helmet>

            <TagSelector
                isOpen={selectTagsModalIsOpen}
                toggle={() => setSelectTagsModalIsOpen(!selectTagsModalIsOpen)}
                handleSave={handleSaveTags}
            />
            <div className={styles.article}>
                <div className={styles.articlePage}>
                    <div className={styles.articleMain}>
                        {article?.publication?.imageThree && (
                            <div className={styles.imageWrapper}>
                                <img
                                    src={
                                        baseUrl +
                                        article.publication.imageThree.fileDownloadUri.slice(1)
                                    }
                                    alt="Обложка"
                                />
                            </div>
                        )}

                        <div className={styles.article}>
                            <Helmet>
                                <title>{article?.publication?.title}</title>
                                <meta
                                    name="description"
                                    content={article?.publication?.metaDescription}
                                />
                                <meta
                                    name="keywords"
                                    content={article?.publication?.metaKeywords}
                                />
                            </Helmet>

                            <h1 className={styles.articleTitle}>
                                {article?.publication?.title || "Название статьи"}
                            </h1>

                            <TagList
                                tags={article?.publication?.tags}
                                isCards
                                className={styles.tags}
                                cardClassName={styles.tag}
                                withShowAllTags
                                handleDeleteTag={handleDeleteTag}
                                customTagColors={TagColors}
                                hideDelete={isPublic || isViewMode}
                                url={PAGES_ROUTES.PUBLICATIONS.VIEW_BY_TAG_ID}
                            />

                            {!isViewMode && (
                                <MDBBtn
                                    color="success"
                                    onClick={() => setSelectTagsModalIsOpen(true)}
                                    className={styles.addTag}
                                    size="sm"
                                >
                                    <i className="fas fa-plus" /> Добавить теги
                                </MDBBtn>
                            )}

                            {article?.publication?.imageThree && (
                                <img
                                    className={styles.divider}
                                    src={require("../../../assets/images/static/divider.svg")}
                                    alt="divider"
                                />
                            )}

                            {!isPublic && (
                                <div className={styles.controller}>
                                    <div
                                        className={styles.modeSwitch}
                                        style={{backgroundColor: "rgb(129,41,255)"}}
                                    >
                                        <div className={styles.switchWrapper}>
                                            <p>Режим изменения позиций</p>

                                            <Switch
                                                checked={isDragOn}
                                                onChange={() => setIsDragOn(!isDragOn)}
                                                className={styles.switch}
                                            />
                                        </div>

                                        <MDBBtn
                                            color="success"
                                            className="mt-2 mb-2"
                                            size="sm"
                                            onClick={savePosition}
                                        >
                                            Сохранить
                                        </MDBBtn>
                                    </div>

                                    <div
                                        className={styles.modeSwitch}
                                        style={{backgroundColor: "rgb(255, 136, 0)"}}
                                    >
                                        <div className={styles.switchWrapper}>
                                            <p>Режим редактирования</p>

                                            <Switch
                                                checked={isViewMode}
                                                onChange={() => setIsViewMode(!isViewMode)}
                                                className={styles.switch}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className={styles.container}>
                                {textModalIsOpen && (
                                    <CKEditorTextModal
                                        isOpen={textModalIsOpen}
                                        toggle={() => setTextModalIsOpen(!textModalIsOpen)}
                                        handleSave={handleSaveText}
                                        blockForEdit={blockForEdit}
                                    />
                                )}

                                {uploadImageModalIsOpen && (
                                    <ImageUploadModal
                                        visible={uploadImageModalIsOpen}
                                        onSaveImage={(img) =>
                                            handleAddImage(imageBlockIdForAddImage, img)
                                        }
                                        toggle={() =>
                                            setUploadImageModalIsOpen(!uploadImageModalIsOpen)
                                        }
                                        selectFromLibraryAvailable={false}
                                    />
                                )}

                                {imageModalIsOpen && (
                                    <NewArticleImageModal
                                        isOpen={imageModalIsOpen}
                                        toggle={() => setImageModalIsOpen(!imageModalIsOpen)}
                                        handleSave={handleSaveBlockImage}
                                        blockForEdit={blockForEdit}
                                    />
                                )}

                                {galleryModalIsOpen && (
                                    <NewArticleGalleryModal
                                        isOpen={galleryModalIsOpen}
                                        toggle={() => setGalleryModalIsOpen(!galleryModalIsOpen)}
                                        handleSave={handleSaveGalleryBlock}
                                        blockForEdit={blockForEdit}
                                    />
                                )}

                                <ArticleBlocks
                                    blocks={article?.blocks}
                                    isViewMode={isViewMode}
                                    isDragOn={isDragOn}
                                    handleDelete={handleDeleteBlock}
                                    handleEdit={handleEditBlock}
                                    handleAddImage={handleAddImage}
                                    handleCustomAction={handleCustomAction}
                                    handleDragEnd={handleDragEnd}
                                />

                                {!isPublic && !isViewMode && (
                                    <div className={styles.createNewBlockButton}>
                                        <ArticleCreateNewBlock
                                            handleClickTextItem={() => setTextModalIsOpen(true)}
                                            handelClickImageItem={() => setImageModalIsOpen(true)}
                                            handleClickGalleryItem={() =>
                                                setGalleryModalIsOpen(true)
                                            }
                                            handleClickPlayerItem={handleSavePlayerBlock}
                                            handleClickFileItem={handleClickFileItem}
                                            handleClickSimilarItem={handleClickSimilarItem}
                                            handleClickMonogramItem={handleClickMonogramItem}
                                            handleClickVideoItem={handleClickVideoItem}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={styles.articleAdditionalWrapper}>
                        <div className={styles.articleAdditional}>
                            <p className={styles.title}>Продолжим чтение?</p>

                            {isSimilarLoading ? (
                                <React.Fragment>
                                    <SpinnerWithText text="Загрузка..." />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {!!similarPublications?.length ? (
                                        <React.Fragment>
                                            {similarPublications.map((sp) => (
                                                <div className={styles.similarCard} key={sp.id}>
                                                    <img
                                                        src={
                                                            hostURL +
                                                            sp.imageTwo?.fileDownloadUri.slice(1)
                                                        }
                                                        alt={sp.publicationUrl}
                                                        onClick={() => {
                                                            history.push(
                                                                isPublic
                                                                    ? PAGES_ROUTES.PUBLICATIONS.VIEW.replace(
                                                                          ":url",
                                                                          sp.publicationUrl,
                                                                      )
                                                                    : ADMIN_PANEL_ROUTES.ARTICLES.EDIT.replace(
                                                                          ":url",
                                                                          sp.publicationUrl,
                                                                      ),
                                                            );
                                                        }}
                                                    />
                                                    <Link
                                                        to={{
                                                            pathname: isPublic
                                                                ? PAGES_ROUTES.PUBLICATIONS.VIEW.replace(
                                                                      ":url",
                                                                      sp.publicationUrl,
                                                                  )
                                                                : ADMIN_PANEL_ROUTES.ARTICLES.EDIT.replace(
                                                                      ":url",
                                                                      sp.publicationUrl,
                                                                  ),
                                                        }}
                                                    >
                                                        <p
                                                            className={cn({
                                                                ["link"]: true,
                                                                [styles.subtitle]: true,
                                                            })}
                                                        >
                                                            {sp.title}
                                                        </p>
                                                    </Link>
                                                </div>
                                            ))}
                                        </React.Fragment>
                                    ) : (
                                        <p>Похожих публикаций не найдено</p>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                        <div id="vk_groups2"></div>
                        <YandexAd />
                    </div>
                </div>

                <ArticleFooterBanner />
            </div>
        </React.Fragment>
    );
};

export default Article;
