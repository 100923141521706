import {UserAPI} from "core/api/user";
import UsersList from "entries/users-list/users-list";
import React, {useEffect, useState} from "react";

const UsersListContainer = () => {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        getUsers();
    }, [page]);

    const getUsers = () => {
        UserAPI.getUsers(100, page).then((response) => {
            setUsers(response.data);
        });
    };

    return <UsersList users={users} page={page} handlePageChange={setPage} />;
};

export default UsersListContainer;
