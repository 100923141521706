import React, {useEffect, useState} from "react";
import {SubscriptionsAPI} from "../../../../core/api/subcriptions";
import AdminPanelFormCreator, {
    EFormCreatorTypes,
} from "../../admin-panel-form-creator/admin-panel-form-creator";
import AdminPanelQueryResultLayout from "../../admin-panel-query-result-layout/admin-panel-query-result-layout";
import {toast} from "react-toastify";

const AdminPanelSubscriptionGive = () => {
    const [isSubsLoading, setIsSubsLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);

    const getSubscriptions = () => {
        setIsSubsLoading(true);
        SubscriptionsAPI.getSubscriptions()
            .then((response: any) => {
                let subs = response.data.content?.map((sub: any) => ({
                    value: sub.id,
                    label: sub.name,
                }));
                setSubscriptions(subs);
            })
            .finally(() => setIsSubsLoading(false));
    };

    const handleSave = (formData: any) => {
        setIsLoading(true);
        SubscriptionsAPI.giveSubscription(formData.id, formData.subscriptId)
            .then(() => toast.success("Подписка выдана"))
            .catch(() => toast.error("Ошибка выдачи подписки"))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getSubscriptions();
    }, []);

    return (
        <React.Fragment>
            {!isSubsLoading ? (
                <React.Fragment>
                    {subscriptions.length > 0 ? (
                        <React.Fragment>
                            <AdminPanelFormCreator
                                handleClick={handleSave}
                                bannerText="Выдать подписку"
                                formConstructor={[
                                    {
                                        name: "id",
                                        labelText: "Введите ID пользователя",
                                        type: EFormCreatorTypes.TEXT,
                                    },
                                    {
                                        name: "subscriptId",
                                        labelText: "Выберите подписку",
                                        type: EFormCreatorTypes.SELECT,
                                        selectValues: subscriptions,
                                    },
                                    {
                                        name: "Выдать",
                                        type: EFormCreatorTypes.BUTTON,
                                        color: "success",
                                    },
                                ]}
                                isLoading={isLoading}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <h4>Нет данных о подписках. </h4>
                        </React.Fragment>
                    )}
                </React.Fragment>
            ) : (
                <AdminPanelQueryResultLayout>
                    <h4>Загрузка данных для формы</h4>
                </AdminPanelQueryResultLayout>
            )}
        </React.Fragment>
    );
};

export default AdminPanelSubscriptionGive;
