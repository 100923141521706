import cn from "classnames";
import {url} from "config";
import AudioPlayer from "entries/audio-player/audio-player";
import PropTypes from "prop-types";
import React, {useRef} from "react";
import {EditButtons} from "views/components/edit-buttons";
import styles from "./styles.module.sass";

const AudioAnswer = ({
    id,
    audio,
    index,
    className,
    image,
    isEditMode,
    handleClick,
    handleDeleteAnswer,
    handleDeleteImageAnswer,
    isSelected,
    handleAddImage,
}) => {
    const audioRef = useRef(null);
    const listenRef = useRef(null);

    return (
        <div
            className={cn({
                [styles.answer]: true,
                [styles.answerConstructor]: isEditMode,
                [styles.isSelected]: isSelected,

                [className]: !!className,
            })}
            onClick={(e) => {
                if (!isEditMode && e.target !== listenRef?.current) {
                    handleClick(id);
                }
            }}
        >
            {isEditMode && (
                <EditButtons
                    deleteAction={() => handleDeleteAnswer(id)}
                    withoutTopBorder={true}
                    withoutRightBorder={true}
                />
            )}

            {index && <p className={styles.title}>Ответ №{index}</p>}

            {(image || isEditMode) && (
                <div className={styles.answerItem}>
                    {image ? (
                        <div className={styles.answerImage}>
                            {isEditMode && (
                                <EditButtons deleteAction={() => handleDeleteImageAnswer(id)} />
                            )}
                            <img src={url + image.fileDownloadUri.slice(1)} />
                        </div>
                    ) : (
                        <div className={styles.addImage} onClick={() => handleAddImage(id)}>
                            <i className="fas fa-image" />
                        </div>
                    )}
                </div>
            )}
            <div className={styles.audio}>
                <AudioPlayer src={audio} audioRef={audioRef} />
            </div>
            {/*<p className="link" onClick={() => audioRef?.current?.play()} ref={listenRef}>*/}
            {/*    Слушать*/}
            {/*</p>*/}
        </div>
    );
};

AudioAnswer.propTypes = {
    audio: PropTypes.string,
    className: PropTypes.string,
    handleAddImage: PropTypes.func,
    handleClick: PropTypes.func,
    handleDeleteAnswer: PropTypes.func,
    handleDeleteImageAnswer: PropTypes.func,
    id: PropTypes.number,
    image: PropTypes.object,
    index: PropTypes.number,
    isEditMode: PropTypes.bool,
    isSelected: PropTypes.bool,
};

export default AudioAnswer;
