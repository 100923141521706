import AudioPlayer from "entries/audio-player/audio-player";
import {MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import PropTypes from "prop-types";
import React, {useRef} from "react";

const UploadAudioModal = ({isOpen, toggle, audio, setAudio, saveAudio}) => {
    let audioInputRef = useRef(null);

    return (
        <MDBContainer style={{zIndex: 9999, position: "relative"}}>
            <MDBModal isOpen={isOpen} toggle={toggle} overflowScroll={false}>
                <MDBModalHeader toggle={toggle} className="modal-header">
                    <p>Добавление аудио</p>
                </MDBModalHeader>
                <MDBModalBody className="container modal-body">
                    {/*<div className="mb-4">*/}
                    {/*    <input*/}
                    {/*        type="file"*/}
                    {/*        accept=".mp3, .wav"*/}
                    {/*        onChange={() => {*/}
                    {/*            let blob = URL.createObjectURL(audioInputRef.current.files[0]);*/}
                    {/*            setAudio(blob);*/}
                    {/*        }}*/}
                    {/*        ref={audioInputRef}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="input-group mb-4">
                        <div className="custom-file">
                            <input
                                className="custom-file-input"
                                id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01"
                                lang="ru"
                                type="file"
                                accept=".mp3, .wav"
                                onChange={() => {
                                    let blob = URL.createObjectURL(audioInputRef.current.files[0]);
                                    setAudio(blob);
                                }}
                                ref={audioInputRef}
                            />
                            <label className="custom-file-label" htmlFor="inputGroupFile01">
                                Выберите файл
                            </label>
                        </div>
                    </div>

                    <div className="mb-2">{audio && <AudioPlayer src={audio} />}</div>

                    <MDBBtn
                        type="submit"
                        color="success"
                        className="mb-2"
                        onClick={() => saveAudio(audioInputRef.current?.files[0], audio)}
                    >
                        Сохранить
                    </MDBBtn>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    );
};

UploadAudioModal.propTypes = {
    audio: PropTypes.string,
    isOpen: PropTypes.bool,
    saveAudio: PropTypes.func,
    setAudio: PropTypes.func,
    toggle: PropTypes.func,
};

export default UploadAudioModal;
