import React, {useEffect, useState} from "react";
import ModalLayout from "../../../modal-layout/modal-layout";
import SpinnerWithText from "../../../spinner/spinner";
import GenericTable from "../../../generic-table/generic-table";
import {EColors} from "../../core/EColors";
import {IPageable} from "../../../../interfaces/IPageable";
import {ISchool} from "../../../../interfaces/ISchool";
import {toast} from "react-toastify";
import {GeoAPI} from "../../../geo-selects/core/GeoAPI";
import {AxiosResponse} from "axios";
import FormSchool from "../../../form-school/form-schools";
import {ISelect} from "../../../../interfaces/ISelect";
import {MDBBtn} from "mdbreact";
import {SchoolAPI} from "../../../../core/api/school-api";

const AdminPanelSchoolsEdit = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [schools, setSchools] = useState<IPageable<ISchool> | undefined>(undefined);
    const [page, setPage] = useState(1);
    const [school, setSchool] = useState<ISchool | undefined>(undefined);
    const [selectedRegion, setSelectedRegion] = useState<ISelect | undefined>(undefined);
    const [selectedCity, setSelectedCity] = useState<ISelect | undefined>(undefined);
    const [searchValue, setSearchValue] = useState("");

    const handleUpdateSchool = (schoolForUpdate: ISchool) => {
        setSchool(schoolForUpdate);
        setSelectedRegion({
            value: schoolForUpdate.region?.geoNameId || 0,
            label: schoolForUpdate.region?.altName || schoolForUpdate.region?.name || "",
        });
        setSelectedCity({
            value: schoolForUpdate.city?.geoNameId || 0,
            label: schoolForUpdate.city?.altName || schoolForUpdate.city?.name || "",
        });
        setModalIsOpen(true);
    };

    const handleSaveSchool = () => {
        setIsLoading(true);
        if (!school) {
            toast.error("Нет данных для школы");
            return;
        }

        console.log(school);

        SchoolAPI.updateSchool(school)
            .then(() => {
                getSchools();
                toast.success("Школа обновлена");
                setModalIsOpen(false);
            })
            .catch(() => toast.error("Ошибка сохранения школы"))
            .finally(() => setIsLoading(false));
    };

    const getSchools = () => {
        setIsLoading(true);
        GeoAPI.getSchoolsElastic(null, null, page, searchValue)
            .then((response: AxiosResponse) => {
                setSchools(response.data);
            })
            .catch(() => toast.error("Ошибка получения школ"))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getSchools();
    }, [page]);

    useEffect(() => {
        if (!schools?.content?.length && !searchValue) return;
        if (searchValue.length >= 1 && searchValue.length <= 3) return;

        let debounceTimer = setTimeout(() => {
            getSchools();
            setPage(1);
        }, 500);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchValue]);

    return (
        <div>
            <div>
                <input
                    type="textarea"
                    className="form-control"
                    style={{width: 400}}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Поиск по названию школы"
                />
            </div>
            <br />

            {modalIsOpen && (
                <ModalLayout
                    title="Редактирование школы"
                    toggle={() => setModalIsOpen(!modalIsOpen)}
                    isOpen={modalIsOpen}
                    size={"fluid"}
                >
                    <FormSchool
                        school={school}
                        setSchool={setSchool}
                        selectedRegion={selectedRegion}
                        setSelectedRegion={setSelectedRegion}
                        selectedCity={selectedCity}
                        setSelectedCity={setSelectedCity}
                    />

                    <div className="d-flex w-100 justify-content-between">
                        <MDBBtn onClick={handleSaveSchool} color="success">
                            Сохранить
                        </MDBBtn>
                        <MDBBtn onClick={() => setModalIsOpen(false)} color="grey">
                            Закрыть
                        </MDBBtn>
                    </div>
                </ModalLayout>
            )}

            {isLoading ? (
                <SpinnerWithText text="Загрузка данных" />
            ) : (
                <React.Fragment>
                    {schools ? (
                        <React.Fragment>
                            <GenericTable
                                headers={[
                                    "ID школы",
                                    "ID города",
                                    "Альт. назв. города",
                                    "Город",
                                    "ID региона",
                                    "Альт. назв. региона",
                                    "Регион",
                                    "Альтернативное название",
                                    "Длинное название",
                                    "Короткое название",
                                    "Адрес",
                                ]}
                                outputKeys={[
                                    "id",
                                    "city.geoNameId",
                                    "city.altName",
                                    "city.name",
                                    "region.geoNameId",
                                    "region.altName",
                                    "region.name",
                                    "alternateName",
                                    "longName",
                                    "shortName",
                                    "lawAddress",
                                ]}
                                data={schools.content}
                                page={page}
                                setPage={setPage}
                                totalElements={schools.totalElements}
                                controllers={[
                                    {
                                        colorActive: EColors.PURPLE,
                                        colorInactive: "gray",
                                        action: handleUpdateSchool,
                                        icon: "fa-solid fa-pen",
                                    },
                                ]}
                            />
                        </React.Fragment>
                    ) : (
                        <p>Данных нет</p>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

export default AdminPanelSchoolsEdit;
