import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {toast} from "react-toastify";

import {TOAST_SUCCESS_CONFIG} from "config/ui";

import {TagsAPI} from "core/api/tags";

import {TagManagerSection, TagManagerTag} from "views/components/tag-manager";

const SectionTagsTitle = () => {
    const {t} = useTranslation("tag_manager");

    return <h6 className="font-weight-bold">{t("sectionTagsTitle")}</h6>;
};

export const SectionTagsContainer = (props) => {
    const {id} = props;
    const {t} = useTranslation("tag_manager");

    const [loaded, setLoaded] = React.useState(false);
    const [tags, setTags] = React.useState([]);

    const loadTags = () =>
        TagsAPI.getTags(id)
            .then((response) => {
                setTags(response.data);
            })
            .finally(() => {
                setLoaded(true);
            });

    React.useEffect(() => {
        loadTags();
    }, [id]);

    const handleEditClick = React.useCallback(
        (tagId, tagName) => {
            const name = prompt(t("tagNewName"), tagName);

            if (name && name !== tagName) {
                TagsAPI.renameTag(id, tagId, name)
                    .then(() => {
                        loadTags();
                        toast.success(t("tagRenamed"), TOAST_SUCCESS_CONFIG);
                    })
                    .catch((error) => {
                        console.error("Renaming tag error", error);
                    });
            }
        },
        [t, id],
    );

    const handleAddClick = React.useCallback(() => {
        const name = prompt(t("newTagName"));
        if (name) {
            TagsAPI.addTag(id, name)
                .then(() => {
                    loadTags();
                    toast.success(t("tagCreated"), TOAST_SUCCESS_CONFIG);
                })
                .catch((error) => {
                    console.error("Adding tag error", error);
                });
        }
    }, [t]);

    const handleRemoveClick = React.useCallback(
        (tagId) => {
            TagsAPI.deleteTag(id, tagId)
                .then(() => {
                    loadTags();
                    toast.success(t("tagRemoved"), TOAST_SUCCESS_CONFIG);
                })
                .catch((error) => {
                    console.error("Removing tag error", error);
                });
        },
        [t, id],
    );

    return (
        <TagManagerSection loaded={loaded} title={<SectionTagsTitle />}>
            <div>
                <button className="btn btn-success btn-sm ml-2" onClick={handleAddClick}>
                    Добавить тег
                </button>
            </div>
            {tags.map((tag) => (
                <TagManagerTag
                    key={tag.id}
                    id={tag.id}
                    name={tag.name}
                    onEditClick={handleEditClick}
                    onRemoveClick={handleRemoveClick}
                />
            ))}
        </TagManagerSection>
    );
};

SectionTagsContainer.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
};
