import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import {useDrop} from "react-dnd";

const DndDroppableBox = ({children, className, handleDrop, acceptType, style, targetId}) => {
    const [{isOver}, drop] = useDrop(
        () => ({
            accept: acceptType,
            drop(item, monitor) {
                handleDrop(item, monitor, targetId);
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [handleDrop],
    );

    return (
        <div
            className={cn({
                [className]: !!className,
            })}
            ref={drop}
            style={{...style, backgroundColor: isOver && "#e8fcff"}}
        >
            {children}
        </div>
    );
};

DndDroppableBox.propTypes = {
    acceptType: PropTypes.string,
    className: PropTypes.string,
    handleDrop: PropTypes.func,
    style: PropTypes.object,
    targetId: PropTypes.number,
};

export default DndDroppableBox;
