import React, {useEffect, useRef, useState} from "react";
import styles from "./styles.module.sass";
import {Button, Form, Image, Input, InputNumber, Space, Switch, Table, Upload} from "antd";
import {Request} from "../../../../core/utils/request";
import {ColumnsType} from "antd/es/table";
import {IGameItem} from "../../../../interfaces/IGameItem";
import {IPageable} from "../../../../interfaces/IPageable";
import ModalWrapper from "../../../modals/modal-wrapper/modal-wrapper";
import {useStores} from "../../../../hooks/use-stores";
import TextArea from "antd/es/input/TextArea";
import {toast} from "react-toastify";
import {PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {url} from "../../../../config";
import AdminPanelGameItemLink from "../item-link/admin-panel-game-item-link";
import AdminPanelBoxItem from "../admin-panel-box-item/admin-panel-box-item";
import Lottie from "lottie-react";

interface IItemModal {
    handleClose: () => void;
    item?: IGameItem;
    handleRefresh: () => void;
}

const ItemModal = ({handleClose, item, handleRefresh}: IItemModal) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [loadedItem, setLoadedItem] = useState<any>(undefined);
    const [animationData, setAnimationData] = useState<any>(null);

    let ref = useRef<any>();

    useEffect(() => {
        if (!item) {
            return;
        }

        setLoadedItem(item);
        form.setFieldsValue(item);
    }, [item]);

    useEffect(() => {
        if (!loadedItem) {
            return;
        }

        if (loadedItem.json) {
            fetch(url + loadedItem.json.fileDownloadUri.slice(1))
                .then((data) => data.json())
                .then((json) => setAnimationData(json));
        } else {
            setAnimationData(null);
        }
    }, [loadedItem]);

    const handleSaveItem = (form: IGameItem) => {
        setIsLoading(true);

        if (item) {
            Request.put(`/game-item/${item.id}`, form)
                .then(() => {
                    handleRefresh();
                })
                .finally(() => setIsLoading(false));
        } else {
            Request.post(`/game-item`, form)
                .then((response) => {
                    setLoadedItem(response.data);
                    handleRefresh();
                })
                .finally(() => setIsLoading(false));
        }
    };

    const handleSaveIcon = ({onSuccess, onError, file}: any) => {
        setIsLoading(true);
        let fmData = new FormData();
        fmData.append("file", file);
        Request.put(`/game-item/${loadedItem?.id}/icon`, fmData)
            .then((response) => {
                toast.success("Иконка загружена");
                onSuccess("Иконка загружена");
                setLoadedItem(response.data);
            })
            .catch(() => {
                toast.error("Ошибка загрузки иконки");
                onError("Ошибка загрузки иконки");
            })
            .finally(() => setIsLoading(false));
    };

    const handleSaveJson = ({onSuccess, onError, file}: any) => {
        setIsLoading(true);
        let fmData = new FormData();
        fmData.append("file", file);
        Request.put(`/game-item/${loadedItem?.id}/json`, fmData)
            .then((response) => {
                toast.success("JSON загружен");
                onSuccess("JSON загружен");
                setLoadedItem(response.data);
            })
            .catch(() => {
                toast.error("Ошибка загрузки JSON");
                onError("Ошибка загрузки JSON");
            })
            .finally(() => setIsLoading(false));
    };

    const handleSaveImage = ({onSuccess, onError, file}: any) => {
        setIsLoading(true);
        let fmData = new FormData();
        fmData.append("file", file);
        Request.put(`/game-item/${loadedItem?.id}/image`, fmData)
            .then((response) => {
                toast.success("Картинка загружена");
                onSuccess("Картинка загружена");
                setLoadedItem(response.data);
            })
            .catch(() => {
                toast.error("Ошибка загрузки картинки");
                onError("Ошибка загрузки картинки");
            })
            .finally(() => setIsLoading(false));
    };

    const handleDeleteImage = () => {
        setIsLoading(true);
        Request.delete(`/game-item/${loadedItem?.id}/image`)
            .then((response) => {
                toast.success("Картинка удалена");
                setLoadedItem(response.data);
            })
            .finally(() => setIsLoading(false));
    };

    const handleDeleteIcon = () => {
        setIsLoading(true);
        Request.delete(`/game-item/${loadedItem?.id}/icon`)
            .then((response) => {
                toast.success("Картинка удалена");
                setLoadedItem(response.data);
            })
            .finally(() => setIsLoading(false));
    };

    const handleDeleteJson = () => {
        setIsLoading(true);
        Request.delete(`/game-item/${loadedItem?.id}/json`)
            .then((response) => {
                toast.success("JSON удален");
                setLoadedItem(response.data);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <ModalWrapper withHeader={true} headerTitle={"Предмет"} onClose={handleClose}>
            <div className={styles.wrapper}>
                <Form
                    layout="horizontal"
                    style={{padding: "20px"}}
                    form={form}
                    onFinish={handleSaveItem}
                >
                    <Form.Item name="name" label="Название">
                        <Input />
                    </Form.Item>

                    <Form.Item name="description" label="Описание">
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item name="rarity" label="Редкость">
                        <InputNumber />
                    </Form.Item>

                    <Form.Item name="shopPrice" label="Цена в магазине">
                        <InputNumber />
                    </Form.Item>

                    <Form.Item name="sellPrice" label="Цена продажи">
                        <InputNumber />
                    </Form.Item>

                    <Form.Item valuePropName="checked" name="isForBoy" label="Для мальчиков?">
                        <Switch />
                    </Form.Item>

                    <Form.Item valuePropName="checked" name="isForGirl" label="Для девочек?">
                        <Switch />
                    </Form.Item>

                    <Form.Item valuePropName="checked" name="isInShop" label="В магазине?">
                        <Switch />
                    </Form.Item>

                    <Form.Item name="zindex" label="Слой (zindex)">
                        <InputNumber />
                    </Form.Item>

                    <Form.Item label="JSON">
                        {animationData ? (
                            <div className={styles.row}>
                                <Lottie
                                    animationData={animationData}
                                    loop={true}
                                    height={100}
                                    width={100}
                                    lottieRef={ref}
                                    className={styles.animation}
                                />

                                <p
                                    style={{
                                        color: "red",
                                        fontWeight: 600,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleDeleteJson()}
                                >
                                    <i className="fas fa-trash" />
                                    Удалить JSON
                                </p>
                            </div>
                        ) : (
                            <Upload customRequest={handleSaveJson} multiple={false} listType="text">
                                <Button icon={<UploadOutlined />}>
                                    Кликните для загрузки файла
                                </Button>
                            </Upload>
                        )}
                    </Form.Item>

                    {!!loadedItem && (
                        <React.Fragment>
                            <Form.Item label="Иконка">
                                {loadedItem?.icon ? (
                                    <div className={styles.row}>
                                        <Image
                                            src={`${
                                                url + loadedItem.icon.fileDownloadUri.slice(1)
                                            }`}
                                            width={80}
                                            height={80}
                                        />
                                        <p
                                            style={{
                                                color: "red",
                                                fontWeight: 600,
                                                cursor: "pointer",
                                            }}
                                            onClick={() => handleDeleteIcon()}
                                        >
                                            <i className="fas fa-trash" />
                                            Удалить иконку
                                        </p>
                                    </div>
                                ) : (
                                    <Upload
                                        listType="picture-card"
                                        customRequest={handleSaveIcon}
                                        multiple={false}
                                    >
                                        <div>
                                            <PlusOutlined />
                                        </div>
                                    </Upload>
                                )}
                            </Form.Item>

                            <Form.Item label="Картинка">
                                {loadedItem?.image ? (
                                    <div className={styles.row}>
                                        <Image
                                            src={`${
                                                url + loadedItem.image.fileDownloadUri.slice(1)
                                            }`}
                                            width={80}
                                            height={80}
                                        />
                                        <p
                                            style={{
                                                color: "red",
                                                fontWeight: 600,
                                                cursor: "pointer",
                                            }}
                                            onClick={() => handleDeleteImage()}
                                        >
                                            <i className="fas fa-trash" />
                                            Удалить картинку
                                        </p>
                                    </div>
                                ) : (
                                    <Upload
                                        listType="picture-card"
                                        customRequest={handleSaveImage}
                                        multiple={false}
                                    >
                                        <div>
                                            <PlusOutlined />
                                        </div>
                                    </Upload>
                                )}
                            </Form.Item>
                        </React.Fragment>
                    )}

                    <Button htmlType="submit" type="primary" disabled={isLoading}>
                        Сохранить
                    </Button>
                </Form>
            </div>
        </ModalWrapper>
    );
};

const AdminPanelGameItems = () => {
    const {appStore} = useStores();
    const [items, setItems] = useState<IPageable<IGameItem> | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");

    const getItems = (isReset?: boolean) => {
        let request = `/game-item?page=${page}`;
        if (searchValue && !isReset) {
            request = `/game-item/by-name?name=${searchValue}&page=${page}`;
        }

        setIsLoading(true);
        Request.get(request)
            .then((response) => {
                setItems(response.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDeleteItem = (id: number) => {
        setIsLoading(true);
        Request.delete(`/game-item/${id}`)
            .then(() => {
                toast.success("Предмет удален");
                getItems();
            })
            .catch(() => {
                toast.error("Ошибка удаления предмета");
            })
            .finally(() => setIsLoading(false));
    };

    const columns: ColumnsType<IGameItem> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: "Название",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Описание",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Цена покупки",
            dataIndex: "shopPrice",
            key: "shopPrice",
        },
        {
            title: "Редкость",
            dataIndex: "rarity",
            key: "rarity",
        },
        {
            title: "Слой (zIndex)",
            dataIndex: "zindex",
            key: "zindex",
        },
        {
            title: "Действия",
            key: "action",
            render: (_: any, record: any) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            appStore.addModalToQueue(
                                <ItemModal
                                    handleClose={() => appStore.closeActiveModal()}
                                    key={"game-item-modal"}
                                    item={record}
                                    handleRefresh={() => getItems()}
                                />,
                            );
                        }}
                    >
                        <i className="fas fa-pen" style={{color: "orange"}} />
                    </a>

                    <a
                        onClick={() => {
                            appStore.addModalToQueue(
                                <AdminPanelGameItemLink
                                    handleClose={() => appStore.closeActiveModal()}
                                    key={"game-item-modal-link"}
                                    item={record}
                                    handleRefresh={() => getItems()}
                                />,
                            );
                        }}
                    >
                        <i className="fas fa-link" style={{color: "purple"}} />
                    </a>

                    <a
                        onClick={() => {
                            appStore.addModalToQueue(
                                <AdminPanelBoxItem
                                    handleClose={() => appStore.closeActiveModal()}
                                    key={"game-item-box-modal"}
                                    item={record}
                                    handleRefresh={() => getItems()}
                                />,
                            );
                        }}
                    >
                        <i className="fas fa-box" style={{color: "green"}} />
                    </a>

                    <a onClick={() => handleDeleteItem(record.id)}>
                        <i className="fas fa-trash" style={{color: "red"}} />
                    </a>
                </Space>
            ),
        },
    ];

    const handleSearch = () => {
        if (searchValue.length < 3) {
            return;
        }

        if (page > 1) {
            setPage(1);
        }

        getItems();
    };

    useEffect(() => {
        getItems();
    }, [page]);

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.headerSearch}>
                        <Input
                            placeholder="Поиск"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <Button onClick={handleSearch} type="primary">
                            Поиск
                        </Button>
                        <Button
                            onClick={() => {
                                setSearchValue("");
                                setPage(1);
                                getItems(true);
                            }}
                            type="primary"
                        >
                            Сброс
                        </Button>
                    </div>

                    <Button
                        type="primary"
                        onClick={() => {
                            appStore.addModalToQueue(
                                <ItemModal
                                    handleClose={() => appStore.closeActiveModal()}
                                    key={"game-item-modal"}
                                    handleRefresh={() => getItems()}
                                />,
                            );
                        }}
                    >
                        Создать предмет
                    </Button>
                </div>

                <Table
                    columns={columns}
                    dataSource={items?.content}
                    loading={isLoading}
                    rowKey="id"
                    pagination={{
                        current: page,
                        total: items?.totalElements,
                        onChange: (page) => setPage(page),
                        pageSize: items?.size,
                        hideOnSinglePage: true,
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default AdminPanelGameItems;
