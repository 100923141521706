import {SKIP_ID} from "entries/constructors/word-skip/views/text-with-skips/text-with-skips-container";
import React from "react";
import PropTypes from "prop-types";
import {EditButtons} from "views/components/edit-buttons";
import cn from "classnames";

import styles from "entries/constructors/word-skip/views/text-with-skips/text-with-skips.module.sass";

export const TextWithSkips = ({text, isEditMode = true, editAction, deleteAction}) => {
    return (
        <div
            className={cn({
                [styles.container]: true,
                [styles.border]: isEditMode,
            })}
        >
            {text.map((word, idx) => {
                if (word === SKIP_ID) {
                    return <span key={idx} className={styles.skip}></span>;
                }

                return `${word} `;
            })}
            {isEditMode && (
                <EditButtons
                    deleteAction={deleteAction}
                    editAction={editAction}
                    withoutRightBorder
                    withoutTopBorder
                />
            )}
        </div>
    );
};

TextWithSkips.propTypes = {
    deleteAction: PropTypes.func,
    editAction: PropTypes.func,
    isEditMode: PropTypes.bool,
    text: PropTypes.array,
};
