import {url} from "config";
import {CLASSIC_TEST} from "config/constructor-names";
import {ClassicTestAPI} from "entries/constructors/classic-test/core/api/classic-test";
import {getClassicAnswers} from "entries/constructors/classic-test/utils/utils";
import {NARRATORS} from "entries/constructors/classic-test/views/components/properties-buttons";
import {CreateAnswer} from "entries/constructors/classic-test/views/containers/create-answer/create-answer";
import {CreateQuestion} from "entries/constructors/classic-test/views/containers/create-question";
import {TestParameters} from "entries/constructors/classic-test/views/containers/test-parameters";
import {useConstructor} from "entries/constructors/hooks/useConstructor";
import {MDBAlert} from "mdbreact";

import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {ConstructorNavbar} from "views/components/constructor-navbar";

import {Col} from "views/components/layout/col";
import {SaveContent} from "views/components/layout/save-content/save-content";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {TestOptions} from "views/components/layout/test-options";
import {SpinnerPage} from "views/components/spinner";
import {PropertyColumn} from "views/containers/property-column";
import TestSave from "views/containers/test-save/test-save";
import {useWizard} from "views2/conatiners/CreateTestWizard";
import useTraining from "../../../../../training/useTraining";
import BlackoutLayer from "../../../../../training/blackout-layer/blackout-layer";
import TrainingHelper from "../../../../../training/training-helper/training-helper";

export const ClassicTestConstructorPage = ({testData, isEditMode}) => {
    const [answers, setAnswers] = useState([]);
    const [answerType, setAnswerType] = useState("");

    const loadAnswers = async (answers, testId) => {
        let result = await getClassicAnswers(answers, textAnswerSize, imageSize, testId, true);
        setAnswerType(result?.type);
        setAnswers(result?.answers);
    };

    const {
        testId,
        isLoading,
        questionText,
        setQuestionText,
        questionImage,
        setQuestionImage,
        questionFormula,
        setQuestionFormula,
        background,
        setBackground,
        narrator,
        setNarrator,
        imageSize,
        setImageSize,
        textTitleSize,
        setTextTitleSize,
        textAnswerSize,
        setTextAnswerSize,
        isRoundedAnswer,
        setIsRoundedAnswer,
        currentPropertyTab,
        setCurrentPropertyTab,
        isTwoColumnMode,
        setIsTwoColumnMode,
        tabNames,
        questionType,
        questionAudio,
        setQuestionAudio,
    } = useConstructor({
        isEditMode,
        constructorName: CLASSIC_TEST,
        testData,
        loadAnswersCallback: loadAnswers,
    });

    const {
        STEP,
        stepsOrder,
        currentStep,
        toNext,
        to,
        enabledSteps,
        enableStep,
        disableStep,
        enableSteps,
    } = useWizard();

    const {
        isTrainingActivated,
        isBlackoutActivated,
        questionRef,
        addAnswerRef,
        propertiesRef,
        helperRef,
        helperData,
        toNextStep,
    } = useTraining({
        questionText,
        narrator,
        answers,
        background,
        isEditMode,
        currentStep,
        textTitleSize,
        constructorName: CLASSIC_TEST,
    });

    useEffect(() => {
        if (isEditMode) {
            enableSteps([STEP.ANSWER, STEP.QUESTION, STEP.PARAMS]);
            return;
        }

        if (questionText && answers?.length >= 2 && narrator) {
            enableStep(STEP.ANSWER);
        } else {
            disableStep(STEP.ANSWER);
        }
    }, [questionText, answers, narrator, isEditMode]);

    if (isLoading) return <SpinnerPage info />;

    return (
        <div>
            {isTrainingActivated && (
                <React.Fragment>
                    {isBlackoutActivated && (
                        <BlackoutLayer>
                            <TrainingHelper
                                message={helperData?.message}
                                imageUrl={helperData?.imageUrl}
                                positionX={helperData?.x}
                                positionY={helperData?.y}
                                isOpen={helperData?.isOpen}
                                helperRef={helperRef}
                                isBigMessage={helperData?.isBigMessage}
                                buttonText={helperData?.buttonText}
                                toNextStep={toNextStep}
                            />
                        </BlackoutLayer>
                    )}
                </React.Fragment>
            )}

            <ConstructorNavbar
                tabs={stepsOrder}
                currentTab={currentStep}
                availableTabs={enabledSteps}
                onSelect={(tab) => to(tab)}
                tabNames={tabNames}
            />

            <Col size={12}>
                {currentStep === STEP.QUESTION || currentStep === STEP.ANSWER ? (
                    <Col size={8} className="d-flex justify-content-start">
                        <TestContent
                            background={background}
                            withEditBorder={currentStep === STEP.QUESTION}
                        >
                            <TestConstructor>
                                <CreateQuestion
                                    apiInstance={ClassicTestAPI}
                                    questionId={testId}
                                    questionTitle={testData.title}
                                    questionImage={questionImage}
                                    questionText={questionText}
                                    questionFormula={questionFormula}
                                    questionAudio={questionAudio}
                                    updateQuestionImage={(image) => setQuestionImage(image)}
                                    updateQuestionText={(text) => setQuestionText(text)}
                                    updateQuestionFormula={(formula) => setQuestionFormula(formula)}
                                    updateQuestionAudio={(audio) => setQuestionAudio(audio)}
                                    selectPropertyNarrator={() => setCurrentPropertyTab(NARRATORS)}
                                    narrator={narrator}
                                    isEditMode={currentStep === STEP.QUESTION}
                                    testHasNarrator
                                    textTitleSize={textTitleSize}
                                    questionRef={questionRef}
                                />
                                <CreateAnswer
                                    isEditMode={currentStep === STEP.QUESTION}
                                    answers={answers}
                                    updateAnswers={(answers) => setAnswers(answers)}
                                    isSelectCorrectAnswerMode={currentStep === STEP.ANSWER}
                                    imageSize={imageSize}
                                    answerType={answerType}
                                    updateAnswerType={(type) => setAnswerType(type)}
                                    textAnswerSize={textAnswerSize}
                                    questionId={testId}
                                    isTwoColumnMode={isTwoColumnMode}
                                    isRoundedAnswer={isRoundedAnswer}
                                    addAnswerRef={addAnswerRef}
                                />
                            </TestConstructor>
                        </TestContent>
                    </Col>
                ) : (
                    <TestParameters
                        apiInstance={ClassicTestAPI}
                        testId={testId}
                        mode={questionType}
                        isEditMode={isEditMode}
                    />
                )}
                {(currentStep === STEP.QUESTION || currentStep === STEP.ANSWER) && (
                    <Col size={4}>
                        <TestOptions>
                            {currentStep === STEP.QUESTION && (
                                <PropertyColumn
                                    selectImage={(background) => setBackground(background)}
                                    selectNarrator={(narrator) =>
                                        setNarrator({
                                            id: narrator.id,
                                            url: `${url}${narrator.url}`,
                                        })
                                    }
                                    background={background}
                                    textTitleSize={textTitleSize}
                                    textAnswerSize={textAnswerSize}
                                    selectImageSize={(size) => setImageSize(size)}
                                    setTextTitleSize={(size) =>
                                        setTextTitleSize(Number.parseInt(size))
                                    }
                                    setTextAnswerSize={(size) =>
                                        setTextAnswerSize(Number.parseInt(size))
                                    }
                                    currentPropertyTab={currentPropertyTab}
                                    onSelectTab={(tab) => setCurrentPropertyTab(tab)}
                                    currentImageSize={imageSize}
                                    answerType={answerType}
                                    isQuestionTextExist={!!questionText}
                                    isTwoColumnMode={isTwoColumnMode}
                                    toggleTwoColumnMode={() => setIsTwoColumnMode(!isTwoColumnMode)}
                                    isTwoColumnModeAvailable
                                    narrator={narrator}
                                    isRoundedAnswer={isRoundedAnswer}
                                    setIsRoundedAnswer={setIsRoundedAnswer}
                                    propertiesRef={propertiesRef}
                                />
                            )}
                            {currentStep === STEP.ANSWER && (
                                <SaveContent>
                                    <h5>Укажите правильный ответ</h5>
                                    <p>Решите задачу правильно, а затем сохраните ответ</p>
                                    <TestSave
                                        questionId={testId}
                                        backgroundId={background.id}
                                        sizeImage={imageSize}
                                        sizeText={textAnswerSize}
                                        sizeTitle={textTitleSize}
                                        narratorId={narrator?.id}
                                        isTwoColumnMode={isTwoColumnMode}
                                        isRoundedAnswer={isRoundedAnswer}
                                        apiInstance={ClassicTestAPI}
                                        constructorName={CLASSIC_TEST}
                                        onSaveProperties={() => {
                                            toNext();
                                        }}
                                        answersIdsForSave={answers
                                            .filter((answer) => answer.isCorrect)
                                            .map((answer) => answer.id)}
                                        mode={questionType}
                                    />

                                    <MDBAlert color="info" className="mt-5">
                                        <p>
                                            <i className="fa fa-info-circle" aria-hidden="true" /> В
                                            этом виде задания вы можете указать один или несколько
                                            правильных ответов.
                                        </p>
                                    </MDBAlert>
                                </SaveContent>
                            )}
                        </TestOptions>
                    </Col>
                )}
            </Col>
        </div>
    );
};

ClassicTestConstructorPage.propTypes = {
    isEditMode: PropTypes.bool,
    testData: PropTypes.object,
};
