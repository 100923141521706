import React from "react";
import styles from "./styles.module.sass";
import {MDBBtn} from "mdbreact";
import cn from "classnames";

interface IProps {
    toggle: () => void;
    url: string;
}

const VideoModal = ({toggle, url}: IProps) => {
    return (
        <div
            className={cn({
                [styles.containerWrapper]: true,
            })}
        >
            <div className={styles.content}>
                <iframe
                    src={url}
                    title="Video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>

            <div className={styles.footer}>
                <MDBBtn color="grey" onClick={toggle}>
                    Закрыть
                </MDBBtn>
            </div>
        </div>
    );
};

export default VideoModal;
