import {url} from "config";
import React, {useState} from "react";
import {ABOUT_ENGINE_DATA} from "views/pages/about/sliders/about-engines/utils/about-engines-data";
import Carousel from "../../../../../entries/carousel/carousel";
import styles from "./styles.module.sass";
import cn from "classnames";
import countTimeIcon from "assets/sliders/engines-icons/count-time.svg";
import countThemeIcon from "assets/sliders/engines-icons/count-themes.svg";
import countTasksIcon from "assets/sliders/engines-icons/count-tasks.svg";

const AboutEngines = () => {
    const [tabs] = useState(ABOUT_ENGINE_DATA);
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const backgroundStyles = {
        backgroundImage: `url(${url + "data/image-bank/c62a0790-16b5-4c9c-943a-0135d2ba1529.svg"})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    return (
        <div className={styles.wrapper} style={backgroundStyles}>
            {activeTab && (
                <div className={styles.subject}>
                    <p className={styles.subjectTitle}>{activeTab.title}</p>
                    <div className={styles.subjectDescription}>
                        <div className={styles.subjectDescriptionItem}>
                            <img src={countThemeIcon} alt="themes-count" />
                            {activeTab.countThemes} тем
                        </div>
                        <div className={styles.subjectDescriptionItem}>
                            <img src={countTimeIcon} alt="time-count" />
                            {activeTab.countHours} часов
                        </div>
                        <div className={styles.subjectDescriptionItem}>
                            <img src={countTasksIcon} alt="task-count" />
                            {activeTab.countTasks} заданий
                        </div>
                    </div>
                </div>
            )}

            <div className={styles.content}>
                <div className={styles.tabs}>
                    {tabs.map((tab, index) => (
                        <div
                            key={index}
                            className={cn({
                                [styles.tab]: true,
                                [styles.active]: tab.id === activeTab.id,
                            })}
                            onClick={() => setActiveTab(tab)}
                        >
                            <img src={tab.logo} alt="logo" />
                        </div>
                    ))}
                </div>
                <div className={styles.carousel}>
                    <Carousel slides={activeTab.slides} />
                </div>
            </div>
        </div>
    );
};

AboutEngines.propTypes = {};

export default AboutEngines;
