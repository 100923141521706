import cn from "classnames";
import {DEFAULT_FONT_SIZE} from "config/project";
import {convertTextWithSymbols} from "core/utils/converter";
import PropTypes from "prop-types";
import React from "react";

import {EditButtons} from "views/components/edit-buttons";
import {Narrator} from "views/components/narrator";

import styles from "./text-upload.module.sass";

export const TextUpload = (props) => {
    const {
        text,
        editText,
        deleteText,
        isEditMode,
        narrator,
        testHasNarrator,
        textTitleSize,
        narratorSelect,
        toggleTextModal,
    } = props;
    return (
        <div
            className={cn({
                [styles.container]: true,
            })}
        >
            {testHasNarrator && (
                <Narrator
                    narrator={narrator}
                    isEditMode={isEditMode}
                    narratorSelect={narratorSelect}
                />
            )}
            {text.length > 0 ? (
                <div>
                    <div className={styles.containerText}>
                        <p style={{fontSize: `${textTitleSize / DEFAULT_FONT_SIZE}rem`}}>
                            {convertTextWithSymbols(text)}
                        </p>
                    </div>
                    {isEditMode && (
                        <div>
                            <EditButtons
                                editAction={editText}
                                deleteAction={deleteText}
                                withoutRightBorder
                                withoutTopBorder
                            />
                        </div>
                    )}
                </div>
            ) : (
                isEditMode && (
                    <p className="link ml-3" onClick={toggleTextModal}>
                        Добавить текст задания
                    </p>
                )
            )}
        </div>
    );
};

TextUpload.propTypes = {
    deleteText: PropTypes.func,
    editText: PropTypes.func,
    isEditMode: PropTypes.bool,
    narrator: PropTypes.any,
    narratorSelect: PropTypes.func,
    testHasNarrator: PropTypes.bool,
    text: PropTypes.string,
    textTitleSize: PropTypes.number,
    toggleTextModal: PropTypes.func,
};
