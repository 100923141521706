import React, {useEffect, useState} from "react";
import {AsyncPaginate} from "react-select-async-paginate";
import PropTypes from "prop-types";

const GeoSelectCity = ({handleSelect, loadOptions, regionId, selectedCity}) => {
    const [key, setKey] = useState(0);

    useEffect(() => {
        setKey((prev) => prev + 1);
    }, [regionId]);

    return (
        <AsyncPaginate
            key={key}
            styles={{menu: (provided) => ({...provided, zIndex: 9999})}}
            onChange={handleSelect}
            closeMenuOnSelect
            loadOptions={loadOptions}
            additional={{
                page: 1,
            }}
            isDisabled={!regionId}
            placeholder="Выберите город"
            value={selectedCity}
        />
    );
};

GeoSelectCity.propTypes = {
    handleSelect: PropTypes.func,
    loadOptions: PropTypes.func,
    regionId: PropTypes.number,
    selectedCity: PropTypes.object,
};

export default GeoSelectCity;
