import {auth} from "../../core/utils/auth";
import {CatchErrorsAPI} from "../../core/api/catch-errors-api";
import React from "react";

const {keycloak} = auth;

export const errorFallback = (res: any) => {
    CatchErrorsAPI.createError({
        title: res?.error?.message,
        status: "REQUEST",
        description: `${res?.error?.stack.substring(0, 180)}, ${keycloak?.tokenParsed?.email}`,
    });
    return (
        <div>
            Произошла ошибка. Пожалуйста, обновите страницу. Если ошибка повторяется - обратитесь в
            техподдержку.
        </div>
    );
};
