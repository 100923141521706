import React from "react";
import styles from "./styles.module.sass";
import {url} from "../../../config";
import {MDBBtn} from "mdbreact";
import cn from "classnames";

const TrainingHelper = ({
    isOpen,
    imageUrl,
    message,
    positionX,
    positionY,
    helperRef,
    isBigMessage,
    buttonText,
    toNextStep,
}) => {
    return (
        <React.Fragment>
            {isOpen && (
                <React.Fragment>
                    {isBigMessage ? (
                        <div
                            className={cn({
                                [styles.bigMessageWrapper]: true,
                                "animate__animated animate__zoomIn": true,
                            })}
                        >
                            <div className={styles.bigMessage}>
                                <img
                                    src={url + imageUrl}
                                    className={styles.bigMessageHelperImage}
                                    alt="raven-help"
                                />
                                <p className={styles.title}>{message}</p>
                                <MDBBtn className={styles.button} color="info" onClick={toNextStep}>
                                    {buttonText}
                                </MDBBtn>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={styles.helper}
                            ref={helperRef}
                            style={{top: positionY || 10, left: positionX || 10}}
                        >
                            <img
                                src={url + imageUrl}
                                className={styles.helperImage}
                                alt="raven-help"
                            />
                            <div className={styles.message}>{message}</div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

TrainingHelper.propTypes = {};

export default TrainingHelper;
