import {url} from "config";
import {CLIPPING_SECONDARY_TEST, CLIPPING_TEST} from "config/constructor-names";
import {NARRATORS} from "entries/constructors/classic-test/views/components/properties-buttons";
import {CreateQuestion} from "entries/constructors/classic-test/views/containers/create-question";
import {TestParameters} from "entries/constructors/classic-test/views/containers/test-parameters";
import {ClippingAPI} from "entries/constructors/clipping/core/ClippingAPI";
import {useConstructor} from "entries/constructors/hooks/useConstructor";
import ClippingPicturesEngineContainer from "feauters/clipping-pictures-engine/clipping-pictures-engine-container";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {MIX_TYPE, SIZE_L} from "utils/answers-config";
import {ConstructorNavbar} from "views/components/constructor-navbar";
import {Col} from "views/components/layout/col";
import {SaveContent} from "views/components/layout/save-content/save-content";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {TestOptions} from "views/components/layout/test-options";
import {PropertyColumn} from "views/containers/property-column";
import TestSave from "views/containers/test-save/test-save";
import {useWizard} from "views2/conatiners/CreateTestWizard";
import {ClippingEngineConverter} from "feauters/utils/clipping-engine-converter";
import {ClippingSecondaryAPI} from "../core/ClippingSecondaryAPI";

const ClippingEngine = ({testData, isEditMode, isSecondary, isManyToMany}) => {
    const {
        testId,
        questionText,
        setQuestionText,
        narrator,
        textTitleSize,
        setCurrentPropertyTab,
        tabNames,
        background,
        setBackground,
        setNarrator,
        currentPropertyTab,
        setTextTitleSize,
        questionType,
        textAnswerSize,
        setTextAnswerSize,
        imageSize,
        setImageSize,
        isRoundedAnswer,
        setIsRoundedAnswer,
        multiple,
        answerType,
        setAnswerType,
    } = useConstructor({
        constructorName: isSecondary ? CLIPPING_SECONDARY_TEST : CLIPPING_TEST,
        testData,
        isEditMode: isEditMode,
        loadAnswersCallback: loadAnswers,
    });

    const [gameBackground, setGameBackground] = useState(null);
    const [boxes, setBoxes] = useState([]);
    const [answers, setAnswers] = useState([]);

    const [selectedAnswerId, setSelectedAnswerId] = useState(null);
    const [selectedAnswerWidth, setSelectedAnswerWidth] = useState(0);

    async function loadAnswers(answers) {
        setGameBackground({objectUrl: url + answers?.backgroundGame?.fileDownloadUri.slice(1)});

        let constructorType = isSecondary ? CLIPPING_SECONDARY_TEST : CLIPPING_TEST;
        let result = await ClippingEngineConverter(answers?.answers, constructorType, answers.id);

        setAnswers(result?.answers ? result.answers : []);
        setBoxes(result?.boxes ? result.boxes : []);
    }

    const {
        STEP,
        stepsOrder,
        currentStep,
        to,
        enabledSteps,
        enableStep,
        toNext,
        disableStep,
        enableSteps,
    } = useWizard();

    useEffect(() => {
        if (isEditMode) {
            enableSteps([STEP.ANSWER, STEP.QUESTION, STEP.PARAMS]);
            return;
        }

        if (questionText && narrator) {
            enableStep(STEP.ANSWER);
        } else {
            disableStep(STEP.ANSWER);
        }
    }, [questionText, narrator, isEditMode]);

    useEffect(() => {
        setImageSize(SIZE_L);
    }, []);

    useEffect(() => {
        setSelectedAnswerId(null);
    }, [currentStep]);

    useEffect(() => {
        if (answers.length > 0) {
            let type = answers[0].type;
            if (type) {
                setAnswerType(type);
                return;
            }
        }

        setAnswerType(null);
    }, [answers]);

    const handleUpdateCoords = (x, y) => {
        let tempBoxes = boxes.slice();
        let boxIdx = tempBoxes.findIndex((box) =>
            isManyToMany ? box.tempId === selectedAnswerId : box.id === selectedAnswerId,
        );

        tempBoxes[boxIdx] = {
            ...tempBoxes[boxIdx],
            left: x,
            top: y,
            center: tempBoxes[boxIdx].width
                ? tempBoxes[boxIdx].width / 2 + x
                : selectedAnswerWidth / 2 + x,
        };

        setBoxes(tempBoxes);
    };

    const handleSelectAnswer = (id, width, tempId) => {
        if (selectedAnswerId === id || selectedAnswerId === tempId) {
            setSelectedAnswerId(null);
            setSelectedAnswerWidth(null);
            return;
        }

        if (tempId) {
            setSelectedAnswerId(tempId);
        } else {
            setSelectedAnswerId(id);
        }

        setSelectedAnswerWidth(width);
    };

    return (
        <React.Fragment>
            <ConstructorNavbar
                tabs={stepsOrder}
                currentTab={currentStep}
                availableTabs={enabledSteps}
                onSelect={(tab) => to(tab)}
                tabNames={tabNames}
            />

            <Col size={12} className="d-flex justify-content-between">
                {currentStep === STEP.QUESTION || currentStep === STEP.ANSWER ? (
                    <React.Fragment>
                        <TestContent
                            className="d-flex justify-content-start position-relative"
                            isFullWidth
                            background={background}
                        >
                            <TestConstructor size="full">
                                <CreateQuestion
                                    apiInstance={isSecondary ? ClippingSecondaryAPI : ClippingAPI}
                                    questionId={testData.id || testId}
                                    questionTitle={testData.title}
                                    questionText={questionText}
                                    updateQuestionText={(text) => setQuestionText(text)}
                                    selectPropertyNarrator={() => setCurrentPropertyTab(NARRATORS)}
                                    narrator={narrator}
                                    isEditMode={currentStep === STEP.QUESTION}
                                    testHasNarrator
                                    textTitleSize={textTitleSize}
                                    withEditButtons={false}
                                    withoutBorder={true}
                                    withoutBottomMargin={true}
                                />

                                <ClippingPicturesEngineContainer
                                    boxes={boxes}
                                    setBoxes={setBoxes}
                                    background={gameBackground}
                                    setBackground={setGameBackground}
                                    imageSize={imageSize}
                                    isEditMode={currentStep === STEP.QUESTION}
                                    isRoundedAnswer={isRoundedAnswer}
                                    answers={answers}
                                    setAnswers={setAnswers}
                                    multiple={multiple}
                                    answerType={answerType}
                                    textAnswerSize={textAnswerSize}
                                    testId={testId}
                                    handleSelectAnswer={handleSelectAnswer}
                                    selectedAnswerId={selectedAnswerId}
                                    isSecondary={isSecondary}
                                    isManyToMany={isManyToMany}
                                />
                            </TestConstructor>
                        </TestContent>
                    </React.Fragment>
                ) : (
                    <TestParameters
                        apiInstance={isSecondary ? ClippingSecondaryAPI : ClippingAPI}
                        testId={testData.id || testId}
                        mode={questionType}
                        isEditMode={isEditMode}
                    />
                )}

                {(currentStep === STEP.QUESTION || currentStep === STEP.ANSWER) && (
                    <Col size={4}>
                        <TestOptions>
                            {currentStep === STEP.QUESTION && (
                                <PropertyColumn
                                    selectImage={(background) => setBackground(background)}
                                    selectNarrator={(narrator) =>
                                        setNarrator({
                                            id: narrator.id,
                                            url: `${url}${narrator.url}`,
                                        })
                                    }
                                    background={background}
                                    textTitleSize={textTitleSize}
                                    textAnswerSize={textAnswerSize}
                                    setTextTitleSize={(size) =>
                                        setTextTitleSize(Number.parseInt(size))
                                    }
                                    setTextAnswerSize={(size) =>
                                        setTextAnswerSize(Number.parseInt(size))
                                    }
                                    currentImageSize={imageSize}
                                    selectImageSize={setImageSize}
                                    currentPropertyTab={currentPropertyTab}
                                    onSelectTab={(tab) => setCurrentPropertyTab(tab)}
                                    constructorType={
                                        isSecondary ? CLIPPING_SECONDARY_TEST : CLIPPING_TEST
                                    }
                                    answerType={MIX_TYPE}
                                    isRoundedAnswer={isRoundedAnswer}
                                    setIsRoundedAnswer={setIsRoundedAnswer}
                                    multiple={multiple}
                                    setMultiple={null}
                                    isQuestionTextExist={questionText}
                                    selectedItem={boxes.find((box) =>
                                        isManyToMany
                                            ? box.tempId === selectedAnswerId
                                            : box.id === selectedAnswerId,
                                    )}
                                    handleUpdateCoords={handleUpdateCoords}
                                />
                            )}
                            {currentStep === STEP.ANSWER && (
                                <SaveContent>
                                    <h5>Проверьте задачу</h5>
                                    <TestSave
                                        questionId={testId}
                                        backgroundId={background?.id}
                                        apiInstance={
                                            isSecondary ? ClippingSecondaryAPI : ClippingAPI
                                        }
                                        constructorName={
                                            isSecondary ? CLIPPING_SECONDARY_TEST : CLIPPING_TEST
                                        }
                                        narratorId={narrator?.id}
                                        mode={questionType}
                                        onSaveProperties={() => {
                                            toNext();
                                        }}
                                        sizeTitle={textTitleSize}
                                        sizeText={textAnswerSize}
                                        sizeImage={imageSize}
                                        isRoundedAnswer={isRoundedAnswer}
                                        answersForSave={{answers, boxes}}
                                    />
                                </SaveContent>
                            )}
                        </TestOptions>
                    </Col>
                )}
            </Col>
        </React.Fragment>
    );
};

ClippingEngine.propTypes = {
    isEditMode: PropTypes.bool,
    isSecondary: PropTypes.bool,
    testData: PropTypes.object,
    isManyToMany: PropTypes.bool,
};

export default ClippingEngine;
