import React from "react";
import PropTypes from "prop-types";
import {AchievementsList} from "entries/achievements/achievements-list/achievements-list";

export const AchievementsListContainer = ({achievements}) => {
    return <AchievementsList achievements={achievements} />;
};

AchievementsListContainer.propTypes = {
    achievements: PropTypes.array,
};
