import {url} from "config";
import React from "react";

export const ABOUT_ENGINE_DATA = [
    {
        id: 1,
        name: "Математика 1-4 класс",
        title: "Математика 1-4 класс",
        countThemes: "235",
        countHours: "100",
        countTasks: "2800",
        logo: `${url + "data/image-bank/32d7682b-8b87-45af-a1b5-103e7dea77cc.svg"}`,
        slides: [
            {
                content: (
                    <img
                        src={url + "data/image-bank/eb400368-45ba-4cde-b126-1b67259a34a6.png"}
                        alt="slide1"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/4debc8dc-e4b8-40fc-b374-d53f4c412578.png"}
                        alt="slide2"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/cbd61b13-243c-4b72-85d8-1352ed8e49d6.png"}
                        alt="slide3"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/b97a08ba-6837-4831-a9d5-02759aa6c234.png"}
                        alt="slide4"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/4c6d1923-eeac-48de-b2ea-31b82e31863c.png"}
                        alt="slide5"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/a4df3834-98d4-4056-b71d-0c621eeaf42e.png"}
                        alt="slide6"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/847f4c46-5112-4c75-afbc-c329e95d9aec.png"}
                        alt="slide7"
                    />
                ),
            },
        ],
    },
    {
        id: 2,
        name: "Русский язык 1-4 класс",
        title: "Русский язык 1-4 класс",
        countThemes: "338",
        countHours: "100",
        countTasks: "3500",
        logo: `${url + "data/image-bank/1d3cb68a-ca0e-4616-865c-8ceeaa091912.svg"}`,
        slides: [
            {
                content: (
                    <img
                        src={url + "data/image-bank/1cf3b31c-1a89-48de-b09c-aff5cd24abea.png"}
                        alt="slide1"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/4a33ad90-e01c-4f0c-ac94-a0b326424c1c.png"}
                        alt="slide2"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/314fe648-d65b-4a44-9e81-5e1287f1b542.png"}
                        alt="slide3"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/194f53fe-a3b3-4262-a9dd-b6db0d528323.png"}
                        alt="slide4"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/ab561250-4617-48fc-b218-faa5892c9f14.png"}
                        alt="slide5"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/90e3b5ad-67cc-4a89-b38b-9120ce3fc9ca.png"}
                        alt="slide6"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/509bcdb7-86bd-4fc2-930b-b651a42b4930.png"}
                        alt="slide7"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/e62162af-412b-492b-8413-f65ada299574.png"}
                        alt="slide8"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/158a8527-ee21-48b6-b445-45a26d2ac96b.png"}
                        alt="slide9"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/1ccd56e8-5563-4c24-9758-7d4dea2240fc.png"}
                        alt="slide10"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/6cb2fa68-491a-42ef-a305-864ec731460b.png"}
                        alt="slide11"
                    />
                ),
            },
        ],
    },
    {
        id: 3,
        name: "Чтение 1-4 класс",
        title: "Чтение 1-4 класс",
        countThemes: "390",
        countHours: "100",
        countTasks: "4000",
        logo: `${url + "data/image-bank/3e9e492f-b024-491b-8abc-8fc15ef8d2d4.svg"}`,
        slides: [
            {
                content: (
                    <img
                        src={url + "data/image-bank/13098557-e707-4701-a4a7-1546beec790e.png"}
                        alt="slide1"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/43487968-beca-4df4-a409-e7d61b314cc1.png"}
                        alt="slide2"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/39e772bd-2b99-4706-b8f7-2d579c7ec39b.png"}
                        alt="slide3"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/b4146c98-5ac1-42bc-8462-349fd94e96d6.png"}
                        alt="slide4"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/c7e26349-f208-49df-85b4-f180b9ef4890.png"}
                        alt="slide5"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/5cb09dd3-8e5f-4f37-b099-e88575740498.png"}
                        alt="slide6"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/cd982058-781e-4231-ac79-9bbfdf1dc2b7.png"}
                        alt="slide7"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/f9fad065-fa42-4955-a400-57696ff47e2e.png"}
                        alt="slide8"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/92677fa3-de89-4434-b8da-33770f0f09bd.png"}
                        alt="slide9"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/9ef35b67-97d8-47ea-b30e-f5e7fdf72e2b.png"}
                        alt="slide10"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/d6595698-9c87-4167-9393-ac70169880d5.png"}
                        alt="slide11"
                    />
                ),
            },
        ],
    },
    {
        id: 4,
        name: "Окружающий мир 1-4 класс",
        title: "Окружающий мир 1-4 класс",
        countThemes: "216",
        countHours: "100",
        countTasks: "3300",
        logo: `${url + "data/image-bank/be56109d-f8bd-4fa7-bee6-d3ac74a11067.svg"}`,
        slides: [
            {
                content: (
                    <img
                        src={url + "data/image-bank/940bc183-af52-4d29-a9ee-fb2a51dcee57.png"}
                        alt="slide1"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/48e4acac-b6c6-4d93-8f94-ad2775504fb3.png"}
                        alt="slide2"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/959f9930-5ea4-49b2-a52d-6166cf3f0ada.png"}
                        alt="slide3"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/e45a35a4-552e-4a65-b7f4-7db3ac74eef5.png"}
                        alt="slide4"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/5dcd0acd-6d05-4d0a-a8c3-b7068fe9a11e.png"}
                        alt="slide5"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/f706d0a0-2367-420f-a304-1f16d90d9ab5.png"}
                        alt="slide6"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/a69b7e0a-0b31-40b0-bbc5-ad045ea73527.png"}
                        alt="slide7"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/7b0c7ae0-63c2-4382-82fd-b2cbc3c2c6db.png"}
                        alt="slide8"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/dfa2903d-e764-4cbc-b3d8-3eccbccffe0f.png"}
                        alt="slide9"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/eff188d7-aa0c-4a43-b2e5-7fcc81d71687.png"}
                        alt="slide10"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/97f451b4-4f7f-4263-b2e7-1a753f774aaa.png"}
                        alt="slide11"
                    />
                ),
            },
        ],
    },
    {
        id: 5,
        name: "Английский язык 2-4 класс",
        title: "Английский язык 2-4 класс",
        countThemes: "120",
        countHours: "50",
        countTasks: "1200",
        logo: `${url + "data/image-bank/7ab1e197-046f-47e1-a803-91eff7ed0d26.svg"}`,
        slides: [
            {
                content: (
                    <img
                        src={url + "data/image-bank/ec38eced-a6a1-430e-ae59-2b2f02c5d9d7.png"}
                        alt="slide1"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/3fbba550-ef5e-487c-8502-6e6c0d1d4837.png"}
                        alt="slide2"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/e83b6321-3ab3-492b-8410-54a7db253f28.png"}
                        alt="slide3"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/47a67d47-1f0c-4c85-b7b6-91652a78624b.png"}
                        alt="slide4"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/7bf35143-5896-46b4-9ad7-2d190e4222b2.png"}
                        alt="slide5"
                    />
                ),
            },
            {
                content: (
                    <img
                        src={url + "data/image-bank/525e0c51-b1fd-4962-b301-fd870f6d70b1.png"}
                        alt="slide6"
                    />
                ),
            },
        ],
    },
    // {
    //     id: 6,
    //     name: "Этикет",
    //     title: "Этикет 1-4 класс",
    //     countThemes: "40",
    //     countHours: "12",
    //     countTasks: "400",
    //     logo: `${url + "data/image-bank/b008e883-2e83-44b2-973f-3ca0a9ee2fc6.svg"}`,
    //     slides: [
    //         {
    //             content: (
    //                 <img
    //                     src={url + "data/image-bank/cb957806-a4e3-4be5-abb8-3a2b487f807b.png"}
    //                     alt="slide1"
    //                 />
    //             ),
    //         },
    //         {
    //             content: (
    //                 <img
    //                     src={url + "data/image-bank/62136530-7158-4d4c-93b4-e54ee5aa7268.png"}
    //                     alt="slide2"
    //                 />
    //             ),
    //         },
    //         {
    //             content: (
    //                 <img
    //                     src={url + "data/image-bank/fe4d81d4-2055-4a2b-814c-cab2c9461cf2.png"}
    //                     alt="slide3"
    //                 />
    //             ),
    //         },
    //     ],
    // },

    // {
    //     id: 7,
    //     name: "Логика 1-4 класс",
    //     title: "Логика 1-4 класс",
    //     logo: `${url + "data/image-bank/83f9c542-ff03-4aae-ba6b-36a758b31c8d.svg"}`,
    //     slides: [
    //         {content: <img src="https://i.imgur.com/quaLmcO.png" alt="slide1" />},
    //         {content: <img src="https://i.imgur.com/JnFUGtT.png" alt="slide2" />},
    //         {content: <img src="https://i.imgur.com/hSv20hR.png" alt="slide3" />},
    //     ],
    // },
];
