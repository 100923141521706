import {getDifferenceBetweenDates} from "core/utils/helpers";
import {Roles} from "enums/roles";
import {useStores} from "hooks/use-stores";
import {MDBTooltip} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import styles from "../player-v2-info-block/styles.module.sass";

const PlayerV2Timer = ({selectedQuestion}) => {
    const [timerValue, setTimerValue] = useState(0);
    const {userStore} = useStores();

    const resolveTime = getDifferenceBetweenDates(
        selectedQuestion?.academyLog?.start,
        selectedQuestion?.academyLog?.stop,
    );

    const timeToShow = (seconds) => {
        if (!seconds) return {minutes: 0, seconds: 0};
        const time = new Date(seconds * 1000).toISOString();

        const minutes = Number(time.slice(14, 16));

        return {
            minutes: minutes === 0 ? 0 : minutes,
            seconds: time.slice(17, 19),
        };
    };

    const {minutes, seconds} = timeToShow(resolveTime);

    const {minutes: resolveMinutes, seconds: resolveSeconds} = timeToShow(timerValue);

    useEffect(() => {
        let academyLog = selectedQuestion?.academyLog;
        let timerId;

        if (academyLog?.start && !academyLog?.stop) {
            timerId = setInterval(() => {
                let diff = getDifferenceBetweenDates(academyLog.start, new Date());
                setTimerValue(diff);
            }, 1000);
        } else if (!academyLog) {
            let startDate = new Date();
            timerId = setInterval(() => {
                let diff = getDifferenceBetweenDates(startDate, new Date());
                setTimerValue(diff);
            }, 1000);
        }

        return () => {
            clearInterval(timerId);
        };
    }, [selectedQuestion]);

    return (
        <div className={styles.footer}>
            {userStore.role !== Roles.TEACHER &&
                selectedQuestion?.academyLog?.start &&
                selectedQuestion?.academyLog?.stop && (
                    <React.Fragment>
                        <p className={styles.footer__result}>
                            {selectedQuestion.academyLog.flagCorrectAnswer ? (
                                <React.Fragment>
                                    <span>
                                        Это задание решено с {selectedQuestion.academyLog.tryCount}{" "}
                                        попытки
                                    </span>
                                    <br />
                                    <span>
                                        {resolveTime > 3600
                                            ? "за мно-о-ого минут…"
                                            : `за ${minutes} мин ${seconds} сек`}
                                    </span>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <span>Это задание пока не решено</span>
                                </React.Fragment>
                            )}
                        </p>
                    </React.Fragment>
                )}

            {((selectedQuestion?.academyLog?.start && !selectedQuestion?.academyLog?.stop) ||
                !selectedQuestion?.academyLog) && (
                <React.Fragment>
                    <p className={styles.footer__timer}>
                        <MDBTooltip domElement tag="span" material placement="top">
                            <span>
                                {timerValue > 1200
                                    ? "Эй! Ты что, совсем забыл про меня?"
                                    : timerValue > 600
                                    ? "Поторопись! Ворона ждёт тебя на следующем задании"
                                    : `${resolveMinutes}:${resolveSeconds}`}
                            </span>
                            <span className="bg-primary">Время решения</span>
                        </MDBTooltip>
                    </p>
                </React.Fragment>
            )}
        </div>
    );
};

PlayerV2Timer.propTypes = {
    selectedQuestion: PropTypes.any,
};

export default PlayerV2Timer;
