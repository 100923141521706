import cn from "classnames";
import {url} from "config";
import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";
import styles from "views/pages/about/sliders/teacher-class-slide/styles.module.sass";

const AcademyRatingSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${url + "data/image-bank/2c6ff1f3-13ad-4232-8bce-5bf319117417.png"})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            <div className={sliders.content}>
                <div
                    className={cn({
                        [sliders.about]: true,
                        "mt-5": true,
                    })}
                >
                    <p
                        className={cn({
                            [sliders.title]: true,
                            [styles.title]: true,
                            "text-center": true,
                        })}
                    >
                        Учитесь играя!
                    </p>
                    <p
                        className={cn({
                            [sliders.description]: true,
                            [styles.description]: true,
                            "text-center": true,
                            "w-100": true,
                        })}
                    >
                        Соревнуйтесь с другими учениками со всей России <br /> в знаниях и скорости.
                        Не бойтесь стать лучшим!
                    </p>
                </div>
            </div>
        </div>
    );
};

AcademyRatingSlide.propTypes = {};

export default AcademyRatingSlide;
