import CreateTest from "entries/constructors/classic-test/views/containers/create-test/create-test";
import {SequencesTestAPI} from "entries/constructors/sequences/core/api/sequences";
import {NumericSequenceTest} from "entries/constructors/sequences/numeric-sequence-test/views/containers/numeric-sequence-test";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";

export const NumericSequenceManager = ({routerInstance}) => {
    const [testData, setTestData] = useState({});
    const [mode, setMode] = useState(undefined);
    let history = useHistory();

    useEffect(() => {
        if (
            !history.location.state &&
            !history.location.pathname.includes(
                routerInstance.NUMERIC_SEQUENCE_TEST.EDITING.replace(":id", ""),
            )
        ) {
            history.push(routerInstance.ROOT);
        }

        setMode(history.location.state);
    }, []);

    const onCreateTest = (testData) => {
        setTestData(testData);
        history.push(routerInstance.NUMERIC_SEQUENCE_TEST.CONSTRUCTOR);
    };

    return (
        <div>
            <Switch>
                <Route path={routerInstance.NUMERIC_SEQUENCE_TEST.CREATE_TEST}>
                    <CreateTest
                        onCreateTest={onCreateTest}
                        apiInstance={SequencesTestAPI}
                        mode={mode}
                    />
                </Route>

                <Route path={routerInstance.NUMERIC_SEQUENCE_TEST.EDITING}>
                    <NumericSequenceTest testData={testData} isEditMode />
                </Route>

                <Route path={routerInstance.NUMERIC_SEQUENCE_TEST.CONSTRUCTOR}>
                    <NumericSequenceTest testData={testData} />
                </Route>
            </Switch>
        </div>
    );
};

NumericSequenceManager.propTypes = {
    routerInstance: PropTypes.any,
};
