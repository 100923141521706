import React, {useEffect, useState} from "react";
import {ITempSchool} from "../../../../interfaces/ITempSchool";
import {MDBAlert, MDBBadge, MDBBtn} from "mdbreact";
import styles from "./styles.module.sass";
import {getFullName} from "../../../../core/utils/helpers";
import {ISchool} from "../../../../interfaces/ISchool";
import {ISelect} from "../../../../interfaces/ISelect";
import {GeoAPI} from "../../../geo-selects/core/GeoAPI";
import SpinnerWithText from "../../../spinner/spinner";
import {AxiosResponse} from "axios";
import {Switch} from "../../../../views/components/switch";
import {IUser} from "../../../../interfaces/IUser";
import GeoSelectSchoolContainer from "../../../geo-selects/views/geo-select-school/geo-select-school-container";
import FormSchool from "../../../form-school/form-schools";

interface IProps {
    tempSchool?: ITempSchool;
    handleCreateNewSchool: (
        payload: ISchool,
        user: IUser,
        withChangeSchool: boolean,
        requestId: number,
    ) => void;
    handleCloseRequest: (requestId: number) => void;
    handleBindUserWithSchool: (userId: number, schoolId: number) => void;
    modalDataIsLoading: boolean;
}

interface IUserRequestProps {
    request: ITempSchool;
}

const UserRequestForm = ({request}: IUserRequestProps) => {
    return (
        <div className={styles.requestWrapper}>
            <div className={styles.formRow}>
                <p>
                    <span className="title">ID Пользователя:</span> {request.user.id}
                </p>
            </div>

            <div className={styles.formRow}>
                <p>
                    <span className="title">Пользователь: </span>
                    {getFullName(request.user?.firstName, request.user?.lastName)}
                </p>
            </div>

            <div className={styles.formRow}>
                <p>
                    <span className="title">Школа:</span> {request.schoolName}
                </p>
            </div>

            <div className={styles.formRow}>
                <p>
                    <span className="title">Регион пользователя:</span> {request.region?.altName}
                </p>
            </div>

            <div className={styles.formRow}>
                <p>
                    <span className="title">Город пользователя:</span> {request.city?.altName}
                </p>
            </div>
        </div>
    );
};

const AdminPanelSchoolsTempEdit = ({
    tempSchool,
    handleCreateNewSchool,
    modalDataIsLoading,
    handleCloseRequest,
    handleBindUserWithSchool,
}: IProps) => {
    const [request, setRequest] = useState<ITempSchool | undefined>(undefined);
    const [selectedRegion, setSelectedRegion] = useState<ISelect | undefined>(undefined);
    const [selectedCity, setSelectedCity] = useState<ISelect | undefined>(undefined);
    const [schoolName, setSchoolName] = useState<string | undefined>(undefined);
    const [similarSchools, setSimilarSchools] = useState<ISchool[] | undefined>(undefined);
    const [schoolsIsLoading, setSchoolsIsLoading] = useState<boolean>(false);
    const [isToggledUserSchoolChange, setIsToggledUserSchoolChange] = useState(true);
    const [isCreateSchoolOpen, setIsCreateSchoolOpen] = useState<boolean>(false);
    const [isSelfSearchActive, setIsSelfSearchActive] = useState<boolean>(false);
    const [selectedSchoolSelf, setSelectedSchoolSelf] = useState<ISelect | null>(null);
    const [newSchool, setNewSchool] = useState<ISchool | undefined>(undefined);

    useEffect(() => {
        setRequest(tempSchool);
        getSimilarSchools();
    }, [tempSchool]);

    useEffect(() => {
        handleFillFormFromUserRequest();
    }, [request]);

    const getSimilarSchools = () => {
        if (tempSchool?.city.geoNameId && tempSchool.region.geoNameId) {
            setSchoolsIsLoading(true);
            GeoAPI.getSchoolsElastic(
                tempSchool.city.geoNameId,
                tempSchool.region.geoNameId,
                1,
                tempSchool.schoolName,
                3,
            )
                .then((response: AxiosResponse) => {
                    setSimilarSchools(response.data.content);
                })
                .finally(() => setSchoolsIsLoading(false));
        }
    };

    const handleFillFormFromUserRequest = () => {
        setSchoolName(request?.schoolName);

        if (request?.region.geoNameId && request.region.altName) {
            setSelectedRegion({value: request?.region.geoNameId, label: request?.region.altName});
        }

        if (request?.city.geoNameId && request.city.altName) {
            setSelectedCity({value: request?.city.geoNameId, label: request?.city.altName});
        }
    };

    if (!request) {
        return <p>Школа не указана</p>;
    }

    return (
        <div className={styles.formWrapper}>
            <div className={styles.form}>
                {!isCreateSchoolOpen && <UserRequestForm request={request} />}

                <p className="link" onClick={() => setIsCreateSchoolOpen(!isCreateSchoolOpen)}>
                    {isCreateSchoolOpen ? "Закрыть" : "Открыть"} форму создания школы
                </p>

                {isCreateSchoolOpen && (
                    <div className={styles.createSchool}>
                        {!!similarSchools?.length && (
                            <MDBAlert color="warning" className="mt-3">
                                Внимание! Есть похожие школы в базе данных.
                            </MDBAlert>
                        )}

                        <FormSchool
                            school={newSchool}
                            selectedCity={selectedCity}
                            setSelectedCity={setSelectedCity}
                            setSchool={setNewSchool}
                            selectedRegion={selectedRegion}
                            setSelectedRegion={setSelectedRegion}
                        />

                        <div className="d-flex align-items-center">
                            <Switch
                                checked={isToggledUserSchoolChange}
                                onChange={() =>
                                    setIsToggledUserSchoolChange(!isToggledUserSchoolChange)
                                }
                                className={styles.switch}
                            />

                            <p>Изменить школу пользователя на созданную</p>
                        </div>

                        {modalDataIsLoading ? (
                            <SpinnerWithText text="Загрузка..." className="mt-5" />
                        ) : (
                            <React.Fragment>
                                <MDBAlert
                                    className="mt-3 d-flex align-items-center"
                                    color="success"
                                >
                                    <MDBBtn
                                        color="success"
                                        onClick={() =>
                                            handleCreateNewSchool(
                                                {
                                                    region: {geoNameId: selectedRegion?.value || 0},
                                                    city: {geoNameId: selectedCity?.value || 0},
                                                    alternateName: schoolName,
                                                    ...newSchool,
                                                },
                                                request?.user,
                                                isToggledUserSchoolChange,
                                                tempSchool?.id ? tempSchool.id : 0,
                                            )
                                        }
                                    >
                                        Создать школу
                                    </MDBBtn>

                                    <p className={styles.description}>
                                        Создание новой школы и закрытие заявки
                                        {isToggledUserSchoolChange
                                            ? ` с изменением текущей школы у пользователя`
                                            : ` без изменений школы для пользователя`}
                                    </p>
                                </MDBAlert>
                            </React.Fragment>
                        )}
                    </div>
                )}

                {modalDataIsLoading ? (
                    <SpinnerWithText text="Загрузка..." className="mt-5" />
                ) : (
                    <React.Fragment>
                        <MDBAlert className="mt-3 d-flex align-items-center" color="warning">
                            <MDBBtn
                                color="success"
                                onClick={() =>
                                    handleCloseRequest(tempSchool?.id ? tempSchool.id : 0)
                                }
                            >
                                Закрыть заявку
                            </MDBBtn>

                            <p className={styles.description}>Заявка будет закрыта</p>
                        </MDBAlert>
                    </React.Fragment>
                )}
            </div>
            <div className={styles.helper}>
                <p className="title">Помощник по созданию</p>

                <div className={styles.formRow}>
                    <MDBBtn
                        color="primary"
                        className="w-50"
                        onClick={() =>
                            window.open(
                                `https://www.google.com/search?q=${
                                    request?.region && `${request.region.altName}`
                                } ${request?.city && `${request.city.altName}`} ${
                                    request?.schoolName
                                }`,
                                "_blank",
                            )
                        }
                    >
                        Поиск в Google
                    </MDBBtn>
                </div>

                <p className="title">Поиск в базе данных</p>
                <p className="link mb-2" onClick={() => setIsSelfSearchActive(!isSelfSearchActive)}>
                    Перейти на {isSelfSearchActive ? "автоматический поиск" : "ручной поиск"}
                </p>

                {isSelfSearchActive ? (
                    <React.Fragment>
                        <GeoSelectSchoolContainer
                            regionId={selectedRegion?.value}
                            cityId={selectedCity?.value}
                            selectedSchool={selectedSchoolSelf}
                            handleSelect={setSelectedSchoolSelf}
                        />

                        {selectedSchoolSelf?.value && (
                            <MDBBtn
                                size="sm"
                                color="primary"
                                className="mt-2"
                                onClick={() =>
                                    handleBindUserWithSchool(
                                        tempSchool?.user.id || 0,
                                        selectedSchoolSelf?.value || 0,
                                    )
                                }
                            >
                                Привязать пользователя к {selectedSchoolSelf?.label}
                            </MDBBtn>
                        )}
                    </React.Fragment>
                ) : (
                    <div className={styles.similarSchoolsWrapper}>
                        {schoolsIsLoading ? (
                            <SpinnerWithText text="Загрузка школ..." />
                        ) : (
                            <React.Fragment>
                                {similarSchools?.length ? (
                                    <React.Fragment>
                                        {similarSchools?.map((school, index) => (
                                            <div
                                                style={{
                                                    boxShadow:
                                                        index === 0
                                                            ? "0 0 5px lightsalmon"
                                                            : "none",
                                                    padding: 10,
                                                }}
                                                key={index}
                                            >
                                                <p>
                                                    {index === 0 && (
                                                        <React.Fragment>
                                                            <MDBBadge color="danger">
                                                                Самое близкое совпадение
                                                            </MDBBadge>
                                                            <br />
                                                        </React.Fragment>
                                                    )}
                                                    {school?.id === tempSchool?.school?.id && (
                                                        <React.Fragment>
                                                            <MDBBadge color="warning">
                                                                Пользователь привязан к этой школе
                                                            </MDBBadge>
                                                            <br />
                                                        </React.Fragment>
                                                    )}
                                                    Название: <b>{school?.alternateName}</b> <br />{" "}
                                                    Короткое название: <b>{school?.shortName}</b>
                                                </p>

                                                <MDBBtn
                                                    size="sm"
                                                    color="primary"
                                                    onClick={() =>
                                                        handleBindUserWithSchool(
                                                            tempSchool?.user.id || 0,
                                                            school.id || 0,
                                                        )
                                                    }
                                                >
                                                    Привязать пользователя к {school?.alternateName}
                                                </MDBBtn>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>Нет совпадений</React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminPanelSchoolsTempEdit;
