import {TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG} from "config";
import {TagUtils} from "core/api/tag-utils";
import {ThemeAPI} from "entries/school-themes/core/api/themes";
import React, {useCallback, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {CreateThemeModal} from "entries/school-themes/views/components/create-theme-modal/create-theme.modal";
import {ListTools} from "views/components/list-tools";
import {ListWithPagination} from "views/components/list-with-pagination";

export const ThemesList = () => {
    const [themes, setThemes] = useState([]);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [activePage, setActivePage] = useState(1);

    const [, setIsFetching] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [classes, setClasses] = useState([]);

    const [modalVisible, setModalVisible] = useState(false);
    const [itemForEdit, setItemForEdit] = useState(null);

    useEffect(() => {
        getThemes(1);
        getSubjectsAndClasses();
    }, []);

    const getThemes = useCallback(
        (activePage) => {
            setIsFetching(true);
            ThemeAPI.getGroupingThemes(null, null, activePage)
                .then((response) => {
                    setThemes(response.data.content);
                    setTotalItemsCount(response.data.totalElements);
                })
                .catch(() => toast.error("Ошибка загрузки данных", TOAST_ERROR_CONFIG))
                .finally(() => setIsFetching(false));
        },
        [activePage],
    );

    const handlePageChange = useCallback((pageNumber) => {
        setActivePage(pageNumber);
        getThemes(pageNumber);
    }, []);

    const getItemForEdit = (id) => {
        return themes.find((theme) => theme.id === id);
    };

    const handleThemeDelete = (id) => {
        ThemeAPI.deleteGroupedTheme(id)
            .then(() => {
                toast.success("Тема удалена", TOAST_SUCCESS_CONFIG);
                getThemes();
            })
            .catch(() => toast.error("Ошибка удаления темы", TOAST_ERROR_CONFIG));
    };

    const handleThemeEdit = (id) => {
        let theme = getItemForEdit(id);
        if (!theme) return;
        setItemForEdit(theme);
        setModalVisible(true);
    };

    const onSaveNewTheme = (newTheme) => {
        setModalVisible(!modalVisible);

        if (itemForEdit) {
            setItemForEdit(null);
            getThemes();
            return;
        }

        setThemes([...themes, newTheme]);
    };

    const getSubjectsAndClasses = () => {
        TagUtils.getSchoolSubjects()
            .then((response) => {
                setIsFetching(true);
                let subjectsForSave = response.data.map((subject) => {
                    return {value: subject.id, label: subject.name};
                });
                setSubjects(subjectsForSave);
            })
            .then(() => {
                TagUtils.getClasses().then((response) => {
                    let schoolClassesForSave = response.data.map((schoolClass) => {
                        return {value: schoolClass.id, label: schoolClass.name};
                    });
                    setClasses(schoolClassesForSave);
                });
            })
            .finally(() => {
                setIsFetching(false);
            });
    };

    return (
        <div className="w-100">
            <CreateThemeModal
                toggle={() => setModalVisible(!modalVisible)}
                visible={modalVisible}
                classes={classes}
                subjects={subjects}
                onSaveNewTheme={onSaveNewTheme}
                itemForEdit={itemForEdit}
            />
            <ListTools handlerAddNewItem={() => setModalVisible(true)} />
            <div>
                <ListWithPagination
                    dataForList={themes}
                    activePage={activePage}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={(page) => handlePageChange(page)}
                    handleItemDelete={(themeId) => handleThemeDelete(themeId)}
                    handleItemEdit={(themeId) => handleThemeEdit(themeId)}
                    listType="Theme"
                />
            </div>
        </div>
    );
};

ThemesList.propTypes = {};
