import {useEffect, useRef, useState} from "react";
import {SECOND_TRIGGER, TRAINING_DIRECTIONS, TRAINING_REF_TYPES} from "./config/config";

const useTraining = ({
    isEditMode,
    constructorName,
    questionText,
    answers,
    narrator,
    background,
    currentStep,
    textTitleSize,
}) => {
    const [isBlackoutActivated, setIsBlackoutActivated] = useState(true);

    const [isTrainingActivated, setIsTrainingActivated] = useState(false);
    const [trainingScript, setTrainingScript] = useState(null);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [currentTrainingStep, setCurrentTrainingStep] = useState(null);

    const [helperData, setHelperData] = useState(null);

    //refs
    const questionRef = useRef(null);
    const addAnswerRef = useRef(null);
    const propertiesRef = useRef(null);

    const helperRef = useRef(null);

    useEffect(() => {
        console.log("[TRAINING] Training system initialized");
        if (isEditMode) {
            console.log("[TRAINING] Edit mode, training disabled");
            return;
        }
        loadTrainingScript();
    }, []);

    useEffect(() => {
        if (trainingScript) {
            let trainingIsAvailable = checkAvailableTrainingScript();
            if (trainingIsAvailable && trainingScript.STEPS?.length) {
                updateTrainingScript();
                if (trainingScript.STEPS[currentStepIndex]) {
                    setCurrentTrainingStep(trainingScript.STEPS[currentStepIndex]);
                    setIsTrainingActivated(true);
                }
            }
        }
    }, [trainingScript]);

    useEffect(() => {
        if (!currentTrainingStep) return;
        showCurrentStep();
    }, [currentTrainingStep]);

    useEffect(() => {
        let step = trainingScript?.STEPS[currentStepIndex];

        if (step?.secondTrigger) {
            if (step.secondTrigger === SECOND_TRIGGER.FONT_SIZE_QUESTION) {
                if (step.targetValue === textTitleSize) {
                    toNextStep();
                }
            }

            if (step.secondTrigger === SECOND_TRIGGER.NARRATOR) {
                if (narrator) {
                    toNextStep();
                }
            }
        }

        switch (step?.refType) {
            case TRAINING_REF_TYPES.QUESTION:
                questionText.length && toNextStep();
                return;
            case TRAINING_REF_TYPES.ADD_ANSWER:
                answers.length && toNextStep();
                return;
            case TRAINING_REF_TYPES.PARAMETERS:
                narrator && toNextStep();
                return;
            case TRAINING_REF_TYPES.BACKGROUND:
                background && toNextStep();
                return;
        }
    }, [questionText, answers, narrator, background, textTitleSize]);

    useEffect(() => {
        if (!currentTrainingStep || !currentStep) return;

        if (!currentTrainingStep.isOpen) {
            if (currentTrainingStep.step === currentStep) {
                setIsBlackoutActivated(true);
                setCurrentTrainingStep({...currentTrainingStep, isOpen: true});
            }
        }
    }, [currentStep, currentTrainingStep]);

    const toNextStep = () => {
        let nextStep = trainingScript?.STEPS[currentStepIndex + 1];

        if (currentTrainingStep.ref) {
            currentTrainingStep.ref.style.zIndex = 1;
        }

        if (nextStep) {
            setCurrentTrainingStep(nextStep);
            setCurrentStepIndex(currentStepIndex + 1);
        } else {
            setIsTrainingActivated(false);
            localStorage.setItem(constructorName, "true");
            console.log("[TRAINING] Training complete");
        }
    };

    const showCurrentStep = () => {
        let tempHelperData = {...currentTrainingStep};

        switch (currentTrainingStep.direction) {
            case TRAINING_DIRECTIONS.TOP:
                tempHelperData.x = currentTrainingStep.left;
                tempHelperData.y = currentTrainingStep.top - 100;
                break;
            case TRAINING_DIRECTIONS.LEFT:
                tempHelperData.x = currentTrainingStep.left - 460;
                tempHelperData.y = currentTrainingStep.top;
                break;
            case TRAINING_DIRECTIONS.RIGHT:
                tempHelperData.x = currentTrainingStep.left + currentTrainingStep.width + 20;
                tempHelperData.y = currentTrainingStep.top;
                break;
        }

        tempHelperData.isOpen = currentTrainingStep.step === currentStep;

        setIsBlackoutActivated(tempHelperData.isOpen);
        tempHelperData.isBigMessage = currentTrainingStep.refType === TRAINING_REF_TYPES.MESSAGE;

        if (currentTrainingStep.ref) {
            currentTrainingStep.ref.style.zIndex = 5001;
        }

        setHelperData(tempHelperData);
    };

    const updateTrainingScript = () => {
        let updatedScript = trainingScript;
        updatedScript.STEPS = updatedScript.STEPS.map((step) => {
            let ref;
            switch (step.refType) {
                case TRAINING_REF_TYPES.QUESTION:
                    ref = questionRef.current;
                    break;
                case TRAINING_REF_TYPES.ADD_ANSWER:
                    ref = addAnswerRef.current;
                    break;
                case TRAINING_REF_TYPES.PARAMETERS:
                    ref = propertiesRef.current;
                    break;
                case TRAINING_REF_TYPES.BACKGROUND:
                    ref = propertiesRef.current;
                    break;
            }

            if (step.refType === TRAINING_REF_TYPES.MESSAGE) {
                return {...step};
            }

            let rect = ref.getBoundingClientRect();

            return {...step, ref, top: rect.top, left: rect.left, width: ref.offsetWidth};
        });

        setTrainingScript(updatedScript);
    };

    const checkAvailableTrainingScript = () => {
        // проверяем имеются для у данного конструктора необходимые рефы для сценария
        // TODO: проверка по рефам
        let isAvailable = true;

        // проверяем проходил ли юзер этот сценарий
        if (localStorage.getItem(constructorName)) {
            isAvailable = false;
        }

        return isAvailable;
    };

    const loadTrainingScript = () => {
        // загружаем нужный сценарий взависимости от типа конструктора
        switch (constructorName) {
            default:
                console.log("[TRAINING] Current constructor dont have script");
                return;
        }
    };

    return {
        isTrainingActivated,
        isBlackoutActivated,
        currentTrainingStep,
        questionRef,
        addAnswerRef,
        helperRef,
        propertiesRef,
        helperData,
        toNextStep,
    };
};

useTraining.propTypes = {};

export default useTraining;
