import {getBackground} from "config/backgrounds";
import {ADMIN_ROLE, STUDENT_ROLE, TEACHER_ROLE, USER_ROLE} from "config/roles";
import {auth} from "core/utils/auth";
import {getRole} from "core/utils/helpers";
import {getHeaderTabs} from "entries/header/config/header-tabs";
import {HeaderContainer} from "entries/header/views/header-container";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import RatingsPage from "entries/ratings/ratings-page";
import ReceivedAchievements from "entries/received-achievements/received-achievements";
import {TEACHER_WORKSHOP_ROUTES} from "entries/teacher-workshop/routes";
import TeacherWorkshopPage from "entries/teacher-workshop/teacher-workshop-page";
import React, {Suspense, useEffect, useState} from "react";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {ADMIN_ROUTES} from "routes/admin";
import {
    ACADEMY_ROUTES,
    PUBLIC_ROUTES,
    RATING_ROUTES,
    SERVICE_ROUTES,
    STUDENT_ROUTES,
    TEACHER_ROUTES,
    TOURNAMENT_ROUTES,
    USER_ROUTES,
} from "routes/main";
import {PrivateRoute} from "utils/private-route";
import PageNotFound from "views/pages/404/404";
import NotificationModalLayer from "../../entries/notifications-modal/notification-modal-layer";
import PlayerV2NotificationLoading from "../../entries/player-v2/views/player-v2-notifications/player-v2-notification-loading/player-v2-notification-loading";

import styles from "./main.module.sass";

const AdminPage = React.lazy(() => import("./admin/index"));
const AcademyPage = React.lazy(() => import("./academy/academy-page"));
const PublicPage = React.lazy(() => import("./public/public-page"));
const TeacherPage = React.lazy(() => import("./teacher/teacher-page"));
const TournamentPage = React.lazy(() => import("./tournament/tournament-page"));
const StudentPage = React.lazy(() => import("./student/student-page"));
const SelectRoleContainer = React.lazy(() =>
    import("../../entries/select-role/select-role-container"),
);
const RegisterFormContainer = React.lazy(() =>
    import("../../entries/register-form/register-form-container"),
);
const PlayerV2Container = React.lazy(() =>
    import("../../entries/player-v2/views/player-v2/player-v2-container"),
);

export const MainPage = () => {
    const {keycloak} = auth;
    let location = useLocation();
    const history = useHistory();
    const [tabs, setTabs] = useState([]);

    useEffect(() => {
        if (history.location.pathname === "/") {
            const role = getRole(keycloak);
            if (role === TEACHER_ROLE) {
                history.push(TEACHER_ROUTES.TRAINING.ROOT);
            } else if (role === STUDENT_ROLE) {
                history.push(STUDENT_ROUTES.ROOT);
            }
        }
    }, [history.location]);

    useEffect(() => {
        if (location) {
            let background = getBackground(location.pathname);

            if (background) {
                document.body.style.background = `url(${background.path}) fixed center no-repeat`;
                document.body.style.backgroundSize = "cover";
            } else {
                document.body.style.background = null;
            }
        }
    }, [location]);

    useEffect(() => {
        setTabs(getHeaderTabs(keycloak));
    }, []);

    return (
        <div className={styles.wrapper}>
            <React.Fragment>
                {!location.pathname.includes(ADMIN_ROUTES.ROOT) &&
                    !location.pathname.includes(USER_ROUTES.REGISTER) && (
                        <HeaderContainer
                            tabs={tabs}
                            userName={keycloak.tokenParsed?.preferred_username}
                            withEvents
                            withShop
                        />
                    )}

                <Suspense
                    fallback={
                        <NotificationModalLayer isActive={true}>
                            <PlayerV2NotificationLoading />
                        </NotificationModalLayer>
                    }
                >
                    <Switch>
                        <PrivateRoute
                            roles={[USER_ROLE, TEACHER_ROLE, ADMIN_ROLE, STUDENT_ROLE]}
                            path={USER_ROUTES.REGISTER}
                        >
                            <RegisterFormContainer />
                        </PrivateRoute>

                        <PrivateRoute
                            roles={[USER_ROLE, TEACHER_ROLE, ADMIN_ROLE, STUDENT_ROLE]}
                            path={USER_ROUTES.RECEIVED_ACHIEVEMENTS}
                        >
                            <ReceivedAchievements />
                        </PrivateRoute>

                        <PrivateRoute roles={[ADMIN_ROLE]} path={TEACHER_WORKSHOP_ROUTES.ROOT}>
                            <TeacherWorkshopPage />
                        </PrivateRoute>

                        {/*Выбор роли*/}
                        <PrivateRoute roles={[USER_ROLE]} path={USER_ROUTES.SELECT_ROLE}>
                            <SelectRoleContainer />
                        </PrivateRoute>

                        {/*STUDENT*/}
                        <PrivateRoute roles={[STUDENT_ROLE]} path={STUDENT_ROUTES.ROOT}>
                            <StudentPage />
                        </PrivateRoute>

                        {/*TEACHER*/}
                        <PrivateRoute roles={[TEACHER_ROLE, ADMIN_ROLE]} path={TEACHER_ROUTES.ROOT}>
                            <TeacherPage />
                        </PrivateRoute>

                        {/*ACADEMY*/}
                        <PrivateRoute
                            roles={[TEACHER_ROLE, ADMIN_ROLE, STUDENT_ROLE]}
                            path={ACADEMY_ROUTES.ROOT}
                        >
                            <AcademyPage />
                        </PrivateRoute>

                        {/*ADMIN*/}
                        <PrivateRoute roles={[ADMIN_ROLE]} path={ADMIN_ROUTES.ROOT}>
                            <AdminPage />
                        </PrivateRoute>

                        <PrivateRoute
                            roles={[TEACHER_ROLE, ADMIN_ROLE, STUDENT_ROLE]}
                            path={TOURNAMENT_ROUTES.ROOT}
                        >
                            <TournamentPage />
                        </PrivateRoute>

                        <Redirect from={RATING_ROUTES.ROOT} to={RATING_ROUTES.ACADEMY} exact />

                        <PrivateRoute
                            roles={[USER_ROLE, STUDENT_ROLE, TEACHER_ROLE, ADMIN_ROLE]}
                            path={RATING_ROUTES.ROOT}
                        >
                            <RatingsPage />
                        </PrivateRoute>

                        <PrivateRoute
                            roles={[ADMIN_ROLE, TEACHER_ROLE]}
                            path={SERVICE_ROUTES.SINGLE_TASK_PREVIEW}
                        >
                            <PlayerV2Container
                                playerMode={PlayerV2Modes.OLD_PREVIEW}
                                isSingleTask={true}
                            />
                        </PrivateRoute>

                        <Route path={PUBLIC_ROUTES.ROOT}>
                            <PublicPage />
                        </Route>

                        <Route path="*">
                            <PageNotFound />
                        </Route>
                    </Switch>
                </Suspense>
            </React.Fragment>
        </div>
    );
};
