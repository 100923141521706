import {TOAST_ERROR_CONFIG} from "config";
import {BasketsAPI} from "entries/constructors/baskets-game/core/BasketsAPI";
import {BasketRectangle} from "entries/constructors/baskets-game/views/basket-rectangle/basket-rectangle";
import PlayerV2NotificationsLayer from "entries/player-v2/views/player-v2-notifications-layer/player-v2-notifications-layer";
import PlayerV2NotificationLoading from "entries/player-v2/views/player-v2-notifications/player-v2-notification-loading/player-v2-notification-loading";
import Konva from "konva";
import {MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React, {useRef, useState} from "react";
import {Group, Image, Layer, Rect, Stage, Text} from "react-konva";
import {toast} from "react-toastify";
import {EditButtons} from "views/components/edit-buttons";
import {ImageUploadModal} from "views/components/image-upload-modal/image-upload-modal";
import styles from "./styles.module.sass";

const BasketsConstructor = ({
    baskets,
    setBaskets,
    answers,
    setAnswers,
    basketIdForDrop,
    draggableId,
    releaseDrop,
    setDraggableId,
    onMoveAnswer,
    selectedBasketId,
    setSelectedBasketId,
    testId,
    image,
    setImageUrl,
    isEditMode,
    textAnswerSize,
}) => {
    const backgroundRef = useRef();
    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const checkDeselect = (e) => {
        const clickedOnEmpty =
            e.target === e.target.getStage() || e.target === backgroundRef?.current;
        if (clickedOnEmpty) {
            setSelectedBasketId(null);
        }
    };

    const createBasket = () => {
        const shapeProps = {
            x: 10,
            y: 10,
            width: 100,
            height: 100,
            fill: "rgba(0, 0, 0, 0.2)",
            color: Konva.Util.getRandomColor(),
            answers: [],
        };

        setIsLoading(true);
        BasketsAPI.createBasket(testId, {
            x: shapeProps.x,
            y: shapeProps.y,
            width: shapeProps.width,
            height: shapeProps.height,
        })
            .then((response) => {
                setBaskets((prev) => [...prev, {...shapeProps, id: response.data.id}]);
            })
            .catch(() => toast.error("Ошибка создания корзины", TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));
    };

    const handleCreateGameBackground = (imageData) => {
        setIsLoading(true);
        BasketsAPI.createGameBackground(testId, {file: imageData.image, params: imageData.params})
            .then((response) => setImageUrl(response.data))
            .catch(() => toast.error("Ошибка загрузки изображения", TOAST_ERROR_CONFIG))
            .finally(() => {
                setIsLoading(false);
            });

        setUploadModalIsOpen(false);
    };

    const createAnswer = (type) => {
        if (type === "text") {
            const text = {
                x: 0,
                y: 0,
                objectType: "text",
            };

            text.text = window.prompt("Введите текст");

            setIsLoading(true);
            BasketsAPI.createAnswerText(testId, text)
                .then((response) => {
                    setAnswers([...answers, {...text, id: response.data.id}]);
                })
                .catch(() => toast.error("Ошибка загрузки ответа", TOAST_ERROR_CONFIG))
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    if (isLoading) {
        return (
            <PlayerV2NotificationsLayer isActive={true}>
                <PlayerV2NotificationLoading />
            </PlayerV2NotificationsLayer>
        );
    }

    return (
        <React.Fragment>
            {uploadModalIsOpen && (
                <ImageUploadModal
                    toggle={() => setUploadModalIsOpen(!uploadModalIsOpen)}
                    uploadIsAvailable
                    selectFromLibraryAvailable={false}
                    visible={uploadModalIsOpen}
                    onSaveImage={handleCreateGameBackground}
                />
            )}

            {image ? (
                <div className={styles.baskets}>
                    {isEditMode && <EditButtons editAction={() => setUploadModalIsOpen(true)} />}

                    <Stage
                        width={588}
                        height={image?.height}
                        onMouseDown={checkDeselect}
                        onTouchStart={checkDeselect}
                    >
                        <Layer>
                            <Image image={image} ref={backgroundRef} />
                        </Layer>

                        <Layer>
                            {baskets.map((basket, index) => (
                                <BasketRectangle
                                    key={basket.id}
                                    shapeProps={basket}
                                    onChange={(newAttrs) => {
                                        const rects = baskets.slice();
                                        rects[index] = newAttrs;
                                        setBaskets(rects);
                                    }}
                                    onSelect={() => setSelectedBasketId(basket.id)}
                                    isSelected={basket.id === selectedBasketId}
                                    basketIdForDrop={basketIdForDrop}
                                    deleteBasket={() => console.log("del")}
                                />
                            ))}
                        </Layer>

                        <Layer>
                            {answers.map((answer) => {
                                if (answer.objectType === "text") {
                                    return (
                                        <Group
                                            key={answer.id}
                                            width={150}
                                            draggable
                                            onDragStart={() => setDraggableId(answer.id)}
                                            onDragEnd={releaseDrop}
                                            onDragMove={onMoveAnswer}
                                        >
                                            <Rect
                                                width={150}
                                                height={50}
                                                fill="rgba(0,0,0, 0.2)"
                                                strokeWidth="1"
                                                stroke="#45B7D6"
                                            />
                                            <Text
                                                text={answer.text}
                                                fill={draggableId === answer.id ? "green" : "black"}
                                                wrap="word"
                                                width={150}
                                                fontSize={textAnswerSize}
                                                height={50}
                                                align="center"
                                            />
                                        </Group>
                                    );
                                }
                            })}
                        </Layer>
                    </Stage>
                    {isEditMode && (
                        <div className="d-flex justify-content-center mt-3">
                            <MDBBtn color="indigo" onClick={createBasket} className="mr-3">
                                Добавить корзину
                            </MDBBtn>
                            <MDBBtn color="indigo" onClick={() => createAnswer("text")}>
                                Добавить текстовый ответ
                            </MDBBtn>
                        </div>
                    )}
                </div>
            ) : (
                <div className={styles.addBackground} onClick={() => setUploadModalIsOpen(true)}>
                    <p className="link">Добавить изображение</p>
                </div>
            )}
        </React.Fragment>
    );
};

BasketsConstructor.propTypes = {
    onMoveAnswer: PropTypes.func,
    answers: PropTypes.array,
    background: PropTypes.object,
    basketIdForDrop: PropTypes.any,
    baskets: PropTypes.array,
    draggableId: PropTypes.any,
    image: PropTypes.any,
    releaseDrop: PropTypes.func,
    selectedBasketId: PropTypes.any,
    setAnswers: PropTypes.func,
    setBackground: PropTypes.func,
    setBaskets: PropTypes.func,
    setDraggableId: PropTypes.func,
    setSelectedBasketId: PropTypes.func,
    testId: PropTypes.number,
    setImageUrl: PropTypes.any,
};

export default BasketsConstructor;
