import TagItem from "entries/tags/views/tag-item/tag-item";
import PropTypes from "prop-types";
import React, {useState} from "react";
import styles from "./styles.module.sass";
import cn from "classnames";

const TagList = ({
    tags,
    isCards,
    withShowAllTags,
    className,
    handleDeleteTag,
    cardClassName,
    customTagColors,
    hideDelete,
    handleClick,
    url,
}) => {
    const [showMore, setShowMore] = useState(false);
    return (
        <div
            className={cn({
                [styles.cards]: isCards,
                [styles.tags]: !isCards,
                [className]: !!className,
            })}
        >
            {tags?.map((tag, index) => {
                if (!tag) {
                    return;
                }

                if (showMore || withShowAllTags) {
                    return (
                        <TagItem
                            key={index}
                            tag={tag}
                            isCards={isCards}
                            handleDeleteTag={handleDeleteTag}
                            cardClassName={cardClassName}
                            customTagColors={customTagColors}
                            hideDelete={hideDelete}
                            handleClick={handleClick}
                            url={url}
                        />
                    );
                } else if (index < 5) {
                    return (
                        <TagItem
                            key={index}
                            tag={tag}
                            isCards={isCards}
                            handleDeleteTag={handleDeleteTag}
                            cardClassName={cardClassName}
                            customTagColors={customTagColors}
                            hideDelete={hideDelete}
                            handleClick={handleClick}
                            url={url}
                        />
                    );
                }
            })}
            {tags?.length > 5 && !showMore && !withShowAllTags && (
                <p
                    className={cn({
                        ["link d-flex align-items-center"]: true,
                        [styles.showMore]: true,
                    })}
                    onClick={() => setShowMore(true)}
                >
                    Показать все темы
                </p>
            )}
        </div>
    );
};

TagList.propTypes = {
    className: PropTypes.string,
    url: PropTypes.string,
    cardClassName: PropTypes.string,
    isCards: PropTypes.bool,
    tags: PropTypes.array,
    withShowAllTags: PropTypes.bool,
    handleDeleteTag: PropTypes.func,
    customTagColors: PropTypes.object,
    hideDelete: PropTypes.bool,
    handleClick: PropTypes.func,
};

export default TagList;
