import React from "react";
import styles from "./header.module.sass";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../hooks/use-stores";
import cn from "classnames";
import mobileStyles from "./style-mobile.module.sass";

const HeaderCoinsItem = observer(() => {
    const {appStore, userStore} = useStores();
    return (
        <div
            className={cn({
                [styles.coins]: true,
                [mobileStyles.coins]: appStore.isMobile,
            })}
        >
            <img src={require("../../../assets/images/static/coin.svg")} alt="coin" />
            <p>{userStore.coins}</p>
        </div>
    );
});

export default HeaderCoinsItem;
