import cn from "classnames";

import {ImageBankAPI} from "core/api/image-bank";
import {ADMIN_PANEL_ROUTES} from "entries/admin-panel/core/routes";
import Thumbler from "entries/thumbler/thumbler";
import {MDBCol, MDBRow, MDBTabContent, MDBTabPane} from "mdbreact";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Redirect, Route, Switch} from "react-router-dom";
import {toast} from "react-toastify";

import {HeadTitle} from "views/components/layout/head-title";
import {PageTitle} from "views/components/layout/page";

import {ImagesLibrary} from "views/containers/images-library";
import {ImagesUpload} from "views/containers/images-library/images-upload";
import {TagSectionSelect} from "views/containers/tag-section-select";
import {
    IMAGE_LOGO_STUDENT,
    IMAGE_LOGO_TEACHER,
    IMAGE_PROFILE_HEADERS,
} from "views/containers/tag-section-select/image-enums";

import styles from "./images-manager.module.sass";

export const ImagesManager = (props) => {
    const {t} = useTranslation("images_manager");

    const [images, setImages] = React.useState([]);
    const [tag, setTag] = React.useState(null);

    const [thumblerValue, setThumblerValue] = useState(0);

    React.useEffect(() => {
        getImages(tag).then(void 0);
    }, [tag]);

    const getImages = (tagId) => {
        if (!tagId && props.directTagEnum) {
            switch (props.directTagEnum) {
                case IMAGE_LOGO_STUDENT:
                    return ImageBankAPI.getImagesByTag(107).then((response) =>
                        setImages(response.data?.content),
                    );
                case IMAGE_LOGO_TEACHER:
                    return ImageBankAPI.getImagesByTag(108).then((response) =>
                        setImages(response.data?.content),
                    );

                case IMAGE_PROFILE_HEADERS:
                    return ImageBankAPI.getImagesByTag(110).then((response) =>
                        setImages(response.data?.content),
                    );
            }
        }

        if (tagId) {
            return ImageBankAPI.getImagesByTag(tagId).then((response) => {
                setImages(response.data?.content);
            });
        } else {
            setImages([]);
            return Promise.resolve();
        }
    };

    const handleUpload = ({files}) => {
        let promises = [];
        if (files.length > 0) {
            files.forEach((file) => {
                promises.push(
                    ImageBankAPI.uploadImage(file, file.name).then((response) =>
                        ImageBankAPI.addTagToImage(response.data.id, tag),
                    ),
                );
            });
        }

        return Promise.all(promises)
            .catch((error) => {
                process.env.NODE_ENV === "development" &&
                    console.error("uploadImages error", error);
                toast.error(t("Ошибка при загрузке изображения"));
            })
            .finally(() => {
                return getImages(tag);
            });
    };

    const handleTagSectionChange = (tagId) => {
        if (tagId !== tag) {
            setTag(tagId);
            getImages(tagId).then(void 0);
        }
    };

    const updateImages = () => {
        getImages(tag).then(void 0);
    };

    const loadImagesFromBankByName = (name) => {
        if (name.trim() && name.length >= 3) {
            ImageBankAPI.getImageBankByName(name).then((response) => {
                setImages(response.data?.content);
            });
        } else {
            setImages([]);
        }
    };

    return (
        <div>
            {!props.selectImage && (
                <>
                    <HeadTitle>{t("title")}</HeadTitle>
                    <PageTitle>{t("title")}</PageTitle>
                </>
            )}
            <div
                className={cn({
                    [styles.container]: true,
                })}
            >
                <div
                    className={cn({
                        [styles.library]: true,
                        "w-100": props.directTagEnum,
                    })}
                >
                    <Switch>
                        <Route path={ADMIN_PANEL_ROUTES.IMAGES.UPLOAD}>
                            <ImagesUpload onSubmit={handleUpload} tagSelected={!!tag} />
                        </Route>

                        <ImagesLibrary
                            images={images}
                            canCreate={!props.selectImage && thumblerValue !== 1}
                            canRename
                            canRemove
                            canUpdate
                            showContent={!!tag || thumblerValue === 1 || images.length > 0}
                            onRename={updateImages}
                            onUpdate={updateImages}
                            onRemove={updateImages}
                            onSelect={props.selectImage}
                            directTagEnum={props.directTagEnum}
                            withoutNameTip={props.withoutNameTip}
                        />

                        <Route>
                            <Redirect to={ADMIN_PANEL_ROUTES.IMAGES.ROOT} />
                        </Route>
                    </Switch>
                </div>
                {!props.directTagEnum && (
                    <div
                        className={cn({
                            [styles.column]: true,
                        })}
                    >
                        <p className="title mb-4 text-center">Выбор изображений</p>
                        <MDBRow>
                            <MDBCol>
                                <Thumbler
                                    options={[
                                        {name: "Каталог", value: 0},
                                        {name: "Поиск", value: 1},
                                    ]}
                                    isFullWidth
                                    activeState={thumblerValue}
                                    setActiveState={setThumblerValue}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol>
                                <MDBTabContent activeItem={thumblerValue} className="mb-2">
                                    <MDBTabPane tabId={0} role="tabpanel">
                                        <TagSectionSelect
                                            onTagSectionChange={handleTagSectionChange}
                                        />
                                    </MDBTabPane>
                                    <MDBTabPane tabId={1} role="tabpanel">
                                        <input
                                            className="form-control mt-2"
                                            type="text"
                                            placeholder="Введите название"
                                            onChange={(e) =>
                                                loadImagesFromBankByName(e.target.value)
                                            }
                                        />
                                    </MDBTabPane>
                                </MDBTabContent>
                            </MDBCol>
                        </MDBRow>
                    </div>
                )}
            </div>
        </div>
    );
};

ImagesManager.propTypes = {
    directTagEnum: PropTypes.string,
    selectImage: PropTypes.func,
};
