import PropTypes from "prop-types";
import React from "react";
import {TextBoxWithSymbolSkips} from "entries/constructors/dictation/views/containers/text-box-with-symbol-skips/text-box-with-symbol-skips";

export const TextBoxWithSymbolSkipsContainer = ({
    text,
    skipSymbols,
    editAction,
    deleteAction,
    withBorder,
    isPlayMode,
    onSelectVariant,
    selectedPositions,
    answers,
    isEditMode,
}) => {
    return (
        <TextBoxWithSymbolSkips
            splittedText={text.split(" ")}
            skipSymbols={skipSymbols}
            editAction={editAction}
            deleteAction={deleteAction}
            withBorder={withBorder}
            isPlayMode={isPlayMode}
            onSelectVariant={onSelectVariant}
            selectedPositions={selectedPositions}
            answers={answers}
            isEditMode={isEditMode}
        />
    );
};

TextBoxWithSymbolSkipsContainer.propTypes = {
    skipSymbols: PropTypes.array.isRequired,
    text: PropTypes.string.isRequired,
    answers: PropTypes.any,
    deleteAction: PropTypes.func,
    editAction: PropTypes.func,
    isEditMode: PropTypes.bool,
    isPlayMode: PropTypes.bool,
    selectedPositions: PropTypes.array,
    withBorder: PropTypes.bool,
    onSelectVariant: PropTypes.func,
};
