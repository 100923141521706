import axios from "axios";

import {REQUEST_CONFIG} from "./config";
import {auth} from "../../../../core/utils/auth";

export const Request = (() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const {keycloak} = auth;

    const instance = axios.create({
        baseURL: REQUEST_CONFIG.URL,
        timeout: REQUEST_CONFIG.TIMEOUT,
        cancelToken: source.token,
    });

    instance.interceptors.request.use(
        (config) => {
            if (keycloak.authenticated) {
                config.headers.Authorization = `Bearer ${keycloak.token}`;
            }

            config.headers.Accept = "application/json";
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest.sent) {
                originalRequest.retry = true;

                try {
                    await keycloak.updateToken();
                    originalRequest.headers["Authorization"] = `Bearer ${keycloak.token}`;
                    return instance(originalRequest);
                } catch (e) {
                    console.log("Error with refresh token", e);
                    keycloak.logout();
                }
            }

            return Promise.reject(error);
        },
    );

    Object.defineProperty(instance, "cancel", {
        value: source.cancel,
    });

    return instance;
})();
