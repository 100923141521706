import SchoolClassesSelect from "entries/school-classes-select/school-classes-select";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

const SchoolClassesSelectContainer = ({handleSelect, selectValue}) => {
    const [classes] = useState([
        {value: 1, label: 1},
        {value: 2, label: 2},
        {value: 3, label: 3},
        {value: 4, label: 4},
    ]);
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        if (selectValue && classes) {
            setSelectedValue(classes.find((cl) => cl.value == selectValue));
        }
    }, [selectValue, classes]);

    // useEffect(() => {
    //     setIsLoading(true);
    //
    //     TagUtils.getClasses()
    //         .then((response) => {
    //             let schoolClassesForSave = response.data.map((schoolClass) => {
    //                 return {value: schoolClass.id, label: schoolClass.name};
    //             });
    //             setClasses(schoolClassesForSave);
    //         })
    //         .finally(() => setIsLoading(false));
    // }, []);

    return (
        <SchoolClassesSelect
            classes={classes}
            handleSelect={handleSelect}
            selectedValue={selectedValue}
        />
    );
};

SchoolClassesSelectContainer.propTypes = {
    handleSelect: PropTypes.func,
    selectValue: PropTypes.any,
};

export default SchoolClassesSelectContainer;
