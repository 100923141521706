import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import {OUTLINE_TYPE} from "./config";

import styles from "./styles.module.scss";

export const OutlineType = (props) => {
    const [outlineType, setOutlineType] = React.useState(
        props.defaultOutlineType || OUTLINE_TYPE.SQUARE,
    );

    const handleClick = React.useCallback((event) => {
        const outlineType = event.target.dataset?.outlineType;

        if (outlineType) {
            setOutlineType(outlineType);
        }
    }, []);

    React.useEffect(() => {
        props.onChange && props.onChange(outlineType);
    }, [outlineType]);

    return (
        <div>
            <div>Стиль изображений</div>
            <div onClick={handleClick}>
                <div
                    className={cn({
                        [styles.OutlineType]: true,
                        [styles.OutlineTypeSelected]: outlineType === OUTLINE_TYPE.CIRCLE,
                    })}
                    data-outline-type={OUTLINE_TYPE.CIRCLE}
                >
                    Круглые
                </div>
                <div
                    data-outline-type={OUTLINE_TYPE.SQUARE}
                    className={cn({
                        [styles.OutlineType]: true,
                        [styles.OutlineTypeSelected]: outlineType === OUTLINE_TYPE.SQUARE,
                    })}
                >
                    Квадратные
                </div>
            </div>
        </div>
    );
};

OutlineType.propTypes = {
    defaultOutlineType: PropTypes.oneOf(Object.values(OUTLINE_TYPE)),
    onChange: PropTypes.func,
};
