import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import {ThemesList} from "views/containers/themes-list/themes-list";

export const ThemesListPage = (props) => {
    return <ThemesList />;
};

ThemesListPage.propTypes = {};
