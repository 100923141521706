import {MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle} from "mdbreact";
import React from "react";

const ArticleCreateNewBlock = ({
    handleClickTextItem,
    handelClickImageItem,
    handleClickGalleryItem,
    handleClickPlayerItem,
    handleClickFileItem,
    handleClickSimilarItem,
    handleClickMonogramItem,
    handleClickVideoItem,
}) => {
    return (
        <MDBDropdown>
            <MDBDropdownToggle caret color="primary">
                Новый блок
            </MDBDropdownToggle>
            <MDBDropdownMenu basic>
                <MDBDropdownItem onClick={handleClickTextItem}>Текст</MDBDropdownItem>
                <MDBDropdownItem onClick={handelClickImageItem}>Изображение</MDBDropdownItem>
                <MDBDropdownItem onClick={handleClickGalleryItem}>Фотогалерея</MDBDropdownItem>
                <MDBDropdownItem onClick={handleClickPlayerItem}>Плеер</MDBDropdownItem>
                <MDBDropdownItem onClick={handleClickFileItem}>Файл</MDBDropdownItem>
                <MDBDropdownItem onClick={handleClickSimilarItem}>Похожие статьи</MDBDropdownItem>
                <MDBDropdownItem onClick={handleClickMonogramItem}>Вензель</MDBDropdownItem>
                <MDBDropdownItem onClick={handleClickVideoItem}>Видео</MDBDropdownItem>
            </MDBDropdownMenu>
        </MDBDropdown>
    );
};

export default ArticleCreateNewBlock;
