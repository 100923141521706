import {makeAutoObservable} from "mobx";

export class PlayerStore {
    public isMobileMode: boolean = false;

    //sides
    public navigateSideIsOpen: boolean = false;
    public messagesSideIsOpen: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    public updateIsMobileMode = (width: number) => {
        width <= 985 ? (this.isMobileMode = true) : (this.isMobileMode = false);
    };

    public toggleNavigate = () => {
        this.navigateSideIsOpen = !this.navigateSideIsOpen;
        this.messagesSideIsOpen = false;
    };

    public toggleMessages = () => {
        this.messagesSideIsOpen = !this.messagesSideIsOpen;
        this.navigateSideIsOpen = false;
    };
}
