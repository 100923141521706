import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import {ABOUT_ENGINE_DATA} from "../about/sliders/about-engines/utils/about-engines-data";
import {Helmet} from "react-helmet";

const We = () => {
    const [tabs] = useState(ABOUT_ENGINE_DATA);
    const [activeTab, setActiveTab] = useState(tabs[0]);

    useEffect(() => {
        document.body.style.background = "";
    }, []);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div className={styles.container}>
                <div className={styles.twoSideContainer}>
                    <div className={styles.containerLeft}>
                        <p className="mb-2">
                            «Умная Ворона» – это бесплатная образовательная платформа для решения
                            интерактивных заданий. В нашей Академии более 20000 задач по школьной
                            программе 1-4 классов, чтобы заниматься индивидуально или с учителем.
                            Кроме готовых заданий для учеников, учителям доступна возможность
                            создавать собственные задания с интересным оформлением и механикой. А
                            для тех, кто любит соревноваться, каждую неделю мы проводим
                            интеллектуальные турниры по математике, логике, русскому языку, этикету,
                            чтению и окружающему миру!{" "}
                        </p>
                        <p className="mt-3">
                            {" "}
                            Образовательная платформа «Умная Ворона» включена в реестр российского
                            программного обеспечения
                            <a href="https://reestr.digital.gov.ru/request/1089122/?sphrase_id=2497842">
                                {" "}
                                – запись в реестре №15588 от 18.11.2022{" "}
                            </a>
                            произведена на основании поручения Министерства цифрового развития,
                            связи и массовых коммуникаций Российской Федерации от 18.11.2022 по
                            протоколу заседания экспертного совета от 14.11.2022 №1755пр
                        </p>
                    </div>

                    <div className={styles.containerRight}>
                        <img
                            src={require("../../../assets/sliders/teacher-free/teacher-free.svg")}
                        />
                    </div>
                </div>
                <div>
                    <p className={styles.title}>О компании «Урок»</p>
                    <p className="mt-4">
                        У нас более 10 лет опыта разработки и поддержки веб-сайтов и всего, что с
                        ними связано. Мы не только программируем, но и рисуем, пишем, и раскручиваем
                        веб-сайты. А потом непрерывно заботимся о них и поддерживаем.
                    </p>
                    <p className="mt-3">
                        Ищете надежного партнёра для создания веб-сайта? Будем рады помочь с
                        реализацией: от составления технического задания до праздничной картинки в
                        соцсетях.
                    </p>
                    <p className="mt-3">
                        admin@umnayavorona.ru <br />
                        ООО «УРОК» <br /> ОГРН: 1171215001980 <br /> ИНН: 1215216925
                    </p>
                </div>
            </div>
        </>
    );
};

export default We;
