import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "views/components/constructor-navbar/constructor-navbar-item.module.sass";

export const ConstructorNavbarItem = ({
    isActive,
    isAvailable,
    onSelect,
    tab,
    tabName,
    tabIndex,
    className,
}) => {
    return (
        <span
            className={cn({
                [styles.active]: isActive,
                "disabled text-muted": !isAvailable,
                [className]: !!className,
            })}
            onClick={() => onSelect(tab)}
        >
            {`${++tabIndex}. ${tabName}`}
        </span>
    );
};

ConstructorNavbarItem.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isAvailable: PropTypes.bool.isRequired,
    tab: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    tabName: PropTypes.string,
};
