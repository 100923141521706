import React from "react";
import {Route, Switch} from "react-router-dom";
import {ADMIN_PANEL_ROUTES} from "../core/routes";
import {FOR_ACADEMY} from "../../teacher/utils/teacher-config";
import {CreateTask} from "../../../views/pages/admin/create-task/create-task";
import {ClassicTestManager} from "../../../views/pages/admin/classic-test/classic-test-manager";
import DragAreaManager from "../../../views/pages/admin/drag-area-manager/drag-area-manager";
import {TextSequenceManager} from "../../../views/pages/admin/text-sequence-manager/text-sequence-manager";
import {NumericSequenceManager} from "../../../views/pages/admin/numeric-sequence-manager/numeric-sequence-manager";
import {PairsManager} from "../../../views/pages/admin/pairs-manager/pairs-manager";
import {WordSkipManager} from "../../../views/pages/admin/word-skip-manager";
import {WordSkipAdditionalManager} from "../../../views/pages/admin/word-skip-additional-manager";
import {SentenceSkipManager} from "../../../views/pages/admin/sentence-skip-manager";
import {OpenAnswerManager} from "../../../views/pages/admin/open-answer-manager/open-answer-manager";
import {ClippingManager} from "../../../views/pages/admin/clipping-manager/clipping-manager";
import {ClippingSecondaryManager} from "../../../views/pages/admin/clipping-secondary-test-manager/clipping-secondary-test-manager";
import {ManyToManyDndTestManager} from "../../../views/pages/admin/many-to-many-dnd-test-manager/many-to-many-dnd-test-manager";
import {BasketsGameManager} from "../../../views/pages/admin/baskets-game-manager/baskets-game-manager";

const AdminPanelConstructors = () => {
    return (
        <div
            style={{
                maxWidth: 1008,
                height: "100%",
            }}
        >
            <Switch>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.ROOT} exact={true}>
                    <CreateTask
                        routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS}
                        mode={FOR_ACADEMY}
                    />
                </Route>

                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.CLASSIC_TEST.ROOT}>
                    <ClassicTestManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.DRAG_AREA_TEST.ROOT}>
                    <DragAreaManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.TEXT_SEQUENCE_TEST.ROOT}>
                    <TextSequenceManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.NUMERIC_SEQUENCE_TEST.ROOT}>
                    <NumericSequenceManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.PAIRS_TEST.ROOT}>
                    <PairsManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.WORD_SKIP_TEST.ROOT}>
                    <WordSkipManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.WORD_SKIP_ADDITIONAL_TEST.ROOT}>
                    <WordSkipAdditionalManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.SENTENCE_TEST.ROOT}>
                    <SentenceSkipManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.OPEN_ANSWER_TEST.ROOT}>
                    <OpenAnswerManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.CLIPPING_TEST.ROOT}>
                    <ClippingManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.CLIPPING_SECONDARY_TEST.ROOT}>
                    <ClippingSecondaryManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.MANY_TO_MANY_DND_TEST.ROOT}>
                    <ManyToManyDndTestManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.CONSTRUCTORS.BASKETS_TEST.ROOT}>
                    <BasketsGameManager routerInstance={ADMIN_PANEL_ROUTES.CONSTRUCTORS} />
                </Route>
            </Switch>
        </div>
    );
};

export default AdminPanelConstructors;
