import {PLAYER_RIGHTS} from "entries/player/views/player/utils/player-config";
import {useStores} from "hooks/use-stores";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.sass";
import cn from "classnames";

const PlayerV2Content = ({content, userRights}) => {
    const {playerStore} = useStores();

    return (
        <div
            className={cn({
                [styles.content]: true,
            })}
        >
            {playerStore.isMobileMode && (
                <React.Fragment>
                    {userRights.includes(PLAYER_RIGHTS.CAN_SWIPE_LEFT) && (
                        <div
                            className={styles.leftSlide}
                            onClick={() => playerStore.toggleNavigate()}
                        >
                            {playerStore.navigateSideIsOpen ? (
                                <i className="fas fa-chevron-right" />
                            ) : (
                                <i className="fas fa-chevron-left" />
                            )}
                        </div>
                    )}

                    {userRights.includes(
                        PLAYER_RIGHTS.CAN_SWIPE_RIGHT && PLAYER_RIGHTS.HAVE_INFO_BLOCK,
                    ) && (
                        <div
                            className={styles.rightSlide}
                            onClick={() => playerStore.toggleMessages()}
                        >
                            {playerStore.messagesSideIsOpen ? (
                                <i className="fas fa-chevron-left" />
                            ) : (
                                <i className="fas fa-chevron-right" />
                            )}
                        </div>
                    )}
                </React.Fragment>
            )}
            {content}
        </div>
    );
};

PlayerV2Content.propTypes = {
    content: PropTypes.object,
};

export default PlayerV2Content;
