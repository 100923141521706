import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import cn from "classnames";
import {EditButtons} from "../../../../views/components/edit-buttons";
import {EPublicationCustomActionTypes} from "../../../../enums/EPublicationCustomActionTypes";
import {IPublicationGalleryImage} from "../../../../interfaces/IPublicationGalleryImage";
import {url} from "../../../../config";
// @ts-ignore
import ImageGallery from "react-image-gallery";
import {IPublicationGalleryPayload} from "../../article-modals/new-article-gallery-modal/new-article-gallery-modal";
import {TextUploadModal} from "../../../../views/components/text-upload-modal/text-upload-modal";
import ArticleTitle from "../../article-title/article-title";

interface IProps {
    block: any;
    isViewMode: boolean;
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
    handleCustomAction: (type: EPublicationCustomActionTypes, payload: any) => void;
}

const ArticleGalleryBlock = ({block, isViewMode, handleDelete, handleCustomAction}: IProps) => {
    const [textModalIsOpen, setTextModalIsOpen] = useState(false);
    const [itemForEdit, setItemForEdit] = useState<IPublicationGalleryImage | null>(null);
    const [imagesForGallery, setImagesForGallery] = useState([]);

    const [, setSettings] = useState<IPublicationGalleryPayload | null>(null);

    useEffect(() => {
        if (block) {
            let tempSettings = JSON.parse(atob(block.blockSettings));
            tempSettings.description = decodeURI(tempSettings.description);
            setSettings(tempSettings);
        }
    }, [block]);

    useEffect(() => {
        if (!textModalIsOpen) {
            setItemForEdit(null);
        }
    }, [textModalIsOpen]);

    useEffect(() => {
        let images = block.galleryImages.map((image: IPublicationGalleryImage) => {
            return {
                original: url + image.imageBlock.fileDownloadUri.slice(1),
                thumbnail: url + image.imageBlock.fileDownloadUri.slice(1),
                description: image.imageBlock.name,
            };
        });

        setImagesForGallery(images);
    }, [block.galleryImages]);

    let saveImages = (rawImages: any) => {
        let files = [...rawImages.target.files];
        handleCustomAction(EPublicationCustomActionTypes.GALLERY_SAVE_IMAGES, {
            images: files,
            blockId: block.id,
        });
    };

    let changeImageName = (name: string) => {
        handleCustomAction(EPublicationCustomActionTypes.GALLERY_CHANGE_NAME, {
            name,
            blockId: block.id,
            imageId: itemForEdit?.imageBlock.id,
        });
        setItemForEdit(null);
        setTextModalIsOpen(false);
    };

    return (
        <div
            className={cn({
                [styles.galleryBlock]: true,
                [styles.editMode]: !isViewMode,
            })}
        >
            {textModalIsOpen && (
                <TextUploadModal
                    saveText={changeImageName}
                    visible={textModalIsOpen}
                    toggle={() => setTextModalIsOpen(!textModalIsOpen)}
                    text={itemForEdit?.imageBlock.name}
                />
            )}

            <ArticleTitle
                isViewMode={isViewMode}
                handleCustomAction={handleCustomAction}
                block={block}
                title={block.description}
            />

            {!isViewMode ? (
                <React.Fragment>
                    {block?.galleryImages.map((img: IPublicationGalleryImage) => {
                        return (
                            <div key={img.id} className={styles.galleryContent}>
                                <img
                                    src={url + img.imageBlock.fileDownloadUri.slice(1)}
                                    alt="article-image"
                                />
                                <div>
                                    <p className={styles.imageName}>
                                        {img.imageBlock.name ? img.imageBlock.name : "Без названия"}
                                        <i
                                            className="fas fa-pen"
                                            onClick={() => {
                                                setItemForEdit(img);
                                                setTextModalIsOpen(true);
                                            }}
                                        />
                                    </p>
                                </div>

                                <EditButtons
                                    deleteAction={() =>
                                        handleCustomAction(
                                            EPublicationCustomActionTypes.GALLERY_DELETE_IMAGE,
                                            {blockId: block.id, imageId: img.id},
                                        )
                                    }
                                    isSolidBorder
                                />
                            </div>
                        );
                    })}

                    <form>
                        <input type="file" multiple onChange={saveImages} />
                    </form>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <ImageGallery
                        items={imagesForGallery}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                    />
                </React.Fragment>
            )}

            {!isViewMode && (
                <EditButtons
                    // editAction={() => handleEdit(block.id)}
                    deleteAction={() => handleDelete(block.id)}
                    isSolidBorder
                    itemId={block.id}
                />
            )}
        </div>
    );
};

export default ArticleGalleryBlock;
