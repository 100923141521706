import cn from "classnames";

import styles from "entries/constructors/classic-test/views/components/text-answer/text.module.sass";
import PropTypes from "prop-types";
import React from "react";

export const Text = ({text, withBorder = false, style}) => {
    return (
        <div
            style={style}
            className={cn({
                [styles.content]: true,
                [styles.border]: withBorder,
                noselect: true,
            })}
        >
            <p>{text}</p>
        </div>
    );
};

Text.propTypes = {
    text: PropTypes.string.isRequired,
    style: PropTypes.any,
    withBorder: PropTypes.bool,
};
