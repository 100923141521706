import {url} from "config";
import {DRAGGING_TEST} from "config/constructor-names";
import {AddQuestionButtons} from "entries/constructors/classic-test/views/components/add-question-buttons";
import {NARRATORS} from "entries/constructors/classic-test/views/components/properties-buttons";
import {CreateQuestion} from "entries/constructors/classic-test/views/containers/create-question";
import {TestParameters} from "entries/constructors/classic-test/views/containers/test-parameters";
import {useConstructor} from "entries/constructors/hooks/useConstructor";
import {ITEMS_TYPE} from "entries/drag-area/config";
import {DraggingAPI} from "entries/drag-area/core/api/DraggingAPI";
import {DragArea} from "entries/drag-area/DragArea";
import {MDBAlert} from "mdbreact";
import React, {useEffect, useState} from "react";
import {ConstructorNavbar} from "views/components/constructor-navbar";
import {Col} from "views/components/layout/col";
import {SaveContent} from "views/components/layout/save-content/save-content";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {TestOptions} from "views/components/layout/test-options";
import {PropertyColumn} from "views/containers/property-column";
import TestSave from "views/containers/test-save/test-save";
import {useWizard} from "views2/conatiners/CreateTestWizard";

const DragAreaFunctional = ({testData, isEditMode}) => {
    const {
        testId,
        questionText,
        setQuestionText,
        questionImage,
        setQuestionImage,
        background,
        setBackground,
        narrator,
        setNarrator,
        imageSize,
        setImageSize,
        textTitleSize,
        setTextTitleSize,
        textAnswerSize,
        setTextAnswerSize,
        isRoundedAnswer,
        setIsRoundedAnswer,
        currentPropertyTab,
        setCurrentPropertyTab,
        isTwoColumnMode,
        setIsTwoColumnMode,
        tabNames,
        questionType,
        questionAudio,
        setQuestionAudio,
        answerType,
        setAnswerType,
    } = useConstructor({
        isEditMode,
        constructorName: DRAGGING_TEST,
        testData,
    });

    const {STEP, stepsOrder, currentStep, toNext, to, enabledSteps, enableStep} = useWizard();
    const [values, setValues] = useState(null);

    return (
        <div>
            <ConstructorNavbar
                tabs={stepsOrder}
                currentTab={currentStep}
                availableTabs={enabledSteps}
                onSelect={(tab) => to(tab)}
                tabNames={tabNames}
            />

            <Col size={12}>
                {currentStep === STEP.QUESTION || currentStep === STEP.ANSWER ? (
                    <Col size={8} className="d-flex justify-content-start">
                        <TestContent
                            background={background}
                            withEditBorder={currentStep === STEP.QUESTION}
                        >
                            <TestConstructor size="full">
                                <CreateQuestion
                                    apiInstance={DraggingAPI}
                                    questionId={testId}
                                    questionTitle={testData.title}
                                    questionImage={questionImage}
                                    questionText={questionText}
                                    questionAudio={questionAudio}
                                    updateQuestionImage={(image) => setQuestionImage(image)}
                                    updateQuestionText={(text) => setQuestionText(text)}
                                    updateQuestionAudio={(audio) => setQuestionAudio(audio)}
                                    selectPropertyNarrator={() => setCurrentPropertyTab(NARRATORS)}
                                    narrator={narrator}
                                    isEditMode={currentStep === STEP.QUESTION}
                                    testHasNarrator
                                    textTitleSize={textTitleSize}
                                    withoutBottomMargin={true}
                                    withEditButtons={false}
                                />

                                <DragArea
                                    itemsType={answerType}
                                    handleSave={setValues}
                                    enableNextStep={(step) => enableStep(step)}
                                />

                                {!answerType && (
                                    <div>
                                        <p className="text-center">
                                            Выберите объект для перетаскивания
                                        </p>
                                        <AddQuestionButtons
                                            imageVisible
                                            textVisible
                                            textAction={() => setAnswerType(ITEMS_TYPE.TEXT)}
                                            imageAction={() => setAnswerType(ITEMS_TYPE.IMAGE)}
                                        />
                                    </div>
                                )}
                            </TestConstructor>
                        </TestContent>
                    </Col>
                ) : (
                    <TestParameters
                        apiInstance={DraggingAPI}
                        testId={testId}
                        mode={questionType}
                        isEditMode={isEditMode}
                    />
                )}
                {(currentStep === STEP.QUESTION || currentStep === STEP.ANSWER) && (
                    <Col size={4}>
                        <TestOptions>
                            {currentStep === STEP.QUESTION && (
                                <PropertyColumn
                                    selectImage={(background) => setBackground(background)}
                                    selectNarrator={(narrator) =>
                                        setNarrator({
                                            id: narrator.id,
                                            url: `${url}${narrator.url}`,
                                        })
                                    }
                                    background={background}
                                    textTitleSize={textTitleSize}
                                    textAnswerSize={textAnswerSize}
                                    selectImageSize={(size) => setImageSize(size)}
                                    setTextTitleSize={(size) =>
                                        setTextTitleSize(Number.parseInt(size))
                                    }
                                    setTextAnswerSize={(size) =>
                                        setTextAnswerSize(Number.parseInt(size))
                                    }
                                    currentPropertyTab={currentPropertyTab}
                                    onSelectTab={(tab) => setCurrentPropertyTab(tab)}
                                    currentImageSize={imageSize}
                                    isQuestionTextExist={!!questionText}
                                    isTwoColumnMode={isTwoColumnMode}
                                    toggleTwoColumnMode={() => setIsTwoColumnMode(!isTwoColumnMode)}
                                    isTwoColumnModeAvailable
                                    narrator={narrator}
                                    isRoundedAnswer={isRoundedAnswer}
                                    setIsRoundedAnswer={setIsRoundedAnswer}
                                />
                            )}
                            {currentStep === STEP.ANSWER && (
                                <SaveContent>
                                    <h5>Укажите правильный ответ</h5>
                                    <p>Решите задачу правильно, а затем сохраните ответ</p>
                                    <TestSave
                                        questionId={testId}
                                        backgroundId={background.id}
                                        sizeImage={imageSize}
                                        sizeText={textAnswerSize}
                                        sizeTitle={textTitleSize}
                                        narratorId={narrator?.id}
                                        apiInstance={DraggingAPI}
                                        constructorName={DRAGGING_TEST}
                                        onSaveProperties={() => {
                                            toNext();
                                        }}
                                        mode={questionType}
                                        answersForSave={values}
                                    />

                                    <MDBAlert color="info" className="mt-5">
                                        <p>
                                            <i className="fa fa-info-circle" aria-hidden="true" /> В
                                            этом виде задания вы можете указать один или несколько
                                            правильных ответов.
                                        </p>
                                    </MDBAlert>
                                </SaveContent>
                            )}
                        </TestOptions>
                    </Col>
                )}
            </Col>
        </div>
    );
};

DragAreaFunctional.propTypes = {};

export default DragAreaFunctional;
