import React from "react";
import ReactDOM from "react-dom";
import {App} from "./views/app";
import "./core/utils/i18n";
import * as serviceWorker from "./serviceWorker";
import "./assets/fonts/opensans.css";
import "antd/dist/reset.css";
import "./styles/styles.sass";

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
