import { Request } from "../utils/request";

export const SuperAdminAPI = {
    getUserRole: (id: number) => {
        return Request.get(`/super-admin/user/${id}/role`)
    },
    getStudentsInClassByTeacher: (teacherId: number) => {
        return Request.get(`/super-admin/teacher/${teacherId}/students-in-class`)
    },
    getStudentsAcceptedByTeacher: (teacherId: number) => {
        return Request.get(`/super-admin/teacher/${teacherId}/student-accepted`)
    },
}
