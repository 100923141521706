import {url} from "config";
import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";

const PlayerV2NotificationLoading = () => {
    const [isHelperOpen, setIsHelperOpen] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsHelperOpen(true);
        }, 10000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <div className={styles.loading}>
            <div className={styles.ravenWrapper}>
                <img
                    src={url + "data/image-bank/36ffef60-d40e-4911-b148-f2f3588d091e.png"}
                    className={styles.lines}
                    alt="lines"
                />
                <img
                    src={url + "data/image-bank/912a0ba5-64c7-4879-bf54-a06c5cb8671e.svg"}
                    className={styles.stones}
                    alt="stones"
                />
                <img
                    src={url + "data/image-bank/60f4d610-a8be-49db-a03a-a2341daca833.png"}
                    className={styles.core}
                    alt="core"
                />
                <img
                    src={url + "data/image-bank/589a8063-473d-4503-97e5-8342d65e2cfc.svg"}
                    className={styles.raven}
                    alt="raven"
                />
            </div>

            {isHelperOpen && (
                <p className={styles.loadingHelper}>
                    Если страница не грузится, пожалуйста, нажмите F5 и обновите её. Если ошибка
                    повторяется, просим сообщить в техподдержку – support@umnayavorona.ru
                </p>
            )}
        </div>
    );
};

export default PlayerV2NotificationLoading;
