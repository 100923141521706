import {SelectSkipBoxContainer} from "entries/constructors/dictation/views/containers/select-skip-box/select-skip-box-container";
import {SelectSymbolBoxContainer} from "entries/constructors/dictation/views/containers/select-symbol-box/select-symbol-box-container";
import React from "react";
import {SelectWordBoxContainer} from "entries/constructors/dictation/views/containers/select-word-box/select-word-box-container";
import {MDBBtn, MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import PropTypes from "prop-types";
import styles from "entries/constructors/dictation/views/containers/modal-dictation-skips/modal-dictation-skips.module.sass";

export const ModalDictationSkips = ({
    isOpen,
    toggle,
    selectedWordIndex,
    setSelectedWordIndex,
    separatedText,
    selectedSymbolIndex,
    setSelectedSymbolIndex,
    skipVariants,
    handleSaveVariant,
    skips,
    handleDeleteSkip,
    setTextWithSkips,
    textWithSkips,
    handleSaveSkip,
}) => {
    return (
        <MDBModal isOpen={isOpen} toggle={toggle} size="lg">
            <MDBModalHeader toggle={toggle} className="modal-header">
                <p>Укажите пропуски в тексте</p>
            </MDBModalHeader>
            <MDBModalBody>
                <div className={styles.container}>
                    <div className={styles.leftWrapper}>
                        <SelectWordBoxContainer
                            separatedText={separatedText}
                            selectedWordIndex={selectedWordIndex}
                            setSelectedWordIndex={setSelectedWordIndex}
                            skips={skips}
                        />

                        <div className={styles.footer}>
                            <MDBBtn
                                size="md"
                                color="success"
                                disabled={skips.length === 0}
                                onClick={handleSaveSkip}
                            >
                                Сохранить пропуски
                            </MDBBtn>
                        </div>
                    </div>
                    <div className={styles.rightWrapper}>
                        <div className={styles.descriptionWrapper}>
                            {selectedWordIndex !== null ? (
                                <div>
                                    <div className={styles.descriptionContainer}>
                                        <SelectSymbolBoxContainer
                                            word={separatedText[selectedWordIndex]}
                                            selectedSymbolIndex={selectedSymbolIndex}
                                            selectedWordIndex={selectedWordIndex}
                                            setSelectedSymbolIndex={setSelectedSymbolIndex}
                                            skips={skips}
                                        />
                                    </div>

                                    {selectedSymbolIndex !== null && (
                                        <div className={styles.descriptionContainer}>
                                            <SelectSkipBoxContainer
                                                skipVariants={skipVariants}
                                                word={separatedText[selectedWordIndex]}
                                                selectedSymbolIndex={selectedSymbolIndex}
                                                selectedWordIndex={selectedWordIndex}
                                                handleSaveVariant={handleSaveVariant}
                                                skips={skips}
                                                handleDeleteSkip={handleDeleteSkip}
                                                setTextWithSkips={setTextWithSkips}
                                                textWithSkips={textWithSkips}
                                            />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div
                                    className={`${styles.description} ${styles.descriptionContainer}`}
                                >
                                    <p className={styles.title}>Как создать пропуск?</p>
                                    <p className={styles.textInfo}>
                                        Кликайте на слова в тексте, чтобы сделать «пропуск».
                                    </p>
                                    <p className={styles.textInfo}>
                                        Кликайте ещё раз, чтобы отменить постановку «пропуска».
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </MDBModalBody>
        </MDBModal>
    );
};

ModalDictationSkips.propTypes = {
    handleDeleteSkip: PropTypes.func,
    handleSaveSkip: PropTypes.func,
    handleSaveVariant: PropTypes.func,
    isOpen: PropTypes.bool,
    selectedSymbolIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    selectedWordIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    separatedText: PropTypes.array,
    setSelectedSymbolIndex: PropTypes.func,
    setSelectedWordIndex: PropTypes.func,
    setTextWithSkips: PropTypes.func,
    skips: PropTypes.array,
    skipVariants: PropTypes.array,
    textWithSkips: PropTypes.string,
    toggle: PropTypes.func,
};
