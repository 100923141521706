import React from "react";
import {MDBBadge, MDBNavItem, MDBNavLink} from "mdbreact";
import cn from "classnames";
import styles from "./header.module.sass";
import {url} from "../../../config";
import noLogo from "assets/images/static/no-logo.svg";
import {useStores} from "../../../hooks/use-stores";
import {observer} from "mobx-react-lite";
import mobileStyles from "./style-mobile.module.sass";

interface IProps {
    userAccountPath: string;
}

const HeaderAvatarItem = observer(({userAccountPath}: IProps) => {
    const {notificationStore, userStore, appStore} = useStores();
    return (
        <MDBNavItem>
            <MDBNavLink
                to={userAccountPath}
                className={cn({
                    [styles.avatar]: true,
                    [mobileStyles.avatar]: appStore.isMobile,
                })}
            >
                {userStore.user?.avatarImageBank ? (
                    <img
                        src={url + userStore.user.avatarImageBank.fileDownloadUri.slice(1)}
                        alt="avatar"
                    />
                ) : (
                    <img src={noLogo} alt="avatar" />
                )}

                {!!notificationStore.unreadMessageCount && (
                    <MDBBadge
                        color="info"
                        className={cn({
                            [mobileStyles.badge]: appStore.isMobile,
                        })}
                    >
                        {notificationStore.unreadMessageCount}
                    </MDBBadge>
                )}
            </MDBNavLink>
        </MDBNavItem>
    );
});

export default HeaderAvatarItem;
