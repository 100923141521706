import {IMAGE_FROM_UPLOAD_ANSWER_CLASSIC} from "entries/constructors/classic-test/utils/answer-types";
import {IMAGE_FROM_UPLOAD_ANSWER_PAIRS} from "entries/constructors/pairs-test/core/types/pairs-test-types";
import {MDBAlert, MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

import ImageUploader from "react-images-upload";
import {PROPORTION_1TO1, ProportionButtons} from "views/components/proportion-buttons";
import {CropImage} from "views/containers/crop-image";

import styles from "./image-upload.module.sass";

export const ImageUpload = ({
    uploadHandler,
    image,
    setPicture,
    withCrop,
    testType,
    svgDisabled = false,
    availableProportions,
}) => {
    const [proportion, setProportion] = useState(PROPORTION_1TO1);
    const [area, setArea] = useState(null);
    const [imageName, setImageName] = useState("");
    const [imgExtensions, setImgExtensions] = useState([".jpg", ".png", ".svg"]);

    useEffect(() => {
        if (imgExtensions.includes(".svg") && svgDisabled) {
            let imgExtensionsClone = imgExtensions;
            imgExtensionsClone = imgExtensionsClone.filter((ext) => ext !== ".svg");
            if (imgExtensionsClone.length > 0) {
                setImgExtensions(imgExtensionsClone);
            }
        }
    }, [svgDisabled]);

    const onSelectAreaForCrop = (area) => {
        setArea(area);
    };

    const onChangeHandler = (picture) => {
        if (picture.length > 0) {
            setPicture(picture);
        }
    };

    const resetImage = () => {
        setImageName("");
        setPicture(null);
    };

    const onUploadImage = () => {
        if (withCrop) {
            uploadHandler({
                image: image[0],
                name: imageName,
                areaForCrop: area,
                type:
                    testType === "Pairs"
                        ? IMAGE_FROM_UPLOAD_ANSWER_PAIRS
                        : IMAGE_FROM_UPLOAD_ANSWER_CLASSIC,
                proportion: proportion,
            });
        } else {
            let img = new Image();
            img.src = URL.createObjectURL(image[0]);
            img.onload = function () {
                uploadHandler({
                    image: image[0],
                    name: imageName,
                    type:
                        testType === "Pairs"
                            ? IMAGE_FROM_UPLOAD_ANSWER_PAIRS
                            : IMAGE_FROM_UPLOAD_ANSWER_CLASSIC,
                    params: {
                        width: img.width,
                        height: img.height,
                    },
                });
            };
        }
    };

    return (
        <div className={styles.container}>
            {!image && (
                <div>
                    <ImageUploader
                        withIcon
                        onChange={onChangeHandler}
                        imgExtension={imgExtensions}
                        maxFileSize={5242880}
                        singleImage
                        withLabel={false}
                        buttonText="Выбрать изображение"
                    />
                </div>
            )}

            {image && withCrop && (
                <div className={styles.uploadWrapper}>
                    <CropImage
                        image={image[0]}
                        proportion={proportion}
                        uploadHandler={uploadHandler}
                        onSelectAreaForCrop={onSelectAreaForCrop}
                    />
                    <div className={styles.optionsWrapper}>
                        <div className="form-outline mb-2">
                            <p className={styles.title}>Введите название картинки</p>
                            <input
                                type="text"
                                className="form-control"
                                value={imageName}
                                onChange={(e) => setImageName(e.target.value)}
                            />
                        </div>

                        <p className={styles.title}>Пропорции</p>
                        <ProportionButtons
                            handleSelect={(proportion) => setProportion(proportion)}
                            availableProportions={availableProportions}
                            selected={proportion}
                        />

                        <MDBAlert color="info" className="mt-3">
                            <i className="fa fa-info-circle" aria-hidden="true" />
                            Для изменения масштаба крутите колесико мыши в окне изображения
                        </MDBAlert>

                        <div className={styles.optionsFooter}>
                            <MDBBtn color="success" onClick={onUploadImage} disabled={!image[0]}>
                                Сохранить
                            </MDBBtn>
                            <MDBBtn color="warning" onClick={resetImage}>
                                Выбрать другую
                            </MDBBtn>
                        </div>
                    </div>
                </div>
            )}

            {image && !withCrop && (
                <div>
                    <div className="form-outline">
                        <p className="form-label text-info font-weight-bold">Введите название</p>
                        <input
                            type="text"
                            className="form-control"
                            value={imageName}
                            onChange={(e) => setImageName(e.target.value)}
                        />
                    </div>
                    <div className={styles.imageWrapper}>
                        <img
                            className={styles.preview}
                            src={window.URL.createObjectURL(image[0])}
                            alt="PreviewImage"
                        />
                    </div>
                    <div className={styles.buttonWrapper}>
                        <button className="btn btn-md btn-danger" onClick={resetImage}>
                            Удалить
                        </button>
                        <button
                            onClick={onUploadImage}
                            className="btn btn-md btn-success"
                            disabled={!image[0]}
                        >
                            Сохранить
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

ImageUpload.propTypes = {
    availableProportions: PropTypes.array,
    image: PropTypes.any,
    setPicture: PropTypes.func,
    svgDisabled: PropTypes.bool,
    tag: PropTypes.array,
    testType: PropTypes.string,
    uploadHandler: PropTypes.func,
    withCrop: PropTypes.bool,
};
