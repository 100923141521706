import {ADMIN_ROLE, STUDENT_ROLE, TEACHER_ROLE} from "config/roles";
import {getRole} from "core/utils/helpers";
import {ADMIN_ROUTES} from "routes/admin";
import {ACADEMY_ROUTES, STUDENT_ROUTES, TEACHER_ROUTES, TOURNAMENT_ROUTES} from "routes/main";
import {PAGES_ROUTES} from "views/routes/routes";

const TEACHER_TABS = [
    {name: "Онлайн-класс", path: TEACHER_ROUTES.TRAINING.STUDENTS.HOMEWORK.MY},
    {name: "Академия", path: ACADEMY_ROUTES.ROOT},
    {name: "Турниры", path: TOURNAMENT_ROUTES.ROOT},
    // {name: "Магазин", path: TEACHER_ROUTES.SHOP.ROOT},
    {name: "Блог", path: PAGES_ROUTES.PUBLICATIONS.ROOT},
];

const STUDENT_TABS = [
    {name: "Академия", path: ACADEMY_ROUTES.ROOT},
    {name: "Турниры", path: TOURNAMENT_ROUTES.TOURNAMENTS},
    {name: "Задания", path: STUDENT_ROUTES.HOMEWORK.ROOT},
    {name: "Мой класс", path: STUDENT_ROUTES.MY_CLASS.ROOT},
    {name: "Блог", path: PAGES_ROUTES.PUBLICATIONS.ROOT},
    {name: "Подписка", path: PAGES_ROUTES.PREMIUM.ROOT},
];

const ADMIN_TABS = [
    {name: "Академия", path: ACADEMY_ROUTES.ROOT},
    {name: "Турниры", path: TOURNAMENT_ROUTES.TOURNAMENTS},
    {name: "Админка", path: ADMIN_ROUTES.ROOT},
    {name: "Админка 2", path: ADMIN_ROUTES.NEW},
    {name: "Блог", path: PAGES_ROUTES.PUBLICATIONS.ROOT},
];

export const PUBLIC_TABS = [
    {name: "Академия", path: PAGES_ROUTES.ACADEMY_ABOUT},
    {name: "Турниры", path: PAGES_ROUTES.TOURNAMENT_ABOUT},
    {name: "Учителю", path: PAGES_ROUTES.TEACHER_ABOUT},
    {name: "Блог", path: PAGES_ROUTES.PUBLICATIONS.ROOT},
];

export const PUBLIC_TABS_MOBILE = [
    {name: "Академия", path: PAGES_ROUTES.MOBILE.ACADEMY},
    {name: "Турниры", path: PAGES_ROUTES.MOBILE.TOURNAMENT},
    {name: "Учителю", path: PAGES_ROUTES.MOBILE.TEACHER},
    {name: "Блог", path: PAGES_ROUTES.PUBLICATIONS.ROOT},
];

export const getHeaderTabs = (keycloak) => {
    if (!keycloak) {
        console.log("Need keycloak for get tabs");
        return;
    }

    let role = getRole(keycloak);
    switch (role) {
        case STUDENT_ROLE:
            return STUDENT_TABS;
        case TEACHER_ROLE:
            return TEACHER_TABS;
        case ADMIN_ROLE:
            return ADMIN_TABS;
        default:
            return [];
    }
};
