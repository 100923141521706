import React from "react";
import PropTypes from "prop-types";

export const CKEdit = () => {
    // let onSave = () => {
    //     if (svgUri) {
    //         saveFormula(svgUri[0].src);
    //     }
    // };
    //
    // useEffect(() => {
    //     const formulaEl = [...document.getElementsByClassName("Wirisformula")];
    //     setSvgUri(formulaEl);
    // }, [data]);
    return (
        <div>
            {/*<CKEditor*/}
            {/*    editor={ClassicEditor}*/}
            {/*    config={{*/}
            {/*        toolbar: {*/}
            {/*            items: ["MathType", "ChemType"],*/}
            {/*        },*/}
            {/*    }}*/}
            {/*    data=""*/}
            {/*    onChange={(event, editor) => {*/}
            {/*        const data = editor.getData();*/}
            {/*        setData(data);*/}
            {/*    }}*/}
            {/*/>*/}
            {/*<div>*/}
            {/*    {svgUri && svgUri.length > 0 && (*/}
            {/*        <div>*/}
            {/*            <button className="btn btn-success btn-sm" onClick={() => onSave()}>*/}
            {/*                Загрузить формулу*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}
        </div>
    );
};

CKEdit.propTypes = {
    saveFormula: PropTypes.func,
};
