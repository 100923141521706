import React from "react";
import cn from "classnames";
import {HeaderItem} from "../header-item/header-item";
import styles from "./styles.module.sass";

type HeaderProps = {
    items: {label: string; value: string}[];
    className?: string;
    activeItem: string;
    onSetActiveItem: (value: string) => void;
};

export const Header = ({items, className = "", activeItem, onSetActiveItem}: HeaderProps) => {
    return (
        <div
            className={cn({
                [styles.header]: true,
                [className]: className,
            })}
        >
            {items.map((item) => (
                <HeaderItem
                    key={item.value}
                    item={item}
                    isActive={activeItem === item.value}
                    onClick={onSetActiveItem}
                />
            ))}
        </div>
    );
};
