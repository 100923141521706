import React, {useEffect} from "react";
import {useStores} from "../../hooks/use-stores";
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {ROUTE_PATHS} from "../../routes/route-paths";
import WelcomeModal from "../../entries/modals/welcome-modal/welcome-modal";
import {UserAPI} from "../../core/api/user";
import {Roles} from "../../enums/roles";
import UpdateClassModal from "../../entries/modals/update-class-modal/update-class-modal";
import TeacherFeedBackModal from "../../entries/teacher-feedback-modal/teacher-feedback-modal";
import {runInAction} from "mobx";
import {INews} from "../../interfaces/INews";
import {RatingsAPI} from "../../entries/ratings/ratings-api";
import GameTutorialModal from "../../entries/modals/game-tutorial-modal/game-tutorial-modal";
import GameTutorialMobileModal from "../../entries/modals/game-tutorial-modal/mobile/game-tutorial-mobile-modal";

const ServiceUser = observer(() => {
    const {userStore, appStore, notificationStore, newsStore} = useStores();
    const history = useHistory();

    useEffect(() => {
        if (appStore.isAuthorized && !userStore.user) {
            history.push(ROUTE_PATHS.PUBLIC.REGISTRATION);
        }
    }, [appStore.isAuthorized, userStore.user]);

    useEffect(() => {
        if (!userStore.user) {
            return;
        }

        userStore.getCoins();
        checkFirstEnterOnSite();
        checkClassChange();
        checkDailyCount();
        checkGameTutorial();
        getUnreadMessageCount();
        getEventLog();
        getNews();
        getPersonalAcademyRating();
    }, [userStore.user]);

    const getUserSettings = () => {
        if (!userStore.user) {
            return null;
        }

        if (userStore.user.settings) {
            return JSON.parse(atob(userStore.user.settings));
        } else {
            return {};
        }
    };

    const checkFirstEnterOnSite = () => {
        if (userStore.role !== Roles.TEACHER) {
            return;
        }

        let settings = getUserSettings();
        if (!settings?.isFirstEnter) {
            appStore.addModalToQueue(
                <WelcomeModal
                    isOpen={true}
                    updateFirstEnter={updateFirstEnterOnSite}
                    key={"first-enter-modal"}
                />,
            );
        }
    };

    const checkGameTutorial = () => {
        if (userStore.role !== Roles.STUDENT) {
            return;
        }

        let settings = getUserSettings();
        if (!settings?.gameTutorial) {
            appStore.addModalToQueue(
                appStore.isMobile ? (
                    <GameTutorialMobileModal
                        updateGameTutorialSetting={updateGameTutorialSetting}
                        key={"game-tutorial-mobile-modal"}
                    />
                ) : (
                    <GameTutorialModal
                        updateGameTutorialSetting={updateGameTutorialSetting}
                        key={"game-tutorial-modal"}
                    />
                ),
            );
        }
    };

    const updateSettings = (field: string, value: any) => {
        let settings = getUserSettings();
        let settingsForSave = {[field]: value, ...settings};
        let convertedSettings = btoa(JSON.stringify(settingsForSave));
        UserAPI.updateSettings({settings: convertedSettings});
    };

    const updateFirstEnterOnSite = () => {
        appStore.closeActiveModal();
        updateSettings("isFirstEnter", true);
    };

    const updateGameTutorialSetting = () => {
        updateSettings("gameTutorial", true);
        appStore.closeActiveModal();
    };

    const updateSendFeedbackByTeacher = () => {
        appStore.closeActiveModal();
        updateSettings("teacherFeedback", true);
    };

    const checkSendFeedbackByTeacher = () => {
        let settings = getUserSettings();
        if (!settings?.teacherFeedback) {
            appStore.addModalToQueue(
                <TeacherFeedBackModal
                    toggle={appStore.closeActiveModal}
                    updateSettings={updateSendFeedbackByTeacher}
                    key={"feedback-teacher-modal toggle-disabled"}
                />,
            );
        }
    };

    const checkClassChange = () => {
        if (!userStore.user || userStore.role !== Roles.STUDENT) {
            return;
        }

        let dateNow = new Date();
        let septemberDate = new Date(dateNow.getFullYear(), 8, 1);
        let mayDate = new Date(dateNow.getFullYear(), dateNow.getMonth() + 9, 1);
        let nextUpdateDate;
        if (userStore.user.updatedClassTag) {
            let updatedClassTagDate = new Date(userStore.user.updatedClassTag);
            nextUpdateDate = new Date(
                updatedClassTagDate.getFullYear(),
                updatedClassTagDate.getMonth() + 9,
                1,
            );
        }

        if (
            dateNow >= septemberDate &&
            dateNow <= mayDate &&
            ((nextUpdateDate && dateNow >= nextUpdateDate) || !userStore.user.updatedClassTag)
        ) {
            appStore.addModalToQueue(
                <UpdateClassModal
                    isActive={true}
                    toggle={appStore.closeActiveModal}
                    user={userStore.user}
                    updateUser={() => userStore.getUser()}
                    key={"update-class-modal"}
                />,
            );
        }
    };

    const checkDailyCount = async () => {
        await dailyCount();

        if (userStore.role !== Roles.TEACHER) {
            return;
        }

        getDailyCount().then((response: any) => {
            if (response.data > 1) {
                checkSendFeedbackByTeacher();
            }
        });
    };

    const dailyCount = () => {
        return UserAPI.dailyCount();
    };

    const getDailyCount = () => {
        return UserAPI.getDailyCount();
    };

    const getUnreadMessageCount = () => {
        let userId = userStore.user?.userDto?.id;
        if (!userId) {
            return 0;
        }

        notificationStore.updateMessageCount(userId);
    };

    const getPersonalAcademyRating = () => {
        let userId = userStore.user?.userDto?.id;
        if (userStore.role !== Roles.STUDENT) {
            return;
        }

        if (!userId) {
            return 0;
        }

        RatingsAPI.getUserAcademyRating(userId).then((res) => {
            runInAction(() => {
                userStore.academyPlace = res.data.place || 0;
            });
        });
    };

    const getEventLog = () => {
        UserAPI.getMyEventLog().then((response: any) => {
            if (!response.data?.content) {
                return;
            }

            let eventsLog = response.data?.content;

            runInAction(() => {
                appStore.events = eventsLog;
            });

            let myLogLastViewedDate = localStorage.getItem("myLogLastViewedDate");
            if (myLogLastViewedDate) {
                try {
                    let lastDate = new Date(myLogLastViewedDate);
                    let countUnread = 0;

                    eventsLog.forEach((eventLog: any) => {
                        let logDate = new Date(eventLog.createdOn);
                        if (logDate > lastDate) {
                            countUnread++;
                        }
                    });

                    runInAction(() => {
                        notificationStore.unreadEventsCount = countUnread;
                    });
                } catch (e) {
                    console.log("Error unread events count", e);
                }
            } else {
                runInAction(() => {
                    notificationStore.unreadEventsCount = eventsLog.length;
                });
            }
        });
    };

    const getNews = async () => {
        let auditory;
        switch (userStore.role) {
            case Roles.ADMIN:
                auditory = undefined;
                break;
            case Roles.STUDENT:
                auditory = "STUDENT";
                break;
            case Roles.TEACHER:
                auditory = "TEACHER";
                break;
            default:
                auditory = undefined;
                break;
        }

        let result = await newsStore.getNews(auditory);
        let news = result?.content;

        if (news) {
            try {
                let myLastViewedNewsId = localStorage.getItem("myLastViewedNewsId");
                if (!myLastViewedNewsId) {
                    notificationStore.unreadNewsCount = news.length;
                    return;
                }

                let parsedId = parseInt(myLastViewedNewsId);
                let countUnread = 0;
                news.forEach((news: INews) => {
                    if (news.id > parsedId) {
                        countUnread++;
                    }
                });

                runInAction(() => {
                    notificationStore.unreadNewsCount = countUnread;
                });
            } catch (e) {
                console.log("Error unread news count", e);
            }
        }
    };

    return null;
});

export default ServiceUser;
