import {Request} from "core/utils/request";
const INTERNAL_ERROR = "error.internal";

export const ThemeAPI = {
    createTheme: (name) => {
        if (name) {
            return Request.post(`theme`, {name});
        }
    },
    groupingThemes: (gradeTagId, schoolSubjectTagId, themeId) => {
        if (gradeTagId && schoolSubjectTagId && themeId) {
            return Request.post(
                `theme-grouped?gradeTagId=${gradeTagId}&schoolSubjectTagId=${schoolSubjectTagId}&themeId=${themeId}`,
            );
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    getGroupingThemes: (selectedGrades, subjectIds, page = 1, value) => {
        let requestString = "";

        if (selectedGrades && (selectedGrades.length > 0 || selectedGrades > 0)) {
            requestString += `gradeTagId=${selectedGrades.toString()}`;
        }

        if (subjectIds && subjectIds.length > 0) {
            if (requestString === "") {
                requestString += `schoolSubjectTagId=${subjectIds.toString()}`;
            } else {
                requestString += `&schoolSubjectTagId=${subjectIds.toString()}`;
            }
        }

        if (value?.trim().length > 3) {
            requestString += `&name=${value}`;
        }

        return Request.get(`theme-grouped?${requestString}&page=${page}&sort=id,desc`);
    },
    updateGroupedTheme: (themeId, themeGroupedDto) => {
        return Request.put(
            `theme-grouped/${themeId}?gradeTagId=${themeGroupedDto.gradeTagId}&schoolSubjectTagId=${themeGroupedDto.schoolTagId}`,
        );
    },
    updateTheme: (themeId, themeDto) => {
        return Request.put(`theme/${themeId}`, themeDto);
    },
    deleteGroupedTheme: (themeId) => {
        return Request.delete(`theme-grouped/${themeId}`);
    },
};
