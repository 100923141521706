import React from "react";
import PropTypes from "prop-types";
import {EditButtons} from "views/components/edit-buttons";
import styles from "./audio-player.module.sass";
import cn from "classnames";

const AudioPlayer = ({src, withEdit, deleteAction, id, audioRef, isQuestion, isConstructor}) => {
    return (
        <div
            className={cn({
                [styles.wrapper]: true,
                [styles.isQuestion]: isQuestion,
                [styles.isConstructor]: isConstructor,
            })}
        >
            <audio
                className={styles.player}
                controls
                src={src}
                controlsList="nofullscreen nodownload noremoteplayback"
                ref={audioRef}
            />
            {withEdit && <EditButtons deleteAction={() => deleteAction(id)} withoutRightBorder />}
        </div>
    );
};

AudioPlayer.propTypes = {
    audioRef: PropTypes.object,
    deleteAction: PropTypes.func,
    id: PropTypes.number,
    isQuestion: PropTypes.bool,
    isConstructor: PropTypes.bool,
    src: PropTypes.any,
    withEdit: PropTypes.bool,
};

export default AudioPlayer;
