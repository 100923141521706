import {url} from "config";
import {SEQUENCES_IMAGE_TEST} from "config/constructor-names";
import {Image} from "entries/constructors/classic-test/views/components/image-answer";
import {NARRATORS} from "entries/constructors/classic-test/views/components/properties-buttons";
import {CreateQuestion} from "entries/constructors/classic-test/views/containers/create-question";
import {TestParameters} from "entries/constructors/classic-test/views/containers/test-parameters";
import {useConstructor} from "entries/constructors/hooks/useConstructor";
import {SequencesTestAPI} from "entries/constructors/sequences/core/api/sequences";

import {CreateImageSequenceAnswers} from "entries/constructors/sequences/numeric-sequence-test/views/containers/create-image-sequence-answers/create-image-sequence-answers";
import {MDBAlert} from "mdbreact";

import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {ConstructorNavbar} from "views/components/constructor-navbar";
import {Col} from "views/components/layout/col";
import {SaveContent} from "views/components/layout/save-content/save-content";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {TestOptions} from "views/components/layout/test-options";
import {PropertyColumn} from "views/containers/property-column";
import TestSave from "views/containers/test-save/test-save";
import {useWizard} from "views2/conatiners/CreateTestWizard";

export const NumericSequenceTest = ({testData, isEditMode}) => {
    const {
        STEP,
        stepsOrder,
        currentStep,
        toNext,
        to,
        enabledSteps,
        enableStep,
        disableStep,
        enableSteps,
    } = useWizard();

    const loadAnswers = (answers) => {
        let answersTemp = answers.map((item) => {
            let itemImage = item.answerImageBank
                ? item.answerImageBank.fileDownloadUri.slice(1)
                : item.answerImageQuestion.fileDownloadUri.slice(1);
            return {
                id: item.id,
                component: <Image imageUrl={itemImage} />,
                type: item.objectType,
            };
        });
        setAnswers(answersTemp);
    };

    const {
        testId,
        questionText,
        setQuestionText,
        questionImage,
        setQuestionImage,
        questionFormula,
        setQuestionFormula,
        background,
        setBackground,
        narrator,
        setNarrator,
        textTitleSize,
        setTextTitleSize,
        currentPropertyTab,
        setCurrentPropertyTab,
        tabNames,
        questionType,
        elementSize,
        setElementSize,
        questionAudio,
        setQuestionAudio,
    } = useConstructor({
        isEditMode,
        constructorName: SEQUENCES_IMAGE_TEST,
        testData,
        loadAnswersCallback: loadAnswers,
    });

    const [answers, setAnswers] = useState([]);
    const [correctAnswers, setCorrectAnswers] = useState([]);

    useEffect(() => {
        if (isEditMode) {
            enableSteps([STEP.ANSWER, STEP.QUESTION, STEP.PARAMS]);
            return;
        }

        if (questionText && answers.length >= 2 && narrator) {
            enableStep(STEP.ANSWER);
        } else {
            disableStep(STEP.ANSWER);
        }
    }, [questionText, answers, narrator, isEditMode]);

    const onSelectNarrator = (narrator) => {
        setNarrator({
            id: narrator.id,
            url: `${url}${narrator.url}`,
        });
    };

    return (
        <div>
            <ConstructorNavbar
                tabs={stepsOrder}
                currentTab={currentStep}
                availableTabs={enabledSteps}
                onSelect={(tab) => to(tab)}
                tabNames={tabNames}
            />

            <Col size={12} className="animated fadeIn">
                {currentStep === STEP.QUESTION || currentStep === STEP.ANSWER ? (
                    <Col size={8} className="d-flex justify-content-start">
                        <TestContent background={background}>
                            <TestConstructor>
                                <CreateQuestion
                                    isEditMode={currentStep === STEP.QUESTION}
                                    narrator={narrator}
                                    testHasNarrator
                                    textTitleSize={textTitleSize}
                                    questionImage={questionImage}
                                    questionText={questionText}
                                    questionFormula={questionFormula}
                                    updateQuestionImage={(image) => setQuestionImage(image)}
                                    updateQuestionText={(text) => setQuestionText(text)}
                                    updateQuestionFormula={(formula) => setQuestionFormula(formula)}
                                    selectPropertyNarrator={() => setCurrentPropertyTab(NARRATORS)}
                                    questionId={testId}
                                    questionTitle={testData.title}
                                    apiInstance={SequencesTestAPI}
                                    questionAudio={questionAudio}
                                    updateQuestionAudio={setQuestionAudio}
                                />

                                <CreateImageSequenceAnswers
                                    isEditMode={currentStep === STEP.QUESTION}
                                    answers={answers}
                                    questionId={testId}
                                    updateAnswers={(answers) => setAnswers(answers)}
                                    updateCorrectAnswers={(answers) => setCorrectAnswers(answers)}
                                    elementSize={elementSize}
                                />
                            </TestConstructor>
                        </TestContent>
                    </Col>
                ) : (
                    <TestParameters
                        testId={testId}
                        apiInstance={SequencesTestAPI}
                        mode={questionType}
                        isEditMode={isEditMode}
                    />
                )}
                {(currentStep === STEP.QUESTION || currentStep === STEP.ANSWER) && (
                    <Col size={4}>
                        <TestOptions>
                            {currentStep === STEP.QUESTION && (
                                <PropertyColumn
                                    onSelectTab={(tab) => setCurrentPropertyTab(tab)}
                                    currentPropertyTab={currentPropertyTab}
                                    selectImage={(background) => setBackground(background)}
                                    selectNarrator={onSelectNarrator}
                                    textTitleSize={textTitleSize}
                                    setTextTitleSize={(size) =>
                                        setTextTitleSize(Number.parseInt(size))
                                    }
                                    isQuestionTextExist={!!questionText}
                                    withResizeElement
                                    currentElementSize={elementSize}
                                    selectElementSize={(size) =>
                                        setElementSize(Number.parseInt(size))
                                    }
                                    background={background}
                                    narrator={narrator}
                                    toolTipText="Вы можете загружать изображения в любой удобной последовательности. При решении задачи ответы будут перемешиваться."
                                />
                            )}
                            {currentStep === STEP.ANSWER && (
                                <SaveContent>
                                    <h5>Укажите правильную последовательность</h5>
                                    <p>Решите задачу правильно, а затем сохраните ответ</p>
                                    <TestSave
                                        apiInstance={SequencesTestAPI}
                                        questionId={testId}
                                        answersIdsForSave={correctAnswers}
                                        backgroundId={background.id}
                                        isTwoColumnMode={false}
                                        narratorId={narrator.id}
                                        sizeTitle={textTitleSize}
                                        onSaveProperties={() => toNext()}
                                        constructorName={SEQUENCES_IMAGE_TEST}
                                        sizeImage={elementSize}
                                        mode={questionType}
                                    />

                                    <MDBAlert color="info" className="mt-5">
                                        <p>
                                            <i className="fa fa-info-circle" aria-hidden="true" />
                                            Кликайте по изображениям и устанавливайте правильные
                                            порядковые номера ответов
                                        </p>
                                    </MDBAlert>
                                </SaveContent>
                            )}
                        </TestOptions>
                    </Col>
                )}
            </Col>
        </div>
    );
};

NumericSequenceTest.propTypes = {
    maxAnswersCount: PropTypes.number.isRequired,
    isEditMode: PropTypes.bool,
    testData: PropTypes.object,
};

NumericSequenceTest.defaultProps = {
    maxAnswersCount: 6,
};
