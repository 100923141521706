import {SELECT_ROLE_TEACHER, SELECT_ROLE_USER} from "entries/select-role/select-role-container";
import {MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";

const SelectRole = ({handleSelectRole}) => {
    return (
        <div>
            У вас роль пользователя
            <MDBBtn color="danger" onClick={() => handleSelectRole(SELECT_ROLE_TEACHER)}>
                Стать учителем
            </MDBBtn>
            <MDBBtn color="warning" onClick={() => handleSelectRole(SELECT_ROLE_USER)}>
                Стать учеником
            </MDBBtn>
        </div>
    );
};

SelectRole.propTypes = {
    handleSelectRole: PropTypes.func,
};

export default SelectRole;
