import React from "react";

interface IProps {
    value: string;
    setValue: (text: string) => void;
}

const InputWithMask = ({value, setValue}: IProps) => {
    const handleChange = (text: string) => {
        if (text.length) {
            // @ts-ignore
            let filteredText = [...text].filter((s) => s !== "−");
            let updatedValue = filteredText.map((symbol, index) => {
                symbol = symbol.toUpperCase();
                index += 1;
                if (index % 4 === 0 && index !== filteredText.length) {
                    return symbol + "−";
                }

                return symbol;
            });
            setValue(updatedValue.join(""));
        } else {
            setValue(text);
        }
    };

    return (
        <input
            type="textarea"
            className="form-control"
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            maxLength={14}
            placeholder="XXXX-XXXX-XXXX"
            style={{width: 300, marginBottom: "0", marginLeft: 20, fontSize: "1rem"}}
        />
    );
};

export default InputWithMask;
