import React from "react";
import cn from "classnames";
import styles from "./header.module.sass";
import EventsModal from "../../modals/events-modal/events-modal";
import {MDBBadge} from "mdbreact";
import {useStores} from "../../../hooks/use-stores";
import {observer} from "mobx-react-lite";
import mobileStyles from "./style-mobile.module.sass";

const HeaderBellItem = observer(() => {
    const {appStore, notificationStore} = useStores();

    return (
        <div className={styles.bellContainer}>
            <div
                className={cn({
                    [styles.bell]: true,
                    [mobileStyles.bell]: appStore.isMobile,
                })}
                onClick={() =>
                    appStore.addModalToQueue(
                        <EventsModal
                            key="events-modal-key"
                            toggle={() => appStore.closeActiveModal()}
                        />,
                    )
                }
            >
                {notificationStore.unreadNewsCount + notificationStore.unreadEventsCount > 0 && (
                    <MDBBadge
                        color="info"
                        className={cn({
                            [mobileStyles.badge]: appStore.isMobile,
                        })}
                    >
                        {notificationStore.unreadEventsCount + notificationStore.unreadNewsCount}
                    </MDBBadge>
                )}
                <i className="fa-regular fa-bell"></i>
            </div>
        </div>
    );
});

export default HeaderBellItem;
