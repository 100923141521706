import {MDBBtn} from "mdbreact";
import React from "react";
import PropTypes from "prop-types";
import styles from "./list-tools.module.sass";

export const ListTools = props => {
    const {handlerAddNewItem} = props;
    return (
        <div className={styles.container}>
            <div>
                <p>Block for search...</p>
            </div>
            <div>
                <MDBBtn className="blue-gradient" size="md" onClick={handlerAddNewItem}>
                    <i className="fas fa-plus mr-2" />
                    Добавить новую тему
                </MDBBtn>
            </div>
        </div>
    );
};

ListTools.propTypes = {
    handlerAddNewItem: PropTypes.func,
};
