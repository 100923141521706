import {TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG} from "config";
import {QuestionAPI} from "core/api/question";
import {MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";

const UpdateImageQuestion = ({questionId}) => {
    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = () => {
        setIsLoading(true);
        QuestionAPI.updateQuestionImageWithoutProperties(questionId, image)
            .then(() => {
                toast.success("Успешно, перезапустите задание", TOAST_SUCCESS_CONFIG);
                setImage(null);
            })
            .catch((err) => toast.error(`Ошибка ${err.message}`, TOAST_ERROR_CONFIG))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setImage(null);
    }, [questionId]);

    return (
        <React.Fragment>
            <p>Замена изображения для ID {questionId}</p>
            <input type="file" onChange={(e) => setImage(e.target.files[0])} />
            {image && (
                <MDBBtn color="danger" className="mt-2" disabled={isLoading} onClick={handleSave}>
                    Сохранить изображение {image.name} в задание с ID-{questionId}
                </MDBBtn>
            )}
        </React.Fragment>
    );
};

UpdateImageQuestion.propTypes = {
    questionId: PropTypes.number,
};

export default UpdateImageQuestion;
