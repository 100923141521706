import React from "react";
import PropTypes from "prop-types";

import styles from "./add-text-answer-button.module.sass";

export const AddTextAnswerButton = (props) => {
    const {handleClick} = props;
    return (
        <div className={styles.answerContainer}>
            <div onClick={handleClick} className={styles.containerAddAnswerText}>
                <p>
                    <i className="fas fa-font" />
                    Добавить ответ
                </p>
            </div>
        </div>
    );
};

AddTextAnswerButton.propTypes = {
    handleClick: PropTypes.func.isRequired,
};
