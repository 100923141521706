import cn from "classnames";
import {url} from "config";
import EnterButton from "entries/enter-button/enter-button";
import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";
import styles from "views/pages/about/sliders/teacher-class-slide/styles.module.sass";

const AcademyHallOfFameSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${url + "data/image-bank/de6a979e-97c5-453b-aeec-146cd8b6edef.png"})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            <div className={sliders.content}>
                <div
                    className={cn({
                        [sliders.about]: true,
                        "mt-5": true,
                    })}
                >
                    <p
                        className={cn({
                            [sliders.title]: true,
                            [styles.title]: true,
                            "text-center": true,
                        })}
                    >
                        Результат Академии
                    </p>
                    <p
                        className={cn({
                            [sliders.description]: true,
                            [styles.description]: true,
                            "text-center": true,
                            "w-100": true,
                        })}
                    >
                        Что главное, участие или результат? <br /> Дипломы точно станут приятным
                        дополнением к школьному портфолио.
                    </p>

                    <EnterButton className={styles.button} />
                </div>
            </div>
        </div>
    );
};

AcademyHallOfFameSlide.propTypes = {};

export default AcademyHallOfFameSlide;
