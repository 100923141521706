import cn from "classnames";
import {url} from "config";
import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";
import styles from "views/pages/about/sliders/teacher-class-slide/styles.module.sass";

const AcademyStartSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${url + "data/image-bank/fc9e985b-bad7-43d1-b26a-ef8b345edc0b.png"})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            <div className={sliders.content}>
                <div
                    className={cn({
                        [sliders.about]: true,
                        "mt-5": true,
                    })}
                >
                    <p
                        className={cn({
                            [sliders.title]: true,
                            "text-center": true,
                        })}
                    >
                        Академия Умной Вороны
                    </p>
                    <p
                        className={cn({
                            [sliders.description]: true,
                            [styles.description]: true,
                            "text-center": true,
                            "w-100": true,
                        })}
                    >
                        Тысячи интерактивных заданий для 1-4 классов по школьной программе. <br />
                        Учитесь играя вместе с Умной Вороной!
                    </p>
                </div>
            </div>
        </div>
    );
};

AcademyStartSlide.propTypes = {};

export default AcademyStartSlide;
