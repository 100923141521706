import {Request} from "core/utils/request";

export const UserAPI = {
    me: () => {
        return Request.get(`me`);
    },
    updateMe: (userDto) => {
        return Request.put(`me`, userDto);
    },
    getUser: (id) => {
        return Request.get(`user/${id}`);
    },
    getUserInfo: () => {
        return Request.get(`user-info`);
    },
    getUserRating: (userId) => {
        return Request.get(`user/${userId}/rating`);
    },
    getAchievements: (userId) => {
        return Request.get(`user/${userId}/event-log?size=1000000`);
    },
    getMyHomeworks: (page) => {
        return Request.get(`student/my-home-work?page=${page}&size=10&sort=id,desc`);
    },
    getMyTeacher: () => {
        return Request.get(`student/my-teacher?teacherRequestJoinStatus=ACCEPTED&size=1000`);
    },
    getRatingBlockAll: () => {
        return Request.get(`student/rating-block-all?size=1000`);
    },
    getRatingBlock: (blockId) => {
        return Request.get(`student/rating-block?academyBlockId=${blockId}`);
    },
    getMyClassmates: () => {
        return Request.get(`student/my-classmates`);
    },
    uploadLogoFromLibrary: (imageId) => {
        return Request.post(`user-info/avatar-image-bank?image_bank_id=${imageId}`);
    },
    uploadHeaderImage: (bankId) => {
        return Request.post(`user-info/header-image-bank?image_bank_id=${bankId}`);
    },
    getUserSkills: () => {
        return Request.get(`teacher/skill`);
    },
    selectRole: (role) => {
        return Request.post(`me/role-add?userKeycloakRole=${role}`);
    },
    updateProfile: (profileDto) => {
        return Request.put(`user-info`, profileDto);
    },
    updateSettings: (settingsDto) => {
        return Request.put(`user-info/settings`, settingsDto);
    },
    addUserTags: (tags) => {
        return Request.post(`user-info/tags`, tags);
    },
    createProfile: (profileDto) => {
        return Request.post(`user-info`, profileDto);
    },
    getUserProfileById: (userId) => {
        return Request.get(`user/${userId}/user-info`);
    },
    getCoins: (userId) => {
        return Request.get(`user/${userId}/coin`);
    },
    getMyDocuments: () => {
        return Request.get(`my-reward-document?size=10000`);
    },
    getRatingBlockAllByUserId: (userId) => {
        return Request.get(`user/${userId}/rating-block-all?size=1000`);
    },
    createTempSchool: (schoolName) => {
        return Request.post(`temp-school?schoolName=${schoolName}`);
    },
    getMySubscriptions: () => {
        return Request.get(`my-subscription`);
    },
    editStudentUserInfoById: (id, userInfoDto) => {
        return Request.put(`teacher/class/student/${id}/user-info`, userInfoDto);
    },
    editStudentDtoById: (id, userDto) => {
        return Request.put(
            `teacher/class/student/${id}/name?firstName=${userDto.firstName}&lastName=${userDto.lastName}`,
        );
    },
    getUsers: (size, page) => {
        return Request.get(`admin/users?size=${size}&page=${page}`);
    },
    getTournamentResults: (userId) => {
        return Request.get(`user/${userId}/tournament/result`);
    },
    getAllRatings: (userId) => {
        return Request.get(`place/all/user/${userId}`);
    },
    updateClass: (classNumber) => {
        return Request.put(`my-class-tag?classTagId=${classNumber}`);
    },
    dailyCount: () => {
        return Request.put(`user/login-days-count`);
    },
    getDailyCount: () => {
        return Request.get(`user/login-days-count`);
    },
    getMyEventLog: () => {
        return Request.get(`my-event-log?size=100000`);
    },
};
