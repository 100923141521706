import React, {useState} from "react";
import {toast} from "react-toastify";
import {AdminPanelUserAPI} from "../admin-panel-users-api";
import AdminPanelFormCreator, {
    EFormCreatorTypes,
} from "../../admin-panel-form-creator/admin-panel-form-creator";

const AdminPanelUserCreateStudent = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleCreate = (formData: any) => {
        let {firstName, lastName, teacherId} = formData;
        if (!(teacherId && lastName && firstName)) {
            toast.error("Заполните все поля");
            return;
        }

        setIsLoading(true);
        AdminPanelUserAPI.createStudent(formData)
            .then(() => {
                toast.success(`Пользователь создан`);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <React.Fragment>
            <AdminPanelFormCreator
                handleClick={handleCreate}
                bannerText="Создание студента"
                formConstructor={[
                    {
                        name: "firstName",
                        labelText: "Имя",
                        type: EFormCreatorTypes.TEXT,
                    },
                    {
                        name: "lastName",
                        labelText: "Фамилия",
                        type: EFormCreatorTypes.TEXT,
                    },
                    {
                        name: "teacherId",
                        labelText: "ID Учителя",
                        type: EFormCreatorTypes.TEXT,
                    },
                    {
                        name: "Создать",
                        type: EFormCreatorTypes.BUTTON,
                        color: "success",
                    },
                ]}
                isLoading={isLoading}
            />
        </React.Fragment>
    );
};

export default AdminPanelUserCreateStudent;
