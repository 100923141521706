import styles from "entries/constructors/classic-test/views/components/formula/formula.module.sass";
import PropTypes from "prop-types";
import React from "react";

export const Formula = ({formulaURI}) => {
    return (
        <div className={styles.formulaWrapper}>
            <img className={styles.image} src={formulaURI} alt="Formula" />
        </div>
    );
};

Formula.propTypes = {
    formulaURI: PropTypes.string,
};
