import React, {useState} from "react";
import {EPublicationCustomActionTypes} from "../../../../enums/EPublicationCustomActionTypes";
import cn from "classnames";
import styles from "./styles.module.sass";
import {EditButtons} from "../../../../views/components/edit-buttons";
import {IPublicationFile} from "../../../../interfaces/IPublicationFile";
import {url} from "../../../../config";
import {getFileTypeByFileName} from "../../../../core/utils/helpers";
import {TextUploadModal} from "../../../../views/components/text-upload-modal/text-upload-modal";
import ArticleTitle from "../../article-title/article-title";

interface IProps {
    block: any;
    isViewMode: boolean;
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
    handleCustomAction: (type: EPublicationCustomActionTypes, payload: any) => void;
}

const ArticleFileBlock = ({isViewMode, handleDelete, block, handleCustomAction}: IProps) => {
    const [textModalIsOpen, setTextModalIsOpen] = useState(false);
    const [itemForEdit, setItemForEdit] = useState<IPublicationFile | null>(null);

    let changeImageName = (name: string) => {
        handleCustomAction(EPublicationCustomActionTypes.FILES_UPDATE_NAME, {
            name,
            blockId: block.id,
            fileId: itemForEdit?.file?.id,
        });
        setItemForEdit(null);
        setTextModalIsOpen(false);
    };

    const getImageByFileType = (fileType: string) => {
        switch (fileType) {
            case "doc":
            case "docx":
                return require("../../../../assets/images/icons/files/doc.svg");
            case "pdf":
                return require("../../../../assets/images/icons/files/pdf.svg");
            case "ppt":
                return require("../../../../assets/images/icons/files/ppt.svg");
            case "xls":
            case "xlsx":
                return require("../../../../assets/images/icons/files/xls.svg");
            case "png":
                return require("../../../../assets/images/icons/files/png.svg");
            default:
                return undefined;
        }
    };

    return (
        <div
            className={cn({
                [styles.editMode]: !isViewMode,
                [styles.fileBlock]: true,
            })}
        >
            {textModalIsOpen && (
                <TextUploadModal
                    saveText={changeImageName}
                    visible={textModalIsOpen}
                    toggle={() => setTextModalIsOpen(!textModalIsOpen)}
                    text={itemForEdit?.file?.name}
                />
            )}

            <ArticleTitle
                isViewMode={isViewMode}
                handleCustomAction={handleCustomAction}
                block={block}
                title={block.description}
            />

            <div className={styles.fileWrapper}>
                {block.documentFiles.map((file: IPublicationFile) => {
                    return (
                        <div key={file.id}>
                            <div
                                className={cn({
                                    [styles.file]: true,
                                    [styles.fileInViewMode]: isViewMode,
                                })}
                            >
                                <div
                                    className={cn({
                                        [styles.filePreview]: true,
                                        [styles.filePreviewInViewMode]: isViewMode,
                                    })}
                                >
                                    <img
                                        src={getImageByFileType(
                                            getFileTypeByFileName(file.file?.fileName),
                                        )}
                                        alt="file-logo"
                                    />
                                </div>
                                <div className={styles.fileDescription}>
                                    <p>
                                        <a
                                            href={url + file.file?.fileDownloadUri.slice(1)}
                                            download={file.file?.name}
                                            target="_blank"
                                            className={styles.link}
                                        >
                                            {file.file?.name}
                                        </a>

                                        {!isViewMode && (
                                            <i
                                                className="fas fa-pen"
                                                onClick={() => {
                                                    setItemForEdit(file);
                                                    setTextModalIsOpen(true);
                                                }}
                                            />
                                        )}
                                    </p>

                                    <div className={styles.download}>
                                        <a
                                            href={url + file.file?.fileDownloadUri.slice(1)}
                                            download={file.file?.name}
                                            target="_blank"
                                        >
                                            Скачать
                                        </a>
                                    </div>

                                    {!isViewMode && (
                                        <EditButtons
                                            deleteAction={() =>
                                                handleCustomAction(
                                                    EPublicationCustomActionTypes.FILES_DELETE_FILE,
                                                    {blockId: block.id, fileId: file.id},
                                                )
                                            }
                                            isSolidBorder
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}

                {!isViewMode && (
                    <div className={styles.file}>
                        <label
                            onChange={(e: any) => {
                                handleCustomAction(
                                    EPublicationCustomActionTypes.FILES_UPLOAD_FILE,
                                    {
                                        file: e.target.files[0],
                                        blockId: block.id,
                                    },
                                );
                            }}
                            htmlFor={`form${block.id}`}
                        >
                            <input
                                name=""
                                type="file"
                                id={`form${block.id}`}
                                hidden
                                accept=".doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx, .png"
                            />
                            <div className={styles.filePreview}>
                                <a>Загрузить файл</a>
                            </div>
                        </label>
                    </div>
                )}
            </div>
            {!isViewMode && (
                <EditButtons
                    deleteAction={() => handleDelete(block.id)}
                    isSolidBorder
                    itemId={block.id}
                />
            )}
        </div>
    );
};

export default ArticleFileBlock;
