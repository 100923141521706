import {MDBBtn, MDBBtnGroup} from "mdbreact";
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const Thumbler = ({options, activeState, setActiveState, isFullWidth, className}) => {
    return (
        <MDBBtnGroup
            className={cn({
                "w-100": isFullWidth,
                [className]: !!className,
            })}
        >
            {options.map((option, index) => (
                <MDBBtn
                    key={index}
                    color="info"
                    active={activeState === option.value}
                    onClick={() => setActiveState(option.value)}
                >
                    {option.name}
                </MDBBtn>
            ))}
        </MDBBtnGroup>
    );
};

Thumbler.propTypes = {
    activeState: PropTypes.any,
    className: PropTypes.string,
    isFullWidth: PropTypes.bool,
    options: PropTypes.array,
    setActiveState: PropTypes.func,
};

export default Thumbler;
