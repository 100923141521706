import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./test-options.module.sass";

export const TestOptions = props => (
    <div
        className={cn({
            [styles.container]: true,
            [props.className]: !!props.className,
        })}
    >
        {props.children}
    </div>
);

TestOptions.propTypes = {
    className: PropTypes.string,
};
