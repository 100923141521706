import React, {memo} from "react";
import {IPublication} from "../../../interfaces/IPublication";
import SpinnerWithText from "../../spinner/spinner";
import ArticleListItem from "../article-list-item/article-list-item";
import cn from "classnames";
import styles from "../../../views/components/list-with-pagination/list-with-pagination.module.sass";

// @ts-ignore
import Pagination from "react-js-pagination";

interface IProps {
    articles: IPublication[] | undefined;
    isLoading: boolean;
    handleDeletePublication?: (id: number) => void;
    handleEditPublication?: (article: IPublication) => void;
    isPublic?: boolean;
    pagination: any;
    handleChangePage: (page: number) => void;
    activePage: number;
}

export const ArticlesList = memo(
    ({
        articles,
        isLoading,
        handleDeletePublication,
        handleEditPublication,
        isPublic,
        activePage,
        handleChangePage,
        pagination,
    }: IProps) => {
        if (isLoading) {
            return (
                <div>
                    <SpinnerWithText text="Загрузка статей..." />
                </div>
            );
        }

        return (
            <div>
                {articles ? (
                    <React.Fragment>
                        {articles?.map((article) => (
                            <ArticleListItem
                                key={article.id}
                                article={article}
                                handleDeletePublication={handleDeletePublication}
                                handleEditPublication={handleEditPublication}
                                isPublic={isPublic}
                            />
                        ))}

                        <Pagination
                            hideNavigation
                            activePage={activePage}
                            totalItemsCount={pagination?.totalElements || 0}
                            itemsCountPerPage={10}
                            onChange={(page: number) => handleChangePage(page)}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass={cn({
                                pagination: true,
                                "pg-blue": true,
                                [styles.pagination]: true,
                            })}
                        />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <p>Статей нет</p>
                    </React.Fragment>
                )}
            </div>
        );
    },
);
