import {GeoAPI} from "entries/geo-selects/core/GeoAPI";
import GeoSelectRegions from "entries/geo-selects/views/geo-select-regions/geo-select-regions";
import React from "react";
import PropTypes from "prop-types";

const GeoSelectRegionsContainer = ({handleSelect, selectedRegion}) => {
    async function loadOptions(searchValue, loadedOptions, {page}) {
        if (searchValue?.length > 0 && searchValue?.length < 3) {
            return {
                options: [],
            };
        }

        const response = await GeoAPI.getRegions(page, searchValue);
        return {
            options: response.data.content.map((item) => {
                return {
                    value: item.geoNameId,
                    label: item.altName,
                };
            }),
            hasMore: !response.data.last,
            additional: {
                page: page + 1,
            },
        };
    }

    return (
        <GeoSelectRegions
            loadOptions={loadOptions}
            handleSelect={handleSelect}
            selectedRegion={selectedRegion}
        />
    );
};

GeoSelectRegionsContainer.propTypes = {
    handleSelect: PropTypes.func,
    selectedRegion: PropTypes.object,
};

export default GeoSelectRegionsContainer;
