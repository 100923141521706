import React, {ReactFragment, useEffect, useRef, useState} from "react";
import styles from "./styles.module.sass";
import EnterButton from "../enter-button/enter-button";
import Lottie from "lottie-react";
import gestureUp from "./../../assets/animations/swipe-gesture-up.json";

export interface ISlide {
    title: ReactFragment;
    image: string;
}

interface IProps {
    slides: ISlide[];
    titleLogo: string;
}

const MobilePresentation = ({slides, titleLogo}: IProps) => {
    const [scrollCoefficient, setScrollCoefficient] = useState(0);
    const imageRef = useRef<HTMLImageElement>(null);

    const lottieGestureUpRef = useRef(null);

    useEffect(() => {
        // @ts-ignore
        return () => lottieGestureUpRef?.current?.destroy();
    }, []);

    const handleScroll = () => {
        let imageHeight = imageRef?.current?.height;
        let scroll = window.scrollY;

        if (imageHeight) {
            let cf = Math.floor(scroll / (imageHeight - 50));
            setScrollCoefficient(cf);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img src={titleLogo} alt="title-logo" />
                {slides[scrollCoefficient]?.title && <div>{slides[scrollCoefficient].title}</div>}
            </div>
            <div className={styles.content}>
                {slides.map((slide, index) => (
                    <img key={index} src={slide.image} alt="slide" ref={imageRef} />
                ))}
            </div>
            <div className={styles.footer}>
                <Lottie
                    animationData={gestureUp}
                    loop={true}
                    className={styles.animationGesture}
                    lottieRef={lottieGestureUpRef}
                />
                <EnterButton className={styles.button} />
            </div>
        </div>
    );
};

export default MobilePresentation;
