import React from "react";
import PolicyHeader from "views/pages/policy/policy-header";
import styles from "views/pages/policy/styles.module.sass";

const CookiesPolicy = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <PolicyHeader />
                <div className={styles.information}>
                    <h1 align="center">Файлы сookie</h1>

                    <p>
                        Продолжая использовать наш сайт, вы даете согласие ООО &laquo;Урок&raquo; на
                        обработку файлов cookie и пользовательских данных (сведения о
                        местоположении; тип и версия ОС; тип и версия Браузера; тип устройства и
                        разрешение его экрана; источник откуда пришел на сайт пользователь; с какого
                        сайта или по какой рекламе; язык ОС и Браузера; какие страницы открывает и
                        на какие кнопки нажимает пользователь; ip-адрес) в целях функционирования
                        сайта, проведения ретаргетинга, и проведения статистических исследований и
                        обзоров. Если вы не хотите, чтобы ваши данные обрабатывались, вам следует
                        прекратить использование сайта umnayavorona.ru и покинуть его.
                    </p>

                    <p>
                        Файлы сookie представляют собой небольшие текстовые файлы, которые
                        сохраняются на вашем компьютере или мобильном устройстве при посещении сайта
                        umnayavorona.ru. Эти данные, как правило, состоят из буквенно-цифровых
                        строк, которые обеспечивают уникальную идентификацию вашего устройства, но
                        могут содержать и иные сведения. Как правило, данные файлы не занимают много
                        места на устройстве и автоматически удаляются по истечении срока их
                        действия. Некоторые сookie-файлы используются до конца сеанса соединения с
                        Интернетом, другие сохраняются в течение ограниченного периода времени. ООО
                        &laquo;Урок&raquo; использует сookie-файлы и аналогичные технологии в
                        зависимости от контекста в описанных ниже целях:
                    </p>

                    <p>
                        <b>Хранение параметров и предпочтений</b>. Umnayavorona.ru может
                        использовать файлы сookie для хранения предпочтений и параметров на вашем
                        устройстве, а также для улучшения взаимодействия вашего устройства с
                        посещенными веб-сайтами. Сохранение предпочтений в файлах сookie, например,
                        местоположение пользователя, позволяет не настраивать данные параметры
                        повторно.
                    </p>

                    <p>
                        <b>Вход и аутентификация</b>. Umnayavorona.ru может использовать
                        сookie-файлы для проверки подлинности зарегистрированного пользователя.
                        Когда зарегистрированный пользователь заходит на веб-сайт, используя личную
                        учетную запись, автоматически происходит сохранение на устройстве
                        пользователя уникального кода и время входа в зашифрованном сookie-файле.
                        Этот файл сookie позволит перемещаться между страницами в пределах веб-сайта
                        без необходимости выполнения входа на каждой странице.
                    </p>

                    <p>
                        <b>Хранение информации, предоставляемой веб-сайту</b>. Umnayavorona.ru может
                        использовать сookie-файлы для хранения сведений, предоставленных
                        пользователями. Когда пользователь, посещающий сайт umnayavorona.ru,
                        предоставляет ООО &laquo;Урок&raquo; информацию, например, пользователь
                        добавляет продукты в корзину при совершении покупок на сайте
                        umnayavorona.ru, то происходит автоматическое сохранение данных в
                        cookie-файле, чтобы запомнить продукты и сведения, которые добавил
                        пользователь.
                    </p>

                    <p>
                        <b>Аналитика</b>. Umnayavorona.ru может использовать файлы cookie для
                        анализа действий пользователей. Например, ООО &laquo;Урок&raquo; использует
                        файлы cookie для учета количества уникальных посетителей веб-сайта, а также
                        для получения других статистических данных, связанных с функционированием
                        сайта umnayavorona.ru.
                    </p>

                    <p>
                        В большинстве браузеров использование сookie-файлов принимается
                        автоматически, но у пользователя есть возможность управлять параметрами
                        браузера для блокирования или удаления сookie-файлов.
                    </p>

                    <p>
                        Если Вы не желаете использовать cookie-файлы, то Вам необходимо
                        самостоятельно в настройках браузера просмотреть какие файлы cookie
                        используются сайтом umnayavorona.ru и отключить вручную функцию
                        использования cookie-файлов.
                    </p>
                </div>
            </div>
        </div>
    );
};

CookiesPolicy.propTypes = {};

export default CookiesPolicy;
