import {url} from "config";
import {Formula} from "entries/constructors/classic-test/views/components/formula";
import {Image} from "entries/constructors/classic-test/views/components/image-answer";
import {Text} from "entries/constructors/classic-test/views/components/text-answer";
import {
    FORMULA_ANSWER_PAIRS,
    IMAGE_FROM_BANK_ANSWER_PAIRS,
    IMAGE_FROM_UPLOAD_ANSWER_PAIRS,
    TEXT_ANSWER_PAIRS,
} from "entries/constructors/pairs-test/core/types/pairs-test-types";
import styles from "entries/constructors/pairs-test/views/containers/pairs-test/pairs-test.module.sass";
import {MDBAlert} from "mdbreact";
import React, {useEffect, useState} from "react";
import {DraggableItem, DraggableList} from "views/components/DraggableList";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {TwoColumnAnswers} from "views/components/layout/two-column-answers/two-column-answers";
import {Question} from "views/components/question";
import PropTypes from "prop-types";

export const PairsPlayer = ({
    question,
    staticAnswers,
    dynamicAnswers,
    properties,
    setDynamicAnswers,
    onSelectCorrectAnswer,
}) => {
    // данные для вопроса
    const [questionText, setQuestionText] = useState("");
    const [questionImage, setQuestionImage] = useState({});
    const [questionNarrator, setQuestionNarrator] = useState({});
    const [questionAudio, setQuestionAudio] = useState(null);

    // параметры
    const [questionSizeText, setQuestionSizeText] = useState(16);
    const [background, setBackground] = useState("");
    const [answerSizeText, setAnswerSizeText] = useState(16);
    const [answerSizeElement, setAnswerSizeElement] = useState(150);

    useEffect(() => {
        if (question) {
            // вопрос
            if (question.questionText) {
                setQuestionText(question.questionText);
            }

            if (question.imageQuestion) {
                setQuestionImage({
                    id: question.imageQuestion.id,
                    url: question.imageQuestion.fileDownloadUri.slice(1),
                });
            }

            if (question.audio) {
                setQuestionAudio(question.audio);
            }
        }
    }, [question, questionSizeText]);

    useEffect(() => {
        if (properties) {
            // фон
            if (properties.backgroundImage) {
                setBackground(properties.backgroundImage.fileDownloadUri.slice(1));
            } else {
                setBackground("data/image-bank/ae86cc0f-5bc8-457f-98bb-454a0ea993a6.svg");
            }

            // персонаж
            if (properties.narrator) {
                let narratorUrl = `${url}${properties.narrator.image.fileDownloadUri.slice(1)}`;
                setQuestionNarrator({url: narratorUrl});
            }

            // размер шрифта вопроса
            if (properties.sizeTitle) {
                setQuestionSizeText(properties.sizeTitle);
            }

            // размер шрифта ответа
            if (properties.sizeText) {
                setAnswerSizeText(properties.sizeText);
            }

            // размер шрифта картинки
            if (properties.sizeImage) {
                setAnswerSizeElement(properties.sizeImage);
            }
        }
    }, [properties]);

    useEffect(() => {
        const resultAnswers = [];
        staticAnswers.forEach((_, index) => {
            resultAnswers.push([staticAnswers[index]?.id, dynamicAnswers[index]?.id]);
        });
        onSelectCorrectAnswer(resultAnswers);
    }, [staticAnswers, dynamicAnswers]);

    return (
        <div className="h-100">
            {question && properties && (
                <TestContent background={background} withEditBorder={false}>
                    <TestConstructor>
                        <div className="animate__animated animate__fadeIn">
                            <Question
                                testHasNarrator
                                isEditMode={false}
                                text={questionText}
                                narrator={questionNarrator}
                                textTitleSize={questionSizeText}
                                image={questionImage}
                                audio={questionAudio}
                            />
                        </div>
                        <div className="mt-2 animate__animated animate__fadeIn">
                            <MDBAlert color="info" className="mt-3 text-center">
                                Двигайте элементы в правой колонке
                            </MDBAlert>
                            <TwoColumnAnswers>
                                <div className={styles.staticAnswers}>
                                    {staticAnswers.map((answer, index) => (
                                        <div
                                            key={index}
                                            className={styles.answerContainer}
                                            style={{height: `${answerSizeElement}px`}}
                                        >
                                            {answer.type === TEXT_ANSWER_PAIRS && (
                                                <Text
                                                    style={{fontSize: answerSizeText}}
                                                    text={answer.content}
                                                    withBorder={false}
                                                />
                                            )}
                                            {answer.type === IMAGE_FROM_BANK_ANSWER_PAIRS && (
                                                <Image imageUrl={answer.content} />
                                            )}
                                            {answer.type === IMAGE_FROM_UPLOAD_ANSWER_PAIRS && (
                                                <Image imageUrl={answer.content} />
                                            )}
                                            {answer.type === FORMULA_ANSWER_PAIRS && (
                                                <Formula formulaURI="" />
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.dynamicAnswers}>
                                    <DraggableList
                                        onSort={setDynamicAnswers}
                                        items={dynamicAnswers}
                                        pressDelay={0}
                                    >
                                        {dynamicAnswers.map((answer, index) => (
                                            <DraggableItem
                                                key={`item-${index}`}
                                                id={`item-${index}`}
                                                index={index}
                                                style={{zIndex: 999}}
                                            >
                                                <div
                                                    className={styles.answerContainer}
                                                    style={{height: `${answerSizeElement}px`}}
                                                >
                                                    {answer.type === TEXT_ANSWER_PAIRS && (
                                                        <Text
                                                            text={answer.content}
                                                            style={{fontSize: answerSizeText}}
                                                            withBorder={false}
                                                        />
                                                    )}
                                                    {answer.type ===
                                                        IMAGE_FROM_BANK_ANSWER_PAIRS && (
                                                        <Image imageUrl={answer.content} />
                                                    )}
                                                    {answer.type ===
                                                        IMAGE_FROM_UPLOAD_ANSWER_PAIRS && (
                                                        <Image imageUrl={answer.content} />
                                                    )}
                                                    {answer.type === FORMULA_ANSWER_PAIRS && (
                                                        <Formula formulaURI="" />
                                                    )}
                                                </div>
                                            </DraggableItem>
                                        ))}
                                    </DraggableList>
                                </div>
                            </TwoColumnAnswers>
                        </div>
                    </TestConstructor>
                </TestContent>
            )}
        </div>
    );
};

PairsPlayer.propTypes = {
    dynamicAnswers: PropTypes.array,
    properties: PropTypes.object,
    question: PropTypes.object,
    setDynamicAnswers: PropTypes.func,
    staticAnswers: PropTypes.array,
    onSelectCorrectAnswer: PropTypes.func,
};
