import React from "react";
import PropTypes from "prop-types";
import styles from "./add-content-box.module.sass";

export const AddContentBox = ({onClick, text}) => {
    return (
        <div className={styles.container} onClick={onClick}>
            <p className="link">{text}</p>
        </div>
    );
};

AddContentBox.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
};
