import {
    DICTATION_EMPTY_DETECT,
    DICTATION_SKIP_DETECT,
} from "entries/constructors/dictation/views/pages/dictation-page";
import React from "react";
import PropTypes from "prop-types";

import styles from "entries/constructors/dictation/views/containers/select-word-box/select-word-box.module.sass";

export const SelectWordBox = ({separatedText, onSelectWord, selectedWordIndex}) => {
    return (
        <div className={styles.container}>
            <div className={styles.textBox}>
                {separatedText.length > 0 &&
                    separatedText.map((word, index) => {
                        return (
                            <span
                                key={index}
                                onClick={() => onSelectWord(index)}
                                className={`${styles.word} ${
                                    selectedWordIndex === index ? styles.selectedWord : null
                                }`}
                            >
                                {word.split("").map((symbol, index) => {
                                    let symbolForView;
                                    if (
                                        symbol === DICTATION_SKIP_DETECT ||
                                        symbol === DICTATION_EMPTY_DETECT
                                    ) {
                                        symbolForView = (
                                            <span key={index} className="skipBox">
                                                {" "}
                                            </span>
                                        );
                                    } else {
                                        symbolForView = symbol;
                                    }
                                    return symbolForView;
                                })}{" "}
                            </span>
                        );
                    })}
            </div>
        </div>
    );
};

SelectWordBox.propTypes = {
    selectedWordIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    separatedText: PropTypes.array,
    skips: PropTypes.array,
    onSelectWord: PropTypes.func,
};
