import React, {useEffect, useState} from "react";
import {EventsAPI} from "../events/core/EventsAPI";
import {AxiosResponse} from "axios";
import {IPageable} from "../../interfaces/IPageable";
import {UserAPI} from "../../core/api/user";
import styles from "./styles.module.sass";
import SpinnerWithText from "../spinner/spinner";
import cn from "classnames";
import {url} from "../../config";
import {useStores} from "../../hooks/use-stores";
import AchievementModal from "../modals/achievement-modal/achievement-modal";
import {Roles} from "../../enums/roles";
import {Helmet} from "react-helmet";

const ReceivedAchievements = () => {
    const [availableAchievements, setAvailableAchievements] = useState<any>([]);
    const [receivedAchievementIds, setReceivedAchievementIds] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const {appStore, userStore} = useStores();

    useEffect(() => {
        getAchievementsData();
    }, []);

    const getAchievementsData = async () => {
        setIsLoading(true);
        await getAvailableAchievements();
        await getReceivedAchievements();
        setIsLoading(false);
    };

    const getAvailableAchievements = () => {
        let API;

        if (userStore.role === Roles.USER || userStore.role === Roles.STUDENT) {
            API = EventsAPI.getEventSetsStudent();
        } else if (userStore.role === Roles.TEACHER) {
            API = EventsAPI.getEventSetsTeacher();
        } else {
            API = EventsAPI.getEventSets();
        }

        return API.then((response: AxiosResponse<IPageable<any[]>>) => {
            let filteredAchievements = response.data?.content?.filter(
                (a) => (a as any).events.length,
            );
            setAvailableAchievements(filteredAchievements);
        });
    };

    const getReceivedAchievements = () => {
        return UserAPI.getMyEventLog().then((response: AxiosResponse<IPageable<any[]>>) => {
            let receivedAchievementsTemp = response.data.content;
            setReceivedAchievementIds(receivedAchievementsTemp?.map((item: any) => item.event?.id));
        });
    };

    if (isLoading) {
        return (
            <div className={styles.achievementWrapper}>
                <SpinnerWithText
                    text="Загрузка достижений..."
                    className="d-flex justify-content-center"
                />
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Все достижения</title>
                <meta name="description" content="Все достижения и награды сайта Умная Ворона" />
                <meta
                    name="keywords"
                    content="достижения награды получить рейтинг бонусы ученик учитель"
                />
            </Helmet>

            <div className={styles.achievementWrapper}>
                <div className={styles.achievementHeader}>
                    <p className={styles.achievementHeaderTitle}>Достижения</p>

                    {userStore.role === Roles.TEACHER ? (
                        <p className={styles.achievementHeaderDescription}>
                            Здесь вы найдете все достижения, которые уже получили и которые можете
                            получить на Умной Вороне
                        </p>
                    ) : (
                        <p className={styles.achievementHeaderDescription}>
                            Здесь ты найдешь все достижения, которые уже получил и которые можешь
                            получить на Умной Вороне
                        </p>
                    )}
                </div>
                {!!availableAchievements.length && (
                    <React.Fragment>
                        {availableAchievements.map((achievementGroup: any) => {
                            return (
                                <div
                                    key={achievementGroup.id}
                                    className={styles.achievementGroupWrapper}
                                >
                                    <p className={styles.title}>{achievementGroup.name}</p>
                                    <div className={styles.achievementGroup}>
                                        {achievementGroup.events?.map((achievement: any) => {
                                            return (
                                                <div
                                                    className={cn({
                                                        [styles.achievementItem]: true,
                                                        [styles.achievementReceived]:
                                                            receivedAchievementIds.includes(
                                                                achievement.id,
                                                            ),
                                                    })}
                                                    onClick={() => {
                                                        appStore.addModalToQueue(
                                                            <AchievementModal
                                                                toggle={appStore.closeActiveModal}
                                                                event={achievement}
                                                                key={"achievement-modal"}
                                                            />,
                                                        );
                                                    }}
                                                    key={achievement.id}
                                                >
                                                    <img
                                                        src={
                                                            url +
                                                            achievement.pictogram?.fileDownloadUri.slice(
                                                                1,
                                                            )
                                                        }
                                                        alt="achievement-logo"
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

export default ReceivedAchievements;
