import React from "react";
import PropTypes from "prop-types";

export const ContextMenu = (props) => {
    const {position, children} = props;

    return (
        <div
            className="menu"
            style={{
                position: "absolute",
                left: position.x,
                top: position.y,
            }}
        >
            {children}
        </div>
    );
};

ContextMenu.propTypes = {
    position: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),
};
