import {TOAST_ERROR_CONFIG} from "config";
import {
    ANSWERS,
    VARIANTS,
} from "entries/constructors/open-answer/views/modal-open-answer/modal-open-answer-container";
import Hints from "entries/hints/hints";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import {OpenAnswerPlayer} from "entries/player/views/task-players/open-answer-player/open-answer-player";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {copy, move, reorder} from "utils/dnd-helpers";

export const OpenAnswerPlayerContainer = ({
    taskId,
    onSelectCorrectAnswer,
    questionApi,
    getInfoAboutSet,
    playerMode,
    answerIsLoading,
    setAnswerIsLoading,
}) => {
    const [question, setQuestion] = useState(null);
    const [answers, setAnswers] = useState(null);
    const [properties, setProperties] = useState(null);

    const [variants, setVariants] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState([]);

    let {id} = useParams();

    useEffect(() => {
        setAnswers(null);
        setQuestion(null);
        setProperties(null);
        setSelectedAnswers([]);
    }, [taskId]);

    useEffect(() => {
        getData();
    }, [taskId]);

    const getData = async () => {
        setAnswerIsLoading(true);
        let data = undefined;
        await questionApi(taskId, id)
            .then((response) => {
                if (playerMode === PlayerV2Modes.TOURNAMENT) {
                    data = response.data[0].question;
                } else {
                    data = response.data;
                    getInfoAboutSet();
                }
            })
            .catch(() => console.log("load data error"))
            .finally(() => setAnswerIsLoading(false));

        if (!data) {
            return;
        }

        setQuestion(data.openAnswer);
        setAnswers(data.openAnswerAnswerVariants);
        setVariants(data.openAnswerAnswerVariants);
        setProperties(data.questionProperties);
    };

    const onDragEnd = (result) => {
        const {source, destination} = result;
        if (!destination) {
            return;
        }

        const list = {
            [VARIANTS]: variants,
            [ANSWERS]: selectedAnswers,
        };

        const getList = (id) => list[id];

        if (source.droppableId === ANSWERS && destination.droppableId === VARIANTS) {
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination,
                true,
            );

            setSelectedAnswers(result.droppableAnswerBox);
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(getList(source.droppableId), source.index, destination.index);

            if (source.droppableId === VARIANTS) {
                setVariants(items);
            } else {
                setSelectedAnswers(items);
            }
        } else {
            if (selectedAnswers.length > 9 && destination.droppableId === ANSWERS) {
                toast.error("Максимальное число символов - 10", TOAST_ERROR_CONFIG);
                return;
            }

            const result = copy(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination,
            );

            setSelectedAnswers(result);
        }
    };

    useEffect(() => {
        let answersForSave = {};
        selectedAnswers.forEach((answer, index) => {
            answersForSave[index] = answer.symbol.id;
        });

        onSelectCorrectAnswer(answersForSave);
    }, [selectedAnswers]);

    const fillCorrectAnswers = (answersForFill) => {
        setSelectedAnswers(answersForFill);
    };

    return (
        <React.Fragment>
            {!answerIsLoading && (
                <React.Fragment>
                    {playerMode === PlayerV2Modes.ACADEMY && (
                        <Hints question={question} onBuyCorrectAnswer={fillCorrectAnswers} />
                    )}

                    {question && answers && properties && (
                        <OpenAnswerPlayer
                            question={question}
                            variants={variants}
                            properties={properties}
                            setAnswers={setAnswers}
                            onDragEnd={onDragEnd}
                            selectedAnswers={selectedAnswers}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

OpenAnswerPlayerContainer.propTypes = {
    getInfoAboutSet: PropTypes.func,
    playerMode: PropTypes.string,
    questionApi: PropTypes.func,
    taskId: PropTypes.number,
    onSelectCorrectAnswer: PropTypes.func,
    answerIsLoading: PropTypes.bool,
    setAnswerIsLoading: PropTypes.func,
};
