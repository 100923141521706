import {Request} from "core/utils/request";

export const SubscriptionsAPI = {
    getSubscriptions: () => {
        return Request.get(`subscription?size=1000`);
    },
    giveSubscription: (id, subId) => {
        return Request.post(`super-admin/user/${id}/subscription/${subId}`);
    },
};
