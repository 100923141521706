import { Request } from "../utils/request";
import { ISchool } from "../../interfaces/ISchool";

export const SchoolAPI = {
    createSchool: (school: ISchool) => {
        return Request.post(`school`, school)
    },
    updateSchool: (school: ISchool) => {
        return Request.put(`school`, school)
    }
}
