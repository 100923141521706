import React from "react";

const DictationEngine = () => {
    return (
        <div>
            <div>
                <p>Текст с пропусками</p>
            </div>
        </div>
    );
};

export default DictationEngine;
