const PLAYER_MESSAGES = [
    {isCorrect: false, message: "А вот и нет!"},
    {isCorrect: false, message: "Попробуй ещё разок…"},
    {isCorrect: false, message: "Давай ещё попытку!"},
    {isCorrect: false, message: "Нам нужна помощь!"},
    {isCorrect: false, message: "Немного везения не помешало бы…"},
    {isCorrect: false, message: "Увы, нет…"},
    {isCorrect: false, message: "Не-а…"},
    {isCorrect: false, message: "Нет…"},
    {isCorrect: false, message: "Мимо…"},
    {isCorrect: false, message: "Что-то не то…"},
    {isCorrect: false, message: "Это неверно…"},
    {isCorrect: false, message: "Неверно…"},
    {isCorrect: false, message: "Ответ не подходит…"},
    {isCorrect: false, message: "Ну что же ты!"},
    {isCorrect: false, message: "Не сдавайся!"},
    {isCorrect: false, message: "Задача победила…"},
    {isCorrect: false, message: "Я бы так хотела помочь!"},
    {isCorrect: false, message: "Задача не решена."},
    {isCorrect: false, message: "Решение не подходит!"},
    {isCorrect: false, message: "Ключ не подходит."},
    {isCorrect: false, message: "Давай ещё раз!"},
    {isCorrect: false, message: "Соберись!"},
    {isCorrect: false, message: "Сконцентрируйся!"},
    {isCorrect: false, message: "Что-то пошло не так…"},
    {isCorrect: false, message: "О-о-о нет!"},
    {isCorrect: false, message: "Нетушки!"},
    {isCorrect: false, message: "Промах!"},
    {isCorrect: false, message: "Промашка вышла…"},
    {isCorrect: false, message: "Ошибочка вышла…"},
    {isCorrect: false, message: "Ошибка!"},
    {isCorrect: false, message: "Я знаю, что ты стараешься!"},
    {isCorrect: false, message: "Попробуй еще одну попытку!"},
    {isCorrect: false, message: "У тебя ещё есть попытки!"},
    {isCorrect: false, message: "Не всё так просто!"},
    {isCorrect: false, message: "Попытка не пытка!"},
    {isCorrect: false, message: "Попытайся ещё раз!"},
    {isCorrect: false, message: "Не зашло!"},
    {isCorrect: false, message: "Ах!"},
    {isCorrect: false, message: "Как мне жаль…"},
    {isCorrect: false, message: "Попробуй ещё!"},
    {isCorrect: false, message: "Нет, нет, нет…"},
    {isCorrect: false, message: "Я бы помогла! Но это нечестно."},
    {isCorrect: false, message: "А другие версии есть?"},
    {isCorrect: false, message: "Этот ответ не подходит…"},
    {isCorrect: false, message: "Ты где-то рядом!"},
    {isCorrect: false, message: "Отнюдь не верно."},
    {isCorrect: false, message: "Не в этот раз!"},
    {isCorrect: false, message: "Хорошая попытка, но нет…"},
    {isCorrect: false, message: "Хороший ответ! Но неверный…"},
    {isCorrect: false, message: "Почти!"},
    {isCorrect: false, message: "Так близко и так далеко…"},
    {isCorrect: false, message: "Маленькая неудача!"},
    {isCorrect: false, message: "Не расстраивайся!"},
    {isCorrect: false, message: "Реши ещё разок!"},
    {isCorrect: false, message: "Я в тебя верю!"},
    {isCorrect: false, message: "Я его тоже не сразу решила!"},
    {isCorrect: false, message: "Что-то не то…"},
    {isCorrect: false, message: "Решено! Но неверно."},
    {isCorrect: false, message: "Этот ответ мне не нравится."},
    {isCorrect: false, message: "А я ответила по-другому!"},
    {isCorrect: false, message: "Не могу принять этот ответ…"},
    {isCorrect: false, message: "Прости, но нет!"},
    {isCorrect: false, message: "Подумай ещё!"},
    {isCorrect: false, message: "Цель была близка!"},
    {isCorrect: false, message: "Не подходит!"},
    {isCorrect: false, message: "Очень жаль…"},
    {isCorrect: false, message: "Могло быть и хуже…"},
    {isCorrect: false, message: "Упс!"},
    {isCorrect: false, message: "Не туда!"},
    {isCorrect: false, message: "Спокойствие!"},
    {isCorrect: false, message: "Этого не может быть!"},
    {isCorrect: false, message: "Не хотела расстраивать, но…"},
    {isCorrect: false, message: "Тебе попался крепкий орешек!"},
    {isCorrect: false, message: "Исправляй скорее!"},
    {isCorrect: false, message: "Я тоже расстроена…"},
    {isCorrect: false, message: "Нет, неверно…"},
    {isCorrect: false, message: "Ну вот!"},
    {isCorrect: false, message: "А давай всё сначала?"},
    {isCorrect: false, message: "Не вышло!"},
    {isCorrect: true, message: "Неплохо!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Да, это верный ответ!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Вот теперь верно!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Уф-ф... справились!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Решено!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Не зря стараешься!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Дальше будет лучше!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Летим дальше!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Можешь и ещё лучше!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Решено не без труда!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Отыграемся на следующей!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Всё получится!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Молодец!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Исправляешься!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Летим к следующей задаче!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Не всё получается сразу!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Вижу, что стараешься!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Сложная была задачка!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Непростая загадка, да?", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Было непросто, но ты молодец!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Я болела за тебя!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Верно!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "А некоторые вообще не решили…", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Балл за терпение!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Балл за настойчивость!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Балл за волю к победе!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Этот балл получен не зря!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Этот балл дорогого стоит!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Я немного волновалась за тебя!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Мне нравится твоя настойчивость!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Кажется, всё хорошо!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Посмотрим на следующий вопрос?", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Вот это молодец!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Всегда старайся!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Заслуженный балл!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Какая интересная задача!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Ох уж эта задачка…", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Победа!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Бывает и так!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Сделано!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Мне бы твою настойчивость!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Молодец, что не сдаёшься!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Никогда не сдавайся!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Балл за упорство!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Хорошая попытка!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Удачная попытка!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Мне нравится твой энтузиазм!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Не сдавайся!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Да!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "С тобой интересно!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Пришлось попотеть!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Мне нравится!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Приятно решить!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Летим дальше!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Полетели!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Вот это точно!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Вот теперь всё на своих местах!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Поздравляю!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Уже лучше!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Другое дело!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Ура! Этот ответ подходит!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "На этот раз всё хорошо!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Сложное задание. Но ты молодец!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Намного лучше!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Вот это верно!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Главное вовремя собраться!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Цель поражена!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Каждый балл ценен!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Правильно! Никогда не сдавайся!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Удачная попытка!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Вот и всё! Летим дальше!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Молодец! Молодец!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Теперь ты знаешь ответ!", minRating: 1, maxRating: 1},
    {isCorrect: true, message: "Молодец!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Было непросто, да?", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Было нелегко?", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Решено и точка!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Между прочим, задача была непростой…", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Так её! Решено!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Я болела за тебя!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Поздравляю!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Сложная задача решена!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Помучились немножко!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "У тебя всё получилось!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Победа!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Пойдем скорее к следующей…", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Какая же тяжёлая задача!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Ты молодец!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Задача побеждена!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Хорошо!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Продолжай!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Ты молодчина!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Ты много знаешь!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Дай пять!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Непросто дается нам…", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Всегда верила в тебя!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Так точно!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Да!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Верно!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Молодчина!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "К следующей задаче!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Вот и всё!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Весьма недурно!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Успешно!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Успех!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Никогда не бросай начатое!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "На тебя можно положиться!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Горжусь тобой!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Ура!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Четверка с плюсом!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Всё решено верно!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Эту задача тебя не победит!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Задачка решена!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Тебе и не такое по плечу!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Молодец, всё верно!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Правильно!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Да, это верно!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Да, всё верно!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Точно!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "В точку!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Точно в десятку!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Точно в яблочко!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Решено без промаха!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Точно в цель!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Метко!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Верное решение!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Есть!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "То, что нужно!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "И я ответила так!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Хорошо!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Без ошибок!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Абсолютно верно!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Умница!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "А вот и награда!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Бум!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "И это верный ответ!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Согласна!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Ты знаешь ответ!", minRating: 2, maxRating: 4},
    {isCorrect: true, message: "Очень хорошо!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Неужели было так легко?", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Удивительно точно!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Так держать!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Круто!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Класс!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Не сбавляй обороты!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Мне понравилось!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Решено без проблем!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Принимай поздравления!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Да ты знаток!", minRating: 5, maxRating: 10},
    {
        isCorrect: true,
        message: "Как-то уж слишком легко у тебя получилось…",
        minRating: 8,
        maxRating: 13,
    },
    {isCorrect: true, message: "Мастер!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Очень шустро!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Ловко справляешься!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Отличное решение!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Хорошо справляешься!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Точно и быстро!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Абсолютно верно!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Легко!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Легко и просто!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Твоя победа!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Такие задачи нам по зубам!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Почему опять так легко!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Заслуженные баллы!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Да, всё отлично!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Всё просто супер!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Великолепно!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Повторим?", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Узнаю чемпионский настрой!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Браво!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Слишком легко?", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Умно!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Достойный результат!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Чудесно!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Всё получилось!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Путь к победе!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Ты меня впечатляешь!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Далеко пойдешь!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Впечатляет!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Мои поздравления!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Отличные знания!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Продолжаешь удивлять!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Я заметила твой талант!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Так держать!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Чудесно!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Хорошо решаешь!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Покоряешь мир!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Всё лучше и лучше!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Для тебя нет преград!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Твёрдая пятерка!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Исключительная точность!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Жирная пятёрка!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Блестяще!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Отлично!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Горжусь тобой!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Великолепная работа!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "С удовольствием ставлю тебе пять!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Довольно быстро!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Тебе везёт!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Всегда бы так!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Не могу не восхититься!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Работа настоящего профи!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Быстро и чётко!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Нечего добавить!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Ты в этом разбираешься!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Такие ответы мне нравятся!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Понравилась задачка?", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Отличная работа!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Не останавливайся!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Пятёрки не жалко!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Пять баллов!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Твой рейтинг подрос!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Это задание решили немногие!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Блестяще!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Высший класс!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Ты меня поражаешь!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Пятёрочка!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Очередная пятёрка!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Конечно, верно!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Впечатляющая точность!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Приятно получать правильные ответы!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Точнее точного!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Шустро! А главное точно.", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Рада сообщить!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "С радостью сообщаю!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Ура-а-а!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Прелестно! Это прелестно!", minRating: 5, maxRating: 10},
    {isCorrect: true, message: "Супер!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Вот это способности!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Невероятный ум!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Кажется, у тебя талант!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Немногим это удалось!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Очень круто!", minRating: 10, maxRating: 99},
    {
        isCorrect: true,
        message: "Эй! Я так долго её придумывала…",
        minRating: 10,
        maxRating: 99,
    },
    {
        isCorrect: true,
        message: "Между прочим, придумывать задачи сложно!",
        minRating: 10,
        maxRating: 99,
    },
    {isCorrect: true, message: "Ва-а-ау!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Такому уму можно позавидовать!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "А ведь задачка была…", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Круто, круто!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Вот этот круто!", minRating: 10, maxRating: 99},
    {
        isCorrect: true,
        message: "Сбавь обороты! Весь рейтинг соберешь;)",
        minRating: 10,
        maxRating: 99,
    },
    {isCorrect: true, message: "Да на тебя рейтинга не напасёшься!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Вот это результат!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Пушка!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Потрясающий результат!", minRating: 10, maxRating: 99},
    {
        isCorrect: true,
        message: "Эту задачу даже я бы не решила так хорошо!",
        minRating: 10,
        maxRating: 99,
    },
    {isCorrect: true, message: "Ты превзошёл даже мой результат!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "А ты точно не старшеклассник?", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Ты просто молния!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Вж-ж-жух и решено!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Волшебство!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Чемпионский результат!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Отличник, да?", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Великолепно!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Привет, большой мозг!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Возмутительно много баллов!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Много баллов!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Аплодисменты чемпиону!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Кто здесь самый лучший?", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Феноменально!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Превосходно!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Ты гений!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Высшая награда!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Близко к рекорду!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Супер-пупер!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "В этом ты лучше всех!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Ты победитель!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Это невероятно!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Вот это знания!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Медаль хочешь?", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Хочешь стать лучше всех?", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Вот это скорость!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Ты мой кумир!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Вершина мира!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Ты наша гордость!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Очень достойный результат!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Фантастический результат!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Ура! Ура! Ура!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Тебе нет равных!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Люблю решать с тобой задачи!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Дашь списать?", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Сколько же у тебя ума!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Я за тобой не успеваю!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Как тебе это удается?", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Лучше и быть не может!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Невообразимо круто!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Идеально!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Пять с огромным плюсом!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "За тобой не угнаться!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Пять! Пять! Пять!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Да!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Горжусь тобой!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Я твой фанат!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Эффектно!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Номер один!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Как много баллов!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Лучший результат!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Молниеносный ответ!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Грандиозно!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Было слишком легко?", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Потрясающе!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Настоящий профессионал!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Вот это результат!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Удивительный результат!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Посмотрите, какая скорость!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Безо всяких промедлений!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Супергеройский ответ!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Рейтинг резко подрос!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Твой рейтинг взлетает!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "На максималках!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Солидная прибавка к рейтингу!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "В этом тебе нет равных!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Этот ответ стоит дорого!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Нет слов!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "За тобой не угнаться!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Я знала, что ты так можешь!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Вот это ум!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Ты знаток!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Наилучший результат!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "В это трудно поверить!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Я и сама набрала меньше баллов!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Становится жарко!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Супер ответ!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Суперская скорость!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Разве можно так быстро?", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Это невозможно!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "У тебя всё так просто!", minRating: 10, maxRating: 99},
    {isCorrect: true, message: "Никаких сомнений, ты гений!", minRating: 10, maxRating: 99},
];

export const getRandomMessageByResult = (isCorrect, rating) => {
    let suitableMessages = PLAYER_MESSAGES.filter((message) => message.isCorrect === !!isCorrect);
    if (rating) {
        suitableMessages = suitableMessages.filter(
            (message) => message.minRating <= rating && message.maxRating >= rating,
        );
    }

    return suitableMessages[Math.floor(Math.random() * suitableMessages.length)]?.message;
};
