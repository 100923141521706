import React from "react";
import {MDBNavItem, MDBNavLink} from "mdbreact";
import VideoModal from "../../modals/video-modal/video-modal";
import cn from "classnames";
import styles from "./header.module.sass";
import {useStores} from "../../../hooks/use-stores";
import {auth} from "../../../core/utils/auth";
import {observer} from "mobx-react-lite";

const HeaderAuthItem = observer(() => {
    const {appStore} = useStores();
    const {keycloak} = auth;
    return (
        <React.Fragment>
            <MDBNavItem>
                <MDBNavLink
                    to="/"
                    onClick={() =>
                        appStore.addModalToQueue(
                            <VideoModal
                                toggle={appStore.closeActiveModal}
                                url="https://www.youtube.com/embed/bbYA6cXTgGQ"
                                key={"video-modal"}
                            />,
                        )
                    }
                    className={cn({
                        [styles.navLink]: true,
                    })}
                >
                    Как зарегистрироваться
                </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
                <MDBNavLink
                    to="/"
                    onClick={() => keycloak.login()}
                    className={cn({
                        [styles.navLink]: true,
                    })}
                >
                    Вход
                </MDBNavLink>
            </MDBNavItem>
        </React.Fragment>
    );
});

export default HeaderAuthItem;
