import React, {useEffect, useState} from "react";
import {AdminPanelSchoolsAPI} from "../admin-panel-schools-api";
import {toast} from "react-toastify";
import GenericTable from "../../../generic-table/generic-table";
import {IPageable} from "../../../../interfaces/IPageable";
import {ITempSchool} from "../../../../interfaces/ITempSchool";
import SpinnerWithText from "../../../spinner/spinner";
import {EColors} from "../../core/EColors";
import ModalLayout from "../../../modal-layout/modal-layout";
import AdminPanelSchoolsTempEdit from "./admin-panel-schools-temp-edit";
import {ISchool} from "../../../../interfaces/ISchool";
import {IUser} from "../../../../interfaces/IUser";
import {SchoolAPI} from "../../../../core/api/school-api";
import {AdminAPI} from "../../../../core/api/admin-api";
import {AxiosResponse} from "axios";
import {Switch} from "../../../../views/components/switch";
import styles from "./styles.module.sass";

const AdminPanelSchoolsTemp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [modalDataIsLoading, setModalDataIsLoading] = useState(false);
    const [schools, setSchools] = useState<IPageable<ITempSchool> | undefined>(undefined);
    const [page, setPage] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [dataForEdit, setDataForEdit] = useState<ITempSchool | undefined>(undefined);
    const [showHidden, setShowHidden] = useState(false);

    const handleUpdateSchool = (item: ITempSchool) => {
        setDataForEdit(item);
        setModalIsOpen(true);
    };

    const handleCreateNewSchool = (
        school: ISchool,
        user: IUser,
        withChangeSchool: boolean,
        requestId: number,
    ) => {
        setModalDataIsLoading(true);
        SchoolAPI.createSchool(school)
            .then(async (response: AxiosResponse<ISchool>) => {
                if (withChangeSchool) {
                    await AdminAPI.updateUserInfo(user.id, {
                        school: {id: response.data.id},
                    }).then(() =>
                        toast.success(
                            `Школа успешно создана, пользователь ${user.firstName} привязан к новой школе ${response.data.alternateName}`,
                        ),
                    );
                } else {
                    toast.success("Школа успешно создана");
                }

                await handleCloseUserRequest(requestId);

                setModalIsOpen(false);
                setDataForEdit(undefined);
            })
            .catch(() => toast.error("Ошибка создания"))
            .finally(() => {
                setModalDataIsLoading(false);
            });
    };

    const handleCloseUserRequest = (requestId: number) => {
        setModalDataIsLoading(true);
        return AdminPanelSchoolsAPI.editTempSchoolVisible(requestId, true)
            .then(() => {
                toast.success("Заявка закрыта");
                getTempSchools();
                setModalIsOpen(false);
            })
            .finally(() => {
                setModalDataIsLoading(false);
            });
    };

    const getTempSchools = () => {
        setIsLoading(true);
        AdminPanelSchoolsAPI.getTempSchools(page, showHidden)
            .then((response) => {
                setSchools(response.data);
            })
            .catch(() => toast.error("Ошибка получения школ"))
            .finally(() => setIsLoading(false));
    };

    const handleBindUserWithSchool = (userId: number, schoolId: number) => {
        setModalDataIsLoading(true);
        return AdminAPI.updateUserInfo(userId, {
            school: {id: schoolId},
        })
            .then(() => {
                getTempSchools();
                toast.success(`Ученик успешно привязан к новой школе`);
            })
            .finally(() => setModalDataIsLoading(false));
    };

    useEffect(() => {
        getTempSchools();
    }, [page, showHidden]);

    useEffect(() => {
        // update actual modal data

        if (!dataForEdit) {
            return;
        }

        let updatedRequestData = schools?.content?.find(
            (s: ITempSchool) => s.id === dataForEdit?.id,
        );

        if (updatedRequestData) {
            setDataForEdit(updatedRequestData);
        }
    }, [schools, dataForEdit]);

    return (
        <React.Fragment>
            {modalIsOpen && (
                <ModalLayout
                    title="Заявка на добавление школы"
                    toggle={() => setModalIsOpen(!modalIsOpen)}
                    isOpen={modalIsOpen}
                    size={"fluid"}
                >
                    <AdminPanelSchoolsTempEdit
                        tempSchool={dataForEdit}
                        handleCreateNewSchool={handleCreateNewSchool}
                        modalDataIsLoading={modalDataIsLoading}
                        handleCloseRequest={handleCloseUserRequest}
                        handleBindUserWithSchool={handleBindUserWithSchool}
                    />
                </ModalLayout>
            )}

            {isLoading ? (
                <SpinnerWithText text="Загрузка данных" />
            ) : (
                <React.Fragment>
                    {schools ? (
                        <React.Fragment>
                            <div className="d-flex align-items-center mb-3">
                                <Switch
                                    checked={showHidden}
                                    onChange={() => setShowHidden(!showHidden)}
                                    className={styles.switch}
                                />

                                <p className="mt-0">Закрытые заявки</p>
                            </div>

                            <GenericTable
                                headers={[
                                    "ID Заявки",
                                    "Название школы",
                                    "Регион",
                                    "Город",
                                    "ID",
                                    "Имя",
                                    "Фамилия",
                                ]}
                                outputKeys={[
                                    "id",
                                    "schoolName",
                                    "region.altName",
                                    "city.altName",
                                    "user.id",
                                    "user.firstName",
                                    "user.lastName",
                                ]}
                                data={schools.content}
                                page={page}
                                setPage={setPage}
                                totalElements={schools.totalElements}
                                controllers={[
                                    {
                                        colorActive: EColors.PURPLE,
                                        colorInactive: "gray",
                                        action: handleUpdateSchool,
                                        icon: "fa-solid fa-pen-to-square",
                                    },
                                ]}
                            />
                        </React.Fragment>
                    ) : (
                        <p>Данных нет</p>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default AdminPanelSchoolsTemp;
