import {url} from "config";
import {DEFAULT_FONT_SIZE} from "config/project";
import {MDBAlert} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {NumericSequenceContainer, NumericSequenceItem} from "views/components/NumericSequence";
import {Question} from "views/components/question";

export const ImageSequencePlayer = ({
    question,
    answers,
    properties,
    onSelectCorrectAnswer,
    indexes,
}) => {
    // данные для вопроса
    const [questionText, setQuestionText] = useState("");
    const [questionNarrator, setQuestionNarrator] = useState({});
    const [questionImage, setQuestionImage] = useState({});
    const [questionAudio, setQuestionAudio] = useState(null);

    // параметры
    const [questionSizeText, setQuestionSizeText] = useState(DEFAULT_FONT_SIZE);
    const [answerSize, setAnswerSize] = useState(DEFAULT_FONT_SIZE);
    const [background, setBackground] = useState("");

    useEffect(() => {
        if (question) {
            // вопрос
            if (question.questionText) {
                setQuestionText(question.questionText);
            }

            if (question.imageQuestion) {
                setQuestionImage({
                    id: question.imageQuestion.id,
                    url: question.imageQuestion.fileDownloadUri.slice(1),
                });
            }

            if (question.audio) {
                setQuestionAudio(question.audio);
            }
        }
    }, [question, questionSizeText]);

    useEffect(() => {
        if (properties) {
            // фон
            if (properties.backgroundImage) {
                setBackground(properties.backgroundImage.fileDownloadUri.slice(1));
            } else {
                setBackground("data/image-bank/ae86cc0f-5bc8-457f-98bb-454a0ea993a6.svg");
            }

            // персонаж
            if (properties.narrator) {
                let narratorUrl = `${url}${properties.narrator.image.fileDownloadUri.slice(1)}`;
                setQuestionNarrator({url: narratorUrl});
            }

            // размер шрифта вопроса
            if (properties.sizeTitle) {
                setQuestionSizeText(properties.sizeTitle);
            }

            // размер ответов
            if (properties.sizeImage) {
                setAnswerSize(properties.sizeImage);
            }
        }
    }, [properties]);

    return (
        <div className="h-100">
            {question && answers && properties && (
                <TestContent background={background} withEditBorder={false}>
                    <TestConstructor>
                        <div className="animate__animated animate__fadeIn">
                            <Question
                                testHasNarrator
                                isEditMode={false}
                                text={questionText}
                                narrator={questionNarrator}
                                textTitleSize={questionSizeText}
                                image={questionImage}
                                audio={questionAudio}
                            />
                        </div>
                        <div className="mt-2 animate__animated animate__fadeIn">
                            <MDBAlert color="info" className="mt-3 text-center">
                                Кликайте на варианты ответов
                            </MDBAlert>

                            <NumericSequenceContainer
                                onChange={(answers) => onSelectCorrectAnswer(answers)}
                                initIndexes={indexes}
                            >
                                {answers?.map((item, index) => (
                                    <NumericSequenceItem
                                        key={index}
                                        id={item.id}
                                        isEditMode={false}
                                        elementSize={answerSize}
                                    >
                                        {item.component}
                                    </NumericSequenceItem>
                                ))}
                            </NumericSequenceContainer>
                        </div>
                    </TestConstructor>
                </TestContent>
            )}
        </div>
    );
};

ImageSequencePlayer.propTypes = {
    answers: PropTypes.array,
    correctAnswers: PropTypes.array,
    properties: PropTypes.object,
    question: PropTypes.object,
    onSelectCorrectAnswer: PropTypes.func,
};
