import React from "react";
import styles from "./styles.module.sass";
import {useHistory} from "react-router-dom";

const ArticleFooterBanner = () => {
    const history = useHistory();

    return (
        <div className={styles.bannerWrapper} onClick={() => history.push("/")}>
            <img
                className={styles.bannerImage}
                src={require("../../../assets/images/article/article-banner.png")}
                alt="banner"
            />
        </div>
    );
};

export default ArticleFooterBanner;
