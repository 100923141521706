import cn from "classnames";
import {url} from "config";
import {DEFAULT_FONT_SIZE} from "config/project";
import DndDroppableBox from "feauters/clipping-pictures-engine/dnd-droppable-box/dnd-droppable-box";
import DraggableBox from "feauters/clipping-pictures-engine/draggable-box/draggable-box";
import {ItemTypes} from "feauters/clipping-pictures-engine/types";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {IMAGE_TYPE, TEXT_TYPE} from "utils/answers-config";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {Question} from "views/components/question";
import styles from "./styles.module.sass";

const ClippingPlayer = ({
    question,
    properties,
    answers,
    handleDropToAnswers,
    handleDropToClip,
    clips,
}) => {
    // данные для вопроса
    const [questionImage, setQuestionImage] = useState({});
    const [questionText, setQuestionText] = useState("");
    const [questionNarrator, setQuestionNarrator] = useState({});

    // параметры
    const [questionSizeText, setQuestionSizeText] = useState(DEFAULT_FONT_SIZE);
    const [answerSizeText, setAnswerSizeText] = useState(DEFAULT_FONT_SIZE);
    const [answerImageSize, setAnswerImageSize] = useState(null);
    const [background, setBackground] = useState("");
    const [isRoundedAnswer, setIsRoundedAnswer] = useState(false);

    useEffect(() => {
        // применяем параметры
        if (properties) {
            // персонаж вопроса
            if (properties.narrator && properties.narrator.image) {
                let narratorUrl = `${url}${properties.narrator.image.fileDownloadUri.slice(1)}`;
                setQuestionNarrator({url: narratorUrl});
            }

            // размер шрифта заголовка
            if (properties.sizeTitle) {
                setQuestionSizeText(properties.sizeTitle);
            }

            // размер шрифта заголовка
            if (properties.sizeTitle) {
                setQuestionSizeText(properties.sizeTitle);
            }

            // размер шрифта ответа
            if (properties.sizeText) {
                setAnswerSizeText(properties.sizeText);
            }

            // размер картинок
            if (properties.sizeImage) {
                setAnswerImageSize(properties.sizeImage);
            }

            // отображение фона
            if (properties.backgroundImage) {
                setBackground(properties.backgroundImage.fileDownloadUri.slice(1));
            } else {
                setBackground("data/image-bank/ae86cc0f-5bc8-457f-98bb-454a0ea993a6.svg");
            }

            if (properties.isRoundedAnswer) {
                setIsRoundedAnswer(properties.isRoundedAnswer);
            }
        }
    }, [properties]);

    useEffect(() => {
        // заполянем вопрос
        if (question) {
            // текст вопроса
            if (question.questionText) {
                setQuestionText(question.questionText);
            }

            // картинка к вопросу
            if (question.imageQuestion) {
                setQuestionImage({
                    id: question.imageQuestion.id,
                    url: question.imageQuestion.fileDownloadUri.slice(1),
                });
            }
        }
    }, [question, questionSizeText]);

    const backgroundStyle = {
        backgroundImage: questionImage && `url(${url + questionImage.url})`,
        backgroundRepeat: "no-repeat",
    };

    return (
        <div
            className={cn({
                [styles.clipping]: true,
                noselect: true,
            })}
        >
            <TestContent background={background} withEditBorder={false} isFullWidth>
                <TestConstructor size="full">
                    <div className="animate__animated animate__fadeIn" style={{marginBottom: 15}}>
                        <div className={styles.questionWrapper}>
                            <Question
                                testHasNarrator
                                isEditMode={false}
                                text={questionText}
                                image={null}
                                narrator={questionNarrator}
                                textTitleSize={questionSizeText}
                            />
                        </div>

                        <div className={styles.playground} style={backgroundStyle}>
                            {clips.map((clip) => {
                                return (
                                    <DndDroppableBox
                                        key={clip.id}
                                        className={cn({
                                            [styles.rounded]: isRoundedAnswer,
                                            [styles.clip]: true,
                                            [answerImageSize]: answers[0].type === IMAGE_TYPE,
                                        })}
                                        acceptType={ItemTypes.CLIP}
                                        handleDrop={handleDropToClip}
                                        style={{
                                            top: clip.top,
                                            left:
                                                answers[0].type === TEXT_TYPE
                                                    ? clip.left - 25
                                                    : clip.left,
                                            fontSize: answerSizeText,
                                            height: answers[0].type === TEXT_TYPE && 50,
                                        }}
                                        targetId={clip.id}
                                    >
                                        <div
                                            className={cn({
                                                [styles.rounded]: isRoundedAnswer,
                                            })}
                                        >
                                            {clip.content}
                                        </div>
                                    </DndDroppableBox>
                                );
                            })}
                        </div>

                        <DndDroppableBox
                            acceptType={ItemTypes.CLIP}
                            handleDrop={handleDropToAnswers}
                            className={styles.answersContainer}
                        >
                            <p className="text-muted">Перетаскивайте ответы отсюда</p>
                            <div className={styles.answers}>
                                {answers.map((answer) => {
                                    if (!answer.isAvailable) {
                                        return;
                                    }

                                    return (
                                        <DraggableBox
                                            key={answer.id}
                                            isFromAnswerBox={true}
                                            className={cn({
                                                [styles.answer]: true,
                                                [styles.rounded]: isRoundedAnswer,
                                            })}
                                            id={answer.id}
                                        >
                                            {answer.isAvailable && (
                                                <React.Fragment>
                                                    {answer.type === IMAGE_TYPE ? (
                                                        <img
                                                            className={cn({
                                                                [answerImageSize]: true,
                                                                [styles.imageAnser]: true,
                                                                [styles.rounded]: isRoundedAnswer,
                                                            })}
                                                            src={answer.url}
                                                            alt="answer"
                                                        />
                                                    ) : (
                                                        <p
                                                            className={cn({
                                                                [styles.textAnswer]: true,
                                                            })}
                                                            style={{
                                                                fontSize: answerSizeText,
                                                                height: 50,
                                                            }}
                                                        >
                                                            {answer.text}
                                                        </p>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </DraggableBox>
                                    );
                                })}
                            </div>
                        </DndDroppableBox>
                    </div>
                </TestConstructor>
            </TestContent>
        </div>
    );
};

ClippingPlayer.propTypes = {
    answers: PropTypes.array,
    clips: PropTypes.array,
    handleDropToAnswers: PropTypes.func,
    handleDropToClip: PropTypes.func,
    properties: PropTypes.object,
    question: PropTypes.object,
};

export default ClippingPlayer;
