import {GeoAPI} from "entries/geo-selects/core/GeoAPI";
import GeoSelectCity from "entries/geo-selects/views/geo-select-city/geo-select-city";
import React from "react";
import PropTypes from "prop-types";

const GeoSelectCityContainer = ({regionId, handleSelect, selectedCity}) => {
    async function loadOptions(searchValue, loadedOptions, {page}) {
        if (searchValue?.length > 0 && searchValue?.length < 2) {
            return {
                options: [],
            };
        }

        const response = await GeoAPI.getCities(regionId, page, searchValue);
        return {
            options: response.data.content.map((item) => {
                return {
                    value: item.geoNameId,
                    label: item.altName ? item.altName : item.name,
                };
            }),
            hasMore: !response.data.last,
            additional: {
                page: page + 1,
            },
        };
    }

    return (
        <GeoSelectCity
            loadOptions={loadOptions}
            handleSelect={handleSelect}
            regionId={regionId}
            selectedCity={selectedCity}
        />
    );
};

GeoSelectCityContainer.propTypes = {
    handleSelect: PropTypes.func,
    regionId: PropTypes.number,
    selectedCity: PropTypes.object,
};

export default GeoSelectCityContainer;
