import React, {useEffect, useState} from "react";
import {IGameItem} from "../../../../interfaces/IGameItem";
import ModalWrapper from "../../../modals/modal-wrapper/modal-wrapper";
import styles from "./styles.module.sass";
import {Request} from "../../../../core/utils/request";
import {toast} from "react-toastify";
import {Button, Form, InputNumber, Select, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";

interface IItemModal {
    handleClose: () => void;
    item?: IGameItem;
    handleRefresh: () => void;
}

const AdminPanelBoxItem = ({item, handleRefresh, handleClose}: IItemModal) => {
    const [, setSettings] = useState<any>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [groupOptions, setGroupOptions] = useState<any>([]);
    const [boxSettings, setBoxSettings] = useState<any>(undefined);

    const getGroups = () => {
        setIsLoading(true);
        Request.get("/game-item-group?size=9999")
            .then((response) => {
                let groupOptions = response.data?.content?.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
                setGroupOptions([...groupOptions, {value: "EMPTY", label: "Без группы"}]);
            })
            .finally(() => setIsLoading(false));
    };

    const getBoxSettings = () => {
        Request.get(`/game-item/${item?.id}/game-box-settings`)
            .then((response) => {
                let sortedData = response.data?.sort(
                    (a: any, b: any) => a.randomFrom - b.randomFrom,
                );
                setBoxSettings(sortedData);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getGroups();
    }, []);

    useEffect(() => {
        if (!item) {
            return;
        }

        if (item?.gameItemType?.name === "BOX") {
            getBoxSettings();
        } else {
            toast.error("Настройки не загружены т.к предмет не BOX");
        }
    }, [item]);

    const handleSaveSettings = (form: any) => {
        setIsLoading(true);
        if (form.gameItemGroup === "EMPTY") {
            delete form["gameItemGroup"];
        } else {
            if (form.gameItemGroup) {
                form.gameItemGroup = {id: form.gameItemGroup.id || form.gameItemGroup};
            }
        }

        Request.post(`/game-item/${item?.id}/game-box-settings`, form)
            .then((response) => {
                setSettings(response.data);
                handleRefresh();
                getBoxSettings();
            })
            .catch(() => {
                toast.error("Ошибка при сохранении настроек");
            })
            .finally(() => setIsLoading(false));
    };

    const columns: ColumnsType<any> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: "Группа",
            dataIndex: "groupId",
            key: "groupId",
            render: (_: any, record: any) => <a>{record?.gameItemGroup?.name}</a>,
        },
        {
            title: "Рандом от",
            dataIndex: "randomFrom",
            key: "randomFrom",
        },
        {
            title: "Рандом до",
            dataIndex: "randomTo",
            key: "randomTo",
        },
        {
            title: "Мин. монет",
            dataIndex: "moneyMin",
            key: "moneyMin",
        },
        {
            title: "Макс. монет",
            dataIndex: "moneyMax",
            key: "moneyMax",
        },
        {
            title: "Действия",
            key: "action",
            render: (_: any, record: any) => (
                <Space size="middle">
                    <a onClick={() => handleDeleteSettings(record.id)}>
                        <i className="fas fa-trash" style={{color: "red"}} />
                    </a>
                </Space>
            ),
        },
    ];

    const handleDeleteSettings = (settingsId: number) => {
        setIsLoading(true);
        Request.delete(`/game-box-settings/${settingsId}`)
            .then((response) => {
                setSettings(response.data);
                handleRefresh();
                getBoxSettings();
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <ModalWrapper
            withHeader={true}
            headerTitle={"Настройки коробки"}
            onClose={handleClose}
            width={800}
        >
            <div className={styles.container}>
                {item ? (
                    <React.Fragment>
                        {item.gameItemType?.name !== "BOX" ? (
                            <p>Тип предмета должен быть BOX</p>
                        ) : (
                            <React.Fragment>
                                <Form
                                    layout="horizontal"
                                    style={{padding: "20px"}}
                                    form={form}
                                    onFinish={handleSaveSettings}
                                >
                                    <Form.Item name="randomFrom" label="Рандом от">
                                        <InputNumber />
                                    </Form.Item>

                                    <Form.Item name="randomTo" label="Рандом до">
                                        <InputNumber />
                                    </Form.Item>

                                    <Form.Item name="moneyMin" label="Мин. монет">
                                        <InputNumber />
                                    </Form.Item>

                                    <Form.Item name="moneyMax" label="Макс. монет">
                                        <InputNumber />
                                    </Form.Item>

                                    <Form.Item name="gameItemGroup" label="Группа">
                                        <Select
                                            loading={isLoading}
                                            options={groupOptions}
                                            style={{width: "100%"}}
                                        />
                                    </Form.Item>

                                    <Button htmlType="submit" type="primary" disabled={isLoading}>
                                        Добавить
                                    </Button>

                                    <Table
                                        columns={columns}
                                        dataSource={boxSettings}
                                        loading={isLoading}
                                        rowKey="id"
                                        pagination={false}
                                    />
                                </Form>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <p>Ошибка при выборе предмета.</p>
                    </React.Fragment>
                )}
            </div>
        </ModalWrapper>
    );
};

export default AdminPanelBoxItem;
