import cn from "classnames";
import React, {useEffect, useState} from "react";
import {animated, useSpring} from "react-spring";
import styles from "./styles.module.sass";
import PropTypes from "prop-types";

const Carousel = ({slides}) => {
    const [sliders, setSliders] = useState(slides || []);
    const [selectedSlide, setSelectedSlide] = useState(0);

    useEffect(() => {
        setSliders(slides);
    }, [slides]);

    const [animate] = useSpring(() => ({
        from: {opacity: 0},
        to: {opacity: 1},
        config: {duration: 500},
    }));
    const [animateBackSlide] = useSpring(() => ({
        from: {opacity: 0},
        to: {opacity: 0.7},
        config: {duration: 1400},
    }));

    useEffect(() => {}, [selectedSlide]);

    const handleNext = () => {
        if (sliders[selectedSlide + 1]?.content) {
            setSelectedSlide(selectedSlide + 1);
        } else {
            setSelectedSlide(0);
        }
    };

    const handlePreviously = () => {
        if (sliders[selectedSlide - 1]?.content) {
            setSelectedSlide(selectedSlide - 1);
        } else {
            setSelectedSlide(sliders.length - 1);
        }
    };

    return (
        <div className={styles.carouselWrapper}>
            <div className={styles.carousel}>
                <animated.div
                    className={cn({
                        [styles.slide]: true,
                        [styles.backSlide]: true,
                        [styles.backSlideLeft]: true,
                    })}
                    style={animateBackSlide}
                    onClick={handlePreviously}
                >
                    {sliders[selectedSlide - 1]?.content
                        ? sliders[selectedSlide - 1].content
                        : sliders[sliders.length - 1].content}
                </animated.div>

                <animated.div
                    className={cn({
                        [styles.slide]: true,
                        [styles.active]: true,
                    })}
                    style={animate}
                >
                    {sliders[selectedSlide]?.content}

                    <i
                        className={cn({
                            [styles.leftArrow]: true,
                            ["fas fa-chevron-left"]: true,
                        })}
                        onClick={handlePreviously}
                    />

                    <i
                        className={cn({
                            [styles.rightArrow]: true,
                            ["fas fa-chevron-right"]: true,
                        })}
                        onClick={handleNext}
                    />
                </animated.div>

                <animated.div
                    className={cn({
                        [styles.slide]: true,
                        [styles.backSlide]: true,
                        [styles.backSlideRight]: true,
                    })}
                    style={animateBackSlide}
                    onClick={handleNext}
                >
                    {sliders[selectedSlide + 1]?.content
                        ? sliders[selectedSlide + 1]?.content
                        : sliders[0]?.content}
                </animated.div>
            </div>
        </div>
    );
};

Carousel.propTypes = {
    slides: PropTypes.array,
};

export default Carousel;
