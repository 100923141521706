import {TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG} from "config";
import {
    BASKETS_TEST,
    CLASSIC_TEST,
    CLIPPING_SECONDARY_TEST,
    CLIPPING_TEST,
    DICTATION_TEST,
    DRAGGING_TEST,
    OPEN_ANSWER_TEST,
    PAIRS_TEST,
    SEQUENCES_IMAGE_TEST,
    SEQUENCES_TEXT_TEST,
    WORD_MANY_SKIP_TEST,
    WORD_SKIP_TEST,
} from "config/constructor-names";
import {DEFAULT_FONT_SIZE} from "config/project";
import {QuestionAPI} from "core/api/question";
import {BasketsAPI} from "entries/constructors/baskets-game/core/BasketsAPI";
import {ClippingAPI} from "entries/constructors/clipping/core/ClippingAPI";
import {MDBBtn} from "mdbreact";

import PropTypes from "prop-types";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {SIZE_S} from "utils/answers-config";
import {ClippingSecondaryAPI} from "../../../entries/constructors/clipping/core/ClippingSecondaryAPI";

const TestSave = (props) => {
    const {
        questionId,
        narratorId,
        backgroundId,
        sizeTitle,
        sizeText,
        sizeImage,
        onSaveProperties,
        isTwoColumnMode,
        apiInstance,
        answersIdsForSave,
        answerSize,
        constructorName,
        pairsAnswers,
        answersForSave,
        isRoundedAnswer,
        mode,
    } = props;

    const isDevelopment = process.env.NODE_ENV === "development";
    const [isLoading, setIsLoading] = useState(false);

    const properties = {
        sizeTitle: sizeTitle ? sizeTitle : DEFAULT_FONT_SIZE,
        sizeText: sizeText ? sizeText : DEFAULT_FONT_SIZE,
        sizeImage: sizeImage ? sizeImage : answerSize ? answerSize : SIZE_S,
        isShortContainer: isTwoColumnMode,
        isRoundedAnswer: isRoundedAnswer,
        levelAccess: mode,
    };

    const savePairs = () => {
        const staticAnswers = pairsAnswers.staticAnswers;
        const dynamicAnswers = pairsAnswers.dynamicAnswers;

        if (staticAnswers.length !== dynamicAnswers.length) {
            isDevelopment && console.log("Arrays are not equal");
            toast.error("Пары не равны", TOAST_ERROR_CONFIG);
            setIsLoading(false);
            return Promise.reject();
        }

        const resultAnswers = [];

        //Формируем пары ответов
        staticAnswers.forEach((_, index) => {
            resultAnswers.push([staticAnswers[index].id, dynamicAnswers[index].id]);
        });

        return apiInstance.addCorrectAnswer(questionId, resultAnswers);
    };

    const saveClassic = async () => {
        if (answersIdsForSave && answersIdsForSave.length > 0) {
            await answersIdsForSave.forEach((answerId) => {
                apiInstance.addCorrectAnswer(questionId, answerId);
            });
            return Promise.resolve();
        } else {
            isDevelopment && console.log("Answers ids empty");
            toast.error("Правильные ответы не выбраны");
            setIsLoading(false);
            return Promise.reject("");
        }
    };

    const saveOpenAnswer = () => {
        if (answersIdsForSave && answersIdsForSave.length > 0) {
            let hashMapAnswer = {};
            answersIdsForSave.map((id, index) => {
                hashMapAnswer[index] = id;
            });

            return apiInstance.addCorrectAnswers(questionId, hashMapAnswer);
        } else {
            isDevelopment && console.log("Answers ids empty");
            toast.error("Не добавлены ответы");
            setIsLoading(false);
            return Promise.reject("");
        }
    };

    const saveSequence = async () => {
        if (answersIdsForSave && answersIdsForSave.length > 0) {
            if (constructorName === SEQUENCES_IMAGE_TEST) {
                await apiInstance.editQuestion(questionId, {isTextAnswer: false});
            }
            return apiInstance.addCorrectAnswers(questionId, answersIdsForSave);
        } else {
            isDevelopment && console.log("Answers ids empty");
            toast.error("Не добавлены ответы");
            setIsLoading(false);
            return Promise.reject("");
        }
    };

    const saveWordSkip = async () => {
        if (constructorName === WORD_SKIP_TEST) {
            await apiInstance.deleteCorrectAnswers(questionId);
        }

        if (answersForSave) {
            return apiInstance.addCorrectAnswers(questionId, answersForSave);
        }

        return Promise.resolve();
    };

    const saveBaskets = async () => {
        if (!answersForSave || !answersForSave.length) return;

        //обновление позиций
        let promises = [];

        //ответы
        let hashMap = {};
        answersForSave.forEach((basket) => {
            promises.push(
                BasketsAPI.updateBasket(questionId, {
                    height: basket.height,
                    width: basket.width,
                    x: basket.x,
                    y: basket.y,
                    id: basket.id,
                }),
            );
            hashMap[basket.id] = basket.answers.map((answer) => answer.id);
        });

        await Promise.all(promises);

        return BasketsAPI.saveCorrectAnswers(questionId, hashMap);
    };

    const saveClipping = () => {
        let answers = answersForSave.boxes.map((box) => {
            return {
                x: box.left,
                y: box.top,
                center: box.center,
                answerVariantId: box.id,
            };
        });

        return ClippingAPI.saveCorrectAnswers(questionId, answers);
    };

    const saveSecondaryClipping = () => {
        let answers = answersForSave.boxes.map((box) => {
            return {
                x: box.left,
                y: box.top,
                center: box.center,
                answerVariantId: box.id,
            };
        });

        return ClippingSecondaryAPI.saveCorrectAnswers(questionId, answers);
    };

    const saveAnswers = () => {
        if (constructorName === PAIRS_TEST) {
            return savePairs();
        } else if (constructorName === CLASSIC_TEST) {
            return saveClassic();
        } else if (
            constructorName === SEQUENCES_TEXT_TEST ||
            constructorName === SEQUENCES_IMAGE_TEST
        ) {
            return saveSequence();
        } else if (constructorName === OPEN_ANSWER_TEST) {
            return saveOpenAnswer();
        } else if (constructorName === WORD_SKIP_TEST || constructorName === DICTATION_TEST) {
            return saveWordSkip();
        } else if (constructorName === BASKETS_TEST) {
            return saveBaskets();
        } else if (constructorName === CLIPPING_TEST) {
            return saveClipping();
        } else if (constructorName === CLIPPING_SECONDARY_TEST) {
            return saveSecondaryClipping();
        }

        return Promise.resolve("");
    };

    const saveTest = () => {
        setIsLoading(true);
        QuestionAPI.updateProperties(questionId, properties)
            .then(() => {
                //Загрузка фона
                if (backgroundId) {
                    return QuestionAPI.updateBackground(questionId, backgroundId).catch((error) => {
                        isDevelopment && console.log("Background loading error", error);
                        toast.error("Ошибка загрузки фона.", TOAST_ERROR_CONFIG);
                        setIsLoading(false);
                    });
                }
                return Promise.resolve("");
            })
            .then(() => {
                //Загрузка персонажа
                if (narratorId) {
                    return QuestionAPI.updateNarrator(questionId, narratorId).catch((error) => {
                        isDevelopment && console.log("Narrator loading error", error);
                        toast.error("Ошибка загрузки персонажа.", TOAST_ERROR_CONFIG);
                        setIsLoading(false);
                    });
                } else {
                    toast.error("Персонаж не добавлен", TOAST_ERROR_CONFIG);
                    setIsLoading(false);
                    return Promise.reject("");
                }
            })
            .then(() => {
                if (constructorName) {
                    //Очищаем предыдущие ответы
                    if (
                        constructorName !== WORD_MANY_SKIP_TEST &&
                        constructorName !== WORD_SKIP_TEST &&
                        constructorName !== DRAGGING_TEST
                    ) {
                        if (constructorName === BASKETS_TEST && !answersForSave.length) return;
                        return apiInstance.deleteCorrectAnswers(questionId).catch((error) => {
                            isDevelopment && console.log("Correct answer deleting error", error);
                            toast.error("Ошибка при чистке вопросов", TOAST_ERROR_CONFIG);
                            setIsLoading(false);
                        });
                    } else {
                        return Promise.resolve("");
                    }
                } else {
                    isDevelopment && console.log("Constructor name is undefined");
                    toast.error("Название конструктора не задано");
                    setIsLoading(false);
                    return Promise.reject("");
                }
            })
            .then(() => {
                saveAnswers()
                    .then(() => {
                        toast.success("Тест успешно загружен", TOAST_SUCCESS_CONFIG);
                        setIsLoading(false);
                        onSaveProperties && onSaveProperties();
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsLoading(false);
                        toast.error("Ошибка сохранения", TOAST_ERROR_CONFIG);
                    });
            });
    };

    return (
        <MDBBtn onClick={saveTest} disabled={isLoading} color="success" className="mt-3">
            Сохранить ответ
        </MDBBtn>
    );
};

export default TestSave;

TestSave.propTypes = {
    answersForSave: PropTypes.any,
    answersIdsForSave: PropTypes.array,
    answerSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    apiInstance: PropTypes.object,
    backgroundId: PropTypes.number,
    constructorName: PropTypes.string,
    isRoundedAnswer: PropTypes.bool,
    isTwoColumnMode: PropTypes.bool,
    mode: PropTypes.string,
    narratorId: PropTypes.any,
    pairsAnswers: PropTypes.object,
    questionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sizeImage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sizeText: PropTypes.number,
    sizeTitle: PropTypes.number,
    onSaveProperties: PropTypes.func,
};
