import cn from "classnames";
import PropTypes from "prop-types";
import React, {Fragment} from "react";
import Pagination from "react-js-pagination";
import {ItemTest} from "views/components/items-for-list/item-test";
import {ItemTheme} from "views/components/items-for-list/item-theme";
import {SpinnerPage} from "views/components/spinner";
import styles from "./list-with-pagination.module.sass";

export const ListWithPagination = props => {
    const {
        dataForList,
        activePage,
        totalItemsCount,
        handlePageChange,
        handleItemDelete,
        handleItemEdit,
        listType,
    } = props;

    return (
        <>
            {dataForList ? (
                <div className={styles.container}>
                    <div>
                        {dataForList.map(item => (
                            <Fragment key={item.id}>
                                {listType === "Test" && (
                                    <ItemTest
                                        test={item}
                                        handleTestDelete={handleItemDelete}
                                        handleTestEdit={handleItemEdit}
                                    />
                                )}

                                {listType === "Theme" && (
                                    <ItemTheme
                                        theme={item}
                                        handleThemeDelete={handleItemDelete}
                                        handleThemeEdit={handleItemEdit}
                                    />
                                )}
                            </Fragment>
                        ))}
                    </div>

                    <Pagination
                        hideNavigation
                        activePage={activePage}
                        totalItemsCount={totalItemsCount}
                        itemsCountPerPage={10}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass={cn({
                            pagination: true,
                            "pg-blue": true,
                            [styles.pagination]: true,
                        })}
                    />
                </div>
            ) : (
                <SpinnerPage primary />
            )}
        </>
    );
};

ListWithPagination.propTypes = {
    activePage: PropTypes.number,
    dataForList: PropTypes.array,
    handleItemDelete: PropTypes.func,
    handleItemEdit: PropTypes.func,
    handlePageChange: PropTypes.func,
    listType: PropTypes.oneOf(["Test", "Theme"]),
    totalItemsCount: PropTypes.number,
};
