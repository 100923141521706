import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.sass";

import SwiperCore, {Mousewheel, Pagination} from "swiper";
import "swiper/components/pagination/pagination.min.css";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/swiper.min.css";

SwiperCore.use([Mousewheel, Pagination]);

const SwiperLayout = ({sliders}) => {
    if (!sliders || !sliders.length) {
        return <p>No sliders</p>;
    }

    return (
        <Swiper
            direction={"vertical"}
            slidesPerView={1}
            spaceBetween={30}
            mousewheel={true}
            pagination={{
                clickable: true,
            }}
            className={styles.swiper}
        >
            <div>
                {sliders.map((slider, index) => {
                    return <SwiperSlide key={index}>{slider.component}</SwiperSlide>;
                })}
            </div>
        </Swiper>
    );
};

SwiperLayout.propTypes = {
    sliders: PropTypes.array,
};

export default SwiperLayout;
