import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

export class Portal extends React.Component {
    componentDidMount() {
        this.renderPortal();
    }

    componentDidUpdate() {
        this.renderPortal();
    }

    componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(this.defaultNode || this.props.node);
        if (this.defaultNode) {
            document.body.removeChild(this.defaultNode);
        }
        this.defaultNode = null;
    }

    renderPortal() {
        if (!this.props.node && !this.defaultNode) {
            this.defaultNode = document.createElement("div");
            document.body.appendChild(this.defaultNode);
        }

        let children = this.props.children;
        if (typeof children.type === "function") {
            children = React.cloneElement(children);
        }

        ReactDOM.render(children);
    }

    render() {
        return null;
    }
}

Portal.propTypes = {
    node: PropTypes.any,
};
