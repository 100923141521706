import React from "react";
import PolicyHeader from "views/pages/policy/policy-header";
import styles from "views/pages/policy/styles.module.sass";

const TermsPolicy = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <PolicyHeader />

                <h1 align="center">Пользовательское соглашение</h1>

                <p align="right">Последнее редактирование 01 декабря 2021</p>

                <p>
                    Настоящий документ &laquo;<strong>Пользовательское соглашение</strong>&raquo;
                    представляет собой предложение ООО &laquo;Урок&raquo; (далее &mdash;
                    &laquo;Администрация&raquo;) заключить договор на изложенных ниже условиях
                    Соглашения.
                </p>

                <p>
                    Пользовательское соглашение постоянно размещено по адресу:{" "}
                    <a href="https://umnayavorona.ru/terms">umnayavorona.ru/terms</a>
                </p>

                <p>
                    <strong>1. Общие положения</strong>
                </p>

                <p>
                    1.1. В настоящем документе и вытекающих или связанным с ним отношениях сторон
                    Соглашения применяются следующие термины и определения:
                </p>

                <p>
                    <strong>ООО &laquo;Урок&raquo;</strong> &ndash;&nbsp; Общество с ограниченной
                    ответственностью &laquo;Урок&raquo;, ОГРН 1171215001980, ИНН 1215216925, 424003,
                    Республика Марий Эл, г. Йошкар-Ола, ул. Зарубина, 44, помещение I, офис 4.
                </p>

                <p>
                    <strong>Сайт</strong> &ndash; совокупность информации: текстов, графических
                    элементов, дизайна, изображений, фото и видеоматериалов и иных результатов
                    интеллектуальной деятельности, а также программ для ЭВМ, содержащихся в
                    информационной системе, обеспечивающей доступность такой информации в сети
                    Интернет по сетевому адресу{" "}
                    <a href="https://umnayavorona.ru">umnayavorona.ru</a>, включая все его
                    поддомены.
                    <br />
                    <br />
                    <strong>Пользователь</strong> &ndash; дееспособное физическое лицо,
                    присоединившееся к настоящему Соглашению в собственном интересе, либо
                    выступающее от имени и в интересах представляемого им физического лица, в пользу
                    которого осуществляется оказание услуг по Договору; добровольно прошедшее
                    процедуру Регистрации и создавшее Личный кабинет пользователя.
                </p>

                <p>
                    <strong>Регистрация</strong> &ndash; процедура, в ходе которой Пользователь
                    предоставляет достоверные данные о себе с целью получения доступа к Сайту.
                </p>

                <p>
                    <strong>Логин</strong> &ndash; уникальный идентификатор, состоящий из символов,
                    для однозначного определения каждого Пользователя Сайта. В качестве Логина может
                    быть использован адрес электронной почты Пользователя.
                </p>

                <p>
                    <strong>Пароль</strong> &ndash; последовательность символов, используемая в
                    связке с Логином, для прохождения процедуры входа Пользователя (аутентификации)
                    на Сайт.
                </p>

                <p>
                    <strong>Личный кабинет </strong>&ndash; информационное пространство,
                    представляющее собой web-сервис для обеспечения доступа к услугам Сайта
                    (например, но не ограничиваясь, образовательным тренажерам, результатам
                    использования Сайта, внесению информации о Пользователе и другим функциям) и
                    имеющее набор прав доступа к Сайту. Доступ к Личному кабинету осуществляется с
                    использованием Логина и Пароля и возможен только после Регистрации на Сайте с
                    указанием адреса электронной почты Пользователя. Предусмотрены следующие
                    варианты Личного кабинета Пользователя: &laquo;Учитель&raquo;,
                    &laquo;Ученик&raquo; (далее &ndash; Роли).
                </p>

                <p>
                    <strong>Ученик </strong>&ndash; физическое лицо, прошедшее Регистрацию на Сайте
                    в данном качестве (Роли), на основании информации, предоставленной о нём его
                    законным представителем (родитель, опекун или попечитель несовершеннолетнего
                    Ученика, который действует от его имени и в его интересах), Учителем или им
                    самим.
                </p>

                <p>
                    <strong>Учитель </strong>&ndash; физическое лицо, прошедшее Регистрацию на Сайте
                    в данном качестве (Роли), на основании предоставленной им информации.
                </p>

                <p>
                    <strong>Персональный код</strong> &ndash; набор символов, выданный Учителю
                    Сайтом в Личном кабинете и позволяющий установить связь между Пользователем с
                    Ролью &laquo;Учитель&raquo; и Пользователем с Ролью &laquo;Ученик&raquo;,
                    посредством внесения его в специальную форму Сайта в разделе &laquo;Мой
                    учитель&raquo; в Личном кабинете &laquo;Ученика&raquo;.
                </p>

                <p>
                    <strong>Мой учитель</strong> &ndash; статус Пользователя с ролью
                    &laquo;Учитель&raquo;, связь с Личным кабинетом которого была установлена
                    Учеником в разделе Личного кабинета &laquo;Мой учитель&raquo; посредством
                    указания Персонального кода.
                </p>

                <p>
                    <strong>Мой ученик</strong> &ndash; статус Пользователя с ролью
                    &laquo;Ученик&raquo;, связь с Личным кабинетом которого была установлена
                    Учителем в разделе личного кабинета &laquo;Мои ученики&raquo; посредством выдачи
                    Ученику Персонального кода либо Регистрации Ученика на Сайте через Личный
                    кабинет Учителя.
                </p>

                <p>
                    <strong>Материалы&nbsp;</strong>&ndash; объекты интеллектуальной собственности,
                    размещенные на Сайте Администрацией или Пользователями.
                    <br />
                    <strong>Сервис</strong> &ndash; комплекс услуг, предоставляемых Пользователю с
                    использованием Сайта.
                </p>

                <p>
                    <b>Соглашение&nbsp;</b>&ndash; настоящее соглашение со всеми дополнениями и
                    изменениями.
                </p>

                <p>
                    1.2. Настоящее Соглашение является лицензионным договором, согласно которому,
                    Администрация предоставляет Пользователю простую (неисключительную) лицензию на
                    использование Сайта или иной интеллектуальной собственности ООО
                    &laquo;Урок&raquo;. При этом право на использование части Материалов может быть
                    предоставлено Пользователю при условии уплаты Пользователем лицензионного
                    вознаграждения в соответствии с{" "}
                    <a href="https://umnayavorona.ru/offer">
                        <strong>Приложением 1</strong>
                    </a>
                    .
                </p>

                <p>
                    1.3. ООО &laquo;Урок&raquo; предоставляет Пользователю право использовать Сайт
                    посредством доступа к нему через сеть Интернет.
                </p>

                <p>
                    1.4. Право использования Сайта предоставляется Пользователю без права
                    сублицензирования.
                </p>

                <p>
                    1.5. Использование Сервиса любым способом и в любой форме в пределах его
                    объявленных функциональных возможностей, включая:
                </p>

                <ul>
                    <li>просмотр размещенных на Сайте Материалов;</li>
                    <li>регистрация и/или авторизация на Сайте,</li>
                    <li>
                        размещение или отображение на Сайте любых Материалов, включая но не
                        ограничиваясь такими как: тексты, гипертекстовые ссылки, изображения, аудио
                        и видео- файлы, сведения и/или иная информация,
                    </li>
                </ul>

                <p>
                    в соответствии со ст. 1286 ГК РФ означает заключение Пользователем договора в
                    соответствии с положениями ст.437 и 438 Гражданского кодекса Российской
                    Федерации с ООО &quot;Урок&quot;, в соответствии которым Пользователь
                    безвозмездно и бессрочно передает Администрации право использования своих
                    произведений, сбор, обработку и опубликование своих персональных данных, в том
                    числе своих изображений, и контактных данных, на условиях и в порядке,
                    предусмотренном настоящим соглашением, а также даёт своё безотзывное согласие на
                    получение файлов{" "}
                    <a href="https://ru.wikipedia.org/wiki/Cookie" target="_blank" rel="noreferrer">
                        cookie
                    </a>
                    , информационных сообщений и служебных уведомлений, исходящих от Администрации
                    сайта.
                </p>

                <p>
                    Персональные данные пользователей Сайта, отображающиеся в Личных кабинетах
                    Пользователей, публикуются (делаются общедоступными) исключительно самими
                    Пользователями, дополнительного согласия на их обработку не требуется, право на
                    отзыв согласия не применяется.
                </p>

                <p>
                    1.6. Воспользовавшись любой из указанных выше возможностей по использованию
                    Сервиса Пользователь подтверждает, что:
                    <br />
                    <br />
                    а) Он ознакомился с условиями настоящего Соглашения в полном объеме до начала
                    использования Сервиса.
                    <br />
                    <br />
                    б) Используя Сайт, Пользователь принимает все условия настоящего Соглашения в
                    полном объеме без каких-либо изъятий и ограничений с его стороны и обязуется их
                    соблюдать или прекратить использование Сервиса. Если Пользователь не согласен с
                    условиями настоящего Соглашения или не имеет права на заключение договора на их
                    основе, ему следует незамедлительно прекратить любое использование Сервиса.
                    <br />
                    <br />
                    в) Настоящее Соглашение полностью или частично может быть изменено
                    Администрацией сайта в любое время без какого-либо специального уведомления.
                    Новая редакция Соглашения вступает в силу с момента ее опубликования на Сайте по
                    адресу: <a href="https://umnayavorona.ru/terms">umnayavorona.ru/terms</a>.
                    Пользование Сайтом после внесения в Соглашение изменений и/или дополнений
                    является согласием Пользователя со всеми изменениями и/или дополнениями.
                </p>

                <p>
                    <strong>2. Условия пользования по Соглашению</strong>
                </p>

                <p>
                    2.1. Использование функциональных возможностей Сервиса допускается только после
                    прохождения Пользователем Регистрации и авторизации на Сайте в соответствии с
                    установленной Администрацией процедурой.
                </p>

                <p>
                    2.2. Выбранные Пользователем Логин и Пароль являются необходимой и достаточной
                    информацией для доступа Пользователя на Сайт. Пользователь не имеет права
                    передавать свои Логин и Пароль третьим лицам, несет полную ответственность за их
                    сохранность, самостоятельно выбирая способ их хранения. Пользователь
                    самостоятельно несет ответственность за утерю Логина и Пароля, а так же за все
                    потери и убытки, понесенные в результате этой утери. Администрация не несёт
                    ответственности за потерю информации для доступа к Сайту, совершенную по вине
                    Пользователя, а также за любые потери и убытки, которые возникли в результате
                    этого.
                </p>

                <p>
                    2.3. Все действия, совершенные авторизованным Пользователем, считаются
                    совершенными самим владельцем Личного кабинета. При выявлении факта
                    несанкционированного доступа к Личному кабинету Пользователь должен
                    незамедлительно сообщить об этом Администрации, отправив электронное письмо на
                    почту <a href="mailto:support@umnayavorona.ru">support@umnayavorona.ru</a>.
                </p>

                <p>
                    2.4. В случае, если Пользователем выступает несовершеннолетнее физическое лицо,
                    все действия, совершаемые на Сайте этим лицом будут считаются одобренными его
                    законным представителем (родитель, опекун или попечитель несовершеннолетнего
                    Ученика, который действует от его имени и в его интересах). Все действия,
                    совершенные несовершеннолетним Пользователем, создают права и обязанности для
                    его законного представителя.
                </p>

                <p>
                    2.5. Администрация предоставляет автоматизиванные средства для восстановления
                    пароля. Администрация оставляет за собой право ограничить доступ к таким
                    средствам временно или полностью.
                </p>

                <p>
                    2.6. Пользователь несет личную ответственность за содержание Материалов,
                    опубликованных им на Сайте самостоятельно.
                </p>

                <p>2.7. Пользователь признает и соглашается с тем, что Администрация сайта:</p>

                <p>
                    2.7.1. не обязана просматривать Материалы (контент) любого вида, размещаемый
                    и/или распространяемый Пользователем на Сайте;
                </p>

                <p>
                    2.7.2. Администрация сайта имеет право по своему усмотрению отказать
                    Пользователю в размещении и/или распространении им Материалов или удалить любые
                    Материалы, размещенные Пользователем на Сайте самостоятельно;
                </p>

                <p>
                    2.7.3. Пользователь осознает и согласен с тем, что он должен самостоятельно
                    оценивать все риски, связанные с использованием Материалов, включая оценку
                    надежности, полноты или полезности этих Материалов.
                </p>

                <p>
                    2.7.4. в отношении Материалов, размещаемых пользователем, Администрация
                    выступает информационным посредником и не несет ответственности за нарушения
                    интеллектуальных прав, допущенных Пользователем в соответствии со ст.1253.1
                    Гражданского кодекса Российской Федерации.
                </p>

                <p>
                    <strong>2.8. Пользователь не вправе:</strong>
                </p>

                <p>
                    2.8.1 нарушать какие-либо нормы законодательства РФ, в том числе международного
                    права;
                </p>

                <p>
                    2.8.2. загружать, отправлять, передавать или размещать любым другим способом
                    и/или распространять Материалы, которые:
                </p>

                <p>
                    2.8.2.1. являются незаконными, вредоносными, клеветническими, оскорбляют
                    нравственность, демонстрируют (или являются пропагандой) насилия и/или
                    жестокости, нарушают права интеллектуальной собственности, пропагандируют
                    ненависть и/или дискриминацию людей по расовому, этническому, половому,
                    религиозному, социальному признакам, содержат оскорбления в адрес каких-либо лиц
                    или организаций, содержат элементы (или являются пропагандой) порнографии,
                    детской эротики;
                </p>

                <p>
                    2.8.2.2. содержат вирусы или другие компьютерные коды, файлы или программы,
                    предназначенные для нарушения, уничтожения либо ограничения функциональности
                    любого компьютерного или телекоммуникационного оборудования или программ, для
                    осуществления несанкционированного доступа, а также серийные номера к
                    коммерческим программным продуктам и программы для их генерации, логины, пароли
                    и прочие средства для получения несанкционированного доступа к платным ресурсам
                    в Интернете, а также размещения ссылок на вышеуказанную информацию;
                </p>

                <p>
                    2.8.2.3.&nbsp; представляют собой рекламу (или являются пропагандой) услуг
                    сексуального характера (в том числе под видом иных услуг);
                </p>

                <p>
                    2.8.2.4. разъясняют порядок изготовления, применения или иного использования
                    наркотических веществ или их аналогов, взрывчатых веществ или иного оружия;
                </p>

                <p>
                    2.8.2.5.&nbsp; нарушают права третьих лиц, в том числе несовершеннолетних лиц
                    и/или причиняют им вред в любой форме.
                </p>

                <p>
                    2.8.3. использовать Материалы, содержащиеся на Сайте, в целях достижения
                    коммерческой выгоды;
                </p>

                <p>
                    2.8.4. направлять посредством Сайта Материалы, являющиеся рекламой каких-либо
                    товаров или услуг, сетевым маркетингом, схемой &laquo;пирамид&raquo;,
                    разъяснением способов заработка, &laquo;письмами счастья&raquo; без получения
                    предварительного письменного согласия Администрации;
                </p>

                <p>
                    2.8.5. выдавать себя за другого человека или представителя организации и/или
                    сообщества без достаточных на то прав, в том числе за сотрудников Сайта, а также
                    применять любые другие формы и способы незаконного представительства других лиц
                    в сети Интернет, а также вводить Пользователей Сайта или Администрацию сайта в
                    заблуждение относительно свойств и характеристик каких-либо субъектов или
                    объектов;
                </p>

                <p>
                    2.8.6. несанкционированно хранить, использовать и/или распространять
                    персональные данные других лиц, в том числе Пользователей сайта;
                </p>

                <p>
                    2.8.7. нарушать нормальную работу Сайта или содействовать другим лицам в
                    нарушении нормальной работы Сайта;
                </p>

                <p>
                    2.8.8. размещать Материалы, содержащие любую несогласованную с Администрацией
                    рекламу любого рода и формата, а так же сообщения о проведении любых публичных
                    акций, демонстраций, шествий, митингов, пикетирований, флэшмобов и т.п.
                    мероприятий;
                </p>

                <p>
                    2.8.9. нарушать информационную безопасность Сайта, а также испытывать Сайт на
                    уязвимость;
                </p>

                <p>
                    2.8.10 создавать помехи или проблемы в использовании Сайта другими
                    Пользователями, включая распространение компьютерных вирусов, изменение и/или
                    подделка заголовков TCP/IP пакетов, одновременную отправку большого&nbsp;
                    количества запросов к Сайту с целью замедлить сервер Сайта или вывести его из
                    строя, или иные действия выходящие за рамки целевого использования Сайта,
                    способные повлечь за собой сбои в его работе.
                </p>

                <p>
                    2.9. Пользователь вправе использовать на условиях настоящего Соглашения
                    Материалы, размещенные на Сайте, для публикации и отправки собственных
                    Материалов на Сайт.
                </p>

                <p>
                    2.10. Пользователь с Ролью &laquo;Учитель&raquo; имеет право просматривать
                    результаты использования Сайта и статистику Пользователей со статусом &laquo;Мой
                    ученик&raquo;.
                </p>

                <p>
                    2.11. Пользователь с Ролью &laquo;Ученик&raquo; дает согласие на просмотр
                    результатов использования Сайта и статистику Пользователю со статусом &laquo;Мой
                    учитель&raquo;.
                </p>

                <p>
                    2.12. Пользователь с Ролью &laquo;Учитель&raquo; имеет право редактировать
                    персональные данные Пользователей со статусом &laquo;Мой ученик&raquo; и несет
                    перед Администрацией личную ответственность за достоверность редактируемых
                    персональных данных.
                </p>

                <p>
                    2.13. Удаление Личного кабинета Пользователя осуществляется по запросу
                    Пользователя, направленного на электронный адрес support@umnayavorona.ru
                </p>

                <p>
                    <strong>3. Авторское право</strong>
                </p>

                <p>
                    3.1. Пользователь может загружать (&laquo;скачивать&raquo;) с Сайта Материалы,
                    охраняемые авторским правом, только для личного некоммерческого использования.
                </p>

                <p>
                    3.2. Пользователь передает ООО &laquo;Урок&raquo; неисключительное право
                    воспроизводить, распространять, переводить и переделывать, редактировать,
                    копировать, публиковать, воспроизводить на любых носителях, сообщать другим
                    Пользователям, передавать третьим лицам, создавать производные продукты или иным
                    образом использовать, частично или полностью, любые Материалы, размещенные
                    Пользователем на Сайте.
                </p>

                <p>
                    3.3. Пользователь не вправе загружать, посылать, передавать или любым другим
                    способом размещать и/или распространять Материалы, при отсутствии прав на такие
                    действия согласно законодательству или каким-либо договорным отношениям.
                </p>

                <p>
                    3.4. При размещении каких-либо Материалов на Сайте Пользователь подтверждает,
                    что владелец таких Материалов предоставил бесплатное, постоянное, безотзывное,
                    неисключительное право на использование, воспроизведение, изменение,
                    редактирование, опубликование, перевод и распространение таких Материалов
                    (частично или полностью) на территории всех стран мира и/или включение таких
                    Материалов в другие произведения в любой форме посредством использования любых
                    средств массовой информации или технологий, которые известны в настоящее время
                    или могут быть изобретены в будущем, на весь срок охраны авторского права,
                    предусмотренный законодательством РФ, в отношении таких Материалов.
                </p>

                <p>
                    3.5. Пользователь несет единоличную полную ответственность в отношении
                    размещаемых на Сайте Материалов, соответствие их требованиям законодательства,
                    за нарушения прав третьих лиц на размещаемые Пользователем Материалы и возмещает
                    любой ущерб, возникающий вследствие таких нарушений, а также любой ущерб,
                    возникающий вследствие загрузки на Сайт таких Материалов.
                </p>

                <p>
                    <strong>4. Права и обязанности Администрации</strong>
                </p>

                <p>
                    4.1. Администрация обязуется предоставить Пользователю доступ к Сайту и
                    поддерживать Сайт в рабочем состоянии, с осуществлением перерывов на техническое
                    обслуживание
                </p>

                <p>
                    4.2. Администрация обязуется прикладывать все усилия к своевременному устранению
                    технических проблем для обеспечения доступа Пользователей к Сайту.
                </p>

                <p>4.3. Администрация сайта вправе:</p>

                <p>4.3.1. модифицировать Сайт по своему усмотрению;</p>

                <p>
                    4.3.2. редактировать или удалять Материалы, опубликованные Пользователем на
                    Сайте, без уведомления Пользователя, если они не соответствуют условиям данного
                    Cоглашения;
                </p>

                <p>
                    4.3.3. посылать Пользователям информационные сообщения (включая, но не
                    ограничиваясь, сообщениями посредством электронной почты);
                </p>

                <p>
                    4.3.4. за нарушение настоящего Соглашения применять в отношении Пользователя
                    ограничительные меры, в том числе без уведомления ограничивать доступ
                    Пользователя к Сайту.
                </p>

                <p>
                    4.3.5. расторгнуть Соглашение в одностороннем порядке в случае нарушения
                    Пользователем условий настоящего Соглашения. В этом случае Пользователь должен
                    незамедлительно прекратить пользование Сайтом.
                </p>

                <p>
                    <strong>5. Ограничение ответственности</strong>
                </p>

                <p>
                    5.1. Сайт предоставляется &laquo;как есть&raquo;. Пользователь использует Сайт
                    на свой собственный риск. Администрация сайта не принимает на себя никакой
                    ответственности, в том числе за соответствие Сервисов целям Пользователя.
                </p>

                <p>
                    5.2. Ответственность за рекламную информацию, размещенную на сайте, несут
                    рекламодатели.
                </p>

                <p>
                    5.3. Все Материалы Сайта носят справочный характер и не являются рекомендацией
                    или одобрением со стороны Администрации сайта. Мнение любого сотрудника Сайта,
                    автора Сайта, Пользователя&nbsp; или третьего лица, разместившего любой Материал
                    на Сайте, является субъективным и может не совпадать с мнением Администрации
                    сайта или Пользователя.
                </p>

                <p>5.4. Администрация сайта не гарантирует, что:</p>

                <p>5.4.1. Сервис соответствует/будет соответствовать требованиям Пользователя;</p>

                <p>
                    5.4.2. Сервис будут предоставляться непрерывно, быстро, надежно и без ошибок;
                    результаты, которые могут быть получены с использованием Сервиса, будут точными
                    и надежными и могут использоваться для каких-либо целей или в каком-либо
                    качестве (например, для установления и/или подтверждения каких-либо фактов);
                </p>

                <p>
                    5.4.3. Качество какого-либо продукта, услуги, информации и пр., полученных с
                    использованием Сервиса, будет соответствовать ожиданиям Пользователя;
                </p>

                <p>
                    5.5. В&nbsp;случае, если применимое законодательство не&nbsp;допускает
                    исключение или ограничение гарантий, или ограничение применимых предусмотренных
                    законом прав потребителей, указанные выше исключения и&nbsp;ограничения
                    применимы в&nbsp;максимально разрешенной законом мере.
                </p>

                <p>
                    5.6. Администрация не&nbsp;дает гарантий о&nbsp;&nbsp;допустимости применения и
                    использования Сайта для несовершеннолетних Пользователей. Это решение
                    принимается самостоятельно их законными представителями на&nbsp;основании
                    проведения должной и&nbsp;полной проверки работы Сайта.
                </p>

                <p>
                    5.5. Администрация не несет ответственности за любые виды убытков, произошедшие
                    вследствие использования Сайта, или невозможности использования Сайта
                    Пользователем или отдельных частей/функций Сервиса и не обязана возмещать
                    Пользователю понесенные в связи с этим убытки, в том числе вызванные
                    непредвиденными чрезвычайными ситуациями (катастрофы, пожары, природные
                    катаклизмы и пр.), плановыми или внеплановыми техническими и профилактическими
                    работами, техническими сбоями интернет-провайдеров, компьютерных сетей, серверов
                    и средств, а также противоправными действиями третьих лиц, неосторожными
                    действиями самого Пользователя, а также вследствие вмешательства третьих лиц, в
                    том числе государственных служб безопасности и правоохранительных органов, и
                    иных чрезвычайных и непредотвратимых при данных условиях обстоятельств, при
                    которых осуществление надлежащей работы Сайта оказалось невозможным.
                </p>

                <p>
                    5.6. Некоторые данные, опубликованные Пользователями на Сайте, могут показаться
                    оскорбительными, недостоверными или вводящими в заблуждение отдельных
                    Пользователей. Пользователь осознает и принимает эти риски, а также соглашается,
                    что Сайт не несет ответственности за действия или бездействие со стороны
                    Пользователя.
                </p>

                <p>
                    5.7. Администрация не несет ответственности за Материалы внешних ресурсов, даже
                    если на Сайте содержатся ссылки на эти ресурсы.
                </p>

                <p>
                    5.8. Администрация не несет ответственности за любые возможные или возникшие
                    потери или убытки, связанные с содержанием Сайта, регистрацией авторских прав, а
                    также товарами или услугами, полученными через иные ресурсы, даже если на Сайте
                    были размещены ссылки на эти ресурсы.
                </p>

                <p>
                    <strong>6. Иные условия</strong>
                </p>

                <p>
                    6.1. Настоящее Соглашение вступает в силу с момента первого посещения сайта
                    Пользователем и действует бессрочно.
                </p>

                <p>
                    6.2. Если по тем или иным причинам одно или несколько положений настоящего
                    Соглашения будут признаны недействительными или не имеющими юридической силы,
                    это не оказывает влияния на действительность или применимость остальных
                    положений Соглашения.
                </p>

                <p>
                    6.3. Бездействие со стороны Администрации в случае нарушения Пользователем либо
                    иными пользователями условий данного соглашения не лишает Администрацию права
                    предпринять соответствующие действия в защиту своих интересов позднее, а также
                    не означает отказа Администрации сайта от своих прав в случае совершения в
                    последующем подобных либо сходных нарушений.
                </p>

                <p>
                    6.4. В случае возникновения любых споров между Пользователем и Администрацией,
                    вытекающих из отношений, регулируемых Пользовательским соглашением, а также
                    любых иных разногласий, такие разногласия должны быть урегулированы с
                    применением обязательного досудебного претензионного порядка. Администрация
                    направляет Пользователю претензию в электронном виде на адрес электронной почты,
                    указанный Пользователем при регистрации на Сайте. Пользователь направляет
                    Администрации претензию в электронном виде на адрес электронной почты{" "}
                    <a href="mailto:support@umnayavorona.ru">support@umnayavorona.ru</a>, а также
                    дублирует претензию на адрес ООО &laquo;Урок&raquo;, указанный в данном
                    соглашении. Срок ответа на претензию &ndash; 10 (десять) рабочих дней со дня ее
                    получения. При несоблюдении любой из сторон Соглашения всех перечисленных выше
                    условий обязательный претензионный порядок не считается соблюденным. Досудебный
                    претензионный порядок разрешения споров завершается удовлетворением требований
                    Пользователя, изложенных в Претензии, или письменным отказом Администрации Сайта
                    в удовлетворении требований Пользователя, изложенных в Претензии.
                </p>

                <p>
                    6.5. В случае невозможности разрешения спора в досудебном порядке, спор между
                    Пользователем и Администрацией Сайта разрешается в суде в соответствии с
                    законодательством Российской Федерации исключительно по месту нахождения ООО
                    &laquo;Урок&raquo;.
                </p>

                <p>
                    6.6. Принимая условия Соглашения, Пользователь также выражает согласие на
                    получение информационных рассылок и рекламных Материалов от Администрации, либо
                    от иных лиц по поручению Администрации, на адрес электронной почты, указанный
                    при регистрации на Сайте. Пользователь в любой момент может отозвать согласие на
                    получение рассылок информационных рассылок и рекламных Материалов от
                    Администрации путем перехода по ссылке &laquo;Отказаться от рассылки&raquo;,
                    указанной в тексте сообщения.
                </p>

                <p>
                    6.7. Соглашение заключается в письменном виде в форме электронного документа и
                    не оформляется на бумажных носителях.
                </p>

                <p>
                    6.8. Пользователь и Администрация используют простые электронные подписи,
                    которыми являются логин и пароль от персональной электронной почты Пользователя,
                    указанной при Регистрации и электронной почты Администрации &ndash;{" "}
                    <a href="mailto:support@umnayavorona.ru">support@umnayavorona.ru</a>. Проверка
                    подлинности и достоверности сообщения, исходящего от Пользователя,
                    осуществляется путем сравнения адреса отправителя с адресом, указанным
                    Пользователем при Регистрации на Сайте.
                </p>

                <p>
                    6.9. Любая корреспонденция в адрес Администрации должна направляться
                    Пользователем по адресу электронной почты{" "}
                    <a href="mailto:support@umnayavorona.ru">support@umnayavorona.ru</a>.
                </p>

                <p>
                    6.10. Принимая условия Соглашения, Пользователь дает свое согласие на обработку
                    предоставленных им персональных данных в соответствии с условиями Политики
                    обработки персональных данных:{" "}
                    <a
                        href="https://umnayavorona.ru/files/private_policy.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://umnayavorona.ru/files/private_policy.pdf
                    </a>
                    . Обработка персональных данных пользователей осуществляется исключительно в
                    целях исполнения настоящего Пользовательского Соглашения.
                </p>
            </div>
        </div>
    );
};

TermsPolicy.propTypes = {};

export default TermsPolicy;
