import {insert} from "entries/constructors/dictation/utils/helpers";
import {
    DICTATION_EMPTY_SKIP,
    DICTATION_SKIP_ID,
} from "entries/constructors/dictation/views/pages/dictation-page";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {ModalDictationSkips} from "entries/constructors/dictation/views/containers/modal-dictation-skips/modal-dictation-skips";

export const ModalDictationSkipsContainer = ({
    isOpen,
    setIsOpen,
    handleSaveVariant,
    skips,
    handleDeleteSkip,
    text,
    handleSaveSkip,
}) => {
    const [selectedWordIndex, setSelectedWordIndex] = useState(null);
    const [selectedSymbolIndex, setSelectedSymbolIndex] = useState(null);
    const [separatedText, setSeparatedText] = useState([]);

    const [skipVariants, setSkipVariants] = useState([]);

    useEffect(() => {
        if (!text.trim()) {
            setSelectedWordIndex(null);
            setSelectedSymbolIndex(null);
            setSkipVariants([]);
        }
        let tempSeparatedText = text.split(" ");
        setSeparatedText(tempSeparatedText);
    }, [text]);

    useEffect(() => {
        if (selectedSymbolIndex !== null) {
            let separatedWord = separatedText[selectedWordIndex].split("");
            let skips = [];

            let variantAfterSelectedSymbol = insert(
                separatedWord,
                selectedSymbolIndex + 1,
                DICTATION_EMPTY_SKIP,
            );
            let variantInsteadSelectedSymbol = separatedWord;

            if (selectedSymbolIndex === 0) {
                let variantBeforeSelectedSymbol = insert(separatedWord, 0, DICTATION_SKIP_ID);
                variantInsteadSelectedSymbol[0] = DICTATION_SKIP_ID;

                skips.push(
                    variantBeforeSelectedSymbol,
                    variantAfterSelectedSymbol,
                    variantInsteadSelectedSymbol,
                );
            } else {
                variantInsteadSelectedSymbol[selectedSymbolIndex] = DICTATION_SKIP_ID;
                skips.push(variantAfterSelectedSymbol, variantInsteadSelectedSymbol);
            }

            setSkipVariants(skips);
        }
    }, [selectedSymbolIndex]);

    return (
        <ModalDictationSkips
            toggle={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
            selectedWordIndex={selectedWordIndex}
            setSelectedWordIndex={setSelectedWordIndex}
            selectedSymbolIndex={selectedSymbolIndex}
            setSelectedSymbolIndex={setSelectedSymbolIndex}
            separatedText={separatedText}
            skipVariants={skipVariants}
            handleSaveVariant={handleSaveVariant}
            skips={skips}
            handleDeleteSkip={handleDeleteSkip}
            handleSaveSkip={handleSaveSkip}
        />
    );
};

ModalDictationSkipsContainer.propTypes = {
    handleDeleteSkip: PropTypes.func.isRequired,
    handleSaveSkip: PropTypes.func.isRequired,
    handleSaveVariant: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    skips: PropTypes.array.isRequired,
    text: PropTypes.string,
};
