import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./two-column-answers.module.sass";

export const TwoColumnAnswers = (props) => (
    <div
        className={cn({
            [props.className]: !!props.className,
            [styles.container]: true,
        })}
    >
        {props.children}
    </div>
);

TwoColumnAnswers.propTypes = {
    className: PropTypes.string,
};
