import {TOAST_ERROR_CONFIG, url} from "config";
import {IMAGE_FROM_BANK} from "core/types/content-types";
import {ClippingAPI} from "entries/constructors/clipping/core/ClippingAPI";
import {
    IMAGE_TYPE_CLIPPING_BANK,
    IMAGE_TYPE_CLIPPING_UPLOAD,
    IMAGE_TYPE_SECONDARY_CLIPPING_BANK,
    IMAGE_TYPE_SECONDARY_CLIPPING_UPLOAD,
} from "entries/constructors/clipping/core/types";
import ContainerForClipping from "feauters/clipping-pictures-engine/container-for-clipping/container-for-clipping";
import ContainerForClippingMulti from "feauters/clipping-pictures-engine/container-for-clipping/container-for-clipping-multi";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {toast} from "react-toastify";
import {IMAGE_TYPE, TEXT_TYPE} from "utils/answers-config";
import {ClippingSecondaryAPI} from "entries/constructors/clipping/core/ClippingSecondaryAPI";
import {v4 as uuidv4} from "uuid";

const ClippingPicturesEngineContainer = ({
    boxes,
    setBoxes,
    background,
    setBackground,
    imageSize,
    isEditMode,
    isRoundedAnswer,
    answers,
    setAnswers,
    multiple,
    answerType,
    textAnswerSize,
    testId,
    handleSelectAnswer,
    selectedAnswerId,
    isSecondary,
    isManyToMany,
}) => {
    const [imageUploadModalIsOpen, setImageUploadModalIsOpen] = useState(false);
    const [uploadImageAnswerModalIsOpen, setUploadImageAnswerModalIsOpen] = useState(false);
    const [uploadTextAnswerModalIsOpen, setUploadTextAnswerModalIsOpen] = useState(false);
    const [api, setApi] = useState(ClippingAPI);

    useEffect(() => {
        let api = isSecondary ? ClippingSecondaryAPI : ClippingAPI;
        setApi(api);
    }, []);

    const handleBackgroundSave = (imageData) => {
        api.addQuestionImage(testId, imageData)
            .then((response) => {
                setBackground({
                    objectUrl: url + response.data.fileDownloadUri.slice(1),
                });
            })
            .catch(() => toast.error("Ошибка добавления изображения", TOAST_ERROR_CONFIG))
            .finally(() => setImageUploadModalIsOpen(false));
    };

    const createAnswer = (answerDto) => {
        if (
            answerDto.objectType === IMAGE_TYPE_CLIPPING_BANK ||
            answerDto.objectType === IMAGE_TYPE_CLIPPING_UPLOAD ||
            answerDto.objectType === IMAGE_TYPE_SECONDARY_CLIPPING_BANK ||
            answerDto.objectType === IMAGE_TYPE_SECONDARY_CLIPPING_UPLOAD
        ) {
            let imageUrl =
                answerDto.objectType === IMAGE_TYPE_CLIPPING_BANK ||
                answerDto.objectType === IMAGE_TYPE_SECONDARY_CLIPPING_BANK
                    ? answerDto.answerImageBank.fileDownloadUri.slice(1)
                    : answerDto.objectType === IMAGE_TYPE_CLIPPING_UPLOAD ||
                      answerDto.objectType === IMAGE_TYPE_SECONDARY_CLIPPING_UPLOAD
                    ? answerDto.answerImageQuestion.fileDownloadUri.slice(1)
                    : null;

            let newAnswer = {
                id: answerDto.id,
                type: IMAGE_TYPE,
                objectUrl: url + imageUrl,
                left: 0,
                top: 0,
                isAvailable: true,
            };

            if (isManyToMany) {
                newAnswer.tempId = uuidv4();
            }

            setAnswers([...answers, newAnswer]);
        }
    };

    const handleAddPictureAnswer = (imageData) => {
        if (!imageData) {
            return;
        }

        if (imageData.type === IMAGE_FROM_BANK) {
            // с банка
            api.addImageAnswerFromBank(testId, imageData.id)
                .then((response) => {
                    createAnswer(response.data);
                })
                .catch(() => toast.error("Ошибка добавления изображения", TOAST_ERROR_CONFIG))
                .finally(() => setUploadImageAnswerModalIsOpen(false));
        } else {
            // с компа
            api.addImageAnswerFromUpload(testId, imageData)
                .then((response) => {
                    createAnswer(response.data);
                })
                .catch(() => toast.error("Ошибка добавления изображения", TOAST_ERROR_CONFIG))
                .finally(() => setUploadImageAnswerModalIsOpen(false));
        }
    };

    const handleAnswerDelete = (id) => {
        api.deleteAnswer(testId, id)
            .then(() => {
                setBoxes(boxes.filter((box) => box.id !== id));
                setAnswers(answers.filter((answer) => answer.id !== id));
            })
            .catch(() => toast.error("Ошибка удаления ответа"));
    };

    const handleAddTextAnswer = (text) => {
        if (!text.trim()) {
            return;
        }

        api.addTextAnswer(testId, text)
            .then((response) => {
                let answer = response.data;

                let newAnswer = {
                    id: answer.id,
                    type: TEXT_TYPE,
                    text: answer.text,
                    left: 0,
                    top: 0,
                    isAvailable: true,
                };

                if (isManyToMany) {
                    newAnswer.tempId = uuidv4();
                }

                setAnswers([...answers, newAnswer]);
            })
            .catch(() => toast.error("Ошибка добавления текста", TOAST_ERROR_CONFIG))
            .finally(() => setUploadTextAnswerModalIsOpen(false));
    };

    const handleBackgroundDelete = () => {
        api.deleteQuestionImage(testId)
            .then(() => {
                setBackground(null);
            })
            .catch(() => toast.error("Ошибка удаления фона", TOAST_ERROR_CONFIG))
            .finally(() => setImageUploadModalIsOpen(false));
    };

    return (
        <DndProvider backend={HTML5Backend}>
            {isManyToMany ? (
                <ContainerForClippingMulti
                    boxes={boxes}
                    setBoxes={setBoxes}
                    background={background}
                    setBackground={setBackground}
                    imageUploadModalIsOpen={imageUploadModalIsOpen}
                    setImageUploadModalIsOpen={setImageUploadModalIsOpen}
                    uploadImageAnswerModalIsOpen={uploadImageAnswerModalIsOpen}
                    setUploadImageAnswerModalIsOpen={setUploadImageAnswerModalIsOpen}
                    handleBackgroundSave={handleBackgroundSave}
                    handleBackgroundDelete={handleBackgroundDelete}
                    handleAddPictureAnswer={handleAddPictureAnswer}
                    handleAnswerDelete={handleAnswerDelete}
                    imageSize={imageSize}
                    isEditMode={isEditMode}
                    isRoundedAnswer={isRoundedAnswer}
                    answers={answers}
                    setAnswers={setAnswers}
                    multiple={multiple}
                    uploadTextAnswerModalIsOpen={uploadTextAnswerModalIsOpen}
                    setUploadTextAnswerModalIsOpen={setUploadTextAnswerModalIsOpen}
                    handleAddTextAnswer={handleAddTextAnswer}
                    answerType={answerType}
                    textAnswerSize={textAnswerSize}
                    handleSelectAnswer={handleSelectAnswer}
                    selectedAnswerId={selectedAnswerId}
                    isManyToMany={isManyToMany}
                />
            ) : (
                <ContainerForClipping
                    boxes={boxes}
                    setBoxes={setBoxes}
                    background={background}
                    setBackground={setBackground}
                    imageUploadModalIsOpen={imageUploadModalIsOpen}
                    setImageUploadModalIsOpen={setImageUploadModalIsOpen}
                    uploadImageAnswerModalIsOpen={uploadImageAnswerModalIsOpen}
                    setUploadImageAnswerModalIsOpen={setUploadImageAnswerModalIsOpen}
                    handleBackgroundSave={handleBackgroundSave}
                    handleBackgroundDelete={handleBackgroundDelete}
                    handleAddPictureAnswer={handleAddPictureAnswer}
                    handleAnswerDelete={handleAnswerDelete}
                    imageSize={imageSize}
                    isEditMode={isEditMode}
                    isRoundedAnswer={isRoundedAnswer}
                    answers={answers}
                    setAnswers={setAnswers}
                    multiple={multiple}
                    uploadTextAnswerModalIsOpen={uploadTextAnswerModalIsOpen}
                    setUploadTextAnswerModalIsOpen={setUploadTextAnswerModalIsOpen}
                    handleAddTextAnswer={handleAddTextAnswer}
                    answerType={answerType}
                    textAnswerSize={textAnswerSize}
                    handleSelectAnswer={handleSelectAnswer}
                    selectedAnswerId={selectedAnswerId}
                    isManyToMany={isManyToMany}
                />
            )}
        </DndProvider>
    );
};

ClippingPicturesEngineContainer.propTypes = {
    answers: PropTypes.array,
    answerType: PropTypes.string,
    background: PropTypes.object,
    boxes: PropTypes.array,
    handleSelectAnswer: PropTypes.func,
    imageSize: PropTypes.string,
    isEditMode: PropTypes.bool,
    isRoundedAnswer: PropTypes.bool,
    isSecondary: PropTypes.bool,
    multiple: PropTypes.bool,
    selectedAnswerId: PropTypes.number,
    setAnswers: PropTypes.func,
    setBackground: PropTypes.func,
    setBoxes: PropTypes.func,
    testId: PropTypes.any,
    textAnswerSize: PropTypes.number,
    isManyToMany: PropTypes.bool,
};

export default ClippingPicturesEngineContainer;
