import background from "assets/sliders/rating_bg.jpg";
import React from "react";
import sliders from "../sliders.module.sass";

const RatingSystemSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            RATING SYSTEM
        </div>
    );
};

RatingSystemSlide.propTypes = {};

export default RatingSystemSlide;
