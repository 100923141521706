import FixedBottomBar from "components/fixed-bottom-bar";
import EventsGroup from "entries/events/event-group/event-group";
import EventItem from "entries/events/event-item/event-item";
import Thumbler from "entries/thumbler/thumbler";
import {
    MDBBtn,
    MDBCol,
    MDBContainer,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBRow,
} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {ADMIN_ROUTES} from "routes/admin";
import {EditButtons} from "views/components/edit-buttons";
import styles from "./styles.module.sass";

export const EVENT = "event";
export const EVENT_GROUP = "event-group";

const EventsAdmin = ({
    events,
    eventSets,
    handleSelect,
    handleSelectSet,
    selectedEvents,
    createNewSetModalIsOpen,
    setCreateNewSetModalIsOpen,
    handleSaveNewSet,
    addEventsToSetModalIsOpen,
    setAddEventsToSetModalIsOpen,
    handleDeleteGroup,
    handleEditGroup,
    groupName,
    setGroupName,
    editGroupClick,
    handleDeleteEvent,
    groupForEdit,
}) => {
    const [choose, setChoose] = useState(EVENT);
    let history = useHistory();
    return (
        <MDBContainer>
            {createNewSetModalIsOpen && (
                <CreateNewGroupModal
                    toggle={() => {
                        setGroupName("");
                        setCreateNewSetModalIsOpen(!createNewSetModalIsOpen);
                    }}
                    isOpen={createNewSetModalIsOpen}
                    handleSave={handleSaveNewSet}
                    groupName={groupName}
                    setGroupName={setGroupName}
                    handleEdit={handleEditGroup}
                    groupForEdit={groupForEdit}
                />
            )}
            {addEventsToSetModalIsOpen && (
                <SelectEventSetModal
                    isOpen={addEventsToSetModalIsOpen}
                    toggle={() => setAddEventsToSetModalIsOpen(!addEventsToSetModalIsOpen)}
                    eventSets={eventSets}
                    handleSelect={handleSelectSet}
                />
            )}
            <MDBRow between>
                <MDBCol md="4">
                    <h2>События</h2>
                </MDBCol>

                <MDBCol md="4" className="d-flex justify-content-end">
                    {choose === EVENT_GROUP && (
                        <MDBBtn color="info" onClick={() => setCreateNewSetModalIsOpen(true)}>
                            Создать набор событий
                        </MDBBtn>
                    )}
                    {choose === EVENT && (
                        <MDBBtn
                            color="info"
                            onClick={() => history.push(ADMIN_ROUTES.EVENTS.CREATE)}
                        >
                            Создать событие
                        </MDBBtn>
                    )}
                </MDBCol>
            </MDBRow>
            <MDBRow className="mt-3">
                <MDBCol>
                    <Thumbler
                        options={[
                            {value: EVENT, name: "События"},
                            {value: EVENT_GROUP, name: "Наборы"},
                        ]}
                        activeState={choose}
                        setActiveState={setChoose}
                    />
                </MDBCol>
            </MDBRow>
            <MDBRow className="mt-3">
                {choose === EVENT && (
                    <MDBCol>
                        {events ? (
                            <div>
                                {events.content.map((event) => (
                                    <div className={styles.event__item} key={event.id}>
                                        <EditButtons
                                            deleteAction={() => handleDeleteEvent(event.id)}
                                            editAction={() =>
                                                history.push(
                                                    ADMIN_ROUTES.EVENTS.EDIT.replace(
                                                        ":id",
                                                        event.id,
                                                    ),
                                                )
                                            }
                                        />
                                        <EventItem
                                            title={event.title}
                                            id={event.id}
                                            isChecked={selectedEvents.includes(event.id)}
                                            onCheck={handleSelect}
                                            description={event.description}
                                            className={styles.event}
                                            withLogo={false}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <h4>Событий не найдено.</h4>
                        )}
                    </MDBCol>
                )}
                {choose === EVENT_GROUP && (
                    <MDBCol>
                        {eventSets ? (
                            <EventsGroup
                                events={eventSets}
                                handleDeleteGroup={handleDeleteGroup}
                                handleEditGroup={editGroupClick}
                            />
                        ) : (
                            <div>
                                <h4>Группы не найдены.</h4>
                            </div>
                        )}
                    </MDBCol>
                )}
            </MDBRow>
            {choose === EVENT && (
                <FixedBottomBar>
                    <div className="d-flex align-items-center">
                        <MDBBtn
                            color="info"
                            className="mr-4"
                            onClick={() => setAddEventsToSetModalIsOpen(true)}
                        >
                            Добавить выбранные в группу
                        </MDBBtn>
                        <p>Выбрано: {selectedEvents.length} событий.</p>
                    </div>
                </FixedBottomBar>
            )}
        </MDBContainer>
    );
};

EventsAdmin.propTypes = {
    addEventsToSetModalIsOpen: PropTypes.bool,
    createNewSetModalIsOpen: PropTypes.bool,
    editGroupClick: PropTypes.func,
    events: PropTypes.object,
    eventSets: PropTypes.object,
    groupForEdit: PropTypes.object,
    groupName: PropTypes.string,
    handleDeleteEvent: PropTypes.func,
    handleDeleteGroup: PropTypes.func,
    handleEditGroup: PropTypes.func,
    handleSaveNewSet: PropTypes.func,
    handleSelect: PropTypes.func,
    handleSelectSet: PropTypes.func,
    selectedEvents: PropTypes.array,
    setAddEventsToSetModalIsOpen: PropTypes.func,
    setCreateNewSetModalIsOpen: PropTypes.func,
    setGroupName: PropTypes.func,
};

export default EventsAdmin;

const CreateNewGroupModal = ({
    isOpen,
    toggle,
    handleSave,
    groupName,
    setGroupName,
    handleEdit,
    groupForEdit,
}) => {
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (groupForEdit) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
    }, [groupForEdit]);

    return (
        <MDBModal isOpen={isOpen} toggle={toggle}>
            <MDBModalHeader>
                <p>Группа событий</p>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBInput
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    label="Название группы"
                />
                <MDBBtn
                    color="info"
                    onClick={() => {
                        isEdit ? handleEdit() : handleSave();
                    }}
                >
                    Сохранить
                </MDBBtn>
            </MDBModalBody>
        </MDBModal>
    );
};

CreateNewGroupModal.propTypes = {
    groupForEdit: PropTypes.object,
    groupName: PropTypes.string,
    handleEdit: PropTypes.func,
    handleSave: PropTypes.func,
    isOpen: PropTypes.bool,
    setGroupName: PropTypes.func,
    toggle: PropTypes.func,
};

const SelectEventSetModal = ({isOpen, toggle, eventSets, handleSelect}) => {
    return (
        <MDBModal isOpen={isOpen} toggle={toggle}>
            <MDBModalHeader>
                <p>Выбор группы</p>
            </MDBModalHeader>
            <MDBModalBody>
                {eventSets ? (
                    <div>
                        {eventSets.content.map((eventSet) => (
                            <div
                                key={eventSet.id}
                                onClick={() => handleSelect(eventSet.id)}
                                className={styles.selectItem}
                            >
                                <p>{eventSet.id}. </p>
                                <p>{eventSet.name}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <h4>Группы не найдены</h4>
                )}
            </MDBModalBody>
        </MDBModal>
    );
};

SelectEventSetModal.propTypes = {
    eventSets: PropTypes.object,
    groupForEdit: PropTypes.object,
    handleSelect: PropTypes.func,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
};
