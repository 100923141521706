import React, {useState} from "react";
import cn from "classnames";
import styles from "../welcome-modal/styles.module.sass";
import {url} from "../../../config/index";
import {IUserInfo} from "../../../interfaces/IUserInfo";
import {MDBBtn} from "mdbreact";
import {UserAPI} from "../../../core/api/user";
import {toast} from "react-toastify";

interface IProps {
    isActive: boolean;
    toggle: () => void;
    user: IUserInfo;
    updateUser: () => void;
}

const UpdateClassModal = ({toggle, user, updateUser}: IProps) => {
    const [selectedClass, setSelectedClass] = useState(0);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const handleSelect = (classNumber: number) => {
        if (!classNumber) return;

        setSelectedClass(classNumber);
        setIsConfirmationOpen(true);
    };

    const handleChangeClass = () => {
        if (!selectedClass) return;

        UserAPI.updateClass(selectedClass)
            .then(() => {
                toast.success("Класс успешно изменен");
                toggle();
                updateUser();
            })
            .catch(() => toast.error("Ошибка изменения класса"));
    };

    return (
        <div
            className={cn({
                [styles.containerWrapper]: true,
            })}
        >
            <img
                className={styles.logo2}
                src={url + "data/narrator/c25d495c-d45a-4c26-87ac-48d37a0d35b5.svg"}
                alt="raven-logo"
            />
            <h3 className={styles.title}>Постой!</h3>
            <div className={styles.content}>
                <div className={styles.description}>
                    {isConfirmationOpen ? (
                        <p>Твой класс изменится на {selectedClass}</p>
                    ) : (
                        <p> Уточни, в каком классе ты сейчас учишься?</p>
                    )}
                </div>
            </div>

            <div className={styles.footer}>
                {isConfirmationOpen ? (
                    <div>
                        <MDBBtn color="success" onClick={handleChangeClass}>
                            Подтвердить
                        </MDBBtn>
                        <MDBBtn
                            color="grey"
                            onClick={() => {
                                setIsConfirmationOpen(false);
                                setSelectedClass(0);
                            }}
                        >
                            Отмена
                        </MDBBtn>
                    </div>
                ) : (
                    <React.Fragment>
                        <MDBBtn
                            color="primary"
                            onClick={
                                user?.classTag?.name
                                    ? () => handleSelect(parseInt(user?.classTag?.name || ""))
                                    : () => null
                            }
                        >
                            {user?.classTag?.name} класс
                        </MDBBtn>
                        <MDBBtn
                            color="success"
                            onClick={
                                user?.classTag?.name
                                    ? () => handleSelect(parseInt(user?.classTag?.name || "") + 1)
                                    : () => null
                            }
                        >
                            {user?.classTag?.name && parseInt(user.classTag.name) + 1} класс
                        </MDBBtn>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default UpdateClassModal;
