import {MDBContainer, MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";
import {CreateTheme} from "entries/school-themes/views/forms/create-theme";

export const CreateThemeModal = (props) => {
    const {visible, toggle, subjects, classes, onSaveNewTheme, itemForEdit} = props;

    return (
        <MDBContainer>
            <MDBModal
                toggle={toggle}
                isOpen={visible}
                overflowScroll={false}
                noClickableBodyWithoutBackdrop
            >
                <MDBModalHeader toggle={toggle}>
                    {itemForEdit ? "Редактирование темы" : "Добавление новой темы"}
                </MDBModalHeader>
                <MDBModalBody>
                    <CreateTheme
                        subjects={subjects}
                        classes={classes}
                        onSaveNewTheme={onSaveNewTheme}
                        itemForEdit={itemForEdit}
                    />
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    );
};

CreateThemeModal.propTypes = {
    classes: PropTypes.array,
    subjects: PropTypes.array,
    toggle: PropTypes.func,
    visible: PropTypes.bool,
    onSaveNewTheme: PropTypes.func,
};
