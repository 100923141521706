import React from "react";

import ServiceDetectDevice from "./serviceDetectDevice/ServiceDetectDevice";
import ServiceMetrics from "./serviceMetrics/serviceMetrics";
import ServiceAuth from "./serviceAuth/serviceAuth";
import ServiceTabs from "./serviceTabs/serviceTabs";
import ServiceModal from "./serviceModal/serviceModal";
import ServiceUser from "./serviceUser/serivceUser";
import ServiceToasts from "./serviceToasts/serviceToasts";
import ServiceWebsocket from "./serviceWebsocket/serviceWebsocket";
import ServiceAchievement from "./serviceAchievement/serviceAchievement";

const Services = () => {
    return (
        <React.Fragment>
            <ServiceAuth />
            <ServiceDetectDevice />
            <ServiceMetrics />
            <ServiceTabs />
            <ServiceModal />
            <ServiceUser />
            <ServiceToasts />
            <ServiceWebsocket />
            <ServiceAchievement />
        </React.Fragment>
    );
};

export default Services;
