import React from "react";
import {ColumnsType} from "antd/es/table";
import {Space, Table} from "antd";
import {Banner} from "actual/entities/banner";
import {IPageable} from "../../../interfaces/IPageable";

interface IProps {
    banners: IPageable<Banner> | undefined;
    handlePaginationChange: (page: number, pageSize: number) => void;
    isLoading: boolean;
    onBannerUpdateAction: (banner: Banner) => void;
    onBannerDeleteAction: (id: number) => void;
}

export const BannerTable = ({
    banners,
    handlePaginationChange,
    isLoading,
    onBannerUpdateAction,
    onBannerDeleteAction,
}: IProps) => {
    const columns: ColumnsType<Banner> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Позиция",
            dataIndex: "position",
            key: "position",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Контент",
            dataIndex: "content",
            key: "content",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Активен?",
            dataIndex: "isActive",
            key: "isActive",
            render: (text) => <a>{String(text)}</a>,
        },
        {
            title: "Действия",
            key: "action",
            render: (_, record) => {
                return (
                    <Space size="small" key={record.id}>
                        <i
                            className="fas fa-pen"
                            style={{
                                color: "orange",
                                cursor: "pointer",
                            }}
                            onClick={() => onBannerUpdateAction(record)}
                        />

                        <i
                            className="fas fa-trash"
                            style={{
                                color: "red",
                                cursor: "pointer",
                            }}
                            onClick={() => onBannerDeleteAction(record.id)}
                        />
                    </Space>
                );
            },
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={banners?.content}
            rowKey="id"
            pagination={{
                current: banners?.page,
                total: banners?.totalElements,
                onChange: handlePaginationChange,
                pageSize: banners?.size,
                hideOnSinglePage: false,
            }}
            loading={isLoading}
        />
    );
};
