import cn from "classnames";

import {MDBCollapse, MDBNavbar, MDBNavbarNav, MDBNavbarToggler} from "mdbreact";
import React, {useEffect, useState} from "react";
import {useStores} from "../../../hooks/use-stores";

import styles from "./header.module.sass";
import {observer} from "mobx-react-lite";
import HeaderBellItem from "./header-bell-item";
import HeaderAvatarItem from "./header-avatar-item";
import HeaderCoinsItem from "./header-coins-item";
import HeaderRatingItem from "./header-rating-item";
import HeaderAuthItem from "./header-auth-item";
import HeaderTabs from "./header-tabs";
import HeaderBrand from "./header-brand";
import {useHistory} from "react-router-dom";

interface IProps {
    tabs: any[];
    withLogoText?: boolean;
    userAccountPath?: string;
}

export const Header = observer(({tabs, userAccountPath, withLogoText = true}: IProps) => {
    const {appStore} = useStores();
    const [navbarIsCollapsed, setNavbarIsCollapsed] = useState(false);

    const history = useHistory();

    useEffect(() => {
        history.listen(() => {
            setNavbarIsCollapsed(false);
        });
    }, [history]);

    return (
        <MDBNavbar
            color="grey darken-3"
            expand={"md"}
            dark
            className={cn({
                [styles.header]: !appStore.isMobile,
                [styles.mobileHeaderContainer]: appStore.isMobile,
            })}
            style={{zIndex: 1}}
        >
            {!appStore.isMobile && <HeaderBrand withLogoText={withLogoText} />}

            <div
                className={cn({
                    [styles.mobileHeader]: appStore.isMobile,
                })}
            >
                <MDBNavbarToggler
                    className={cn({
                        [styles.mobileToggler]: appStore.isMobile,
                    })}
                    onClick={() => setNavbarIsCollapsed(!navbarIsCollapsed)}
                    left
                />
                {appStore.isAuthorized && appStore.isMobile && (
                    <div className={styles.mobileHeaderRight}>
                        <HeaderRatingItem />
                        <HeaderCoinsItem />
                        <HeaderBellItem />
                        <HeaderAvatarItem userAccountPath={userAccountPath || ""} />
                    </div>
                )}

                {!appStore.isAuthorized && appStore.isMobile && (
                    <MDBNavbarNav right className={styles.mobileHeaderAuth}>
                        <HeaderAuthItem />
                    </MDBNavbarNav>
                )}
            </div>

            <MDBCollapse isOpen={navbarIsCollapsed} navbar>
                <MDBNavbarNav left>
                    <HeaderTabs tabs={tabs} navbarIsCollapsed={navbarIsCollapsed} />
                </MDBNavbarNav>

                {appStore.isAuthorized && !appStore.isMobile && (
                    <MDBNavbarNav right>
                        <HeaderRatingItem />
                        <HeaderCoinsItem />
                        <HeaderBellItem />
                        {userAccountPath && <HeaderAvatarItem userAccountPath={userAccountPath} />}
                    </MDBNavbarNav>
                )}

                {!appStore.isAuthorized && !appStore.isMobile && (
                    <MDBNavbarNav right>
                        <HeaderAuthItem />
                    </MDBNavbarNav>
                )}
            </MDBCollapse>
        </MDBNavbar>
    );
});
