import ConfirmModal from "entries/confirm-modal/confirm-modal";
import PropTypes from "prop-types";
import React, {useState} from "react";

import styles from "views/components/edit-buttons/edit-buttons.module.sass";
import cn from "classnames";

export const EditButtons = ({
    editAction,
    deleteAction,
    playAction,
    viewAction,
    imageAction,
    itemId,
    withoutTopBorder = false,
    withoutRightBorder = false,
    withoutButtonBorder = false,
    isSolidBorder,
    className,
    withoutDeleteConfirmation = false,
    changePositionAction,
}) => {
    const itemIdIsSet = itemId !== undefined;
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    return (
        <div
            className={cn({
                [styles.container]: true,
                [className]: !!className,
            })}
        >
            {deleteAction && deleteModalIsOpen && (
                <ConfirmModal
                    isDanger
                    text="Вы действительно хотите удалить?"
                    isOpen={deleteModalIsOpen}
                    toggle={() => setDeleteModalIsOpen(!deleteModalIsOpen)}
                    handleClick={(isConfirmed) => {
                        setDeleteModalIsOpen(false);
                        if (isConfirmed) {
                            if (itemIdIsSet) {
                                deleteAction(itemId);
                            } else {
                                deleteAction();
                            }
                        }
                    }}
                />
            )}

            {playAction && (
                <div
                    className={cn({
                        [styles.editItems]: true,
                        [styles.isSolidBorder]: isSolidBorder,
                        [styles.withoutTopBorder]: withoutTopBorder,
                        [styles.withoutRightBorder]: withoutRightBorder,
                        [styles.withoutBottomBorder]:
                            withoutButtonBorder || (playAction && editAction),
                    })}
                >
                    <span onClick={playAction}>
                        <i className="fas fa-play edit-items m-0" />
                    </span>
                </div>
            )}
            {editAction && (
                <div
                    className={cn({
                        [styles.editItems]: true,
                        [styles.isSolidBorder]: isSolidBorder,
                        [styles.withoutTopBorder]: withoutTopBorder && !playAction,
                        [styles.withoutRightBorder]: withoutRightBorder,
                        [styles.withoutBottomBorder]:
                            withoutButtonBorder || (editAction && deleteAction),
                    })}
                >
                    <span onClick={itemIdIsSet ? () => editAction(itemId) : editAction}>
                        <i className="fas fa-pen edit-items text-center m-0" />
                    </span>
                </div>
            )}
            {deleteAction && (
                <div
                    className={cn({
                        [styles.editItems]: true,
                        [styles.isSolidBorder]: isSolidBorder,
                        [styles.withoutTopBorder]:
                            withoutTopBorder && !(editAction && deleteAction),
                        [styles.withoutRightBorder]: withoutRightBorder,
                        [styles.withoutBottomBorder]: withoutButtonBorder,
                    })}
                >
                    <span
                        onClick={() =>
                            withoutDeleteConfirmation
                                ? deleteAction(itemId)
                                : setDeleteModalIsOpen(true)
                        }
                    >
                        <i id="delete-action" className="fas fa-times edit-items m-0" />
                    </span>
                </div>
            )}
            {viewAction && (
                <div
                    className={cn({
                        [styles.editItems]: true,
                        [styles.isSolidBorder]: isSolidBorder,
                        [styles.withoutTopBorder]: withoutTopBorder,
                        [styles.withoutRightBorder]: withoutRightBorder,
                    })}
                >
                    <span onClick={viewAction}>
                        <i className="fas fa-eye edit-items m-0" />
                    </span>
                </div>
            )}
            {imageAction && (
                <div
                    className={cn({
                        [styles.editItems]: true,
                        [styles.isSolidBorder]: isSolidBorder,
                        [styles.withoutTopBorder]: withoutTopBorder,
                        [styles.withoutRightBorder]: withoutRightBorder,
                    })}
                >
                    <span onClick={imageAction}>
                        <i className="fas fa-image edit-items m-0"></i>
                    </span>
                </div>
            )}
            {changePositionAction && (
                <div
                    className={cn({
                        [styles.editItems]: true,
                        [styles.isSolidBorder]: isSolidBorder,
                        [styles.withoutTopBorder]: withoutTopBorder,
                        [styles.withoutRightBorder]: withoutRightBorder,
                    })}
                >
                    <span onClick={() => changePositionAction("up", itemId)}>
                        <i className="fas fa-solid fa-arrow-up m-0"></i>
                    </span>
                </div>
            )}
            {changePositionAction && (
                <div
                    className={cn({
                        [styles.editItems]: true,
                        [styles.isSolidBorder]: isSolidBorder,
                        [styles.withoutTopBorder]: withoutTopBorder,
                        [styles.withoutRightBorder]: withoutRightBorder,
                    })}
                >
                    <span onClick={() => changePositionAction("down", itemId)}>
                        <i className="fas fa-solid fa-arrow-down m-0"></i>
                    </span>
                </div>
            )}
        </div>
    );
};

EditButtons.propTypes = {
    className: PropTypes.string,
    deleteAction: PropTypes.func,
    editAction: PropTypes.func,
    isSolidBorder: PropTypes.bool,
    isTopRightPosition: PropTypes.bool,
    itemId: PropTypes.any,
    playAction: PropTypes.func,
    imageAction: PropTypes.func,
    viewAction: PropTypes.func,
    withoutButtonBorder: PropTypes.bool,
    withoutDeleteConfirmation: PropTypes.bool,
    withoutRightBorder: PropTypes.bool,
    withoutTopBorder: PropTypes.bool,
    changePositionAction: PropTypes.func,
};
