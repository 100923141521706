import {BASKETS_TEST} from "config/constructor-names";
import {BasketsAPI} from "entries/constructors/baskets-game/core/BasketsAPI";
import BasketsGameContainer from "entries/constructors/baskets-game/views/baskets-game/baskets-game-container";
import CreateTest from "entries/constructors/classic-test/views/containers/create-test/create-test";
import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import PropTypes from "prop-types";

export const BasketsGameManager = ({routerInstance}) => {
    let history = useHistory();
    const [testData, setTestData] = useState({});
    const [mode, setMode] = useState(null);

    useEffect(() => {
        if (
            !history.location.state &&
            !history.location.pathname.includes(
                routerInstance.BASKETS_TEST.EDITING.replace(":id", ""),
            )
        ) {
            history.push(routerInstance.ROOT);
        }

        setMode(history.location.state);
    }, []);

    const onCreateTest = (testData) => {
        setTestData(testData);
        history.push(routerInstance.BASKETS_TEST.CONSTRUCTOR);
    };

    return (
        <div>
            <Switch>
                <Route path={routerInstance.BASKETS_TEST.CREATE_TEST}>
                    <CreateTest
                        onCreateTest={onCreateTest}
                        apiInstance={BasketsAPI}
                        mode={mode}
                        constructorType={BASKETS_TEST}
                    />
                </Route>

                <Route path={routerInstance.BASKETS_TEST.EDITING}>
                    <BasketsGameContainer testData={testData} isEditMode={true} />
                </Route>

                <Route path={routerInstance.BASKETS_TEST.CONSTRUCTOR}>
                    <BasketsGameContainer testData={testData} mode={mode} />
                </Route>
            </Switch>
        </div>
    );
};

BasketsGameManager.propTypes = {
    routerInstance: PropTypes.any,
};
