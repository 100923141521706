import React from "react";
import cn from "classnames";
import {SIZE_M, SIZE_S, SIZE_XL} from "utils/answers-config";
import styles from "entries/constructors/classic-test/views/components/image-size-buttons/buttons-image-size.module.sass";
import PropTypes from "prop-types";

export const DEFAULT_SIZES = [
    {value: SIZE_S, name: "S"},
    {value: SIZE_M, name: "M"},
    {value: SIZE_XL, name: "XL"},
];

export const ButtonsImageSize = ({
    availableSizes = DEFAULT_SIZES,
    handleClick,
    currentImageSize,
    className,
}) => {
    return (
        <div
            className={cn({
                "btn-group": true,
                [styles.container]: true,
                [className]: !!className,
            })}
            role="group"
            aria-label="property-column"
        >
            {availableSizes.map((size, index) => (
                <button
                    key={index}
                    type="button"
                    className={cn({
                        btn: true,
                        "btn-sm": true,
                        [styles.active]: currentImageSize === size.value,
                    })}
                    onClick={() => handleClick(size.value)}
                >
                    {size.name}
                </button>
            ))}
        </div>
    );
};

ButtonsImageSize.propTypes = {
    availableSizes: PropTypes.array,
    className: PropTypes.string,
    currentImageSize: PropTypes.string,
    handleClick: PropTypes.func,
};
