import React from "react";
import cn from "classnames";
import styles from "./styles.module.sass";

type HeaderItemProps = {
    item: {label: string; value: string};
    isActive: boolean;
    className?: string;
    onClick: (value: string) => void;
};

export const HeaderItem = ({isActive, item, className = "", onClick}: HeaderItemProps) => {
    return (
        <div
            className={cn({
                [styles.headerItem]: true,
                [styles.activeItem]: isActive,
                [className]: className,
            })}
            onClick={() => onClick(item.value)}
        >
            <p>{item.label}</p>
        </div>
    );
};
