import React from "react";
import styles from "./styles.module.sass";
import {EditButtons} from "../../../../views/components/edit-buttons";
import cn from "classnames";
import {EPublicationCustomActionTypes} from "../../../../enums/EPublicationCustomActionTypes";

interface IProps {
    block: any;
    isViewMode: boolean;
    handleDelete: (id: number) => void;
    handleCustomAction: (type: EPublicationCustomActionTypes, payload: any) => void;
}

const ArticleMonogramBlock = ({block, handleDelete, isViewMode}: IProps) => {
    return (
        <div
            className={cn({
                [styles.monogramBlock]: true,
                [styles.editMode]: !isViewMode,
            })}
        >
            <img
                className={styles.divider}
                src={require("../../../../assets/images/static/divider.svg")}
                alt="divider"
            />

            {!isViewMode && (
                <EditButtons
                    deleteAction={() => handleDelete(block.id)}
                    itemId={block.id}
                    isSolidBorder
                />
            )}
        </div>
    );
};

export default ArticleMonogramBlock;
