import {MDBBtn, MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import React, {useEffect, useState} from "react";
import CKEditor from "react-ckeditor-component";

const CKEditorTextModal = ({isOpen, toggle, handleSave, blockForEdit}) => {
    const [value, setValue] = useState("");

    useEffect(() => {
        if (blockForEdit?.blockText) {
            setValue(blockForEdit.blockText);
        }
    }, [blockForEdit]);

    return (
        <MDBModal isOpen={isOpen} toggle={toggle} size="lg">
            <MDBModalHeader toggle={toggle} className="modal-header">
                <p>Редактировать текст</p>
            </MDBModalHeader>
            <MDBModalBody>
                <CKEditor
                    content={value}
                    events={{
                        change: (evt) => setValue(evt.editor.getData()),
                    }}
                />
                <MDBBtn onClick={() => handleSave(value)} color="success" className="mt-3">
                    Сохранить
                </MDBBtn>
            </MDBModalBody>
        </MDBModal>
    );
};

export default CKEditorTextModal;
