import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import RatingsHeader from "./ratings-header/ratings-header";
import {useLocation} from "react-router-dom";
import Thumbler from "../thumbler/thumbler";
import {RATING_ROUTES} from "../../routes/main";
import {RatingsAPI} from "./ratings-api";
import RatingsList from "./ratings-list/ratings-list";
import {useStores} from "../../hooks/use-stores";
import {toast} from "react-toastify";
import {Helmet} from "react-helmet";

const RatingsPage = () => {
    const [ratings, setRatings] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [filterSelect, setFilterSelect] = useState("ALL");
    const [personalRating, setPersonalRating] = useState({
        place: 0,
        rating: 0,
        school: {},
    });

    const {userStore} = useStores();
    let location = useLocation();

    const loadRatings = () => {
        let API: any;
        let PersonalRatingAPI: any;

        let userId = userStore.user?.userDto?.id;
        if (!userId) {
            toast.error("Ошибка получения данных");
            return;
        }

        if (location.pathname === RATING_ROUTES.ACADEMY) {
            if (filterSelect === "ALL") {
                API = RatingsAPI.getAcademyRatings(20, page);
                PersonalRatingAPI = RatingsAPI.getUserAcademyRating(userId);
            }
            if (filterSelect === "MONTH") {
                API = RatingsAPI.getAcademyRatingsByMonth(20, page);
                PersonalRatingAPI = RatingsAPI.getUserAcademyRatingByMonth(userId);
            }
            if (filterSelect === "WEEK") {
                API = RatingsAPI.getAcademyRatingsByWeek(20, page);
                PersonalRatingAPI = RatingsAPI.getUserAcademyRatingByWeek(userId);
            }
        }

        if (location.pathname === RATING_ROUTES.TOURNAMENTS) {
            if (filterSelect === "ALL") {
                API = RatingsAPI.getTournamentRatings(20, page);
                PersonalRatingAPI = RatingsAPI.getUserTournamentRating(userId);
            }
            if (filterSelect === "MONTH") {
                API = RatingsAPI.getTournamentRatingsByMonth(20, page);
                PersonalRatingAPI = RatingsAPI.getUserTournamentRatingByMonth(userId);
            }
            if (filterSelect === "WEEK") {
                API = RatingsAPI.getTournamentRatingsByWeek(20, page);
                PersonalRatingAPI = RatingsAPI.getUserTournamentRatingByWeek(userId);
            }
        }

        if (location.pathname === RATING_ROUTES.SCHOOLS) {
            if (filterSelect === "ACADEMY") {
                API = RatingsAPI.getSchoolAcademy(20, page);
                PersonalRatingAPI = RatingsAPI.getUserSchoolAcademyRating(userId);
            }
            if (filterSelect === "TOURNAMENT") {
                API = RatingsAPI.getSchoolTournament(20, page);
                PersonalRatingAPI = RatingsAPI.getUserSchoolTournamentRating(userId);
            }
        }

        if (!API || !PersonalRatingAPI) {
            setRatings({
                last: true,
                content: [],
            });

            return;
        }

        API.then((res: any) => {
            let data = res.data;
            if (ratings?.content) {
                let newContent = data.content;
                data.content = [...ratings.content, ...newContent];
            }

            setRatings(data);
        });

        PersonalRatingAPI.then((res: any) => {
            setPersonalRating(res.data);
        }).catch(() => setPersonalRating({place: 0, rating: 0, school: {}}));
    };

    useEffect(() => {
        setRatings(null);
        setPage(1);
    }, [filterSelect]);

    useEffect(() => {
        setPage(1);
        setRatings(null);
        if (location.pathname === RATING_ROUTES.SCHOOLS) {
            setFilterSelect("ACADEMY");
        } else {
            setFilterSelect("ALL");
        }
    }, [location]);

    useEffect(() => {
        if (!ratings) {
            loadRatings();
        }
    }, [ratings]);

    const loadMore = () => {
        setPage((prev) => prev + 1);
    };

    useEffect(() => {
        if (page > 1) {
            loadRatings();
        }
    }, [page]);

    return (
        <React.Fragment>
            {location.pathname === RATING_ROUTES.ACADEMY ? (
                <Helmet>
                    <title>Рейтинг Академии Умной Вороны</title>
                    <meta
                        name="description"
                        content="Лучшие ученики Академии Умной Вороны за все время, за месяц и за неделю."
                    />
                </Helmet>
            ) : location.pathname === RATING_ROUTES.TOURNAMENTS ? (
                <Helmet>
                    <title>Рейтинг Турниров Умной Вороны</title>
                    <meta
                        name="description"
                        content="Лучше участиники Турниров Умной Вороны за все время, за месяц и за неделю."
                    />
                </Helmet>
            ) : location.pathname === RATING_ROUTES.SCHOOLS ? (
                <Helmet>
                    <title>Рейтинг школ Умной Вороны</title>
                    <meta
                        name="description"
                        content="Лучшие школы в рейтинге Умной Вороны по успехам учеников."
                    />
                </Helmet>
            ) : null}

            <div className={styles.ratingsWrapper}>
                <RatingsHeader
                    place={personalRating.place}
                    rating={personalRating.rating}
                    filter={filterSelect}
                    school={personalRating.school}
                />

                <Thumbler
                    activeState={filterSelect}
                    setActiveState={setFilterSelect}
                    options={
                        location.pathname === RATING_ROUTES.ACADEMY ||
                        location.pathname === RATING_ROUTES.TOURNAMENTS
                            ? [
                                  {value: "ALL", name: "За всё время"},
                                  {value: "MONTH", name: "За прошлый месяц"},
                                  {value: "WEEK", name: "За прошлую неделю"},
                              ]
                            : [
                                  {value: "ACADEMY", name: "В Академии"},
                                  {value: "TOURNAMENT", name: "В Турнирах"},
                              ]
                    }
                    className={styles.filter}
                />

                <RatingsList ratings={ratings} handleLoadMore={loadMore} filter={filterSelect} />
            </div>
        </React.Fragment>
    );
};

export default RatingsPage;
