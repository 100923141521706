import academy2 from "assets/backgrounds/academy/academy_02.svg";
import tournament1 from "assets/backgrounds/tournaments/tournament_01.png";
import school from "assets/backgrounds/school.png";
import {url} from "config/url";
import {ACADEMY_ROUTES, RATING_ROUTES, TOURNAMENT_ROUTES} from "routes/main";

const BACKGROUNDS_TYPES = {
    BACKGROUND_TOURNAMENT: "BACKGROUND_TOURNAMENT",
    BACKGROUND_ACADEMY: "BACKGROUND_ACADEMY",
    BACKGROUND_PLAYER: "BACKGROUND_PLAYER",
};

export const BACKGROUNDS = [
    {
        name: "tournament",
        path: tournament1,
        url: TOURNAMENT_ROUTES.ROOT,
        type: BACKGROUNDS_TYPES.BACKGROUND_TOURNAMENT,
    },
    {
        name: "academy1",
        path: academy2,
        url: ACADEMY_ROUTES.ROOT,
        type: BACKGROUNDS_TYPES.BACKGROUND_ACADEMY,
    },
    {
        name: "school",
        path: school,
        url: RATING_ROUTES.SCHOOLS,
        type: BACKGROUNDS_TYPES.BACKGROUND_ACADEMY,
    },
    {
        name: "player01",
        path: url + "data/image-bank/646c1100-91bc-48a3-9d7c-2d087a52815d.png",
        url: ACADEMY_ROUTES.SETS.PLAY.replace(":id", ""),
        type: BACKGROUNDS_TYPES.BACKGROUND_PLAYER,
    },
];

const getBackgroundType = (location) => {
    if (location.includes(ACADEMY_ROUTES.SETS.PLAY.replace(":id", ""))) {
        return BACKGROUNDS_TYPES.BACKGROUND_PLAYER;
    } else if (location.includes(TOURNAMENT_ROUTES.ROOT)) {
        return BACKGROUNDS_TYPES.BACKGROUND_TOURNAMENT;
    } else if (location.includes(ACADEMY_ROUTES.ROOT)) {
        return BACKGROUNDS_TYPES.BACKGROUND_ACADEMY;
    }
};

export const getBackground = (location) => {
    if (!location) {
        console.log("[BACKGROUND_SYSTEM] Location is undefined");
        return;
    }

    let type = getBackgroundType(location);
    let background = BACKGROUNDS.find((background) => background.url === location);

    if (!background && type) {
        background = BACKGROUNDS.find((background) => background.type === type);
    }

    return background;
};
