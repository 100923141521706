import React from "react";
import AdminPanelLayout from "./admin-panel-layout/admin-panel-layout";
import {Route, Switch} from "react-router-dom";
import {ADMIN_PANEL_ROUTE_COMPONENTS} from "./core/routes";

const AdminPanel = () => {
    return (
        <AdminPanelLayout>
            <Switch>
                {ADMIN_PANEL_ROUTE_COMPONENTS.map((route) => (
                    <Route key={route.id} path={route.path}>
                        {route.component}
                    </Route>
                ))}
            </Switch>
        </AdminPanelLayout>
    );
};

export default AdminPanel;
