import {url} from "../../../config/index";

export const PromoSets = [
    {
        id: 1,
        classId: 1,
        name: "Русский язык",
        logoSrc: url + "data/image-bank/500794cd-c4e6-48ed-9b98-46228899eb54.svg",
        setId: 22,
    },
    {
        id: 2,
        classId: 1,
        name: "Математика",
        logoSrc: url + "data/image-bank/c66b5b91-634a-4e1a-b643-c4dcefb02e0e.svg",
        setId: 216,
    },
    {
        id: 3,
        classId: 1,
        name: "Чтение",
        logoSrc: url + "data/image-bank/f907f570-091a-40f3-83f5-e6a3b59d8afc.svg",
        setId: 797,
    },
    {
        id: 4,
        classId: 1,
        name: "Окружающий мир",
        logoSrc: url + "data/image-bank/7a267407-adba-4074-b21d-13e2d2ed7660.svg",
        setId: 223,
    },
    {
        id: 5,
        classId: 1,
        name: "Этика",
        logoSrc: url + "data/image-bank/d123ffb5-8a7b-40b7-b268-841bbad433ab.svg",
        setId: 813,
    },
    {
        id: 6,
        classId: 2,
        name: "Русский язык",
        logoSrc: url + "data/image-bank/500794cd-c4e6-48ed-9b98-46228899eb54.svg",
        setId: 113,
    },
    {
        id: 7,
        classId: 2,
        name: "Математика",
        logoSrc: url + "data/image-bank/c66b5b91-634a-4e1a-b643-c4dcefb02e0e.svg",
        setId: 657,
    },
    {
        id: 8,
        classId: 2,
        name: "Чтение",
        logoSrc: url + "data/image-bank/f907f570-091a-40f3-83f5-e6a3b59d8afc.svg",
        setId: 914,
    },
    {
        id: 9,
        classId: 2,
        name: "Окружающий мир",
        logoSrc: url + "data/image-bank/7a267407-adba-4074-b21d-13e2d2ed7660.svg",
        setId: 374,
    },
    {
        id: 10,
        classId: 2,
        name: "Английский язык",
        logoSrc: url + "data/image-bank/36ae4010-8700-4a0f-9d95-e6e0c5bb9095.svg",
        setId: 189,
    },
    {
        id: 11,
        classId: 2,
        name: "Этика",
        logoSrc: url + "data/image-bank/d123ffb5-8a7b-40b7-b268-841bbad433ab.svg",
        setId: 813,
    },
    {
        id: 12,
        classId: 3,
        name: "Русский язык",
        logoSrc: url + "data/image-bank/500794cd-c4e6-48ed-9b98-46228899eb54.svg",
        setId: 164,
    },
    {
        id: 13,
        classId: 3,
        name: "Математика",
        logoSrc: url + "data/image-bank/c66b5b91-634a-4e1a-b643-c4dcefb02e0e.svg",
        setId: 705,
    },
    {
        id: 14,
        classId: 3,
        name: "Чтение",
        logoSrc: url + "data/image-bank/f907f570-091a-40f3-83f5-e6a3b59d8afc.svg",
        setId: 888,
    },
    {
        id: 15,
        classId: 3,
        name: "Окружающий мир",
        logoSrc: url + "data/image-bank/7a267407-adba-4074-b21d-13e2d2ed7660.svg",
        setId: 617,
    },
    {
        id: 16,
        classId: 3,
        name: "Английский язык",
        logoSrc: url + "data/image-bank/36ae4010-8700-4a0f-9d95-e6e0c5bb9095.svg",
        setId: 285,
    },
    {
        id: 17,
        classId: 3,
        name: "Этика",
        logoSrc: url + "data/image-bank/d123ffb5-8a7b-40b7-b268-841bbad433ab.svg",
        setId: 813,
    },

    {
        id: 18,
        classId: 4,
        name: "Русский язык",
        logoSrc: url + "data/image-bank/500794cd-c4e6-48ed-9b98-46228899eb54.svg",
        setId: 516,
    },
    {
        id: 19,
        classId: 4,
        name: "Математика",
        logoSrc: url + "data/image-bank/c66b5b91-634a-4e1a-b643-c4dcefb02e0e.svg",
        setId: 958,
    },
    {
        id: 20,
        classId: 4,
        name: "Чтение",
        logoSrc: url + "data/image-bank/f907f570-091a-40f3-83f5-e6a3b59d8afc.svg",
        setId: 968,
    },
    {
        id: 21,
        classId: 4,
        name: "Окружающий мир",
        logoSrc: url + "data/image-bank/7a267407-adba-4074-b21d-13e2d2ed7660.svg",
        setId: 521,
    },
    {
        id: 22,
        classId: 4,
        name: "Английский язык",
        logoSrc: url + "data/image-bank/36ae4010-8700-4a0f-9d95-e6e0c5bb9095.svg",
        setId: 329,
    },
    {
        id: 23,
        classId: 4,
        name: "Этика",
        logoSrc: url + "data/image-bank/d123ffb5-8a7b-40b7-b268-841bbad433ab.svg",
        setId: 813,
    },
    {
        id: 24,
        classId: 1,
        name: "Логика",
        logoSrc: url + "data/image-bank/5fcc7b57-8a29-4039-8c06-bd89b8dab22f.svg",
        setId: 1422,
    },
    {
        id: 25,
        classId: 2,
        name: "Логика",
        logoSrc: url + "data/image-bank/5fcc7b57-8a29-4039-8c06-bd89b8dab22f.svg",
        setId: 1422,
    },
    {
        id: 26,
        classId: 3,
        name: "Логика",
        logoSrc: url + "data/image-bank/5fcc7b57-8a29-4039-8c06-bd89b8dab22f.svg",
        setId: 1422,
    },
    {
        id: 27,
        classId: 4,
        name: "Логика",
        logoSrc: url + "data/image-bank/5fcc7b57-8a29-4039-8c06-bd89b8dab22f.svg",
        setId: 1422,
    },
];
