import React from "react";
import ArticleTextBlock from "./article-text-block/article-text-block";
import {EPublicationBlockTypes} from "../../../enums/EPublicationBlockTypes";
import {IPublicationBlockText} from "../../../interfaces/IPublicationBlockText";
import {IPublicationBlockImage} from "../../../interfaces/IPublicationBlockImage";
import ArticleImageBlock from "./article-image-block/article-image-block";
import ArticleGalleryBlock from "./article-gallery-block/article-gallery-block";
import {EPublicationCustomActionTypes} from "../../../enums/EPublicationCustomActionTypes";
import ArticlePlayerBlock from "./article-player-block/article-player-block";
import ArticleFileBlock from "./article-file-block/article-file-block";
import ArticleSimilarBlock from "./article-similar-block/article-similar-block";
import ArticleMonogramBlock from "./article-monogram-block/article-monogram-block";

import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import ArticleVideoBlock from "./article-video-block/article-video-block";

interface IProps {
    blocks: Array<IPublicationBlockText | IPublicationBlockImage> | undefined;
    isViewMode: boolean;
    isDragOn: boolean;
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
    handleAddImage: (id: number) => void;
    handleCustomAction: (type: EPublicationCustomActionTypes, payload: any) => void;
    handleDragEnd: (action: any) => void;
}

const isDraggingOverStyles = (isDraggingOver: any) => ({
    border: isDraggingOver && "2px solid rgb(0, 166, 210)",
});

const isDraggingItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    background: isDragging && "rgba(41,105,132,0.1)",
    border: isDragging && "2px dashed gold",
    ...draggableStyle,
});

const ArticleBlocks = ({
    blocks,
    handleEdit,
    handleDelete,
    isViewMode,
    isDragOn,
    handleAddImage,
    handleCustomAction,
    handleDragEnd,
}: IProps) => {
    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="article-droppable" isDropDisabled={!isDragOn}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={isDraggingOverStyles(snapshot.isDraggingOver)}
                    >
                        {blocks
                            ?.sort((a, b) => {
                                if (!a.positionBlock || !b.positionBlock) {
                                    return 1;
                                }

                                return a.positionBlock - b.positionBlock;
                            })
                            .map((block, index) => {
                                let blockForRender: any = null;
                                switch (block.objectType) {
                                    case EPublicationBlockTypes.PublicationBlockTextDto:
                                        blockForRender = (
                                            <ArticleTextBlock
                                                key={block.id}
                                                block={block}
                                                isViewMode={isViewMode}
                                                handleEdit={handleEdit}
                                                handleDelete={handleDelete}
                                                handleCustomAction={handleCustomAction}
                                            />
                                        );
                                        break;
                                    case EPublicationBlockTypes.PublicationBlockImageDto:
                                        blockForRender = (
                                            <ArticleImageBlock
                                                key={block.id}
                                                block={block}
                                                isViewMode={isViewMode}
                                                handleEdit={handleEdit}
                                                handleDelete={handleDelete}
                                                handleAddImage={handleAddImage}
                                                handleCustomAction={handleCustomAction}
                                            />
                                        );
                                        break;

                                    case EPublicationBlockTypes.PublicationBlockGalleryDto:
                                        blockForRender = (
                                            <ArticleGalleryBlock
                                                key={block.id}
                                                block={block}
                                                isViewMode={isViewMode}
                                                handleEdit={handleEdit}
                                                handleDelete={handleDelete}
                                                handleCustomAction={handleCustomAction}
                                            />
                                        );
                                        break;
                                    case EPublicationBlockTypes.PublicationBlockPlayerDto:
                                        blockForRender = (
                                            <ArticlePlayerBlock
                                                key={block.id}
                                                block={block}
                                                isViewMode={isViewMode}
                                                handleEdit={handleEdit}
                                                handleDelete={handleDelete}
                                                handleCustomAction={handleCustomAction}
                                            />
                                        );
                                        break;
                                    case EPublicationBlockTypes.PublicationBlockDocumentDto:
                                        blockForRender = (
                                            <ArticleFileBlock
                                                key={block.id}
                                                block={block}
                                                isViewMode={isViewMode}
                                                handleEdit={handleEdit}
                                                handleDelete={handleDelete}
                                                handleCustomAction={handleCustomAction}
                                            />
                                        );
                                        break;
                                    case EPublicationBlockTypes.PublicationBlockSimilarDto:
                                        blockForRender = (
                                            <ArticleSimilarBlock
                                                key={block.id}
                                                block={block}
                                                isViewMode={isViewMode}
                                                handleDelete={handleDelete}
                                                handleCustomAction={handleCustomAction}
                                            />
                                        );
                                        break;
                                    case EPublicationBlockTypes.PublicationBlockLineDto:
                                        blockForRender = (
                                            <ArticleMonogramBlock
                                                key={block.id}
                                                block={block}
                                                isViewMode={isViewMode}
                                                handleDelete={handleDelete}
                                                handleCustomAction={handleCustomAction}
                                            />
                                        );
                                        break;
                                    case EPublicationBlockTypes.PublicationBlockVideoDto:
                                    case EPublicationBlockTypes.PublicationBlockVideoSharingDto:
                                        blockForRender = (
                                            <ArticleVideoBlock
                                                key={block.id}
                                                block={block}
                                                isViewMode={isViewMode}
                                                handleDelete={handleDelete}
                                                handleCustomAction={handleCustomAction}
                                            />
                                        );
                                    default:
                                        break;
                                }

                                return (
                                    <div key={block.id.toString()}>
                                        {index === 4 ? (
                                            <div
                                                id="yandex_rtb_R-A-1983523-3"
                                                style={{
                                                    maxHeight: "300px",
                                                    marginTop: "2rem",
                                                    marginBottom: "2rem",
                                                }}
                                            ></div>
                                        ) : null}

                                        <Draggable
                                            draggableId={block?.id.toString()}
                                            index={index}
                                            isDragDisabled={!isDragOn}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={isDraggingItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style,
                                                    )}
                                                >
                                                    {blockForRender}
                                                </div>
                                            )}
                                        </Draggable>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default ArticleBlocks;
