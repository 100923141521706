import bottomBorder from "assets/sliders/buy-subscription/bottom-right-border.svg";
import topBorder from "assets/sliders/buy-subscription/top-left-border.svg";
import cn from "classnames";
import React from "react";
import ReactCardFlip from "react-card-flip";
import styles from "./styles.module.sass";
import {url} from "../../config";

interface IProps {
    children: React.ReactNode;
    backgroundHeaderURL: string;
    logoHeaderURL: string;
    title: string;
    description: string;
}

const PremiumCard = ({
    children,
    description,
    title,
    backgroundHeaderURL,
    logoHeaderURL,
}: IProps) => {
    const backgroundStyle = {
        backgroundImage: `url(${url + backgroundHeaderURL})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    return (
        <div
            className={cn({
                [styles.card]: true,
                animate__animated: true,
            })}
        >
            <img src={topBorder} className={styles.borderTop} alt="topLeftBorder" />
            <img src={bottomBorder} className={styles.borderBot} alt="botRightBorder" />
            <ReactCardFlip isFlipped={false} flipDirection="horizontal">
                <div className={styles.frontCard}>
                    <div className={styles.frontCardHeader} style={backgroundStyle}>
                        <img src={url + logoHeaderURL} alt="logo" className={styles.headerLogo} />
                    </div>
                    <div className={styles.frontCardBody}>
                        <p className={styles.title}>{title}</p>
                        <p className={styles.description}>{description}</p>
                        {children}
                    </div>
                </div>

                <div className={styles.backCard}>test 2</div>
            </ReactCardFlip>
        </div>
    );
};

export default PremiumCard;
