import {MDBBadge} from "mdbreact";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import styles from "./styles.module.sass";

interface IProps {
    title: string;
    icon: string;
    color: string;
    path: string;
    isSuperAdmin?: boolean;
}

const AdminPanelItemCard = ({title, path, icon, color, isSuperAdmin}: IProps) => {
    const [isHover, setIsHover] = useState(false);
    const history = useHistory();
    return (
        <div
            className={styles.card}
            onClick={() => history.push(path)}
            style={{boxShadow: isHover ? `0 0 15px ${color}` : `0 0 5px ${color}`}}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            {isSuperAdmin && (
                <MDBBadge className={styles.cardBadge} color="danger">
                    SUPER ADMIN
                </MDBBadge>
            )}

            <div className={styles.icon} style={{backgroundColor: color}}>
                <i className={icon}></i>
            </div>
            <p className={styles.title}>{title}</p>
        </div>
    );
};

export default AdminPanelItemCard;
