export const WS_TYPES = {
    ACHIEVEMENT: "SocketMessageAchievementDto",
    TASK: "SocketMessageTaskDto",
    STUDENT: "SocketMessageStudentDto",
    NOTIFICATION: "SocketMessageNotificationDto",
    NEWS: "SocketMessageNewsDto",
    RATING: "SocketMessageRatingDto",
    ACTION: "SocketMessageActionDto",
    OTHER: "SocketMessageOtherDto",
};
