import React from "react";
import PropTypes from "prop-types";

import {ThirdLevelSectionSelect} from "./third-level-section-select";
import {SecondLevelSectionSelect} from "./second-level-section-select";
import {FirstLevelSectionSelect} from "./first-level-section-select";
import {TagsSelect} from "./tags-select";

export const TagSectionSelect = (props) => {
    const [thirdLevelSectionTag, setThirdLevelSectionTag] = React.useState();
    const [secondLevelSectionTag, setSecondLevelSectionTag] = React.useState();
    const [firstLevelSectionTag, setFirstLevelSectionTag] = React.useState();

    const makeTopLevelSectionChangeHandler = (handler) => (tag) => {
        handler(tag);

        if (props.onTagSectionChange && !tag) {
            props.onTagSectionChange(null);
        }
    };

    const handleThirdLevelSectionSelect = React.useCallback(
        makeTopLevelSectionChangeHandler(setThirdLevelSectionTag),
        [],
    );
    const handleSecondLevelSectionSelect = React.useCallback(
        makeTopLevelSectionChangeHandler(setSecondLevelSectionTag),
        [],
    );
    const handleFirstLevelSectionSelect = React.useCallback(
        makeTopLevelSectionChangeHandler(setFirstLevelSectionTag),
        [],
    );

    return (
        <div>
            <div className="mt-3">
                <ThirdLevelSectionSelect onChange={handleThirdLevelSectionSelect} />
            </div>
            <div className="mt-3">
                <SecondLevelSectionSelect
                    parentSectionId={thirdLevelSectionTag}
                    onChange={handleSecondLevelSectionSelect}
                />
            </div>
            <div className="mt-3">
                <FirstLevelSectionSelect
                    parentSectionId={secondLevelSectionTag}
                    onChange={handleFirstLevelSectionSelect}
                />
            </div>
            <div className="mt-3">
                <TagsSelect
                    parentSectionId={firstLevelSectionTag}
                    onChange={props.onTagSectionChange}
                />
            </div>
        </div>
    );
};

TagSectionSelect.propTypes = {
    onTagSectionChange: PropTypes.func,
};
