import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import {ADMIN_PANEL_ROUTES} from "../../../entries/admin-panel/core/routes";
import ArticleControlHeader from "../../../entries/articles/article-control-header/article-control-header";
import NewArticleModal from "../../../entries/articles/article-modals/new-article-modal/new-article-modal";
import Article from "../../../entries/articles/article/article";
import {ArticlesList} from "../../../entries/articles/articles-list/articles-list";

import {ArticleAPI} from "../../../entries/articles/core/article-api";
import SpinnerWithText from "../../../entries/spinner/spinner";
import {IPublication} from "../../../interfaces/IPublication";

const ArticlesPage = () => {
    let history = useHistory();
    const [articles, setArticles] = useState<IPublication[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [articleForEdit, setArticleForEdit] = useState<IPublication | null>(null);
    const [createModalStep, setCreateModalStep] = useState(1);
    const [pagination, setPagination] = useState(null);
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        getArticles();
    }, [activePage]);

    useEffect(() => {
        if (!createModalIsOpen) {
            setArticleForEdit(null);
            setCreateModalStep(1);
        }
    }, [createModalIsOpen]);

    const getArticles = () => {
        setIsLoading(true);
        ArticleAPI.getPublications(activePage)
            .then((response) => {
                setArticles(response.data.content);
                setPagination({...response.data, content: null});
            })

            .finally(() => setIsLoading(false));
    };

    const handleDeletePublication = (id: number) => {
        setIsLoading(true);
        ArticleAPI.deletePublication(id)
            .then(() => {
                getArticles();
            })
            .finally(() => setIsLoading(false));
    };

    const handleCreateNewArticle = (article: IPublication) => {
        if (createModalStep === 1) {
            ArticleAPI.createPublication(article).then((response) => {
                getArticles();
                setCreateModalStep(2);
                setArticleForEdit(response.data);
            });
        }
    };

    const handleEditArticle = (article: IPublication) => {
        if (createModalStep === 1) {
            ArticleAPI.updatePublication(article).then((response) => {
                setArticleForEdit(response.data);
                setCreateModalStep(2);
            });
        } else {
            setCreateModalStep(1);

            history.push(
                ADMIN_PANEL_ROUTES.ARTICLES.EDIT.replace(
                    ":url",
                    articleForEdit?.publicationUrl || "",
                ),
            );

            setCreateModalIsOpen(false);
            setArticleForEdit(null);
        }
    };

    const handleEditPublication = (publication: IPublication) => {
        setArticleForEdit(publication);
        setCreateModalIsOpen(true);
    };

    const handleSaveArticle = (article: IPublication) => {
        if (articleForEdit) {
            handleEditArticle(article);
        } else {
            handleCreateNewArticle(article);
        }
    };

    if (isLoading) {
        return <SpinnerWithText text="Загрузка статей..." />;
    }

    return (
        <div>
            {createModalIsOpen && (
                <NewArticleModal
                    isOpen={createModalIsOpen}
                    toggle={() => setCreateModalIsOpen(!createModalIsOpen)}
                    handleSave={handleSaveArticle}
                    articleForEdit={articleForEdit}
                    createModalStep={createModalStep}
                    setCreateModalStep={setCreateModalStep}
                />
            )}

            <Switch>
                <Route path={ADMIN_PANEL_ROUTES.ARTICLES.ROOT} exact>
                    <ArticleControlHeader handleCreateClick={() => setCreateModalIsOpen(true)} />
                    <ArticlesList
                        articles={articles}
                        isLoading={isLoading}
                        handleDeletePublication={handleDeletePublication}
                        handleEditPublication={handleEditPublication}
                        activePage={activePage}
                        handleChangePage={setActivePage}
                        pagination={pagination}
                    />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.ARTICLES.CREATE}>
                    <Article />
                </Route>
                <Route path={ADMIN_PANEL_ROUTES.ARTICLES.EDIT}>
                    <Article />
                </Route>
            </Switch>
        </div>
    );
};

export default ArticlesPage;
