import React from "react";
import styles from "./styles.module.sass";
import {TagManagerPage} from "../../../../views/pages/admin/tag-manager";

const AdminPanelTagsConstructor = () => {
    return (
        <div className={styles.wrapper}>
            <TagManagerPage />
        </div>
    );
};

export default AdminPanelTagsConstructor;
