import {url} from "config";
import {MDBBtn} from "mdbreact";
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.sass";

const PlayerV2TournamentResult = ({handleToTournamentPage}) => {
    return (
        <div className={styles.result}>
            <p className={styles.result__title}>Турнир пройден</p>
            <img
                src={url + "data/image-bank/1b8fc493-fc09-416a-b6eb-e7f4cf3959e2.svg"}
                alt="raven-tournament"
                className={styles.result__image}
            />
            <p className={styles.result__description}>
                Результаты будут известны <br /> после завершения турнира.
            </p>
            <MDBBtn
                color="success"
                onClick={handleToTournamentPage}
                className={styles.result__button}
            >
                На страницу турнира
            </MDBBtn>
        </div>
    );
};

PlayerV2TournamentResult.propTypes = {
    handleToTournamentPage: PropTypes.func,
};

export default PlayerV2TournamentResult;
