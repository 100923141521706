export const MULTIPART_FORM_DATA_CONTENT_TYPE_HEADER = {
    "Content-Type": "multipart/form-data",
};

export const getMultipartFormDataContentTypeHeader = (headers = {}) => {
    return {
        headers: {
            ...MULTIPART_FORM_DATA_CONTENT_TYPE_HEADER,
            ...headers,
        },
    };
};
