import {observer} from "mobx-react-lite";
import React from "react";
import {Route, Switch} from "react-router-dom";
import {ROUTE_PATHS} from "./route-paths";
import WelcomePage from "../views/pages/welcome-page/welcome-page";
import {useStores} from "../hooks/use-stores";
import NotificationModalLayer from "../entries/notifications-modal/notification-modal-layer";
import PlayerV2NotificationLoading from "../entries/player-v2/views/player-v2-notifications/player-v2-notification-loading/player-v2-notification-loading";
import RegisterFormContainer from "../entries/register-form/register-form-container";
import {PageRoutes, PAGES_ROUTES} from "../views/routes/routes";

import RavenHelper from "../entries/raven-helper/raven-helper";
import Article from "../entries/articles/article/article";
import {ADMIN_ROLE} from "../config/roles";
import {ADMIN_ROUTES} from "./admin";
import {PrivateRoute} from "../utils/private-route";
import {META_TAGS} from "../config/meta-tags";
import {Helmet} from "react-helmet";
import {HeaderContainer} from "../entries/header/views/header-container";
import {MainPage} from "../views/pages/main";
import AdminPanel from "../entries/admin-panel/admin-panel";

const Routes = observer(() => {
    const {appStore} = useStores();

    if (!appStore.isAppInitialized) {
        return (
            <NotificationModalLayer isActive={true}>
                <PlayerV2NotificationLoading />
            </NotificationModalLayer>
        );
    }

    return (
        <div style={{height: "100vh"}}>
            <Helmet>
                <title>{META_TAGS.MAIN.TITLE}</title>
                <meta name="description" content={META_TAGS.MAIN.DESCRIPTION} />
                <meta name="keywords" content={META_TAGS.MAIN.KEYWORDS} />
            </Helmet>

            <Switch>
                <Route path={ROUTE_PATHS.PUBLIC.MAIN} exact={true}>
                    <WelcomePage tabs={appStore.tabs} />
                </Route>

                <Route path={ROUTE_PATHS.PUBLIC.REGISTRATION}>
                    <RegisterFormContainer />
                </Route>

                {PageRoutes.map((page, index) => {
                    return (
                        <Route key={index} path={page.path} exact={page.exact}>
                            <div className="header position-relative">
                                <div className="header-container">
                                    <HeaderContainer
                                        tabs={appStore.tabs}
                                        withEvents={false}
                                        withAuth
                                        withLogoText
                                    />
                                </div>
                            </div>
                            {page.component}

                            {page.withHelper && <RavenHelper message="Листайте вниз" />}
                        </Route>
                    );
                })}

                <PrivateRoute roles={[ADMIN_ROLE]} path={ADMIN_ROUTES.NEW}>
                    <AdminPanel />
                </PrivateRoute>

                <Route
                    path={
                        PAGES_ROUTES.PUBLICATIONS.VIEW || PAGES_ROUTES.PUBLICATIONS.VIEW_BY_TAG_ID
                    }
                >
                    <div className="header position-relative">
                        <div className="header-container">
                            <HeaderContainer
                                tabs={appStore.tabs}
                                withEvents={false}
                                withAuth
                                withLogoText
                            />
                        </div>
                    </div>

                    <Article isPublic={true} />
                </Route>

                <Route path="/">
                    <MainPage />
                </Route>
            </Switch>
        </div>
    );
});

export default Routes;
