import React from "react";
import styles from "./header.module.sass";
import {RATING_ROUTES} from "../../../routes/main";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router-dom";
import {useStores} from "../../../hooks/use-stores";
import mobileStyles from "./style-mobile.module.sass";
import cn from "classnames";

const HeaderRatingItem = observer(() => {
    let history = useHistory();
    const {userStore, appStore} = useStores();
    return (
        <div
            className={cn({
                [styles.rating]: true,
                [mobileStyles.rating]: appStore.isMobile,
            })}
            onClick={() => history.push(RATING_ROUTES.ACADEMY)}
        >
            <img src={require("../../../assets/images/static/rating.svg")} alt="rating" />
            <p>{userStore.academyPlace || ""}</p>
        </div>
    );
});

export default HeaderRatingItem;
