import cn from "classnames";
import {url} from "config";
import {DEFAULT_FONT_SIZE} from "config/project";
import {BoxWithSymbols} from "entries/constructors/open-answer/views/box-with-symbols/box-with-symbols";
import styles from "entries/constructors/open-answer/views/modal-open-answer/modal-open-answer.module.sass";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {Question} from "views/components/question";

export const OpenAnswerPlayer = ({question, variants, properties, selectedAnswers, onDragEnd}) => {
    // данные для вопроса
    const [questionText, setQuestionText] = useState("");
    const [questionNarrator, setQuestionNarrator] = useState({});
    const [questionImage, setQuestionImage] = useState({});
    const [questionAudio, setQuestionAudio] = useState(null);

    // параметры
    const [questionSizeText, setQuestionSizeText] = useState(DEFAULT_FONT_SIZE);
    const [, setAnswerSizeText] = useState(DEFAULT_FONT_SIZE);
    const [background, setBackground] = useState("");

    useEffect(() => {
        if (question) {
            // вопрос
            if (question.questionText) {
                setQuestionText(question.questionText);
            }

            if (question.imageQuestion) {
                setQuestionImage({
                    id: question.imageQuestion.id,
                    url: question.imageQuestion.fileDownloadUri.slice(1),
                });
            }

            if (question.audio) {
                setQuestionAudio(question.audio);
            }
        }
    }, [question, questionSizeText]);

    useEffect(() => {
        if (properties) {
            // фон
            if (properties.backgroundImage) {
                setBackground(properties.backgroundImage.fileDownloadUri.slice(1));
            } else {
                setBackground("data/image-bank/ae86cc0f-5bc8-457f-98bb-454a0ea993a6.svg");
            }

            // персонаж
            if (properties.narrator) {
                let narratorUrl = `${url}${properties.narrator.image.fileDownloadUri.slice(1)}`;
                setQuestionNarrator({url: narratorUrl});
            }

            // размер шрифта вопроса
            if (properties.sizeTitle) {
                setQuestionSizeText(properties.sizeTitle);
            }

            // размер шрифта ответов
            if (properties.sizeText) {
                setAnswerSizeText(properties.sizeText);
            }
        }
    }, [properties]);

    return (
        <div className="h-100">
            {question || variants || properties ? (
                <TestContent background={background} withEditBorder={false}>
                    <TestConstructor>
                        <div className="animate__animated animate__fadeIn">
                            <Question
                                testHasNarrator
                                isEditMode={false}
                                text={questionText}
                                narrator={questionNarrator}
                                textTitleSize={questionSizeText}
                                image={questionImage}
                                audio={questionAudio}
                            />
                        </div>

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppableAnswerBox" direction="horizontal">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={cn({
                                            [styles.dragging]: snapshot.isDraggingOver,
                                            [styles.boxWithSymbols]: true,
                                            [styles.disabledBox]: variants.length === 0,
                                            "mt-2": true,
                                        })}
                                    >
                                        <BoxWithSymbols
                                            symbols={selectedAnswers}
                                            placeholder={"Переноси символы сюда и составляй ответ"}
                                            isShortContainer
                                            isForAnswer
                                        />
                                    </div>
                                )}
                            </Droppable>

                            <Droppable droppableId="droppableBoxWithSymbols" direction="horizontal">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={cn({
                                            [styles.boxWithSymbols]: true,
                                            [styles.dragging]: snapshot.isDraggingOver,
                                        })}
                                    >
                                        <p className="text-muted text-center mb-2">
                                            Переноси символы отсюда
                                        </p>
                                        <BoxWithSymbols
                                            symbols={variants}
                                            isShortContainer={false}
                                            isForAnswer={true}
                                        />
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </TestConstructor>
                </TestContent>
            ) : (
                <p>Данных нет</p>
            )}
        </div>
    );
};

OpenAnswerPlayer.propTypes = {
    variants: PropTypes.array,
    properties: PropTypes.object,
    question: PropTypes.object,
    selectedAnswers: PropTypes.array,
    onDragEnd: PropTypes.func,
};
