import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./styles.module.sass";
import {EditButtons} from "../../../../views/components/edit-buttons/edit-buttons";
import {url} from "../../../../config";
import {IPublicationImagePayload} from "../../article-modals/new-article-image-modal/new-article-image-modal";
import ArticleTitle from "../../article-title/article-title";
import {EPublicationCustomActionTypes} from "../../../../enums/EPublicationCustomActionTypes";
import {TextUploadModal} from "../../../../views/components/text-upload-modal/text-upload-modal";

interface IProps {
    block: any;
    isViewMode: boolean;
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
    handleAddImage: (id: number) => void;
    handleCustomAction: (type: EPublicationCustomActionTypes, payload: any) => void;
}

const ArticleImageBlock = ({
    block,
    handleDelete,
    handleEdit,
    handleAddImage,
    isViewMode,
    handleCustomAction,
}: IProps) => {
    const [settings, setSettings] = useState<IPublicationImagePayload | null>(null);
    const [textModalIsOpen, setTextModalIsOpen] = useState(false);

    useEffect(() => {
        if (block) {
            let tempSettings = JSON.parse(atob(block.blockSettings));
            setSettings(tempSettings);
        }
    }, [block]);

    const handleSaveText = (name: string) => {
        handleCustomAction(EPublicationCustomActionTypes.IMAGE_CHANGE_NAME, {
            name,
            blockId: block.id,
            imageId: block.imageBlock.id,
        });
        setTextModalIsOpen(false);
    };

    return (
        <div
            className={cn({
                [styles.imageBlock]: true,
                [styles.editMode]: !isViewMode,
            })}
        >
            {textModalIsOpen && (
                <TextUploadModal
                    saveText={handleSaveText}
                    visible={textModalIsOpen}
                    toggle={() => setTextModalIsOpen(!textModalIsOpen)}
                    text={block.imageBlock.name}
                />
            )}

            <ArticleTitle
                isViewMode={isViewMode}
                handleCustomAction={handleCustomAction}
                block={block}
                title={block.description}
            />

            {(settings?.descriptionStyle === 3 || settings?.descriptionStyle === 4) && (
                <React.Fragment>
                    <div
                        className={cn({
                            [styles.description]: true,
                            [styles.descriptionBold]: settings?.descriptionStyle === 4,
                        })}
                    >
                        {block.imageBlock?.name && (
                            <p>
                                {block.imageBlock.name}
                                {!isViewMode && (
                                    <i
                                        className="fas fa-pen"
                                        onClick={() => setTextModalIsOpen(true)}
                                    />
                                )}
                            </p>
                        )}
                    </div>
                </React.Fragment>
            )}

            {block.imageBlock && (
                <div
                    className={cn({
                        [styles.image]: true,
                        [styles.imageExpand]: settings?.blockStyle === 1,
                        [styles.imageCenter]: settings?.blockStyle === 2,
                        [styles.imageLeft]: settings?.blockStyle === 3,
                    })}
                >
                    <img
                        src={url + block.imageBlock.fileDownloadUri.slice(1)}
                        alt={block.imageBlock.name}
                    />
                </div>
            )}

            {(settings?.descriptionStyle === 1 || settings?.descriptionStyle === 2) && (
                <React.Fragment>
                    <div
                        className={cn({
                            [styles.description]: true,
                            [styles.descriptionBold]: settings?.descriptionStyle === 2,
                        })}
                    >
                        {block.imageBlock?.name && (
                            <p>
                                {block.imageBlock.name}
                                {!isViewMode && (
                                    <i
                                        className="fas fa-pen"
                                        onClick={() => setTextModalIsOpen(true)}
                                    />
                                )}
                            </p>
                        )}
                    </div>
                </React.Fragment>
            )}

            {!isViewMode && (
                <EditButtons
                    editAction={() => handleEdit(block.id)}
                    imageAction={() => handleAddImage(block.id)}
                    deleteAction={() => handleDelete(block.id)}
                    isSolidBorder
                    itemId={block.id}
                />
            )}
        </div>
    );
};

export default ArticleImageBlock;
