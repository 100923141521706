import { makeAutoObservable, runInAction } from "mobx";
import { NewsAPI } from "../entries/news/news-api";
import { toast } from "react-toastify";
import { INews } from "../interfaces/INews";
import { IPageable } from "../interfaces/IPageable";

export class NewsStore {
    public news: IPageable<INews> | null = null

    constructor() {
        makeAutoObservable(this)
    }

    getNews = (auditory?: string) : any => {
        let API;
        if (!auditory) {
            API = NewsAPI.getNews();
        } else {
            API = NewsAPI.getNewsByAuditory(auditory);
        }

        return API.then((response) => {
            runInAction(() => {
                this.news = response.data;
            })

            return this.news
        }).catch(() => {
            toast.error("Ошибка получения новостей");
        })
    };
}

