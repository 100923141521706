import {getMultipartFormDataContentTypeHeader} from "core/config/headers";
import {Request} from "../utils/request";

const INTERNAL_ERROR = "error.internal";

export const ImageBankAPI = {
    addTagToImage: (id, tagsId) => {
        if (id) {
            return Request.post(`/image-bank/${id}/tags`, [tagsId]);
        }

        return Promise.reject("");
    },
    deleteImage: (id) => {
        if (id) {
            return Request.delete(`/image-bank/${id}`);
        }

        return Promise.reject("");
    },
    getImagesByTag: (tagId) => {
        return Request.get(`/image-bank/by-tags?tags_id=${tagId}&size=1000`);
    },

    updateImage: (id, file) => {
        if (id && file) {
            const fileData = new FormData();
            fileData.set("file", file);

            return Request.put(`/image-bank/${id}`, fileData);
        }

        return Promise.reject("");
    },

    updateImageName: (id, name) => {
        if (id && name) {
            return Request.put(`/image-bank/${id}?name=${name}`);
        }

        return Promise.reject("");
    },

    uploadImage: (file, name) => {
        if (file) {
            const fileData = new FormData();
            fileData.set("file", file);

            return Request.post(
                `/image-bank?name=${name}`,
                fileData,
                getMultipartFormDataContentTypeHeader(),
            );
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    getImageBankByName: (name) => {
        if (name.trim()) {
            return Request.get(`/image-bank-by-name?name=${name}&size=1000`);
        }
    },
};
