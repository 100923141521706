import {Request} from "core/utils/request";

export const GeoAPI = {
    getRegions: (page = 1, region) => {
        return Request.get(
            `regions?page=${page}&size=10&sort=population,desc${region && `&name=${region}`}`,
        );
    },
    getCities: (regionId, page = 1, city) => {
        return Request.get(
            `region/${regionId}/cities?page=${page}&size=10&sort=population,desc${
                city && `&name=${city}`
            }`,
        );
    },
    getSchoolsElastic: (cityId, regionId, page = 1, school, size = 50) => {
        let splittedSchool = school.split(" ");

        if (splittedSchool.length === 1) {
            return Request.get(
                `es/school/search-like?${cityId ? `cityId=${cityId}&` : ""}${
                    regionId ? `regionId=${regionId}&` : ""
                }slop=3&page=${page}&size=${size}${school && `&search=${school}`}`,
            );
        }

        if (regionId || cityId) {
            return Request.get(
                `es/school/search?${cityId ? `cityId=${cityId}&` : ""}${
                    regionId && `regionId=${regionId}&`
                }slop=3&page=${page}&size=${size}${school && `&search=${school}`}`,
            );
        }

        return Request.get(
            `es/school/search?slop=3&page=${page}&size=${size}${school && `&search=${school}`}`,
        );
    },
};
