import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {toast} from "react-toastify";

import {TOAST_SUCCESS_CONFIG} from "config/ui";

import {TagsAPI} from "core/api/tags";

import {SectionTagsContainer} from "views/containers/tag-manager/section-tags";

import {TagManagerSection, TagManagerList} from "views/components/tag-manager";

const FirstSectionTagsTitle = () => {
    const {t} = useTranslation("tag_manager");

    return <h6 className="font-weight-bold">{t("firstLevelSectionTitle")}</h6>;
};

export const FirstLevelTagsContainer = (props) => {
    const {id} = props;
    const {t} = useTranslation("tag_manager");

    const [loaded, setLoaded] = React.useState(false);
    const [tags, setTags] = React.useState([]);

    const loadTags = () =>
        TagsAPI.getFirstLevelTagsBySectionTwo(id)
            .then((response) => {
                setTags(response.data);
            })
            .finally(() => {
                setLoaded(true);
            });

    React.useEffect(() => {
        loadTags();
    }, []);

    const handleAddClick = React.useCallback(() => {
        const name = prompt(t("newTagName"));
        if (name) {
            TagsAPI.addFirstLevelTag(name)
                .then((response) => {
                    TagsAPI.addSectionToTwoSection(response.data.id, id).then(() => {
                        loadTags();
                        toast.success(t("tagCreated"), TOAST_SUCCESS_CONFIG);
                    });
                })
                .catch((error) => {
                    console.error("Adding tag error", error);
                });
        }
    }, [t]);

    const handleRemoveClick = React.useCallback(
        (tagId) => {
            TagsAPI.deleteFirstLevelTag(tagId)
                .then(() => {
                    loadTags();
                    toast.success(t("tagRemoved"), TOAST_SUCCESS_CONFIG);
                })
                .catch((error) => {
                    console.error("Removing tag error", error);
                });
        },
        [t, id],
    );

    const handleRenameClick = React.useCallback(
        (tagId, tagName) => {
            const name = prompt(t("tagNewName"), tagName);

            if (name && name !== tagName) {
                TagsAPI.renameFirstLevelTag(tagId, name)
                    .then(() => {
                        loadTags();
                        toast.success(t("tagRenamed"), TOAST_SUCCESS_CONFIG);
                    })
                    .catch((error) => {
                        console.error("Renaming tag error", error);
                    });
            }
        },
        [t, id],
    );

    return (
        <TagManagerSection loaded={loaded} title={<FirstSectionTagsTitle />}>
            <div>
                <button className="btn btn-success btn-sm ml-2" onClick={handleAddClick}>
                    Добавить секцию 1 уровня
                </button>
            </div>
            {tags.map((tag) => (
                <TagManagerList
                    key={tag.id}
                    id={tag.id}
                    name={tag.name}
                    // onAddClick={handleAddClick}
                    onRemoveClick={handleRemoveClick}
                    onRenameClick={handleRenameClick}
                >
                    <SectionTagsContainer key={tag.id} id={tag.id} name={tag.name} />
                </TagManagerList>
            ))}
        </TagManagerSection>
    );
};

FirstLevelTagsContainer.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
};
