import cn from "classnames";
import {TOAST_ERROR_CONFIG} from "config";
import {BASKETS_TEST} from "config/constructor-names";
import {ADMIN_ROLE} from "config/roles";
import {QuestionAPI} from "core/api/question";
import {auth} from "core/utils/auth";
import {CLASSIC_TEST_LIMIT_SECONDS} from "entries/constructors/classic-test/core/api/classic-test";
import styles from "entries/constructors/classic-test/views/containers/create-test/create-test.module.sass";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {hasAccess} from "utils/private-route";
import SaveButton from "views/components/save-button/save-button";

const CreateTest = ({apiInstance, onCreateTest, mode, constructorType, settings, ...props}) => {
    const {keycloak} = auth;
    const [testName, setTestName] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSaveClick = () => {
        if (testName.trim().length > 0) {
            createTest();
        } else {
            toast.error("Заполните поле с названием теста", TOAST_ERROR_CONFIG);
        }
    };

    const createTest = () => {
        setIsLoading(true);

        const questionDto = {
            title: testName,
            questionText: "",
            limitSec: CLASSIC_TEST_LIMIT_SECONDS,
            levelAccess: mode,
            isOpen: false,
        };

        if (constructorType === BASKETS_TEST) {
            questionDto.isRequireAllOnlyWin = true;
        }

        let questionApi = apiInstance.createQuestion;

        if (hasAccess(keycloak, [ADMIN_ROLE])) {
            questionApi = apiInstance.createQuestionAdmin;
        }

        questionApi(questionDto)
            .then((response) => {
                createProperties(response.data.id, response.data);
            })
            .catch(() => {
                toast.error("Ошибка создания теста", TOAST_ERROR_CONFIG);
                setIsLoading(false);
            })
            .finally(() => setIsLoading(false));
    };

    const createProperties = (questionId, questionData) => {
        if (settings) {
            settings = btoa(JSON.stringify(settings));
        }
        QuestionAPI.createProperties(questionId, settings)
            .catch((err) => {
                console.log(err);
                toast.error("Ошибка создания параметров", TOAST_ERROR_CONFIG);
            })
            .finally(() => {
                setIsLoading(false);
                onCreateTest(questionData);
            });
    };

    return (
        <div
            className={cn({
                "form-group": true,
                [styles.container]: true,
                [props.className]: !!props.className,
            })}
        >
            <div className={styles.createContainer}>
                <div className="form-group">
                    <label htmlFor="input" className="text-dark">
                        Введите название задания
                    </label>
                    <input
                        type="text"
                        value={testName}
                        onChange={(event) => setTestName(event.target.value)}
                        autoComplete="false"
                        className="form-control"
                        id="input"
                        disabled={isLoading}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                handleSaveClick();
                            }
                        }}
                    />
                    <p className={styles.helpMessage}>Например: задача на сложение</p>
                </div>
                <SaveButton
                    handleSaveClick={handleSaveClick}
                    buttonText="Создать задание"
                    isDisabled={isLoading}
                    isDisabledWithLoad={isLoading}
                />
            </div>
        </div>
    );
};

export default CreateTest;

CreateTest.propTypes = {
    apiInstance: PropTypes.object,
    settings: PropTypes.object,
    className: PropTypes.string,
    mode: PropTypes.string,
    onCreateTest: PropTypes.func,
};
