import cn from "classnames";
import {ItemTypes} from "feauters/clipping-pictures-engine/types";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {isMobile} from "react-device-detect";
import {useDrag} from "react-dnd";
import {usePreview} from "react-dnd-preview";
import styles from "./styles.module.sass";

const MyPreview = ({children}) => {
    const {display, style} = usePreview();

    if (!display) {
        return null;
    }

    return <div style={style}>{children}</div>;
};

const DraggableBox = ({
    id,
    left,
    top,
    children,
    className,
    handleClick,
    tempId,
    isFromAnswerBox = false,
}) => {
    const [width, setWidth] = useState(null);

    const [{isDragging}, drag] = useDrag(
        () => ({
            type: ItemTypes.CLIP,
            item: {id, tempId, left: left ? left : 0, top, isFromAnswerBox, width},
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [id, left, top, width],
    );

    if (isDragging) {
        {
            return isMobile ? <MyPreview children={children} /> : <div ref={drag} />;
        }
    }

    return (
        <div
            ref={(el) => {
                drag(el);
                setWidth(el?.offsetWidth);
            }}
            className={cn({
                [styles.clip]: true,
                [styles.clip_absolute]: top && left,
                [className]: !!className,
            })}
            style={{left, top}}
            role="Box"
            onClick={handleClick ? () => handleClick(id, width, tempId) : null}
        >
            {children}
        </div>
    );
};

DraggableBox.propTypes = {
    className: PropTypes.string,
    handleClick: PropTypes.func,
    id: PropTypes.any,
    isFromAnswerBox: PropTypes.bool,
    left: PropTypes.any,
    top: PropTypes.any,
};

export default DraggableBox;
