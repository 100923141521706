import {TOAST_ERROR_CONFIG, url} from "config";
import {convertTextWithSymbols} from "core/utils/converter";
import {
    AUDIO_ANSWER_CLASSIC,
    IMAGE_ANSWER_CLASSIC,
    IMAGE_FROM_BANK_ANSWER_CLASSIC,
    TEXT_ANSWER_CLASSIC,
} from "entries/constructors/classic-test/utils/answer-types";
import {Formula} from "entries/constructors/classic-test/views/components/formula";
import React from "react";
import {Image} from "entries/constructors/classic-test/views/components/image-answer";
import {Text} from "entries/constructors/classic-test/views/components/text-answer";
import {IMAGE_TYPE, TEXT_TYPE} from "utils/answers-config";
import {ClassicTestAPI} from "../core/api/classic-test";
import {toast} from "react-toastify";

export const convertResponseToTextAnswer = (response, answerText) => {
    return {
        id: response.data.id,
        component: <Text text={answerText} />,
        text: answerText,
        type: response.data.objectType,
    };
};

export const convertResponseToFormulaAnswer = (response) => {
    let formulaImage = response.data.answerImageQuestion.fileDownloadUri.slice(1);
    let formulaUrl = `${url}${formulaImage}`;
    return {
        id: response.data.id,
        component: <Formula formulaURI={formulaUrl} />,
        type: response.data.objectType,
    };
};

export const convertResponseToBackground = (response) => {
    return response.data.map((background) => {
        return {label: background.name, value: background.id};
    });
};

export const convertResponseToImage = (response) => {
    return response.data.content.map((img) => {
        return {id: img.id, name: img.name, url: img.fileDownloadUri.slice(1)};
    });
};

export const convertResponseToNarrator = (response) => {
    return response.data.content.map((narrator) => {
        return {
            id: narrator.id,
            name: narrator.name,
            url: narrator.image?.fileDownloadUri.slice(1),
        };
    });
};

export const deleteAnswerFromArray = (answersArray, answerId) => {
    return answersArray.filter((answer) => answer.id !== answerId);
};

export const getClassicAnswers = async (
    answers,
    answerSizeText,
    answerImageSize,
    testId,
    withCorrectAnswers = false,
) => {
    let correctAnswers;

    const getCorrectAnswers = () => {
        return ClassicTestAPI.getCorrectAnswer(testId)
            .then((res) => {
                return res.data;
            })
            .catch(() => {
                toast.error("Ошибка получения правильных ответов", TOAST_ERROR_CONFIG);
                return null;
            });
    };

    if (withCorrectAnswers) {
        correctAnswers = await getCorrectAnswers();
    }

    const getAnswerIsCorrect = (answer) => {
        if (!withCorrectAnswers || !correctAnswers?.length || !answer) {
            return answer?.isCorrect;
        }

        return correctAnswers.some((ca) => ca.correctAnswers.id === answer.id);
    };

    if (answers && answers.length > 0) {
        let answerType = answers[0].objectType;
        if (answerType === TEXT_ANSWER_CLASSIC) {
            let tempTextAnswers = answers.map((answer) => {
                return {
                    id: answer.id,
                    component: (
                        <Text
                            text={convertTextWithSymbols(answer.text)}
                            fontSize={answerSizeText}
                            withBorder={false}
                        />
                    ),
                    isCorrect: getAnswerIsCorrect(answer),
                    objectType: answer.objectType,
                    text: answer.text,
                };
            });
            return {type: TEXT_TYPE, answers: tempTextAnswers};
        } else if (
            answerType === IMAGE_ANSWER_CLASSIC ||
            answerType === IMAGE_FROM_BANK_ANSWER_CLASSIC
        ) {
            // конвертируем данные компонента
            let tempImageAnswers = answers.map((answer) => {
                let imageData = answer.answerImageQuestion || answer.answerImageBank;
                return {
                    id: answer.id,
                    component: (
                        <Image
                            imageUrl={imageData?.fileDownloadUri.slice(1)}
                            size={answerImageSize}
                        />
                    ),
                    isCorrect: getAnswerIsCorrect(answer),
                    type: answer.objectType,
                };
            });
            return {type: IMAGE_TYPE, answers: tempImageAnswers};
        } else if (answerType === AUDIO_ANSWER_CLASSIC) {
            let convertedAnswers = answers.map((answer) => ({
                id: answer.id,
                audioUrl: url + answer.audio.fileDownloadUri.slice(1),
                image: answer.answerImageBank,
                isCorrect: getAnswerIsCorrect(answer),
                type: AUDIO_ANSWER_CLASSIC,
            }));
            return {type: AUDIO_ANSWER_CLASSIC, answers: convertedAnswers};
        }
    }
};
