import React, {useState} from "react";
import AdminPanelFormCreator, {
    EFormCreatorTypes,
} from "../../admin-panel-form-creator/admin-panel-form-creator";
import AdminPanelQueryResultLayout from "../../admin-panel-query-result-layout/admin-panel-query-result-layout";
import PreviewProfile from "../../../preview-profile/preview-profile";
import {toast} from "react-toastify";
import {AdminPanelUserAPI} from "../admin-panel-users-api";
import usePreviewProfile from "../../../preview-profile/hooks/usePreviewProfile";

const AdminPanelUsersInClass = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [classes, setClasses] = useState<any[] | null>(null);
    const {isCtrlKeyDowned, handleLoadProfile, currentProfile, isProfileLoading} =
        usePreviewProfile();

    const handleGetUsersInClass = (formData: any) => {
        let {id} = formData;

        if (!id) {
            toast.error("Введите ID");
            return;
        }

        setClasses(null);
        setIsLoading(true);
        AdminPanelUserAPI.getStudentsInClass(id)
            .then((response) => {
                setClasses(response.data);
            })
            .catch((err) => toast.error(err.response.data.message))
            .finally(() => setIsLoading(false));
    };

    return (
        <React.Fragment>
            <AdminPanelFormCreator
                handleClick={handleGetUsersInClass}
                bannerText="Получить учеников"
                formConstructor={[
                    {
                        name: "id",
                        labelText: "ID Учителя",
                        type: EFormCreatorTypes.TEXT,
                    },
                    {
                        name: "Получить",
                        type: EFormCreatorTypes.BUTTON,
                        color: "success",
                    },
                ]}
                isLoading={isLoading}
            />

            {classes && (
                <AdminPanelQueryResultLayout>
                    <div className="mt-2">
                        <h4>Классов: {classes?.length}</h4>
                        <hr />
                        {classes.map((cl) => (
                            <div key={cl.id}>
                                <p>
                                    Класс: {cl.classNumber}
                                    {cl.classLetter}
                                </p>
                                <p>Ученики:</p>
                                <ul>
                                    {cl.students.map((student: any) => (
                                        <li key={student.id} className="mb-2">
                                            <b>ID: {student.id}</b> <br />
                                            <PreviewProfile
                                                isCtrlKeyDowned={isCtrlKeyDowned}
                                                handleLoadProfile={handleLoadProfile}
                                                userId={student.id}
                                                currentProfile={currentProfile}
                                                isProfileLoading={isProfileLoading}
                                            >
                                                <React.Fragment>
                                                    {student.firstName} {student.lastName}
                                                </React.Fragment>
                                            </PreviewProfile>
                                        </li>
                                    ))}
                                </ul>
                                <hr />
                            </div>
                        ))}
                    </div>
                </AdminPanelQueryResultLayout>
            )}
        </React.Fragment>
    );
};

export default AdminPanelUsersInClass;
