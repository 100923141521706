import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

export const TagManagerList = (props) => {
    const {id, name, className, onAddClick, onRemoveClick, onRenameClick, onVisibilityChange} =
        props;

    const [visible, setVisible] = React.useState(false);

    const handleChangeVisibility = React.useCallback(() => {
        setVisible(!visible);
        onVisibilityChange && onVisibilityChange(!visible, id, name);
    }, [visible, id, name, onVisibilityChange]);

    React.useCallback(() => {
        onAddClick(id);
    }, [onAddClick, id]);

    const handleRenameClick = React.useCallback(() => {
        onRenameClick(id, name);
    }, [onRenameClick, id, name]);

    const handleRemoveClick = React.useCallback(() => {
        onRemoveClick(id);
    }, [onRemoveClick, id]);

    return (
        <li
            className={cn({
                "list-group-item": true,
                "mt-1": true,
                [className]: !!className,
            })}
        >
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <b>ID: {id}</b> {name}
                </div>
                <div className="btn-group" role="group">
                    <>
                        <button className="btn btn-primary btn-sm" onClick={handleChangeVisibility}>
                            {visible ? (
                                <i className="fas fa-arrow-up" />
                            ) : (
                                <i className="fas fa-arrow-down" />
                            )}
                        </button>
                        {/*<button className="btn btn-success btn-sm" onClick={handleAddClick}>*/}
                        {/*    <i className="fas fa-plus" />*/}
                        {/*</button>*/}
                    </>
                    <button className="btn btn-warning btn-sm" onClick={handleRenameClick}>
                        <i className="fas fa-pen" />
                    </button>
                    <button className="btn btn-danger btn-sm" onClick={handleRemoveClick}>
                        <i className="fas fa-trash-alt" />
                    </button>
                </div>
            </div>
            {visible ? props.children : null}
        </li>
    );
};

TagManagerList.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    onRemoveClick: PropTypes.func.isRequired,
    onRenameClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    onAddClick: PropTypes.func,
    onVisibilityChange: PropTypes.func,
};
