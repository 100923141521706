import {Request} from "core/utils/request";

const API_NAME = `narrator`;

export const NarratorAPI = {
    getNarrators: () => {
        return Request.get(`${API_NAME}?size=1000`);
    },
    createNarrator: (name) => {
        if (name) {
            return Request.post(API_NAME, {name});
        }

        return Promise.reject("");
    },
    addImageToNarrator: (narratorId, file) => {
        const fileData = new FormData();
        fileData.set("file", file);
        return Request.post(`${API_NAME}/${narratorId}/image`, fileData);
    },
    getNarratorEmotions: (narratorId) => {
        return Request.get(`${API_NAME}/${narratorId}/emotion`);
    },
};
