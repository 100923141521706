import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

export const SpinnerPage = (props) => (
    <div
        className={cn({
            "spinner-border": !props.grow,
            "spinner-grow": props.grow,
            "spinner-border-sm": !props.grow && props.sm,
            "spinner-grow-sm": props.grow && props.sm,
            "text-danger": props.danger,
            "text-info": props.info,
            "text-primary": props.primary,
            "text-success": props.success,
            "text-warning": props.warning,
        })}
        role="status"
    >
        <span className="sr-only">Loading...</span>
    </div>
);

SpinnerPage.propTypes = {
    danger: PropTypes.bool,
    grow: PropTypes.bool,
    info: PropTypes.bool,
    primary: PropTypes.bool,
    sm: PropTypes.bool,
    success: PropTypes.bool,
    warning: PropTypes.bool,
};
