import {MDBBtn, MDBBtnGroup} from "mdbreact";
import React from "react";
import PropTypes from "prop-types";

const SchoolClassesSelectButtons = ({classes, handleSelectClass, selectedClasses}) => {
    return (
        <MDBBtnGroup className="w-100">
            {classes &&
                classes.map((classData) => (
                    <MDBBtn
                        color="info"
                        key={classData.id}
                        active={selectedClasses.includes(classData.id)}
                        onClick={() => {
                            handleSelectClass(classData);
                        }}
                    >
                        {classData.name}
                    </MDBBtn>
                ))}
        </MDBBtnGroup>
    );
};

SchoolClassesSelectButtons.propTypes = {
    classes: PropTypes.array.isRequired,
    handleSelectClass: PropTypes.func.isRequired,
    selectedClasses: PropTypes.array,
};

export default SchoolClassesSelectButtons;
