import {
    MDBCol,
    MDBContainer,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBRow,
    MDBTabContent,
    MDBTabPane,
} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {SpinnerPage} from "views/components/spinner";

import {UploadImageForm} from "views/containers/upload-image-manager";
import {ImagesManager} from "views/pages/admin/images-manager";

export const ImageUploadModal = (props) => {
    const {
        onSaveImage,
        toggle,
        visible,
        withCrop,
        uploadIsAvailable = true,
        selectFromLibraryAvailable = true,
        isLoading,
        testType = "Classic",
        svgDisabled,
        availableProportions,
        directTagEnum,
    } = props;

    const [activeItem, setActiveItem] = useState("0");

    const toggleTab = (tab) => {
        if (activeItem !== tab) {
            setActiveItem(tab);
        }
    };

    useEffect(() => {
        if (selectFromLibraryAvailable) {
            setActiveItem("1");
        } else if (uploadIsAvailable) {
            setActiveItem("2");
        }
    }, []);

    return (
        <MDBContainer style={{zIndex: 9999, position: "relative"}}>
            <MDBModal isOpen={visible} toggle={toggle} size="lg" overflowScroll={false}>
                <MDBModalHeader toggle={toggle} className="modal-header">
                    <p>Выбор картинки</p>
                </MDBModalHeader>
                <MDBModalBody className="container modal-body">
                    <div>
                        {isLoading ? (
                            <SpinnerPage primary />
                        ) : (
                            <>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBNav className="nav-tabs mb-4">
                                            <MDBNavItem>
                                                <MDBNavLink
                                                    link
                                                    to="#"
                                                    active={activeItem === "1"}
                                                    onClick={() => toggleTab("1")}
                                                    role="tab"
                                                    disabled={!selectFromLibraryAvailable}
                                                >
                                                    Выбрать из библиотеки
                                                </MDBNavLink>
                                            </MDBNavItem>
                                            <MDBNavItem>
                                                <MDBNavLink
                                                    link
                                                    to="#"
                                                    active={activeItem === "2"}
                                                    onClick={() => {
                                                        toggleTab("2");
                                                    }}
                                                    role="tab"
                                                    disabled={!uploadIsAvailable}
                                                >
                                                    Загрузить
                                                </MDBNavLink>
                                            </MDBNavItem>
                                        </MDBNav>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol>
                                        <MDBTabContent activeItem={activeItem}>
                                            <MDBTabPane tabId="1" role="tabpanel">
                                                <ImagesManager
                                                    selectImage={onSaveImage}
                                                    directTagEnum={directTagEnum}
                                                    withoutNameTip={true}
                                                />
                                            </MDBTabPane>
                                            <MDBTabPane tabId="2" role="tabpanel">
                                                <UploadImageForm
                                                    onSaveImage={onSaveImage}
                                                    withCrop={withCrop}
                                                    testType={testType}
                                                    svgDisabled={svgDisabled}
                                                    availableProportions={availableProportions}
                                                />
                                            </MDBTabPane>
                                        </MDBTabContent>
                                    </MDBCol>
                                </MDBRow>
                            </>
                        )}
                    </div>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    );
};

ImageUploadModal.propTypes = {
    availableProportions: PropTypes.array,
    directTagEnum: PropTypes.string,
    isLoading: PropTypes.bool,
    selectFromLibraryAvailable: PropTypes.bool,
    svgDisabled: PropTypes.bool,
    testType: PropTypes.string,
    toggle: PropTypes.func,
    uploadIsAvailable: PropTypes.bool,
    visible: PropTypes.bool,
    withCrop: PropTypes.bool,
    onSaveImage: PropTypes.func,
};
