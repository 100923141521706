export const IMAGE_TYPE = "image";
export const FORMULA_TYPE = "formula";
export const TEXT_TYPE = "text";
export const AUDIO_TYPE = "audio";
export const MIX_TYPE = "mix-type";

export const SIZE_S = "SIZE_S";
export const SIZE_M = "SIZE_M";
export const SIZE_L = "SIZE_L";
export const SIZE_XL = "SIZE_XL";
