import CreateTest from "entries/constructors/classic-test/views/containers/create-test/create-test";
import {OpenAnswerAPI} from "entries/constructors/open-answer/core/api/open-answer";
import {OpenAnswerConstructor} from "entries/constructors/open-answer/views/open-answer-constructor/open-answer-constructor";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

import {Route, Switch, useHistory} from "react-router-dom";

export const OpenAnswerManager = ({routerInstance}) => {
    const [testData, setTestData] = useState({});
    const [mode, setMode] = useState(undefined);
    let history = useHistory();

    useEffect(() => {
        if (
            !history.location.state &&
            !history.location.pathname.includes(
                routerInstance.OPEN_ANSWER_TEST.EDITING.replace(":id", ""),
            )
        ) {
            history.push(routerInstance.ROOT);
        }

        setMode(history.location.state);
    }, []);

    const onCreateTest = (testData) => {
        setTestData(testData);
        history.push(routerInstance.OPEN_ANSWER_TEST.CONSTRUCTOR);
    };

    return (
        <div>
            <Switch>
                <Route path={routerInstance.OPEN_ANSWER_TEST.CREATE_TEST}>
                    <CreateTest
                        onCreateTest={onCreateTest}
                        apiInstance={OpenAnswerAPI}
                        mode={mode}
                    />
                </Route>

                <Route path={routerInstance.OPEN_ANSWER_TEST.EDITING}>
                    <OpenAnswerConstructor testData={testData} isEditMode />
                </Route>

                <Route path={routerInstance.OPEN_ANSWER_TEST.CONSTRUCTOR}>
                    <OpenAnswerConstructor testData={testData} />
                </Route>
            </Switch>
        </div>
    );
};

OpenAnswerManager.propTypes = {
    routerInstance: PropTypes.any,
};
