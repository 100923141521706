import {Request} from "../../../core/utils/request";
import {IConversationReply} from "../../../interfaces/IConversationReply";

export const ConversationsAPI = {
    getConversations: (page: number) => {
        return Request.get(`/conversation?size=20&page=${page}`);
    },
    getConversationsReply: (id: number) => {
        return Request.get(`/conversation/${id}/reply?size=100000`);
    },
    sendMessage: (userTwoId: number, reply: IConversationReply) => {
        return Request.post(`/conversation-reply/to-user-two/${userTwoId}`, reply);
    },
    getUnreadMessageCount: (userId: number) => {
        return Request.get(`/conversation/${userId}/new-message-all-count`);
    },
    readMessages: (messageIds: number[]) => {
        return Request.put(`/conversation-reply/read`, messageIds);
    },
    addImage: (replyId: number, file: File) => {
        const fileData = new FormData();
        fileData.set("file", file);

        return Request.put(`/conversation-reply/${replyId}/image`, fileData);
    },
};
