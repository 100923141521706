import cn from "classnames";

import {url} from "config/url";
import PropTypes from "prop-types";
import React from "react";

import styles from "./test-content.module.sass";

export const TestContent = (props) => {
    const {background, withEditBorder = true} = props;

    const backgroundStyle = {
        backgroundImage:
            background && `url(${url + (background.url ? background.url : background)})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    return (
        <div
            className={cn({
                [styles.container]: true,
                [props.className]: !!props.className,
                [styles.border]: withEditBorder,
                [styles.full]: props.isFullWidth,
            })}
            style={backgroundStyle}
        >
            {props.children}
        </div>
    );
};

TestContent.propTypes = {
    background: PropTypes.any,
    className: PropTypes.string,
    isFullWidth: PropTypes.bool,
    withEditBorder: PropTypes.bool,
};
