export enum EPublicationBlockTypes {
    PublicationBlockTextDto = "PublicationBlockTextDto",
    PublicationBlockImageDto = "PublicationBlockImageDto",
    PublicationBlockGalleryDto = "PublicationBlockGalleryDto",
    PublicationBlockPlayerDto = "PublicationBlockPlayerDto",
    PublicationBlockDocumentDto = "PublicationBlockDocumentDto",
    PublicationBlockSimilarDto = "PublicationBlockSimilarDto",
    PublicationBlockLineDto = "PublicationBlockLineDto",
    PublicationBlockVideoDto = "PublicationBlockVideoDto",
    PublicationBlockVideoSharingDto = "PublicationBlockVideoSharingDto",
}
