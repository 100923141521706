import {MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBModalHeader, MDBRow} from "mdbreact";
import React, {useEffect, useState} from "react";

import {IPublication} from "../../../../interfaces/IPublication";
import {ImageUploadModal} from "../../../../views/components/image-upload-modal/image-upload-modal";
import styles from "./styles.module.sass";
import {url} from "../../../../config";
import {ArticleAPI} from "../../core/article-api";
import {translate} from "../../../../core/utils/helpers";

export const ICON_IMAGE = "ICON_IMAGE";
export const MINI_IMAGE = "MINI_IMAGE";
export const WRAPPER_IMAGE = "WRAPPER_IMAGE";

interface IProps {
    createModalStep: number;
    isOpen: boolean;
    toggle: () => void;
    handleSave: (article: IPublication) => void;
    articleForEdit: IPublication | null;
    setCreateModalStep: (step: number) => void;
}

const NewArticleModal = ({isOpen, toggle, handleSave, articleForEdit, createModalStep}: IProps) => {
    const [article, setArticle] = useState<IPublication>({
        imageOne: null,
        imageTwo: null,
        imageThree: null,
        title: "",
        publicationUrl: Date.now().toString(),
        metaDescription: "",
        metaKeywords: "",
        id: 0,
        metaTitle: "",
        section: "MAIN",
        settings: "",
        description: "",
    });

    const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
    const [imageType, setImageType] = useState<any>("");

    useEffect(() => {
        if (articleForEdit) {
            setArticle(articleForEdit);
        }
    }, [articleForEdit]);

    const handleClickMiniImage = () => {
        setImageType(MINI_IMAGE);
        setImageModalIsOpen(true);
    };

    const handleClickIconImage = () => {
        setImageType(ICON_IMAGE);
        setImageModalIsOpen(true);
    };

    const handleClickWrapperImage = () => {
        setImageType(WRAPPER_IMAGE);
        setImageModalIsOpen(true);
    };

    let handleSaveIconImage = (image: any) => {
        if (!articleForEdit?.id || !image) {
            return;
        }

        ArticleAPI.updatePublicationImageTwo(articleForEdit?.id, image.image).then((response) => {
            setArticle(response.data);
        });
    };

    let handleSaveMiniImage = (image: any) => {
        if (!articleForEdit?.id || !image) {
            return;
        }

        ArticleAPI.updatePublicationImageOne(articleForEdit?.id, image.image).then((response) => {
            setArticle(response.data);
        });
    };

    let handleSaveWrapperImage = (image: any) => {
        if (!articleForEdit?.id || !image) {
            return;
        }

        ArticleAPI.updatePublicationImageThree(articleForEdit?.id, image.image).then((response) => {
            setArticle(response.data);
        });
    };

    const handleSaveImage = (image: any) => {
        switch (imageType) {
            case ICON_IMAGE:
                handleSaveIconImage(image);
                break;
            case MINI_IMAGE:
                handleSaveMiniImage(image);
                break;
            case WRAPPER_IMAGE:
                handleSaveWrapperImage(image);
                break;
            default:
                break;
        }

        setImageType(null);
        setImageModalIsOpen(false);
    };

    return (
        <MDBModal
            isOpen={isOpen}
            toggle={toggle}
            size="lg"
            overflowScroll
            noClickableBodyWithoutBackdrop
            inline={false}
        >
            {imageModalIsOpen && (
                <ImageUploadModal
                    visible={imageModalIsOpen}
                    toggle={() => setImageModalIsOpen(!imageModalIsOpen)}
                    onSaveImage={handleSaveImage}
                    selectFromLibraryAvailable={false}
                />
            )}

            <MDBModalHeader toggle={toggle} className="modal-header">
                <p>Новая статья</p>
            </MDBModalHeader>
            <MDBModalBody>
                {createModalStep === 2 && (
                    <React.Fragment>
                        <MDBRow className={styles.row}>
                            <MDBCol md="3">
                                <p>Изображение</p>
                            </MDBCol>
                            <MDBCol md="9" className={styles.images}>
                                <div className={styles.imageBlock} onClick={handleClickMiniImage}>
                                    {article.imageOne ? (
                                        <img
                                            src={url + article.imageOne.fileDownloadUri.slice(1)}
                                            alt="mini-image"
                                        />
                                    ) : (
                                        <p>Загрузить миниатюру для ленты</p>
                                    )}
                                </div>
                                <div className={styles.imageBlock} onClick={handleClickIconImage}>
                                    {article.imageTwo ? (
                                        <img
                                            src={url + article.imageTwo.fileDownloadUri.slice(1)}
                                            alt="icon-image"
                                        />
                                    ) : (
                                        <p> Загрузить иконку для блока статей</p>
                                    )}
                                </div>
                                <div
                                    className={styles.imageBlock}
                                    onClick={handleClickWrapperImage}
                                >
                                    {article.imageThree ? (
                                        <img
                                            src={url + article.imageThree.fileDownloadUri.slice(1)}
                                            alt="wrapper-image"
                                        />
                                    ) : (
                                        <p> Загрузить обложку страницы</p>
                                    )}
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </React.Fragment>
                )}

                {createModalStep === 1 && (
                    <React.Fragment>
                        <MDBRow className={styles.row}>
                            <MDBCol md="3">
                                <p>Название</p>
                            </MDBCol>
                            <MDBCol md="9">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={article.title}
                                    onChange={(e) =>
                                        setArticle({...article, title: e.target.value})
                                    }
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow className={styles.row}>
                            <MDBCol md="3">
                                <p>Описание</p>
                            </MDBCol>
                            <MDBCol md="9">
                                <textarea
                                    className="form-control"
                                    value={article.description}
                                    onChange={(e) =>
                                        setArticle({...article, description: e.target.value})
                                    }
                                    rows={3}
                                />
                                <p style={{fontSize: "1rem"}}>
                                    Символов: <span>{article.description?.length}</span>
                                </p>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow className={styles.row}>
                            <MDBCol md="3">
                                <p>URL</p>
                            </MDBCol>
                            <MDBCol md="9">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={article.publicationUrl}
                                    onChange={(e) =>
                                        setArticle({...article, publicationUrl: e.target.value})
                                    }
                                />

                                <MDBBtn
                                    color="primary"
                                    className="mt-1"
                                    onClick={() => {
                                        setArticle({
                                            ...article,
                                            publicationUrl: translate(article.title),
                                        });
                                    }}
                                >
                                    Сгенерировать URL из заголовка
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow className={styles.row}>
                            <MDBCol md="3">
                                <p>Meta-description</p>
                            </MDBCol>
                            <MDBCol md="9">
                                <textarea
                                    className="form-control"
                                    value={article.metaDescription}
                                    onChange={(e) =>
                                        setArticle({...article, metaDescription: e.target.value})
                                    }
                                    rows={3}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow className={styles.row}>
                            <MDBCol md="3">
                                <p>Meta-keywords</p>
                            </MDBCol>
                            <MDBCol md="9">
                                <textarea
                                    className="form-control"
                                    value={article.metaKeywords}
                                    onChange={(e) =>
                                        setArticle({...article, metaKeywords: e.target.value})
                                    }
                                    rows={3}
                                />
                                <p style={{fontSize: "1rem"}}>
                                    Символов: <span>{article.metaKeywords.length}</span>
                                </p>
                                {article.metaKeywords.length > 90 && (
                                    <p className="red-text" style={{fontSize: "1rem"}}>
                                        Слишком много символов! Сократите до 90
                                    </p>
                                )}
                            </MDBCol>
                        </MDBRow>
                    </React.Fragment>
                )}

                <MDBRow className={styles.row}>
                    <MDBCol md="3" className="mt-3">
                        <MDBBtn color="success" onClick={() => handleSave(article)}>
                            {createModalStep === 1 ? "Продолжить" : "Сохранить"}
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>
        </MDBModal>
    );
};

export default NewArticleModal;
