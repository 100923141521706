import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import React, {FC} from "react";
// @ts-ignore
import Pagination from "react-js-pagination";
import cn from "classnames";
import styles from "./styles.module.sass";
import {ITableControl} from "../../interfaces/ITableControl";

interface IProps {
    headers: Array<string>;
    outputKeys: Array<string>;
    data: any;
    page?: number;
    totalElements?: number;
    setPage?: (page: number) => void;
    controllers?: ITableControl[];
}

interface ITableRow {
    item: any;
    outputKeys: string[];
    controllers?: ITableControl[];
}

/**
 * fieldForCheck - название поля, которое будет проверяться с табличным
 * значением и в зависимости от результата менять цвет иконки
 **/

const TableRow = ({controllers, outputKeys, item}: ITableRow) => {
    return (
        <tr key={item.id}>
            {outputKeys.map((outKey, index) => {
                // для вложенных объектов
                let key = outKey.split(".");

                if (key[1] && Object(item[key[0]]).hasOwnProperty(key[1])) {
                    return <td key={index}>{item[key[0]][key[1]]}</td>;
                }

                return <td key={index}>{item[outKey]?.toString()}</td>;
            })}

            {controllers && (
                <React.Fragment>
                    {controllers.map((control: ITableControl, index) => {
                        return (
                            <td key={index}>
                                <i
                                    className={cn({
                                        [control.icon]: true,
                                        "mr-0": true,
                                    })}
                                    style={{
                                        color: `${
                                            control.filedForCheck
                                                ? item[control.filedForCheck]
                                                    ? control.colorActive
                                                    : control.colorInactive
                                                : control.colorActive
                                        }`,
                                    }}
                                    onClick={() => control.action(item)}
                                />
                            </td>
                        );
                    })}
                </React.Fragment>
            )}
        </tr>
    );
};

const GenericTable: FC<IProps> = ({
    data,
    headers,
    outputKeys,
    page,
    setPage,
    totalElements,
    controllers,
}) => {
    if (!data) {
        return <h3>Данных не найдено</h3>;
    }

    return (
        <div className={styles.container}>
            <MDBTable striped bordered>
                <MDBTableHead>
                    <tr>
                        {headers.map((head, index) => (
                            <th key={index}>{head}</th>
                        ))}
                    </tr>
                </MDBTableHead>

                <MDBTableBody>
                    {data.map((item: any) => {
                        return (
                            <TableRow
                                key={item.id}
                                item={item}
                                outputKeys={outputKeys}
                                controllers={controllers}
                            />
                        );
                    })}
                </MDBTableBody>
            </MDBTable>

            {page && setPage && (
                <Pagination
                    activePage={page}
                    totalItemsCount={totalElements}
                    itemsCountPerPage={50}
                    onChange={setPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass={cn({
                        pagination: true,
                        "pg-blue": true,
                        [styles.pagination]: true,
                    })}
                />
            )}
        </div>
    );
};

export default GenericTable;
