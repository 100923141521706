import React from "react";
import PropTypes from "prop-types";

import {ConstructorNavbarItem} from "views/components/constructor-navbar/constructor-navbar-item";

import styles from "views/components/constructor-navbar/constructor-navbar.module.sass";

export const ConstructorNavbar = ({currentTab, availableTabs, onSelect, tabs, tabNames}) => {
    return (
        <div className={styles.nav}>
            <div className={styles.nav_sectionLeft}>{/*<p>⟵ Вернуться</p>*/}</div>
            <div className={styles.nav_sectionRight}>
                {tabs.map((tab, index) => (
                    <React.Fragment key={index}>
                        <ConstructorNavbarItem
                            isActive={currentTab === tab}
                            isAvailable={availableTabs.includes(tab)}
                            onSelect={onSelect}
                            tabName={tabNames[tab]}
                            tab={tab}
                            tabIndex={index}
                        />
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

ConstructorNavbar.propTypes = {
    availableTabs: PropTypes.array,
    currentTab: PropTypes.string,
    tabNames: PropTypes.object,
    tabs: PropTypes.array,
    onSelect: PropTypes.func,
};
