import cn from "classnames";
import styles from "./styles.module.sass";
import React from "react";

export const MenuItem = ({
    currentLocation,
    handleClick,
    item,
}: {
    item: any;
    handleClick: () => void;
    currentLocation: any;
}) => {
    return (
        <div
            className={cn({
                [styles.item]: true,
                [styles.itemActive]: currentLocation.includes(item.path),
            })}
            style={
                currentLocation.includes(item.path)
                    ? {backgroundColor: item.color}
                    : {color: "black"}
            }
            key={item.id}
            onClick={handleClick}
        >
            <i className={item.icon}></i>
            {item.name}
        </div>
    );
};
