import {Request} from "core/utils/request";

const INTERNAL_ERROR = "error.internal";

export const AcademyAPI = {
    getAcademyBlockById: (id) => {
        if (id) {
            return Request.get(`academy-block/${id}`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    getAcademyBlocks: () => {
        return Request.get(`academy-block?size=1000`);
    },
    addAcademyBlock: (academyBlockDto) => {
        let {
            avatarId,
            backgroundId,
            cardBackgroundId,
            classTagId,
            logoId,
            name,
            nameTwo,
        } = academyBlockDto;
        if (academyBlockDto) {
            return Request.post(
                `/academy-block?avatarId=${avatarId}&backgroundId=${backgroundId}&cardBackgroundId=${cardBackgroundId}&classTagId=${classTagId}&logoId=${logoId}`,
                {name, nameTwo},
            );
        }

        return Request.reject(INTERNAL_ERROR);
    },
    updateAcademyBlock: (academyBlockDto, id) => {
        let {
            avatarId,
            backgroundId,
            cardBackgroundId,
            classTagId,
            logoId,
            name,
            nameTwo,
        } = academyBlockDto;
        if (academyBlockDto) {
            return Request.put(
                `/academy-block/${id}?avatarId=${avatarId}&backgroundId=${backgroundId}&cardBackgroundId=${cardBackgroundId}&classTagId=${classTagId}&logoId=${logoId}`,
                {name, nameTwo},
            );
        }

        return Request.reject(INTERNAL_ERROR);
    },
    addAcademySet: (blockId, academySetDto) => {
        if (blockId) {
            return Request.post(`/academy-block/${blockId}/academy-set`, academySetDto);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    getAcademySets: (blockId, page = 1, size = 1000) => {
        if (blockId) {
            return Request.get(
                `/academy-block/${blockId}/academy-set?size=${size}&page=${page}&sort=position,asc`,
            );
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    getAcademyQuestions: (setId) => {
        if (setId) {
            return Request.get(`/academy-set/${setId}/academy-question`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    addAcademyQuestions: (setId, questionsData) => {
        if (setId) {
            return Request.post(`/academy-set/${setId}/academy-question`, questionsData);
        }
    },
    deleteQuestionFromSet: (setId, questionId) => {
        if (setId && questionId) {
            return Request.delete(`/academy-set/${setId}/academy-question/${questionId}`);
        }
    },
    getAcademySet: (setId) => {
        if (setId) {
            return Request.get(`/academy-set/${setId}`);
        }
    },
    deleteAcademyBlock: (blockId) => {
        if (blockId) {
            return Request.delete(`/academy-block/${blockId}`);
        }
    },
    deleteAcademySet: (setId) => {
        if (setId) {
            return Request.delete(`/academy-set/${setId}`);
        }
    },
    editPositionAcademyQuestion: (id, hashMap) => {
        return Request.put(`/academy-set/${id}/academy-question-position`, hashMap);
    },
    getClassmatesRating: (id) => {
        return Request.get(`/academy-set/${id}/rating-my-classmates`);
    },
    editAcademySet: (setId, setDto) => {
        return Request.put(`/academy-set/${setId}`, setDto);
    },
};
