export const RAVEN_EMOTIONS_TYPES = {
    FUNNY: "RAVEN_FUNNY",
    SAD: "RAVEN_SAD",
};

const RAVEN_EMOTIONS = [
    {type: RAVEN_EMOTIONS_TYPES.FUNNY, image: require("assets/characters/emotions/raven_funny_01.svg")},
    {type: RAVEN_EMOTIONS_TYPES.FUNNY, image: require("assets/characters/emotions/raven_funny_02.svg")},
    {type: RAVEN_EMOTIONS_TYPES.FUNNY, image: require("assets/characters/emotions/raven_funny_03.svg")},
    {type: RAVEN_EMOTIONS_TYPES.FUNNY, image: require("assets/characters/emotions/raven_funny_04.svg")},
    {type: RAVEN_EMOTIONS_TYPES.FUNNY, image: require("assets/characters/emotions/raven_funny_05.svg")},
    {type: RAVEN_EMOTIONS_TYPES.FUNNY, image: require("assets/characters/emotions/raven_funny_06.svg")},
    {type: RAVEN_EMOTIONS_TYPES.FUNNY, image: require("assets/characters/emotions/raven_funny_07.svg")},
    {type: RAVEN_EMOTIONS_TYPES.FUNNY, image: require("assets/characters/emotions/raven_funny_08.svg")},
    {type: RAVEN_EMOTIONS_TYPES.FUNNY, image: require("assets/characters/emotions/raven_funny_09.svg")},
    {type: RAVEN_EMOTIONS_TYPES.SAD, image: require("assets/characters/emotions/raven_sad_01.svg")},
    {type: RAVEN_EMOTIONS_TYPES.SAD, image: require("assets/characters/emotions/raven_sad_02.svg")},
    {type: RAVEN_EMOTIONS_TYPES.SAD, image: require("assets/characters/emotions/raven_sad_03.svg")},
    {type: RAVEN_EMOTIONS_TYPES.SAD, image: require("assets/characters/emotions/raven_sad_04.svg")},
    {type: RAVEN_EMOTIONS_TYPES.SAD, image: require("assets/characters/emotions/raven_sad_05.svg")},
    {type: RAVEN_EMOTIONS_TYPES.SAD, image: require("assets/characters/emotions/raven_sad_06.svg")},
    {type: RAVEN_EMOTIONS_TYPES.SAD, image: require("assets/characters/emotions/raven_sad_07.svg")},
    {type: RAVEN_EMOTIONS_TYPES.SAD, image: require("assets/characters/emotions/raven_sad_08.svg")},
    {type: RAVEN_EMOTIONS_TYPES.SAD, image: require("assets/characters/emotions/raven_sad_09.svg")},
    {type: RAVEN_EMOTIONS_TYPES.SAD, image: require("assets/characters/emotions/raven_sad_10.svg")},
    {type: RAVEN_EMOTIONS_TYPES.SAD, image: require("assets/characters/emotions/raven_sad_11.svg")},
];

export const getRandomRavenWithEmotion = (emotionType) => {
    let suitableEmotions = RAVEN_EMOTIONS.filter((emotion) => emotion.type === emotionType);
    return suitableEmotions[Math.floor(Math.random() * suitableEmotions.length)].image;
};
