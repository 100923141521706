import cn from "classnames";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import React from "react";
import Pagination from "react-js-pagination";
import {convertDateTime} from "../../core/utils/helpers";
import styles from "views/components/list-with-pagination/list-with-pagination.module.sass";

const UsersList = ({users, handlePageChange, page}) => {
    return (
        <div className={styles.content}>
            {users?.content?.length && (
                <>
                    <MDBTable striped bordered small>
                        <MDBTableHead color="primary-color" textWhite>
                            <tr>
                                <th>ID</th>
                                <th>Имя</th>
                                <th>Фамилия</th>
                                <th>UserName</th>
                                <th>Создан</th>
                                <th>Email</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {users.content.map((user) => {
                                return (
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>{user.firstName}</td>
                                        <td>{user.lastName}</td>
                                        <td>{user.userName}</td>
                                        <td>{convertDateTime(user.createdOn)}</td>
                                        <td>{user.email}</td>
                                    </tr>
                                );
                            })}
                        </MDBTableBody>
                    </MDBTable>
                    <Pagination
                        hideNavigation
                        activePage={page}
                        totalItemsCount={users.totalElements}
                        itemsCountPerPage={100}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass={cn({
                            pagination: true,
                            "pg-blue": true,
                            [styles.pagination]: true,
                        })}
                    />
                </>
            )}
        </div>
    );
};

export default UsersList;
