import {MDBBtn} from "mdbreact";
import React from "react";
import styles from "./styles.module.sass";

const PlayerV2SetWithoutQuestions = ({handleExitClick}: {handleExitClick: () => void}) => {
    return (
        <div className={styles.result}>
            <p className={styles.title}>В этом комплекте пока нет заданий</p>

            <img
                src={require("../../../../../assets/characters/emotions/raven_sad_01.svg")}
                alt="raven-sad"
            />

            <div className={styles.controls}>
                <MDBBtn onClick={handleExitClick} color="success">
                    Вернуться назад
                </MDBBtn>
            </div>
        </div>
    );
};

export default PlayerV2SetWithoutQuestions;
