import {
    MDBBtn,
    MDBContainer,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
} from "mdbreact";
import React from "react";
import PropTypes from "prop-types";

const ConfirmModal = ({isOpen, toggle, handleClick, text, isDanger}) => {
    return (
        <MDBContainer>
            <MDBModal isOpen={isOpen} toggle={toggle} centered overflowScroll={false}>
                <MDBModalHeader toggle={toggle}>
                    <p>Подтверждение действия</p>
                </MDBModalHeader>
                <MDBModalBody>{text}</MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="info" className="mr-3" onClick={() => handleClick(false)}>
                        Отменить
                    </MDBBtn>
                    {isDanger ? (
                        <MDBBtn color="danger" onClick={() => handleClick(true)}>
                            Удалить
                        </MDBBtn>
                    ) : (
                        <MDBBtn color="primary" onClick={() => handleClick(true)}>
                            Применить
                        </MDBBtn>
                    )}
                </MDBModalFooter>
            </MDBModal>
        </MDBContainer>
    );
};

ConfirmModal.propTypes = {
    handleClick: PropTypes.func,
    isDanger: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
    toggle: PropTypes.func,
};

export default ConfirmModal;
