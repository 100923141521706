import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import {ADMIN_PANEL_MENU_ITEMS} from "../core/menu-items";
import {useHistory} from "react-router-dom";
import {MenuItem} from "./menu-item";
import {auth} from "../../../core/utils/auth";
import {url} from "../../../config";

const AdminPanelSideMenu = () => {
    const history = useHistory();
    const {keycloak} = auth;
    const [currentLocation, setCurrentLocation] = useState<string>("");

    useEffect(() => {
        if (!currentLocation.trim()) {
            setCurrentLocation(history.location.pathname);
        }

        history.listen((location) => {
            setCurrentLocation(location.pathname);
        });
    }, [history]);

    return (
        <div className={styles.menuWrapper}>
            <div className={styles.menuHeader}>
                <img
                    src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                    alt="logo"
                    style={{height: "35px", width: "35px"}}
                />

                <p className={styles.title} onClick={() => history.push("/")}>
                    Умная Ворона
                </p>
            </div>

            <div className={styles.items}>
                {ADMIN_PANEL_MENU_ITEMS.map((item, index) => {
                    if (item.type === "DIVIDER") {
                        return (
                            <div key={index} className={styles.divider}>
                                {item.name}
                            </div>
                        );
                    } else {
                        if (!item.path) return;
                        return (
                            <MenuItem
                                key={index}
                                item={item}
                                handleClick={() => history.push(item.path)}
                                currentLocation={currentLocation}
                            />
                        );
                    }
                })}

                <MenuItem
                    item={{
                        name: "Выход из аккаунта",
                        color: "red",
                        icon: "fa-solid fa-right-from-bracket",
                    }}
                    handleClick={() => keycloak.logout()}
                    currentLocation={currentLocation}
                />
            </div>
        </div>
    );
};

export default AdminPanelSideMenu;
