import {ThemeAPI} from "entries/school-themes/core/api/themes";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {AsyncPaginate} from "react-select-async-paginate";

const SchoolThemesSelect = ({subjects, classes, handleSelectTheme, isMulti = true, theme}) => {
    const [key, setKey] = useState(0);

    useEffect(() => {
        setKey(key + 1);
    }, [subjects, classes]);

    async function loadOptions(value, loadedOptions, {page}) {
        console.log(value);
        let subjectsIds = subjects.map((item) => item.value);
        const response = await ThemeAPI.getGroupingThemes(classes, subjectsIds, page, value);
        return {
            options: response.data.content.map((item) => {
                return {
                    value: item.theme.id,
                    label: item.theme.name,
                };
            }),
            hasMore: !response.data.last,
            additional: {
                page: page + 1,
            },
        };
    }

    return (
        <AsyncPaginate
            key={key}
            styles={{menu: (provided) => ({...provided, zIndex: 9999})}}
            loadOptions={loadOptions}
            onChange={handleSelectTheme}
            isMulti={isMulti}
            closeMenuOnSelect={true}
            placeholder={"Выберите тему"}
            value={theme && theme}
            additional={{
                page: 1,
            }}
        />
    );
};

SchoolThemesSelect.propTypes = {
    classes: PropTypes.array,
    handleSelectTheme: PropTypes.func,
    isMulti: PropTypes.bool,
    subjects: PropTypes.any,
    theme: PropTypes.any,
};

export default SchoolThemesSelect;
