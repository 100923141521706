import {Request} from "../../../core/utils/request";

export const AdminPanelSchoolsAPI = {
    getTempSchools: (page: number, isHidden: boolean) => {
        return Request.get(`temp-school?page=${page}&size=50&isHidden=${isHidden}`);
    },
    editTempSchoolVisible: (requestId: number, isHidden: boolean) => {
        return Request.put(`temp-school/${requestId}?isHidden=${isHidden}`);
    },
};
