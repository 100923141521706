import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import {useStores} from "../../hooks/use-stores";

const ServiceDetectDevice = observer(() => {
    const {playerStore, appStore} = useStores();
    useEffect(() => {
        playerStore.updateIsMobileMode(window.innerWidth);
        appStore.updateIsMobile(window.innerWidth);

        window.addEventListener("resize", (e: UIEvent) => {
            const eventWindow = e.target as Window;
            playerStore.updateIsMobileMode(eventWindow.innerWidth);
            appStore.updateIsMobile(eventWindow.innerWidth);
        });
    }, []);

    return null;
});

export default ServiceDetectDevice;
