import {useEffect, useState} from "react";
import {UserAPI} from "../../../core/api/user";
import {AxiosResponse} from "axios";

const usePreviewProfile = () => {
    const [profiles, setProfiles] = useState<any>({});
    const [currentProfile, setCurrentProfile] = useState<any>({});
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [isCtrlKeyDowned, setIsCtrlKeyDowned] = useState(false);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    const handleKeyDown = (e: KeyboardEvent) => {
        setIsCtrlKeyDowned(e.key === "Control");
    };

    const handleKeyUp = () => {
        setIsCtrlKeyDowned(false);
    };

    const handleLoadProfile = (userId: number) => {
        if (!userId) {
            return;
        }

        if (Object(profiles).hasOwnProperty(userId)) {
            setCurrentProfile(profiles[userId]);
            return;
        }

        setIsProfileLoading(true);
        UserAPI.getUser(userId)
            .then((response: AxiosResponse) => {
                let user = response.data;
                setProfiles({...profiles, [user.id]: user});
                setCurrentProfile(user);

                return UserAPI.getUserProfileById(userId).then((response: AxiosResponse) => {
                    user = {...user, additional: response.data};
                    setProfiles({...profiles, [user.id]: user});
                    setCurrentProfile(user);
                });
            })
            .finally(() => {
                setIsProfileLoading(false);
            });
    };

    return {
        isCtrlKeyDowned,
        handleLoadProfile,
        currentProfile,
        isProfileLoading,
    };
};

export default usePreviewProfile;
