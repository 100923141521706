import {ConfigProvider} from "antd";
import {errorFallback} from "entries/app-error/app-error";
import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import {BrowserRouter} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Routes from "routes/routes";
import Services from "services/services";
import ruRU from "antd/locale/ru_RU";

import "../styles/App.sass";

export const App = () => {
    return (
        <ErrorBoundary FallbackComponent={errorFallback}>
            <BrowserRouter>
                <ConfigProvider
                    theme={{
                        token: {
                            fontFamily: "Open Sans",
                            colorPrimary: "#45b7d6",
                            colorInfo: "#45b7d6",
                            fontSize: 15,
                        },
                    }}
                    locale={ruRU}
                >
                    <Services />
                    <Routes />
                </ConfigProvider>
            </BrowserRouter>
        </ErrorBoundary>
    );
};
