import CreateTest from "entries/constructors/classic-test/views/containers/create-test/create-test";
import {DraggingAPI} from "entries/drag-area/core/api/DraggingAPI";
import DragAreaFunctional from "entries/drag-area/DragAreaFunctional";
import React, {useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import PropTypes from "prop-types";

const DragAreaManager = ({routerInstance}) => {
    let history = useHistory();
    const [testData, setTestData] = useState({});
    const [mode] = useState("ACADEMY");

    const onCreateTest = (testData) => {
        setTestData(testData);
        history.push(routerInstance.DRAG_AREA_TEST.CONSTRUCTOR);
    };

    return (
        <div>
            <Switch>
                <Route path={routerInstance.DRAG_AREA_TEST.CREATE_TEST}>
                    <CreateTest onCreateTest={onCreateTest} apiInstance={DraggingAPI} mode={mode} />
                </Route>

                <Route path={routerInstance.DRAG_AREA_TEST.CONSTRUCTOR}>
                    <DragAreaFunctional testData={testData} mode={mode} />
                </Route>
            </Switch>
        </div>
    );
};

DragAreaManager.propTypes = {
    routerInstance: PropTypes.any,
};

export default DragAreaManager;
