import React from "react";
import {Route, Switch} from "react-router-dom";
import AdminPanelHeader from "../admin-panel-header/admin-panel-header";
import styles from "./styles.module.sass";
import {ADMIN_PANEL_ROUTES, ADMIN_PANEL_SUBSCRIPTIONS_ACTIONS} from "../core/routes";
import AdminPanelItemCard from "../admin-panel-item-card/admin-panel-item-card";

const AdminPanelSubscriptions = () => {
    return (
        <React.Fragment>
            <AdminPanelHeader title="Подписки" />
            <div className={styles.wrapper}>
                <Switch>
                    <Route path={ADMIN_PANEL_ROUTES.SUBSCRIBES.ROOT} exact={true}>
                        {ADMIN_PANEL_SUBSCRIPTIONS_ACTIONS.map((action) => {
                            return (
                                <AdminPanelItemCard
                                    key={action.id}
                                    title={action.title}
                                    icon={action.icon}
                                    color={action.color}
                                    path={action.path}
                                    isSuperAdmin={action.isSuperAdmin}
                                />
                            );
                        })}
                    </Route>

                    {ADMIN_PANEL_SUBSCRIPTIONS_ACTIONS.map((route) => (
                        <Route exact={route.exact} key={route.id} path={route.path}>
                            {route.component}
                        </Route>
                    ))}
                </Switch>
            </div>
        </React.Fragment>
    );
};

export default AdminPanelSubscriptions;
