import {url} from "config";
import {ADMIN_PANEL_ROUTES} from "entries/admin-panel/core/routes";
import {TagColors} from "entries/articles/core/tag-colors";
import TagList from "entries/tags/views/tag-list/tag-list";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {EditButtons} from "views/components/edit-buttons";
import {PAGES_ROUTES} from "views/routes/routes";
import styles from "./styles.module.sass";
import noLogo from "assets/images/static/no-logo.svg";

const ArticleListItem = ({article, handleDeletePublication, handleEditPublication, isPublic}) => {
    useHistory();
    const [mainArticles, setMainArticles] = useState(null);

    useEffect(() => {
        setMainArticles(article.tags?.filter((tag) => tag.sectionTag?.id === 72));
    }, [article]);

    return (
        <div className={styles.article} id="article">
            <img
                className={styles.articleImagePreview}
                src={article.imageOne ? url + article.imageOne?.fileDownloadUri.slice(1) : noLogo}
                alt="news-preview"
            />

            <div>
                <TagList
                    tags={mainArticles}
                    isCards
                    cardClassName={styles.tagFilled}
                    className={styles.tagWrapper}
                    withShowAllTags
                />

                <Link
                    className={styles.articleTitle}
                    to={{
                        pathname: isPublic
                            ? PAGES_ROUTES.PUBLICATIONS.VIEW.replace(":url", article.publicationUrl)
                            : ADMIN_PANEL_ROUTES.ARTICLES.EDIT.replace(
                                  ":url",
                                  article.publicationUrl,
                              ),
                    }}
                >
                    {article.title}
                </Link>

                <div className={styles.articleText}>{article.description}</div>

                <TagList
                    tags={article.tags}
                    cardClassName={styles.tag}
                    isCards
                    className={styles.tagWrapper}
                    withShowAllTags
                    customTagColors={TagColors}
                    hideDelete={isPublic}
                    url={PAGES_ROUTES.PUBLICATIONS.VIEW_BY_TAG_ID}
                />
            </div>

            {/*<p className={styles.articleDate}>Создано: {convertDateTime(article.updatedOn)}</p>*/}

            {!isPublic && (
                <EditButtons
                    editAction={() => handleEditPublication(article)}
                    deleteAction={() => handleDeletePublication(article.id)}
                />
            )}
        </div>
    );
};

export default ArticleListItem;
