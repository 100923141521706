import React from "react";
import {MDBContainer, MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import PropTypes from "prop-types";

import {CKEdit} from "views/containers/ck-editor";

export const FormulaUploadModal = props => {
    const {visible, toggle, saveFormula} = props;

    return (
        <MDBContainer>
            <MDBModal isOpen={visible} toggle={toggle} size="lg">
                <MDBModalHeader toggle={toggle}>Формулы</MDBModalHeader>
                <MDBModalBody className="container">
                    <CKEdit saveFormula={saveFormula} />
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    );
};

FormulaUploadModal.propTypes = {
    saveFormula: PropTypes.func,
    toggle: PropTypes.func,
    visible: PropTypes.bool,
};
