import React, {useState} from "react";
import Premium from "./premium";
import {PaymentAPI} from "../../views/pages/about/sliders/buy-subscription-slide/core/PaymentAPI";

const PremiumContainer = () => {
    const [selectedPremiumTime, setSelectedPremiumTime] = useState("MONTH");
    const handlePayment = (id: number) => {
        PaymentAPI.getPaymentParameters(id, "").then((response: any) => {
            if (response.data) {
                window.open(
                    `https://auth.robokassa.ru/Merchant/Index.aspx?${response.data}`,
                    "_self",
                );
            }
        });
    };

    return (
        <Premium
            handlePayment={handlePayment}
            selectedPremiumTime={selectedPremiumTime}
            setSelectedPremiumTime={setSelectedPremiumTime}
        />
    );
};

export default PremiumContainer;
