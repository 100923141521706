import {url} from "config";
import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";

const TeacherCareerSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${url + "data/image-bank/1bc10644-13ba-4e7b-8842-dc68888ecc25.png"})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };

    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            <div className={sliders.content}>
                <div className={sliders.about}>
                    <p className={sliders.title}>Работайте вместе</p>
                    <p className={sliders.description}>
                        Пополняя общий банк заданий, мы обмениваемся оригинальными идеями и
                        накопленным опытом. А значит, помогаем друг другу сделать любимую работу
                        легче и интересней! И конечно, благодарим друг друга!
                    </p>
                </div>
            </div>
        </div>
    );
};

TeacherCareerSlide.propTypes = {};

export default TeacherCareerSlide;
