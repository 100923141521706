import {MDBBtn} from "mdbreact";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "entries/constructors/word-skip/views/text-box-with-select/text-box-with-select.module.sass";

export const TextBoxWithSelect = ({
    splittedText,
    onSelectWord,
    onSaveSkips,
    selectedWords,
    onAddSkip,
    onDeleteSkip,
    indexesForText,
    skipWithAdditionalAnswers,
    selectSkipHandler,
    selectedSkip,
    additionalAnswers,
    newAnswerValue,
    setNewAnswerValue,
    addNewAnswer,
    deleteAdditionalAnswer,
    sentenceSkip,
}) => {
    const [skipExist, setSkipExist] = useState(false);

    useEffect(() => {}, [splittedText, selectedWords]);

    useEffect(() => {
        setSkipExist(false);
        indexesForText.forEach((wordIndex) => {
            if (Array.isArray(wordIndex)) {
                setSkipExist(true);
            }
        });
    }, [indexesForText]);

    useEffect(() => {
        if (sentenceSkip && indexesForText.length > 0) {
            let check = true;
            indexesForText.forEach((wordIndex) => {
                if (typeof wordIndex === "number") {
                    check = false;
                }
            });

            if (check) {
                setSkipExist(true);
            } else {
                setSkipExist(false);
            }
        }
    }, [indexesForText]);

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.textBox}>
                    {indexesForText.map((textIndex, idx) => {
                        if (Array.isArray(textIndex)) {
                            if (skipWithAdditionalAnswers) {
                                return (
                                    <span
                                        key={idx}
                                        className={cn({
                                            [styles.skip]: true,
                                            [styles.selectedSkip]:
                                                selectedSkip && selectedSkip.indexInArray === idx,
                                        })}
                                    >
                                        <span onClick={() => selectSkipHandler(textIndex, idx)}>
                                            {textIndex.map((textIdx, index) => (
                                                <span
                                                    key={index}
                                                >{`${splittedText[textIdx]} `}</span>
                                            ))}
                                        </span>
                                        <i
                                            onClick={() => onDeleteSkip(textIndex, idx)}
                                            className="fas fa-times"
                                        />
                                    </span>
                                );
                            } else {
                                return (
                                    <span key={idx} className={styles.skip}>
                                        {textIndex.map((textIdx, index) => (
                                            <span key={index}>{`${splittedText[textIdx]} `}</span>
                                        ))}
                                        <i
                                            onClick={() => onDeleteSkip(textIndex, idx)}
                                            className="fas fa-times"
                                        />
                                    </span>
                                );
                            }
                        } else {
                            return (
                                <span
                                    key={idx}
                                    className={
                                        selectedWords.includes(textIndex) ? styles.selected : null
                                    }
                                    onClick={() => onSelectWord(textIndex)}
                                >
                                    {`${splittedText[textIndex]} `}
                                </span>
                            );
                        }
                    })}
                </div>
                <div className={styles.saveButton}>
                    <MDBBtn color="success" size="md" onClick={onSaveSkips} disabled={!skipExist}>
                        <i className="fas fa-check" /> Сохранить пропуски
                    </MDBBtn>
                </div>
            </div>

            <div className={styles.right}>
                <div>
                    {selectedWords && selectedWords.length > 0 && (
                        <MDBBtn
                            className={cn({
                                animate__bounceIn: true,
                            })}
                            block
                            onClick={onAddSkip}
                            color="success"
                        >
                            + {selectedWords.map((idx) => `${splittedText[idx]} `)}
                        </MDBBtn>
                    )}
                </div>

                {skipWithAdditionalAnswers && selectedSkip && selectedWords.length === 0 && (
                    <div className={styles.newAnswersContainer}>
                        <p className="title">Из чего ученик будет выбирать</p>
                        <div className={styles.answersContainer}>
                            {additionalAnswers.map((answers) => {
                                if (answers.skipIndexes === selectedSkip.skipIndexes) {
                                    return answers.answers.map((answer, index) => (
                                        <div className={styles.answersContainerItem} key={index}>
                                            <p>
                                                {answer.word}
                                                {answer.isEditable && (
                                                    <i
                                                        onClick={() =>
                                                            deleteAdditionalAnswer(answer)
                                                        }
                                                        className="fas fa-times"
                                                    />
                                                )}
                                            </p>
                                        </div>
                                    ));
                                }
                            })}
                        </div>
                        <div className={styles.addNewAnswerContainer}>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={newAnswerValue}
                                    onChange={(e) => setNewAnswerValue(e.target.value)}
                                    placeholder="Ещё вариант"
                                />
                            </div>
                            <div>
                                <MDBBtn
                                    onClick={() => addNewAnswer(selectedSkip)}
                                    className={styles.addItem}
                                    color="success"
                                    size="sm"
                                >
                                    +
                                </MDBBtn>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

TextBoxWithSelect.propTypes = {
    indexesForText: PropTypes.array.isRequired,
    selectedWords: PropTypes.array.isRequired,
    splittedText: PropTypes.array.isRequired,
    onAddSkip: PropTypes.func.isRequired,
    onDeleteSkip: PropTypes.func.isRequired,
    onSaveSkips: PropTypes.func.isRequired,
    onSelectWord: PropTypes.func.isRequired,
    additionalAnswers: PropTypes.array,
    addNewAnswer: PropTypes.func,
    deleteAdditionalAnswer: PropTypes.func,
    newAnswerValue: PropTypes.string,
    selectedSkip: PropTypes.any,
    selectSkipHandler: PropTypes.func,
    sentenceSkip: PropTypes.bool,
    setNewAnswerValue: PropTypes.func,
    skipWithAdditionalAnswers: PropTypes.bool,
};
