import React from "react";
import {useHistory} from "react-router-dom";
import styles from "./styles.module.sass";
import {Helmet} from "react-helmet";

const PageNotFound = () => {
    let history = useHistory();
    return (
        <div className={styles.wrapper}>
            <Helmet>
                <meta name="prerender-status-code" content="404" />
            </Helmet>
            <h1>404 Такой страницы нет</h1>
            <img src={require("../../../assets/characters/raven/raven-404.svg")} alt="not-found" />
            <p className="link" onClick={() => history.push("/")}>
                Вернуться на главную
            </p>
        </div>
    );
};

export default PageNotFound;
