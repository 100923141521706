import React, {useEffect, useState} from "react";
import {Roles} from "../../enums/roles";
import {useStores} from "../../hooks/use-stores";

const YandexAd = () => {
    const {userStore} = useStores();
    const [isInitialAdLoaded, setIsInitialAdLoaded] = useState(false);
    const AD_PC_SCRIPT = `
        window.yaContextCb.push(()=>{
            Ya.Context.AdvManager.render({
                renderTo: 'yandex_rtb_R-A-1983523-1',
                blockId: 'R-A-1983523-1'
            })
        })
    `;

    const AD_PC_SCRIPT_2 = `
        window.yaContextCb.push(()=>{
            Ya.Context.AdvManager.render({
                blockId: 'R-A-1983523-3',
                renderTo: 'yandex_rtb_R-A-1983523-3'
            })
        })
    `;

    const AD_MOBILE_SCRIPT = `
        window.yaContextCb.push(()=>{
             Ya.Context.AdvManager.render({
                type: 'floorAd',    
                blockId: 'R-A-1983523-2'
             })
        })
    `;

    const AD_DELAY = 35000;

    const loadScript = (script: string, scriptId: string) => {
        const oldScriptElement = document.getElementById(scriptId);
        if (oldScriptElement) {
            oldScriptElement.remove();
        }

        const yaScript = document.createElement("script");
        yaScript.setAttribute("type", "text/javascript");
        yaScript.innerHTML = script;
        yaScript.id = scriptId;
        document.head.appendChild(yaScript);
    };

    useEffect(() => {
        let pcAdInterval: any = null;
        let pcAd2Interval: any = null;
        let mobileAdInterval: any = null;

        if (userStore?.role !== Roles.ADMIN) {
            if (!isInitialAdLoaded) {
                loadScript(AD_PC_SCRIPT, "pc_ad");
                loadScript(AD_PC_SCRIPT_2, "pc_ad2");
                loadScript(AD_MOBILE_SCRIPT, "mobile_ad");
                setIsInitialAdLoaded(true);
                return;
            }

            pcAdInterval = setInterval(() => {
                loadScript(AD_PC_SCRIPT, "pc_ad");
            }, AD_DELAY);
            pcAd2Interval = setInterval(() => {
                loadScript(AD_PC_SCRIPT_2, "pc_ad2");
            }, AD_DELAY);
            mobileAdInterval = setInterval(() => {
                loadScript(AD_MOBILE_SCRIPT, "mobile_ad");
            }, AD_DELAY);
        }

        return () => {
            clearInterval(pcAdInterval);
            clearInterval(pcAd2Interval);
            clearInterval(mobileAdInterval);
        };
    }, [userStore.role, isInitialAdLoaded]);

    return (
        <React.Fragment>
            <div
                id="yandex_rtb_R-A-1983523-1"
                style={{
                    position: "sticky",
                    top: 10,
                }}
            ></div>

            <div id="R-A-1983523-2" style={{maxHeight: "30vh"}}></div>
        </React.Fragment>
    );
};

export default YandexAd;
