export enum EPublicationCustomActionTypes {
    GALLERY_SAVE_IMAGES = "GALLERY_SAVE_IMAGES",
    GALLERY_DELETE_IMAGE = "GALLERY_DELETE_IMAGE",
    GALLERY_CHANGE_NAME = "GALLERY_CHANGE_NAME",
    PLAYER_ADD_TASK = "PLAYER_ADD_TASK",
    PLAYER_DELETE_TASK = "PLAYER_DELETE_TASK",
    FILES_UPLOAD_FILE = "FILES_UPLOAD_FILE",
    FILES_UPDATE_NAME = "FILES_UPDATE_NAME",
    FILES_DELETE_FILE = "FILES_DELETE_FILE",
    TITLE_SAVE = "TITLE_SAVE",
    IMAGE_CHANGE_NAME = "IMAGE_CHANGE_NAME",
    SIMILAR_ADD_ARTICLE = "SIMILAR_ADD_ARTICLE",
    SIMILAR_DELETE_ARTICLE = "SIMILAR_DELETE_ARTICLE",
    ADD_VIDEO_URL = "ADD_VIDEO_URL",
}
