import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import {useStores} from "../../../hooks/use-stores";
import HintsAddModal from "../../modals/hints-add-modal/hints-add-modal";
import {IHint} from "../../../interfaces/IHint";
import SpinnerWithText from "../../spinner/spinner";
import {HintsAPI} from "../hints-api";
import {toast} from "react-toastify";
import {EditButtons} from "../../../views/components/edit-buttons";

interface IProps {
    questionId: number;
}

const HintsAdd = ({questionId}: IProps) => {
    const {appStore} = useStores();
    const [isLoading, setIsLoading] = useState(false);
    const [hints, setHints] = useState<IHint[] | null>(null);

    const getHintsForQuestion = () => {
        setIsLoading(true);
        HintsAPI.getHintsByQuestionId(questionId)
            .then((response) => {
                setHints(response.data);
            })
            .catch(() => toast.error("Ошибка загрузки подсказок"))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDeleteHint = (hintId: number) => {
        setIsLoading(true);
        HintsAPI.deleteHintAdmin(hintId)
            .then(() => {
                getHintsForQuestion();
            })
            .catch(() => toast.error("Ошибка удаления подсказки"))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (!questionId) return;
        getHintsForQuestion();
    }, [questionId]);

    return (
        <div className={styles.hintsAdd}>
            <div className={styles.hintsAddHeader}>
                <p className={styles.title}>Подсказки</p>
                <p
                    className="link"
                    onClick={() => {
                        appStore.addModalToQueue(
                            <HintsAddModal
                                toggle={appStore.closeActiveModal}
                                key={"hints-add-modal toggle-disabled"}
                                questionId={questionId}
                                onUpdate={getHintsForQuestion}
                            />,
                        );
                    }}
                >
                    Добавить
                </p>
            </div>
            <div className={styles.hintsBody}>
                {isLoading ? (
                    <React.Fragment>
                        <SpinnerWithText text="Загрузка..." />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {hints && hints?.length > 0 ? (
                            <div>
                                {hints.map((hint) => {
                                    return (
                                        <div key={hint.id} className={styles.hint}>
                                            <p>
                                                {hint.hintType === "ANSWER"
                                                    ? `Правильный ответ`
                                                    : "Подсказка"}
                                            </p>
                                            <p>{hint.price} монет</p>
                                            <EditButtons
                                                editAction={() => {
                                                    appStore.addModalToQueue(
                                                        <HintsAddModal
                                                            toggle={appStore.closeActiveModal}
                                                            key={"hints-add-modal toggle-disabled"}
                                                            questionId={questionId}
                                                            hintId={hint.id}
                                                            onUpdate={getHintsForQuestion}
                                                        />,
                                                    );
                                                }}
                                                deleteAction={() => handleDeleteHint(hint.id)}
                                                withoutDeleteConfirmation
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <p>Подсказки пока не созданы</p>
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default HintsAdd;
