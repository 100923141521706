import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {url} from "config";
import {EditButtons} from "views/components/edit-buttons";

import styles from "./achievement.module.sass";

export const Achievement = ({
    imageUrl,
    rating,
    text,
    onSelect,
    title,
    withEditButtons,
    deleteAction,
    isDisabled = false,
    className,
}) => {
    return (
        <div
            className={cn({
                animate__animated: true,
                animate_fadeIn: true,
                [styles.achievement]: true,
                [styles.select]: onSelect,
                [styles.disabled]: isDisabled,
                [className]: !!className,
            })}
            onClick={onSelect ? () => onSelect() : null}
        >
            {imageUrl && <img src={imageUrl ? `${url}${imageUrl}` : null} alt="logo" />}
            <div>
                {title}
                <p className={styles.text}>
                    {text}
                    {!!rating && (
                        <span className={styles.rating}>
                            <i className="fa fa-star" aria-hidden="true" /> {rating}
                        </span>
                    )}
                </p>
            </div>

            {withEditButtons && (
                <EditButtons deleteAction={deleteAction} className={styles.editButtons} />
            )}
        </div>
    );
};

Achievement.propTypes = {
    deleteAction: PropTypes.func,
    id: PropTypes.number,
    imageUrl: PropTypes.string,
    rating: PropTypes.any,
    text: PropTypes.string,
    title: PropTypes.string,
    withEditButtons: PropTypes.bool,
    onSelect: PropTypes.func,
    isDisabled: PropTypes.bool,
    className: PropTypes.string,
};
