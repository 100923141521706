import React, {useEffect, useState} from "react";
import {BannerTable} from "../../widgets/banner-table";
import {IPageable} from "../../../interfaces/IPageable";
import {Banner, BannerAPI} from "../../entities/banner";
import {Button, Form} from "antd";
import {BannerModal} from "../../widgets/banner-modal";
import {toast} from "react-toastify";

const BannerPage = () => {
    const [banners, setBanners] = useState<IPageable<Banner> | undefined>(undefined);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bannerForUpdate, setBannerForUpdate] = useState<Banner | undefined>(undefined);
    const [form] = Form.useForm();

    useEffect(() => {
        getBanners();
    }, [page, pageSize]);

    const getBanners = () => {
        setIsLoading(true);
        BannerAPI.getBanners(page, pageSize)
            .then((response) => setBanners(response.data))
            .finally(() => setIsLoading(false));
    };

    const handlePaginationChange = (page: number, size: number) => {
        setPage(page);
        setPageSize(size);
    };

    const handleSave = (banner: Banner) => {
        if (bannerForUpdate) {
            BannerAPI.updateBanner(bannerForUpdate.id, banner)
                .then((response) => {
                    toast.success("Баннер обновлен");
                    setIsModalOpen(false);
                    getBanners();
                })
                .finally(() => {
                    setBannerForUpdate(undefined);
                    form.resetFields();
                });
        } else {
            BannerAPI.createBanner(banner)
                .then((response) => {
                    toast.success("Баннер создан");
                    setIsModalOpen(false);
                    getBanners();
                })
                .finally(() => {
                    setBannerForUpdate(undefined);
                    form.resetFields();
                });
        }
    };

    const handleDeleteBanner = (id: number) => {
        BannerAPI.deleteBanner(id).then((response) => {
            toast.success("Баннер удален");
            getBanners();
        });
    };

    return (
        <React.Fragment>
            <BannerModal
                isOpen={isModalOpen}
                handleClose={() => {
                    setIsModalOpen(false);
                    setBannerForUpdate(undefined);
                }}
                bannerForUpdate={bannerForUpdate}
                handleSave={handleSave}
                form={form}
            />

            <div className="mb-2">
                <Button
                    type="primary"
                    onClick={() => {
                        setIsModalOpen(true);
                        setBannerForUpdate(undefined);
                        form.resetFields();
                    }}
                >
                    Создать
                </Button>
            </div>
            <BannerTable
                banners={banners}
                handlePaginationChange={handlePaginationChange}
                isLoading={isLoading}
                onBannerUpdateAction={(banner) => {
                    setBannerForUpdate(banner);
                    form.setFieldsValue(banner);
                    setIsModalOpen(true);
                }}
                onBannerDeleteAction={handleDeleteBanner}
            />
        </React.Fragment>
    );
};

export default BannerPage;
