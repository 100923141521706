import cn from "classnames";
import {url} from "config";
import {USER_ROLE} from "config/roles";
import {MDBBtn} from "mdbreact";
import React from "react";
import GeoSelectCityContainer from "../geo-selects/views/geo-select-city/geo-select-city-container";
import GeoSelectRegionsContainer from "../geo-selects/views/geo-select-regions/geo-select-regions-container";
import GeoSelectSchoolContainer from "../geo-selects/views/geo-select-school/geo-select-school-container";
import SchoolClassesSelectContainer from "../school-classes-select/school-classes-select-container";
import SchoolSubjectsSelectContainer from "../school-subjects-select/school-subjects-select-container";
import {SELECT_ROLE_TEACHER, SELECT_ROLE_USER} from "../select-role/select-role-container";
import Thumbler from "../thumbler/thumbler";
import styles from "./styles.module.sass";

const RegisterForm = ({
    handleSelectRegion,
    handleSelectCity,
    handleSelectSchool,
    handleSelectGrade,
    handleSelectGender,
    handleSelectRole,
    region,
    city,
    grade,
    gender,
    role,
    handleRegister,
    isLoading,
    teacherPosition,
    setTeacherPosition,
    selectedSubjects,
    handleSelectSubject,
    userRole,
    setIsSchoolInputOpen,
    isSchoolInputOpen,
    schoolInputValue,
    setSchoolInputValue,
}) => {
    return (
        <div className={styles.RegisterContainer}>
            <img
                className={styles.Raven}
                src={url + "data/image-bank/c10283ae-144e-49fa-9568-0abcf27bfdf1.svg"}
                alt="raven-choose-role"
            />
            <div className={styles.RegisterForm}>
                <p className={styles.FormTitle}>Завершение регистрации</p>
                <div className={styles.FormContainer}>
                    {userRole === USER_ROLE && (
                        <div className={styles.FormItem}>
                            <p>Роль</p>
                            <Thumbler
                                isFullWidth
                                options={[
                                    {value: SELECT_ROLE_USER, name: "Ученик"},
                                    {value: SELECT_ROLE_TEACHER, name: "Учитель"},
                                ]}
                                activeState={role}
                                setActiveState={handleSelectRole}
                            />
                        </div>
                    )}

                    <div className={styles.FormItem}>
                        <p>Регион</p>
                        <GeoSelectRegionsContainer handleSelect={handleSelectRegion} />
                    </div>
                    <div className={styles.FormItem}>
                        <p>Город</p>
                        <GeoSelectCityContainer
                            regionId={region?.value}
                            handleSelect={handleSelectCity}
                        />
                    </div>
                    <div className={styles.FormItem}>
                        <p>Школа</p>

                        {isSchoolInputOpen ? (
                            <input
                                autoComplete="false"
                                className="form-control"
                                id="school-input"
                                onChange={(e) => setSchoolInputValue(e.target.value)}
                                type="text"
                                value={schoolInputValue}
                                style={{minHeight: 35}}
                            />
                        ) : (
                            <GeoSelectSchoolContainer
                                cityId={city?.value}
                                regionId={region?.value}
                                handleSelect={handleSelectSchool}
                            />
                        )}

                        <p
                            className={cn({
                                link: true,
                                [styles.Link]: true,
                            })}
                            onClick={() => setIsSchoolInputOpen(!isSchoolInputOpen)}
                        >
                            {isSchoolInputOpen ? "К выбору школы" : "Не могу найти школу"}
                        </p>
                    </div>
                    {role === SELECT_ROLE_TEACHER && (
                        <div className={styles.FormItem}>
                            <p>Должность</p>
                            <input
                                autoComplete="false"
                                className="form-control"
                                id="teacher-position"
                                onChange={(e) => setTeacherPosition(e.target.value)}
                                type="text"
                                style={{minHeight: 35}}
                                value={teacherPosition}
                            />
                        </div>
                    )}
                    {role === SELECT_ROLE_USER && (
                        <div className={styles.FormItem}>
                            <p>Класс</p>
                            <SchoolClassesSelectContainer
                                handleSelect={handleSelectGrade}
                                selectValue={grade}
                            />
                        </div>
                    )}
                    {role === SELECT_ROLE_TEACHER && (
                        <div className={styles.FormItem}>
                            <p>Предмет</p>
                            <SchoolSubjectsSelectContainer
                                handleSelectSubject={handleSelectSubject}
                                selectedSubjects={selectedSubjects}
                                isMulti={true}
                            />
                        </div>
                    )}

                    <div className={styles.FormItem}>
                        <p>Пол</p>
                        <Thumbler
                            isFullWidth
                            options={[
                                {value: "MAN", name: "Мужской"},
                                {value: "WOMAN", name: "Женский"},
                            ]}
                            activeState={gender}
                            setActiveState={handleSelectGender}
                        />
                    </div>
                </div>
                <MDBBtn color={"success"} onClick={handleRegister} disabled={isLoading}>
                    Сохранить
                </MDBBtn>
            </div>
        </div>
    );
};

export default RegisterForm;
