import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import {EditButtons} from "views/components/edit-buttons";

import styles from "./NumericSequence.module.sass";

export const NumericSequenceItem = (props) => {
    const {
        className,
        index,
        children,
        isEditMode,
        elementSize,
        handleDelete,
        handleEdit,
        ...controlProps
    } = props;

    return (
        <div
            data-index={index}
            className={cn({
                [styles.NumericSequenceItem]: true,
                [className]: !!className,
                [styles.NumericSequenceItemBorder]: isEditMode,
            })}
            style={elementSize && {height: `${elementSize}px`}}
            {...controlProps}
        >
            {children}
            {index && (
                <div
                    className={cn({
                        [styles.NumericSequenceItemIndex]: true,
                        animate__animated: true,
                        animate__zoomIn: true,
                    })}
                >
                    {index}
                </div>
            )}
            {isEditMode && (
                <EditButtons
                    withoutTopBorder
                    withoutRightBorder
                    deleteAction={() => handleDelete(props.id)}
                    editAction={() => handleEdit(props.id)}
                />
            )}
        </div>
    );
};

NumericSequenceItem.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    elementSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
    id: PropTypes.number,
    index: PropTypes.number,
    isEditMode: PropTypes.bool,
};
