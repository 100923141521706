import {Request} from "core/utils/request";
import {SIZE_S} from "utils/answers-config";
const INTERNAL_ERROR = "error.internal";
const API_PATH = `dragging`;

export const DraggingAPI = {
    createQuestion: (questionDto) => {
        return Request.post(`${API_PATH}`, questionDto);
    },
    createQuestionAdmin: (questionDto) => {
        return Request.post(`admin/${API_PATH}`, questionDto);
    },
    createProperties: (questionId) => {
        if (questionId) {
            return Request.post(`question/${questionId}/properties`, {
                sizeText: 16,
                sizeTitle: 18,
                sizeImage: SIZE_S,
            });
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    editQuestion: (questionId, answerDto) => {
        return Request.put(`${API_PATH}/${questionId}`, answerDto);
    },
};
