import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import {Draggable} from "react-beautiful-dnd";

import styles from "./box-with-answers.module.sass";

export const BoxWithAnswers = ({answers}) => {
    return (
        <div
            className={cn({
                [styles.droppableBox]: true,
            })}
        >
            {answers?.map((item, index) => (
                <Draggable key={`${item.id}`} draggableId={`${item.id}`} index={index}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={styles.cardWrapper}
                        >
                            <div className={styles.card}>{item.words}</div>
                        </div>
                    )}
                </Draggable>
            ))}
        </div>
    );
};

BoxWithAnswers.propTypes = {
    answers: PropTypes.array,
};
