import {DEFAULT_FONT_SIZE} from "config/project";
import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import {EditButtons} from "views/components/edit-buttons";

import styles from "entries/constructors/classic-test/views/components/text-answer/text-answer.module.sass";

export const TextAnswer = (props) => {
    const {
        answers,
        isSelectCorrectAnswerMode,
        correctAnswer,
        isEditMode,
        editText,
        deleteAnswer,
        setTextModalVisible,
        updateAnswerType,
        textAnswerSize,
        isTwoColumnMode,
        isPlayerMode,
    } = props;
    return (
        <>
            <div
                className={cn({
                    [styles.twoColumnsMode]: isTwoColumnMode,
                })}
            >
                {answers?.map((answer) => (
                    <div
                        className={cn({
                            [styles.container]: true,
                        })}
                        key={answer.id}
                        onClick={() => isSelectCorrectAnswerMode && correctAnswer(answer.id)}
                    >
                        {isEditMode && (
                            <EditButtons
                                editAction={editText}
                                deleteAction={deleteAnswer}
                                itemId={answer.id}
                            />
                        )}
                        <div
                            className={cn({
                                [styles.correctAnswer]: answer.isCorrect,
                                [styles.withBorder]: isEditMode,
                                [styles.playSelect]: isPlayerMode && answer.isCorrect,
                            })}
                            style={{
                                fontSize: `${textAnswerSize / DEFAULT_FONT_SIZE}rem`,
                                height: "100%",
                            }}
                        >
                            {answer.component}
                        </div>
                    </div>
                ))}

                {answers?.length < 6 && isEditMode && (
                    <div className={styles.answerContainer}>
                        <div
                            onClick={() => setTextModalVisible(true)}
                            className={styles.containerAddAnswerText}
                        >
                            <p className="link">Добавить ответ</p>
                        </div>
                        {answers.length === 0 && (
                            <EditButtons deleteAction={() => updateAnswerType("")} />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

TextAnswer.propTypes = {
    answers: PropTypes.arrayOf(PropTypes.any),
    correctAnswer: PropTypes.any,
    deleteAnswer: PropTypes.func,
    editText: PropTypes.func,
    isEditMode: PropTypes.bool,
    isPlayerMode: PropTypes.bool,
    isSelectCorrectAnswerMode: PropTypes.bool,
    isTwoColumnMode: PropTypes.bool,
    setTextModalVisible: PropTypes.func,
    textAnswerSize: PropTypes.number,
    updateAnswerType: PropTypes.func,
};
