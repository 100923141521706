import cn from "classnames";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import PlayerV2Content from "entries/player-v2/views/player-v2-content/player-v2-content";
import PlayerV2Controls from "entries/player-v2/views/player-v2-controls/player-v2-controls";
import PlayerV2InfoBlock from "entries/player-v2/views/player-v2-info-block/player-v2-info-block";
import PlayerV2ModerationContainer from "entries/player-v2/views/player-v2-moderation/player-v2-moderation-container";
import PlayerV2NotificationsLayer from "entries/player-v2/views/player-v2-notifications-layer/player-v2-notifications-layer";
import PlayerV2Stepper from "entries/player-v2/views/player-v2-stepper/player-v2-stepper";
import {PLAYER_RIGHTS} from "entries/player/views/player/utils/player-config";
import {useStores} from "hooks/use-stores";
import {observer} from "mobx-react-lite";
import PropTypes from "prop-types";
import React from "react";
import {Helmet} from "react-helmet";
import styles from "./styles.module.sass";

const PlayerV2 = observer((props) => {
    let {
        notificationContent,
        questions,
        handleSelectStep,
        currentStep,
        content,
        handleCheckAnswer,
        handleToNextQuestion,
        answer,
        selectedQuestion,
        userRights,
        withModeration,
        isAdmin,
        isCreator,
        playerMode,
        handleExit,
        resultIsLoading,
        metaTags,
    } = props;
    const {playerStore} = useStores();

    return (
        <div
            className={cn({
                [styles.player]: true,
                [styles.player__article]:
                    playerMode === PlayerV2Modes.ARTICLE || playerMode === PlayerV2Modes.PREVIEW,
            })}
        >
            {metaTags && (
                <Helmet>
                    <title>{metaTags.title}</title>
                    <meta name="description" content={metaTags.description} />
                    <meta name="keywords" content={metaTags.keywords} />
                </Helmet>
            )}

            {/*Действия на экране (загрузки, уведомления и т.п*/}
            {notificationContent && (
                <PlayerV2NotificationsLayer isActive={!!notificationContent}>
                    {notificationContent}
                </PlayerV2NotificationsLayer>
            )}

            {!playerStore.isMobileMode && (
                <PlayerV2Stepper
                    questions={questions}
                    handleSelectStep={handleSelectStep}
                    currentStep={currentStep}
                    isAdmin={isAdmin}
                    handleExit={handleExit}
                    playerMode={playerMode}
                />
            )}

            <div
                className={cn({
                    [styles.player__mobileWrapper]: playerStore.isMobileMode,
                    [styles.player__wrapper]: !playerStore.isMobileMode,
                    [styles.centerPlayer]: !(
                        playerStore.messagesSideIsOpen || playerStore.navigateSideIsOpen
                    ),
                })}
            >
                <React.Fragment>
                    {playerStore.isMobileMode && playerStore.navigateSideIsOpen && (
                        <PlayerV2Stepper
                            questions={questions}
                            handleSelectStep={handleSelectStep}
                            currentStep={currentStep}
                            isAdmin={isAdmin}
                            handleExit={handleExit}
                            selectedQuestion={selectedQuestion}
                        />
                    )}

                    <div
                        className={cn({
                            [styles.isNavigateActive]: playerStore.navigateSideIsOpen,
                            [styles.isInfoActive]: playerStore.messagesSideIsOpen,
                        })}
                    >
                        <PlayerV2Content content={content} userRights={userRights} />
                        <PlayerV2Controls
                            handleCheckAnswer={handleCheckAnswer}
                            handleToNextQuestion={handleToNextQuestion}
                            answer={answer}
                            userRights={userRights}
                        />
                    </div>

                    {playerStore.isMobileMode && playerStore.messagesSideIsOpen && (
                        <React.Fragment>
                            {withModeration ? (
                                <PlayerV2ModerationContainer
                                    taskId={selectedQuestion?.question?.id || selectedQuestion?.id}
                                    isCreator={isCreator}
                                    handleCheckAnswer={handleCheckAnswer}
                                    answer={answer}
                                    resultIsLoading={resultIsLoading}
                                    tags={
                                        selectedQuestion?.tags || selectedQuestion?.question?.tags
                                    }
                                    selectedQuestion={selectedQuestion}
                                />
                            ) : (
                                <PlayerV2InfoBlock
                                    selectedQuestion={selectedQuestion}
                                    taskId={selectedQuestion?.question?.id || selectedQuestion?.id}
                                    isAdmin={isAdmin}
                                    handleCheckAnswer={handleCheckAnswer}
                                    answer={answer}
                                    playerMode={playerMode}
                                    resultIsLoading={resultIsLoading}
                                    withAnswerButton={false}
                                    isCreator={isCreator}
                                />
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>

                {userRights.includes(PLAYER_RIGHTS.HAVE_INFO_BLOCK) && (
                    <React.Fragment>
                        {!playerStore.isMobileMode && (
                            <React.Fragment>
                                {withModeration ? (
                                    <PlayerV2ModerationContainer
                                        taskId={
                                            selectedQuestion?.question?.id || selectedQuestion?.id
                                        }
                                        isCreator={isCreator}
                                        handleCheckAnswer={handleCheckAnswer}
                                        answer={answer}
                                        resultIsLoading={resultIsLoading}
                                        tags={
                                            selectedQuestion?.tags ||
                                            selectedQuestion?.question?.tags
                                        }
                                        selectedQuestion={selectedQuestion}
                                    />
                                ) : (
                                    <PlayerV2InfoBlock
                                        selectedQuestion={selectedQuestion}
                                        taskId={
                                            selectedQuestion?.question?.id || selectedQuestion?.id
                                        }
                                        isAdmin={isAdmin}
                                        handleCheckAnswer={handleCheckAnswer}
                                        answer={answer}
                                        playerMode={playerMode}
                                        resultIsLoading={resultIsLoading}
                                        isCreator={isCreator}
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
});

PlayerV2.propTypes = {
    answer: PropTypes.any,
    content: PropTypes.object,
    currentStep: PropTypes.number,
    handleCheckAnswer: PropTypes.func,
    handleExit: PropTypes.func,
    handleSelectStep: PropTypes.func,
    handleToNextQuestion: PropTypes.func,
    isAdmin: PropTypes.bool,
    isCreator: PropTypes.bool,
    notificationContent: PropTypes.object,
    notificationLayerIsActive: PropTypes.bool,
    playerMode: PropTypes.any,
    questions: PropTypes.array,
    resultIsLoading: PropTypes.bool,
    selectedQuestion: PropTypes.object,
    withModeration: PropTypes.bool,
    metaTags: PropTypes.object,
};

export default PlayerV2;
