import {Space, Table, Tag} from "antd";
import {ColumnsType} from "antd/es/table";
import React from "react";
import {IPageable} from "../../interfaces/IPageable";
import {ITask} from "../../interfaces/ITask";
import {getFullName} from "../../core/utils/helpers";
import {useStores} from "../../hooks/use-stores";
import {getConstructorData} from "../editing/views/constructor-data";
import {ADMIN_PANEL_ROUTES} from "../admin-panel/core/routes";
import {SERVICE_ROUTES} from "../../routes/main";

interface IProps {
    tasks: IPageable<ITask> | undefined;
    handlePaginationChange: (page: number, pageSize: number) => void;
    isLoading: boolean;
}

const TaskList = ({tasks, handlePaginationChange, isLoading}: IProps) => {
    const {userStore} = useStores();

    const columns: ColumnsType<ITask> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Автор",
            dataIndex: ["user"],
            key: "id",
            render: (user) => (
                <a>
                    <b>ID: {user.id}</b> {getFullName(user.firstName, user.lastName)}
                </a>
            ),
        },
        {
            title: "Конструктор",
            key: "constructor",
            render: (_, record) => {
                let constructorData = getConstructorData(
                    record.objectType,
                    record.id,
                    ADMIN_PANEL_ROUTES.CONSTRUCTORS,
                    record.isTextAnswer,
                );
                return constructorData?.name;
            },
        },
        {
            title: "Вопрос",
            dataIndex: "title",
            key: "task",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Теги",
            key: "tags",
            dataIndex: "tags",
            render: (_, {tags}) => (
                <>
                    {tags.map((tag, index: number) => {
                        let color = "purple";
                        if (tag.id >= 1 && tag.id <= 11) {
                            color = "geekblue";
                        }
                        return (
                            <Tag key={index} color={color} style={{marginBottom: 10}}>
                                {tag.name.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: "Действия",
            key: "action",
            render: (_, record) => {
                let isAuthor = record.user.id === userStore.user?.userDto?.id;
                let constructorData = getConstructorData(
                    record.objectType,
                    record.id,
                    ADMIN_PANEL_ROUTES.CONSTRUCTORS,
                    record.isTextAnswer,
                );
                return (
                    <Space size="small" key={record.id}>
                        <a
                            onClick={() =>
                                window.open(
                                    SERVICE_ROUTES.SINGLE_TASK_PREVIEW.replace(
                                        ":id",
                                        record.id.toString(),
                                    ),
                                )
                            }
                        >
                            <i className="fas fa-play" style={{color: "green"}} />
                        </a>
                        <a href={constructorData?.editUrl} target={"_blank"}>
                            <i
                                className="fas fa-pen"
                                style={{
                                    color: isAuthor ? "orange" : "#eaeaea",
                                    cursor: isAuthor ? "pointer" : "default",
                                }}
                            />
                        </a>
                        <a>
                            <i
                                className="fas fa-trash"
                                style={{
                                    color: isAuthor ? "red" : "#eaeaea",
                                    cursor: isAuthor ? "pointer" : "default",
                                }}
                            />
                        </a>
                    </Space>
                );
            },
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={tasks?.content}
            rowKey="id"
            pagination={{
                current: tasks?.page,
                total: tasks?.totalElements,
                onChange: handlePaginationChange,
                pageSize: tasks?.size,
                hideOnSinglePage: true,
            }}
            loading={isLoading}
        />
    );
};

export default TaskList;
