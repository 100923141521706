import noLogo from "assets/images/static/no-logo.svg";
import cn from "classnames";
import {url} from "config";
import {MDBTooltip} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";
import {PUBLIC_ROUTES} from "routes/main";
import styles from "./styles.module.sass";

const TournamentParticipantItem = ({
    id,
    avatar,
    city,
    rating,
    userName,
    school,
    place,
    reward,
    noClickable = false,
}) => {
    return (
        <div
            className={cn({
                [styles.participant]: true,
            })}
            onClick={
                noClickable
                    ? () => {}
                    : () => window.open(PUBLIC_ROUTES.PAGES.USER.replace(":id", id), "_blank")
            }
        >
            <div className={styles.user}>
                <img className={styles.user__avatar} src={avatar ? avatar : noLogo} alt="avatar" />
                <p className={styles.user__rating}>
                    {rating}
                    <i className="fa fa-star" aria-hidden="true" />
                </p>
                {place && (
                    <div className={styles.reward}>
                        {reward ? (
                            <div className={styles.reward__cup}>
                                <img
                                    src={url + reward.image?.fileDownloadUri.slice(1)}
                                    alt="reward"
                                />
                                <span>{place} место</span>
                            </div>
                        ) : (
                            <span className={styles.reward__place}>{place}</span>
                        )}
                    </div>
                )}
            </div>

            <p className={styles.participant__userName}>{userName}</p>
            <MDBTooltip domElement tag="span" placement="top">
                <p className={styles.participant__city}>
                    {city?.length > 40 ? city?.substring(0, 40) + "..." : city}
                </p>
                <span>{city}</span>
            </MDBTooltip>

            <MDBTooltip domElement tag="span" placement="top">
                <p className={styles.participant__school}>
                    {school.length > 40 ? school.substring(0, 40) + "..." : school}
                </p>
                <span>{school}</span>
            </MDBTooltip>
        </div>
    );
};

TournamentParticipantItem.propTypes = {
    id: PropTypes.number,
    avatar: PropTypes.string,
    city: PropTypes.string,
    place: PropTypes.number,
    rating: PropTypes.number,
    reward: PropTypes.any,
    school: PropTypes.string,
    tournamentStatus: PropTypes.string,
    userName: PropTypes.string,
};

export default TournamentParticipantItem;
