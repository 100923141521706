import cn from "classnames";
import {url} from "config";
import {useStores} from "hooks/use-stores";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import mobileStyles from "./mobile-styles.module.sass";
import browserStyles from "./styles.module.sass";
import {PlayerV2Modes} from "../../config/player-v2-modes";

const PlayerV2Stepper = ({
    questions,
    currentStep,
    handleSelectStep,
    isAdmin,
    handleExit,
    selectedQuestion,
    playerMode,
}) => {
    const [styles, setStyles] = useState(browserStyles);
    const {playerStore} = useStores();

    useEffect(() => {
        if (playerStore.isMobileMode) {
            setStyles(mobileStyles);
        } else {
            setStyles(browserStyles);
        }
    }, [playerStore.isMobileMode]);

    return (
        <div className={styles.stepper}>
            {selectedQuestion && (
                <div className={styles.header}>
                    {selectedQuestion?.academyBlockAvatar && (
                        <img
                            src={url + selectedQuestion.academyBlockAvatar.fileDownloadUri.slice(1)}
                            alt="logo"
                        />
                    )}

                    <p className={styles.header__subject}>{selectedQuestion?.academyBlockName}</p>
                    <p className={styles.header__theme}>{selectedQuestion?.academySetName}</p>
                </div>
            )}

            <div className={styles.steps}>
                {questions?.map((question, index) => (
                    <span
                        key={index}
                        className={cn({
                            [styles.stepper__step]: true,
                            [styles.stepper__current]: currentStep === index,
                            [styles.stepper__correct]:
                                questions[index]?.academyLog?.flagCorrectAnswer === 1,
                            [styles.stepper__uncorrect]:
                                questions[index]?.academyLog?.flagCorrectAnswer === 0,
                        })}
                        onClick={() => handleSelectStep(index)}
                    >
                        {/* eslint-disable-next-line no-prototype-builtins */}
                        {question.question?.isFree ||
                        !question.hasOwnProperty("isBuySubscription") ||
                        question.isBuySubscription ||
                        playerMode === PlayerV2Modes.ACADEMY_HOMEWORK ||
                        playerMode === PlayerV2Modes.ARTICLE ||
                        isAdmin ? (
                            <p>{index + 1}</p>
                        ) : (
                            <i className="fas fa-lock mr-0" />
                        )}
                    </span>
                ))}

                {playerMode !== PlayerV2Modes.ARTICLE && (
                    <span
                        className={cn({
                            [styles.stepper__step]: true,
                            [styles.stepper__exit]: true,
                        })}
                        onClick={handleExit}
                    >
                        <i className="fas fa-sign-out-alt mr-0" />
                    </span>
                )}
            </div>
        </div>
    );
};

PlayerV2Stepper.propTypes = {
    currentStep: PropTypes.number,
    handleExit: PropTypes.func,
    handleSelectStep: PropTypes.func,
    isAdmin: PropTypes.bool,
    questions: PropTypes.array,
    selectedQuestion: PropTypes.any,
    playerMode: PropTypes.string,
};

export default PlayerV2Stepper;
