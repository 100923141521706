import React from "react";
import styles from "./styles.module.sass";
import SubHeaderContainer from "../../sub-header/sub-header/sub-header-container";
import {RATING_ROUTES} from "../../../routes/main";
import {useStores} from "../../../hooks/use-stores";
import noLogo from "assets/images/static/no-logo.svg";
import {url} from "../../../config";
import {getFullName} from "../../../core/utils/helpers";
import ratingLogo from "assets/logo/other/rating.svg";
import {ISchool} from "../../../interfaces/ISchool";
import {useLocation} from "react-router-dom";
import {Roles} from "../../../enums/roles";

interface IProps {
    place: number;
    rating: number;
    filter: string;
    school: ISchool;
}

const RatingsHeader = ({place, rating, filter, school}: IProps) => {
    const {userStore} = useStores();
    let location = useLocation();
    return (
        <div className={styles.ratingsHeader}>
            <SubHeaderContainer
                subTabs={[
                    {
                        name: "Рейтинг Академии",
                        path: RATING_ROUTES.ACADEMY,
                        image: require("../../../assets/images/icons/rating-academy.svg"),
                    },
                    {
                        name: "Рейтинг Турниров",
                        path: RATING_ROUTES.TOURNAMENTS,
                        image: require("../../../assets/images/icons/rating-tournament.svg"),
                    },
                    {
                        name: "Рейтинг школ",
                        path: RATING_ROUTES.SCHOOLS,
                        image: require("../../../assets/images/icons/rating-school.svg"),
                    },
                ]}
            />

            <div className={styles.ratingsUser}>
                <img
                    className={styles.ratingsUserLogo}
                    src={
                        userStore.user?.avatarImageBank
                            ? url + userStore.user.avatarImageBank.fileDownloadUri.slice(1)
                            : noLogo
                    }
                    alt="avatar"
                />
                <div className={styles.ratingUserInfo}>
                    {userStore.role === Roles.TEACHER &&
                    location.pathname !== RATING_ROUTES.SCHOOLS ? (
                        <React.Fragment>
                            <p className={styles.ratingsUserInfoFio}>
                                {getFullName(
                                    userStore.user?.userDto?.firstName,
                                    userStore.user?.userDto?.lastName,
                                )}
                            </p>

                            {location.pathname === RATING_ROUTES.ACADEMY ? (
                                <p className={styles.ratingsUserInfoTeacher}>
                                    В рейтинге Академии участвуют только ученики
                                </p>
                            ) : location.pathname === RATING_ROUTES.TOURNAMENTS ? (
                                <p className={styles.ratingsUserInfoTeacher}>
                                    В рейтинге Турниров участвуют только ученики
                                </p>
                            ) : (
                                ""
                            )}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {location.pathname === RATING_ROUTES.SCHOOLS && school ? (
                                <div>
                                    <p className={styles.ratingsSchoolInfo}>
                                        {school?.alternateName === "TEMP"
                                            ? "Школа не указана"
                                            : school?.alternateName || ""}
                                    </p>
                                    <p className={styles.ratingsSchoolInfo}>
                                        {school.city?.altName === school.region?.altName ? (
                                            school?.city?.altName === "TEMP" ? (
                                                "Город не указан"
                                            ) : (
                                                school?.city?.altName || ""
                                            )
                                        ) : (
                                            <React.Fragment>
                                                {school?.region?.altName
                                                    ? school?.region?.altName
                                                    : "Регион не указан"}
                                                ,{" "}
                                                {school?.city?.altName === "TEMP"
                                                    ? "Город не указан"
                                                    : school?.city?.altName || ""}
                                            </React.Fragment>
                                        )}
                                    </p>
                                </div>
                            ) : (
                                <p className={styles.ratingsUserInfoFio}>
                                    {getFullName(
                                        userStore.user?.userDto?.firstName,
                                        userStore.user?.userDto?.lastName,
                                    )}
                                </p>
                            )}

                            {!!place && (
                                <p className={styles.ratingUserInfoPlace}>
                                    {place} место{" "}
                                    {filter === "ALL"
                                        ? "в рейтинге за всё время"
                                        : filter === "MONTH"
                                        ? "в рейтинге за месяц"
                                        : filter === "WEEK"
                                        ? "в рейтинге за неделю"
                                        : ""}{" "}
                                </p>
                            )}

                            <div className={styles.ratingsSelf}>
                                <img src={ratingLogo} alt="rating" />
                                <p>{rating || 0}</p>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RatingsHeader;
