import {GeoAPI} from "entries/geo-selects/core/GeoAPI";
import React from "react";
import PropTypes from "prop-types";
import GeoSelectSchool from "./geo-select-school";

const GeoSelectSchoolContainer = ({cityId, regionId, handleSelect, selectedSchool}) => {
    async function loadOptions(searchValue, loadedOptions, {page}) {
        if (searchValue?.length > 0 && searchValue?.length < 3) {
            return {
                options: [],
            };
        }

        let response = await GeoAPI.getSchoolsElastic(cityId, regionId, page, searchValue);
        let isAdditionalSearch = false;

        if (!response.data.content.length) {
            // города регионы, доп поиск
            if (regionId === 524894 || regionId === 694422 || regionId === 536203) {
                response = await GeoAPI.getSchoolsElastic(null, regionId, page, searchValue);
                isAdditionalSearch = true;
            }
        }

        return {
            options: response.data.content.map((item) => {
                return {
                    value: item.id,
                    label: `${item.alternateName} ${
                        isAdditionalSearch ? `(г. ${item.city?.altName})` : ""
                    }`,
                };
            }),
            hasMore: !response.data.last,
            additional: {
                page: page + 1,
            },
        };
    }

    return (
        <GeoSelectSchool
            loadOptions={loadOptions}
            handleSelect={handleSelect}
            regionId={regionId}
            cityId={cityId}
            selectedSchool={selectedSchool}
        />
    );
};

GeoSelectSchoolContainer.propTypes = {
    cityId: PropTypes.number,
    handleSelect: PropTypes.func,
    regionId: PropTypes.number,
    selectedSchool: PropTypes.object,
};

export default GeoSelectSchoolContainer;
