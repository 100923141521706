import {Achievement} from "entries/achievements/achievement/achievement";
import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";

import styles from "./achievements-list.module.sass";

export const AchievementsList = ({achievements}) => {
    const lastAchievementRef = useRef(null);

    useEffect(() => {
        if (!lastAchievementRef?.current) return;

        lastAchievementRef.current.scrollIntoView({behavior: "smooth"});
    }, [achievements]);
    return (
        <div className={styles.achievements}>
            {achievements && achievements.length > 0 && (
                <div>
                    {achievements.map((achievement, index) => (
                        <React.Fragment key={index}>
                            <Achievement
                                text={achievement.description}
                                imageUrl={achievement.imageUri?.slice(1)}
                                rating={achievement.rating}
                                title={achievement.title}
                            />
                            <div ref={lastAchievementRef}></div>
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    );
};

AchievementsList.propTypes = {
    achievements: PropTypes.array,
};
