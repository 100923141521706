import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./styles.module.scss";

export const Size = (props) => (
    <button
        className={cn({
            [styles.Size]: true,
            [styles.SizeSelected]: props.selected,
        })}
        data-size={props.size}
    >
        {props.children}
    </button>
);
Size.propTypes = {
    selected: PropTypes.bool,
    size: PropTypes.string,
};
