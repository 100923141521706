import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import styles from "./styles.module.sass";
import cn from "classnames";

const TagItem = ({
    tag,
    url,
    isCards = false,
    handleDeleteTag,
    cardClassName,
    customTagColors,
    hideDelete,
    handleClick,
}) => {
    return (
        <div
            className={cn({
                [styles.card]: isCards,
                [styles.tag]: !isCards,
                [cardClassName]: !!cardClassName,
                [styles.pointer]: handleClick,
            })}
            style={{borderColor: customTagColors ? customTagColors[tag.sectionTag.id] : "#45B7D6"}}
            onClick={handleClick ? () => handleClick(tag.id, tag.name || tag.label) : null}
        >
            {!isCards && (
                <div className={styles.tag__icon}>
                    <i className="fas fa-tags" />
                </div>
            )}

            <div className={isCards ? styles.card__body : styles.tag__body}>
                {url ? (
                    <Link to={url.replace(":tagId", tag.id)}>{tag?.name || tag?.label}</Link>
                ) : (
                    <React.Fragment>
                        {tag?.name || tag?.label} {tag.id <= 11 && "класс"}
                    </React.Fragment>
                )}

                {!hideDelete && handleDeleteTag ? (
                    <i
                        className="fas fa-trash-alt red-text mr-0"
                        onClick={() => handleDeleteTag(tag.value || tag.id)}
                    />
                ) : null}
            </div>
        </div>
    );
};

TagItem.propTypes = {
    isCards: PropTypes.bool,
    url: PropTypes.string,
    tag: PropTypes.object,
    cardClassName: PropTypes.string,
    handleClick: PropTypes.func,
};

export default TagItem;
