import React from "react";
import {arrayMove, SortableContainer} from "react-sortable-hoc";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./DraggableItem.module.sass";

const DraggableContainer = SortableContainer((props) => {
    const {className, ...controlProps} = props;
    return (
        <div
            className={cn({
                [styles.List]: true,
                [className]: !!className,
            })}
            {...controlProps}
        />
    );
});

export const DraggableList = (props) => {
    const {items, onSort, ...controlProps} = props;
    const [, setIsMoving] = React.useState(false);

    const onSortStart = () => setIsMoving(true);
    const onSortEnd = ({oldIndex, newIndex}) => {
        setIsMoving(false);

        const nextItems = arrayMove(items, oldIndex, newIndex);

        onSort && onSort(nextItems, items);
    };

    return <DraggableContainer {...controlProps} onSortStart={onSortStart} onSortEnd={onSortEnd} />;
};

DraggableList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    onSort: PropTypes.func.isRequired,
    lockAxis: PropTypes.oneOf(["x", "y"]),
    pressDelay: PropTypes.number,
};

DraggableList.defaultProps = {
    pressDelay: 100,
    lockAxis: "y",
};
