import {TEACHER_WORKSHOP_ROUTES} from "../../teacher-workshop/routes";

export const ENGINES = [
    {
        name: "Классический тест",
        image: "https://i.imgur.com/tGYoxeQ.png",
        constructorURL: TEACHER_WORKSHOP_ROUTES.CONSTRUCTOR.CLASSIC_TEST,
        isDisabled: true,
    },
    {
        name: "Диктант",
        image: "https://i.imgur.com/pQMO2CG.png",
        videoURL:
            "https://drive.google.com/uc?export=download&id=1rqJno_CHbnQphgUJ1jUr8mk_cyI_mZa8",
        constructorURL: TEACHER_WORKSHOP_ROUTES.CONSTRUCTOR.DICTATION,
        isDisabled: false,
        rewardForCreate: 15,
        rewardForResolve: 1,
    },
    {name: "Последовательность текстов", isDisabled: true},
    {name: "Последовательность изображений", isDisabled: true},
    {name: "Пары", isDisabled: true},
    {name: "Пропуск слов", isDisabled: true},
    {name: "Пропуск слов с вариантами ответов", isDisabled: true},
    {name: "Открытый ответ", isDisabled: true},
    {name: "Восстановление предложения", isDisabled: true},
    {name: "Перетаскивание", isDisabled: true},
    {name: "Перетаскивание «Один к одному»", isDisabled: true},
    {name: "Перетаскивание «Один ко многим»", isDisabled: true},
    {name: "Перетаскивание «Многие к одному»", isDisabled: true},
];
