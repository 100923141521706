import React from "react";
import styles from "./styles.module.sass";
import {EditButtons} from "../../../../views/components/edit-buttons";
import cn from "classnames";
import ArticleTitle from "../../article-title/article-title";
import {EPublicationCustomActionTypes} from "../../../../enums/EPublicationCustomActionTypes";

interface IProps {
    block: any;
    isViewMode: boolean;
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
    handleCustomAction: (type: EPublicationCustomActionTypes, payload: any) => void;
}

const ArticleTextBlock = ({
    block,
    handleDelete,
    handleEdit,
    isViewMode,
    handleCustomAction,
}: IProps) => {
    return (
        <div
            className={cn({
                [styles.textBlock]: true,
                [styles.editMode]: !isViewMode,
            })}
        >
            <ArticleTitle
                isViewMode={isViewMode}
                handleCustomAction={handleCustomAction}
                block={block}
                title={block.description}
            />

            <div className={styles.text} dangerouslySetInnerHTML={{__html: block.blockText}}></div>
            {!isViewMode && (
                <EditButtons
                    editAction={() => handleEdit(block.id)}
                    deleteAction={() => handleDelete(block.id)}
                    itemId={block.id}
                    isSolidBorder
                />
            )}
        </div>
    );
};

export default ArticleTextBlock;
