import cn from "classnames";

import {url} from "config";

import {ImageBankAPI} from "core/api/image-bank";
import {IMAGE_FROM_BANK} from "core/types/content-types";
import {ADMIN_PANEL_ROUTES} from "entries/admin-panel/core/routes";
import {MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

import {Image} from "views/components/images-library-image";
import {ImageEdit} from "views/containers/images-library/image-edit";

import styles from "./images-library.module.sass";

export const ImagesLibrary = (props) => {
    const {t} = useTranslation("images_manager");
    const [editableImage, setEditableImage] = React.useState(undefined);
    const modalIsShown = !!editableImage;
    const [disabled, setDisabled] = React.useState(false);

    const currentImage =
        props.images && editableImage
            ? props.images.find((item) => item.id === editableImage.id)
            : null;

    React.useEffect(() => {
        if (
            currentImage &&
            editableImage &&
            currentImage.fileDownloadUri !== editableImage.fileDownloadUri
        ) {
            setEditableImage(currentImage);
        }
    }, [currentImage]);

    const closeImageEditModal = () => {
        setEditableImage(null);
        props.onClose && props.onClose();
        setDisabled(false);
    };

    const handleDelete = (id) => {
        setDisabled(true);

        return ImageBankAPI.deleteImage(id)
            .then(() => {
                setEditableImage(null);
                toast(t("Изображение удалено"));
                props.onRemove && props.onRemove(id);
            })
            .finally(() => {
                setDisabled(false);
            });
    };

    const handleRename = (id, name) => {
        setDisabled(true);

        return ImageBankAPI.updateImageName(id, name)
            .then(() => {
                props.onRename && props.onRename(id, name);
                toast(t("Изображение переименовано"));
            })
            .finally(() => setDisabled(false));
    };

    const handleUpdate = (id, file) => {
        setDisabled(true);

        return ImageBankAPI.updateImage(id, file)
            .then(() => {
                toast(t("Изображение обновлено"));
                props.onUpdate && props.onUpdate(id);
            })
            .finally(() => setDisabled(false));
    };

    return (
        <div
            className={cn({
                [styles.imagesLibrary]: true,
                [props.className]: !!props.className,
            })}
        >
            {props.showContent ? (
                <>
                    <div
                        className={cn({
                            [styles.images]: true,
                            [styles.imagesFullWidth]: props.directTagEnum,
                        })}
                    >
                        {props.canCreate && (
                            <div className={styles.addLinkWrapper}>
                                <Link
                                    className={styles.addLink}
                                    to={ADMIN_PANEL_ROUTES.IMAGES.UPLOAD}
                                >
                                    +
                                </Link>
                            </div>
                        )}
                        {props.images.map((item) => (
                            <Image
                                withoutNameTip={props.withoutNameTip}
                                key={item.id}
                                {...item}
                                src={
                                    item.fileDownloadUri
                                        ? `${url}${item.fileDownloadUri.slice(1)}`
                                        : null
                                }
                                onClick={
                                    props.onSelect
                                        ? () => (
                                              (item.type = IMAGE_FROM_BANK), props.onSelect(item)
                                          )
                                        : () => setEditableImage(item)
                                }
                            />
                        ))}
                    </div>
                </>
            ) : (
                <div className={styles.textHelper}>
                    <div>
                        <i className="far fa-images" />
                    </div>
                    <p>
                        В библиотеке более 5000 изображений.
                        <br />
                        Выберите раздел.
                    </p>
                </div>
            )}

            <MDBModal
                isOpen={modalIsShown}
                toggle={closeImageEditModal}
                overflowScroll={false}
                inline={false}
                noClickableBodyWithoutBackdrop={false}
            >
                <MDBModalHeader toggle={closeImageEditModal}>
                    {t("Редактирование изображения")}
                </MDBModalHeader>
                <MDBModalBody>
                    <ImageEdit
                        image={editableImage || {}}
                        onRename={handleRename}
                        onDelete={handleDelete}
                        onUpdate={handleUpdate}
                        disabled={disabled}
                    />
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="primary" onClick={closeImageEditModal}>
                        {t("Закрыть")}
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </div>
    );
};

ImagesLibrary.propTypes = {
    directTagEnum: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    showContent: PropTypes.bool.isRequired,
    canCreate: PropTypes.bool,
    canRemove: PropTypes.bool,
    canRename: PropTypes.bool,
    canUpdate: PropTypes.bool,
    className: PropTypes.string,
    testType: PropTypes.string,
    onClose: PropTypes.func,
    onRemove: PropTypes.func,
    onRename: PropTypes.func,
    onSelect: PropTypes.func,
    onUpdate: PropTypes.func,
};
