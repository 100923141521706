import PropTypes from "prop-types";
import React from "react";
import {EditButtons} from "views/components/edit-buttons";
import styles from "entries/constructors/classic-test/views/components/formula-question/formula-question.module.sass";

export const FormulaQuestion = (props) => {
    const {formula, isEditMode, deleteFormula} = props;
    return (
        <div className={styles.container}>
            {formula && <img src={formula} alt="Formula" />}
            {formula && isEditMode && <EditButtons deleteAction={deleteFormula} />}
        </div>
    );
};

FormulaQuestion.propTypes = {
    deleteFormula: PropTypes.func,
    formula: PropTypes.any,
    isEditMode: PropTypes.bool,
};
