import {useEffect} from "react";
import {auth} from "../../core/utils/auth";
import {getRole} from "../../core/utils/helpers";

import {runInAction} from "mobx";
import {useStores} from "../../hooks/use-stores";

const ServiceAuth = () => {
    const {keycloak} = auth;
    const {appStore, userStore} = useStores();

    const initApp = () => {
        auth.init().then(async () => {
            if (keycloak.authenticated) {
                await userStore.getUser();
                runInAction(() => {
                    // @ts-ignore
                    userStore.role = getRole(keycloak, true);
                });

                runInAction(() => {
                    appStore.isAuthorized = true;
                });
            }

            runInAction(() => {
                appStore.isAppInitialized = true;
            });
        });
    };

    useEffect(() => {
        initApp();
    }, []);

    return null;
};

export default ServiceAuth;
