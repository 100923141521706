import cn from "classnames";
import {url} from "config";
import {DEFAULT_FONT_SIZE} from "config/project";
import {convertTextWithSymbols} from "core/utils/converter";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {Question} from "views/components/question";
import styles from "./styles.module.sass";

const BasketsPlayer = ({answers, baskets, onDragEnd, properties, question}) => {
    // данные для вопроса
    const [questionImage] = useState({});
    const [questionText, setQuestionText] = useState("");
    const [questionNarrator, setQuestionNarrator] = useState({});

    // параметры
    const [questionSizeText, setQuestionSizeText] = useState(DEFAULT_FONT_SIZE);
    const [answerSizeText, setAnswerSizeText] = useState(DEFAULT_FONT_SIZE);
    const [background, setBackground] = useState("");

    const [gameBackground, setGameBackground] = useState("");

    useEffect(() => {
        // заполянем вопрос
        if (question) {
            // текст вопроса
            if (question.questionText) {
                setQuestionText(question.questionText);
            }

            setGameBackground(question.imageQuestion);
        }

        if (properties?.sizeTitle) {
            setQuestionSizeText(properties.sizeTitle);
        }

        // размер шрифта ответа
        if (properties?.sizeText) {
            setAnswerSizeText(properties.sizeText);
        }
    }, [question, properties]);

    useEffect(() => {
        // применяем параметры
        if (properties) {
            // персонаж вопроса
            if (properties.narrator && properties.narrator.image) {
                let narratorUrl = `${url}${properties.narrator.image.fileDownloadUri.slice(1)}`;
                setQuestionNarrator({url: narratorUrl});
            }

            // отображение фона
            if (properties.backgroundImage) {
                setBackground(properties.backgroundImage.fileDownloadUri.slice(1));
            } else {
                setBackground("data/image-bank/ae86cc0f-5bc8-457f-98bb-454a0ea993a6.svg");
            }
        }
    }, [properties]);

    const backgroundStyle = {
        backgroundImage: gameBackground && `url(${url + gameBackground.fileDownloadUri.slice(1)})`,
        backgroundRepeat: "no-repeat",
    };

    return (
        <div className={styles.baskets}>
            <TestContent background={background} withEditBorder={false} isFullWidth>
                <TestConstructor size="full">
                    <div className="animate__animated animate__fadeIn" style={{marginBottom: 15}}>
                        <div className={styles.questionWrapper}>
                            <Question
                                testHasNarrator
                                isEditMode={false}
                                text={questionText}
                                image={questionImage}
                                narrator={questionNarrator}
                                textTitleSize={questionSizeText}
                            />
                        </div>
                    </div>

                    {baskets?.length > 0 && (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <div className={styles.player} style={backgroundStyle}>
                                {baskets?.map((basket) => {
                                    let basketStyles = {
                                        left: basket.x,
                                        top: basket.y,
                                        width: basket.width,
                                        height: basket.height,
                                    };

                                    return (
                                        <Droppable
                                            droppableId={basket.id.toString()}
                                            key={basket.id}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...basketStyles,
                                                        backgroundColor:
                                                            snapshot?.isDraggingOver && "#add8e6",
                                                    }}
                                                    key={basket.id}
                                                    className={styles.player__skipWrapper}
                                                >
                                                    {basket?.answers?.map((basketAnswer) => {
                                                        return (
                                                            <Draggable
                                                                key={basketAnswer.id}
                                                                draggableId={basketAnswer.id.toString()}
                                                                index={basketAnswer.id}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        className={cn({
                                                                            [styles.itemInsert]: true,
                                                                            [styles.item_isDragging]:
                                                                                snapshot?.isDragging,
                                                                        })}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                fontSize:
                                                                                    answerSizeText ||
                                                                                    25,
                                                                                textAlign: "center",
                                                                            }}
                                                                        >
                                                                            {convertTextWithSymbols(
                                                                                basketAnswer.text,
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </Droppable>
                                    );
                                })}
                            </div>

                            <Droppable droppableId={"answers"} direction="horizontal">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={styles.skip}
                                        style={{
                                            backgroundColor: snapshot?.isDraggingOver && "#add8e6",
                                        }}
                                    >
                                        {answers.map((answer) => {
                                            return (
                                                <Draggable
                                                    key={answer.id}
                                                    draggableId={answer.id.toString()}
                                                    index={answer.id}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={cn({
                                                                [styles.item]: true,
                                                                [styles.item_isAnswer]: true,
                                                                [styles.item_isDragging]:
                                                                    snapshot?.isDragging,
                                                            })}
                                                        >
                                                            <p
                                                                style={{
                                                                    fontSize: answerSizeText || 25,
                                                                }}
                                                            >
                                                                {convertTextWithSymbols(
                                                                    answer.text,
                                                                )}
                                                            </p>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                </TestConstructor>
            </TestContent>
        </div>
    );
};

BasketsPlayer.propTypes = {
    answers: PropTypes.array,
    baskets: PropTypes.array,
    gameBackground: PropTypes.string,
    properties: PropTypes.object,
    question: PropTypes.object,
    onDragEnd: PropTypes.func,
};

export default BasketsPlayer;
