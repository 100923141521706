import React, {useState} from "react";
import {AdminPanelUserAPI} from "../admin-panel-users-api";
import {toast} from "react-toastify";
import {IUser} from "../../../../interfaces/IUser";
import AdminPanelFormCreator, {
    EFormCreatorTypes,
} from "../../admin-panel-form-creator/admin-panel-form-creator";
import GenericTable from "../../../generic-table/generic-table";

const AdminPanelUserByName = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState<IUser | null>(null);

    const handleGetUserByEmail = (formData: any) => {
        let {firstName, lastName} = formData;
        console.log(firstName, lastName);

        setIsLoading(true);
        setResult(null);
        AdminPanelUserAPI.getUserByName(firstName, lastName)
            .then((response) => {
                setResult(response.data.content);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <React.Fragment>
            <AdminPanelFormCreator
                handleClick={handleGetUserByEmail}
                bannerText="Поиск пользователя"
                formConstructor={[
                    {
                        name: "firstName",
                        labelText: "Введите имя",
                        type: EFormCreatorTypes.TEXT,
                    },
                    {
                        name: "lastName",
                        labelText: "Введите фамилию",
                        type: EFormCreatorTypes.TEXT,
                    },
                    {
                        name: "Найти",
                        type: EFormCreatorTypes.BUTTON,
                        color: "success",
                    },
                ]}
                isLoading={isLoading}
            />

            {result && (
                <GenericTable
                    headers={["ID", "Имя", "Фамилия", "UserName"]}
                    outputKeys={["id", "firstName", "lastName", "userName"]}
                    data={result}
                />
            )}
        </React.Fragment>
    );
};

export default AdminPanelUserByName;
