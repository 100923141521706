import React, {useEffect, useState} from "react";
import {MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBModalHeader, MDBRow} from "mdbreact";
import styles from "./styles.module.sass";
// @ts-ignore
import Select from "react-select";
import {IPublicationBlockDto} from "../../../../interfaces/IPublicationBlockDto";

export enum EStylesImageBlock {}

export enum EStylesDescription {
    "CENTER" = 1,
}

export interface IPublicationBlockStylesSelect {
    value: EStylesImageBlock;
    label: string;
}

export interface IPublicationDescriptionStylesSelect {
    value: EStylesDescription;
    label: string;
}

interface IProps {
    isOpen: boolean;
    toggle: () => void;
    handleSave: (payload: IPublicationImagePayload) => void;
    blockForEdit: IPublicationBlockDto | null;
}

export interface IPublicationImagePayload {
    blockStyle: number;
    descriptionStyle: number;
}

export const BLOCK_STYLE_OPTIONS = [
    {value: 1, label: "Во всю ширину"},
    {value: 2, label: "В реальном размере - по центру"},
    {value: 3, label: "В реальном размере - по левому краю"},
];

export const DESCRIPTION_STYLE_OPTIONS = [
    {value: 1, label: "Под фото - обычный - по центру"},
    {value: 2, label: "Под фото - жирный - по центру"},
    {value: 3, label: "Перед фото - обычный - по центру"},
    {value: 4, label: "Перед фото - жирный - по центру"},
];

const NewArticleImageModal = ({isOpen, toggle, handleSave, blockForEdit}: IProps) => {
    const [payload, setPayload] = useState<IPublicationImagePayload>({
        blockStyle: BLOCK_STYLE_OPTIONS[1].value,
        descriptionStyle: DESCRIPTION_STYLE_OPTIONS[1].value,
    });

    useEffect(() => {
        if (blockForEdit) {
            if (typeof blockForEdit.blockSettings === "string") {
                let settings: IPublicationImagePayload = JSON.parse(
                    atob(blockForEdit.blockSettings),
                );

                setPayload({...settings});
            }
        }
    }, [blockForEdit]);

    return (
        <MDBModal
            isOpen={isOpen}
            toggle={toggle}
            size="lg"
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll
        >
            <MDBModalHeader toggle={toggle} className="modal-header">
                <p>Блок Изображение</p>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow className={styles.row}>
                    <MDBCol md="3">
                        <p>Стиль блока</p>
                    </MDBCol>

                    <MDBCol md="9">
                        <Select
                            options={BLOCK_STYLE_OPTIONS}
                            value={BLOCK_STYLE_OPTIONS[payload.blockStyle - 1]}
                            onChange={(v: IPublicationBlockStylesSelect) =>
                                setPayload({...payload, blockStyle: v.value})
                            }
                            placeholder="Выберите стиль"
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow className={styles.row}>
                    <MDBCol md="3">
                        <p>Стиль подписи</p>
                    </MDBCol>

                    <MDBCol md="9">
                        <Select
                            options={DESCRIPTION_STYLE_OPTIONS}
                            value={DESCRIPTION_STYLE_OPTIONS[payload.descriptionStyle - 1]}
                            onChange={(v: IPublicationDescriptionStylesSelect) =>
                                setPayload({...payload, descriptionStyle: v.value})
                            }
                            placeholder="Выберите стиль"
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow className={styles.row}>
                    <MDBCol md="3" className="mt-3">
                        <MDBBtn color="success" onClick={() => handleSave(payload)}>
                            Сохранить
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>
        </MDBModal>
    );
};

export default NewArticleImageModal;
