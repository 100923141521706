import {MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";
import {useHistory} from "react-router-dom";
import {PAGES_ROUTES} from "views/routes/routes";
import buySubRaven from "../../../../../assets/characters/raven/raven-buy-sub.svg";
import styles from "./styles.module.sass";

const PlayerV2BuySubscription = ({handleClose}) => {
    let history = useHistory();
    return (
        <div className={styles.container}>
            <p className={styles.title}>
                Это задание доступно <br /> только по подписке
            </p>
            <img className={styles.image} src={buySubRaven} alt="buy-subscription-raven" />
            <div className={styles.buttons}>
                <MDBBtn
                    size="lg"
                    color="success"
                    onClick={() => history.push(PAGES_ROUTES.PREMIUM.ROOT)}
                >
                    Оформить подписку за 169 руб. в месяц
                </MDBBtn>
                <p onClick={handleClose}>Закрыть</p>
            </div>
        </div>
    );
};

PlayerV2BuySubscription.propTypes = {
    handleClose: PropTypes.func,
};

export default PlayerV2BuySubscription;
