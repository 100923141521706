import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import cn from "classnames";
import {Button, Form, Input, Select} from "antd";

// @ts-ignore
import CKEditor from "react-ckeditor-component";
import {EAuditory} from "../../../enums/EAuditory";
import {NewsAPI} from "../../news/news-api";
import {toast} from "react-toastify";
import {INews} from "../../../interfaces/INews";
import TagSelect from "../../tag-select/tag-select";

interface IProps {
    toggle: () => void;
    news?: INews;
    getNews: () => void;
}

export interface INewsDto {
    id?: number;
    newsTitle: string;
    auditory: EAuditory;
    importance: string;
    newsText: string;
}

const CreateNewsModal = ({toggle, news, getNews}: IProps) => {
    const [form] = Form.useForm();
    const [text, setText] = useState("");
    const [newsId, setNewsId] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (news) {
            form.setFieldsValue(news);
            setNewsId(news.id);
            setText(news.newsText);
        }
    }, [news]);

    const saveNews = (form: INewsDto) => {
        form.newsText = text;

        let API;
        if (newsId) {
            API = NewsAPI.updateNews(newsId, form);
        } else {
            API = NewsAPI.createNews(form);
        }

        API.then(() => {
            toast.success("Успешно");
            getNews();
            toggle();
        }).catch(() => toast.error("Ошибка"));
    };

    return (
        <div
            className={cn({
                [styles.containerWrapper]: true,
            })}
        >
            <Form
                name="basic"
                labelCol={{span: 4}}
                initialValues={{remember: true}}
                onFinish={saveNews}
                onFinishFailed={(err) => console.log("err", err)}
                autoComplete="off"
                form={form}
                size={"middle"}
            >
                <Form.Item
                    label="Заголовок"
                    name="title"
                    rules={[{required: true, message: "Введите заголовок"}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="auditory"
                    label="Аудитория"
                    rules={[{required: true, message: "Выберите аудиторию"}]}
                >
                    <Select
                        onChange={(v) => form.setFieldValue("auditory", v)}
                        options={[
                            {
                                value: "ALL",
                                label: "Все",
                            },
                            {
                                value: "TEACHER",
                                label: "Учителя",
                            },
                            {
                                value: "STUDENT",
                                label: "Ученики",
                            },
                            {
                                value: "ADMIN",
                                label: "Администрация",
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item label="Тег" name="newsTag">
                    <TagSelect
                        handleSelect={(v) => form.setFieldValue("newsTag", {id: v})}
                        sectionTagId={81}
                        selectedTag={form.getFieldValue("newsTag")?.id}
                    />
                </Form.Item>

                <Form.Item
                    label="Важность"
                    name="importance"
                    rules={[{required: true, message: "Выберите важность"}]}
                >
                    <Select
                        onChange={(v) => form.setFieldValue("importance", v)}
                        options={[
                            {
                                value: 1,
                                label: "Обычное",
                            },
                            {
                                value: 2,
                                label: "Важное",
                            },
                            {
                                value: 3,
                                label: "Срочное",
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item label="Текст новости">
                    <CKEditor
                        content={text}
                        events={{
                            change: (v: any) => setText(v.editor.getData()),
                        }}
                    />
                </Form.Item>

                <Form.Item className="d-flex justify-content-center">
                    <Button type="primary" htmlType="submit" className="mr-2">
                        Сохранить
                    </Button>

                    <Button type="default" onClick={toggle}>
                        Закрыть
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default CreateNewsModal;
