import {url} from "config";
import PropTypes from "prop-types";
import React from "react";

import {EditButtons} from "views/components/edit-buttons";

import styles from "entries/constructors/classic-test/views/components/image-question/image-question.module.sass";

export const ImageForQuestion = (props) => {
    const {image, editImage, deleteImage, isEditMode} = props;
    return (
        <div>
            {image && image.id && (
                <div className={styles.container}>
                    <img
                        className={styles.questionImage}
                        src={image.url ? `${url}${image.url}` : null}
                        alt="Image"
                    />
                    {isEditMode && (
                        <EditButtons
                            editAction={editImage}
                            deleteAction={deleteImage}
                            withoutRightBorder
                        />
                    )}
                </div>
            )}
        </div>
    );
};

ImageForQuestion.propTypes = {
    deleteImage: PropTypes.func,
    editImage: PropTypes.func,
    image: PropTypes.any,
    isEditMode: PropTypes.bool,
};
