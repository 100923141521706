import React, {useEffect, useState} from "react";

// @ts-ignore
import Select from "react-select";
import {SubscriptionsAPI} from "../../core/api/subcriptions";

interface IProps {
    onSelect: (id: number) => void;
}

const SelectSubscription = ({onSelect}: IProps) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [, setIsLoading] = useState(false);

    const getSubscriptions = () => {
        setIsLoading(true);
        SubscriptionsAPI.getSubscriptions()
            .then((response: any) => {
                let subs = response.data.content?.map((sub: any) => ({
                    value: sub.id,
                    label: sub.name,
                }));
                setSubscriptions(subs);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getSubscriptions();
    }, []);

    return (
        <Select
            style={{width: 250}}
            options={subscriptions}
            onChange={(e: any) => onSelect(e.value)}
            placeholder="Выбор..."
        />
    );
};

export default SelectSubscription;
