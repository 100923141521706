export const TRAINING_REF_TYPES = {
    MESSAGE: "MESSAGE",
    QUESTION: "QUESTION_REF_TYPE",
    PARAMETERS: "PARAMETERS_REF_TYPE",
    ADD_ANSWER: "ADD_ANSWER_REF_TYPE",
    BACKGROUND: "BACKGROUND_REF_TYPE",
};

export const SECOND_TRIGGER = {
    FONT_SIZE_QUESTION: "FONT_SIZE_QUESTION",
    NARRATOR: "NARRATOR",
};

export const TRAINING_DIRECTIONS = {
    TOP: "TOP",
    RIGHT: "RIGHT",
    LEFT: "LEFT",
    BOTTOM: "BOTTOM",
};
