import React, {useState} from "react";
import cn from "classnames";
import styles from "./styles.module.sass";
import {MDBBtn} from "mdbreact";
import {ConversationsAPI} from "../conversations/api/conversations-api";
import {toast} from "react-toastify";
import {ITag} from "../../interfaces/ITag";

interface IProps {
    toggle: () => void;
    question: any;
    isTeacherQuestion: boolean;
}

const TaskFeedbackModal = ({toggle, question, isTeacherQuestion}: IProps) => {
    const [message, setMessage] = useState("");

    const sendFeedback = () => {
        if (!question) return;

        let messageForSend;

        if (isTeacherQuestion) {
            messageForSend = `Сообщение об ошибке к учительскому заданию: ${question.id}\nСообщение: ${message}`;
        } else {
            messageForSend = `Сообщение об ошибке к заданию ${question?.question.id}\nБлок: ${
                question.academyBlockName
            }\nКомплект: ${question.academySetName}\nПозиция: ${
                question.position
            }\nТеги: ${question.question?.tags.map(
                (tag: ITag) => tag.name + "",
            )}\nСообщение: ${message}`;
        }

        ConversationsAPI.sendMessage(5893, {reply: messageForSend})
            .then(() => {
                toast.success("Успешно!");
            })
            .catch(() => toast.error("Произошла ошибка"))
            .finally(() => toggle());
    };

    return (
        <div
            className={cn({
                [styles.containerWrapper]: true,
            })}
        >
            <h3 className={styles.title}>Сообщить об ошибке в задании</h3>
            <div className={styles.content}>
                <div className={styles.description}>
                    <p>
                        Подробно опишите, какая ошибка в задании. Пожалуйста, не забывайте, что в
                        заданиях может быть несколько верных ответов.
                    </p>

                    <div className="mt-3">
                        <textarea
                            className="w-100 form-control"
                            rows={7}
                            value={message}
                            placeholder=""
                            onChange={(event) => setMessage(event.target.value)}
                        />

                        <p
                            className="mt-2 mb-2"
                            style={{color: message?.length > 500 ? "red" : "#b9b9b9"}}
                        >
                            Не более 500 знаков. Сейчас — {message?.length}
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.footer}>
                <MDBBtn color="success" onClick={sendFeedback} disabled={message?.length >= 500}>
                    Отправить
                </MDBBtn>

                <MDBBtn color="grey" onClick={toggle}>
                    Закрыть
                </MDBBtn>
            </div>
        </div>
    );
};

export default TaskFeedbackModal;
