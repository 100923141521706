import {
    BASKETS_TEST,
    CLASSIC_TEST,
    CLIPPING_SECONDARY_TEST,
    CLIPPING_TEST,
    DICTATION_TEST,
    OPEN_ANSWER_TEST,
    PAIRS_TEST,
    SEQUENCES,
    SEQUENCES_IMAGE_TEST,
    SEQUENCES_TEXT_TEST,
    WORD_MANY_SKIP_TEST,
    WORD_SEQUENCE_SKIP_TEST,
    WORD_SKIP_TEST,
} from "config/constructor-names";
import {ADMIN_ROLE, TEACHER_ROLE} from "config/roles";
import {QuestionAPI} from "core/api/question";
import {auth} from "core/utils/auth";
import {getRole} from "core/utils/helpers";
import {AcademyAPI} from "entries/academy/core/api/AcademyAPI";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import {PlayerAPI} from "entries/player-v2/core/PlayerAPI";
import {getPlayer} from "entries/player-v2/utils/player";
import PlayerV2SetResult from "entries/player-v2/views/player-v2-notifications/player-v2-set-result/player-v2-set-result";
import PlayerV2SetWithoutQuestions from "entries/player-v2/views/player-v2-notifications/player-v2-set-without-questions/player-v2-set-without-questions";
import PlayerV2SolvingResult from "entries/player-v2/views/player-v2-notifications/player-v2-solving-result/player-v2-solving-result";
import PlayerV2SolvingResultWithoutWS from "entries/player-v2/views/player-v2-notifications/player-v2-solving-result/player-v2-solving-result-without-ws";
import PlayerV2TournamentResult from "entries/player-v2/views/player-v2-notifications/player-v2-tournament-result/player-v2-tournament-result";
import {
    academyModeRights,
    articleModeRights,
    oldPreviewModeRights,
    PLAYER_RIGHTS,
    previewModeRights,
    teacherModeRights,
    tournamentModeRights,
} from "entries/player/views/player/utils/player-config";
import {TeacherAPI} from "entries/teacher/core/api/TeacherApi";
import {useStores} from "hooks/use-stores";
import useNotificationQueue from "hooks/useNotificationQueue";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {ACADEMY_ROUTES, STUDENT_ROUTES, TOURNAMENT_ROUTES} from "routes/main";
import {hasAccess} from "utils/private-route";
import PlayerV2BuySubscription from "../views/player-v2-notifications/player-v2-buy-subscription/player-v2-buy-subscription";
import PlayerV2NotificationLoading from "../views/player-v2-notifications/player-v2-notification-loading/player-v2-notification-loading";

const usePlayerV2 = (id, playerMode, isSingleTask, asid, questionsForView) => {
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [currentStep, setCurrentStep] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [answerIsLoading, setAnswerIsLoading] = useState(false);
    const [resultIsLoading, setResultIsLoading] = useState(false);
    const [metaTags, setMetaTags] = useState(null);

    // поле игры
    const [content, setContent] = useState(null);
    const [notificationContent, setNotificationContent] = useState(null);

    const {activeNotification, addNotificationInQueue, removeNotificationFromQueue} =
        useNotificationQueue();

    const [userRights, setUserRights] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isCreator, setIsCreator] = useState(false);
    const [withModeration, setWithModeration] = useState(false);

    const {userStore} = useStores();

    const [reset, setReset] = useState(0);
    const {keycloak} = auth;

    let history = useHistory();

    useEffect(() => {
        setIsAdmin(hasAccess(keycloak, [ADMIN_ROLE]));
        updateUserRights();

        if (
            hasAccess(keycloak, [TEACHER_ROLE]) &&
            playerMode !== PlayerV2Modes.TOURNAMENT &&
            playerMode !== PlayerV2Modes.ACADEMY
        ) {
            setWithModeration(true);
        }

        if (playerMode === PlayerV2Modes.TOURNAMENT) {
            getTournamentQuestion();
        } else if (
            playerMode === PlayerV2Modes.ARTICLE ||
            (questionsForView && playerMode === PlayerV2Modes.PREVIEW)
        ) {
            setCurrentStep(0);
            setSelectedQuestion(questionsForView[0]);
            setQuestions(questionsForView);
        } else {
            let API = getQuestionsAPI();
            getQuestions(API);
        }
    }, []);

    useEffect(() => {
        setNotificationContent(activeNotification);
    }, [activeNotification]);

    useEffect(() => {
        let componentUniqueKey = "PV2NL";
        if (answerIsLoading) {
            addNotificationInQueue(10, <PlayerV2NotificationLoading key={componentUniqueKey} />);
        } else {
            removeNotificationFromQueue(null, componentUniqueKey);
        }
    }, [answerIsLoading]);

    useEffect(() => {
        if (window.ym) {
            window.ym(86877424, "hit", `/academy/play/set/${id}`);
        }
    }, []);

    useEffect(() => {
        if (!selectedQuestion) {
            setMetaTags(null);
            return;
        }

        if (window.ym && selectedQuestion) {
            window.ym(86877424, "hit", `/academy/play/task/${selectedQuestion.id}`);
        }

        switch (playerMode) {
            case PlayerV2Modes.ACADEMY:
                setMetaTags({
                    title: `${selectedQuestion.academyBlockName} – ${
                        selectedQuestion.academySetName
                    } - ${currentStep + 1} задание`,
                    description: `${selectedQuestion.academyBlockName} решаем задания для начальный классов по школьной теме ${selectedQuestion.academySetName}`,
                    keywords: `${selectedQuestion.academyBlockName} ${selectedQuestion.academySetName} онлайн тренажёр задание задача учеба обучение решать тренироваться школа школьный начальный`,
                });
                break;
            case PlayerV2Modes.HOMEWORK:
                setMetaTags({
                    title: `Задание от учителя №${currentStep + 1}`,
                    description: `Интерактивные задания для дистанционных и дополнительных школьных занятий от вашего учителя. Тренажеры, домашние и контрольные работы.`,
                    keywords: `решать бесплатно задание задача урок тема онлайн дистанционно учитель сайт домашняя контрольная работа школьный класс начальный 1 2 3 4 учеба`,
                });
                break;
        }
    }, [playerMode, selectedQuestion]);

    useEffect(() => {
        if (!selectedQuestion) {
            return;
        }

        let questionId;
        let type = getConstructorType();

        if (isSingleTask) {
            questionId = id;
        } else {
            questionId = selectedQuestion?.question?.id;
        }

        if (playerMode === PlayerV2Modes.ACADEMY) {
            if (
                !(
                    isAdmin ||
                    selectedQuestion.isBuySubscription ||
                    selectedQuestion?.question?.isFree
                )
            ) {
                showSubscribeModal();
                return;
            }
        }

        let player = getPlayer(
            type,
            setAnswer,
            questionId,
            reset,
            playerMode,
            () => void 0,
            answerIsLoading,
            setAnswerIsLoading,
        );
        setContent(player);
    }, [selectedQuestion, reset]);

    useEffect(() => {
        if (!userStore?.user?.userDto || !selectedQuestion) {
            return;
        }

        if (
            userStore?.user?.userDto?.id === selectedQuestion.user?.id ||
            userStore?.user?.userDto?.id === selectedQuestion.question?.user?.id
        ) {
            setIsCreator(true);
        } else {
            setIsCreator(false);
        }
    }, [userStore?.user?.userDto, selectedQuestion]);

    useEffect(() => {
        if (!questions || !questions?.length) {
            return;
        }

        checkOnSetResolved(questions);
    }, [selectedQuestion, questions]);

    const showSubscribeModal = () => {
        let uniqueComponentKey = "PV2BS";
        addNotificationInQueue(
            9,
            <PlayerV2BuySubscription
                handleClose={() => {
                    removeNotificationFromQueue(null, uniqueComponentKey);
                    setContent(null);
                }}
            />,
        );
    };

    const showNotificationSetWithoutQuestions = () => {
        let uniqueComponentKey = "PV2SWQ";
        addNotificationInQueue(
            4,
            <PlayerV2SetWithoutQuestions
                key={uniqueComponentKey}
                handleExitClick={() => {
                    removeNotificationFromQueue(null, uniqueComponentKey);
                    history.goBack();
                }}
            />,
        );
    };

    const handleSelectStep = (step) => {
        if (currentStep === step) {
            return;
        }

        updateQuestions();
        setAnswer(null);
        setCurrentStep(step);
        getQuestionData(step);
    };

    const updateUserRights = () => {
        switch (playerMode) {
            case PlayerV2Modes.ACADEMY:
                setUserRights(academyModeRights);
                break;
            case PlayerV2Modes.TEACHER:
                setUserRights(teacherModeRights);
                break;
            case PlayerV2Modes.PREVIEW:
                setUserRights(previewModeRights);
                break;
            case PlayerV2Modes.TOURNAMENT:
                setUserRights(tournamentModeRights);
                break;
            case PlayerV2Modes.HOMEWORK:
            case PlayerV2Modes.ACADEMY_HOMEWORK:
                setUserRights(academyModeRights);
                break;
            case PlayerV2Modes.ACADEMY_HOMEWORK:
                setUserRights(academyModeRights);
                break;
            case PlayerV2Modes.ARTICLE:
                setUserRights(articleModeRights);
                break;
            case PlayerV2Modes.OLD_PREVIEW:
                setUserRights(oldPreviewModeRights);
                break;
            default:
                setUserRights(academyModeRights);
        }
    };

    const handleCheckAnswer = () => {
        if (userRights.includes(PLAYER_RIGHTS.HAVE_INFO_BLOCK)) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }

        let prefix = getApiPrefix();
        let convertedAnswer = getConvertedAnswer();
        let questionId;
        let entityId;

        if (playerMode === PlayerV2Modes.TOURNAMENT) {
            entityId = id;
        } else if (
            playerMode === PlayerV2Modes.HOMEWORK ||
            playerMode === PlayerV2Modes.ACADEMY_HOMEWORK
        ) {
            entityId = id;
            questionId = selectedQuestion?.question?.id;
        } else {
            questionId = selectedQuestion?.question?.id;
        }

        if (isSingleTask) {
            questionId = selectedQuestion?.id;
        }

        let role = getRole(keycloak);

        setResultIsLoading(true);
        PlayerAPI.checkAnswer(prefix, questionId, convertedAnswer, playerMode, role, entityId)
            .then((response) => {
                let result = response.data;
                let isDictation = getConstructorType() === DICTATION_TEST;
                if (isDictation) {
                    result = response.data >= 0.7;
                }

                showSolvingResult(result, questionId);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setResultIsLoading(false));
    };

    const handleContinue = () => {
        if (playerMode === PlayerV2Modes.TOURNAMENT) {
            setContent(null);
            getTournamentQuestion();
        } else {
            if (isSingleTask) {
                setReset((prev) => prev + 1);
            } else {
                handleToNextQuestion();
                updateQuestions();
            }
        }
    };

    const handleRetry = () => {
        setReset((prev) => prev + 1);

        if (playerMode !== PlayerV2Modes.TOURNAMENT && isSingleTask) {
            return;
        }

        updateQuestions();
    };

    const showSolvingResult = (result, id) => {
        if (playerMode === PlayerV2Modes.TOURNAMENT) {
            handleContinue();
            return;
        }

        let uniqueComponentKey = "PV2SR";

        if (userRights.includes(PLAYER_RIGHTS.HAVE_INFO_BLOCK)) {
            addNotificationInQueue(
                2,
                <PlayerV2SolvingResult
                    result={result}
                    userRights={userRights}
                    handleContinue={() => {
                        handleContinue();
                        removeNotificationFromQueue(null, uniqueComponentKey);
                    }}
                    handleRetry={() => {
                        handleRetry();
                        removeNotificationFromQueue(null, uniqueComponentKey);
                    }}
                    questionId={id}
                    key={uniqueComponentKey}
                />,
            );
        } else {
            addNotificationInQueue(
                2,
                <PlayerV2SolvingResultWithoutWS
                    result={result}
                    userRights={userRights}
                    handleContinue={() => {
                        handleContinue();
                        removeNotificationFromQueue(null, uniqueComponentKey);
                    }}
                    handleRetry={() => {
                        handleRetry();
                        removeNotificationFromQueue(null, uniqueComponentKey);
                    }}
                    questionId={id}
                    key={uniqueComponentKey}
                />,
            );
        }
    };

    const getApiPrefix = () => {
        let prefixApi;

        let constructorType = getConstructorType();
        switch (constructorType) {
            case CLASSIC_TEST:
                prefixApi = "classic-test";
                break;
            case SEQUENCES_TEXT_TEST:
                prefixApi = "sequence-test";
                break;
            case WORD_MANY_SKIP_TEST:
                prefixApi = "many-word-skip";
                break;
            case WORD_SKIP_TEST:
                prefixApi = "word-skip";
                break;
            case WORD_SEQUENCE_SKIP_TEST:
                prefixApi = "word-skip-clause";
                break;
            case OPEN_ANSWER_TEST:
                prefixApi = "open-answer";
                break;
            case SEQUENCES_IMAGE_TEST:
                prefixApi = "sequence-test";
                break;
            case PAIRS_TEST:
                prefixApi = "pair";
                break;
            case DICTATION_TEST:
                prefixApi = "dictation";
                break;
            case BASKETS_TEST:
                prefixApi = "basket-engine";
                break;
            case CLIPPING_TEST:
                prefixApi = "clipping";
                break;
            case CLIPPING_SECONDARY_TEST:
                prefixApi = "clipping-secondary";
                break;
        }

        return prefixApi;
    };

    const getConvertedAnswer = () => {
        let convertedAnswers;
        let constructorType = getConstructorType();

        if (
            constructorType === CLASSIC_TEST ||
            constructorType === SEQUENCES_TEXT_TEST ||
            constructorType === WORD_MANY_SKIP_TEST ||
            constructorType === CLIPPING_SECONDARY_TEST
        ) {
            convertedAnswers = answer.map((answer) => answer.id);
        } else if (constructorType === BASKETS_TEST) {
            let baskets = answer;
            let hashMap = {};
            baskets.forEach((basket) => {
                hashMap[basket.id] = basket.answers.map((answer) => answer.id);
            });
            convertedAnswers = hashMap;
        } else {
            convertedAnswers = answer;
        }

        return convertedAnswers;
    };

    const handleToNextQuestion = () => {
        let question = questions[currentStep + 1]?.question;
        if (question) {
            setAnswer(null);
            setSelectedQuestion(questions[currentStep + 1]);
            setCurrentStep(currentStep + 1);
        } else {
            let unresolvedIndex = questions.findIndex(
                (question) => !question.academyLog?.flagCorrectAnswer,
            );

            if (unresolvedIndex !== -1) {
                setCurrentStep(unresolvedIndex);
                setSelectedQuestion(questions[unresolvedIndex]);
            }

            //todo: можно сделать выход из комплекта после всего решения.
        }
    };

    const getConstructorType = () => {
        let question;
        if (isSingleTask) {
            question = selectedQuestion;
        } else {
            question = selectedQuestion?.question;
        }

        if (!question) return;

        if (question.objectType === SEQUENCES) {
            if (question.isTextAnswer) {
                return SEQUENCES_TEXT_TEST;
            } else {
                return SEQUENCES_IMAGE_TEST;
            }
        }
        return question.objectType;
    };

    const getQuestionsAPI = () => {
        let API = undefined;
        switch (playerMode) {
            case PlayerV2Modes.ACADEMY:
            case PlayerV2Modes.ACADEMY_HOMEWORK:
                API = AcademyAPI.getAcademyQuestions;
                break;
            case PlayerV2Modes.TEACHER:
                API = isSingleTask ? QuestionAPI.getQuestion : TeacherAPI.getTeacherSetQuestions;
                break;
            case PlayerV2Modes.HOMEWORK:
                API = QuestionAPI.getQuestionsForHomework;
                break;
            case PlayerV2Modes.PREVIEW:
            case PlayerV2Modes.OLD_PREVIEW:
                API = QuestionAPI.getQuestion;
                break;
        }

        return API;
    };

    const checkOnSetResolved = (questions = []) => {
        if (!questions) {
            return;
        }

        let isResolved = questions.every(
            (question) =>
                question.academyLog?.flagCorrectAnswer === 1 ||
                question.academyLog?.flagCorrectAnswer === 0,
        );

        if (isResolved) {
            let result = {
                correct: 0,
                wrong: 0,
            };

            questions.forEach((question) => {
                if (question.academyLog?.flagCorrectAnswer) {
                    result.correct++;
                } else {
                    result.wrong++;
                }
            });

            let uniqueComponentKey = "PV2SR";
            addNotificationInQueue(
                4,
                <PlayerV2SetResult
                    key={uniqueComponentKey}
                    handleStayClick={() => {
                        removeNotificationFromQueue(null, uniqueComponentKey);
                    }}
                    handleExitClick={() => {
                        removeNotificationFromQueue(null, uniqueComponentKey);
                        history.push(ACADEMY_ROUTES.ROOT);
                    }}
                    result={result}
                />,
            );
        }
    };

    const getQuestions = (API) => {
        if (!API) {
            console.log("Player v2 API not detected");
            return;
        }

        let idForGetQuestion = id;

        if (playerMode === PlayerV2Modes.ACADEMY_HOMEWORK) {
            idForGetQuestion = asid;
        }

        API(idForGetQuestion).then((response) => {
            if (isSingleTask) {
                setSelectedQuestion(response.data);
            } else {
                let questions = response.data.sort((a, b) => (a.position > b.position && 1) || -1);

                if (!questions.length) {
                    showNotificationSetWithoutQuestions();
                    return;
                }

                setQuestions(questions);
                let question = questions[0]?.question;
                let firstQuestionWithAcademyLog = questions[0];

                if (
                    !firstQuestionWithAcademyLog?.isBuySubscription &&
                    playerMode === PlayerV2Modes.ACADEMY
                ) {
                    // первое задание платное
                    let firstFreeQuestionIndex = questions.findIndex((q) => q.question.isFree);

                    if (firstFreeQuestionIndex !== -1) {
                        setCurrentStep(firstFreeQuestionIndex);
                        setSelectedQuestion(questions[firstFreeQuestionIndex]);
                        return;
                    }
                }

                setCurrentStep(0);

                if (question) {
                    setSelectedQuestion(questions[0]);
                }
            }
        });
    };

    const getTournamentQuestion = () => {
        QuestionAPI.getAllForTournament(id)
            .then((response) => {
                let tempQuestionType = response.data[0]?.questionTypeDto;
                if (tempQuestionType) {
                    setSelectedQuestion(tempQuestionType);
                }
            })
            .catch((error) => {
                if (error.response?.data.status === 601) {
                    setSelectedQuestion(null);
                    addNotificationInQueue(
                        5,
                        <PlayerV2TournamentResult
                            handleToTournamentPage={() =>
                                history.push(TOURNAMENT_ROUTES.VIEW.replace(":id", id))
                            }
                        />,
                    );
                }
            });
    };

    const updateQuestions = () => {
        if (playerMode === PlayerV2Modes.TOURNAMENT) return;

        let idForGetQuestion = id;

        if (playerMode === PlayerV2Modes.ACADEMY_HOMEWORK) {
            idForGetQuestion = asid;
        }

        if (playerMode === PlayerV2Modes.ARTICLE) {
            setQuestions(questionsForView);
            return;
        }

        let API = getQuestionsAPI();
        API(idForGetQuestion).then((response) => {
            let questions = response.data.sort((a, b) => (a.position > b.position && 1) || -1);
            setQuestions(questions);
        });
    };

    const getQuestionData = (questionIndex) => {
        let tempQuestion = questions[questionIndex];
        if (tempQuestion) {
            setSelectedQuestion(tempQuestion);
        }
    };

    const handleExit = () => {
        switch (playerMode) {
            case PlayerV2Modes.ACADEMY:
                questions[0]?.academyBlockId &&
                    history.push(
                        ACADEMY_ROUTES.RESOLVE.replace(":id", questions[0].academyBlockId),
                    );
                break;
            case PlayerV2Modes.TEACHER:
                history.goBack();
                break;
            case PlayerV2Modes.PREVIEW:
                history.goBack();
                break;
            case PlayerV2Modes.TOURNAMENT:
                history.goBack();
                break;
            case PlayerV2Modes.HOMEWORK:
            case PlayerV2Modes.ACADEMY_HOMEWORK:
                history.push(STUDENT_ROUTES.HOMEWORK.ROOT);
                break;
        }
    };

    return {
        notificationContent,
        questions,
        handleSelectStep,
        currentStep,
        content,
        handleCheckAnswer,
        handleToNextQuestion,
        selectedQuestion,
        answer,
        userRights,
        isAdmin,
        isCreator,
        handleExit,
        resultIsLoading,
        withModeration,
        metaTags,
    };
};

usePlayerV2.propTypes = {
    id: PropTypes.number,
    isSingleTask: PropTypes.bool,
    playerMode: PropTypes.string,
    asid: PropTypes.number,
};

export default usePlayerV2;
