import {IMAGE_FROM_BANK} from "core/types/content-types";
import {IMAGE_FROM_UPLOAD_ANSWER_CLASSIC} from "entries/constructors/classic-test/utils/answer-types";

export const ConvertBankImageFromResponse = (image) => {
    return {
        id: image.id,
        url: image.fileDownloadUri.slice(1),
        name: image.name,
        type: IMAGE_FROM_BANK,
    };
};

export const ConvertUploadImageFromResponse = (image, id) => {
    return {
        id: id,
        url: image.fileDownloadUri.slice(1),
        name: image.name,
        type: IMAGE_FROM_UPLOAD_ANSWER_CLASSIC,
    };
};
