import {TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG} from "config";
import {ThemeAPI} from "entries/school-themes/core/api/themes";
import {MDBBtn, MDBCol, MDBContainer, MDBRow} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {toast} from "react-toastify";
import styles from "entries/school-themes/views/forms/create-theme/create-theme.module.sass";

export const CreateTheme = ({subjects, classes, onSaveNewTheme, itemForEdit}) => {
    const [gradeTag, setGradeTag] = useState(null);
    const [subjectTag, setSubjectTag] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [themeName, setThemeName] = useState("");

    useEffect(() => {
        if (!itemForEdit) return;
        setSubjectTag({
            value: itemForEdit.schoolSubjectTag.id,
            label: itemForEdit.schoolSubjectTag.name,
        });

        setGradeTag({
            value: itemForEdit.gradeTag.id,
            label: itemForEdit.gradeTag.name,
        });

        setThemeName(itemForEdit.theme.name);
    }, [itemForEdit]);

    const handleSave = () => {
        if (itemForEdit) {
            let dto = {
                gradeTagId: gradeTag.value,
                schoolTagId: subjectTag.value,
            };

            Promise.all([
                ThemeAPI.updateGroupedTheme(itemForEdit.id, dto),
                ThemeAPI.updateTheme(itemForEdit.theme.id, {name: themeName}),
            ])
                .then(() => {
                    toast.success("Тема обновлена", TOAST_SUCCESS_CONFIG);
                    onSaveNewTheme();
                })
                .catch(() => {
                    toast.error("Ошибка обновления темы", TOAST_ERROR_CONFIG);
                });
            return;
        }

        if (subjectTag && gradeTag) {
            setIsFetching(true);
            ThemeAPI.createTheme(themeName)
                .then((response) => {
                    let themeId = response.data.id;
                    ThemeAPI.groupingThemes(gradeTag.value, subjectTag.value, themeId).then(
                        (response) => {
                            toast.success("Новая тема добавлена", TOAST_SUCCESS_CONFIG);
                            onSaveNewTheme(response.data);
                        },
                    );
                })
                .finally(() => {
                    setIsFetching(false);
                });
        } else {
            toast.error("Заполните поля", TOAST_ERROR_CONFIG);
        }
    };

    return (
        <MDBContainer>
            <MDBRow>
                <MDBCol>
                    <div className={styles.container}>
                        <Select
                            styles={{menu: (provided) => ({...provided, zIndex: 9999})}}
                            options={subjects}
                            placeholder="Выберите предмет"
                            className="mb-3"
                            maxMenuHeight={200}
                            onChange={(event) => setSubjectTag(event)}
                            value={subjectTag}
                        />

                        <Select
                            styles={{menu: (provided) => ({...provided, zIndex: 9999})}}
                            options={classes}
                            placeholder="Выберите класс"
                            className="mb-3"
                            maxMenuHeight={200}
                            onChange={(event) => setGradeTag(event)}
                            value={gradeTag}
                        />

                        <input
                            name="themeName"
                            className="form-control"
                            placeholder="Название темы"
                            autoComplete="off"
                            onChange={(e) => setThemeName(e.target.value)}
                            value={themeName}
                        />
                    </div>

                    <MDBBtn
                        className="btn-block my-4"
                        disabled={isFetching}
                        color="info"
                        onClick={handleSave}
                    >
                        Сохранить
                    </MDBBtn>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

CreateTheme.propTypes = {
    classes: PropTypes.array,
    subjects: PropTypes.array,
    onSaveNewTheme: PropTypes.func,
};
