import promoTitle from "assets/images/static/promo-title.svg";
import urok from "assets/images/static/socials/urok.svg";
import vk from "assets/images/static/socials/vk.svg";
import cn from "classnames";
import {url} from "config";
import EnterButton from "entries/enter-button/enter-button";
import {HeaderContainer} from "entries/header/views/header-container";
import {useStores} from "hooks/use-stores";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {PAGES_ROUTES} from "views/routes/routes";

import styles from "./styles.module.sass";

const WelcomePage = ({tabs}) => {
    let history = useHistory();

    const [cookiesConfirmIsVisible, setCookiesConfirmIsVisible] = useState(false);
    const {playerStore} = useStores();

    useEffect(() => {
        let val = localStorage.getItem("isCookiesAccepted");
        if (!val) {
            setCookiesConfirmIsVisible(true);
        }
    }, []);

    return (
        <div className={styles.content}>
            <div
                className={cn({
                    "header position-relative": true,
                    [styles.header]: true,
                })}
            >
                <div className="header-container">
                    <HeaderContainer tabs={tabs} withEvents={false} withAuth withLogoText />
                </div>
            </div>
            {cookiesConfirmIsVisible && (
                <div
                    className={cn({
                        "animate__animated animate__fadeIn": true,
                        [styles.cookiesMessage]: true,
                    })}
                >
                    <p>
                        Продолжая использовать сайт, вы даете согласие на обработку файлов
                        <span
                            className="link ml-1"
                            onClick={() => history.push(PAGES_ROUTES.POLICY.COOKIES_POLICY)}
                        >
                            cookie
                        </span>
                    </p>
                    <i
                        className="fas fa-times"
                        onClick={() => {
                            setCookiesConfirmIsVisible(false);
                            localStorage.setItem("isCookiesAccepted", "true");
                        }}
                    />
                </div>
            )}

            <div className={styles.promo}>
                <div className={styles.titleWrapper}>
                    <img
                        className={cn({
                            "animate__animated animate__zoomIn": true,
                            [styles.promoTitle]: true,
                        })}
                        src={promoTitle}
                        alt="promo-title"
                    />
                    <p className={styles.description}>
                        Бесплатные образовательные тренажёры <br /> для учеников 1-4 классов
                    </p>
                </div>

                <EnterButton className={styles.button} />
                <div className={styles.promoContainer}>
                    <img
                        className={cn({
                            "animate__animated animate__fadeIn": true,
                            [styles.promoImage]: true,
                        })}
                        src={
                            playerStore.isMobileMode
                                ? url + "data/image-bank/f47d034b-ffbc-4f0b-991b-32be548489d1.png"
                                : url + "data/image-bank/c6cc1531-e975-46b6-987b-5814d1f676d4.png"
                        }
                        alt="promo"
                    />
                    {playerStore.isMobileMode && (
                        <div className={styles.mobileNav}>
                            <p onClick={() => history.push(PAGES_ROUTES.MOBILE.ACADEMY)}>
                                Академия
                            </p>
                            <hr />
                            <p onClick={() => history.push(PAGES_ROUTES.MOBILE.TOURNAMENT)}>
                                Турниры
                            </p>
                            <hr />
                            <p onClick={() => history.push(PAGES_ROUTES.MOBILE.TEACHER)}>Учителю</p>
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.socials}>
                    <img
                        src={vk}
                        alt="vk-logo"
                        onClick={() => window.open("https://vk.com/umnaya_vorona", "_blank")}
                    />
                </div>
                <div
                    className={styles.projects}
                    onClick={() => window.open("https://xn--j1ahfl.xn--p1ai/", "_blank")}
                >
                    <p>Педагогическое сообщество «Урок»</p>
                    <img src={urok} alt="logo-urok" />
                </div>
                <div
                    className={styles.about}
                    onClick={() => history.push(PAGES_ROUTES.POLICY.TERMS)}
                >
                    <p>Пользовательское соглашение</p>
                </div>
            </div>
        </div>
    );
};

export default WelcomePage;
