import {searchQueryParams} from "core/utils/helpers";
import {SIZE_S} from "utils/answers-config";
import {getMultipartFormDataContentTypeHeader} from "../config/headers";
import {Request} from "../utils/request";

export const QuestionAPI = {
    getQuestion: (id) => {
        if (id) {
            return Request.get(`question/${id}`);
        }

        return Promise.reject();
    },
    deleteQuestion: (id) => {
        return Request.delete(`question/${id}`);
    },
    getQuestionByName: (name, page = 1, size = 20) => {
        return Request.get(`questions-by-name?name=${name}&page=${page}&size=${size}`);
    },
    getQuestions: (page = 1, size = 20) => {
        return Request.get(`questions?size=${size}&page=${page}`);
    },
    getQuestionsForAcademy: () => {
        return Request.get(`questions-for-academy?size=500&page=1&sort=id,desc`);
    },
    getQuestionsForTeacher: (page, tags, themes) => {
        return Request.get(`questions-for-teacher${searchQueryParams(page, tags, themes)}`);
    },
    getQuestionsForHomework: (id) => {
        return Request.get(`my-home-work/${id}/questions`);
    },
    getAllForAcademy: (id) => {
        return Request.get(`question/${id}/all-for-academy`);
    },
    getAllForArticle: (id) => {
        return Request.get(`publication/question/${id}/all`);
    },
    getAllForHomeWork: (id, homeworkId) => {
        return Request.get(
            `question/${id}/all-home-work-for-student?teacherHomeWorkId=${homeworkId}`,
        );
    },
    getAllForAdmin: (id) => {
        return Request.get(`admin/question/${id}/all`);
    },
    getAllForTournament: (id) => {
        return Request.get(`tournament/${id}/questions-all-for-tournament`);
    },
    getAcademyLog: (questionId) => {
        return Request.get(`/question/${questionId}/my-academy-log`);
    },
    validate: (id) => {
        return Request.put(`question/${id}/validate`);
    },
    createProperties: (questionId, settings) => {
        return Request.post(`question/${questionId}/properties`, {
            sizeText: 16,
            sizeTitle: 18,
            sizeImage: SIZE_S,
            settings,
        });
    },
    updateProperties: (questionId, propertiesDto) => {
        return Request.put(`question/${questionId}/properties`, propertiesDto);
    },
    updateBackground: (questionId, imageBankId) => {
        return Request.put(
            `question/${questionId}/properties-background?imageBankId=${imageBankId}`,
        );
    },
    updateNarrator: (questionId, narratorId) => {
        return Request.put(`question/${questionId}/properties-narrator?narratorId=${narratorId}`);
    },
    addThemes: (questionId, themes) => {
        return Request.post(`question/${questionId}/theme`, themes);
    },
    deleteThemes: (questionId) => {
        return Request.delete(`question/${questionId}/theme-all`);
    },
    addTags: (questionId, themes) => {
        return Request.post(`question/${questionId}/tags`, themes);
    },
    deleteTags: (questionId) => {
        return Request.delete(`question/${questionId}/tags-all`);
    },
    addAudioForQuestion: (questionId, file) => {
        const fileData = new FormData();
        fileData.append("file", file);

        return Request.post(
            `question/${questionId}/question-audio`,
            fileData,
            getMultipartFormDataContentTypeHeader(),
        );
    },
    deleteAudioForQuestion: (questionId) => {
        return Request.delete(`question/${questionId}/question-audio`);
    },
    updateQuestionImageWithoutProperties: (questionId, file) => {
        const fileData = new FormData();
        fileData.set("file", file);

        return Request.post(
            `admin/question/${questionId}/question-image`,
            fileData,
            getMultipartFormDataContentTypeHeader(),
        );
    },
    getQuestionThemes: (questionId) => {
        return Request.get(`question/${questionId}/theme`);
    },
    getQuestionProperties: (questionId) => {
        return Request.get(`question/${questionId}/properties`);
    },
    updateLevelAccess: (questionId, type) => {
        return Request.put(`admin/question/${questionId}/level-access?levelAccess=${type}`);
    },
    getExpertise: (taskId) => {
        return Request.get(`question/${taskId}/expertise`);
    },
};
