import cn from "classnames";
import {BASKETS_TEST, CLIPPING_TEST} from "config/constructor-names";
import {
    DEFAULT_ANSWER_FONT_SIZE,
    DEFAULT_FONT_SIZE,
    FONT_ANSWER_SIZE_STEP,
    FONT_SIZE_STEP,
    MAX_ANSWER_FONT_SIZE,
    MAX_FONT_SIZE,
} from "config/project";
import {ImageBankAPI} from "core/api/image-bank";
import {TagUtils} from "core/api/tag-utils";
import BasketsTools from "entries/constructors/baskets-game/views/baskets-tools/baskets-tools";
import {
    convertResponseToBackground,
    convertResponseToImage,
    convertResponseToNarrator,
} from "entries/constructors/classic-test/utils/utils";
import {
    ButtonsImageSize,
    DEFAULT_SIZES,
} from "entries/constructors/classic-test/views/components/image-size-buttons";
import {
    BACKGROUNDS,
    NARRATORS,
    PARAMETERS,
    PropertiesButtons,
} from "entries/constructors/classic-test/views/components/properties-buttons";
import ItemCoords from "entries/item-coords/item-coords";
import {NarratorAPI} from "entries/narrator/core/NarratorAPI";
import Thumbler from "entries/thumbler/thumbler";
import {MDBAlert} from "mdbreact";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {
    IMAGE_TYPE,
    MIX_TYPE,
    SIZE_L,
    SIZE_M,
    SIZE_S,
    SIZE_XL,
    TEXT_TYPE,
} from "utils/answers-config";
import {SpinnerPage} from "views/components/spinner";
import {Switch} from "views/components/switch";

import {PictureList} from "views/containers/property-background-select/picture-list";

import styles from "./property-column.module.sass";

export const PropertyColumn = (props) => {
    const {
        selectImage,
        selectNarrator,
        selectImageSize,
        textTitleSize,
        setTextTitleSize,
        textAnswerSize,
        setTextAnswerSize,
        currentPropertyTab,
        onSelectTab,
        currentImageSize,
        answerType,
        isQuestionTextExist,
        isTwoColumnMode,
        toggleTwoColumnMode,
        isTwoColumnModeAvailable,
        withResizeElement = false,
        currentElementSize,
        selectElementSize,
        background,
        narrator,
        toolTipText,
        isRoundedAnswer,
        setIsRoundedAnswer,
        constructorType,
        basketsToolsData,
        setBaskets,
        deleteAnswer,
        deleteBasket,
        selectedBasketId,
        createBasketCopy,
        children,
        multiple,
        setMultiple,
        selectedItem,
        handleUpdateCoords,
        propertiesRef,
    } = props;

    const [isFetching, setIsFetching] = useState(false);
    const [images, setImages] = useState([]);
    const [backgroundOptions, setBackgroundOptions] = useState([]);

    useEffect(() => {
        currentPropertyTab === BACKGROUNDS && getBackgrounds();
        currentPropertyTab === NARRATORS && getNarrators();
    }, [currentPropertyTab, isTwoColumnMode]);

    const getBackgrounds = () => {
        setImages([]);
        TagUtils.getBackgrounds()
            .then((response) => {
                setBackgroundOptions(convertResponseToBackground(response));
            })
            .catch((error) => console.log(error));
    };

    const getImagesByTag = (tagId) => {
        ImageBankAPI.getImagesByTag(tagId).then((response) => {
            setImages(convertResponseToImage(response));
        });
    };

    const getNarrators = () => {
        setIsFetching(true);
        setImages([]);
        NarratorAPI.getNarrators()
            .then((response) => {
                setImages(convertResponseToNarrator(response));
            })
            .catch((error) => console.log(error))
            .finally(() => setIsFetching(false));
    };

    return (
        <div ref={propertiesRef} className={styles.content}>
            <PropertiesButtons
                handleClick={(choose) => onSelectTab(choose)}
                currentChoose={currentPropertyTab}
            />

            <div
                className={cn({
                    [styles.container]: true,
                    [props.className]: !!props.className,
                })}
            >
                {currentPropertyTab === BACKGROUNDS && (
                    <div className={styles.selectContainer}>
                        {backgroundOptions.length > 0 ? (
                            <React.Fragment>
                                <Select
                                    placeholder="Выберите изображение"
                                    options={backgroundOptions}
                                    onChange={(tagSelect) => getImagesByTag(tagSelect.value)}
                                />
                                <div className={styles.backgroundWrapper}>
                                    <div className={styles.imagesContainer}>
                                        {images.length > 0 && (
                                            <PictureList
                                                tags={images}
                                                saveImage={selectImage}
                                                selected={background}
                                            />
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <SpinnerPage primary />
                        )}
                    </div>
                )}

                {currentPropertyTab === PARAMETERS && (
                    <>
                        {setMultiple && (
                            <div className={styles.propertyContainer}>
                                <p className={styles.propertyTitle}>Использовать объект</p>
                                <div className={styles.property}>
                                    <Thumbler
                                        options={[
                                            {value: false, name: "Один раз"},
                                            {value: true, name: "Многократно"},
                                        ]}
                                        activeState={multiple}
                                        setActiveState={setMultiple}
                                        isFullWidth
                                    />
                                </div>
                            </div>
                        )}

                        {(answerType === IMAGE_TYPE || answerType === MIX_TYPE) && (
                            <React.Fragment>
                                <div className={styles.propertyContainer}>
                                    <p className={styles.propertyTitle}>Размер вариантов ответов</p>
                                    <div className={styles.property}>
                                        <ButtonsImageSize
                                            handleClick={(size) => selectImageSize(size)}
                                            currentImageSize={currentImageSize}
                                            availableSizes={
                                                constructorType === CLIPPING_TEST
                                                    ? [
                                                          {value: SIZE_S, name: "S"},
                                                          {value: SIZE_M, name: "M"},
                                                          {value: SIZE_L, name: "L"},
                                                          {value: SIZE_XL, name: "XL"},
                                                      ]
                                                    : DEFAULT_SIZES
                                            }
                                        />
                                    </div>
                                </div>

                                {setIsRoundedAnswer && (
                                    <div className={styles.switch}>
                                        <p className={styles.propertyTitle}>Круглые ответы</p>
                                        <Switch
                                            checked={isRoundedAnswer}
                                            onChange={() => setIsRoundedAnswer(!isRoundedAnswer)}
                                        />
                                    </div>
                                )}
                            </React.Fragment>
                        )}

                        {constructorType === CLIPPING_TEST && (
                            <React.Fragment>{children}</React.Fragment>
                        )}

                        {constructorType === BASKETS_TEST && (
                            <div className={styles.propertyContainer}>
                                <BasketsTools
                                    basketsToolsData={basketsToolsData}
                                    setBaskets={setBaskets}
                                    deleteAnswer={deleteAnswer}
                                    deleteBasket={deleteBasket}
                                    selectedBasketId={selectedBasketId}
                                    createBasketCopy={createBasketCopy}
                                />
                            </div>
                        )}

                        {isQuestionTextExist && (
                            <div className={styles.propertyContainer}>
                                <p className={styles.propertyTitle}>Размер шрифта вопроса</p>
                                <div className={styles.property}>
                                    <input
                                        type="range"
                                        className="custom-range"
                                        color="info"
                                        min={DEFAULT_FONT_SIZE}
                                        max={MAX_FONT_SIZE}
                                        step={FONT_SIZE_STEP}
                                        value={textTitleSize}
                                        onChange={(e) => setTextTitleSize(e.target.value)}
                                    />
                                    <p className={styles.fontSizer}>{textTitleSize}</p>
                                </div>
                            </div>
                        )}

                        {(answerType === TEXT_TYPE || answerType === MIX_TYPE) && (
                            <>
                                <div className={styles.propertyContainer}>
                                    <p className={styles.propertyTitle}>Размер шрифта ответа</p>
                                    <div className={styles.property}>
                                        <input
                                            type="range"
                                            className="custom-range"
                                            min={DEFAULT_ANSWER_FONT_SIZE}
                                            max={MAX_ANSWER_FONT_SIZE}
                                            step={FONT_ANSWER_SIZE_STEP}
                                            value={textAnswerSize}
                                            onChange={(e) => setTextAnswerSize(e.target.value)}
                                        />
                                        <p className={styles.fontSizer}>{textAnswerSize}</p>
                                    </div>
                                </div>

                                {isTwoColumnModeAvailable && (
                                    <div className={styles.switch}>
                                        <p className={styles.propertyTitle}>Ответы в два столбца</p>
                                        <Switch
                                            checked={isTwoColumnMode}
                                            onChange={toggleTwoColumnMode}
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        {withResizeElement && (
                            <>
                                <div className={styles.propertyContainer}>
                                    <p className={styles.propertyTitle}>Размер ответа</p>
                                    <div className={styles.property}>
                                        <input
                                            type="range"
                                            className="custom-range"
                                            min={100}
                                            max={210}
                                            step={10}
                                            value={currentElementSize}
                                            onChange={(e) => selectElementSize(e.target.value)}
                                        />
                                        <p className={styles.fontSizer}>{currentElementSize}</p>
                                    </div>
                                </div>
                            </>
                        )}

                        {selectedItem && (
                            <>
                                <div className={styles.propertyContainer}>
                                    <p className={styles.propertyTitle}>Координаты ответа:</p>
                                    <div className={styles.property}>
                                        <ItemCoords
                                            item={selectedItem}
                                            handleUpdateCoords={handleUpdateCoords}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}

                {currentPropertyTab === NARRATORS && (
                    <>
                        {isFetching ? (
                            <SpinnerPage primary />
                        ) : (
                            <div className={styles.narratorContainer}>
                                {images.length > 0 && (
                                    <PictureList
                                        tags={images}
                                        saveImage={selectNarrator}
                                        selected={narrator}
                                    />
                                )}
                            </div>
                        )}
                    </>
                )}

                {toolTipText && (
                    <div
                        className={cn({
                            [styles.helperBox]: true,
                        })}
                    >
                        <MDBAlert color="info" className="mt-3 text-center">
                            <p>
                                <i
                                    className="fa fa-info-circle animated animate__shakeY slow duration-5"
                                    aria-hidden="true"
                                />
                                {toolTipText}
                            </p>
                        </MDBAlert>
                    </div>
                )}
            </div>
        </div>
    );
};

PropertyColumn.propTypes = {
    answerType: PropTypes.string,
    background: PropTypes.any,
    basketsToolsData: PropTypes.object,
    className: PropTypes.string,
    constructorType: PropTypes.string,
    createBasketCopy: PropTypes.func,
    currentElementSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currentImageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currentPropertyTab: PropTypes.string,
    deleteAnswer: PropTypes.func,
    deleteBasket: PropTypes.func,
    handleUpdateCoords: PropTypes.func,
    isQuestionTextExist: PropTypes.any,
    isRoundedAnswer: PropTypes.bool,
    isTwoColumnMode: PropTypes.bool,
    isTwoColumnModeAvailable: PropTypes.bool,
    onSelectTab: PropTypes.func,
    narrator: PropTypes.object,
    selectedBasketId: PropTypes.any,
    selectElementSize: PropTypes.func,
    selectImage: PropTypes.func,
    selectImageSize: PropTypes.func,
    selectNarrator: PropTypes.func,
    setBaskets: PropTypes.func,
    setImageUrl: PropTypes.func,
    setIsRoundedAnswer: PropTypes.func,
    setTextAnswerSize: PropTypes.func,
    setTextTitleSize: PropTypes.func,
    testHasNarrator: PropTypes.func,
    textAnswerSize: PropTypes.any,
    textTitleSize: PropTypes.number,
    toggleTwoColumnMode: PropTypes.func,
    toolTipText: PropTypes.string,
    withResizeElement: PropTypes.bool,
    image: PropTypes.any,
    multiple: PropTypes.bool,
    setMultiple: PropTypes.func,
    selectedItem: PropTypes.object,
};
