import {MDBAlert, MDBBtn, MDBCol, MDBRow} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.sass";

const BasketsTools = ({
    basketsToolsData,
    setBaskets,
    deleteAnswer,
    deleteBasket,
    selectedBasketId,
    createBasketCopy,
}) => {
    const updateBasket = (value, basketIndex, positionType) => {
        if (!value.trim()) {
            value = 0;
        }

        let tempBaskets = basketsToolsData.baskets.slice();
        let basket = tempBaskets[basketIndex];
        basket = {...basket, [positionType]: parseInt(value)};
        tempBaskets[basketIndex] = basket;
        setBaskets(tempBaskets);
    };

    return (
        <div className={styles.tools}>
            {basketsToolsData?.baskets?.length > 0 ? (
                <React.Fragment>
                    {selectedBasketId ? (
                        <React.Fragment>
                            {basketsToolsData.baskets.map((basket, index) => {
                                if (selectedBasketId !== basket.id) {
                                    return;
                                }
                                return (
                                    <div
                                        key={basket.id}
                                        className={styles.tools__item}
                                        style={{
                                            border: `2px solid ${basket.color}`,
                                        }}
                                    >
                                        <p className={styles.tools__title}>Настройка корзины</p>
                                        <MDBRow className={styles.settings}>
                                            <MDBCol size="1" className={styles.settings__label}>
                                                X
                                            </MDBCol>
                                            <MDBCol>
                                                <input
                                                    value={basket.x}
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        updateBasket(e.target.value, index, "x")
                                                    }
                                                />
                                            </MDBCol>
                                            <MDBCol size="1" className={styles.settings__label}>
                                                Y
                                            </MDBCol>
                                            <MDBCol>
                                                <input
                                                    value={basket.y}
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        updateBasket(e.target.value, index, "y")
                                                    }
                                                />
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow className={styles.settings}>
                                            <MDBCol size="1" className={styles.settings__label}>
                                                Ш
                                            </MDBCol>
                                            <MDBCol>
                                                <input
                                                    value={basket.width}
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        updateBasket(e.target.value, index, "width")
                                                    }
                                                />
                                            </MDBCol>
                                            <MDBCol size="1" className={styles.settings__label}>
                                                В
                                            </MDBCol>
                                            <MDBCol>
                                                <input
                                                    value={basket.height}
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        updateBasket(
                                                            e.target.value,
                                                            index,
                                                            "height",
                                                        )
                                                    }
                                                />
                                            </MDBCol>
                                        </MDBRow>

                                        {basket.answers?.length > 0 && (
                                            <React.Fragment>
                                                <p className={styles.tools__title}>
                                                    Ответы в этой корзине
                                                </p>
                                                {basket.answers?.map((answer) => (
                                                    <React.Fragment key={answer.id}>
                                                        <MDBRow
                                                            between
                                                            className={styles.answers__item}
                                                        >
                                                            <MDBCol>{answer.text}</MDBCol>
                                                            <MDBCol size="4">
                                                                <MDBBtn
                                                                    size="sm"
                                                                    color="danger"
                                                                    onClick={() =>
                                                                        deleteAnswer(answer.id)
                                                                    }
                                                                >
                                                                    <i className="fas fa-trash" />
                                                                </MDBBtn>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        )}

                                        <div className={styles.settings__controls}>
                                            <MDBBtn
                                                size="sm"
                                                color="danger"
                                                onClick={() => deleteBasket(basket.id)}
                                            >
                                                <i className="fas fa-trash" /> Удалить корзину
                                            </MDBBtn>
                                            <MDBBtn
                                                onClick={() => createBasketCopy(basket)}
                                                color="info"
                                            >
                                                <i className="far fa-copy"></i>
                                            </MDBBtn>
                                        </div>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    ) : (
                        <MDBAlert color="info">Выберите корзину</MDBAlert>
                    )}
                </React.Fragment>
            ) : (
                <MDBAlert>Корзин пока нет</MDBAlert>
            )}
        </div>
    );
};

BasketsTools.propTypes = {
    basketsToolsData: PropTypes.object,
    createBasketCopy: PropTypes.func,
    deleteAnswer: PropTypes.func,
    deleteBasket: PropTypes.func,
    selectedBasketId: PropTypes.any,
    selectedDropBasket: PropTypes.func,
    setBaskets: PropTypes.func,
};

export default BasketsTools;
