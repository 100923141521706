import {TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG} from "config";
import {QuestionAPI} from "core/api/question";
import {ExpertiseAPI} from "entries/player-v2/views/player-v2-moderation/api/ExpertiseAPI";
import PlayerV2Moderation from "entries/player-v2/views/player-v2-moderation/player-v2-moderation";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";

const PlayerV2ModerationContainer = ({
    taskId,
    isCreator,
    handleCheckAnswer,
    answer,
    resultIsLoading,
    tags,
    selectedQuestion,
}) => {
    const [qualityRating, setQualityRating] = useState(0);
    const [editRating, setEditRating] = useState(0);
    const [funRating, setFunRating] = useState(0);
    const [plagiarismRating, setPlagiarismRating] = useState(0);
    const [selectedThemeValue, setSelectedThemeValue] = useState(1);
    const [gradeTagId, setGradeTagId] = useState(null);
    const [comment, setComment] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [theme, setTheme] = useState(null);
    const [themeTags, setThemeTags] = useState([]);
    const [isTaskExpertiseLoading, setIsTaskExpertiseLoading] = useState(false);
    const [taskExpertise, setTaskExpertise] = useState(null);

    const reset = () => {
        setQualityRating(0);
        setEditRating(0);
        setFunRating(0);
        setPlagiarismRating(0);
        setSelectedThemeValue(1);
        setGradeTagId(null);
        setComment("");
        setTaskExpertise(null);
    };

    const getTaskExpertise = () => {
        setIsTaskExpertiseLoading(true);
        QuestionAPI.getExpertise(taskId)
            .then((response) => {
                setTaskExpertise(response.data);
            })
            .finally(() => {
                setIsTaskExpertiseLoading(false);
            });
    };

    const getThemes = () => {
        QuestionAPI.getQuestionThemes(taskId).then((response) => {
            setThemeTags(response.data.content || []);
        });
    };

    useEffect(() => {
        reset();

        if (taskId) {
            getTaskExpertise();
            getThemes();
        }
    }, [taskId]);

    const handleEvaluate = () => {
        let expertiseDto = {
            comment,
            edit: editRating,
            fun: funRating,
            plagiarism: plagiarismRating,
            quality: qualityRating,
        };
        setIsLoading(true);
        ExpertiseAPI.createExpertise(taskId, expertiseDto, theme?.value)
            .then(() => {
                toast.success("Результат экспертизы отправлен", TOAST_SUCCESS_CONFIG);
                reset();
                getTaskExpertise();
            })
            .catch(() =>
                toast.error("Ошибка отправления результата экспертизы", TOAST_ERROR_CONFIG),
            )
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <PlayerV2Moderation
                taskExpertiseData={{taskExpertise, isTaskExpertiseLoading}}
                qualityRating={qualityRating}
                setQualityRating={setQualityRating}
                editRating={editRating}
                setEditRating={setEditRating}
                funRating={funRating}
                setFunRating={setFunRating}
                plagiarismRating={plagiarismRating}
                setPlagiarismRating={setPlagiarismRating}
                selectedThemeValue={selectedThemeValue}
                setSelectedThemeValue={setSelectedThemeValue}
                gradeTagId={gradeTagId}
                setGradeTagId={setGradeTagId}
                handleEvaluate={handleEvaluate}
                taskId={taskId}
                comment={comment}
                setComment={setComment}
                setTheme={setTheme}
                isLoading={isLoading}
                theme={theme}
                isCreator={isCreator}
                answer={answer}
                handleCheckAnswer={handleCheckAnswer}
                resultIsLoading={resultIsLoading}
                tags={tags}
                themeTags={themeTags}
                selectedQuestion={selectedQuestion}
            />
        </>
    );
};

PlayerV2ModerationContainer.propTypes = {
    answer: PropTypes.any,
    handleCheckAnswer: PropTypes.func,
    isCreator: PropTypes.bool,
    isExpertiseSubmitted: PropTypes.bool,
    resultIsLoading: PropTypes.bool,
    taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PlayerV2ModerationContainer;
