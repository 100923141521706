import React from "react";
import styles from "./styles.module.sass";
import cn from "classnames";
import {IHint} from "../../../interfaces/IHint";
import {MDBBtn} from "mdbreact";
import {url} from "../../../config";

interface IProps {
    toggle: () => void;
    hint: IHint;
}

const HintModal = ({hint, toggle}: IProps) => {
    return (
        <div
            className={cn({
                [styles.containerWrapper]: true,
            })}
        >
            <div className={styles.content}>
                <p className={styles.hintText}>{hint.hintText}</p>

                {hint.imageHint && (
                    <div className={styles.imageWrapper}>
                        <img src={url + hint.imageHint?.fileDownloadUri?.slice(1)} />
                    </div>
                )}
            </div>

            <div className={styles.footer}>
                <MDBBtn color="success" onClick={toggle}>
                    Закрыть подсказку
                </MDBBtn>
            </div>
        </div>
    );
};

export default HintModal;
