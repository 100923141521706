import {createContext} from "react";
import {UserStore} from "./user-store";
import {PlayerStore} from "./player-store";
import { AppStore } from "./app-store";
import { NotificationStore } from "./notification-store";
import { ConstructorV2Store } from "./constructor-v2-store";
import { NewsStore } from "./news-store";

export const rootStoreContext = createContext({
    userStore: new UserStore(),
    playerStore: new PlayerStore(),
    appStore: new AppStore(),
    notificationStore: new NotificationStore(),
    constructorV2Store: new ConstructorV2Store(),
    newsStore: new NewsStore()
});
