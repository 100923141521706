import { Request } from "../../core/utils/request";
import { INewsDto } from "../modals/create-news-modal/create-news-modal";

export const NewsAPI = {
    getNewsByAuditory: (auditory: string) => {
        return Request.get(`/news?size=100000&auditory=${auditory}&sort=id,desc`)
    },
    getNews: () => {
        return Request.get(`/news?size=100000&sort=id,desc`)
    },
    createNews: (newsDto: INewsDto) => {
        return Request.post(`/admin/news`, newsDto)
    },
    updateNews: (newsId: number, newsDto: INewsDto) => {
        return Request.put(`/admin/news/${newsId}`, newsDto)
    },
    deleteNews: (newsId: number) => {
        return Request.delete(`/admin/news/${newsId}`)
    },
}
