import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import {toast} from "react-toastify";

import SockJS from "sockjs-client";
import Stomp from "stompjs";
import {auth} from "../../core/utils/auth";
import {useStores} from "../../hooks/use-stores";
import {IWebsocketMessage} from "../../interfaces/IWebsocketMessage";
import TournamentStartModal from "../../entries/modals/tournament-start-modal/tournament-start-modal";
import {runInAction} from "mobx";
import {EWebsocketTypes} from "../../enums/EWebsocketTypes";
import {WS_TYPES} from "../../hooks/useWebsocket";

const ServiceWebsocket = observer(() => {
    const {keycloak} = auth;
    const {appStore, notificationStore} = useStores();

    useEffect(() => {
        if (appStore.isAuthorized) {
            connectWebsocket();
        }
    }, [appStore.isAuthorized]);

    const connectWebsocket = () => {
        let stompClient: any;
        let socket = new SockJS("https://umnayavorona.ru:8443/greeting");
        stompClient = Stomp.over(socket);
        stompClient.debug = null;

        let headers: any = {
            Authorization: `Bearer ${keycloak?.token}`,
        };

        const onStompConnect = () => {
            stompClient.subscribe("/user/queue/reply", onMessageReceived);
            stompClient.subscribe("/topic/reply", onMessageReceived);
        };

        const onStompError = (error: any) => {
            console.log(error);
        };

        stompClient.connect(headers, onStompConnect, onStompError);

        const onMessageReceived = (message: any) => {
            console.log(message);
            if (!message.body) return;

            try {
                let messageBody: IWebsocketMessage = JSON.parse(message.body);
                runInAction(() => {
                    appStore.websocketMessages.push(messageBody);
                });

                actionByMessageType(messageBody);
                customActionByCode(messageBody);
            } catch (e) {
                console.log("Websocket message parse error", e);
            }
        };
    };

    const actionByMessageType = (message: IWebsocketMessage) => {
        switch (message.objectType) {
            case EWebsocketTypes.RATING:
                appStore.websocketMessages.push({
                    description: `${
                        message.rating ? "За правильное решение ты получаешь" : "Задание решено"
                    }`,
                    rating: message.rating ? message.rating : 0,
                    objectType: WS_TYPES.OTHER,
                    questionId: message.questionId,
                    imageUri: "/data/image-bank/82dc8647-c8f1-4d35-b585-203ab6988ef1.svg",
                });
                break;
            case EWebsocketTypes.ACHIEVEMENT:
                runInAction(() => {
                    appStore.events = [
                        {
                            createdOn: new Date(),
                            event: {
                                description: message.description,
                                eventSubSection: message.eventSubSection,
                                pictogram: {fileDownloadUri: message.imageUri},
                                title: message.title,
                            },
                        },
                        ...appStore.events,
                    ];
                    notificationStore.unreadEventsCount++;
                });
                break;
        }
    };

    const customActionByCode = (message: IWebsocketMessage) => {
        if (!message.code) return;

        switch (message.code) {
            case 1000:
                appStore.addModalToQueue(
                    <TournamentStartModal
                        name={message.message}
                        tournamentId={message.id}
                        key={"tsm-modal"}
                        toggle={appStore.closeActiveModal}
                    />,
                );
                break;
            case 1002:
                toast.info("У вас новое сообщение от техподдержки!");
                runInAction(() => {
                    notificationStore.unreadMessageCount += 1;
                });

                break;
            case 1003:
                // перед стартом турнира
                break;
            case 1004:
                // старт турнира за 5 минут
                break;
        }
    };

    return null;
});

export default ServiceWebsocket;
