import {url} from "config";
import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";
import styles from "./styles.module.sass";
import cn from "classnames";

const TeacherClassSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${url + "data/image-bank/5441d238-5ee8-4106-943a-6df18bc1d561.png"})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundColor: "#a5e2e5",
    };

    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            <div className={sliders.content}>
                <div className={sliders.about}>
                    <p
                        className={cn({
                            [sliders.title]: true,
                            [styles.title]: true,
                        })}
                    >
                        Постройте свой онлайн класс!
                    </p>
                    <p
                        className={cn({
                            [sliders.description]: true,
                            [styles.description]: true,
                        })}
                    >
                        Создавайте интерактивные задания легко и быстро. Собирайте учеников в
                        группы, чтобы проверить знания или потренировать их. Сделайте учебный
                        процесс ещё интересней!
                    </p>
                </div>
            </div>
        </div>
    );
};

TeacherClassSlide.propTypes = {};

export default TeacherClassSlide;
