import React from "react";
import {useStores} from "../../hooks/use-stores";
import {observer} from "mobx-react-lite";
import NotificationModalLayer from "../../entries/notifications-modal/notification-modal-layer";

const ServiceModal = observer(() => {
    const {appStore} = useStores();

    return (
        <React.Fragment>
            <NotificationModalLayer
                isActive={!!appStore.activeModal}
                toggle={
                    !appStore.activeModal?.key.includes("toggle-disabled")
                        ? appStore.closeActiveModal
                        : null
                }
            >
                {appStore.activeModal}
            </NotificationModalLayer>
        </React.Fragment>
    );
});

export default ServiceModal;
