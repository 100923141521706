import {MDBBadge, MDBNavItem, MDBNavLink} from "mdbreact";
import React from "react";
import PropTypes from "prop-types";
import styles from "./sub-header-item.module.sass";
import cn from "classnames";
import {useLocation} from "react-router-dom";

const SubHeaderItem = ({
    name,
    value,
    path,
    isLinks,
    onSelect,
    currentTab,
    className,
    action,
    counter,
    image,
}) => {
    let location = useLocation();

    return (
        <MDBNavItem>
            {isLinks ? (
                <React.Fragment>
                    {action ? (
                        <div
                            className={cn({
                                "waves-effect": true,
                                "waves-light": true,
                                [styles.container]: true,
                                [className]: !!className,
                            })}
                            onClick={action}
                        >
                            {name}
                        </div>
                    ) : (
                        <MDBNavLink
                            className={cn({
                                "waves-effect": true,
                                "waves-light": true,
                                [styles.container]: true,
                                [styles.active]: location.pathname.includes(path),
                                [className]: !!className,
                            })}
                            to={path}
                            active={true}
                        >
                            {image && (
                                <img
                                    style={{width: 25, height: 25, marginRight: 10}}
                                    src={image}
                                    alt="menu-icon"
                                />
                            )}
                            {name}
                            {!!counter && (
                                <MDBBadge className="ml-1" color="info">
                                    {counter}
                                </MDBBadge>
                            )}
                        </MDBNavLink>
                    )}
                </React.Fragment>
            ) : (
                <div
                    className={cn({
                        "waves-effect": true,
                        "waves-light": true,
                        [styles.container]: true,
                        [styles.active]: currentTab === value,
                        [className]: !!className,
                    })}
                    onClick={() => onSelect(value)}
                >
                    {name}
                    {!!counter && (
                        <MDBBadge className="ml-1" color="info">
                            {counter}
                        </MDBBadge>
                    )}
                </div>
            )}
        </MDBNavItem>
    );
};

SubHeaderItem.propTypes = {
    currentTab: PropTypes.string,
    isLinks: PropTypes.bool,
    name: PropTypes.string,
    path: PropTypes.string,
    value: PropTypes.string,
    onSelect: PropTypes.func,
};

export default SubHeaderItem;
