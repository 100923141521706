import vk from "assets/images/static/socials/vk.svg";
import {auth} from "core/utils/auth";
import SubHeaderItem from "entries/sub-header/sub-header-item/sub-header-item";
import styles from "entries/sub-header/sub-header/sub-header.module.sass";
import {useStores} from "hooks/use-stores";
import {
    MDBCollapse,
    MDBNavbar,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBNavItem,
    MDBNavLink,
} from "mdbreact";
import {observer} from "mobx-react-lite";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";

const SubHeader = observer(
    ({subTabs, isLinks = true, onSelect, currentTab, withExit = false, withVK = false}) => {
        const {keycloak} = auth;
        const {notificationStore} = useStores();
        const [navbarIsCollapsed, setNavbarIsCollapsed] = useState(false);
        const history = useHistory();
        const {appStore} = useStores();

        if (appStore.isMobile) {
            return null;
        }

        return (
            <MDBNavbar expand="md" light className={styles.container}>
                <MDBNavbarToggler
                    className={styles.toggler}
                    onClick={() => setNavbarIsCollapsed(!navbarIsCollapsed)}
                />
                <MDBCollapse isOpen={navbarIsCollapsed} navbar>
                    <MDBNavbarNav left>
                        {!!subTabs.length &&
                            subTabs.map((subTab, index) => {
                                let counter = 0;
                                if (subTab?.path?.includes("conversations")) {
                                    counter = notificationStore.unreadMessageCount;
                                }

                                if (subTab?.value === "NEWS") {
                                    counter = notificationStore.unreadNewsCount;
                                }

                                return (
                                    <SubHeaderItem
                                        key={index}
                                        path={subTab.path}
                                        action={subTab.action}
                                        name={subTab.name}
                                        isLinks={isLinks}
                                        onSelect={onSelect}
                                        value={subTab.value}
                                        currentTab={currentTab}
                                        className={styles.tab}
                                        counter={counter}
                                        image={subTab.image}
                                    />
                                );
                            })}

                        {withVK &&
                            history?.location?.pathname?.includes("account/page") &&
                            !navbarIsCollapsed && (
                                <MDBNavLink className={styles.socials} key="vk-group" to="#">
                                    <img
                                        src={vk}
                                        alt="vk-logo"
                                        onClick={() =>
                                            window.open("https://vk.com/umnaya_vorona", "_blank")
                                        }
                                    />
                                </MDBNavLink>
                            )}
                    </MDBNavbarNav>

                    <MDBNavbarNav right>
                        {withExit && (
                            <MDBNavItem className={styles.exitButton}>
                                <MDBNavLink key="exit" to="#">
                                    <div onClick={() => keycloak.logout()}>
                                        <p style={{color: "#434242"}}>Выход</p>
                                    </div>
                                </MDBNavLink>
                            </MDBNavItem>
                        )}
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        );
    },
);

SubHeader.propTypes = {
    currentTab: PropTypes.string,
    isLinks: PropTypes.bool,
    show: PropTypes.bool,
    subTabs: PropTypes.array,
    withExit: PropTypes.bool,
    onSelect: PropTypes.func,
};

export default SubHeader;
