import React from "react";
import AdminPanelHeader from "../admin-panel-header/admin-panel-header";
import styles from "./styles.module.sass";
import {Route, Switch} from "react-router-dom";
import {ADMIN_PANEL_TASKS_ACTIONS} from "../core/routes";

const AdminPanelTasks = () => {
    return (
        <React.Fragment>
            <AdminPanelHeader title="Задания" />
            <div className={styles.wrapper}>
                <Switch>
                    {ADMIN_PANEL_TASKS_ACTIONS.map((route) => (
                        <Route path={route.path} key={route.id} exact={route.exact}>
                            {route.component}
                        </Route>
                    ))}
                </Switch>
            </div>
        </React.Fragment>
    );
};

export default AdminPanelTasks;
