import React from "react";
import {useTranslation} from "react-i18next";
import {MDBBtn} from "mdbreact";
import PropTypes from "prop-types";
import ImagesUploader from "react-images-upload";

import {IMAGE_EXTENSIONS, MAX_FILE_SIZE, url} from "config";

import {Image} from "views/components/images-library-image";

export const ImageEdit = (props) => {
    const {t} = useTranslation();
    const {image = {}} = props;

    const [name, setName] = React.useState(image.name);
    const handleChangeName = React.useCallback((event) => {
        setName(event.target.value);
    }, []);
    const handleChange = React.useCallback((event) => {
        handleChange(event.target.value);
    }, []);

    const handleDelete = React.useCallback(() => {
        props.onDelete(image.id);
    }, []);

    const handleUpdate = React.useCallback((items) => {
        if (items && items.length) {
            props.onUpdate(image.id, items[0]);
        }
    }, []);

    const handleRename = React.useCallback(() => {
        props.onRename(image.id, name);
    }, [name]);

    return (
        <form>
            <div>
                <div>
                    ID: <strong>{image.id}</strong>
                </div>
                <div>
                    <label>
                        {t("Название изображения")}
                        <input
                            type="text"
                            defaultValue={name}
                            onChange={handleChangeName}
                            disabled={props.disabled}
                        />
                    </label>
                </div>
                <Image
                    src={
                        props.image.fileDownloadUri
                            ? `${url}${props.image.fileDownloadUri.slice(1)}`
                            : null
                    }
                />
                <div>
                    <ImagesUploader
                        buttonText={t("Обновить")}
                        buttonClassName="btn btn-primary Ripple-parent btn-sm"
                        fileSizeError={t("большой размер файла")}
                        fileTypeError={t("неподдерживаемый формат файла")}
                        imgExtension={IMAGE_EXTENSIONS}
                        maxFileSize={MAX_FILE_SIZE}
                        singleImage
                        withIcon={false}
                        withLabel={false}
                        disabled={props.disabled}
                        onChange={handleUpdate}
                    />
                    <MDBBtn
                        size={"sm"}
                        color="danger"
                        onClick={handleDelete}
                        disabled={props.disabled}
                    >
                        {t("Удалить")}
                    </MDBBtn>
                    <MDBBtn
                        size={"sm"}
                        type="submit"
                        color="deep-orange"
                        onClick={handleRename}
                        disabled={props.disabled}
                    >
                        {t("Переименовать")}
                    </MDBBtn>
                </div>
            </div>
        </form>
    );
};

ImageEdit.propTypes = {
    image: PropTypes.shape({
        fileDownloadUri: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onRename: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
