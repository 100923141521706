import {Request} from "../../core/utils/request";
import {IHint} from "../../interfaces/IHint";

export const HintsAPI = {
    getHintAdmin: (hintId: number) => {
        return Request.get(`/admin/hint/${hintId}`);
    },
    updateHintAdmin: (hintId: number, hint: IHint) => {
        return Request.put(`/admin/hint/${hintId}`, hint);
    },
    deleteHintAdmin: (hintId: number) => {
        return Request.delete(`/admin/hint/${hintId}`);
    },
    addImageHintAdmin: (hintId: number, file: Blob) => {
        const fileData = new FormData();
        fileData.set("file", file);
        return Request.put(`/admin/hint/${hintId}/add-image?name=hintImage${hintId}`, fileData);
    },
    createHintAdmin: (questionId: number, hint: IHint) => {
        return Request.post(`/admin/question/${questionId}/hint`, hint);
    },
    buyAnswer: (hintId: number) => {
        return Request.get(`/hint/${hintId}/buy-answer`);
    },
    buyHint: (hintId: number) => {
        return Request.get(`/hint/${hintId}/buy-hint`);
    },
    getHintsByQuestionId: (questionId: number) => {
        return Request.get(`/hint/question/${questionId}/hints`);
    },
};
