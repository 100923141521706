import React from "react";
import {Circle, Group, Image, Rect, Transformer} from "react-konva";
import PropTypes from "prop-types";

import {OUTLINE_TYPE, SIZE, STAGE_PADDING} from "./config";
import {getStageImageSizes} from "./utils";

export const URLImage = (props) => {
    const [size, setSize] = React.useState(
        getStageImageSizes(props.width, props.height, SIZE[props.size]),
    );

    const stageSize = size.width > size.height ? size.width : size.height;

    const [position, setPosition] = React.useState({
        x: props.defaultX ? props.defaultX - size.width / 2 : 0,
        y: props.defaultY ? props.defaultY - size.height / 2 : 0,
    });

    React.useEffect(() => props.onMove(position), [position]);

    React.useEffect(() => {
        const sizeConfig = SIZE[props.size];
        setSize(getStageImageSizes(props.width, props.height, sizeConfig));
    }, [props.size]);

    const [stateRotation, setRotation] = React.useState(props.rotation || 0);

    React.useEffect(() => {
        props.onTransform({
            width: size.width,
            height: size.height,
            rotation: stateRotation,
        });
    }, [size.width, size.height, stateRotation]);

    const {stageWidth, stageHeight} = props;

    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (props.isSelected && props.enableResize) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [props.isSelected]);

    const minWidth = SIZE[props.size].minImageWidth;
    const maxWidth = SIZE[props.size].maxImageWidth;
    const minHeight = SIZE[props.size].minImageHeight;
    const maxHeight = SIZE[props.size].maxImageHeight;

    return (
        <>
            <Group
                x={position.x}
                y={position.y}
                rotation={stateRotation}
                width={stageSize}
                height={stageSize}
                ref={shapeRef}
                draggable
                onTransformEnd={() => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    const rotation = node.rotation();

                    if (rotation !== stateRotation) {
                        setRotation(Math.floor(rotation));
                    } else {
                        const newWidth = Math.floor(size.width * scaleX);
                        const newHeight = Math.floor(size.height * scaleY);

                        setSize({
                            width: newWidth,
                            height: newHeight,
                        });

                        node.scaleY(1);
                        node.scaleX(1);
                    }
                }}
                dragBoundFunc={function (pos) {
                    let newX = pos.x;
                    let newY = pos.y;
                    if (props.outlineType === OUTLINE_TYPE.SQUARE) {
                        if (pos.x < STAGE_PADDING) {
                            newX = STAGE_PADDING;
                        } else if (pos.x > stageWidth - stageSize - STAGE_PADDING) {
                            newX = stageWidth - stageSize - STAGE_PADDING;
                        }
                        if (pos.y < STAGE_PADDING) {
                            newY = STAGE_PADDING;
                        } else if (pos.y > stageHeight - stageSize - STAGE_PADDING) {
                            newY = stageHeight - stageSize - STAGE_PADDING;
                        }
                    } else if (props.outlineType === OUTLINE_TYPE.CIRCLE) {
                        const outlineRadius = (Math.sqrt(2) * stageSize) / 2;
                        const outlineOffset = stageSize / 2;

                        if (pos.x < STAGE_PADDING + outlineRadius - outlineOffset) {
                            newX = STAGE_PADDING + outlineRadius - outlineOffset;
                        } else if (
                            pos.x >
                            stageWidth - outlineRadius - outlineOffset - STAGE_PADDING
                        ) {
                            newX = stageWidth - outlineRadius - outlineOffset - STAGE_PADDING;
                        }

                        if (pos.y < STAGE_PADDING + outlineRadius - outlineOffset) {
                            newY = STAGE_PADDING + outlineRadius - outlineOffset;
                        } else if (
                            pos.y >
                            stageHeight - outlineRadius - outlineOffset - STAGE_PADDING
                        ) {
                            newY = stageHeight - outlineRadius - outlineOffset - STAGE_PADDING;
                        }
                    }

                    return {
                        x: newX,
                        y: newY,
                    };
                }}
                onDragEnd={(e) => {
                    setPosition({
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onContextMenu={(e) => {
                    e.evt.preventDefault(true);

                    const mousePosition = e.target.getStage().getPointerPosition();
                    props.onContextMenu(mousePosition);
                }}
            >
                {props.showOutline &&
                    (props.outlineType === OUTLINE_TYPE.CIRCLE ? (
                        <Circle
                            radius={(Math.sqrt(2) * stageSize) / 2}
                            fill="black"
                            opacity={0.2}
                            x={stageSize / 2}
                            y={stageSize / 2}
                        />
                    ) : (
                        <Rect fill="black" opacity={0.2} width={stageSize} height={stageSize} />
                    ))}
                <Image
                    image={props.image}
                    width={size.width}
                    height={size.height}
                    x={(stageSize - size.width) / 2}
                    y={(stageSize - size.height) / 2}
                />
            </Group>
            {props.enableResize && props.isSelected && (
                <Transformer
                    ref={trRef}
                    enabledAnchors={["top-left", "top-right", "bottom-left", "bottom-right"]}
                    rotationSnapTolerance={10}
                    rotationSnaps={[0, 90, 180, 270]}
                    keepRatio
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width < minWidth || newBox.height < minHeight) {
                            return oldBox;
                        }
                        if (newBox.width > maxWidth || newBox.height > maxHeight) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    );
};

URLImage.propTypes = {
    defaultX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    enableResize: PropTypes.bool,
    height: PropTypes.number,
    image: PropTypes.any.isRequired,
    isSelected: PropTypes.bool,
    outlineType: PropTypes.oneOf(Object.values(OUTLINE_TYPE)),
    rotation: PropTypes.number,
    showOutline: PropTypes.bool,
    size: PropTypes.string,
    stageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.number,
    zoomLevel: PropTypes.number,
    onContextMenu: PropTypes.func,
    onMove: PropTypes.func.isRequired,
    onTransform: PropTypes.func,
};
