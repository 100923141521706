import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const SchoolSubjectsSelect = ({
    subjects,
    handleSelectSubject,
    isMulti = false,
    selectedSubjects,
}) => {
    return (
        <Select
            styles={{menu: (provided) => ({...provided, zIndex: 9999})}}
            options={subjects}
            onChange={(e) => handleSelectSubject(e)}
            isMulti={isMulti}
            placeholder={"Выберите предмет"}
            value={selectedSubjects}
        />
    );
};

SchoolSubjectsSelect.propTypes = {
    handleSelectSubject: PropTypes.func.isRequired,
    subjects: PropTypes.array.isRequired,
    isMulti: PropTypes.bool,
    selectedSubjects: PropTypes.any,
};

export default SchoolSubjectsSelect;
