import React from "react";

import {Route, Switch} from "react-router-dom";
import styles from "./styles.module.sass";
import {TEACHER_WORKSHOP_ROUTES} from "./routes";
import SelectEnginePage from "./pages/select-engine-page";
import ConstructorV2TaskWrapper from "../constructors-v2/constructor-v2-task-wrapper";
import {EngineTypes} from "../constructors-v2/config/engine-types";

const TeacherWorkshopPage = () => {
    return (
        <div className={styles.workshopWrapper}>
            <div className={styles.workshopContainer}>
                <div className={styles.workshopContent}>
                    <Switch>
                        <Route path={TEACHER_WORKSHOP_ROUTES.TASKS.CREATE_TASK}>
                            <SelectEnginePage />
                        </Route>
                        <Route path={TEACHER_WORKSHOP_ROUTES.TASKS.MY_TASKS}>
                            <p>my tasks</p>
                        </Route>
                        <Route path={TEACHER_WORKSHOP_ROUTES.SETS.MY_SETS}>
                            <p>my sets</p>
                        </Route>
                        <Route path={TEACHER_WORKSHOP_ROUTES.SETS.OTHER_SETS}>
                            <p>other sets</p>
                        </Route>
                        <Route path={TEACHER_WORKSHOP_ROUTES.CONSTRUCTOR.DICTATION}>
                            <ConstructorV2TaskWrapper engineType={EngineTypes.DICTATION} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default TeacherWorkshopPage;
