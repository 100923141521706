import React from "react";
import PropTypes from "prop-types";

import {ModalCreateWordSkip} from "entries/constructors/word-skip/views/modal-create-word-skip/modal-create-word-skip";

export const ModalCreateWordSkipContainer = ({
    onSaveSkips,
    skipsIndexes,
    isOpen,
    setIsOpen,
    text,
    skipWithAdditionalAnswers,
    additionalAnswers,
    sentenceSkip,
}) => {
    return (
        <ModalCreateWordSkip
            toggle={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
            onSaveSkips={onSaveSkips}
            skipsIndexes={skipsIndexes}
            text={text}
            skipWithAdditionalAnswers={skipWithAdditionalAnswers}
            additionalAnswers={additionalAnswers}
            sentenceSkip={sentenceSkip}
        />
    );
};

ModalCreateWordSkipContainer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    skipsIndexes: PropTypes.array.isRequired,
    text: PropTypes.string.isRequired,
    onSaveSkips: PropTypes.func.isRequired,
    additionalAnswers: PropTypes.array,
    sentenceSkip: PropTypes.bool,
    skipWithAdditionalAnswers: PropTypes.bool,
};
