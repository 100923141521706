import React from "react";
import styles from "./styles.module.sass";
import {Button} from "antd";
import {useStores} from "../../../hooks/use-stores";
import CreateNewsModal from "../../modals/create-news-modal/create-news-modal";

interface IProps {
    getNews: () => void;
}

const NewsHeader = ({getNews}: IProps) => {
    const {appStore} = useStores();

    return (
        <div className={styles.newsHeader}>
            <div>
                <p className={styles.title}>Новости</p>
            </div>

            <div>
                <Button
                    type="primary"
                    onClick={() =>
                        appStore.addModalToQueue(
                            <CreateNewsModal
                                key={"cnm-modal toggle-disabled"}
                                toggle={appStore.closeActiveModal}
                                getNews={getNews}
                            />,
                        )
                    }
                >
                    Создать новость
                </Button>
            </div>
        </div>
    );
};

export default NewsHeader;
