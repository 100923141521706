import {ADMIN_ROLE, TEACHER_ROLE} from "config/roles";
import {Request} from "core/utils/request";
import {
    ACADEMY_MODE,
    HOMEWORK_MODE,
    TOURNAMENT_MODE,
} from "entries/player/views/player/utils/player-config";
import {SIZE_S} from "utils/answers-config";

const API_PATH = `many-word-skip`;
const INTERNAL_ERROR = "error.internal";

export const WordSkipAdditionalAPI = {
    createQuestion: (questionDto) => {
        return Request.post(`${API_PATH}`, questionDto);
    },
    createQuestionAdmin: (questionDto) => {
        return Request.post(`admin/${API_PATH}`, questionDto);
    },
    createProperties: (questionId) => {
        if (questionId) {
            return Request.post(`${API_PATH}/${questionId}/properties`, {
                sizeText: 16,
                sizeTitle: 18,
                sizeImage: SIZE_S,
            });
        }

        return Promise.reject(INTERNAL_ERROR);
    },

    editQuestion: (questionId, questionEditDto) => {
        return Request.put(`${API_PATH}/${questionId}`, questionEditDto);
    },

    setProperties: (questionId, propertiesDto) => {
        if (questionId) {
            return Request.put(`${API_PATH}/${questionId}/properties`, propertiesDto);
        }
        return Promise.reject(INTERNAL_ERROR);
    },

    addTags: (questionId, tagIds) => {
        if (tagIds) {
            return Request.post(`${API_PATH}/${questionId}/tags`, tagIds);
        }
    },
    deleteQuestion: (questionId) => {
        if (questionId) {
            return Request.delete(`${API_PATH}/${questionId}`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    deleteCorrectAnswers: (questionId) => {
        if (questionId) {
            return Request.delete(`${API_PATH}/${questionId}/correct-answer`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    deleteAllAnswers: (questionId) => {
        if (questionId) {
            return Request.delete(`${API_PATH}/${questionId}/answer`);
        }
    },
    addAnswers: (questionId, answers) => {
        if (questionId) {
            return Request.post(`${API_PATH}/${questionId}/answer`, answers);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    addCorrectAnswers: (questionId, answers) => {
        if (questionId && answers) {
            return Request.post(`${API_PATH}/${questionId}/correct-answer`, answers);
        }

        return Request.reject(INTERNAL_ERROR);
    },
    getAllData: (questionId) => {
        if (questionId) {
            return Request.get(`${API_PATH}/${questionId}/all`);
        }
    },
    checkAnswer: (questionId, answers, playerMode, role, id) => {
        if (questionId && answers) {
            if (role === ADMIN_ROLE || role === TEACHER_ROLE) {
                return Request.post(`${API_PATH}/${questionId}/submit-answers-admin`, answers);
            }
            if (playerMode) {
                if (playerMode === ACADEMY_MODE) {
                    return Request.post(
                        `${API_PATH}/${questionId}/submit-answers-academy`,
                        answers,
                    );
                } else if (playerMode === HOMEWORK_MODE) {
                    return Request.post(
                        `${API_PATH}/${questionId}/submit-answers-home-work?teacherHomeWorkId=${id}`,
                        answers,
                    );
                } else if (playerMode === TOURNAMENT_MODE) {
                    return Request.post(
                        `${API_PATH}/${questionId}/submit-answers-tournament?tournamentId=${id}`,
                        answers,
                    );
                }
            }
        }
    },
};
