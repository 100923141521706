import React, {useEffect} from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./NumericSequence.module.sass";

export const NumericSequenceContainer = (props) => {
    const {className, children, onChange, isEditMode, initIndexes, ...controlProps} = props;
    const [indexes, setIndexes] = React.useState([]);

    useEffect(() => {
        console.log(initIndexes);
        if (initIndexes) {
            setIndexes(initIndexes);
        }
    }, [initIndexes]);

    useEffect(() => {
        console.log(indexes);
    }, [indexes]);

    const handleItemClick = (id) => {
        if (isEditMode) {
            return;
        }
        let nextIndexes;
        if (indexes.includes(id)) {
            nextIndexes = [...indexes];
            const currentIndex = indexes.indexOf(id);
            nextIndexes.splice(currentIndex, 1);

            setIndexes(nextIndexes);
        } else {
            nextIndexes = [...indexes, id];
            setIndexes(nextIndexes);
        }

        onChange && onChange(nextIndexes);
    };

    return (
        <div
            className={cn({
                [styles.NumericSequenceContainer]: true,
                [className]: !!className,
            })}
            {...controlProps}
        >
            {React.Children.map(children, (child) =>
                React.cloneElement(child, {
                    onClick: !child.props.disabled && handleItemClick.bind(child, child.props.id),
                    index: indexes.includes(child.props.id)
                        ? indexes.indexOf(child.props.id) + 1
                        : undefined,
                }),
            )}
        </div>
    );
};

NumericSequenceContainer.propTypes = {
    className: PropTypes.string,
    isEditMode: PropTypes.bool,
    onChange: PropTypes.func,
};
