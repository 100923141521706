import {getMultipartFormDataContentTypeHeader} from "core/config/headers";
import {Request} from "core/utils/request";
const API_NAME = `clipping`;

export const ClippingAPI = {
    createQuestion: (questionDto) => {
        return Request.post(`${API_NAME}`, questionDto);
    },
    createQuestionAdmin: (questionDto) => {
        return Request.post(`admin/${API_NAME}`, questionDto);
    },
    editQuestion: (questionId, questionEditDto) => {
        return Request.put(`${API_NAME}/${questionId}`, questionEditDto);
    },
    addQuestionImage: (questionId, fileDto) => {
        const fileData = new FormData();
        fileData.set("file", fileDto.image);
        let areaForCrop = fileDto.areaForCrop;

        return Request.post(
            `${API_NAME}/${questionId}/question-image?height=${areaForCrop.height}&width=${areaForCrop.width}&x=${areaForCrop.x}&y=${areaForCrop.y}`,
            fileData,
            getMultipartFormDataContentTypeHeader(),
        );
    },
    saveCorrectAnswers: (questionId, answers) => {
        return Request.post(`${API_NAME}/${questionId}/correct-answers`, answers);
    },
    deleteCorrectAnswers: (questionId) => {
        return Request.delete(`${API_NAME}/${questionId}/correct-answer`);
    },
    deleteQuestion: (questionId) => {
        return Request.delete(`${API_NAME}/${questionId}`);
    },
    deleteQuestionImage: (questionId) => {
        return Request.delete(`${API_NAME}/${questionId}/question-image`);
    },
    deleteAnswer: (questionId, answerId) => {
        return Request.delete(`${API_NAME}/${questionId}/answer/${answerId}`);
    },
    addImageAnswerFromBank: (questionId, imageBankId) => {
        return Request.post(
            `${API_NAME}/${questionId}/answer/answer-image-bank?imageBankId=${imageBankId}`,
        );
    },
    addImageAnswerFromUpload: (questionId, fileDto) => {
        let params = fileDto.areaForCrop;
        const fileData = new FormData();
        fileData.set("file", fileDto.image);

        return Request.post(
            `${API_NAME}/${questionId}/answer/answer-image-question?height=${params.height}&width=${params.width}&x=${params.x}&y=${params.y}`,
            fileData,
            getMultipartFormDataContentTypeHeader(),
        );
    },
    addTextAnswer: (questionId, text) => {
        return Request.post(`${API_NAME}/${questionId}/answer`, {text});
    },
    getAllData: (questionId) => {
        if (questionId) {
            return Request.get(`${API_NAME}/${questionId}/all`);
        }
    },
    getCorrectAnswers: (questionId) => {
        return Request.get(`${API_NAME}/${questionId}/correct-answer`);
    },
};
