import {Alert} from "antd";
import React from "react";
import EngineCreate from "../../engine-common-components/engine-create/engine-create";
import DictationEngine from "./dictation-engine";
import DictationText from "./text";

export const DictationScript = [
    {
        title: "Задание",
        description: "Создание задания",
        component: <EngineCreate />,
        helpers: [
            {
                component: (
                    <Alert
                        message="Данное название видно только вам. Вы сможете использовать его в поиске своих заданий"
                        type="info"
                        showIcon
                    />
                ),
            },
        ],
    },
    {
        title: "Вопрос",
        description: "Создание вопроса",
        component: <p>QQ</p>,
    },
    {
        title: "Текст",
        description: "Добавляем текст диктанта",
        component: <DictationText />,
    },
    {
        title: "Пропуски",
        description: "Добавляем пропуски",
        component: <DictationEngine />,
    },
    {
        title: "Стилизация",
        description: "Добавляем красоту",
        component: <p>QQ6</p>,
    },
    {
        title: "Правила",
        description: "Добавляем правила",
        component: <p>QQ3</p>,
    },
    {
        title: "Проверка",
        description: "Проверяем свой диктант",
        component: <p>QQ4</p>,
    },
    {
        title: "Параметры",
        description: "Параметры задания",
        component: <p>QQ5</p>,
    },
];
