export const PlayerV2Modes = {
    ACADEMY: "ACADEMY",
    TOURNAMENT: "TOURNAMENT",
    HOMEWORK: "HOMEWORK",
    TEACHER: "TEACHER",
    MODERATION: "MODERATION",
    PREVIEW: "PREVIEW",
    OLD_PREVIEW: "OLD_PREVIEW",
    ACADEMY_HOMEWORK: "ACADEMY_HOMEWORK",
    ARTICLE: "ARTICLE",
};
