import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import styles from "./save-content.module.sass";

export const SaveContent = (props) => {
    return (
        <div
            className={cn({
                [styles.wrapper]: true,
                [props.className]: !!props.className,
            })}
        >
            <div className={styles.container}>{props.children}</div>
        </div>
    );
};

SaveContent.propTypes = {
    className: PropTypes.string,
};
