import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import styles from "entries/constructors/classic-test/views/components/add-question-buttons/add-question-buttons.module.sass";

export const AddQuestionButtons = (props) => {
    const {
        textVisible,
        imageVisible,
        formulaVisible,
        textAction,
        imageAction,
        formulaAction,
        withLabels,
        audioVisible,
        audioAction,
        isMini,
        isSingle,
    } = props;

    return (
        <div
            className={cn({
                [styles.container]: true,
                [styles.container_mini]: isMini,
                [styles.container_single]: isSingle,
            })}
        >
            {textVisible && (
                <div
                    className={cn({
                        [styles.button]: true,
                        [styles.button_mini]: isMini,
                        [styles.button_single]: isSingle,
                    })}
                    onClick={textAction}
                >
                    <i className="fas fa-font" />
                    {withLabels && <p>Текст</p>}
                </div>
            )}
            {imageVisible && (
                <div
                    className={cn({
                        [styles.button]: true,
                        [styles.button_mini]: isMini,
                        [styles.button_single]: isSingle,
                    })}
                    onClick={imageAction}
                >
                    <i className="fas fa-image" />
                    {withLabels && <p>Изображение</p>}
                </div>
            )}
            {formulaVisible && (
                <div
                    className={cn({
                        [styles.button]: true,
                        [styles.button_mini]: isMini,
                        [styles.button_single]: isSingle,
                    })}
                    onClick={formulaAction}
                >
                    <i className="fas fa-bolt" />
                    {withLabels && <p>Формула</p>}
                </div>
            )}
            {audioVisible && (
                <div
                    className={cn({
                        [styles.button]: true,
                        [styles.button_mini]: isMini,
                        [styles.button_single]: isSingle,
                    })}
                    onClick={audioAction}
                >
                    <i className="fas fa-music" />
                    {withLabels && <p>Аудио</p>}
                </div>
            )}
        </div>
    );
};

AddQuestionButtons.propTypes = {
    audioAction: PropTypes.func,
    audioVisible: PropTypes.bool,
    formulaAction: PropTypes.func,
    formulaVisible: PropTypes.bool,
    imageAction: PropTypes.func,
    imageVisible: PropTypes.bool,
    isMini: PropTypes.bool,
    isSingle: PropTypes.bool,
    textAction: PropTypes.func,
    textVisible: PropTypes.bool,
    withLabels: PropTypes.bool,
};

AddQuestionButtons.defaultProps = {
    withLabels: true,
};
