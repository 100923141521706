import cn from "classnames";

import React from "react";
import {useHistory} from "react-router-dom";
import {auth} from "../../core/utils/auth";
import {ACADEMY_ROUTES} from "../../routes/main";

import styles from "./styles.module.sass";
import {useStores} from "../../hooks/use-stores";

const EnterButton = ({className}: any) => {
    const {keycloak} = auth;
    const {appStore} = useStores();
    let history = useHistory();

    return (
        <React.Fragment>
            {!appStore.isAuthorized ? (
                <p
                    className={cn({
                        [styles.buttonEnter]: true,
                        [className]: !!className,
                    })}
                    onClick={() => keycloak.login()}
                >
                    ВХОД
                </p>
            ) : (
                <p
                    className={cn({
                        [styles.buttonEnter]: true,
                        [className]: !!className,
                    })}
                    onClick={() => history.push(ACADEMY_ROUTES.ROOT)}
                >
                    Полетели учиться!
                </p>
            )}
        </React.Fragment>
    );
};

export default EnterButton;
