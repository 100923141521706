import React from "react";

import {STEP} from "../constants";

export const useWizard = (props = {}) => {
    const {
        stepsOrder = [STEP.QUESTION, STEP.ANSWER, STEP.PARAMS],
        defaultEnabledSteps = [STEP.QUESTION],
        defaultStep = STEP.QUESTION,
    } = props;

    const [currentStep, setCurrentStep] = React.useState(defaultStep);
    const [enabledSteps, setEnabledSteps] = React.useState(defaultEnabledSteps);

    const toNext = () => {
        const nextStep = stepsOrder.indexOf(currentStep) + 1;
        if (stepsOrder.length > nextStep) {
            setCurrentStep(stepsOrder[nextStep]);
        }
    };

    const toPrev = () => {
        const prevStep = stepsOrder.indexOf(currentStep) - 1;
        if (prevStep > 0) {
            setCurrentStep(stepsOrder[prevStep]);
        }
    };

    const to = (step) => {
        if (stepsOrder.includes(step)) setCurrentStep(step);
    };

    const enableStep = (step) => {
        if (step && !enabledSteps.includes(step)) {
            const nextEnabledSteps = [...enabledSteps, step];
            setEnabledSteps(nextEnabledSteps);
        }
    };

    const enableSteps = (steps) => {
        if (Array.isArray(steps)) {
            const nextEnabledSteps = new Set([...enabledSteps, ...steps]);
            const nextState = [...nextEnabledSteps];
            setEnabledSteps(nextState);
        } else {
            enableStep(steps);
        }
    };

    const disableStep = (step) => {
        if (step && enabledSteps.includes(step)) {
            const nextEnabledSteps = [...enabledSteps];
            nextEnabledSteps.splice(nextEnabledSteps.indexOf(step), 1);
            setEnabledSteps(nextEnabledSteps);
        }
    };

    const disableSteps = (steps) => {
        if (Array.isArray(steps)) {
            const nextEnabledSteps = [...enabledSteps];
            steps.every((step) => {
                nextEnabledSteps.splice(nextEnabledSteps.indexOf(step), 1);
            });

            setEnabledSteps(nextEnabledSteps);
        } else {
            disableStep(steps);
        }
    };

    return {
        STEP,
        stepsOrder,
        currentStep,
        toNext,
        toPrev,
        to,
        enabledSteps,
        enableStep,
        enableSteps,
        disableStep,
        disableSteps,
    };
};
