import Hints from "entries/hints/hints";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import {TextSequencePlayer} from "entries/player/views/task-players/text-sequence-player/text-sequence-player";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export const TextSequencePlayerContainer = ({
    taskId,
    onSelectCorrectAnswer,
    questionApi,
    getInfoAboutSet,
    playerMode,
    answerIsLoading,
    setAnswerIsLoading,
}) => {
    const [question, setQuestion] = useState(null);
    const [answers, setAnswers] = useState(null);
    const [properties, setProperties] = useState(null);

    let {id} = useParams();

    useEffect(() => {
        getData();
    }, [taskId]);

    const getData = async () => {
        setAnswerIsLoading(true);
        let data = undefined;
        await questionApi(taskId, id)
            .then((response) => {
                if (playerMode === PlayerV2Modes.TOURNAMENT) {
                    data = response.data[0].question;
                } else {
                    data = response.data;
                    getInfoAboutSet();
                }
            })
            .catch(() => console.log("load data error"))
            .finally(() => setAnswerIsLoading(false));

        if (!data) {
            return;
        }

        setQuestion(data.sequenceTest);
        setAnswers(data.sequenceTestAnswerVariants);
        setProperties(data.questionProperties);
    };

    const handleSave = (answers) => {
        setAnswers(answers);
        onSelectCorrectAnswer(answers);
    };

    useEffect(() => {
        onSelectCorrectAnswer(answers);
    }, [answers]);

    const fillCorrectAnswers = (answers) => {
        let correctAnswers = answers.map((answer) => answer.correctAnswer);
        setAnswers(correctAnswers);
    };

    return (
        <React.Fragment>
            {!answerIsLoading && (
                <React.Fragment>
                    {playerMode === PlayerV2Modes.ACADEMY && (
                        <Hints question={question} onBuyCorrectAnswer={fillCorrectAnswers} />
                    )}

                    {question && answers && properties && (
                        <TextSequencePlayer
                            question={question}
                            answers={answers}
                            properties={properties}
                            setAnswers={handleSave}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

TextSequencePlayerContainer.propTypes = {
    getInfoAboutSet: PropTypes.func,
    playerMode: PropTypes.string,
    questionApi: PropTypes.func,
    taskId: PropTypes.number,
    onSelectCorrectAnswer: PropTypes.func,
    answerIsLoading: PropTypes.bool,
    setAnswerIsLoading: PropTypes.func,
};
