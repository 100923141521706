import React, {useState} from "react";
import styles from "./styles.module.sass";
import {MDBBtn} from "mdbreact";
import {SuperAdminAPI} from "../../../../core/api/super-admin";
import {toast} from "react-toastify";
import {UserAPI} from "../../../../core/api/user";
import {IUserInfo} from "../../../../interfaces/IUserInfo";
import SelectSubscription from "../../../select-subscription/select-subscription";
import {SubscriptionsAPI} from "../../../../core/api/subcriptions";
import SpinnerWithText from "../../../spinner/spinner";

const AdminPanelSubsBulkGive = () => {
    const [loadings, setLoadings] = useState({
        isGetUserRoleLoading: false,
        isGetUserLoading: false,
        isGiveSubscriptionLoading: false,
        isGetStudentsInClassLoading: false,
        isGetStudentsLoading: false,
    });
    const [userId, setUserId] = useState("");
    const [userRole, setUserRole] = useState(undefined);
    const [user, setUser] = useState<IUserInfo | undefined>(undefined);
    const [selectedSubscription, setSelectedSubscription] = useState(0);
    const [studentsInClass, setStudentsInClass] = useState<any>([]);
    const [, setStudentsAccepted] = useState<any>([]);

    const handleGetUser = () => {
        setLoadings({...loadings, isGetUserRoleLoading: true});
        SuperAdminAPI.getUserRole(+userId)
            .then(async (response) => {
                if (response.data && response.data[0]) {
                    if (response.data[0] === "TEACHER") {
                        await handleGetStudentsAcceptedByTeacher();
                        await handleGetStudentsInClassByTeacher();
                    }
                    setUserRole(response.data[0]);
                    await handleGetUserProfile();
                }
            })
            .catch(() => toast.error("Ошибка загрузки"))
            .finally(() => {
                setLoadings({...loadings, isGetUserRoleLoading: false});
            });
    };

    const handleGetStudentsInClassByTeacher = () => {
        return SuperAdminAPI.getStudentsInClassByTeacher(+userId)
            .then((response) => {
                setStudentsInClass(response.data);
            })
            .catch(() => toast.error("Ошибка получения учеников в классе"));
    };

    const handleGetStudentsAcceptedByTeacher = () => {
        return SuperAdminAPI.getStudentsAcceptedByTeacher(+userId)
            .then((response) => {
                setStudentsInClass(response.data);
            })
            .catch(() => toast.error("Ошибка получения учеников в классе"));
    };

    const handleGetUserProfile = () => {
        return UserAPI.getUserProfileById(+userId)
            .then((response: any) => setUser(response.data))
            .catch(() => toast.error("Ошибка загрузки профиля"));
    };

    const handleGiveSubscription = () => {
        SubscriptionsAPI.giveSubscription(+userId, selectedSubscription)
            .then(() => toast.success("Подписка выдана"))
            .catch(() => toast.error("Ошибка выдачи подписки"));
    };

    const handleBulkGiveSubscriptions = (users: any[]) => {
        let confirm = window.confirm(`Вы действительно хотите включить подписки?`);
        if (!confirm) {
            return;
        }

        let requests: any = [];

        users.forEach((user) => {
            requests.push(SubscriptionsAPI.giveSubscription(user.id, selectedSubscription));
        });

        Promise.allSettled(requests)
            .then(() => toast.success(`Успешно`))
            .catch(() => toast.error(`Ошибка`));
    };

    const handleReset = () => {
        setUserRole(undefined);
        setUserId("");
        setUser(undefined);
        setSelectedSubscription(0);
        setStudentsInClass([]);
        setStudentsAccepted([]);
    };

    if (loadings.isGetUserRoleLoading) {
        return <SpinnerWithText text="Загрузка..." />;
    }

    return (
        <div className={styles.wrapper}>
            {user ? (
                <div>
                    <p className="title">
                        Пользователь: {user.userDto?.firstName} {user.userDto?.lastName}
                    </p>
                    <p className="title">
                        Роль:{" "}
                        {userRole === "TEACHER"
                            ? "Учитель"
                            : userRole === "STUDENT"
                            ? "Ученик"
                            : "Незвестная роль"}
                    </p>
                    <p className="title mb-3">Класс: {user.classTag?.name}</p>

                    <hr />
                    <div className="mb-3">
                        <p className="title">Подписка для выдачи</p>
                        <SelectSubscription onSelect={setSelectedSubscription} />

                        <hr />
                    </div>

                    {userRole === "STUDENT" && (
                        <div className="mb-3">
                            <MDBBtn
                                onClick={handleGiveSubscription}
                                color="success"
                                disabled={!selectedSubscription}
                                className="mt-3 mb-3"
                            >
                                Включить подписку
                            </MDBBtn>

                            <hr />
                        </div>
                    )}

                    {userRole === "TEACHER" && (
                        <div className="mb-3">
                            <p className="title">Данные по учителю</p>

                            <React.Fragment>
                                <p className="title">По классам:</p>
                                {studentsInClass.length > 0 ? (
                                    <React.Fragment>
                                        {studentsInClass.map((el: any) => (
                                            <div className={styles.classRow} key={el.id}>
                                                <p className="title">
                                                    {el.classNumber} {el.classLetter}
                                                </p>
                                                <p>Учеников в классе: {el.students.length}</p>

                                                <MDBBtn
                                                    size="sm"
                                                    onClick={() =>
                                                        handleBulkGiveSubscriptions(el.students)
                                                    }
                                                    color="success"
                                                    disabled={!selectedSubscription}
                                                >
                                                    Включить подписки для {el.classNumber}{" "}
                                                    {el.classLetter}
                                                </MDBBtn>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                ) : (
                                    <p>Нет данных</p>
                                )}
                            </React.Fragment>
                        </div>
                    )}

                    <MDBBtn color="warning" onClick={handleReset}>
                        Сброс
                    </MDBBtn>
                </div>
            ) : (
                <div>
                    <p className="title">ID Пользователя</p>
                    <input
                        type="text"
                        className="form-control"
                        value={userId || ""}
                        onChange={(e) => setUserId(e.target.value)}
                    />

                    <MDBBtn color="success" onClick={handleGetUser} disabled={!userId}>
                        Загрузить
                    </MDBBtn>
                </div>
            )}
        </div>
    );
};

export default AdminPanelSubsBulkGive;
