import React from "react";
import PropTypes from "prop-types";
import { MDBIcon } from "mdbreact";
import cn from "classnames"
import styles from "./icons.module.sass"

/**
 * Эмулятор HTML checkbox
 * @param {Object} props
 * @param {boolean} props.checked
 * @param {Function} props.onClick
 * @param {string} [props.className]
 * @param {string} [props.containerClassName]
 * @param {string} [props.labelClassName]
 * @param {string} [props.label]
 * @param {string} [props.size]
 */
export function CheckboxIcon({checked, onClick, size="lg", className, containerClassName, labelClassName, label}) {
	const _containerClassName = ['cursor-pointer', 'd-iflex-aic']
	if (containerClassName) _containerClassName.push(containerClassName)

	const _labelClassName = ['ml-2']
	if (labelClassName) _labelClassName.push(labelClassName)

	return (
		<span onClick={onClick} className={_containerClassName.join(' ')}>
			<MDBIcon far icon={checked ? 'check-square' : 'square'} size={size} className={cn({
                [styles.checkBox]: true,
                [className]: !!className
            })} />
			{label && <span className={_labelClassName.join(' ')}>{label}</span>}
		</span>
	)
}

CheckboxIcon.propTypes = {
	"checked": PropTypes.bool.isRequired,
	"onClick": PropTypes.func.isRequired,
	"className": PropTypes.string,
	"containerClassName": PropTypes.string,
	"label": PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
	"labelClassName": PropTypes.string,
	"size": PropTypes.oneOf(['lg', '2x', '3x']),
}
