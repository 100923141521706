import {CLASSIC_TEST_ROUTES} from "entries/constructors/classic-test/views/routes";
import {DICTATION_TEST_ROUTES} from "entries/constructors/dictation/views/routes";
import {OPEN_ANSWER_TEST_ROUTES} from "entries/constructors/open-answer/routes";
import {PAIRS_TEST_ROUTES} from "entries/constructors/pairs-test";
import {TEXT_SEQUENCE_TEST_ROUTES} from "entries/constructors/sequences/sequence-test/routes";
import {NUMERIC_SEQUENCE_TEST_ROUTES} from "entries/constructors/sequences/numeric-sequence-test/routes";
import {SENTENCES_SKIP_TEST_ROUTES} from "entries/constructors/word-skip";
import {
    WORD_SKIP_ADDITIONAL_TEST_ROUTES,
    WORD_SKIP_TEST_ROUTES,
} from "entries/constructors/word-skip/routes";

export const ADMIN_ROUTES = {
    ROOT: "/admin",
    IMAGES: {
        ROOT: "/admin/images",
        LIBRARY: "/admin/images/library",
        UPLOAD: "/admin/images/upload",
    },
    NEW: "/admin/new",
    IMAGES_LIBRARY: "/admin/library-images",
    IMAGES_UPLOAD: "/admin/upload-images",
    TAG_MANAGER: "/admin/tag-manager",
    TAGS: "/admin/tags",
    TEXT_UPLOAD: "/admin/upload-text",
    USERS: "/admin/users",
    TEST: {
        ROOT: "/admin/test",
        ANY: "/admin/:section",
        CREATE: {
            ROOT: "/admin/test/create",
            CLASSIC: "/admin/test/create/classic",
        },
        EDIT: "/admin/test/:id",
        LIST: "/admin/test/list",
    },
    CREATE_TEST: "/admin/create-test/",
    CREATE_SYMBOLS: "/admin/create-symbols",
    THEMES_LIST: "/admin/themes-list",
    CONSTRUCTORS: {
        ROOT: "/admin/create-task",
    },
    CLASSIC_TEST: {
        ROOT: `/admin${CLASSIC_TEST_ROUTES.ROOT}`,
        CREATE_TEST: `/admin${CLASSIC_TEST_ROUTES.CREATE_TEST}`,
        CONSTRUCTOR: `/admin${CLASSIC_TEST_ROUTES.CONSTRUCTOR}`,
        EDITING: `/admin${CLASSIC_TEST_ROUTES.EDITING}`,
    },
    DICTATION: {
        ROOT: `/admin${DICTATION_TEST_ROUTES.ROOT}`,
        CREATE_TEST: `/admin${DICTATION_TEST_ROUTES.CREATE_TEST}`,
        CONSTRUCTOR: `/admin${DICTATION_TEST_ROUTES.CONSTRUCTOR}`,
        EDITING: `/admin${DICTATION_TEST_ROUTES.EDITING}`,
    },
    TEXT_SEQUENCE_TEST: {
        ROOT: `/admin${TEXT_SEQUENCE_TEST_ROUTES.ROOT}`,
        CREATE_TEST: `/admin${TEXT_SEQUENCE_TEST_ROUTES.CREATE_TEST}`,
        CONSTRUCTOR: `/admin${TEXT_SEQUENCE_TEST_ROUTES.CONSTRUCTOR}`,
        EDITING: `/admin${TEXT_SEQUENCE_TEST_ROUTES.EDITING}`,
    },
    NUMERIC_SEQUENCE_TEST: {
        ROOT: `/admin${NUMERIC_SEQUENCE_TEST_ROUTES.ROOT}`,
        CREATE_TEST: `/admin${NUMERIC_SEQUENCE_TEST_ROUTES.CREATE_TEST}`,
        CONSTRUCTOR: `/admin${NUMERIC_SEQUENCE_TEST_ROUTES.CONSTRUCTOR}`,
        EDITING: `/admin${NUMERIC_SEQUENCE_TEST_ROUTES.EDITING}`,
    },
    PAIRS_TEST: {
        ROOT: `/admin${PAIRS_TEST_ROUTES.ROOT}`,
        CREATE_TEST: `/admin${PAIRS_TEST_ROUTES.CREATE_TEST}`,
        CONSTRUCTOR: `/admin${PAIRS_TEST_ROUTES.CONSTRUCTOR}`,
        EDITING: `/admin${PAIRS_TEST_ROUTES.EDITING}`,
    },
    WORD_SKIP_TEST: {
        ROOT: `/admin${WORD_SKIP_TEST_ROUTES.ROOT}`,
        CREATE_TEST: `/admin${WORD_SKIP_TEST_ROUTES.CREATE_TEST}`,
        CONSTRUCTOR: `/admin${WORD_SKIP_TEST_ROUTES.CONSTRUCTOR}`,
        EDITING: `/admin${WORD_SKIP_TEST_ROUTES.EDITING}`,
    },
    WORD_SKIP_ADDITIONAL_TEST: {
        ROOT: `/admin${WORD_SKIP_ADDITIONAL_TEST_ROUTES.ROOT}`,
        CREATE_TEST: `/admin${WORD_SKIP_ADDITIONAL_TEST_ROUTES.CREATE_TEST}`,
        CONSTRUCTOR: `/admin${WORD_SKIP_ADDITIONAL_TEST_ROUTES.CONSTRUCTOR}`,
        EDITING: `/admin${WORD_SKIP_ADDITIONAL_TEST_ROUTES.EDITING}`,
    },
    OPEN_ANSWER_TEST: {
        ROOT: `/admin${OPEN_ANSWER_TEST_ROUTES.ROOT}`,
        CREATE_TEST: `/admin${OPEN_ANSWER_TEST_ROUTES.CREATE_TEST}`,
        CONSTRUCTOR: `/admin${OPEN_ANSWER_TEST_ROUTES.CONSTRUCTOR}`,
        EDITING: `/admin${OPEN_ANSWER_TEST_ROUTES.EDITING}`,
    },
    SENTENCE_TEST: {
        ROOT: `/admin${SENTENCES_SKIP_TEST_ROUTES.ROOT}`,
        CREATE_TEST: `/admin${SENTENCES_SKIP_TEST_ROUTES.CREATE_TEST}`,
        CONSTRUCTOR: `/admin${SENTENCES_SKIP_TEST_ROUTES.CONSTRUCTOR}`,
        EDITING: `/admin${SENTENCES_SKIP_TEST_ROUTES.EDITING}`,
    },
    BASKETS_TEST: {
        ROOT: `/admin/baskets-game`,
        CREATE_TEST: `/admin/baskets-game/create`,
        CONSTRUCTOR: `/admin/baskets-game/constructor`,
        EDITING: `/admin/baskets-game/editing/:id`,
    },
    CLIPPING_TEST: {
        ROOT: `/admin/clipping-game`,
        CREATE_TEST: `/admin/clipping-game/create`,
        CONSTRUCTOR: `/admin/clipping-game/constructor`,
        EDITING: `/admin/clipping-game/editing/:id`,
    },
    CLIPPING_SECONDARY_TEST: {
        ROOT: `/admin/clipping-secondary-game`,
        CREATE_TEST: `/admin/clipping-secondary-game/create`,
        CONSTRUCTOR: `/admin/clipping-secondary-game/constructor`,
        EDITING: `/admin/clipping-secondary-game/editing/:id`,
    },
    MANY_TO_MANY_DND_TEST: {
        ROOT: `/admin/many-to-many-dnd-test`,
        CREATE_TEST: `/admin/many-to-many-dnd-test/create`,
        CONSTRUCTOR: `/admin/many-to-many-dnd-test/constructor`,
        EDITING: `/admin/many-to-many-dnd-test/editing/:id`,
    },
    DRAG_AREA_TEST: {
        ROOT: `/admin/drag-area`,
        CREATE_TEST: `/admin/drag-area/create`,
        CONSTRUCTOR: `/admin/drag-area/constructor`,
    },
    TOURNAMENTS: {
        ROOT: "/admin/tournaments",
        CREATE: "/admin/tournaments/new/:type",
        EDIT: "/admin/tournaments/:type/:id/edit",
    },
    EVENTS: {
        ROOT: "/admin/events",
        CREATE: "/admin/events/create",
        EDIT: "/admin/events/edit/:id",
    },
    REWARDS: "/admin/rewards",
    NARRATORS: "/admin/narrators",
    EDITING: "/admin/editing",
    TICKETS: "/admin/tickets",
    DOCS: "/admin/docs",
    ARTICLES: {
        ROOT: "/admin/articles",
        CREATE: "/admin/articles/create",
        EDIT: "/admin/articles/edit/:id",
    },
};
