import {Request} from "core/utils/request";

const INTERNAL_ERROR = "error.internal";

export const SymbolsAPI = {
    getSymbols: () => {
        return Request.get(`symbol`);
    },
    getSymbolsBySymbolGroup: (groupId) => {
        return Request.get(`symbol-group/${groupId}/symbols`);
    },
    addSymbol: (symbolDto) => {
        if (symbolDto) {
            return Request.post(`symbol`, symbolDto);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    editSymbol: (symbolId, symbolDto) => {
        return Request.put(`symbol/${symbolId}`, symbolDto);
    },
    getGroups: (page = 1) => {
        return Request.get(`symbol-group?page=${page}`);
    },
    getDictationGroupsForSymbol: (symbolId) => {
        if (symbolId) {
            return Request.get(`symbol/${symbolId}/group-for-dictation`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    addSymbolToGroup: (groupId, symbolDto) => {
        if (groupId) {
            return Request.post(`symbol-group/${groupId}/symbol`, symbolDto);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
};
