import {convertTextWithSymbols} from "core/utils/converter";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
    MDBBtn,
    MDBContainer,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
} from "mdbreact";

import styles from "./text-upload-modal.module.sass";
import cn from "classnames";

export const TextUploadModal = ({
    saveText,
    toggle,
    visible,
    itemForEdit,
    maxSymbols = 500,
    questionText,
    isLoading,
    text,
    withoutInputCheck,
}) => {
    const [value, setValue] = useState("");

    const onChange = (event) => {
        setValue(convertTextWithSymbols(event.target.value));
    };

    const onSaveText = () => {
        if (itemForEdit) {
            saveText({text: value.trim(), id: itemForEdit.id, type: itemForEdit.type});
        } else {
            saveText(value.trim());
        }
        setValue("");
    };

    useEffect(() => {
        if (text) {
            setValue(text);
            return;
        }

        if (itemForEdit) {
            setValue(itemForEdit.text);
        } else {
            setValue("");
        }
    }, [itemForEdit, text]);

    useEffect(() => {
        questionText && setValue(questionText);
    }, [questionText, visible]);

    return (
        <MDBContainer style={{zIndex: 6000, position: "relative"}}>
            <MDBModal
                isOpen={visible}
                toggle={toggle}
                size="lg"
                autoFocus
                overflowScroll={false}
                centered
            >
                <MDBModalHeader toggle={toggle} className="modal-header">
                    <p>Добавление текста</p>
                </MDBModalHeader>
                <MDBModalBody className="modal-body">
                    <div
                        className={cn({
                            [styles.container]: true,
                            "col-12": true,
                        })}
                    >
                        <div className="col-8 p-0">
                            <textarea
                                cols="60"
                                rows="8"
                                value={convertTextWithSymbols(value)}
                                onChange={(event) => onChange(event)}
                            />
                        </div>
                        <div className="col-4">
                            <p>
                                Наберите текст с клавиатуры или скопируйте из любого текстового
                                редактора.
                            </p>
                            <hr />
                            <p>Символов осталось: {maxSymbols - value?.length}</p>
                        </div>
                    </div>
                </MDBModalBody>
                <MDBModalFooter className="modal-footer">
                    <MDBBtn
                        color="success"
                        disabled={
                            !withoutInputCheck &&
                            (value?.length > maxSymbols || isLoading || !value?.trim())
                        }
                        onClick={onSaveText}
                    >
                        Сохранить
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </MDBContainer>
    );
};

TextUploadModal.propTypes = {
    saveText: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    itemForEdit: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    maxSymbols: PropTypes.number,
    questionText: PropTypes.string,
    toggle: PropTypes.func,
    visible: PropTypes.bool,
    withoutInputCheck: PropTypes.bool,
};
