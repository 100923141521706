import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.sass";

const ItemCoords = ({item, handleUpdateCoords}) => {
    return (
        <div className={styles.container}>
            {item.left && (
                <div className={styles.coords}>
                    <div>
                        <p>X:</p>
                    </div>

                    <input
                        className="form-control"
                        value={item.left}
                        onChange={(e) => handleUpdateCoords(parseInt(e.target.value), item.top)}
                    />
                </div>
            )}

            {item.top && (
                <div className={styles.coords}>
                    <div>
                        <p>Y:</p>
                    </div>

                    <input
                        className="form-control"
                        value={item.top}
                        onChange={(e) => handleUpdateCoords(item.left, parseInt(e.target.value))}
                    />
                </div>
            )}
        </div>
    );
};

ItemCoords.propTypes = {
    handleUpdateCoords: PropTypes.func,
    item: PropTypes.object,
};

export default ItemCoords;
