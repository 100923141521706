import {Request} from "core/utils/request";

export const ExpertiseAPI = {
    createExpertise: (taskId, expertiseDto, themeId) => {
        return Request.post(
            `question/${taskId}/expertise${themeId ? `?themeId=${themeId}` : ""}`,
            expertiseDto,
        );
    },
};
