import {SKIP_ID} from "entries/constructors/word-skip/views/text-with-skips/text-with-skips-container";

export const SkipsToSymbols = (skips) => {
    let swappedIndexes = skips.map((skip) => {
        if (Array.isArray(skip)) {
            return SKIP_ID;
        }

        return skip;
    });

    return swappedIndexes;
};

export const TextWithSkipSymbol = (skips, text) => {
    let skipsWithSymbols = SkipsToSymbols(skips);
    text = text.split(" ");
    let textWithSkips = skipsWithSymbols.map((wordIndex) => {
        if (wordIndex === SKIP_ID) {
            return `${(text[wordIndex] = SKIP_ID)}`;
        }

        return `${text[wordIndex]}`;
    });

    return textWithSkips;
};
