import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import styles from "entries/constructors/classic-test/views/components/properties-buttons/properties-buttons.module.sass";

export const PARAMETERS = "PARAMETERS";
export const BACKGROUNDS = "BACKGROUNDS";
export const NARRATORS = "NARRATORS";

export const PropertiesButtons = (props) => {
    const {handleClick, currentChoose} = props;

    return (
        <div
            className={cn({
                "btn-group": true,
                [styles.container]: true,
                [props.className]: !!props.className,
            })}
            role="group"
            aria-label="property-column"
        >
            <button
                type="button"
                className={cn({
                    btn: true,
                    "btn-md": true,
                    [styles.active]: currentChoose === PARAMETERS,
                })}
                onClick={() => handleClick(PARAMETERS)}
            >
                Параметры
            </button>
            <button
                type="button"
                className={cn({
                    btn: true,
                    "btn-md": true,
                    [styles.active]: currentChoose === BACKGROUNDS,
                })}
                onClick={() => handleClick(BACKGROUNDS)}
            >
                Фон
            </button>
            <button
                type="button"
                className={cn({
                    btn: true,
                    "btn-md": true,
                    [styles.active]: currentChoose === NARRATORS,
                })}
                onClick={() => handleClick(NARRATORS)}
            >
                Персонаж
            </button>
        </div>
    );
};

PropertiesButtons.propTypes = {
    className: PropTypes.string,
    currentChoose: PropTypes.string,
    handleClick: PropTypes.func,
};
