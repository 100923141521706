import cn from "classnames";
import SchoolClassesSelectContainer from "entries/school-classes-select/school-classes-select-container";
import SchoolThemesSelect from "entries/school-themes-select/school-themes-select";
import TagList from "entries/tags/views/tag-list/tag-list";
import TaskFeedbackModal from "entries/task-feedback-modal/task-feedback-modal";
import Thumbler from "entries/thumbler/thumbler";
import {useStores} from "hooks/use-stores";
import {MDBAlert, MDBBtn, MDBRow} from "mdbreact";
import PropTypes from "prop-types";
import React from "react";
import StarRatings from "react-star-ratings";
import styles from "./styles.module.sass";

const PlayerV2Moderation = ({
    qualityRating,
    setQualityRating,
    editRating,
    setEditRating,
    funRating,
    setFunRating,
    plagiarismRating,
    setPlagiarismRating,
    selectedThemeValue,
    setSelectedThemeValue,
    setGradeTagId,
    gradeTagId,
    handleEvaluate,
    taskId,
    comment,
    setComment,
    setTheme,
    isLoading,
    theme,
    answer,
    handleCheckAnswer,
    resultIsLoading,
    tags,
    themeTags,
    taskExpertiseData,
    selectedQuestion,
    isCreator,
}) => {
    const {taskExpertise} = taskExpertiseData;
    const {appStore} = useStores();

    if (taskExpertise) {
        return (
            <div className={styles.ratingContainer}>
                <div className={styles.rating}>
                    <MDBAlert color="info">
                        <h6> Вы уже оценили данное задание</h6>
                        <div>
                            <p>Качество: {taskExpertise.quality}</p>
                            <p>Оформление: {taskExpertise.edit}</p>
                            <p>Увлекательность: {taskExpertise.fun}</p>
                            <p>Плагиат: {taskExpertise.plagiarism}</p>
                        </div>
                    </MDBAlert>
                </div>

                <div className={styles.bottomContainer}>
                    {selectedQuestion && (
                        <MDBAlert>
                            <p
                                className="link text-center"
                                style={{fontSize: "1rem"}}
                                onClick={() =>
                                    appStore.addModalToQueue(
                                        <TaskFeedbackModal
                                            toggle={appStore.closeActiveModal}
                                            key={"task-feedback-modal toggle-disabled"}
                                            question={selectedQuestion}
                                        />,
                                    )
                                }
                            >
                                Сообщить об ошибке
                            </p>
                        </MDBAlert>
                    )}

                    <MDBBtn
                        color={!answer ? "blue-grey" : "success"}
                        className={styles.buttonAnswer}
                        onClick={handleCheckAnswer}
                        disabled={!answer || resultIsLoading}
                    >
                        {resultIsLoading ? "Проверяю..." : "Ответить"}
                    </MDBBtn>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.ratingContainer}>
            {tags?.length && (
                <TagList tags={[...tags, ...themeTags]} isCards className={styles.tags} />
            )}

            <div className={styles.rating}>
                <p
                    className={cn({
                        title: true,
                        [styles.title]: true,
                    })}
                >
                    Оцените задание #{taskId}
                </p>

                <MDBRow className={styles.ratingRow}>
                    <p className={styles.subTitle}>Качество</p>
                    <StarRatings
                        rating={qualityRating}
                        changeRating={setQualityRating}
                        numberOfStars={3}
                        starRatedColor="gold"
                        starHoverColor="gold"
                        starDimension={"24px"}
                        starSpacing={"3px"}
                        name="rating"
                    />
                </MDBRow>

                <MDBRow className={styles.ratingRow}>
                    <p className={styles.subTitle}>Оформление</p>
                    <StarRatings
                        rating={editRating}
                        changeRating={setEditRating}
                        numberOfStars={3}
                        starRatedColor="gold"
                        starHoverColor="gold"
                        starDimension={"24px"}
                        starSpacing={"3px"}
                        name="rating"
                    />
                </MDBRow>

                <MDBRow className={styles.ratingRow}>
                    <p className={styles.subTitle}>Увлекательность</p>
                    <StarRatings
                        rating={funRating}
                        changeRating={setFunRating}
                        numberOfStars={3}
                        starRatedColor="gold"
                        starHoverColor="gold"
                        starDimension={"24px"}
                        starSpacing={"3px"}
                        name="rating"
                    />
                </MDBRow>

                <MDBRow className={styles.ratingRow}>
                    <p className={styles.subTitle}>Плагиат</p>
                    <StarRatings
                        rating={plagiarismRating}
                        changeRating={setPlagiarismRating}
                        numberOfStars={3}
                        starRatedColor="gold"
                        starHoverColor="gold"
                        starDimension={"24px"}
                        starSpacing={"3px"}
                        name="rating"
                    />
                </MDBRow>

                <p
                    className={cn({
                        title: true,
                        [styles.title]: true,
                    })}
                >
                    Проверьте тему
                </p>
                <div className={styles.additionalInfo}>
                    <div className={styles.ratingRow}>
                        <p className={styles.subTitle}>Подходит к теме</p>
                        <Thumbler
                            options={[
                                {value: 1, name: "Да"},
                                {value: 0, name: "Нет"},
                            ]}
                            activeState={selectedThemeValue}
                            setActiveState={setSelectedThemeValue}
                        />
                    </div>

                    {selectedThemeValue === 0 && (
                        <div className={styles.themeTitle}>
                            <p className={styles.subTitle}>Выберите другую тему</p>
                            <div className="mb-2">
                                <SchoolClassesSelectContainer
                                    handleSelect={(id) => setGradeTagId(id)}
                                    selectValue={gradeTagId}
                                />
                            </div>

                            {}

                            <SchoolThemesSelect
                                isMulti={false}
                                classes={[gradeTagId]}
                                subjects={[]}
                                handleSelectTheme={setTheme}
                                theme={theme}
                            />
                        </div>
                    )}

                    <div
                        className={cn({
                            "modal-body": true,
                            "p-0": true,
                            "mt-3": true,
                            [styles.comment]: true,
                            [styles.themeTitle]: true,
                        })}
                    >
                        <p className="title text-center">Добавьте комментарий</p>
                        <textarea
                            className="w-100"
                            rows="4"
                            placeholder="Оставьте пустым, если замечаний и ссылки на плагиат нет."
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>

                    <MDBBtn
                        color="success"
                        className={styles.send}
                        onClick={() =>
                            handleEvaluate({
                                qualityRating,
                                editRating,
                                funRating,
                                plagiarismRating,
                                selectedThemeValue,
                                gradeTagId,
                            })
                        }
                        disabled={isLoading}
                    >
                        Оценить
                    </MDBBtn>
                </div>
            </div>

            <div className={styles.bottomContainer}>
                {selectedQuestion && !isCreator && (
                    <MDBAlert>
                        <p
                            className="link text-center"
                            style={{fontSize: "1rem"}}
                            onClick={() =>
                                appStore.addModalToQueue(
                                    <TaskFeedbackModal
                                        toggle={appStore.closeActiveModal}
                                        key={"task-feedback-modal toggle-disabled"}
                                        question={selectedQuestion}
                                        isTeacherQuestion
                                    />,
                                )
                            }
                        >
                            Сообщить об ошибке
                        </p>
                    </MDBAlert>
                )}

                <MDBBtn
                    color={!answer ? "blue-grey" : "success"}
                    className={styles.buttonAnswer}
                    onClick={handleCheckAnswer}
                    disabled={!answer || resultIsLoading}
                >
                    {resultIsLoading ? "Проверяю..." : "Ответить"}
                </MDBBtn>
            </div>
        </div>
    );
};

PlayerV2Moderation.propTypes = {
    answer: PropTypes.any,
    comment: PropTypes.string,
    editRating: PropTypes.number,
    funRating: PropTypes.number,
    gradeTagId: PropTypes.any,
    handleCheckAnswer: PropTypes.func,
    handleEvaluate: PropTypes.func,
    isCreator: PropTypes.bool,
    isExpertiseSubmitted: PropTypes.bool,
    isLoading: PropTypes.bool,
    plagiarismRating: PropTypes.number,
    qualityRating: PropTypes.number,
    resultIsLoading: PropTypes.bool,
    selectedThemeValue: PropTypes.any,
    setComment: PropTypes.func,
    setEditRating: PropTypes.func,
    setFunRating: PropTypes.func,
    setGradeTagId: PropTypes.func,
    setPlagiarismRating: PropTypes.func,
    setQualityRating: PropTypes.func,
    setSelectedThemeValue: PropTypes.func,
    setTheme: PropTypes.func,
    taskId: PropTypes.number,
    theme: PropTypes.any,
};

export default PlayerV2Moderation;
