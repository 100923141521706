import cn from "classnames";
import {url} from "config";
import React from "react";
import sliders from "views/pages/about/sliders/sliders.module.sass";
import styles from "views/pages/about/sliders/teacher-class-slide/styles.module.sass";

const AcademyStructureSlide = () => {
    const backgroundStyles = {
        backgroundImage: `url(${url + "data/image-bank/36bbf84f-7c19-4639-940a-4dc5c900e9b3.png"})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    };
    return (
        <div className={sliders.wrapper} style={backgroundStyles}>
            <div className={sliders.content}>
                <div
                    className={cn({
                        [sliders.about]: true,
                        "mt-5": true,
                    })}
                >
                    <p
                        className={cn({
                            [sliders.title]: true,
                            [styles.title]: true,
                            "text-center": true,
                        })}
                    >
                        В составе Академии всё <br /> самое интересное и полезное
                    </p>
                </div>
            </div>
        </div>
    );
};

AcademyStructureSlide.propTypes = {};

export default AcademyStructureSlide;
