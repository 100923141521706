import React, {useState} from "react";
import {
    MDBBtn,
    MDBContainer,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
} from "mdbreact";
import {ISelect} from "../../../../interfaces/ISelect";
import ArticleTags from "../../article-tags/article-tags";
import TagList from "../../../tags/views/tag-list/tag-list";

interface IProps {
    isOpen: boolean;
    toggle: () => void;
    handleSave: (tags: ISelect[]) => void;
}

const TagSelector = ({isOpen, toggle, handleSave}: IProps) => {
    const [selectedTags, setSelectedTags] = useState<ISelect[]>([]);

    const handleSaveClick = () => {
        if (!selectedTags) {
            return;
        }

        handleSave(selectedTags);
    };

    const handleDeleteTag = (tagId: number) => {
        let updatedTags = selectedTags.filter((t) => t.value !== tagId);
        setSelectedTags(updatedTags);
    };

    return (
        <MDBContainer>
            <MDBModal
                isOpen={isOpen}
                toggle={toggle}
                centered
                overflowScroll={false}
                noClickableBodyWithoutBackdrop={false}
                inline={false}
            >
                <MDBModalHeader toggle={toggle}>
                    <p>Теги</p>
                </MDBModalHeader>
                <MDBModalBody>
                    <p className="mb-4 title">Выберите теги</p>
                    <ArticleTags
                        handleTagSelect={(tag) => {
                            setSelectedTags([...selectedTags, tag]);
                        }}
                    />

                    {!!selectedTags.length && (
                        <div className="mt-5">
                            <p className="title">Теги для сохранения:</p>
                            <div>
                                <TagList
                                    tags={selectedTags}
                                    isCards
                                    handleDeleteTag={handleDeleteTag}
                                />
                            </div>
                        </div>
                    )}
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn
                        color="success"
                        onClick={handleSaveClick}
                        disabled={!selectedTags.length}
                    >
                        Сохранить
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </MDBContainer>
    );
};

export default TagSelector;
