import React from "react";
import styles from "./styles.module.sass";
import cn from "classnames";

interface IProps {
    withHeader: boolean;
    headerTitle?: string;
    children: React.ReactNode;
    onClose: () => void;
    width?: number;
    wrapperClassNames?: any;
    contentClassNames?: any;
}

const ModalWrapper = ({
    withHeader,
    children,
    headerTitle,
    onClose,
    width = 500,
    wrapperClassNames,
    contentClassNames,
}: IProps) => {
    return (
        <div
            className={cn({
                [styles.wrapper]: true,
                [wrapperClassNames]: !!wrapperClassNames,
            })}
            style={{width}}
        >
            {withHeader && (
                <div className={styles.header}>
                    <p>{headerTitle}</p>

                    <i className="fa-solid fa-xmark" onClick={onClose}></i>
                </div>
            )}
            <div
                className={cn({
                    [styles.content]: true,
                    [contentClassNames]: !!contentClassNames,
                })}
            >
                {children}
            </div>
        </div>
    );
};

export default ModalWrapper;
