import {getMultipartFormDataContentTypeHeader} from "core/config/headers";
import {Request} from "core/utils/request";
const API_NAME = `basket-engine`;

export const BasketsAPI = {
    createQuestion: (questionDto) => {
        return Request.post(`${API_NAME}`, questionDto);
    },
    createQuestionAdmin: (questionDto) => {
        return Request.post(`admin/${API_NAME}`, questionDto);
    },
    editQuestion: (questionId, questionEditDto) => {
        return Request.put(`${API_NAME}/${questionId}`, questionEditDto);
    },
    createGameBackground: (questionId, fileDto) => {
        let params = fileDto.params;
        const fileData = new FormData();
        fileData.set("file", fileDto.file);

        return Request.post(
            `${API_NAME}/${questionId}/question-image?height=${params.height}&width=${
                params.width
            }&x=${0}&y=${0}`,
            fileData,
            getMultipartFormDataContentTypeHeader(),
        );
    },
    createBasket: (questionId, basketDto) => {
        return Request.post(`${API_NAME}/${questionId}/basket`, basketDto);
    },
    updateBasket: (questionId, basketDto) => {
        return Request.put(`${API_NAME}/${questionId}/basket`, basketDto);
    },
    createAnswerText: (questionId, textAnswerDto) => {
        return Request.post(`${API_NAME}/${questionId}/answer`, textAnswerDto);
    },
    saveCorrectAnswers: (questionId, answers) => {
        return Request.post(`${API_NAME}/${questionId}/correct-answer`, answers);
    },
    deleteCorrectAnswers: (questionId) => {
        return Request.delete(`${API_NAME}/${questionId}/correct-answer`);
    },
    deleteQuestion: (questionId) => {
        return Request.delete(`${API_NAME}/${questionId}`);
    },
    deleteBasket: (questionId, basketId) => {
        return Request.delete(`${API_NAME}/${questionId}/basket/${basketId}`);
    },
    deleteAnswer: (questionId, answerId) => {
        return Request.delete(`${API_NAME}/${questionId}/answer/${answerId}`);
    },
};
