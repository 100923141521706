import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import {useStores} from "../../hooks/use-stores";
import {Achievement} from "../../entries/achievements/achievement/achievement";
import {useHistory} from "react-router-dom";
import {STUDENT_ROUTES, TOURNAMENT_ROUTES} from "../../routes/main";

const ServiceAchievement = observer(() => {
    const {appStore} = useStores();
    const [activeAchievementShow, setActiveAchievementShow] = useState<any>(null);
    const [achievementQueue, setAchievementQueue] = useState<any>([]);
    const history = useHistory();

    useEffect(() => {
        // добавляем ачивку в очередь
        let messages = appStore.websocketMessages;
        if (!messages.length) {
            return;
        }

        let lastMessage = messages[messages.length - 1];

        if (lastMessage?.code === 1005) {
            let updatedLastMessage = {
                title: "Получен новый предмет!",
                message: lastMessage.icon,
                description: lastMessage.name,
                code: 1005,
            };
            setAchievementQueue((prev: any) => [...prev, updatedLastMessage]);
        }

        if (lastMessage?.code === 1003) {
            let updatedLastMessage = {
                title: "Начинается турнир!",
                message: "/data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg",
                description: lastMessage.message,
                code: 1003,
                id: lastMessage.id,
            };
            setAchievementQueue((prev: any) => [...prev, updatedLastMessage]);
        }
    }, [appStore.websocketMessages.length]);

    useEffect(() => {
        if (activeAchievementShow) return;

        if (achievementQueue.length) {
            setActiveAchievementShow(achievementQueue[0]);
            setAchievementQueue(achievementQueue.filter((_: any, index: number) => index !== 0));

            setTimeout(() => {
                setActiveAchievementShow(null);
            }, 15000);
        }
    }, [activeAchievementShow, achievementQueue]);

    if (!activeAchievementShow) return null;

    return (
        <div
            className={styles.achievementWrapper}
            onClick={
                activeAchievementShow.code === 1005
                    ? () => history.push(STUDENT_ROUTES.ACCOUNT.ROOT)
                    : () =>
                          history.push(
                              TOURNAMENT_ROUTES.VIEW.replace(":id", activeAchievementShow.id),
                          )
            }
        >
            <Achievement
                title={activeAchievementShow.title}
                imageUrl={activeAchievementShow.message?.slice(1)}
                text={activeAchievementShow.description}
            />
        </div>
    );
});

export default ServiceAchievement;
