import React from "react";
import {EditButtons} from "views/components/edit-buttons";
import styles from "./item-theme.module.sass";
import PropTypes from "prop-types";

export const ItemTheme = ({theme, handleThemeDelete, handleThemeEdit}) => {
    return (
        <div className={styles.container}>
            <p className={styles.itemTitle}>Тема: {theme.theme.name}</p>
            <div className={styles.itemAdditionalInfo}>
                <p>
                    Класс: {theme.gradeTag.name}, {theme.schoolSubjectTag.name}
                </p>
            </div>
            <EditButtons
                itemId={theme.id}
                editAction={() => handleThemeEdit(theme.id)}
                deleteAction={() => handleThemeDelete(theme.id)}
            />
        </div>
    );
};

ItemTheme.propTypes = {
    handleThemeDelete: PropTypes.func,
    handleThemeEdit: PropTypes.func,
    theme: PropTypes.object,
};
