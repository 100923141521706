import {TextBoxWithSelectContainer} from "entries/constructors/word-skip/views/text-box-with-select/text-box-with-select-container";
import {MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import React from "react";
import PropTypes from "prop-types";

import styles from "entries/constructors/word-skip/views/modal-create-word-skip/modal-create-word-skip.module.sass";

export const ModalCreateWordSkip = ({
    isOpen,
    toggle,
    onSaveSkips,
    skipsIndexes,
    text,
    skipWithAdditionalAnswers,
    additionalAnswers,
    sentenceSkip,
}) => {
    return (
        <MDBModal isOpen={isOpen} toggle={toggle} size="lg">
            <MDBModalHeader toggle={toggle} className="modal-header">
                <p>Укажите пропуски в тексте</p>
            </MDBModalHeader>
            <MDBModalBody>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <p className={styles.headerDescription}>
                            Кликните на слово, чтобы выделить его, а затем нажмите на кнопку «+»,
                            чтобы создать пропуск в этом месте.
                        </p>
                    </div>
                    <TextBoxWithSelectContainer
                        onSaveSkips={onSaveSkips}
                        skipsIndexes={skipsIndexes}
                        text={text}
                        skipWithAdditionalAnswers={skipWithAdditionalAnswers}
                        additionalAnswersFromSave={additionalAnswers}
                        sentenceSkip={sentenceSkip}
                    />
                </div>
            </MDBModalBody>
        </MDBModal>
    );
};

ModalCreateWordSkip.propTypes = {
    additionalAnswers: PropTypes.array,
    isOpen: PropTypes.bool,
    sentenceSkip: PropTypes.bool,
    skipsIndexes: PropTypes.array,
    skipWithAdditionalAnswers: PropTypes.bool,
    text: PropTypes.string,
    toggle: PropTypes.func,
    onSaveSkips: PropTypes.func,
};
