import BasketsGame from "entries/constructors/baskets-game/views/baskets-game/baskets-game";
import React from "react";
import PropTypes from "prop-types";

const BasketsGameContainer = ({testData, isEditMode}) => {
    return <BasketsGame testData={testData} isEditMode={isEditMode} />;
};

BasketsGameContainer.propTypes = {
    isEditMode: PropTypes.bool,
    testData: PropTypes.object,
};

export default BasketsGameContainer;
