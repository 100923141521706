import React from "react";
import PropTypes from "prop-types";

import {STEP} from "./constants";

export const SetTestParams = (props) => {
    return (
        <div>
            <div>{props.children}</div>
            <div>Params</div>
        </div>
    );
};

SetTestParams.propTypes = {
    toPrev: PropTypes.func.isRequired,
};

SetTestParams.step = STEP.PARAMS;
