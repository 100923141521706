import React, {ChangeEvent, useEffect, useState} from "react";
import styles from "./styles.module.sass";
import {ArticlesList} from "../../../entries/articles/articles-list/articles-list";
import {ArticleAPI} from "../../../entries/articles/core/article-api";
import {IPublication} from "../../../interfaces/IPublication";
import TagList from "../../../entries/tags/views/tag-list/tag-list";
import {Helmet} from "react-helmet";
import {useHistory, useParams} from "react-router-dom";
import {PAGES_ROUTES} from "../../routes/routes";

const ArticlePublicPage = () => {
    const [searchValue, setSearchValue] = useState("");
    const [articles, setArticles] = useState<IPublication[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [popularTags, setPopularTags] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [paginationStage, setPaginationStage] = useState(1);
    const [selectedTag, setSelectedTag] = useState<any>(null);
    let {tagId} = useParams<any>();
    let history = useHistory();

    const getArticles = (page?: number) => {
        if (tagId) return;
        setPaginationStage(1);
        setIsLoading(true);
        ArticleAPI.getPublications(page || activePage)
            .then((response) => {
                setArticles(response.data.content);
                setPagination({...response.data, content: null});
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (!tagId && !selectedTag?.id) return;
        setActivePage(1);
        getArticlesByTagId();
    }, [tagId, selectedTag]);

    const getArticlesByText = () => {
        setIsLoading(true);
        setPaginationStage(2);
        ArticleAPI.searchPublication(searchValue, activePage)
            .then((response) => {
                setArticles(response.data.content);
                setPagination({...response.data, content: null});
            })
            .finally(() => setIsLoading(false));
    };

    const getPopularTags = () => {
        ArticleAPI.getPopularTags().then((response) => {
            setPopularTags(response.data);
        });
    };

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        let text = e.target.value;
        setSearchValue(e.target.value);

        if (text.length === 0) {
            getArticles();
        }
    };

    const getArticlesByTagId = () => {
        setIsLoading(true);
        setPaginationStage(3);
        ArticleAPI.getPublicationsByTagId(selectedTag?.id || tagId, activePage)
            .then((response) => {
                setArticles(response.data.content);
                setPagination({...response.data, content: null});
            })
            .finally(() => setIsLoading(false));
    };

    const resetFilters = () => {
        if (tagId) {
            history.push(PAGES_ROUTES.PUBLICATIONS.ROOT);
        }

        setActivePage(1);
        setPaginationStage(1);
        setSelectedTag(null);
        setSearchValue("");
        getArticles(1);
    };

    useEffect(() => {
        if (searchValue.length < 3) return;

        const search = setTimeout(() => {
            getArticlesByText();
        }, 500);

        return () => clearTimeout(search);
    }, [searchValue]);

    useEffect(() => {
        if (paginationStage === 1) {
            getArticles();
        } else if (paginationStage === 2) {
            getArticlesByText();
        } else if (paginationStage === 3) {
            getArticlesByTagId();
        }

        getPopularTags();
    }, [activePage]);

    useEffect(() => {
        setActivePage(1);
        if (paginationStage !== 3) {
            setSelectedTag(null);
        }
    }, [paginationStage]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Статьи для родителей и учителей начальных классов</title>
                <meta
                    name="description"
                    content="Полезные статьи об образовании в начальных классах. Методики, задания и техники обучения в 1-4 классе школы."
                />
                <meta
                    name="keywords"
                    content="обучение домашний школа 1 2 3 4 класс начальный родитель учитель методика способ решение задание русский математика английский окружайка чтение"
                />

                <script type="text/javascript">
                    {` VK.Widgets.Group("vk_groups", {mode: 3, wide: 1, height: 200, color1: "FFFFFF", color2: "000000", color3: "68C8F2"}, 208897875);`}
                </script>
            </Helmet>

            <div className={styles.article}>
                <div className={styles.header}>
                    <div className={styles.headerLeftSide} onClick={() => resetFilters()}>
                        <img
                            src={require("../../../assets/articles/logo.svg")}
                            alt="raven"
                            className={styles.headerLogo}
                        />
                        <div className={styles.headerInfo}>
                            <div className={styles.headerTitle}>
                                <img src={require("../../../assets/articles/title.svg")} />
                            </div>
                            <h1 className={styles.headerDescription}>
                                Статьи для родителей и учителей начальных классов
                            </h1>
                        </div>
                    </div>

                    <div className={styles.headerSearch}>
                        <input
                            type="textarea"
                            className="form-control"
                            value={searchValue}
                            onChange={handleSearch}
                            placeholder="Поиск статей"
                        />
                    </div>
                </div>
                <div className={styles.themes}>
                    <h2 className={styles.themesTitle}>Популярные темы</h2>
                    <div className={styles.wrapper}>
                        <TagList
                            tags={popularTags}
                            withShowAllTags
                            isCards
                            cardClassName={styles.tagFilled}
                            className={styles.tagWrapper}
                            handleClick={(id: number, tagName: string) => {
                                setSelectedTag({id, tagName});
                            }}
                        />

                        <div id="vk_groups"></div>
                    </div>
                </div>
                <img
                    className={styles.divider}
                    src={require("../../../assets/images/static/divider.svg")}
                    alt="divider"
                />
                <div className={styles.content}>
                    <div className={styles.titleWrapper}>
                        <h2 className={styles.contentTitle}>
                            Свежие статьи{" "}
                            {selectedTag?.tagName && `по тегу «${selectedTag.tagName}»`}{" "}
                        </h2>

                        {(paginationStage === 2 || paginationStage === 3) && (
                            <p className="link" onClick={() => resetFilters()}>
                                Сбросить фильтр
                            </p>
                        )}
                    </div>

                    {searchValue && articles?.length === 0 && (
                        <p className="title">Статей по данному запросу не найдено</p>
                    )}

                    <ArticlesList
                        articles={articles}
                        isLoading={isLoading}
                        isPublic={true}
                        pagination={pagination}
                        handleChangePage={setActivePage}
                        activePage={activePage}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ArticlePublicPage;
