import SubHeader from "entries/sub-header/sub-header/sub-header";
import React from "react";
import PropTypes from "prop-types";

const SubHeaderContainer = ({subTabs, show, isLinks, onSelect, currentTab, withExit, withVK}) => {
    return (
        <SubHeader
            subTabs={subTabs}
            show={show}
            isLinks={isLinks}
            onSelect={onSelect}
            currentTab={currentTab}
            withExit={withExit}
            withVK={withVK}
        />
    );
};

SubHeaderContainer.propTypes = {
    currentTab: PropTypes.string,
    isLinks: PropTypes.bool,
    show: PropTypes.bool,
    subTabs: PropTypes.array,
    withExit: PropTypes.bool,
    withVK: PropTypes.bool,
    onSelect: PropTypes.func,
};

export default SubHeaderContainer;
