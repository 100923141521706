import PropTypes from "prop-types";
import React from "react";
import {SIZE_M, SIZE_S, SIZE_XL} from "utils/answers-config";

import {EditButtons} from "views/components/edit-buttons";
import styles from "entries/constructors/classic-test/views/components/image-answer/image-answer.module.sass";
import cn from "classnames";

export const ImageAnswer = (props) => {
    const {
        answers,
        imageSize,
        isSelectCorrectAnswerMode,
        correctAnswer,
        isEditMode,
        editImage,
        deleteAnswer,
        setImageModalVisible,
        updateAnswerType,
        isRoundedAnswer,
        isPlayerMode,
    } = props;

    return (
        <div className={styles.container}>
            {answers?.map((answer) => (
                <div
                    key={answer.id}
                    className={cn({
                        [styles.imageWrapper]: true,
                        [styles.rounded]: isRoundedAnswer,
                        [styles.correctAnswerImage]: answer.isCorrect,
                        [styles.playSelected]: isPlayerMode && answer.isCorrect,
                        [styles.border]: isEditMode,
                        sizeS: imageSize === SIZE_S,
                        sizeM: imageSize === SIZE_M,
                        sizeXL: imageSize === SIZE_XL,
                    })}
                    onClick={() => isSelectCorrectAnswerMode && correctAnswer(answer.id)}
                >
                    {isEditMode && (
                        <EditButtons
                            editAction={editImage}
                            deleteAction={deleteAnswer}
                            itemId={answer.id}
                            withoutTopBorder
                            withoutRightBorder
                        />
                    )}
                    {answer.component}
                </div>
            ))}

            {answers?.length < 6 && isEditMode && (
                <div className={styles.answerContainer}>
                    <div
                        className={cn({
                            [styles.addAnswer]: true,
                            sizeS: imageSize === SIZE_S,
                            sizeM: imageSize === SIZE_M,
                            sizeXL: imageSize === SIZE_XL,
                        })}
                        style={{height: "100%"}}
                        onClick={() => setImageModalVisible(true)}
                    >
                        <div
                            className={cn({
                                link: true,
                            })}
                        >
                            Добавить ответ
                        </div>
                    </div>
                    {answers.length === 0 && (
                        <EditButtons
                            deleteAction={() => updateAnswerType("")}
                            withoutTopBorder
                            withoutRightBorder
                        />
                    )}
                </div>
            )}
        </div>
    );
};

ImageAnswer.propTypes = {
    answers: PropTypes.arrayOf(PropTypes.any),
    correctAnswer: PropTypes.any,
    deleteAnswer: PropTypes.func,
    editImage: PropTypes.func,
    imageSize: PropTypes.string,
    isEditMode: PropTypes.bool,
    isPlayerMode: PropTypes.bool,
    isRoundedAnswer: PropTypes.bool,
    isSelectCorrectAnswerMode: PropTypes.bool,
    setImageModalVisible: PropTypes.func,
    updateAnswerType: PropTypes.func,
};
