import React from "react";
import {useHistory} from "react-router-dom";
import {PAGES_ROUTES} from "views/routes/routes";
import styles from "./styles.module.sass";

const PolicyHeader = () => {
    let history = useHistory();
    return (
        <div className={styles.header}>
            <p>
                <span
                    className="link mr-2 ml-2"
                    onClick={() => history.push(PAGES_ROUTES.POLICY.TERMS)}
                >
                    Пользовательское соглашение
                </span>{" "}
                ―
                <span
                    className="link mr-2 ml-2"
                    onClick={() => history.push(PAGES_ROUTES.POLICY.OFFER)}
                >
                    Оферта
                </span>{" "}
                ―
                <span
                    className="link mr-2 ml-2"
                    onClick={() =>
                        window.open("https://umnayavorona.ru/files/private_policy.pdf", "_blank")
                    }
                >
                    Политика обработки персональных данных
                </span>{" "}
                ―
                <span
                    className="link ml-2"
                    onClick={() => history.push(PAGES_ROUTES.POLICY.COOKIES_POLICY)}
                >
                    Файлы cookies
                </span>
            </p>
        </div>
    );
};

PolicyHeader.propTypes = {};

export default PolicyHeader;
