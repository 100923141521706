import React, {useState} from "react";
import EngineList from "../../../constructors-v2/engine-list";
import styles from "./styles.module.sass";
import {ENGINES} from "../../../constructors-v2/config/engines";
import EnginePreview from "../../../constructors-v2/engine-preview/engine-preview";

const SelectEnginePage = () => {
    const [selectedEngine, setSelectedEngine] = useState<any>(ENGINES[1]);

    return (
        <div className={styles.selectEngineContainer}>
            <EngineList onClick={setSelectedEngine} />
            <EnginePreview engine={selectedEngine} />
        </div>
    );
};

export default SelectEnginePage;
