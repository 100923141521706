import React from "react";
import styles from "./header.module.sass";
import {url} from "../../../config";
import {MDBNavbarBrand} from "mdbreact";
import {useHistory} from "react-router-dom";
import {useStores} from "../../../hooks/use-stores";

interface IProps {
    withLogoText: boolean;
}

const HeaderBrand = ({withLogoText}: IProps) => {
    let history = useHistory();
    const {playerStore} = useStores();
    return (
        <MDBNavbarBrand
            style={{marginRight: !withLogoText && "0", cursor: "pointer"}}
            onClick={() => history.push("/")}
        >
            <div className={styles.brandContainer}>
                <img
                    src={url + "data/image-bank/3b3a2bcd-1848-4c41-8417-1d10ae864401.svg"}
                    alt="logo"
                    style={{height: "40px", width: "40px"}}
                />
                {withLogoText && !playerStore.isMobileMode && (
                    <p className={styles.brandTitle}>Умная Ворона</p>
                )}
            </div>
        </MDBNavbarBrand>
    );
};

export default HeaderBrand;
