import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import {EditButtons} from "views/components/edit-buttons";
import AudioAnswer from "../audio-answer/audio-answer";
import styles from "./styles.module.sass";

const ListOfAudioAnswers = ({
    answers,
    handleAddImage,
    handleDeleteAnswer,
    handleDeleteType,
    handleClick,
    handleAddAnswer,
    handleDeleteImageAnswer,
    withAddAudioButton,
    maxAnswers,
    className,
    isEditMode,
}) => {
    return (
        <div
            className={cn({
                [styles.audioList]: true,
                [className]: !!className,
            })}
        >
            {answers?.map((answer, index) => (
                <AudioAnswer
                    key={answer.id}
                    id={answer.id}
                    image={answer.image}
                    audio={answer.audioUrl}
                    index={index + 1}
                    className={styles.audioItem}
                    isEditMode={isEditMode}
                    handleClick={handleClick}
                    isSelected={answer.isCorrect}
                    handleDeleteAnswer={handleDeleteAnswer}
                    handleAddImage={handleAddImage}
                    handleDeleteImageAnswer={handleDeleteImageAnswer}
                />
            ))}

            {withAddAudioButton && answers.length < maxAnswers && isEditMode && (
                <div className={styles.audioTools}>
                    {answers.length === 0 && isEditMode && (
                        <EditButtons
                            deleteAction={handleDeleteType}
                            withoutTopBorder={true}
                            withoutRightBorder={true}
                            withoutDeleteConfirmation={true}
                        />
                    )}

                    <div className={styles.addAudio} onClick={handleAddAnswer}>
                        <p>Добавить аудио</p>
                    </div>
                </div>
            )}
        </div>
    );
};

ListOfAudioAnswers.propTypes = {
    answers: PropTypes.array,
    className: PropTypes.string,
    handleAddAnswer: PropTypes.func,
    handleAddImage: PropTypes.func,
    handleClick: PropTypes.func,
    handleDeleteAnswer: PropTypes.func,
    handleDeleteImageAnswer: PropTypes.func,
    handleDeleteType: PropTypes.func,
    isEditMode: PropTypes.bool,
    maxAnswers: PropTypes.number,
    withAddAudioButton: PropTypes.bool,
};

export default ListOfAudioAnswers;
