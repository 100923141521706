import React, {useState} from "react";
import styles from "./styles.module.sass";
import {TextUploadModal} from "../../../views/components/text-upload-modal/text-upload-modal";
import {EPublicationCustomActionTypes} from "../../../enums/EPublicationCustomActionTypes";

interface IProps {
    isViewMode: boolean;
    handleCustomAction: (type: EPublicationCustomActionTypes, payload: any) => void;
    block: any;
    title: string;
}

const ArticleTitle = ({isViewMode, handleCustomAction, block, title}: IProps) => {
    const [textModalIsOpen, setTextModalIsOpen] = useState(false);

    const handleSaveText = (text: string) => {
        handleCustomAction(EPublicationCustomActionTypes.TITLE_SAVE, {title: text, block});
        setTextModalIsOpen(false);
    };

    return (
        <React.Fragment>
            {textModalIsOpen && (
                <TextUploadModal
                    saveText={handleSaveText}
                    visible={textModalIsOpen}
                    toggle={() => setTextModalIsOpen(!textModalIsOpen)}
                    text={title}
                    withoutInputCheck
                />
            )}

            {isViewMode ? (
                <h2 className={styles.title}>{title}</h2>
            ) : (
                <div className={styles.title}>
                    <h2>
                        {title ? title : "Заголовок"}
                        {!isViewMode && (
                            <i className="fas fa-pen" onClick={() => setTextModalIsOpen(true)} />
                        )}
                    </h2>
                </div>
            )}
        </React.Fragment>
    );
};

export default ArticleTitle;
