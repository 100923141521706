import React from "react";
import styles from "./styles.module.sass";
import {url} from "../../../config";
import {IEvent} from "../../../interfaces/IEvent";
import {toJS} from "mobx";
import ModalWrapper from "../modal-wrapper/modal-wrapper";
import ratingLogo from "assets/logo/other/rating.svg";

interface IProps {
    toggle: () => void;
    event: IEvent;
}

const AchievementModal = ({event, toggle}: IProps) => {
    console.log(toJS(event));

    return (
        <ModalWrapper withHeader={true} headerTitle="Награда" onClose={toggle}>
            <div className={styles.achievementContent}>
                <p className={styles.achievementText}>{event.title}</p>

                {event.pictogram && (
                    <div className={styles.imageWrapper}>
                        <img src={url + event.pictogram.fileDownloadUri.slice(1)} />
                    </div>
                )}

                <p className={styles.achievementDescription}>{event.description}</p>
            </div>

            <div className={styles.footer}>
                <div className={styles.ratingLogo}>
                    <img src={ratingLogo} alt="rating" /> {event.rating || 0}
                </div>
                <div className={styles.coinLogo}>
                    <img src={require("../../../assets/images/static/coin.svg")} alt="coin" />
                    {event.coin || 0}
                </div>
            </div>
        </ModalWrapper>
    );
};

export default AchievementModal;
