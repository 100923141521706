import Hints from "entries/hints/hints";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import {ClassicTestPlayer} from "entries/player/views/task-players/classic-test-player/classic-test-player";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export const ClassicTestPlayerContainer = ({
    taskId,
    onSelectCorrectAnswer,
    reset,
    questionApi,
    getInfoAboutSet,
    playerMode,
    answerIsLoading,
    setAnswerIsLoading,
}) => {
    const [question, setQuestion] = useState(null);
    const [answers, setAnswers] = useState(null);
    const [properties, setProperties] = useState(null);

    let {id} = useParams();

    useEffect(() => {
        setAnswers(null);
        setQuestion(null);
        setProperties(null);
        getData();
    }, [taskId]);

    const getData = async () => {
        setAnswerIsLoading(true);
        let data = undefined;
        await questionApi(taskId, id)
            .then((response) => {
                if (playerMode === PlayerV2Modes.TOURNAMENT) {
                    data = response.data[0].question;
                } else {
                    data = response.data;
                    getInfoAboutSet();
                }
            })
            .catch((err) => console.log("load data error", err))
            .finally(() => setAnswerIsLoading(false));

        if (!data) {
            return;
        }

        setQuestion(data.classicTest);
        setAnswers(data.classicTestAnswerVariants);
        setProperties(data.questionProperties);
    };

    const handleSelectAnswer = (answerId) => {
        let arrayWithCorrectAnswer = answers.map((answer) => {
            if (answer.id === answerId) {
                if (answer.isCorrect) {
                    return {...answer, isCorrect: false};
                } else {
                    return {...answer, isCorrect: true};
                }
            }
            return answer;
        });
        setAnswers(arrayWithCorrectAnswer);
        let correctAnswers = arrayWithCorrectAnswer.filter((answer) => answer.isCorrect);
        onSelectCorrectAnswer(correctAnswers);
    };

    useEffect(() => {
        if (!answers || answers.length === 0) return;
        let cloneAnswers = answers.map((answer) => ({...answer, isCorrect: false}));
        setAnswers(cloneAnswers);
    }, [reset]);

    const fillCorrectAnswers = (answersForFill) => {
        let correctAnswers = answersForFill.map((answer) => answer.correctAnswers);
        let updatedAnswers = answers.map((answer) => {
            if (correctAnswers.find((a) => a.id === answer.id)) {
                return {...answer, isCorrect: true};
            } else {
                return {...answer, isCorrect: false};
            }
        });

        setAnswers(updatedAnswers);
        onSelectCorrectAnswer(correctAnswers);
    };

    return (
        <React.Fragment>
            {!answerIsLoading && (
                <React.Fragment>
                    {playerMode === PlayerV2Modes.ACADEMY && (
                        <Hints question={question} onBuyCorrectAnswer={fillCorrectAnswers} />
                    )}

                    {question && answers && properties && (
                        <ClassicTestPlayer
                            question={question}
                            answers={answers}
                            properties={properties}
                            onSelectCorrectAnswer={handleSelectAnswer}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

ClassicTestPlayerContainer.propTypes = {
    onSelectCorrectAnswer: PropTypes.func.isRequired,
    playerMode: PropTypes.string,
    getInfoAboutSet: PropTypes.func,
    taskId: PropTypes.number.isRequired,
    homeworkId: PropTypes.string,
    questionApi: PropTypes.func,
    reset: PropTypes.any,
    answerIsLoading: PropTypes.bool,
    setAnswerIsLoading: PropTypes.func,
};
