import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import {url} from "config";

import styles from "./picture.module.sass";

export const Picture = ({pictureData, saveImage, selected, showCorrect = true}) => {
    return (
        <React.Fragment>
            {pictureData.url ? (
                <div className={styles.imageWrapper}>
                    <img
                        key={pictureData.id}
                        className={styles.img}
                        src={`${url}${pictureData.url}`}
                        alt="LibraryPicture"
                        onClick={() => saveImage(pictureData)}
                    />
                    {showCorrect && (
                        <span
                            className={cn({
                                [styles.checkCircle]: true,
                                [styles.imgSelected]: selected && selected.id === pictureData.id,
                            })}
                        >
                            <i className="fa fa-check-circle" aria-hidden="true" />
                        </span>
                    )}
                </div>
            ) : (
                <img className={styles.img} alt="noImage" />
            )}
        </React.Fragment>
    );
};

Picture.propTypes = {
    editMode: PropTypes.bool,
    loadHandler: PropTypes.func,
    pictureData: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string,
        url: PropTypes.string,
    }),
    saveImage: PropTypes.func,
    selected: PropTypes.any,
    showCorrect: PropTypes.bool,
};
