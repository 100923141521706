import React from "react";
import PropTypes from "prop-types";
import {AsyncPaginate} from "react-select-async-paginate";

const GeoSelectRegions = ({handleSelect, loadOptions, selectedRegion}) => {
    return (
        <AsyncPaginate
            styles={{menu: (provided) => ({...provided, zIndex: 9999})}}
            onChange={handleSelect}
            closeMenuOnSelect
            loadOptions={loadOptions}
            additional={{
                page: 1,
            }}
            placeholder="Выберите регион"
            value={selectedRegion}
        />
    );
};

GeoSelectRegions.propTypes = {
    handleSelect: PropTypes.func,
    loadOptions: PropTypes.func,
    selectedRegion: PropTypes.object,
};

export default GeoSelectRegions;
