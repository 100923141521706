import {EColors} from "./EColors";
import AdminPanelDashboard from "../admin-panel-dashboard/admin-panel-dashboard";
import React from "react";
import AdminPanelUsers from "../admin-panel-users/admin-panel-users";
import {ImagesManager} from "../../../views/pages/admin/images-manager";
import AdminPanelSubscriptions from "../admin-panel-subscriptions/admin-panel-subscriptions";
import UsersListContainer from "../../users-list/users-list-container";
import AdminPanelUserByEmail from "../admin-panel-users/admin-panel-user-by-email/admin-panel-user-by-email";
import AdminPanelUserCreateStudent from "../admin-panel-users/admin-panel-user-create-student/admin-panel-user-create-student";
import AdminPanelSubsByUser from "../admin-panel-subscriptions/admin-panel-subs-by-user/admin-panel-subs-by-user";
import AdminPanelSubscriptionGive from "../admin-panel-subscriptions/admin-panel-subscription-give/admin-panel-subscription-give";
import AdminPanelUsersAcceptedByTeacher from "../admin-panel-users/admin-panel-users-accepted-by-teacher/admin-panel-users-accepted-by-teachert";
import AdminPanelUsersInClass from "../admin-panel-users/admin-panel-users-in-class/admin-panel-users-in-class";
import ArticlesPage from "../../../views/pages/articles/articles-page";
import {CLASSIC_TEST_ROUTES} from "../../constructors/classic-test/views/routes";
import {DICTATION_TEST_ROUTES} from "../../constructors/dictation/views/routes";
import {TEXT_SEQUENCE_TEST_ROUTES} from "../../constructors/sequences/sequence-test/routes";
import {OPEN_ANSWER_TEST_ROUTES} from "../../constructors/open-answer/routes";
import {NUMERIC_SEQUENCE_TEST_ROUTES} from "../../constructors/sequences/numeric-sequence-test/routes";
import {PAIRS_TEST_ROUTES} from "../../constructors/pairs-test/routes";
import {
    SENTENCES_SKIP_TEST_ROUTES,
    WORD_SKIP_ADDITIONAL_TEST_ROUTES,
    WORD_SKIP_TEST_ROUTES,
} from "../../constructors/word-skip/routes";
import AdminPanelConstructors from "../admin-panel-constructors/admin-panel-constructors";
import {ThemesListPage} from "../../../views/pages/admin/school-themes/school-themes";
import AdminPanelTags from "../admin-panel-tags/admin-panel-tags";
import AdminPanelTagsConstructor from "../admin-panel-tags/admin-panel-tags-constructor/admin-panel-tags-constructor";
import AdminPanelSchools from "../admin-panel-schools/admin-panel-schools";
import AdminPanelSchoolsTemp from "../admin-panel-schools/admin-panel-schools-temp/admin-panel-schools-temp";
import AdminPanelSchoolsEdit from "../admin-panel-schools/admin-panel-schools-edit/admin-panel-schools-edit";
import AdminPanelSubsBulkGive from "../admin-panel-subscriptions/admin-panel-subs-bulk-give/admin-panel-subs-bulk-give";
import AdminPanelUserByName from "../admin-panel-users/admin-panel-user-by-name/admin-panel-user-by-name";
import AdminPanelUserUpdate from "../admin-panel-users/admin-panel-user-update/admin-panel-user-by-email";
import NewsPage from "../../news/news-page";
import TaskListContainer from "../../task-list/task-list-container";
import AdminPanelTasks from "../admin-panel-tasks/admin-panel-tasks";
import EventsAdminContainer from "../../events/events-admin/events-admin-container";
import AdminPanelSystem from "../admin-panel-system/admin-panel-users";
import AdminPanelGame from "../admin-panel-game/admin-panel-game";
import AdminPanelGameItems from "../admin-panel-game/items/admin-panel-game-items";
import AdminPanelGameItemType from "../admin-panel-game/item-type/admin-panel-game-item-type";
import AdminPanelGameMagic from "../admin-panel-game/magic/admin-panel-game-magic";
import AdminPanelItemGroups from "../admin-panel-game/item-groups/admin-panel-item-groups";
import BannerPage from "../../../actual/pages/banner/banner-page";

export const ADMIN_PANEL_ROUTES = {
    DASHBOARD: {
        ROOT: "/admin/new/dashboard",
    },
    IMAGES: {
        ROOT: "/admin/new/images",
        UPLOAD: "/admin/new/images/upload",
    },
    THEMES: {
        ROOT: "/admin/new/themes",
    },
    SYSTEM: {
        ROOT: "/admin/new/system",
        EVENTS: "/admin/new/system/events",
        THEMES: "/admin/new/system/themes",
    },
    CONSTRUCTORS: {
        ROOT: "/admin/new/constructors",
        CLASSIC_TEST: {
            ROOT: `/admin/new/constructors${CLASSIC_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/admin/new/constructors${CLASSIC_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/admin/new/constructors${CLASSIC_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/admin/new/constructors${CLASSIC_TEST_ROUTES.EDITING}`,
        },
        DICTATION: {
            ROOT: `/admin/new/constructors${DICTATION_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/admin/new/constructors${DICTATION_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/admin/new/constructors${DICTATION_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/admin/new/constructors${DICTATION_TEST_ROUTES.EDITING}`,
        },
        TEXT_SEQUENCE_TEST: {
            ROOT: `/admin/new/constructors${TEXT_SEQUENCE_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/admin/new/constructors${TEXT_SEQUENCE_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/admin/new/constructors${TEXT_SEQUENCE_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/admin/new/constructors${TEXT_SEQUENCE_TEST_ROUTES.EDITING}`,
        },
        NUMERIC_SEQUENCE_TEST: {
            ROOT: `/admin/new/constructors${NUMERIC_SEQUENCE_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/admin/new/constructors${NUMERIC_SEQUENCE_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/admin/new/constructors${NUMERIC_SEQUENCE_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/admin/new/constructors${NUMERIC_SEQUENCE_TEST_ROUTES.EDITING}`,
        },
        PAIRS_TEST: {
            ROOT: `/admin/new/constructors${PAIRS_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/admin/new/constructors${PAIRS_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/admin/new/constructors${PAIRS_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/admin/new/constructors${PAIRS_TEST_ROUTES.EDITING}`,
        },
        WORD_SKIP_TEST: {
            ROOT: `/admin/new/constructors${WORD_SKIP_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/admin/new/constructors${WORD_SKIP_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/admin/new/constructors${WORD_SKIP_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/admin/new/constructors${WORD_SKIP_TEST_ROUTES.EDITING}`,
        },
        WORD_SKIP_ADDITIONAL_TEST: {
            ROOT: `/admin/new/constructors${WORD_SKIP_ADDITIONAL_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/admin/new/constructors${WORD_SKIP_ADDITIONAL_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/admin/new/constructors${WORD_SKIP_ADDITIONAL_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/admin/new/constructors${WORD_SKIP_ADDITIONAL_TEST_ROUTES.EDITING}`,
        },
        OPEN_ANSWER_TEST: {
            ROOT: `/admin/new/constructors${OPEN_ANSWER_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/admin/new/constructors${OPEN_ANSWER_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/admin/new/constructors${OPEN_ANSWER_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/admin/new/constructors${OPEN_ANSWER_TEST_ROUTES.EDITING}`,
        },
        SENTENCE_TEST: {
            ROOT: `/admin/new/constructors${SENTENCES_SKIP_TEST_ROUTES.ROOT}`,
            CREATE_TEST: `/admin/new/constructors${SENTENCES_SKIP_TEST_ROUTES.CREATE_TEST}`,
            CONSTRUCTOR: `/admin/new/constructors${SENTENCES_SKIP_TEST_ROUTES.CONSTRUCTOR}`,
            EDITING: `/admin/new/constructors${SENTENCES_SKIP_TEST_ROUTES.EDITING}`,
        },
        BASKETS_TEST: {
            ROOT: `/admin/new/constructors/baskets-game`,
            CREATE_TEST: `/admin/new/constructors/baskets-game/create`,
            CONSTRUCTOR: `/admin/new/constructors/baskets-game/constructor`,
            EDITING: `/admin/new/constructors/baskets-game/editing`,
        },
        CLIPPING_TEST: {
            ROOT: `/admin/new/constructors/clipping-game`,
            CREATE_TEST: `/admin/new/constructors/clipping-game/create`,
            CONSTRUCTOR: `/admin/new/constructors/clipping-game/constructor`,
            EDITING: `/admin/new/constructors/clipping-game/editing/:id`,
        },
        CLIPPING_SECONDARY_TEST: {
            ROOT: `/admin/new/constructors/clipping-secondary-game`,
            CREATE_TEST: `/admin/new/constructors/clipping-secondary-game/create`,
            CONSTRUCTOR: `/admin/new/constructors/clipping-secondary-game/constructor`,
            EDITING: `/admin/new/constructors/clipping-secondary-game/editing/:id`,
        },
        MANY_TO_MANY_DND_TEST: {
            ROOT: `/admin/new/constructors/many-to-many-dnd-test`,
            CREATE_TEST: `/admin/new/constructors/many-to-many-dnd-test/create`,
            CONSTRUCTOR: `/admin/new/constructors/many-to-many-dnd-test/constructor`,
            EDITING: `/admin/new/constructors/many-to-many-dnd-test/editing/:id`,
        },
        DRAG_AREA_TEST: {
            ROOT: `/admin/new/constructors/drag-area`,
            CREATE_TEST: `/admin/new/constructors/drag-area/create`,
            CONSTRUCTOR: `/admin/new/constructors/drag-area/constructor`,
        },
    },
    ARTICLES: {
        ROOT: "/admin/new/articles",
        CREATE: "/admin/new/articles/create",
        EDIT: "/admin/new/articles/edit/:url",
    },
    BANNERS: {
        ROOT: "/admin/new/banners",
    },
    NEWS: {
        ROOT: "/admin/new/news",
        CREATE: "/admin/new/news/create",
        EDIT: "/admin/new/news/edit/:id",
    },
    TOURNAMENTS: {
        ROOT: "/admin/new/tournaments",
    },
    TAGS: {
        ROOT: "/admin/new/tags",
        CONSTRUCTOR: "/admin/new/tags/constructor",
    },
    USERS: {
        ROOT: "/admin/new/users",
        VIEW: "/admin/new/users/view",
        VIEW_BY_NAME: "/admin/new/users/student/view/name",
        VIEW_BY_EMAIL: "/admin/new/users/view/email",
        REGISTER_STUDENT: "/admin/new/users/student/register",
        ACCEPTED_STUDENTS: "/admin/new/users/student/accepted",
        STUDENTS_IN_CLASS: "/admin/new/users/student/in-class",
        USER_UPDATE: "/admin/new/users/update",
    },
    SUBSCRIBES: {
        ROOT: "/admin/new/subscribes",
        GET_BY_USER: "/admin/new/subscribes/get-by-user",
        GIVE: "/admin/new/subscribes/give",
        BULK_GIVE: "/admin/new/subscribes/bulk-give",
    },
    SCHOOLS: {
        ROOT: "/admin/new/schools",
        GET_TEMP_SCHOOLS: "/admin/new/schools/get-temp-schools",
        EDIT_SCHOOLS: "/admin/new/schools/edit-schools",
    },
    TASKS: {
        ROOT: "/admin/new/tasks",
        LIST: "/admin/new/tasks/list",
    },
    GAME: {
        ROOT: "/admin/new/game",
        ITEMS: "/admin/new/game/items",
        TYPES: "/admin/new/game/item-types",
        MAGIC: "/admin/new/game/magic",
        GROUPS: "/admin/new/game/groups",
    },
};

export const ADMIN_PANEL_ROUTE_COMPONENTS = [
    {id: 1, path: ADMIN_PANEL_ROUTES.DASHBOARD.ROOT, component: <AdminPanelDashboard />},
    {id: 2, path: ADMIN_PANEL_ROUTES.USERS.ROOT, component: <AdminPanelUsers />},
    {id: 3, path: ADMIN_PANEL_ROUTES.IMAGES.ROOT, component: <ImagesManager />},
    {id: 4, path: ADMIN_PANEL_ROUTES.SUBSCRIBES.ROOT, component: <AdminPanelSubscriptions />},
    {id: 5, path: ADMIN_PANEL_ROUTES.ARTICLES.ROOT, component: <ArticlesPage />},
    {
        id: 6,
        path: ADMIN_PANEL_ROUTES.CONSTRUCTORS.ROOT,
        component: <AdminPanelConstructors />,
    },
    {id: 8, path: ADMIN_PANEL_ROUTES.TAGS.ROOT, component: <AdminPanelTags />},
    {id: 9, path: ADMIN_PANEL_ROUTES.SCHOOLS.ROOT, component: <AdminPanelSchools />},
    {id: 10, path: ADMIN_PANEL_ROUTES.NEWS.ROOT, component: <NewsPage />},
    {id: 11, path: ADMIN_PANEL_ROUTES.TASKS.ROOT, component: <AdminPanelTasks />},
    {id: 12, path: ADMIN_PANEL_ROUTES.SYSTEM.ROOT, component: <AdminPanelSystem />},
    {id: 13, path: ADMIN_PANEL_ROUTES.GAME.ROOT, component: <AdminPanelGame />},
    {id: 14, path: ADMIN_PANEL_ROUTES.BANNERS.ROOT, component: <BannerPage />},
];

export const ADMIN_PANEL_USERS_ACTIONS = [
    {
        id: 1,
        title: "Получить пользователей",
        path: ADMIN_PANEL_ROUTES.USERS.VIEW,
        icon: "fa-solid fa-users",
        color: EColors.BLUE,
        component: <UsersListContainer />,
        exact: true,
    },
    {
        id: 2,
        title: "Получить пользователя по Email",
        path: ADMIN_PANEL_ROUTES.USERS.VIEW_BY_EMAIL,
        icon: "fa-solid fa-at",
        color: EColors.BLUE,
        component: <AdminPanelUserByEmail />,
        exact: false,
    },
    {
        id: 3,
        title: "Создать ученика",
        path: ADMIN_PANEL_ROUTES.USERS.REGISTER_STUDENT,
        icon: "fa-solid fa-user-plus",
        color: EColors.GREEN,
        component: <AdminPanelUserCreateStudent />,
        exact: false,
    },
    {
        id: 4,
        title: "Получить учеников подтвержденных учителем",
        path: ADMIN_PANEL_ROUTES.USERS.ACCEPTED_STUDENTS,
        icon: "fa-solid fa-users",
        color: EColors.BLUE,
        component: <AdminPanelUsersAcceptedByTeacher />,
        exact: false,
    },
    {
        id: 5,
        title: "Получить учеников в классе по учителю",
        path: ADMIN_PANEL_ROUTES.USERS.STUDENTS_IN_CLASS,
        icon: "fa-solid fa-users",
        color: EColors.BLUE,
        component: <AdminPanelUsersInClass />,
        exact: false,
    },
    {
        id: 5,
        title: "Получить ученика по имени и фамилии",
        path: ADMIN_PANEL_ROUTES.USERS.VIEW_BY_NAME,
        icon: "fa-solid fa-users",
        color: EColors.BLUE,
        component: <AdminPanelUserByName />,
        exact: false,
    },
    {
        id: 6,
        title: "Редактировать пользователя",
        path: ADMIN_PANEL_ROUTES.USERS.USER_UPDATE,
        icon: "fa-solid fa-users",
        color: EColors.ORANGE,
        isSuperAdmin: true,
        component: <AdminPanelUserUpdate />,
        exact: false,
    },
];

export const ADMIN_PANEL_SUBSCRIPTIONS_ACTIONS = [
    {
        id: 1,
        title: "Получить подписки пользователя",
        path: ADMIN_PANEL_ROUTES.SUBSCRIBES.GET_BY_USER,
        icon: "fa-solid fa-user",
        color: EColors.BLUE,
        component: <AdminPanelSubsByUser />,
        exact: false,
    },
    {
        id: 2,
        title: "Выдать подписку",
        path: ADMIN_PANEL_ROUTES.SUBSCRIBES.GIVE,
        icon: "fa-solid fa-credit-card",
        color: EColors.GREEN,
        component: <AdminPanelSubscriptionGive />,
        exact: false,
    },
    {
        id: 3,
        title: "Массовая выдача подписок по пользователю",
        path: ADMIN_PANEL_ROUTES.SUBSCRIBES.BULK_GIVE,
        icon: "fa-solid fa-credit-card",
        color: EColors.GREEN,
        component: <AdminPanelSubsBulkGive />,
        isSuperAdmin: true,
        exact: false,
    },
];

export const ADMIN_PANEL_TAGS_ACTIONS = [
    {
        id: 1,
        title: "Конструктор тегов",
        path: ADMIN_PANEL_ROUTES.TAGS.CONSTRUCTOR,
        icon: "fa-solid fa-tags",
        color: EColors.BLUE,
        component: <AdminPanelTagsConstructor />,
        exact: false,
    },
];

export const ADMIN_PANEL_SCHOOLS_ACTIONS = [
    {
        id: 1,
        title: "Заявки на добавление школ",
        path: ADMIN_PANEL_ROUTES.SCHOOLS.GET_TEMP_SCHOOLS,
        icon: "fa-solid fa-school",
        color: EColors.BLUE,
        component: <AdminPanelSchoolsTemp />,
        exact: false,
    },
    {
        id: 2,
        title: "Редактор школ",
        path: ADMIN_PANEL_ROUTES.SCHOOLS.EDIT_SCHOOLS,
        icon: "fa-solid fa-pen",
        color: EColors.ORANGE,
        component: <AdminPanelSchoolsEdit />,
        exact: false,
    },
];

export const ADMIN_PANEL_TASKS_ACTIONS = [
    {
        id: 1,
        title: "Задания",
        path: ADMIN_PANEL_ROUTES.TASKS.LIST,
        icon: "",
        color: EColors.BLUE,
        component: <TaskListContainer />,
        exact: true,
    },
];

export const ADMIN_PANEL_SYSTEM_ACTIONS = [
    {
        id: 1,
        title: "События",
        path: ADMIN_PANEL_ROUTES.SYSTEM.EVENTS,
        icon: "fa-solid fa-exclamation",
        color: EColors.BLUE,
        component: <EventsAdminContainer />,
        exact: false,
        isSuperAdmin: false,
    },
    {
        id: 2,
        title: "Темы",
        path: ADMIN_PANEL_ROUTES.SYSTEM.THEMES,
        icon: "fa-solid fa-exclamation",
        color: EColors.BLUE,
        component: <ThemesListPage />,
        exact: false,
        isSuperAdmin: false,
    },
];

export const ADMIN_PANEL_GAME = [
    {
        id: 1,
        title: "Предметы",
        path: ADMIN_PANEL_ROUTES.GAME.ITEMS,
        icon: "fa-solid fa-gamepad",
        color: EColors.BLUE,
        component: <AdminPanelGameItems />,
        exact: false,
        isSuperAdmin: false,
    },
    {
        id: 2,
        title: "Типы предметов",
        path: ADMIN_PANEL_ROUTES.GAME.TYPES,
        icon: "fa-solid fa-gamepad",
        color: EColors.BLUE,
        component: <AdminPanelGameItemType />,
        exact: false,
        isSuperAdmin: false,
    },
    {
        id: 3,
        title: "Магия",
        path: ADMIN_PANEL_ROUTES.GAME.MAGIC,
        icon: "fa-solid fa-gamepad",
        color: EColors.BLUE,
        component: <AdminPanelGameMagic />,
        exact: false,
        isSuperAdmin: false,
    },
    {
        id: 4,
        title: "Группы",
        path: ADMIN_PANEL_ROUTES.GAME.GROUPS,
        icon: "fa-solid fa-gamepad",
        color: EColors.BLUE,
        component: <AdminPanelItemGroups />,
        exact: false,
        isSuperAdmin: false,
    },
];
