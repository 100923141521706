import React, {useState} from "react";
import styles from "./styles.module.sass";
import cn from "classnames";
import {EditButtons} from "../../../../views/components/edit-buttons";
import {MDBBtn, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {EPublicationCustomActionTypes} from "../../../../enums/EPublicationCustomActionTypes";
import PlayerV2Container from "../../../player-v2/views/player-v2/player-v2-container";
import {PlayerV2Modes} from "../../../player-v2/config/player-v2-modes";
import ArticleTitle from "../../article-title/article-title";

interface IProps {
    block: any;
    isViewMode: boolean;
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
    handleCustomAction: (type: EPublicationCustomActionTypes, payload: any) => void;
}

const ArticlePlayerBlock = ({block, isViewMode, handleDelete, handleCustomAction}: IProps) => {
    const [idValue, setIdValue] = useState("");

    if (isViewMode) {
        return (
            <div className={styles.player}>
                <div className={styles.divider}>
                    <img
                        src={require("../../../../assets/images/static/divider.svg")}
                        alt="divider"
                    />
                </div>

                <PlayerV2Container
                    playerMode={PlayerV2Modes.ARTICLE}
                    questionsForView={block.playerQuestions}
                />

                <div className={styles.divider}>
                    <img
                        src={require("../../../../assets/images/static/divider.svg")}
                        alt="divider"
                    />
                </div>
            </div>
        );
    }

    return (
        <div
            className={cn({
                [styles.editMode]: !isViewMode,
                [styles.playerBlock]: true,
            })}
        >
            <div>
                <ArticleTitle
                    isViewMode={isViewMode}
                    handleCustomAction={handleCustomAction}
                    block={block}
                    title={block.description}
                />
                Задания в блоке:
                {block.playerQuestions.length ? (
                    <MDBTable small bordered>
                        <MDBTableHead>
                            <tr>
                                <th>ID</th>
                                <th>Название</th>
                                <th>Позиция</th>
                                <th></th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {block.playerQuestions.map((question: any) => (
                                <tr key={question.question.id}>
                                    <th>{question.question.id}</th>
                                    <th>{question.question.title}</th>
                                    <th>{question.positionQuestion}</th>
                                    <th className="text-center">
                                        <i
                                            className="fas fa-trash-alt red-text mr-0"
                                            onClick={() =>
                                                handleCustomAction(
                                                    EPublicationCustomActionTypes.PLAYER_DELETE_TASK,
                                                    {blockId: block.id, questionId: question.id},
                                                )
                                            }
                                        />
                                    </th>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                ) : (
                    <React.Fragment>
                        <p>нет заданий</p>
                    </React.Fragment>
                )}
            </div>

            <div className={styles.playerAddTask}>
                <input
                    type="text"
                    className="form-control"
                    value={idValue}
                    onChange={(e) => setIdValue(e.target.value)}
                    placeholder="ID задания"
                />
                <MDBBtn
                    color="success"
                    onClick={() => {
                        handleCustomAction(EPublicationCustomActionTypes.PLAYER_ADD_TASK, {
                            questionId: idValue,
                            blockId: block.id,
                        });
                        setIdValue("");
                    }}
                >
                    Добавить
                </MDBBtn>
            </div>
            {!isViewMode && (
                <EditButtons
                    deleteAction={() => handleDelete(block.id)}
                    isSolidBorder
                    itemId={block.id}
                />
            )}
        </div>
    );
};

export default ArticlePlayerBlock;
