import BasketsPlayer from "entries/constructors/baskets-game/views/baskets-player/baskets-player";
import Hints from "entries/hints/hints";
import {PlayerV2Modes} from "entries/player-v2/config/player-v2-modes";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

const BasketsPlayerContainer = ({
    taskId,
    onSelectCorrectAnswer,
    questionApi,
    getInfoAboutSet,
    playerMode,
    answerIsLoading,
    setAnswerIsLoading,
}) => {
    let {id} = useParams();
    const [answers, setAnswers] = useState([]);
    const [baskets, setBaskets] = useState([]);
    const [properties, setProperties] = useState(null);
    const [question, setQuestion] = useState(null);

    useEffect(() => {
        setAnswers([]);
        setQuestion({});
        setProperties({});
        getData();
    }, [taskId]);

    const getData = async () => {
        setAnswerIsLoading(true);
        let data = undefined;
        await questionApi(taskId, id)
            .then((response) => {
                if (playerMode === PlayerV2Modes.TOURNAMENT) {
                    data = response.data[0].question;
                } else {
                    data = response.data;
                    getInfoAboutSet();
                }
            })
            .catch((err) => console.log("load data error", err))
            .finally(() => setAnswerIsLoading(false));

        if (!data) {
            return;
        }

        let tempBaskets = data.baskets.map((basket) => ({...basket, answers: []}));

        setQuestion(data.basketEngine);
        setAnswers(data.basketEngineAnswerVariants);
        setProperties(data.questionProperties);
        setBaskets(tempBaskets);
    };

    const updateBaskets = (updatedBasket) => {
        let updatedBaskets = baskets.map((basket) =>
            basket.id === updatedBasket.id ? updatedBasket : basket,
        );

        setBaskets(updatedBaskets);
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.destination?.droppableId === result.source?.droppableId) {
            return;
        }

        let fromBasket = baskets.find(
            (basket) => basket.id === parseInt(result.source?.droppableId),
        );
        let toBasket = baskets.find(
            (basket) => basket.id === parseInt(result.destination?.droppableId),
        );

        // когда берем элемент из ответов
        if (result.source.droppableId === "answers") {
            // удаляем из массива ответов
            let updatedAnswers = answers?.filter(
                (answer) => answer.id !== parseInt(result.draggableId),
            );

            // добавляем в таргет
            let draggableAnswer = answers?.find(
                (answer) => answer.id === parseInt(result.draggableId),
            );
            toBasket.answers.push(draggableAnswer);
            updateBaskets(toBasket);

            setAnswers(updatedAnswers);
            return;
        }

        // когда складываем элемент в ответы
        if (result.destination.droppableId === "answers") {
            let answer = fromBasket.answers.find(
                (answer) => answer.id === parseInt(result.draggableId),
            );
            fromBasket.answers = fromBasket.answers.filter(
                (answer) => answer.id !== parseInt(result.draggableId),
            );
            updateBaskets(fromBasket);

            setAnswers([...answers, answer]);
            return;
        }

        // когда складываем в корзину
        if (fromBasket && toBasket) {
            let answer = fromBasket.answers.find(
                (answer) => answer.id === parseInt(result.draggableId),
            );
            fromBasket.answers = fromBasket.answers.filter(
                (answer) => answer.id !== parseInt(result.draggableId),
            );
            updateBaskets(fromBasket);

            //добавляем в таргет
            toBasket.answers.push(answer);
            updateBaskets(toBasket);
        }
    };

    useEffect(() => {
        onSelectCorrectAnswer(baskets);
    }, [baskets]);

    const fillCorrectAnswers = (answersForFill) => {
        let correctAnswers = [];
        let updatedAnswers = answers;
        answersForFill.forEach((answer) => {
            let basketIndex = correctAnswers.findIndex((basket) => basket.id === answer.basket.id);

            if (basketIndex > -1) {
                correctAnswers[basketIndex] = {
                    ...correctAnswers[basketIndex],
                    answers: [
                        ...correctAnswers[basketIndex].answers,
                        answer.basketEngineAnswerVariant,
                    ],
                };
            } else {
                correctAnswers.push({
                    ...answer.basket,
                    answers: [answer.basketEngineAnswerVariant],
                });
            }

            updatedAnswers = updatedAnswers.filter((ua) => {
                return ua.id !== answer.basketEngineAnswerVariant.id;
            });
        });

        setAnswers(updatedAnswers);
        setBaskets(correctAnswers);
    };

    return (
        <React.Fragment>
            {!answerIsLoading && (
                <React.Fragment>
                    {playerMode === PlayerV2Modes.ACADEMY && (
                        <Hints question={question} onBuyCorrectAnswer={fillCorrectAnswers} />
                    )}

                    {question && answers && properties && (
                        <BasketsPlayer
                            question={question}
                            answers={answers}
                            baskets={baskets}
                            onDragEnd={onDragEnd}
                            properties={properties}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

BasketsPlayerContainer.propTypes = {
    getInfoAboutSet: PropTypes.func,
    homeworkId: PropTypes.string,
    playerMode: PropTypes.string,
    questionApi: PropTypes.func,
    reset: PropTypes.any,
    taskId: PropTypes.number.isRequired,
    answerIsLoading: PropTypes.bool,
    setAnswerIsLoading: PropTypes.func,
};

export default BasketsPlayerContainer;
