import React, {useEffect, useState} from "react";
// @ts-ignore
import Select from "react-select";
import {TagsAPI} from "../../../core/api/tags-api";
import {toast} from "react-toastify";
import {ISelect} from "../../../interfaces/ISelect";

interface IProps {
    handleTagSelect: (tag: ISelect) => void;
}

const ArticleTags = ({handleTagSelect}: IProps) => {
    const [twoSectionTags, setTwoSectionTags] = useState<any>(null);
    const [selectedSectionTag, setSelectedSectionTag] = useState(null);
    const [tagSelectValue, setTagSelectValue] = useState<ISelect | null>(null);
    const [tags, setTags] = useState<ISelect[]>([]);

    const loadTwoSectionTagsById = () => {
        TagsAPI.getTwoSectionTagsById(49)
            .then((response) => {
                if (response.data) {
                    setTwoSectionTags(
                        response.data.map((t: any) => ({value: t.id, label: t.name})),
                    );
                }
            })
            .catch(() => toast.error("Ошибка загрузки тегов"));
    };

    const loadTagsBySectionTag = (tagId: number) => {
        TagsAPI.getTagsBySectionTagId(tagId)
            .then((response) => {
                if (response.data) {
                    setTags(response.data.map((t: any) => ({value: t.id, label: t.name})));
                }
            })
            .catch(() => toast.error("Ошибка загрузки тегов"));
    };

    useEffect(() => {
        loadTwoSectionTagsById();
    }, []);

    return (
        <div>
            <Select
                placeholder="Выберите группу тегов"
                options={twoSectionTags}
                onChange={(t: any) => {
                    setSelectedSectionTag(t.value);
                    loadTagsBySectionTag(t.value);
                    setTagSelectValue(null);
                }}
                styles={{menu: (provided: any) => ({...provided, zIndex: 9999})}}
            />

            <Select
                placeholder="Выберите тег"
                options={tags}
                onChange={(tag: ISelect) => {
                    setTagSelectValue(tag);
                    handleTagSelect(tag);
                }}
                styles={{menu: (provided: any) => ({...provided, zIndex: 9999})}}
                isDisabled={!selectedSectionTag}
                className="mt-2"
                value={tagSelectValue}
            />
        </div>
    );
};

export default ArticleTags;
