import React from "react";
import {useTranslation} from "react-i18next";

import {ThirdLevelSectionTagsContainer} from "views/containers/tag-manager/third-level-section-tags";

import {HeadTitle} from "views/components/layout/head-title";
import {PageTitle} from "views/components/layout/page";

export const TagManagerPage = () => {
    const {t} = useTranslation("tag_manager");

    return (
        <div>
            <HeadTitle>{t("title")}</HeadTitle>
            <PageTitle>{t("title")}</PageTitle>

            <div>
                <ThirdLevelSectionTagsContainer />
            </div>
        </div>
    );
};
