import {url} from "config";
import {DEFAULT_FONT_SIZE} from "config/project";
import {TextBoxWithSymbolSkipsContainer} from "entries/constructors/dictation/views/containers/text-box-with-symbol-skips/text-box-with-symbol-skips-container";
import {DICTATION_SKIP_DETECT} from "entries/constructors/dictation/views/pages/dictation-page";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {TestContent} from "views/components/layout/test-content";
import {TestConstructor} from "views/components/layout/test-contructor";
import {Question} from "views/components/question";

export const DictationPlayer = ({
    question,
    answers,
    properties,
    onSelectCorrectAnswer,
    selectedPositions,
    setSelectedPosition,
}) => {
    // данные для вопроса
    const [questionText, setQuestionText] = useState("");
    const [questionNarrator, setQuestionNarrator] = useState({});

    const [text, setText] = useState("");

    // параметры
    const [questionSizeText, setQuestionSizeText] = useState(DEFAULT_FONT_SIZE);

    const [background, setBackground] = useState("");

    const onSelectVariant = (index, symbol) => {
        if (selectedPositions.filter((item) => item.index === index).length > 0) {
            let clone = selectedPositions;
            clone = clone.map((item) => {
                if (item.index === index) {
                    item.symbol = symbol;
                    return item;
                } else return item;
            });
            setSelectedPosition(clone);
            onSelectCorrectAnswer(clone);
            return;
        }
        let selectedVariant = [...selectedPositions, {index, symbol}];
        setSelectedPosition(selectedVariant);
    };

    useEffect(() => {
        let variantsForSave = {};
        selectedPositions.forEach((variant) => {
            variantsForSave[variant.index] = variant.symbol.id;
        });
        onSelectCorrectAnswer(variantsForSave);
    }, [selectedPositions]);

    useEffect(() => {
        if (question) {
            // вопрос
            if (question.questionText) {
                setQuestionText(question.questionText);
            }

            // текст диктанта
            if (question.text) {
                setText(question.text);
            }
        }
    }, [question, questionSizeText]);

    useEffect(() => {
        if (properties) {
            // фон
            if (properties.backgroundImage) {
                setBackground(properties.backgroundImage.fileDownloadUri.slice(1));
            } else {
                setBackground("data/image-bank/ae86cc0f-5bc8-457f-98bb-454a0ea993a6.svg");
            }

            // персонаж
            if (properties.narrator) {
                let narratorUrl = `${url}${properties.narrator.image.fileDownloadUri.slice(1)}`;
                setQuestionNarrator({url: narratorUrl});
            }

            // размер шрифта вопроса
            if (properties.sizeTitle) {
                setQuestionSizeText(properties.sizeTitle);
            }
        }
    }, [properties]);

    return (
        <div className="h-100">
            {question && answers && properties && (
                <TestContent background={background} withEditBorder={false}>
                    <TestConstructor>
                        <div className="animate__animated animate__fadeIn">
                            <Question
                                testHasNarrator
                                isEditMode={false}
                                text={questionText}
                                narrator={questionNarrator}
                                textTitleSize={questionSizeText}
                                image={{}}
                            />
                        </div>

                        <div className="mt-2 animate__animated animate__fadeIn">
                            {text.trim() ? (
                                <TextBoxWithSymbolSkipsContainer
                                    skipSymbols={[DICTATION_SKIP_DETECT]}
                                    text={text}
                                    withBorder={false}
                                    isPlayMode={true}
                                    onSelectVariant={onSelectVariant}
                                    selectedPositions={selectedPositions}
                                    answers={answers}
                                />
                            ) : (
                                <p>Текста нет...</p>
                            )}
                        </div>
                    </TestConstructor>
                </TestContent>
            )}
        </div>
    );
};

DictationPlayer.propTypes = {
    answers: PropTypes.array,
    properties: PropTypes.object,
    question: PropTypes.object,
    onSelectCorrectAnswer: PropTypes.func,
};
