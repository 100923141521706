import React, {useEffect, useState} from "react";
import styles from "./styles.module.sass";
import {useStores} from "../../hooks/use-stores";
import {ITask} from "../../interfaces/ITask";
import {observer} from "mobx-react-lite";
import {IHint} from "../../interfaces/IHint";
import {HintsAPI} from "./hints-api";
import {toast} from "react-toastify";
import cn from "classnames";
import SpinnerWithText from "../spinner/spinner";
import HintModal from "../modals/hint-modal/hint-modal";

interface IProps {
    question: ITask;
    onBuyCorrectAnswer: (answers: any) => void;
}

const Hints = observer(({question, onBuyCorrectAnswer}: IProps) => {
    const {appStore, userStore} = useStores();
    const [isLoading, setIsLoading] = useState(false);
    const [hints, setHints] = useState<IHint[] | any[]>([]);
    const [isOpen, setIsOpen] = useState(false);

    const getHints = () => {
        setIsOpen(true);

        if (hints.length) {
            return;
        }

        setIsLoading(true);
        HintsAPI.getHintsByQuestionId(question.id)
            .then((response) => {
                setHints(response.data);
            })
            .catch(() => toast.error("Ошибка получения подсказок"))
            .finally(() => setIsLoading(false));
    };

    const buyHint = (hint: IHint) => {
        if (!hint) {
            toast.error("Произошла ошибка");
            return;
        }

        if (hint.price > userStore.coins) {
            toast.error("Недостаточно монет для покупки");
            return;
        }

        setIsLoading(true);
        if (hint.hintType === "ANSWER") {
            HintsAPI.buyAnswer(hint.id)
                .then((response) => {
                    setIsLoading(false);
                    onBuyCorrectAnswer(response.data);
                    toast.success("Правильные ответы выбраны");
                    userStore.getCoins();
                    getHints();
                })
                .catch(() => {
                    toast.error("Ошибка при покупке подсказки");
                    setIsLoading(false);
                });
        } else {
            HintsAPI.buyHint(hint.id)
                .then((response) => {
                    userStore.getCoins();
                    appStore.addModalToQueue(
                        <HintModal
                            toggle={appStore.closeActiveModal}
                            hint={response.data}
                            key={"hint-modal"}
                        />,
                    );
                })
                .catch(() => toast.error("Ошибка при покупке подсказки"))
                .finally(() => setIsLoading(false));
        }
    };

    useEffect(() => {
        setIsOpen(false);
        setHints([]);
    }, [question?.id]);

    return (
        <div className={styles.hintsWrapper}>
            <div
                className={cn({
                    [styles.hints]: true,
                    [styles.hintsBackground]: isOpen,
                })}
            >
                {isOpen && (
                    <div>
                        {isLoading ? (
                            <SpinnerWithText text="Загрузка..." />
                        ) : (
                            <React.Fragment>
                                {hints.length > 0 ? (
                                    <div
                                        className={cn({
                                            "animate__animated animate__pulse": true,
                                            [styles.hintsMenu]: true,
                                        })}
                                    >
                                        {hints.map((hint) => {
                                            if (hint.hintType === "ANSWER") {
                                                return (
                                                    <div
                                                        className={styles.hintItem}
                                                        onClick={() => buyHint(hint)}
                                                        key={hint.id}
                                                    >
                                                        <div className={styles.hintItemPrice}>
                                                            {hint.price}
                                                        </div>
                                                        <div className={styles.hintItemTitle}>
                                                            {hint.isBuy
                                                                ? "Посмотреть ответ"
                                                                : "Купить ответ"}
                                                        </div>
                                                    </div>
                                                );
                                            } else if (hint.hintType === "HINT") {
                                                return (
                                                    <div
                                                        className={styles.hintItem}
                                                        onClick={() => buyHint(hint)}
                                                        key={hint.id}
                                                    >
                                                        <div className={styles.hintItemPrice}>
                                                            {hint.price}
                                                        </div>
                                                        <div className={styles.hintItemTitle}>
                                                            {hint.isBuy
                                                                ? "Посмотреть подсказку"
                                                                : "Купить подсказку"}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                ) : (
                                    <div>Подсказок нет</div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                )}

                <div
                    className={styles.questionIconWrapper}
                    onClick={() => {
                        getHints();
                        setIsOpen(!isOpen);
                    }}
                >
                    <img
                        className={cn({
                            "animate__animated animate__bounce animate__fast animate__repeat-2":
                                !isOpen,
                            [styles.animate]: true,
                        })}
                        src={require("../../assets/images/static/coin.svg")}
                        alt="coin"
                    />
                </div>
            </div>
        </div>
    );
});

export default Hints;
