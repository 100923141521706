import {ADMIN_ROLE, TEACHER_ROLE} from "config/roles";
import {getMultipartFormDataContentTypeHeader} from "core/config/headers";
import {Request} from "core/utils/request";
import {
    ACADEMY_MODE,
    HOMEWORK_MODE,
    TOURNAMENT_MODE,
} from "entries/player/views/player/utils/player-config";
import {SIZE_S} from "utils/answers-config";

const API_PATH = `open-answer`;
const INTERNAL_ERROR = "error.internal";

export const OpenAnswerAPI = {
    createQuestion: (questionDto) => {
        return Request.post(`${API_PATH}`, questionDto);
    },
    createQuestionAdmin: (questionDto) => {
        return Request.post(`admin/${API_PATH}`, questionDto);
    },
    createProperties: (questionId) => {
        if (questionId) {
            return Request.post(`${API_PATH}/${questionId}/properties`, {
                sizeText: 16,
                sizeTitle: 18,
                sizeImage: SIZE_S,
            });
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    clearAnswers: (questionId) => {
        if (questionId) {
            return Request.delete(`${API_PATH}/${questionId}/answers/clear`);
        }
    },
    editQuestion: (questionId, questionEditDto) => {
        return Request.put(`${API_PATH}/${questionId}`, questionEditDto);
    },
    addImageForQuestion: (questionId, file, height, width, x, y) => {
        if (questionId && file) {
            const fileData = new FormData();
            fileData.set("file", file);

            return Request.post(
                `${API_PATH}/${questionId}/question-image?height=${height}&width=${width}&x=${x}&y=${y}`,
                fileData,
                getMultipartFormDataContentTypeHeader(),
            );
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    deleteQuestionImage: (questionId, imageId) => {
        if (questionId && imageId) {
            return Request.delete(`${API_PATH}/${questionId}/question-image`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    deleteQuestionFormula: (questionId, imageId) => {
        if (questionId && imageId) {
            return Request.delete(`${API_PATH}/${questionId}/question-image-formula`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    deleteQuestion: (questionId) => {
        if (questionId) {
            return Request.delete(`${API_PATH}/${questionId}`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    addFormulaForQuestion: (questionId, formulaImage) => {
        if (questionId && formulaImage) {
            const fileData = new FormData();
            fileData.set("file", formulaImage);

            return Request.post(
                `${API_PATH}/${questionId}/question-image-formula`,
                fileData,
                getMultipartFormDataContentTypeHeader(),
            );
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    deleteAnswer: (questionId, answerId) => {
        if (questionId && answerId) {
            return Request.delete(`${API_PATH}/${questionId}/answer/${answerId}`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    addCorrectAnswers: (questionId, answersIds) => {
        if (questionId) {
            return Request.post(`${API_PATH}/${questionId}/correct-answer`, answersIds);
        }
    },
    setProperties: (questionId, propertiesDto) => {
        if (questionId) {
            return Request.put(`${API_PATH}/${questionId}/properties`, propertiesDto);
        }
        return Promise.reject(INTERNAL_ERROR);
    },
    deleteCorrectAnswers: (questionId) => {
        if (questionId) {
            return Request.delete(`${API_PATH}/${questionId}/correct-answer`);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    addTags: (questionId, tagIds) => {
        if (tagIds) {
            return Request.post(`${API_PATH}/${questionId}/tags`, tagIds);
        }
    },
    addAnswers: (questionId, symbolIds) => {
        if (questionId && symbolIds.length > 0) {
            return Request.post(`${API_PATH}/${questionId}/answer`, symbolIds);
        }

        return Promise.reject(INTERNAL_ERROR);
    },
    getAllData: (questionId) => {
        if (questionId) {
            return Request.get(`${API_PATH}/${questionId}/all`);
        }
        return Promise.reject(INTERNAL_ERROR);
    },
    checkAnswer: (questionId, answers, playerMode, role, id) => {
        if (questionId && answers) {
            if (role === ADMIN_ROLE || role === TEACHER_ROLE) {
                return Request.post(`${API_PATH}/${questionId}/submit-answers-admin`, answers);
            }
            if (playerMode) {
                if (playerMode === ACADEMY_MODE) {
                    return Request.post(
                        `${API_PATH}/${questionId}/submit-answers-academy`,
                        answers,
                    );
                } else if (playerMode === HOMEWORK_MODE) {
                    return Request.post(
                        `${API_PATH}/${questionId}/submit-answers-home-work?teacherHomeWorkId=${id}`,
                        answers,
                    );
                } else if (playerMode === TOURNAMENT_MODE) {
                    return Request.post(
                        `${API_PATH}/${questionId}/submit-answers-tournament?tournamentId=${id}`,
                        answers,
                    );
                }
            }
        }
    },
};
