import { Request } from "../utils/request";

export const TagsAPI = {
    getTwoSectionTagsById: (id: number) => {
        return Request.get(`section-tags/by-section-two/${id}`)
    },
    getTagsBySectionTagId: (id: number) => {
        return Request.get(`section-tags/${id}/tags`)
    }
}
