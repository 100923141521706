import React, {useState} from "react";
import cn from "classnames";
import styles from "./styles.module.sass";
import {MDBBtn} from "mdbreact";
import {ConversationsAPI} from "../conversations/api/conversations-api";
import {toast} from "react-toastify";

interface IProps {
    toggle: () => void;
    updateSettings: () => void;
}

const TeacherFeedBackModal = ({toggle, updateSettings}: IProps) => {
    const [positiveMessage, setPositiveMessage] = useState("");
    const [negativeMessage, setNegativeMessage] = useState("");
    const [needToAddMessage, setNeedToAddMessage] = useState("");

    const sendFeedback = () => {
        let message = `Отзыв: \nЧто понравилось? \n${
            positiveMessage.length ? positiveMessage : "Не заполнено"
        } \nЧто не понравилось? \n${
            negativeMessage.length ? negativeMessage : "Не заполнено"
        } \nЧего не хватило? \n${needToAddMessage.length ? needToAddMessage : "Не заполнено"}`;
        ConversationsAPI.sendMessage(5893, {reply: message}).then(() => {
            updateSettings();
            toast.success("Отзыв отправлен. Спасибо!");
        });
    };

    return (
        <div
            className={cn({
                [styles.containerWrapper]: true,
            })}
        >
            <h3 className={styles.title}>Отзыв</h3>
            <div className={styles.content}>
                <div className={styles.description}>
                    <p>
                        Пожалуйста, уделите нам пару минут и поделитесь впечатлением. Мы хотим
                        развивать не только бесплатный, но и удобный ресурс для ваших занятий.
                    </p>

                    <div className="mt-3">
                        <p className="title mb-2">Что понравилось?</p>
                        <textarea
                            className="w-100 form-control"
                            rows={3}
                            value={positiveMessage}
                            placeholder=""
                            onChange={(event) => setPositiveMessage(event.target.value)}
                        />
                    </div>

                    <div className="mt-3">
                        <p className="title mb-2">Что не понравилось?</p>
                        <textarea
                            className="w-100 form-control"
                            rows={3}
                            value={negativeMessage}
                            placeholder=""
                            onChange={(event) => setNegativeMessage(event.target.value)}
                        />
                    </div>

                    <div className="mt-3">
                        <p className="title mb-2">Чего не хватило?</p>
                        <textarea
                            className="w-100 form-control"
                            rows={3}
                            value={needToAddMessage}
                            placeholder=""
                            onChange={(event) => setNeedToAddMessage(event.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.footer}>
                <MDBBtn color="success" onClick={sendFeedback}>
                    Отправить
                </MDBBtn>

                <MDBBtn color="grey" onClick={toggle}>
                    Закрыть
                </MDBBtn>
            </div>
        </div>
    );
};

export default TeacherFeedBackModal;
