import {useEffect} from "react";
import {
    getHeaderTabs,
    PUBLIC_TABS,
    PUBLIC_TABS_MOBILE,
} from "../../entries/header/config/header-tabs";
import {useStores} from "../../hooks/use-stores";
import {auth} from "../../core/utils/auth";
import {observer} from "mobx-react-lite";

const ServiceTabs = observer(() => {
    const {keycloak} = auth;
    const {playerStore, appStore, userStore} = useStores();

    useEffect(() => {
        if (keycloak.authenticated) {
            appStore.tabs = getHeaderTabs(keycloak);
            return;
        }

        if (playerStore.isMobileMode) {
            appStore.tabs = PUBLIC_TABS_MOBILE;
        } else {
            appStore.tabs = PUBLIC_TABS;
        }
    }, [playerStore.isMobileMode, userStore.role]);

    return null;
});

export default ServiceTabs;
