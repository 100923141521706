export const CLASSIC_TEST = "ClassicTestDto";
export const DICTATION_TEST = "DictationDto";
export const SEQUENCES = "SequenceTestDto";
export const SEQUENCES_TEXT_TEST = "SequenceTestDtoText";
export const SEQUENCES_IMAGE_TEST = "SequenceTestDtoImage";
export const PAIRS_TEST = "PairDto";
export const WORD_SKIP_TEST = "WordSkipDto";
export const WORD_SEQUENCE_SKIP_TEST = "WordSkipClauseDto";
export const WORD_MANY_SKIP_TEST = "ManyWordSkipDto";
export const DRAGGING_TEST = "DraggingDto";
export const OPEN_ANSWER_TEST = "OpenAnswerDto";
export const BASKETS_TEST = "BasketEngineDto";
export const CLIPPING_TEST = "ClippingEngineDto";
export const CLIPPING_SECONDARY_TEST = "ClippingSecondaryEngineDto";
