import React, {ReactElement} from "react";
import styles from "./styles.module.sass";

interface IProps {
    children: ReactElement;
}

const AdminPanelQueryResultLayout = ({children}: IProps) => {
    return (
        <div className={styles.resultWrapper}>
            <div className={styles.result}>{children}</div>
        </div>
    );
};

export default AdminPanelQueryResultLayout;
