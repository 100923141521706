import React from "react";
import PropTypes from "prop-types";

import {SpinnerPage} from "views/components/spinner";

export const TagManagerSection = (props) => {
    const {loaded, className, title, children} = props;

    return (
        <div className={className}>
            <div>{title}</div>
            {loaded ? (
                <ul className="list-group mt-3 p-2 border border-info">{children}</ul>
            ) : (
                <div>
                    <SpinnerPage />
                </div>
            )}
        </div>
    );
};

TagManagerSection.propTypes = {
    loaded: PropTypes.bool.isRequired,
    title: PropTypes.node.isRequired,
    className: PropTypes.string,
};
