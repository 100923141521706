import {makeAutoObservable, runInAction} from "mobx";
import {UserAPI} from "../core/api/user";
import {IUserInfo} from "../interfaces/IUserInfo";
import {toast} from "react-toastify";
import {Roles} from "../enums/roles";

export class UserStore {
    public user: IUserInfo | undefined;
    public role: Roles = Roles.USER;
    public userIsLoading: boolean = false;
    public coins: number = 0;
    public isCoinsLoading = false;
    public academyPlace: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    public getUser = () => {
        this.userIsLoading = true;
        return UserAPI.getUserInfo()
            .then((response: any) => {
                runInAction(() => {
                    this.user = response.data;
                });
            })
            .catch((err: any) => {
                if (err.response.data.status === 404) {
                    toast.info("Пожалуйста, пройдите регистрацию в личном кабинете");
                } else {
                    toast.error("Ошибка авторизации");
                }
            })
            .finally(() => {
                this.userIsLoading = false;
            });
    };

    public getCoins = () => {
        if (!this.user) {
            this.coins = 0;
            return;
        }

        this.isCoinsLoading = true;
        UserAPI.getCoins(this.user.userDto?.id)
            .then((res: any) => {
                runInAction(() => {
                    this.coins = res.data?.coin;
                });
            })
            .finally(() => {
                this.isCoinsLoading = false;
            });
    };
}
