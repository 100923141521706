import {v4 as uuidv4} from "uuid";

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const move = (
    source,
    destination,
    droppableSource,
    droppableDestination,
    withRemove = true,
) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);

    if (withRemove) {
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destClone.splice(droppableDestination.index, 0, removed);
    } else {
        destClone.splice(droppableDestination.index, 0, sourceClone[droppableSource.index]);
    }

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

export const copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item = sourceClone[droppableSource.index];

    destClone.splice(droppableDestination.index, 0, {...item, id: uuidv4()});
    return destClone;
};
