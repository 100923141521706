import React, {useEffect, useState} from "react";
import {Button, Select, Space, Table} from "antd";
import {Request} from "../../../../core/utils/request";
import ModalWrapper from "../../../modals/modal-wrapper/modal-wrapper";
import {IGameItem} from "../../../../interfaces/IGameItem";
import {IGameMagic} from "../../../../interfaces/IGameMagic";
import styles from "./styles.module.sass";
import {toast} from "react-toastify";
import {v4 as uuidv4} from "uuid";

interface IItemModal {
    handleClose: () => void;
    item: IGameItem;
    handleRefresh: () => void;
}

const GameItemLinkModal = ({handleClose, item, handleRefresh}: IItemModal) => {
    const [isLoading, setIsLoading] = useState(false);

    const [magics, setMagics] = useState([]);
    const [slots, setSlots] = useState<any>([]);
    const [itemMagics, setItemMagics] = useState([]);
    const [selectedMagic, setSelectedMagic] = useState<any>(undefined);
    const [slotOptions, setSlotOptions] = useState([]);
    const [selectedSlotOption, setSelectedSlotOption] = useState<any>(undefined);
    const [itemTypesOptions, setItemTypesOptions] = useState<any>([]);
    const [selectedTypeOption, setSelectedTypeOption] = useState<any>(undefined);
    const [groupOptions, setGroupOptions] = useState<any>([]);
    const [selectedGroup, setSelectedGroup] = useState<any>(undefined);

    useEffect(() => {
        if (item?.slots) {
            setSlots(item.slots);
        }
    }, [item?.slots]);

    const getMagics = () => {
        setIsLoading(true);
        Request.get(`/game-magic?size=10000`)
            .then((response) => {
                let magics = response.data?.content?.map((magic: IGameMagic) => ({
                    value: magic.id,
                    label: `${magic.description} (${magic.gameMagicType})`,
                }));
                setMagics(magics);
            })
            .finally(() => setIsLoading(false));
    };

    const getItemMagics = () => {
        setIsLoading(true);
        Request.get(`/game-magic/${item.id}/items?size=10000`)
            .then((response) => {
                setItemMagics(response.data);
            })
            .finally(() => setIsLoading(false));
    };

    const getItemTypesOptions = () => {
        setIsLoading(true);
        Request.get(`/game-item-type?size=10000`)
            .then((response) => {
                let itemTypes = response.data?.content?.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
                setItemTypesOptions(itemTypes);
            })
            .finally(() => setIsLoading(false));
    };

    const getSlots = () => {
        setIsLoading(true);
        Request.get("/game-slot?size=10000")
            .then((response) => {
                let slotOptions = response.data?.content?.map((item: any) => ({
                    value: item.id,
                    label: item.description,
                }));
                setSlotOptions(slotOptions);
            })
            .finally(() => setIsLoading(false));
    };

    const getGroups = () => {
        setIsLoading(true);
        Request.get("/game-item-group?size=10000")
            .then((response) => {
                let groupOptions = response.data?.content?.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
                setGroupOptions(groupOptions);
            })
            .finally(() => setIsLoading(false));
    };

    const updateItemType = (typeId: number) => {
        setIsLoading(true);
        Request.put(`/game-item/${item.id}?size=10000`, {
            gameItemType: {
                id: typeId,
            },
        })
            .then(() => {
                handleRefresh();
                setSelectedTypeOption(typeId);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateItemGroup = (groupId: number) => {
        setIsLoading(true);
        Request.put(`/game-item/${item.id}`, {
            gameItemGroup: {
                id: groupId,
            },
        })
            .then(() => {
                handleRefresh();
                setSelectedGroup(groupId);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getMagics();
        getSlots();
        getItemTypesOptions();
        getGroups();
    }, []);

    useEffect(() => {
        if (item.id) {
            getItemMagics();
        }

        if (item.gameItemType?.id) {
            setSelectedTypeOption(item.gameItemType.id);
        }

        if (item.gameItemGroup?.id) {
            setSelectedGroup(item.gameItemGroup.id);
        }
    }, [item]);

    const handleAddMagic = () => {
        if (!selectedMagic) return;
        Request.post(`/game-item/${item.id}/add/game-magic/${selectedMagic}`)
            .then(() => {
                getItemMagics();
            })
            .finally(() => setIsLoading(false));
    };

    const handleAddSlot = () => {
        setIsLoading(true);
        Request.post(`/game-slot/${selectedSlotOption}/add/game-item/${item.id}`)
            .then((response) => {
                handleRefresh();
                setSlots((prev: any) => [...prev, response.data.gameSlot]);
            })
            .catch(() => {
                toast.error("Ошибка привязки к слоту");
            })
            .finally(() => setIsLoading(false));
    };

    const handleDeleteMagic = (magicId: number) => {
        Request.delete(`/game-magic-item/${magicId}`)
            .then(() => {
                getItemMagics();
            })
            .finally(() => setIsLoading(false));
    };

    const handleDeleteItemSlot = () => {
        setIsLoading(true);
        toast.error("Тут нужно API, пока не удаляет");
        setIsLoading(false);
    };

    const columns: any = [
        {
            title: "Тип",
            key: "gameMagicType",
            render: (_: any, record: any) => {
                return <p>{record?.gameMagic?.gameMagicType}</p>;
            },
        },

        {
            title: "Действия",
            key: "action",
            render: (_: any, record: any) => (
                <Space size="middle">
                    <a onClick={() => handleDeleteMagic(record.id)}>
                        <i className="fas fa-trash" style={{color: "red"}} />
                    </a>
                </Space>
            ),
        },
    ];

    const columnsSlots: any = [
        {
            title: "Слот",
            key: "slots",
            render: (_: any, record: any) => {
                return <p>{record?.description}</p>;
            },
        },

        {
            title: "Действия",
            key: "action1",
            render: (_: any) => (
                <Space size="middle">
                    <a onClick={() => handleDeleteItemSlot()}>
                        <i className="fas fa-trash" style={{color: "red"}} />
                    </a>
                </Space>
            ),
        },
    ];

    return (
        <ModalWrapper withHeader={true} headerTitle={"Привязки"} onClose={handleClose}>
            <div className={styles.wrapper}>
                <div className={styles.block}>
                    <div className={styles.titleBlock}>
                        <p>Тип</p>
                    </div>

                    <div className={styles.controllBlock}>
                        <Select
                            loading={isLoading}
                            options={itemTypesOptions}
                            style={{width: "100%"}}
                            onSelect={(e: any) => updateItemType(e)}
                            value={selectedTypeOption}
                        />
                    </div>
                </div>

                <div className={styles.block}>
                    <div className={styles.titleBlock}>
                        <p>Группа</p>
                    </div>

                    <div className={styles.controllBlock}>
                        <Select
                            loading={isLoading}
                            options={groupOptions}
                            style={{width: "100%"}}
                            onSelect={(e: any) => updateItemGroup(e)}
                            value={selectedGroup}
                        />
                    </div>
                </div>

                <div className={styles.block}>
                    <div className={styles.titleBlock}>
                        <p>Магия</p>
                    </div>

                    <div className={styles.controllBlock}>
                        <Select
                            loading={isLoading}
                            options={magics}
                            style={{width: "90%"}}
                            onSelect={(e) => setSelectedMagic(e)}
                            value={selectedMagic}
                        />

                        <Button
                            htmlType="submit"
                            type="primary"
                            disabled={isLoading || !selectedMagic}
                            className={styles.button}
                            onClick={() => handleAddMagic()}
                        >
                            +
                        </Button>
                    </div>

                    <Table
                        dataSource={itemMagics}
                        columns={columns}
                        className={styles.table}
                        loading={isLoading}
                        rowKey="id"
                        pagination={false}
                    />
                </div>

                <div className={styles.slotsWrapper}>
                    <div className={styles.titleBlock}>
                        <p>Слот</p>
                    </div>

                    <div className={styles.blockSlots}>
                        <Select
                            loading={isLoading}
                            options={slotOptions}
                            style={{width: "90%"}}
                            onSelect={(e) => setSelectedSlotOption(e)}
                            value={selectedSlotOption}
                        />

                        <Button
                            htmlType="submit"
                            type="primary"
                            disabled={isLoading}
                            className={styles.button}
                            onClick={() => handleAddSlot()}
                        >
                            +
                        </Button>
                    </div>

                    <Table
                        dataSource={slots}
                        columns={columnsSlots}
                        className={styles.table}
                        loading={isLoading}
                        rowKey={() => uuidv4()}
                        pagination={false}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default GameItemLinkModal;
